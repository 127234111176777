var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4",
      "dense": ""
    }
  }, [_c('v-treeview', {
    staticClass: "text-button",
    attrs: {
      "active": _vm.deptActive,
      "items": _vm.departments,
      "open": _vm.deptOpenTree,
      "item-key": "_id",
      "transition": "",
      "hoverable": "",
      "activatable": "",
      "dense": ""
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.deptActive = $event;
      },
      "update:open": function updateOpen($event) {
        _vm.deptOpenTree = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.countEmployee(item)) + " 人 ")];
      }
    }, {
      key: "prepend",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.children ? _c('v-icon', {
          domProps: {
            "textContent": _vm._s("".concat(item.type === 1 ? 'account_tree' : 'folder_shared'))
          }
        }) : _vm._e()];
      }
    }])
  }), _c('v-btn', {
    staticClass: "mt-2 ma-2",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "secondary",
      "disabled": !_vm.selectedDept
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_dept', _vm.selectedDept);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("增加部门 ")], 1)], 1), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', [_c('v-scroll-y-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [!_vm.selectedDept ? _c('div', {
    staticClass: "title font-weight-light",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" 选择一个部门 ")]) : _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar', {
    attrs: {
      "dense": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm.selectedDept.children ? _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("account_tree")]) : _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("folder_shared")]), _vm._v(" " + _vm._s(_vm.selectedDept.name) + " ")], 1), _c('v-spacer'), !_vm.selectedDept.isEdit ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "outlined": "",
      "fab": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('edit_managers', _vm.selectedDept);
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e(), _vm.selectedDept.isEdit ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "outlined": "",
      "fab": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('edit_dept', _vm.selectedDept);
      }
    }
  }, [_c('v-icon', [_vm._v("create")])], 1) : _vm._e(), _vm.selectedDept.isEdit ? _c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "outlined": "",
      "fab": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('delete_dept', _vm.selectedDept);
      }
    }
  }, [_c('v-icon', [_vm._v("delete")])], 1) : _vm._e()], 1), _c('v-divider'), _vm._l(_vm.selectedDept.children, function (item, i) {
    return _c('v-list-item', {
      key: i,
      attrs: {
        "dense": ""
      },
      on: {
        "click": function click($event) {
          return _vm.doAction('open_dept', item);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.name))])], 1), _c('v-list-item-action', [_c('v-icon', [_vm._v("keyboard_arrow_right")])], 1)], 1);
  }), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_dept', _vm.selectedDept);
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("创建子部门")])], 1), _c('v-list-item-action', [_c('v-icon', [_vm._v("add")])], 1)], 1), _c('v-divider'), _c('v-data-table', {
    attrs: {
      "headers": _vm.headerEmployee,
      "items": _vm.accountList,
      "options": _vm.employee_options,
      "server-items-length": _vm.accountCount,
      "loading": _vm.accountLoading,
      "item-class": _vm.row_class,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "item-key": "_id",
      "dense": "",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.employee_options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v("groups")]), _vm._v("部门人员")], 1), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer'), _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "outlined": "",
            "fab": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('create_employee', _vm.selectedDept);
            }
          }
        }, [_c('v-icon', [_vm._v("person_add")])], 1), _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "outlined": "",
            "fab": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('import_employee', _vm.selectedDept);
            }
          }
        }, [_c('v-icon', [_vm._v("group_add")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.personal.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('widgets-employee-dialogs', {
          attrs: {
            "account": item._id
          },
          on: {
            "UpdateEmployee": function UpdateEmployee($event) {
              return _vm.doAction('reload_tree');
            }
          }
        }, [_vm._v(_vm._s(item.personal.name ? item.personal.name : '*未设置姓名'))]), _vm.selectedDept.managers.includes(item._id) ? _c('v-chip', {
          staticClass: "ml-1",
          attrs: {
            "x-small": "",
            "label": ""
          }
        }, [_vm._v("负责人")]) : _vm._e()];
      }
    }])
  })], 2)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dialogDept,
      callback: function callback($$v) {
        _vm.dialogDept = $$v;
      },
      expression: "dialogDept"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.dialogTitle) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDept
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _vm.dialogTitle === '新建部门' ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "value": _vm.editedDept.parent.name,
      "label": "上级部门",
      "disabled": "",
      "outlined": "",
      "dense": ""
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "部门名称",
      "hint": "请输入部门名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedDept.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedDept, "name", $$v);
      },
      expression: "editedDept.name"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.dialogTitle === '指定负责人' ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择部门负责人",
      "multiple": ""
    },
    model: {
      value: _vm.editedDept.managers,
      callback: function callback($$v) {
        _vm.$set(_vm.editedDept, "managers", $$v);
      },
      expression: "editedDept.managers"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.dialogTitle === '编辑部门' ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "rules": [_vm.rules.required],
      "value": _vm.selDeptActive.length > 0 ? _vm.selDeptActive[0].name : '',
      "label": "上级部门",
      "disabled": "",
      "outlined": "",
      "dense": ""
    }
  }), _c('v-menu', {
    attrs: {
      "transition": "slide-y-transition",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
            attrs = _ref4.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "mb-6",
          attrs: {
            "outlined": "",
            "dense": "",
            "block": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(_vm._s(_vm.selDeptActive.length > 0 ? _vm.selDeptActive[0].name : '')), _c('v-spacer'), _c('v-icon', {
          attrs: {
            "right": "",
            "dark": ""
          }
        }, [_vm._v("arrow_drop_down")])], 1)];
      }
    }], null, false, 1821205492)
  }, [_c('v-card', [_c('v-card-text', [_c('v-treeview', {
    attrs: {
      "items": _vm.departments,
      "active": _vm.selDeptActive,
      "item-key": "_id",
      "item-disabled": "locked",
      "hoverable": "",
      "activatable": "",
      "return-object": ""
    },
    on: {
      "update:active": function updateActive($event) {
        _vm.selDeptActive = $event;
      }
    }
  })], 1)], 1)], 1), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "部门名称",
      "hint": "请输入部门名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedDept.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedDept, "name", $$v);
      },
      expression: "editedDept.name"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择部门负责人",
      "multiple": ""
    },
    model: {
      value: _vm.editedDept.managers,
      callback: function callback($$v) {
        _vm.$set(_vm.editedDept, "managers", $$v);
      },
      expression: "editedDept.managers"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.dialogTitle === '新增人员' ? _c('v-card-text', [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "start",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var hover = _ref5.hover;
        return [_c('v-avatar', [_c('v-img', {
          attrs: {
            "src": _vm.editedEmployee.profile ? _vm.ossURL + '/' + _vm.editedEmployee.profile : '/static/grey_silhouette.png',
            "alt": "设置照片",
            "aspect-ratio": "1"
          }
        }, [_vm.Upload.uploading ? _c('v-progress-circular', {
          attrs: {
            "color": "secondary",
            "rotate": "360",
            "size": "48",
            "width": "5",
            "value": _vm.Upload.progressUpload
          }
        }) : _vm._e()], 1), hover ? _c('div', {
          staticClass: "show_upload",
          staticStyle: {
            "height": "100%",
            "cursor": "pointer"
          }
        }, [_c('v-icon', {
          on: {
            "click": function click($event) {
              return _vm.doAction('upload_avatar', _vm.editedEmployee);
            }
          }
        }, [_vm._v("add_a_photo")])], 1) : _vm._e()], 1)];
      }
    }], null, false, 3352624915)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工姓名",
      "hint": "员工姓名为用户的真实姓名",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedEmployee.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "name", $$v);
      },
      expression: "editedEmployee.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("https")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "error-messages": _vm.editedEmployee.msgName,
      "clearable": _vm.editedEmployee.msgName !== '',
      "type": "text",
      "label": "电子邮件",
      "hint": "电子邮件将作为登陆名称使用",
      "outlined": "",
      "dense": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.editedEmployee.chkName ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 2521021126),
    model: {
      value: _vm.editedEmployee.username,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "username", $$v);
      },
      expression: "editedEmployee.username"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("vpn_key")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": _vm.editedEmployee.showPass ? 'text' : 'password',
      "append-icon": _vm.editedEmployee.showPass ? 'visibility_off' : 'visibility',
      "label": "账号密码",
      "hint": "请牢记此密码，密码将作为登陆凭证",
      "outlined": "",
      "dense": "",
      "required": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.editedEmployee.showPass = !_vm.editedEmployee.showPass;
      }
    },
    model: {
      value: _vm.editedEmployee.password,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "password", $$v);
      },
      expression: "editedEmployee.password"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("stay_current_portrait")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.phonenum],
      "error-messages": _vm.editedEmployee.msgPhone,
      "clearable": _vm.editedEmployee.msgPhone !== '',
      "type": "number",
      "label": "手机号码",
      "hint": "电话号码将用于恢复密码和登陆凭证",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.editedEmployee.chkPhone ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 488914429),
    model: {
      value: _vm.editedEmployee.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "phone", $$v);
      },
      expression: "editedEmployee.phone"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("account_tree")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.getDeptList(_vm.departments[0]),
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "value",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择所属部门",
      "multiple": ""
    },
    model: {
      value: _vm.editedEmployee.department,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "department", $$v);
      },
      expression: "editedEmployee.department"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("perm_identity")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.flatGroupRoles(_vm.groupList),
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择所属角色",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_typeof(data.item) !== 'object' ? [_c('v-list-item-content', {
          domProps: {
            "textContent": _vm._s(data.item)
          }
        })] : [_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "input-value": data.attrs.inputValue
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        })], 1)]];
      }
    }], null, false, 3296285918),
    model: {
      value: _vm.editedEmployee.roles,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "roles", $$v);
      },
      expression: "editedEmployee.roles"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("store")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.branchList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择所属门店"
    },
    model: {
      value: _vm.editedEmployee.branch,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "branch", $$v);
      },
      expression: "editedEmployee.branch"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("assignment_ind")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工编号",
      "hint": "员工编号为企业内部的员工编号",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedEmployee.employeeNo,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "employeeNo", $$v);
      },
      expression: "editedEmployee.employeeNo"
    }
  })], 1)], 1), _vm._l(_vm.customFields, function (item, i) {
    return _c('v-row', {
      key: i,
      staticClass: "mt-n6",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-center",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-icon', {
      staticClass: "mt-n6",
      attrs: {
        "color": "primary lighten-4"
      }
    }, [_vm._v("list")])], 1), item.format.type === 'text' ? _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [item.format.mask ? _c('v-text-field', {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: item.format.mask,
        expression: "item.format.mask"
      }],
      attrs: {
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _c('v-text-field', {
      attrs: {
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.format.type === 'selects' ? _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": item.format.items,
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e()], 1);
  })], 2)], 1) : _vm._e(), _vm.dialogTitle === '导入人员' ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载人员模版，填写成员信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('download_template');
      }
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importFiles($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('upload_employee');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1), _vm.importEnd ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _c('ul', _vm._l(_vm.importFailed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功导入：" + _vm._s(_vm.importSuccess) + " 条记录 ")])], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_vm.dialogTitle === '新增人员' ? _c('widgets-custom-field', {
    attrs: {
      "module": "Account",
      "ownerId": _vm.currentEmployer.ownerId._id,
      "ownerType": "Dealer"
    },
    on: {
      "Complete": function Complete($event) {
        return _vm.doAction('update_field');
      }
    }
  }) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeDept
    }
  }, [_vm._v("取消")]), _vm.dialogTitle !== '导入人员' ? _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")]) : _vm._e()], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }