var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/dealer/workbench');
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("外采请求列表")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "flat": "",
      "dense": "",
      "hide-details": "",
      "label": "搜索请求编号、企业PO号...",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchItem.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 外采请求是交付企业订单产品时需要进行外部采购的产品清单，通过外采请求处理完成采购成本、利润率估算等内容。 ")]), _c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "options": _vm.optionsItem,
      "headers": _vm.computedHeaders,
      "items": _vm.purchaseList,
      "server-items-length": _vm.purchaseCount,
      "loading": _vm.purchaseLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50]
      },
      "transition": "",
      "fixed-header": "",
      "height": "600"
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-tabs', {
          attrs: {
            "align-with-title": ""
          },
          model: {
            value: _vm.active_tab,
            callback: function callback($$v) {
              _vm.active_tab = $$v;
            },
            expression: "active_tab"
          }
        }, [_c('v-tabs-slider', {
          attrs: {
            "color": "secondary"
          }
        }), _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('all');
            }
          }
        }, [_vm._v("全部工单")]), _vm.todoPurchaseCount ? _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('todo');
            }
          }
        }, [_c('v-badge', {
          attrs: {
            "color": "secondary",
            "content": _vm.todoPurchaseCount
          }
        }, [_vm._v(" 我的待办 ")])], 1) : _vm._e(), _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('follow');
            }
          }
        }, [_vm._v("我的关注")])], 1), _c('widgets-custom-header', {
          attrs: {
            "defaultHeader": _vm.defaultHeader,
            "headerList": _vm.headerItem,
            "version": 3,
            "tableName": "purchaseHeader"
          },
          on: {
            "Cancel": _vm.cancelHeader,
            "Default": _vm.setDefaultHeader,
            "Save": _vm.setHeader
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    _vm.filterDrawer = !_vm.filterDrawer;
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.request_number",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.isMark ? _c('v-btn', {
          attrs: {
            "icon": "",
            "color": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.markCase(item._id, false);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "transition": "scale-transition"
          }
        }, [_vm._v("star")])], 1) : _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.markCase(item._id, true);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "transition": "scale-transition"
          }
        }, [_vm._v("star_outline")])], 1), _c('v-badge', {
          attrs: {
            "color": "secondary",
            "dot": "",
            "value": item.editor._id === _vm.currentUser._id && item.status === 0
          }
        }, [_c('purchase-dialogs', {
          key: item._id,
          attrs: {
            "getId": item._id
          },
          on: {
            "Update": function Update($event) {
              return _vm.getList(_vm.query);
            }
          }
        }, [_vm._v(" " + _vm._s(item.request_number) + " "), item.editor._id === _vm.currentUser._id && item.status === 1 ? _c('v-icon', {
          attrs: {
            "right": "",
            "small": ""
          }
        }, [_vm._v("create")]) : _vm._e()], 1)], 1)], 1)];
      }
    }, {
      key: "item.enterprise",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.enterprise ? _c('td', {
          staticClass: "v-list-item--link text-truncate",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.openEnterInfo(item.enterprise._id);
            }
          }
        }, [_vm._v(" " + _vm._s(item.enterprise.name)), item.branch ? _c('br') : _vm._e(), _vm._v(_vm._s(item.branch) + " ")]) : _vm._e()];
      }
    }, {
      key: "item.product_name_list",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, _vm._l(item.product_name_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            staticStyle: {
              "width": "200px"
            },
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-truncate",
            staticStyle: {
              "max-width": "200px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        }), 0)];
      }
    }, {
      key: "item.product_part_list",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, _vm._l(item.product_part_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            staticStyle: {
              "width": "150px"
            },
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-truncate",
            staticStyle: {
              "max-width": "150px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        }), 0)];
      }
    }, {
      key: "item.product_type_list",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, _vm._l(item.product_type_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            staticStyle: {
              "width": "150px"
            },
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-truncate",
            staticStyle: {
              "max-width": "150px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        }), 0)];
      }
    }, {
      key: "item.product_vendor_list",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, _vm._l(item.product_vendor_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            staticStyle: {
              "width": "150px"
            },
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-truncate",
            staticStyle: {
              "max-width": "150px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        }), 0)];
      }
    }, {
      key: "item.product_quantity_list",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return _vm._l(item.product_quantity_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-center",
            staticStyle: {
              "min-width": "40px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        });
      }
    }, {
      key: "item.product_sales_price_list",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return _vm._l(item.product_sales_price_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-right",
            staticStyle: {
              "min-width": "75px"
            }
          }, [_vm._v(_vm._s(_vm._f("formatMoney")(pn.$numberDecimal)))])])], 1);
        });
      }
    }, {
      key: "item.product_purchase_price_list",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return _vm._l(item.product_purchase_price_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-right",
            staticStyle: {
              "min-width": "75px"
            }
          }, [_vm._v(_vm._s(_vm._f("formatMoney")(pn.$numberDecimal)))])])], 1);
        });
      }
    }, {
      key: "item.product_purchase_margin_list",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return _vm._l(item.product_purchase_margin_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-right",
            staticStyle: {
              "min-width": "75px"
            }
          }, [_vm._v(_vm._s(parseFloat(pn * 100).toFixed(2)) + "%")])])], 1);
        });
      }
    }, {
      key: "item.product_purchase_area_list",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return _vm._l(item.product_purchase_area_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-right",
            staticStyle: {
              "min-width": "75px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        });
      }
    }, {
      key: "item.product_purchase_delivery_list",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return _vm._l(item.product_purchase_delivery_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-right",
            staticStyle: {
              "min-width": "75px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        });
      }
    }, {
      key: "item.editor",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [item.editor && item.editor.personal ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.editor.personal.name))]) : _vm._e()];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.status === 0 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "secondary"
          }
        }, [_vm._v("待处理")]) : item.status === 1 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "primary"
          }
        }, [_vm._v("处理中")]) : item.status === 2 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已取消")]) : _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已完成")])], 1)];
      }
    }])
  })], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "600px",
      "persistent": ""
    },
    model: {
      value: _vm.enterInfoDlg,
      callback: function callback($$v) {
        _vm.enterInfoDlg = $$v;
      },
      expression: "enterInfoDlg"
    }
  }, [_c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": _vm.closeEnterInfo
    }
  })], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.filterDrawer = !_vm.filterDrawer;
            }
          }
        }, [_vm._v("隐藏")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": _vm.clearFilter
          }
        }, [_vm._v("清空")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("处理状态")]), _vm.purchaseStatus.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.status,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "status", $$v);
      },
      expression: "query.status"
    }
  }, _vm._l(_vm.purchaseStatus, function (orders) {
    return _c('v-chip', {
      key: orders.code,
      attrs: {
        "value": orders.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(orders.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2"
  }, [_vm._v("客户")]), _c('v-autocomplete', {
    attrs: {
      "label": "请输入客户名称",
      "autocomplete": "off",
      "items": _vm.dealer_enterprise,
      "item-text": "name",
      "item-value": "_id",
      "hide-no-data": "",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": _vm.setFilterBranch
    },
    model: {
      value: _vm.query.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "enterprise", $$v);
      },
      expression: "query.enterprise"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.arrBranch,
      "label": "分支机构",
      "hint": "请选择企业的分支机构",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.branch,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "branch", $$v);
      },
      expression: "query.branch"
    }
  }), _c('h2', {
    staticClass: "subtitle-2"
  }, [_vm._v("产品类型")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "type", $$v);
      },
      expression: "query.type"
    }
  }, _vm._l(_vm.typeList, function (item) {
    return _c('v-chip', {
      key: item._id,
      attrs: {
        "value": item._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("厂商品牌")]), _c('v-autocomplete', {
    staticClass: "mt-2",
    attrs: {
      "items": _vm.vendorList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "厂商品牌",
      "hint": "请选择产品厂商品牌",
      "chips": "",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "vendor", $$v);
      },
      expression: "query.vendor"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }