<template>
  <div class="fluid">
    <v-data-table
      :headers="headerTable"
      :items="processList"
      :options.sync="optionsTable"
      :server-items-length="processCount"
      :loading="processLoading"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      loading-text="数据读取中... 请稍后"
      item-key="_id"
      transition
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>流程列表</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" depressed color="secondary" @click="doAction('create_item', '', '创建新流程')">
            <v-icon>add</v-icon> 创建流程
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <span :class="item.isEnable ? '' : 'text-decoration-line-through'">{{item.name}}</span>
      </template>
      <template v-slot:item.service_catalog="{ item }">
        <span v-if="item.service_catalog">{{item.service_catalog.name}}</span>
      </template>
      <template v-slot:item.isRecovery="{ item }">
        <span>{{item.isRecovery ? '是' : '否'}}</span>
      </template>
      <template v-slot:item.isOrder="{ item }">
        <span>{{item.isOrder ? '是' : '否'}}</span>
      </template>
      <template v-slot:item.tasks_relation="{ item }">
        <span v-if="item.tasks_relation">{{item.tasks_relation | stringModule}}</span>
      </template>
      <template v-slot:item.SLA_limit="{ item }">
        <span>{{item.SLA_limit === 0 ? '不限' : item.SLA_limit}}</span>
      </template>
      <template v-slot:item.act="{ item }">
        <v-icon small class="mr-2" @click="doAction('edit_item', item, '编辑')">edit</v-icon>
        <v-icon small @click="doAction('active_item', item, true)" v-if="!item.isEnable">check_circle_outline</v-icon>
        <v-icon small @click="doAction('active_item', item, false)" v-if="item.isEnable">block</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataList()">获取数据</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dlgEdit" v-if="dlgEdit" persistent scrollable max-width="700px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[rules.required]"
                  type="text"
                  label="流程名称"
                  hint="请输入任务名称"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="mt-n6">
                <v-autocomplete
                  v-model="editedItem.service_catalog"
                  :items="serviceList"
                  :rules="[rules.selected]"
                  @change="preCreateGroup(editedItem.service_catalog)"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  :disabled="this.editedIndex !== -1"
                  label="请选择流程所属服务"
                >
                  <template v-slot:item="data">
                    <v-list-item-icon><v-icon>{{data.item.icon}}</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.name}}</v-list-item-title>
                      <v-list-item-subtitle>{{data.item.group}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3" class="mt-n10" v-if="isRepair">
                <v-checkbox
                  v-model="editedItem.repair_confirm"
                  label="申请人确认维修"
                  value="applicant"
                ></v-checkbox>
              </v-col>
              <v-col cols="3" class="mt-n10" v-if="isRepair">
                <v-checkbox
                  v-model="editedItem.repair_confirm"
                  label="IT确认维修"
                  value="admin"
                ></v-checkbox>
              </v-col>
              <v-col cols="3" class="mt-n10" v-if="!isRepair">
                <v-switch
                  v-model="editedItem.isRecovery"
                  :label="`更换流程: ${editedItem.isRecovery ? '是' : '否'}`"
                ></v-switch>
              </v-col>
              <v-col cols="3" class="mt-n10" v-if="!isRepair">
                <v-switch
                  v-model="editedItem.isOrder"
                  :label="`采购流程: ${editedItem.isOrder ? '是' : '否'}`"
                ></v-switch>
              </v-col>
              <v-col cols="12" class="mt-n6" v-if="!isRepair">
                <v-combobox
                  v-model="editedItem.process_type"
                  label="服务类型"
                  hint="新建工单时供开单人选择的服务类型，多个逗号分割"
                  deletable-chips
                  multiple
                  small-chips
                  outlined
                  dense
                >
                </v-combobox>
              </v-col>
              <v-col cols="12" class="mt-n6">
                <v-autocomplete
                  v-model="editedItem.department"
                  :items="getDeptList(departments[0])"
                  :rules="[rules.required, rules.selected]"
                  @change="doAction('set_employee_list')"
                  item-text="name"
                  item-value="value"
                  autocomplete="off"
                  outlined
                  dense
                  label="请选择受理部门"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="mt-n6">
                <v-autocomplete
                  v-model="editedItem.account"
                  :items="accountList"
                  :disabled="!selectAccount"
                  ref="selectAccount"
                  item-text="personal.name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  chips
                  small-chips
                  multiple
                  deletable-chips
                  clearable
                  label="受理人员 空代表部门内人员都可受理"
                  hint="请选择任务受理人员"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                      <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="mt-n6">
                <v-text-field
                  v-model.number="editedItem.accept_SLA_limit"
                  suffix="小时"
                  type="number"
                  label="受理环节SLA时效"
                  hint="输入该流程在受理环节的处理时效，0代表不限制"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="mt-n6">
                <v-text-field
                  v-model.number="editedItem.SLA_limit"
                  suffix="小时"
                  type="number"
                  label="流程整体SLA时效"
                  hint="输入该流程整体处理时效，0代表不限制"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" class="mt-n12" v-if="!isRepair">
                <v-switch
                  v-model="editedItem.require_account"
                  :label="`新建工单必须选择用户: ${editedItem.require_account ? '是' : '否'}`"
                ></v-switch>
              </v-col>
              <v-col cols="6" class="mt-n12" v-if="!isRepair">
                <v-switch
                  v-model="editedItem.require_asset"
                  :label="`新建工单必须选择设备: ${editedItem.require_asset ? '是' : '否'}`"
                ></v-switch>
              </v-col>
              <v-col cols="12" class="mt-n6" v-if="!isRepair">
                <v-autocomplete
                  v-model="editedItem.tasks_relation"
                  :items="taskRelation"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="code"
                  autocomplete="off"
                  outlined
                  dense
                  label="请选择任务执行逻辑"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.name}}</v-list-item-title>
                      <v-list-item-subtitle>{{data.item.text}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="mt-n10" v-if="!isRepair">
                <v-subheader>未关联任务清单</v-subheader>
                <v-virtual-scroll :items="tasksList" :item-height="50" height="250">
                  <template v-slot:default="{ index, item }">
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title v-html="item.name"></v-list-item-title>
                        <v-list-item-subtitle v-if="item.action_roles === 'applicant'">申请人执行</v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="item.action_roles === 'enterprise'">申请人团队执行</v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="item.action_roles === 'service'">指定服务团队执行</v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="item.action_roles === 'assign'">申请人指定人员</v-list-item-subtitle>
                        <v-list-item-subtitle v-else>
                          {{item.department.name}}
                          <span v-for="account in item.account" :key="account._id" :account="account._id" divider="- ">{{account.personal.name ? account.personal.name : '*未设置姓名'}}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn depressed small @click="doAction('add_task', index)">关联任务<v-icon right>keyboard_arrow_right</v-icon></v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider></v-divider>
                  </template>
                </v-virtual-scroll>
              </v-col>
              <v-col cols="6" class="mt-n10" v-if="!isRepair">
                <v-list dense>
                  <v-subheader>已关联任务清单</v-subheader>
                  <draggable :list="editedItem.tasks" v-bind="dragOptions" handle=".drag">
                    <v-list-item v-for="(element, index) in editedItem.tasks" :key="element.name">
                      <v-list-item-icon>
                        <v-icon class="mt-2 drag" small>drag_indicator</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="element.name" v-html="element.name"></v-list-item-title>
                        <v-list-item-subtitle v-if="element.action_roles === 'applicant'">申请人执行</v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="element.action_roles === 'enterprise'">申请人团队执行</v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="element.action_roles === 'service'">指定服务团队执行</v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="element.action_roles === 'assign'">申请人指定人员</v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="element.department">
                          {{element.department.name}}
                          <span v-for="account in element.account" :key="account._id" :account="account._id" divider="- ">{{account.personal.name ? account.personal.name : '*未设置姓名'}}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn depressed small icon @click="doAction('remove_task', index)"><v-icon>remove</v-icon></v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </draggable>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import Util from '@/common/util';
import { mapGetters } from "vuex";
import store from "@/store";
import {SET_ERROR} from "@/store/mutations.type";
import {
  FETCH_PROCESS_LIST,
  PUBLISH_PROCESS,
  EDIT_PROCESS,
  ACTIV_PROCESS,
  FETCH_CATALOG_LIST,
  FETCH_TASK_LIST, FETCH_DEPT_LIST, FETCH_ACCOUNT_LIST,
} from "@/store/actions.type";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedIndex: -1,
      isRepair: false,
      selectAccount: false,
      editedItem: {
        _id: '',
        name: '',
        SLA_limit: 0,
        accept_SLA_limit: 0,
        service_catalog: null,
        tasks_relation: 'Sequence',
        process_type: '',
        require_account: true,
        require_asset: false,
        tasks:[],
        enterprise: [],
        isRecovery: false,
        isOrder: false,
        department: null,
        account: [],
        repair_confirm: ['applicant', 'admin'],
      },
      defaultItem: {
        _id: '',
        name: '',
        SLA_limit: 0,
        accept_SLA_limit: 0,
        service_catalog: null,
        tasks_relation: 'Sequence',
        process_type: '',
        require_account: true,
        require_asset: false,
        tasks:[],
        enterprise: [],
        isRecovery: false,
        isOrder: false,
        department: null,
        account: [],
        repair_confirm: ['applicant', 'admin'],
      },
      query: {
        key: '',
      },
      defaultQuery: {
        key: '',
      },
      headerTable: [
        {text: '名称', value: 'name'},
        {text: '所属服务目录', value: 'service_catalog' },
        {text: '回收流程', value: 'isRecovery' },
        {text: '采购流程', value: 'isOrder' },
        {text: 'SLA 单位：小时', value: 'SLA_limit' },
        {text: '任务执行方式', value: 'tasks_relation' },
        {text: '操作', value: 'act', align: 'end', sortable: false}
      ],
      optionsTable: {},
      serviceList: [],
      tasksList: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_DEPT_LIST),
      store.dispatch(FETCH_TASK_LIST)
    ]).then(() => {
      next();
    });
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "processLoading",
      "processList",
      "processCount",
      "taskList",
      "departments",
      "accountList",
    ]),
    taskRelation () {
      return Util.categories('taskRelation')
    },
    dragOptions() {
      return {
        animation: 200,
        group: "tasks",
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  mounted() {},
  methods: {
    getDataList(query = {}) {
      // 获取分页信息
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (query.key) urlQuery += '&key=' + query.key
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_PROCESS_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    async doAction(action, item, customitem) {
      switch (action) {
        case 'search_key': {
          if (this.query.key !== '') {
            this.getDataList({key: this.query.key})
          } else {
            this.getDataList()
          }
          break
        }
        case 'create_item': {
          const [services, tasks] = await Promise.all([
            store.dispatch(FETCH_CATALOG_LIST),
            store.dispatch(FETCH_TASK_LIST, 'isEnable=true')
          ])
          this.serviceList = this.flatGroupCatalog(services['catalog'])
          this.tasksList = tasks['tasks']
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
        case 'set_employee_list': {
          this.editedItem.account = []
          this.$refs.selectAccount.internalSearch = null;
          if (this.editedItem.department) {
            if (this.editedItem.department !== 'branch' && this.editedItem.department !== 'assign') {
              store.dispatch(FETCH_ACCOUNT_LIST, '&limit=100&department='+ this.editedItem.department)
              this.selectAccount = true
            } else {
              this.editedItem.account = null
              this.$refs.selectAccount.internalSearch = null;
              this.selectAccount = false
            }
          }
          break
        }
        case 'edit_item': {
          this.editedIndex = this.processList.indexOf(item)
          this.editedItem = Object.assign({}, item)
          const [services, tasks] = await Promise.all([
            store.dispatch(FETCH_CATALOG_LIST),
            store.dispatch(FETCH_TASK_LIST, 'isEnable=true')
          ])
          this.serviceList = this.flatGroupCatalog(services['catalog'])
          this.tasksList = tasks['tasks'].filter(f => !this.editedItem.tasks.includes(f._id))
          this.editedItem.tasks = this.editedItem.tasks.map(i => tasks['tasks'].find(o => o._id === i))

          if (this.editedItem.service_catalog ) {
            this.editedItem.service_catalog = this.editedItem.service_catalog._id
            this.preCreateGroup(this.editedItem.service_catalog)
          }
          if (this.editedItem.action_roles === 'branch') {
            this.editedItem.department = 'branch'
          } else if (this.editedItem.action_roles === 'assign') {
            this.editedItem.department = 'assign'
          } else {
            this.selectAccount = true
            this.editedItem.action_roles = 'department'
            if (this.editedItem.department) {
              this.editedItem.department = this.editedItem.department._id
            } else {
              this.editedItem.department = ''
            }
            store.dispatch(FETCH_ACCOUNT_LIST, '&limit=100&department='+ this.editedItem.department)
              .then(() => {
                if (this.editedItem.account) this.editedItem.account = this.editedItem.account.map(o => o._id)
              })
          }

          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
        case 'active_item': {
          if (confirm(`确定要 ${customitem ? '启用' : '禁用'} ${item.name}？`)) {
            store.dispatch(ACTIV_PROCESS, {_id: item._id, reply: customitem})
              .then(() => {
                store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
                this.getDataList()
                this.close()
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          }
          break
        }
        case 'add_task': {
          this.editedItem.tasks.push(this.tasksList[item])
          this.tasksList.splice(item, 1)
          break
        }
        case 'remove_task': {
          this.tasksList.push(this.editedItem.tasks[item])
          this.editedItem.tasks.splice(item, 1)
          break
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    close () {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.serviceList.length = 0
      this.tasksList.length = 0
      this.$nextTick(() => {
        this.isRepair = false
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.tasks.length = 0
        this.editedItem.enterprise.length = 0
        this.editedItem.account.length = 0
        this.editedIndex = -1
      })
    },
    submit() {
      if (!this.isRepair && !this.editedItem.tasks.length) {
        store.commit(SET_ERROR, {msg: '请选择流程包含的任务。'});
        return
      }
      let strInsert = {}
      strInsert.name = this.editedItem.name
      if (typeof this.editedItem.service_catalog === "object") {
        strInsert.service_catalog = this.editedItem.service_catalog._id
      } else {
        strInsert.service_catalog = this.editedItem.service_catalog
      }
      if (this.editedItem.department === 'branch') {
        strInsert.action_roles = 'branch'
        strInsert.department = ''
        strInsert.account = ''
      } else if (this.editedItem.department === 'assign') {
        strInsert.action_roles = 'assign'
        strInsert.department = ''
        strInsert.account = ''
      } else {
        strInsert.action_roles = 'department'
        strInsert.department = this.editedItem.department
        strInsert.account = this.editedItem.account
      }
      if (this.editedItem.account && this.editedItem.account.length) strInsert.action_roles = 'account'
      strInsert.process_type = this.editedItem.process_type || []
      strInsert.SLA_limit = this.editedItem.SLA_limit || 0
      strInsert.accept_SLA_limit = this.editedItem.accept_SLA_limit || 0
      strInsert.require_account = this.editedItem.require_account || false
      strInsert.require_asset = this.editedItem.require_asset || false
      strInsert.isRecovery = this.editedItem.isRecovery || false
      strInsert.isOrder = this.editedItem.isOrder || false
      strInsert.tasks_relation = this.editedItem.tasks_relation || 'Sequence'
      strInsert.tasks = this.editedItem.tasks.map(o => o._id)
      strInsert.repair_confirm = this.editedItem.repair_confirm || []

      if (this.editedIndex !== -1) {
        strInsert._id = this.editedItem._id
        store.dispatch(EDIT_PROCESS, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_PROCESS, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },
    getDeptList(obj) {
      let arrObj = [];
      arrObj.push({name: obj.name, value: obj._id})
      if (obj.children) {
        for (let item_1 of obj.children) {
          arrObj.push({name: obj.name +'>>'+ item_1.name, value: item_1._id})
          if (item_1.children) {
            for (let item_2 of item_1.children) {
              arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name, value: item_2._id})
              if (item_2.children) {
                for (let item_3 of item_2.children) {
                  arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name +'>>'+ item_3.name, value: item_3._id})
                }
              }
            }
          }
        }
      }
      arrObj.push({name: '按企业所属服务门店', value: 'branch'})
      // arrObj.push({name: '申请人指定人员', value: 'assign'})
      return arrObj;
    },
    flatGroupCatalog(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        groupItem.children.forEach((catalogItem) => {
          arrRoles.push({ _id: catalogItem._id, name: catalogItem.name, icon: catalogItem.profileIcon, remarks: catalogItem.remarks, group: groupItem.name, group_code: groupItem.service_type })
        })
      })
      return arrRoles
    },
    preCreateGroup(group = '') {
      let objGroup = this.serviceList.find(o => o._id === group)
      if (objGroup && objGroup.group_code === 'asset_repair') {
        this.isRepair = true
      } else {
        this.isRepair = false
      }
    }
  }
}
</script>
<style scoped>

</style>
