var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.productList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.productCount,
      "loading": _vm.productLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "item-class": _vm.itemRowBackground,
      "fixed-header": "",
      "height": "600",
      "item-key": "_id",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("产品列表")]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-text-field', {
          attrs: {
            "label": "关键字搜索（按回车）",
            "prepend-inner-icon": "search",
            "background-color": _vm.$vuetify.theme.dark ? '' : 'grey lighten-4',
            "solo": "",
            "flat": "",
            "dense": "",
            "hide-details": "",
            "clearable": ""
          },
          on: {
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.searchItem.apply(null, arguments);
            },
            "click:clear": _vm.clearSearchItem
          },
          model: {
            value: _vm.query.key,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "key", $$v);
            },
            expression: "query.key"
          }
        }), _c('v-spacer'), _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "ma-2",
                attrs: {
                  "depressed": "",
                  "color": "secondary"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("add")]), _vm._v(" 创建产品 ")], 1)];
            }
          }])
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.editItem();
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("add_box")])], 1), _c('v-list-item-title', [_vm._v("通过表单增加单个产品")])], 1), _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.importItem();
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("library_add")])], 1), _c('v-list-item-title', [_vm._v("通过文件批量增加产品")])], 1)], 1)], 1), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Asset_Manage') ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "loading": !_vm.exportEnd
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.exportItem.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("save_alt")])], 1)];
            }
          }], null, false, 3898156763)
        }, [_c('span', [_vm._v("导出数据")])]) : _vm._e(), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.openFilter.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "header.scale",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "mx-2",
          attrs: {
            "size": "18"
          },
          on: {
            "click": function click($event) {
              _vm.headerScale = !_vm.headerScale;
            }
          }
        }, [_vm._v(_vm._s(_vm.headerScale ? 'keyboard_backspace' : 'keyboard_tab'))])];
      },
      proxy: true
    }, {
      key: "item.name",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('td', {
          staticClass: "text-truncate",
          style: _vm.headerScale ? '' : 'max-width: 125px;',
          on: {
            "click": function click($event) {
              return _vm.openItem(item._id);
            }
          }
        }, [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: "item.product_code",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          staticClass: "text-truncate",
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.product_code))])];
      }
    }, {
      key: "item.product_type",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.product_type ? item.product_type.name : ''))])];
      }
    }, {
      key: "item.part_number",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          staticClass: "text-truncate",
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.part_number))])];
      }
    }, {
      key: "item.vendor",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          staticClass: "text-truncate",
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.vendor ? item.vendor.name : ''))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(item);
            }
          }
        }, [_vm._v("edit")]), !item.isEnable ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.activeItem(item, true);
            }
          }
        }, [_vm._v("check_circle_outline")]) : _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.activeItem(item, false);
            }
          }
        }, [_vm._v("block")])], 1)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("刷新")])];
      },
      proxy: true
    }])
  }), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("所属模块")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.filterItem,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "filterItem", $$v);
      },
      expression: "query.filterItem"
    }
  }, _vm._l(_vm.ProductModule, function (module) {
    return _c('v-chip', {
      key: module.code,
      attrs: {
        "value": module.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(module.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("是否启用")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "mandatory": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.isEnable,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isEnable", $$v);
      },
      expression: "query.isEnable"
    }
  }, _vm._l(_vm.ProductEnable, function (enable) {
    return _c('v-chip', {
      key: enable.code,
      attrs: {
        "value": enable.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(enable.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("厂商品牌")]), _c('v-autocomplete', {
    staticClass: "mt-2",
    attrs: {
      "items": _vm.vendorList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "厂商品牌",
      "hint": "请选择产品厂商品牌",
      "chips": "",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "vendor", $$v);
      },
      expression: "query.vendor"
    }
  }), _c('h2', {
    staticClass: "subtitle-2"
  }, [_vm._v("产品类型")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "type", $$v);
      },
      expression: "query.type"
    }
  }, _vm._l(_vm.typeList, function (item) {
    return _c('v-chip', {
      key: item._id,
      attrs: {
        "value": item._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "650px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "产品名称",
      "hint": "请输入产品名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "产品简称",
      "hint": "请输入产品简称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.product_code,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "product_code", $$v);
      },
      expression: "editedItem.product_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.vendorList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "品牌厂商",
      "hint": "请选择产品品牌厂商"
    },
    model: {
      value: _vm.editedItem.vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "vendor", $$v);
      },
      expression: "editedItem.vendor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.typeList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "产品类型",
      "hint": "请选择产品类型"
    },
    on: {
      "change": function change($event) {
        return _vm.setFields();
      }
    },
    model: {
      value: _vm.editedItem.product_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "product_type", $$v);
      },
      expression: "editedItem.product_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "产品型号",
      "hint": "请输入产品型号 如：MGXC2CH/A",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.checkItem();
      }
    },
    model: {
      value: _vm.editedItem.part_number,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "part_number", $$v);
      },
      expression: "editedItem.part_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "部件号",
      "hint": "请输入产品部件号 如：A1389",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.model_number,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "model_number", $$v);
      },
      expression: "editedItem.model_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "ERP物料号",
      "hint": "请输入ERP中物料编码",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.erp_code,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "erp_code", $$v);
      },
      expression: "editedItem.erp_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "产品描述",
      "rows": "2",
      "hint": "请输入产品详细信息",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "remarks", $$v);
      },
      expression: "editedItem.remarks"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6 mb-4",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "rules": [_vm.rules.selected],
      "label": "设备服务",
      "value": "default",
      "hide-details": ""
    },
    model: {
      value: _vm.editedItem.selected,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "selected", $$v);
      },
      expression: "editedItem.selected"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6 mb-4",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "rules": [_vm.rules.selected],
      "label": "维修服务",
      "value": "repair",
      "hide-details": ""
    },
    model: {
      value: _vm.editedItem.selected,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "selected", $$v);
      },
      expression: "editedItem.selected"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6 mb-4",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "rules": [_vm.rules.selected],
      "label": "订单服务",
      "value": "order",
      "hide-details": ""
    },
    model: {
      value: _vm.editedItem.selected,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "selected", $$v);
      },
      expression: "editedItem.selected"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6 mb-4",
    attrs: {
      "cols": "3"
    }
  }, [_vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Lease_Product') || _vm.currentUser.permission.includes('Lease_Admin') || _vm.currentUser.permission.includes('Lease_Audit') ? _c('v-checkbox', {
    attrs: {
      "rules": [_vm.rules.selected],
      "label": "租赁服务",
      "value": "rent",
      "hide-details": ""
    },
    model: {
      value: _vm.editedItem.selected,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "selected", $$v);
      },
      expression: "editedItem.selected"
    }
  }) : _vm._e()], 1), _vm.editedItem.selected.includes('order') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "label": "销售价格",
      "outlined": "",
      "dense": "",
      "min": "0",
      "step": "0.01",
      "type": "number",
      "prefix": "¥",
      "suffix": "元"
    },
    model: {
      value: _vm.editedItem.sales_price,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "sales_price", $$v);
      },
      expression: "editedItem.sales_price"
    }
  })], 1) : _vm._e(), _vm.editedItem.selected.includes('default') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.ramSize,
      "label": "内存大小",
      "hint": "请选择内存大小",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.ramSize,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "ramSize", $$v);
      },
      expression: "editedItem.ramSize"
    }
  })], 1) : _vm._e(), _vm.editedItem.selected.includes('default') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.networkModel,
      "label": "网络制式",
      "hint": "请选择网络制式",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": ""
    },
    model: {
      value: _vm.editedItem.networkModel,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "networkModel", $$v);
      },
      expression: "editedItem.networkModel"
    }
  })], 1) : _vm._e(), _vm.editedItem.selected.includes('default') ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": [0, 1, 2, 3, 4, 5, 6, 8, 9, 10],
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "保修期：年",
      "hint": "年"
    },
    model: {
      value: _vm.editedItem.guarantee_year,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "guarantee_year", $$v);
      },
      expression: "editedItem.guarantee_year"
    }
  })], 1) : _vm._e(), _vm.editedItem.selected.includes('default') ? _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": [0, 1, 2, 3, 4, 5, 6, 8, 9, 10, 11],
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "保修期：月",
      "hint": "月"
    },
    model: {
      value: _vm.editedItem.guarantee_month,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "guarantee_month", $$v);
      },
      expression: "editedItem.guarantee_month"
    }
  })], 1) : _vm._e()], 1), _vm._l(_vm.customFields, function (item, i) {
    return _c('v-row', {
      key: i,
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [item.type === 'text' ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [item.mask ? _c('v-text-field', {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: item.mask,
        expression: "item.mask"
      }],
      attrs: {
        "rules": item.rules ? item.rules.map(function (d) {
          return _vm.rules[d];
        }) : [],
        "label": item.name,
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _c('v-text-field', {
      attrs: {
        "label": item.name,
        "rules": item.rules ? item.rules.map(function (d) {
          return _vm.rules[d];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.type === 'selects' ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": item.items,
        "label": item.name,
        "rules": item.rules ? item.rules.map(function (d) {
          return _vm.rules[d];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e()], 1);
  }), _vm.editedItem.selected.includes('rent') ? _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "官网价格",
      "outlined": "",
      "dense": "",
      "min": "0",
      "step": "0.01",
      "type": "number",
      "prefix": "¥",
      "suffix": "元"
    },
    model: {
      value: _vm.editedItem.rentInfo.official_price,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo, "official_price", $$v);
      },
      expression: "editedItem.rentInfo.official_price"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "label": "采购价格",
      "outlined": "",
      "dense": "",
      "min": "0",
      "step": "0.01",
      "type": "number",
      "prefix": "¥",
      "suffix": "元"
    },
    model: {
      value: _vm.editedItem.rentInfo.purchase_price,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo, "purchase_price", $$v);
      },
      expression: "editedItem.rentInfo.purchase_price"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n12",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', [_vm._v("采购折扣比例：" + _vm._s(parseFloat((_vm.editedItem.rentInfo.purchase_price / _vm.editedItem.rentInfo.official_price * 100).toFixed(2))) + "%")])]), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "1年余值比例",
      "suffix": "%",
      "hint": '1年到期余值：' + parseFloat((_vm.editedItem.rentInfo.official_price > 0 ? _vm.editedItem.rentInfo.official_price * parseFloat((_vm.editedItem.rentInfo.SV._1_year_ratio / 100).toFixed(4)) : _vm.editedItem.rentInfo.purchase_price * parseFloat((_vm.editedItem.rentInfo.SV._1_year_ratio / 100).toFixed(4))).toFixed(2)),
      "persistent-hint": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rentInfo.SV._1_year_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo.SV, "_1_year_ratio", $$v);
      },
      expression: "editedItem.rentInfo.SV._1_year_ratio"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "2年余值比例",
      "suffix": "%",
      "hint": '2年到期余值：' + parseFloat((_vm.editedItem.rentInfo.official_price > 0 ? _vm.editedItem.rentInfo.official_price * parseFloat((_vm.editedItem.rentInfo.SV._2_year_ratio / 100).toFixed(4)) : _vm.editedItem.rentInfo.purchase_price * parseFloat((_vm.editedItem.rentInfo.SV._2_year_ratio / 100).toFixed(4))).toFixed(2)),
      "persistent-hint": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rentInfo.SV._2_year_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo.SV, "_2_year_ratio", $$v);
      },
      expression: "editedItem.rentInfo.SV._2_year_ratio"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "3年余值比例",
      "suffix": "%",
      "hint": '3年到期余值：' + parseFloat((_vm.editedItem.rentInfo.official_price > 0 ? _vm.editedItem.rentInfo.official_price * parseFloat((_vm.editedItem.rentInfo.SV._3_year_ratio / 100).toFixed(4)) : _vm.editedItem.rentInfo.purchase_price * parseFloat((_vm.editedItem.rentInfo.SV._3_year_ratio / 100).toFixed(4))).toFixed(2)),
      "persistent-hint": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rentInfo.SV._3_year_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo.SV, "_3_year_ratio", $$v);
      },
      expression: "editedItem.rentInfo.SV._3_year_ratio"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "1年回收残值",
      "prefix": "¥",
      "suffix": "元",
      "hint": '1年回收比例：' + parseFloat((_vm.editedItem.rentInfo.official_price > 0 ? _vm.editedItem.rentInfo.SV._1_year_recycle / _vm.editedItem.rentInfo.official_price * 100 : _vm.editedItem.rentInfo.SV._1_year_recycle / _vm.editedItem.rentInfo.purchase_price * 100).toFixed(2)) + '%',
      "persistent-hint": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rentInfo.SV._1_year_recycle,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo.SV, "_1_year_recycle", $$v);
      },
      expression: "editedItem.rentInfo.SV._1_year_recycle"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "2年回收残值",
      "prefix": "¥",
      "suffix": "元",
      "hint": '2年回收比例：' + parseFloat((_vm.editedItem.rentInfo.official_price > 0 ? _vm.editedItem.rentInfo.SV._2_year_recycle / _vm.editedItem.rentInfo.official_price * 100 : _vm.editedItem.rentInfo.SV._2_year_recycle / _vm.editedItem.rentInfo.purchase_price * 100).toFixed(2)) + '%',
      "persistent-hint": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rentInfo.SV._2_year_recycle,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo.SV, "_2_year_recycle", $$v);
      },
      expression: "editedItem.rentInfo.SV._2_year_recycle"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "3年回收残值",
      "prefix": "¥",
      "suffix": "元",
      "hint": '3年回收比例：' + parseFloat((_vm.editedItem.rentInfo.official_price > 0 ? _vm.editedItem.rentInfo.SV._3_year_recycle / _vm.editedItem.rentInfo.official_price * 100 : _vm.editedItem.rentInfo.SV._3_year_recycle / _vm.editedItem.rentInfo.purchase_price * 100).toFixed(2)) + '%',
      "persistent-hint": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rentInfo.SV._3_year_recycle,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo.SV, "_3_year_recycle", $$v);
      },
      expression: "editedItem.rentInfo.SV._3_year_recycle"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "type": "number",
      "label": "2年延保单价",
      "prefix": "¥",
      "suffix": "元",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rentInfo.warranty._2_year_inside,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo.warranty, "_2_year_inside", $$v);
      },
      expression: "editedItem.rentInfo.warranty._2_year_inside"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "type": "number",
      "label": "3年延保单价",
      "prefix": "¥",
      "suffix": "元",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rentInfo.warranty._3_year_inside,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo.warranty, "_3_year_inside", $$v);
      },
      expression: "editedItem.rentInfo.warranty._3_year_inside"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "type": "number",
      "label": "2年延保零售",
      "prefix": "¥",
      "suffix": "元",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rentInfo.warranty._2_year_retail,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo.warranty, "_2_year_retail", $$v);
      },
      expression: "editedItem.rentInfo.warranty._2_year_retail"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "type": "number",
      "label": "3年延保零售",
      "prefix": "¥",
      "suffix": "元",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rentInfo.warranty._3_year_retail,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rentInfo.warranty, "_3_year_retail", $$v);
      },
      expression: "editedItem.rentInfo.warranty._3_year_retail"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-subheader', [_vm._v("产品图片")]), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm._l(this.editedItem.pictures, function (n, i) {
    return _c('v-card', {
      key: i,
      staticClass: "ma-2",
      attrs: {
        "outlined": "",
        "tile": ""
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref10) {
          var hover = _ref10.hover;
          return [_c('v-img', {
            staticClass: "ma-2",
            attrs: {
              "src": _vm.ossURL + '/' + n,
              "max-width": 125
            }
          }, [_c('v-fade-transition', [hover ? _c('v-overlay', {
            staticClass: "align-center",
            attrs: {
              "absolute": "",
              "color": "white lighten-5"
            }
          }, [_c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.addImage(i);
              }
            }
          }, [_c('v-icon', [_vm._v("backup")])], 1), _c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.removeImage(i);
              }
            }
          }, [_c('v-icon', [_vm._v("clear")])], 1)], 1) : _vm._e()], 1)], 1)];
        }
      }], null, true)
    })], 1);
  }), _c('v-card', {
    staticClass: "ma-3 pa-6",
    attrs: {
      "outlined": "",
      "tile": "",
      "hover": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addImage(-1);
      }
    }
  }, [_c('v-icon', [_vm._v("add")])], 1)], 2)], 1)], 1)], 2), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.dlgConflict,
      callback: function callback($$v) {
        _vm.dlgConflict = $$v;
      },
      expression: "dlgConflict"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v("产品型号" + _vm._s(_vm.editedItem.part_number) + "已经存在")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.returnEdit
    }
  }, [_vm._v("修改原有产品")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": _vm.clearPartNo
    }
  }, [_vm._v("重新填写型号")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "700"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'product' ? _c('widgets-product-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": _vm.closeItem
    }
  }) : _vm._e(), _vm.activeType === 'import' ? _c('v-card', [_c('v-card-title', [_vm._v(" 批量导入 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeItem
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载设备模版，填写产品信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.downloadTemplate();
      }
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importFile($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.uploadImportFile();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1), _vm.importEnd ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _c('ul', _vm._l(_vm.importFailed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功导入：" + _vm._s(_vm.importSuccess) + " 条记录 ")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeItem
    }
  }, [_vm._v("关闭")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }