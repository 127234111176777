<template>
  <v-container>
    hi~
  </v-container>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
    ]).then(() => {
      next();
    });
  },
  created() {
  },
  computed: {
  },
  watch: {},
  methods: {
  },
}
</script>
