<template>
  <v-container>
    <v-form v-model="validOffer">
      <v-card>
        <v-card-title class="secondary font-weight-regular">
          <v-btn
            icon
            @click="$router.push('/dealer/workbench/rent/list')"
            class="mr-2"
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          新建报价
          <!-- <v-spacer></v-spacer>
          <v-btn
            color="white"
            outlined
            :loading="loading"
            :disabled="!validOffer || !linkedProduct.length"
          >
            保存
          </v-btn> -->
        </v-card-title>
        <v-card-text>
          <v-subheader>基本信息</v-subheader>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="offerItem.title"
                label="报价名称、说明"
                hint="新建报价单命名规则：客户名称加日期"
                outlined
                :rules="[rules.required]"
                dense
                prepend-icon="content_paste"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="offerItem.enterprise"
                label="客户"
                autocomplete="off"
                :items="enterpriseList"
                item-text="name"
                item-value="_id"
                :rules="[rules.required]"
                hide-no-data
                :loading="isEnterpriseLoading"
                :search-input.sync="searchEnterprise"
                outlined
                dense
                prepend-icon="business"
                append-outer-icon="add"
                @click:append-outer="openCreateEnterprise"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="offerItem.payment_day"
                :items="Array.from({ length: 28 }, (v, k) => k + 1)"
                label="客户付款日"
                :rules="[rules.selected]"
                outlined
                dense
                prepend-icon="payment"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="offerItem.rent_type"
                :items="rentType"
                label="租赁方式"
                :rules="[rules.selected]"
                item-text="name"
                item-value="code"
                outlined
                dense
                prepend-icon="published_with_changes"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-menu
                v-model="startMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="offerItem.lease_start"
                    label="预计起租日期"
                    prepend-icon="date_range"
                    readonly
                    :rules="[rules.required]"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="offerItem.lease_start"
                  @input="startMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="offerItem.lease_term"
                label="租期（年）"
                outlined
                :rules="[rules.selected]"
                item-text="name"
                item-value="code"
                dense
                :items="leaseTerm"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="offerItem.payment_cycle"
                :items="paymentCycle"
                label="支付周期"
                :rules="[rules.selected]"
                outlined
                item-text="name"
                item-value="code"
                dense
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="offerItem.payment_period"
                label="预计客户账期（天）"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-autocomplete
                v-model="offerItem.creator"
                :search-input.sync="searchSales"
                label="分配销售"
                item-text="personal.name"
                :rules="[rules.required]"
                item-value="_id"
                :items="salesList"
                outlined
                dense
                prepend-icon="person_outline"
                :disabled="disSelectSales"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      data.item.personal.name
                        ? data.item.personal.name
                        : "未设置名称"
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ data.item.username }} -
                      {{ data.item.phone }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="offerItem.reviewer"
                :search-input.sync="searchAudit"
                label="选择核价助理"
                item-text="personal.name"
                item-value="_id"
                :rules="[rules.required]"
                :items="auditList"
                outlined
                dense
                prepend-icon="support_agent"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      data.item.personal.name
                        ? data.item.personal.name
                        : "未设置名称"
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ data.item.username }} -
                      {{ data.item.phone }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="linkedHeaders"
                :items="linkedProduct"
                item-key="index"
                class="elevation-0 mt-4"
                hide-default-footer
                no-data-text="请关联或新增产品"
                :items-per-page="100"
              >
                <template v-slot:top>
                  <v-subheader>报价产品</v-subheader>

                  <v-btn
                    v-if="!isTemplate"
                    color="primary"
                    dark
                    small
                    class="ml-4"
                    @click="openProductDlg"
                  >
                    <v-icon small>link</v-icon>
                    关联产品
                  </v-btn>
                  <v-btn
                    v-if="!isTemplate"
                    color="primary"
                    dark
                    small
                    class="ml-4"
                    @click="editProInfo('')"
                  >
                    <v-icon small>add</v-icon>
                    新增产品
                  </v-btn>
                </template>
                <template v-slot:item.name="{ item }">
                  <span @click="editProInfo(item)" style="cursor: pointer">
                    <v-icon small class="mr-2">edit</v-icon>
                    <span>{{ item.product_code || item.name }}</span>
                  </span>
                  <!-- <span
                    v-else
                    style="cursor: pointer"
                    @click="editProInfo(item)"
                  >
                    <v-icon small class="mr-2">edit</v-icon>
                    <span>{{ item.product_code || item.name }}</span>
                  </span> -->
                </template>
                <template v-slot:item.isWarranty="{ item }">
                  <v-switch
                    v-model="item.isWarranty"
                    :label="item.isWarranty ? '是' : '否'"
                    color="secondary"
                  ></v-switch>
                </template>
                <template v-slot:item.quantity="{ item }">
                  {{ item.quantity }}
                </template>
                <template v-slot:item.official="{ item }">
                  <span>¥{{ Number(item.official_price).toFixed(2) }}</span>
                </template>
                <template v-slot:item.purchase="{ item }">
                  <span>¥{{ Number(item.purchase_price).toFixed(2) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="secondary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        small
                        icon
                        @click="removeLinked(item)"
                      >
                        <v-icon>remove</v-icon>
                      </v-btn>
                    </template>
                    <span>移除</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            width="30%"
            :loading="loading"
            @click="submitOffer"
            :disabled="!validOffer || !linkedProduct.length"
            large
          >
            <v-icon small class="mr-2">mdi-calculator</v-icon>
            保存
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog v-model="proInfoDlg" persistent scrollable width="700">
      <widgets-product-dialogs
        :id="activeId"
        :key="activeId"
        v-on:Close="proInfoDlg = false"
      ></widgets-product-dialogs>
    </v-dialog>
    <v-dialog v-model="newDialog" scrollable persistent max-width="600px">
      <v-form v-model="validNewPro" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span>新增产品</span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mb-4">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newProInfo.name"
                  label="产品名称"
                  :rules="[rules.required]"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="newProInfo.part_number"
                  :rules="[rules.required]"
                  @input="checkItem"
                  :error-messages="errPartNo"
                  type="text"
                  label="产品型号"
                  :loading="isCheckProLoading"
                  hint="请输入产品型号 如：MGXC2CH/A"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="newProInfo.product_vendor"
                  :items="vendorList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  return-object
                  outlined
                  dense
                  label="产品供应商"
                  hint="请选择产品供应商"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="newProInfo.product_type"
                  :items="typeList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  return-object
                  outlined
                  dense
                  clearable
                  label="产品类型"
                  hint="请选择产品类型"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newProInfo.quantity"
                  label="数量"
                  :rules="[rules.required]"
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newProInfo.official_price"
                  type="number"
                  label="官网价格"
                  :rules="[rules.required]"
                  outlined
                  dense
                  prefix="¥"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newProInfo.purchase_price"
                  type="number"
                  label="采购价"
                  :rules="[rules.required]"
                  outlined
                  dense
                  prefix="¥"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newProInfo.SV._1_year_ratio"
                  type="number"
                  label="1年余值比例"
                  :rules="[rules.required]"
                  outlined
                  dense
                  suffix="%"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newProInfo.SV._2_year_ratio"
                  type="number"
                  label="2年余值比例"
                  :rules="[rules.required]"
                  outlined
                  dense
                  suffix="%"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newProInfo.SV._3_year_ratio"
                  type="number"
                  label="3年余值比例"
                  :rules="[rules.required]"
                  outlined
                  dense
                  suffix="%"
                ></v-text-field>
              </v-col>

              <v-col cols="4" justify="center">
                <v-switch
                  v-model="newProInfo.isWarranty"
                  color="secondary"
                  class="mt-0"
                >
                  <template v-slot:label>
                    <span class="body-2">{{
                      newProInfo.isWarranty ? "是否延保：是" : "是否延保：否"
                    }}</span>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                          small
                          class="ml-6"
                        >
                          info
                        </v-icon>
                      </template>
                      <span class="caption"
                        >请联系MDAAS产品负责人获取延保单价</span
                      >
                    </v-tooltip>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newProInfo.warranty._2_year_inside"
                  type="number"
                  label="2年延保成本单价"
                  :rules="[rules.required]"
                  outlined
                  dense
                  prefix="¥"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newProInfo.warranty._3_year_inside"
                  type="number"
                  label="3年延保成本单价"
                  :rules="[rules.required]"
                  outlined
                  dense
                  prefix="¥"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="closeEditPro">
              取消
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary darken-1"
              text
              @click="saveEditPro"
              :disabled="!validNewPro"
            >
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dlgEnterprise" persistent scrollable max-width="600px">
      <v-form v-model="validEnterprise" @submit.prevent="submitEmployee">
        <v-card>
          <v-card-title>
            <span class="headline">创建新客户</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dlgEnterprise = false"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="10">
                <v-text-field
                  v-model="newEnterprise.editedItem.name"
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="客户名称"
                  hint="请输入客户的名称，该名称为全局唯一命名"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="10">
                <v-text-field
                  v-model="newEnterprise.editedItem.name_code"
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="客户简称"
                  hint="请输入客户的简称，该简称来自S1系统。"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="10">
                <v-text-field
                  v-model="newEnterprise.editedItem.contact_name"
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="联系人"
                  hint="请输入客户的联系人，联系人不公开仅用于经销商与客户联系使用"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="10">
                <v-text-field
                  v-model="newEnterprise.editedItem.contact_phone"
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="联系电话"
                  hint="请输入企业的联系电话，联系电话不公开仅用于经销商与客户联系使用"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="10">
                <v-text-field
                  v-model="newEnterprise.editedItem.address"
                  :error-messages="newEnterprise.editedItem.msgAddress"
                  :clearable="newEnterprise.editedItem.msgAddress !== ''"
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="详细地址"
                  hint="请输入完整的地址，地址必须按正规格式输入"
                >
                  <template v-slot:append>
                    <v-fade-transition leave-absolute>
                      <v-progress-circular
                        v-if="newEnterprise.editedItem.chkAddress"
                        width="2"
                        size="24"
                        color="secondary"
                        indeterminate
                      ></v-progress-circular>
                    </v-fade-transition>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="dlgEnterprise = false">取消</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="submitCreateEnterprise"
              :disabled="!validEnterprise"
              >创建</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="productDialog" scrollable max-width="800px">
      <v-card>
        <v-card-title>
          <span>关联产品</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="query.key"
            label="搜索产品"
            outlined
            dense
            prepend-icon="search"
            hide-details
            @keydown.enter="searchProduct"
            clearable
          ></v-text-field>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            v-model="chooseProduct"
            :headers="productHeaders"
            :items="productList"
            :options.sync="optionsItem"
            :server-items-length="productCount"
            item-key="product_id"
            class="elevation-0 mt-4"
            show-select
            :footer-props="{ 'items-per-page-options': [5, 10, 15] }"
          >
            <template v-slot:item.name="{ item }">
              <td
                nowrap="true"
                class="text-truncate"
                width="220"
                style="cursor: pointer"
                @click="openProInfo(item.product_id)"
              >
                {{ item.product_code || item.name }}
              </td>
            </template>
            <template v-slot:item.official="{ item }">
              <span>¥{{ Number(item.official_price).toFixed(2) }}</span>
            </template>
            <template v-slot:item.purchase="{ item }">
              <span>¥{{ Number(item.purchase_price).toFixed(2) }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="closeChoose">
            关闭
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" text @click="linkChoose" :disabled="!chooseProduct.length">
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import _ from "lodash";
import Util from "@/common/util";
import store from "@/store";
import {
  INIT_RENT_QUOTE,
  FETCH_ENTERPRISE_LIST,
  FETCH_ACCOUNT_LIST,
  CHECK_ADDR,
  PUBLISH_ENTERPRISE,
  FETCH_PRODUCT_LIST,
  PUBLISH_RENT_QUOTE,
  FETCH_RENT_QUOTE,
  FETCH_PRODUCTTYPE_LIST,
  FETCH_VENDOR_LIST,
  CHECK_PRODUCT,
  FETCH_PRODUCT,
} from "@/store/actions.type";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
export default {
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        phonenum: (value) => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || "请输入正确的电话号码";
          } else {
            return true;
          }
        },
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        length: (value) => value.length < 16 || "最多16个字符",
      },
      check: {
        chkAddressMessage: "",
        chkAddressLoading: false,
      },
      startMenu: "",
      startDate: new Date().toISOString().substr(0, 10),
      payDateMenu: "",
      payDate: new Date().toISOString().substr(0, 10),
      leaseItems: [1, 2, 3],
      salesmanItem: ["销售员A", "销售员B", "销售员C", "销售员D"],
      helperItems: ["助理A", "助理B", "助理C", "助理D"],
      leaseTime: "",
      isDelaySelect: [
        {
          name: "是",
          value: true,
        },
        {
          name: "否",
          value: false,
        },
      ],
      delayOrNot: "",
      comDialog: false,
      isClientV: false,
      changedrawer: false,
      headers: [
        { text: "产品", value: "name" },
        { text: "官网价格", value: "price" },
        { text: "采购价", value: "costPrice" },
        { text: "到期残值", value: "leftValue" },
        { text: "是否延保", value: "isDelay" },
        { text: "延保单价", value: "delayPrice" },
        { text: "操作", value: "actions" },
      ],
      productHeaders: [
        { text: "产品", value: "name", width: "150" },
        // { text: "是否延保", value: "isDelay", width: "100" },
        { text: "产品类别", value: "product_type.name", width: "100" },
        { text: "品牌", value: "product_vendor.name", width: "100" },
        { text: "官网价格", value: "official", width: "100" },
        { text: "采购价", value: "purchase", width: "100" },
        // { text: "1年到期余值", value: "oneLeft", width: "120" },
        // { text: "2年到期余值", value: "twoLeft", width: "120" },
        // { text: "3年到期余值", value: "threeLeft", width: "120" },
        // { text: "2年延保单价", value: "twoDelay", width: "120" },
        // { text: "3年延保单价", value: "threeDelay", width: "120" },
      ],
      linkedHeaders: [
        { text: "产品", value: "name" },
        // { text: "是否延保", value: "isWarranty" },
        { text: "产品类别", value: "product_type.name", width: "100" },
        { text: "品牌", value: "product_vendor.name", width: "100" },
        { text: "数量", value: "quantity" },
        { text: "官网价格", value: "official" },
        { text: "采购价", value: "purchase" },
        // { text: "1年到期余值", value: "oneLeft" },
        // { text: "2年到期余值", value: "twoLeft" },
        // { text: "3年到期余值", value: "threeLeft" },
        // { text: "2年延保单价", value: "twoDelay" },
        // { text: "3年延保单价", value: "threeDelay" },
        { text: "移除", value: "actions", sortable: false },
      ],
      typeLinkedHeaders: [
        { text: "产品", value: "name" },
        { text: "是否延保", value: "isWarranty" },
        { text: "数量", value: "quantity" },
        { text: "官网价格", value: "official" },
        { text: "采购价", value: "purchase" },
        { text: "1年到期余值", value: "oneLeft", align: " d-none" },
        { text: "2年到期余值", value: "twoLeft", align: " d-none" },
        { text: "3年到期余值", value: "threeLeft", align: " d-none" },
        { text: "2年延保单价", value: "twoDelay" },
        { text: "3年延保单价", value: "threeDelay" },
        { text: "移除", value: "actions" },
      ],
      defaultLinkedHeaders: [
        { text: "产品", value: "name" },
        { text: "是否延保", value: "isWarranty" },
        { text: "数量", value: "quantity" },
        { text: "官网价格", value: "official" },
        { text: "采购价", value: "purchase" },
        { text: "1年到期余值", value: "oneLeft" },
        { text: "2年到期余值", value: "twoLeft" },
        { text: "3年到期余值", value: "threeLeft" },
        { text: "2年延保单价", value: "twoDelay" },
        { text: "3年延保单价", value: "threeDelay" },
        { text: "移除", value: "actions" },
      ],
      productDialog: false,
      newDialog: false,
      selected: [],
      styleItems: ["融资租赁", "经营性租赁"],
      payItems: ["月付", "季付"],
      loading: false,
      caDialog: false,
      singlePro: true,
      switch1: true,
      query: {
        key: "",
        template: "",
      },
      offerItem: {
        title: "",
        enterprise: "",
        rent_type: "",
        lease_term: "",
        lease_start: "",
        payment_cycle: "",
        payment_day: "",
        payment_period: "",
        product_list: [],
        reviewer: "",
        creator: "",
      },
      isEnterpriseLoading: false,
      searchEnterprise: null,
      enterpriseList: [],
      disSelectSales: false,
      salesList: [],
      auditList: [],
      searchSales: null,
      searchAudit: null,
      loadingEmployee: false,
      dlgEnterprise: false,
      validEnterprise: true,
      newEnterprise: {
        editedItem: {
          name: "",
          name_code: "",
          contact_name: "",
          contact_phone: "",
          address: "",
          addressInfo: "",
          msgAddress: "",
          chkAddress: false,
        },
        defaultItem: {
          name: "",
          name_code: "",
          contact_name: "",
          contact_phone: "",
          address: "",
          addressInfo: "",
          msgAddress: "",
          chkAddress: false,
        },
      },
      productList: [],
      linkedProduct: [],
      linkedHadProduct: [],
      proInfoDlg: false,
      activeId: "",
      validNewPro: true,
      newProInfo: {
        isWarranty: false,
        quantity: 1,
        product_vendor: "",
        product_type: "",
        part_number: "",
        name: "",
        official_price: "",
        purchase_price: "",
        SV: {
          _1_year_ratio: 0,
          _2_year_ratio: 0,
          _3_year_ratio: 0,
        },
        warranty: {
          _2_year_inside: 0,
          _3_year_inside: 0,
        },
      },
      defaultProInfo: {
        isWarranty: false,
        quantity: 1,
        product_vendor: "",
        product_type: "",
        part_number: "",
        name: "",
        official_price: "",
        purchase_price: "",
        SV: {
          _1_year_ratio: 0,
          _2_year_ratio: 0,
          _3_year_ratio: 0,
        },
        warranty: {
          _2_year_inside: 0,
          _3_year_inside: 0,
        },
      },
      editedIndex: -1,
      validOffer: true,
      linkedList: [],
      isTemplate: false,
      optionsItem: {},
      hasProId: "",
      isRentPro: true,
      isCheckProLoading: false,
      chooseProduct: [],
      errPartNo: "",
      oldPartNo: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_PRODUCTTYPE_LIST, "isEnable=true"),
      store.dispatch(FETCH_VENDOR_LIST, "limit=-1&isEnable=true"),
    ]).then(() => {
      next();
    });
  },
  mounted() {
    this.initQuote();
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "enterprise",
      "init_rent_info",
      "productCount",
      "vendorList",
      "typeList",
    ]),
    rentType() {
      return Util.categories("rentType");
    },
    leaseTerm() {
      return Util.categories("leaseTerm");
    },
    paymentCycle() {
      return Util.categories("paymentCycle");
    },
  },
  created() {},
  watch: {
    optionsItem: {
      handler() {
        this.getProductList(this.query);
      },
      deep: true,
    },
    searchEnterprise(val) {
      if (this.countDown) {
        clearTimeout(this.countDown);
      }
      this.countDown = setTimeout(() => {
        if (this.isEnterpriseLoading) return;
        this.isEnterpriseLoading = true;
        store
          .dispatch(FETCH_ENTERPRISE_LIST, "&key=" + val)
          .then((data) => {
            this.enterpriseList = data.enterprise;
            this.isEnterpriseLoading = false;
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
            this.isEnterpriseLoading = false;
          });
      }, 500);
    },
    searchSales(val) {
      if (this.inputTimer) {
        clearTimeout(this.inputTimer);
      }
      this.inputTimer = setTimeout(() => {
        if (this.loadingEmployee) return;
        this.loadingEmployee = true;
        store
          .dispatch(FETCH_ACCOUNT_LIST, "&key=" + val)
          .then((data) => {
            if (data) {
              const { employees } = data;
              this.entriesEmployee = employees;
            }
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
          })
          .finally(() => (this.loadingEmployee = false));
      }, 500);
    },
    searchAudit(val) {
      if (this.inputTimer) {
        clearTimeout(this.inputTimer);
      }
      this.inputTimer = setTimeout(() => {
        if (this.loadingEmployee) return;
        this.loadingEmployee = true;
        store
          .dispatch(FETCH_ACCOUNT_LIST, "&key=" + val)
          .then((data) => {
            if (data) {
              const { employees } = data;
              this.entriesEmployee = employees;
            }
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
          })
          .finally(() => (this.loadingEmployee = false));
      }, 500);
    },
    "newEnterprise.editedItem.address"() {
      this.checkAddressEnterprise();
    },
  },
  methods: {
    linkChoose() {
      let pros = this.linkedProduct.concat(this.chooseProduct)
      let res = new Map()
      this.linkedProduct = pros.filter((pros) => !res.has(pros.part_number) && res.set(pros.part_number, 1))
      this.closeChoose()
    },
    closeChoose() {
      this.chooseProduct = []
      this.productDialog = false
    },
    openProductDlg() {
      this.getProductList(this.query);
      this.productDialog = true;
    },
    initQuote() {
      if (this.$route.query.templateId) {
        if (this.$route.query.type === "template") {
          this.query.template = this.$route.query.templateId;
          this.isTemplate = true;
        } else if (this.$route.query.type === "copyTemplate") {
          this.isTemplate = true;
        }
      }
      Promise.all([
        store.dispatch(INIT_RENT_QUOTE, this.query.template).then((res) => {
          let sales = res.sales_roles.join(",");
          let audit = res.audit_roles.join(",");
          if (res.product_list.length) {
            let pl = res.product_list.map((item) => {
              let j = {};
              if (item.product_id) {
                j.product_id = item.product_id;
              }

              j.SV = item.SV;
              j.quantity = item.quantity;
              j.isWarranty = item.isWarranty;
              j.name = item.name;
              if (item.product_code) j.product_code = item.product_code;
              j.official_price = item.official_price.$numberDecimal;
              j.purchase_price = item.purchase_price.$numberDecimal;
              j.warranty = {};
              j.warranty._2_year_inside =
                item.warranty._2_year_inside.$numberDecimal;
              j.warranty._3_year_inside =
                item.warranty._3_year_inside.$numberDecimal;
              j.product_vendor = item.product_vendor || item.vendor || ''
              j.product_type = item.product_type || ''
              return j;
            });
            this.linkedProduct = JSON.parse(JSON.stringify(pl));
          } else {
            if (
              this.$route.query.offerId &&
              this.$route.query.type === "copy"
            ) {
              this.getProductList(this.query.key);
              this.getOffer();
            } else if (
              this.$route.query.offerId &&
              this.$route.query.type === "copyTemplate"
            ) {
              this.getOffer();
            } else {
              this.getProductList(this.query.key);
            }
          }
          store.dispatch(FETCH_ACCOUNT_LIST, "roles=" + sales).then((res) => {
            this.salesList = res.employees;
            let currentUser = this.currentUser;
            if (
              currentUser.hasAdmin.includes("dealer") ||
              currentUser.permission.includes("Lease_Product") ||
              currentUser.permission.includes("Lease_Admin")
            ) {
              this.disSelectSales = false;
            } else {
              this.disSelectSales = true;
              this.offerItem.creator = currentUser._id;
            }
          });
          store.dispatch(FETCH_ACCOUNT_LIST, "roles=" + audit).then((res) => {
            this.auditList = res.employees;
          });
        }),
      ]).catch((err) => {
        console.log(err);
      });
    },
    openCreateEnterprise() {
      this.$nextTick(() => {
        this.newEnterprise.editedItem = Object.assign(
          {},
          this.newEnterprise.defaultItem
        );
      });
      this.dlgEnterprise = true;
    },
    checkAddressEnterprise: _.debounce(function () {
      if (this.newEnterprise.editedItem.address) {
        this.newEnterprise.editedItem.chkAddress = true;
        this.$store
          .dispatch(CHECK_ADDR, this.newEnterprise.editedItem.address)
          .then((data) => {
            if (data.status !== 0 || data.result.reliability < 7) {
              this.newEnterprise.editedItem.msgAddress =
                "地址信息错误，请输入正确的地址信息";
            } else {
              this.newEnterprise.editedItem.addressInfo = data.result;
              this.newEnterprise.editedItem.msgAddress = "";
            }
            this.newEnterprise.editedItem.chkAddress = false;
          })
          .catch((err) => {
            this.newEnterprise.editedItem.msgAddress = err.data.message;
            this.newEnterprise.editedItem.chkAddress = false;
          });
      }
    }, 500),
    submitCreateEnterprise() {
      store
        .dispatch(PUBLISH_ENTERPRISE, this.newEnterprise.editedItem)
        .then(({ data }) => {
          this.enterpriseList.push(data);
          this.offerItem.enterprise = data._id;
          this.dlgEnterprise = false;
          store.commit(SET_ERROR, { msg: "创建新客户成功", color: "primary" });
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, { msg: err });
        });
    },
    getProductList() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      let url =
        "isRent=true&page=" + page + "&limit=" + itemsPerPage + "&sort=" + sort;
      if (this.query.key) {
        url += "&key=" + this.query.key;
      }
      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_PRODUCT_LIST, url).then((data) => {
          this.productList = data.product.map((item) => {
            let j = {};
            j.product_id = item._id;
            j.isWarranty = false;
            j.quantity = 1;
            j.part_number = item.part_number;
            j.name = item.name;
            if (item.product_code) j.product_code = item.product_code;
            j.official_price = item.rentInfo.official_price.$numberDecimal;
            j.purchase_price = item.rentInfo.purchase_price.$numberDecimal;
            j.SV = item.rentInfo.SV;
            j.product_type = item.product_type;
            j.product_vendor = item.vendor || "";
            j.warranty = {};
            j.warranty._2_year_inside =
              item.rentInfo.warranty._2_year_inside.$numberDecimal;
            j.warranty._3_year_inside =
              item.rentInfo.warranty._3_year_inside.$numberDecimal;
            return j;
          });
        });
      }
    },
    getOffer() {
      store
        .dispatch(FETCH_RENT_QUOTE, { query: this.$route.query.offerId })
        .then((data) => {
          this.offerItem.payment_day = data.payment_day;
          this.offerItem.rent_type = data.rent_type.toString();
          this.offerItem.lease_start = new Date(data.lease_start)
            .toISOString()
            .substr(0, 10);
          this.offerItem.lease_term = data.lease_term;
          this.offerItem.payment_cycle = data.payment_cycle.toString();
          this.offerItem.payment_period = data.payment_period;
          let pl = data.product_list.map((item) => {
            let j = {};
            if (item.product_id) {
              j.product_id = item.product_id;
            }
            j.SV = item.SV;
            j.SV._1_year_ratio = parseFloat(j.SV._1_year_ratio * 100).toFixed(2)
            j.SV._2_year_ratio = parseFloat(j.SV._2_year_ratio * 100).toFixed(2)
            j.SV._3_year_ratio = parseFloat(j.SV._3_year_ratio * 100).toFixed(2)
            j.quantity = item.quantity;
            j.isWarranty = item.isWarranty;
            j.name = item.name;
            if (item.product_code) j.product_code = item.product_code;
            j.official_price = item.official_price.$numberDecimal;
            j.purchase_price = item.purchase_price.$numberDecimal;
            j.part_number = item.part_number
            j.product_type = item.product_type;
            j.product_vendor = item.vendor || item.product_vendor || '';
            j.warranty = {};
            j.warranty._2_year_inside =
              item.warranty._2_year_inside.$numberDecimal;
            j.warranty._3_year_inside =
              item.warranty._3_year_inside.$numberDecimal;
            return j;
          });
          this.linkedProduct = JSON.parse(JSON.stringify(pl));
        });
    },
    searchProduct() {
      this.getProductList(this.query.key);
    },
    removeLinked(pro) {
      let proIndex = this.linkedProduct.indexOf(pro);
      if (proIndex > -1) {
        this.linkedProduct.splice(proIndex, 1);
      }
    },
    openProInfo(id) {
      this.activeId = id;
      this.proInfoDlg = true;
    },
    editProInfo(item) {
      if (item) {
        this.newProInfo = JSON.parse(JSON.stringify(item));
        this.newProInfo.SV._1_year_ratio = parseFloat(
          (this.newProInfo.SV._1_year_ratio * 100).toFixed(2)
        );
        this.newProInfo.SV._2_year_ratio = parseFloat(
          (this.newProInfo.SV._2_year_ratio * 100).toFixed(2)
        );
        this.newProInfo.SV._3_year_ratio = parseFloat(
          (this.newProInfo.SV._3_year_ratio * 100).toFixed(2)
        );
        this.editedIndex = this.linkedProduct.indexOf(item);
        this.oldPartNo = item.part_number
      }
      this.newDialog = true;
    },
    saveEditPro() {
      if (this.editedIndex > -1) {
        let num = -1;
        this.linkedProduct.map((item) => {
          if (
            item.part_number &&
            item.part_number === this.newProInfo.part_number
          )
            num += 1;
        });
        if (num > -1) {
          if (this.oldPartNo === this.newProInfo.part_number) {
            this.newProInfo.SV._1_year_ratio = parseFloat(
              (this.newProInfo.SV._1_year_ratio / 100).toFixed(4)
            );
            this.newProInfo.SV._2_year_ratio = parseFloat(
              (this.newProInfo.SV._2_year_ratio / 100).toFixed(4)
            );
            this.newProInfo.SV._3_year_ratio = parseFloat(
              (this.newProInfo.SV._3_year_ratio / 100).toFixed(4)
            );
            Object.assign(
              this.linkedProduct[this.editedIndex],
              this.newProInfo
            );
            this.closeEditPro();
          } else {
            this.errPartNo = "重新填写";
            store.commit(SET_ERROR, {
              msg: "列表中已包含此型号产品",
              color: "primary",
            });
          }
        } else {
          this.newProInfo.SV._1_year_ratio = parseFloat(
            (this.newProInfo.SV._1_year_ratio / 100).toFixed(4)
          );
          this.newProInfo.SV._2_year_ratio = parseFloat(
            (this.newProInfo.SV._2_year_ratio / 100).toFixed(4)
          );
          this.newProInfo.SV._3_year_ratio = parseFloat(
            (this.newProInfo.SV._3_year_ratio / 100).toFixed(4)
          );
          Object.assign(this.linkedProduct[this.editedIndex], this.newProInfo);
          this.closeEditPro();
        }
      } else {
        let num = -1;
        this.linkedProduct.map((item) => {
          if (
            item.part_number &&
            item.part_number === this.newProInfo.part_number
          )
            num += 1;
        });
        if (num > -1) {
          this.errPartNo = "重新填写";
          store.commit(SET_ERROR, {
            msg: "关联列表中已包含此型号产品",
            color: "primary",
          });
        } else {
          this.newProInfo.SV._1_year_ratio = parseFloat(
            (this.newProInfo.SV._1_year_ratio / 100).toFixed(4)
          );
          this.newProInfo.SV._2_year_ratio = parseFloat(
            (this.newProInfo.SV._2_year_ratio / 100).toFixed(4)
          );
          this.newProInfo.SV._3_year_ratio = parseFloat(
            (this.newProInfo.SV._3_year_ratio / 100).toFixed(4)
          );
          this.linkedProduct.push(this.newProInfo);
          this.closeEditPro();
        }
      }
    },
    checkItem(val) {
      this.errPartNo = "";
      if (val == "") {
        clearTimeout(this.countDownPro);
        return;
      } else {
        if (this.countDownPro) {
          clearTimeout(this.countDownPro);
        }
        this.countDownPro = setTimeout(() => {
          if (this.isCheckProLoading) return;
          this.isCheckProLoading = true;
          store
            .dispatch(CHECK_PRODUCT, "isRent=true&partno=" + val)
            .then((data) => {
              this.proBox = data.product;
              if (this.proBox.length) {
                let pro = this.proBox[0]
                if (!this.newProInfo.name) this.newProInfo.name = pro.name
                if (!this.newProInfo.product_vendor) this.newProInfo.product_vendor = pro.vendor
                if (!this.newProInfo.product_type) this.newProInfo.product_type = pro.product_type
                if (!this.newProInfo.official_price) this.newProInfo.official_price = pro.rentInfo.official_price.$numberDecimal
                if (!this.newProInfo.purchase_price) this.newProInfo.purchase_price = pro.rentInfo.purchase_price.$numberDecimal
                if (!this.newProInfo.SV._1_year_ratio) this.newProInfo.SV._1_year_ratio = parseFloat(pro.rentInfo.SV._1_year_ratio*100).toFixed(2)
                if (!this.newProInfo.SV._2_year_ratio) this.newProInfo.SV._2_year_ratio = parseFloat(pro.rentInfo.SV._2_year_ratio*100).toFixed(2)
                if (!this.newProInfo.SV._3_year_ratio) this.newProInfo.SV._3_year_ratio = parseFloat(pro.rentInfo.SV._3_year_ratio*100).toFixed(2)
                if (!this.newProInfo.warranty._2_year_inside) this.newProInfo.warranty._2_year_inside = pro.rentInfo.warranty._2_year_inside.$numberDecimal
                if (!this.newProInfo.warranty._3_year_inside) this.newProInfo.warranty._3_year_inside = pro.rentInfo.warranty._3_year_inside.$numberDecimal
              }
              this.isCheckProLoading = false;
            })
            .catch((error) => {
              this.isCheckProLoading = false;
              store.commit(SET_ERROR, { msg: error });
            });
        }, 500);
      }
    },
    clearPartNo() {
      this.newProInfo.part_number = "";
    },
    linkPresent() {
      let num = -1;
      this.linkedProduct.map((item) => {
        if (item.part_number === this.newProInfo.part_number) num += 1;
      });
      if (num > -1) {
        store.commit(SET_ERROR, {
          msg: "关联列表中已包含此产品",
          color: "primary",
        });
      } else {
        store.dispatch(FETCH_PRODUCT, this.hasProId).then((data) => {
          let pro = JSON.parse(JSON.stringify(data));

          let j = {};
          j.product_id = pro._id;
          j.isWarranty = false;
          j.quantity = 1;
          j.part_number = pro.part_number;
          j.name = pro.name;
          if (pro.product_code) j.product_code = pro.product_code;
          j.official_price = pro.rentInfo.official_price.$numberDecimal;
          j.purchase_price = pro.rentInfo.purchase_price.$numberDecimal;
          j.product_type = pro.product_type;
          j.product_vendor = pro.vendor || "";
          j.SV = pro.rentInfo.SV;
          j.warranty = {};
          j.warranty._2_year_inside =
            pro.rentInfo.warranty._2_year_inside.$numberDecimal;
          j.warranty._3_year_inside =
            pro.rentInfo.warranty._3_year_inside.$numberDecimal;

          this.linkedProduct.push(j);
          this.closeEditPro();
          this.hasProId = "";
        });
      }
    },
    createUnrealId() {
      let uId = new Date().getTime() + Math.floor(Math.random() * 100);
      return uId;
    },
    closeEditPro() {
      this.newDialog = false;
      this.$nextTick(() => {
        this.newProInfo = JSON.parse(JSON.stringify(this.defaultProInfo));
        this.editedIndex = -1;
      });
    },
    async submitOffer() {
      let products = JSON.parse(JSON.stringify(this.linkedProduct));
      if (this.isTemplate) {
        this.offerItem.template = this.$route.query.templateId;
      }
      this.offerItem.payment_cycle = parseInt(this.offerItem.payment_cycle);
      this.offerItem.payment_period = parseInt(this.offerItem.payment_period);
      this.offerItem.rent_type = parseInt(this.offerItem.rent_type);
      this.offerItem.product_list = products;
      store
        .dispatch(PUBLISH_RENT_QUOTE, this.offerItem)
        .then((res) => {
          store.commit(SET_ERROR, { msg: "报价创建成功！", color: "primary" });
          this.$router.push("/dealer/workbench/rent/offer?offerId="+res._id);
        })
        .catch(({ err }) => {
          store.commit(SET_ERROR, { msg: err.response.data.message });
        });
    },
  },
};
</script>

<style scoped>
.footerList {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
</style>
