<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="secondary white--text headline">
        后台管理首页
      </v-card-title>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {},
  directives: {}
}
</script>
