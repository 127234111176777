<template>
  <div class="fluid">
    <v-data-table
      :headers="headerTable"
      :items="productList"
      :options.sync="optionsTable"
      :server-items-length="productCount"
      :loading="productLoading"
      loading-text="数据读取中... 请稍后"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      :item-class="itemRowBackground"
      fixed-header
      height="600"
      item-key="_id"
      transition
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>产品列表</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="query.key"
            label="关键字搜索（按回车）"
            prepend-inner-icon="search"
            :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
            solo
            flat
            dense
            hide-details
            clearable
            @keydown.enter="searchItem"
            @click:clear="clearSearchItem"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="ma-2" depressed color="secondary">
                <v-icon>add</v-icon> 创建产品
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="editItem()">
                <v-list-item-icon>
                  <v-icon>add_box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>通过表单增加单个产品</v-list-item-title>
              </v-list-item>
              <v-list-item @click="importItem()">
                <v-list-item-icon>
                  <v-icon>library_add</v-icon>
                </v-list-item-icon>
                <v-list-item-title>通过文件批量增加产品</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-tooltip bottom v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Asset_Manage')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click.stop="exportItem" :loading="!exportEnd">
                <v-icon small>save_alt</v-icon>
              </v-btn>
            </template>
            <span>导出数据</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click.stop="openFilter">
                <v-icon small>filter_list</v-icon>
              </v-btn>
            </template>
            <span>过滤数据</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:header.scale>
        <v-icon size="18" class="mx-2" @click="headerScale = !headerScale">{{headerScale ? 'keyboard_backspace' : 'keyboard_tab'}}</v-icon>
      </template>
      <template v-slot:item.name="{ item }">
        <td @click="openItem(item._id)" class="text-truncate" :style="headerScale ? '' : 'max-width: 125px;'">{{ item.name }}</td>
      </template>
      <template v-slot:item.product_code="{ item }">
        <td nowrap="true" class="text-truncate">{{item.product_code}}</td>
      </template>
      <template v-slot:item.product_type="{ item }">
        <td nowrap="true">{{item.product_type ? item.product_type.name : ''}}</td>
      </template>
      <template v-slot:item.part_number="{ item }">
        <td nowrap="true" class="text-truncate">{{item.part_number}}</td>
      </template>
      <template v-slot:item.vendor="{ item }">
        <td nowrap="true" class="text-truncate">{{item.vendor ? item.vendor.name : ''}}</td>
      </template>
      <template v-slot:item.actions="{ item }">
        <td nowrap="true">
          <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon small @click="activeItem(item, true)" v-if="!item.isEnable">check_circle_outline</v-icon>
          <v-icon small @click="activeItem(item, false)" v-else>block</v-icon>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" depressed @click="getDataList()">刷新</v-btn>
      </template>
    </v-data-table>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">所属模块</h2>
        <v-chip-group v-model="query.filterItem" column multiple @change="setFilter">
          <v-chip v-for="module in ProductModule" :key="module.code" :value="module.code" filter label outlined>{{module.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">是否启用</h2>
        <v-chip-group v-model="query.isEnable" column mandatory @change="setFilter">
          <v-chip v-for="enable in ProductEnable" :key="enable.code" :value="enable.code" filter label outlined>{{enable.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">厂商品牌</h2>
        <v-autocomplete
          class="mt-2"
          v-model="query.vendor"
          :items="vendorList"
          @change="setFilter"
          item-text="name"
          item-value="_id"
          autocomplete="off"
          outlined
          dense
          label="厂商品牌"
          hint="请选择产品厂商品牌"
          chips
          deletable-chips
          multiple
          small-chips
        >
        </v-autocomplete>
        <h2 class="subtitle-2">产品类型</h2>
        <v-chip-group v-model="query.type" column multiple @change="setFilter">
          <v-chip v-for="item in typeList" :key="item._id" :value="item._id" filter label outlined>{{item.name}}</v-chip>
        </v-chip-group>
      </v-card-text>
    </v-navigation-drawer>
    <v-dialog v-model="dlgEdit"  persistent max-width="650px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[rules.required]"
                  type="text"
                  label="产品名称"
                  hint="请输入产品名称"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.product_code"
                  type="text"
                  label="产品简称"
                  hint="请输入产品简称"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="editedItem.vendor"
                  :items="vendorList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  label="品牌厂商"
                  hint="请选择产品品牌厂商"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="editedItem.product_type"
                  :items="typeList"
                  :rules="[rules.selected]"
                  @change="setFields()"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  label="产品类型"
                  hint="请选择产品类型"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.part_number"
                  :rules="[rules.required]"
                  @change="checkItem()"
                  type="text"
                  label="产品型号"
                  hint="请输入产品型号 如：MGXC2CH/A"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.model_number"
                  type="text"
                  label="部件号"
                  hint="请输入产品部件号 如：A1389"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.erp_code"
                  type="text"
                  label="ERP物料号"
                  hint="请输入ERP中物料编码"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.remarks"
                  label="产品描述"
                  rows="2"
                  hint="请输入产品详细信息"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="3" class="mt-n6 mb-4">
                <v-checkbox
                  v-model="editedItem.selected"
                  :rules="[rules.selected]"
                  label="设备服务"
                  value="default"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="3" class="mt-n6 mb-4">
                <v-checkbox
                  v-model="editedItem.selected"
                  :rules="[rules.selected]"
                  label="维修服务"
                  value="repair"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="3" class="mt-n6 mb-4">
                <v-checkbox
                  v-model="editedItem.selected"
                  :rules="[rules.selected]"
                  label="订单服务"
                  value="order"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="3" class="mt-n6 mb-4">
                <v-checkbox
                  v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Lease_Product') || currentUser.permission.includes('Lease_Admin') || currentUser.permission.includes('Lease_Audit')"
                  v-model="editedItem.selected"
                  :rules="[rules.selected]"
                  label="租赁服务"
                  value="rent"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="6" v-if="editedItem.selected.includes('order')">
                <v-text-field
                  v-model="editedItem.sales_price"
                  :rules="[rules.required, rules.negative]"
                  label="销售价格"
                  outlined
                  dense
                  min="0"
                  step="0.01"
                  type="number"
                  prefix="¥"
                  suffix="元"
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="editedItem.selected.includes('default')">
                <v-autocomplete
                  autocomplete="off"
                  v-model="editedItem.ramSize"
                  :items="ramSize"
                  label="内存大小"
                  hint="请选择内存大小"
                  item-text="name"
                  item-value="code"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" v-if="editedItem.selected.includes('default')">
                <v-autocomplete
                  autocomplete="off"
                  v-model="editedItem.networkModel"
                  :items="networkModel"
                  label="网络制式"
                  hint="请选择网络制式"
                  item-text="name"
                  item-value="code"
                  outlined
                  dense
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" v-if="editedItem.selected.includes('default')">
                <v-autocomplete
                  v-model="editedItem.guarantee_year"
                  :items="[0,1,2,3,4,5,6,8,9,10]"
                  autocomplete="off"
                  outlined
                  dense
                  label="保修期：年"
                  hint="年"
                ></v-autocomplete>
              </v-col>
              <v-col cols="3" v-if="editedItem.selected.includes('default')">
                <v-autocomplete
                  v-model="editedItem.guarantee_month"
                  :items="[0,1,2,3,4,5,6,8,9,10,11]"
                  autocomplete="off"
                  outlined
                  dense
                  label="保修期：月"
                  hint="月"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-for="(item, i) in customFields" :key="i">
              <v-col cols="12" v-if="item.type === 'text'">
                <v-text-field
                  v-if="item.mask"
                  v-model="item.value"
                  v-mask="item.mask"
                  :rules="item.rules ? item.rules.map(d => rules[d]) : []"
                  :label="item.name"
                  outlined
                  dense>
                </v-text-field>
                <v-text-field
                  v-else
                  v-model="item.value"
                  :label="item.name"
                  :rules="item.rules ? item.rules.map(d => rules[d]) : []"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="item.type === 'selects'">
                <v-autocomplete
                  autocomplete="off"
                  v-model="item.value"
                  :items="item.items"
                  :label="item.name"
                  :rules="item.rules ? item.rules.map(d => rules[d]) : []"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" v-if="editedItem.selected.includes('rent')">
              <v-col cols="4" class="mt-n6">
                <v-text-field
                  v-model="editedItem.rentInfo.official_price"
                  label="官网价格"
                  outlined
                  dense
                  min="0"
                  step="0.01"
                  type="number"
                  prefix="¥"
                  suffix="元"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n6">
                <v-text-field
                  v-model="editedItem.rentInfo.purchase_price"
                  :rules="[rules.required, rules.negative]"
                  label="采购价格"
                  outlined
                  dense
                  min="0"
                  step="0.01"
                  type="number"
                  prefix="¥"
                  suffix="元"
                ></v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n12">
                <div>采购折扣比例：{{parseFloat((editedItem.rentInfo.purchase_price / editedItem.rentInfo.official_price*100).toFixed(2))}}%</div>
              </v-col>
              <v-col cols="4" class="mt-n6">
                <v-text-field
                  v-model="editedItem.rentInfo.SV._1_year_ratio"
                  :rules="[rules.required]"
                  type="number"
                  label="1年余值比例"
                  suffix="%"
                  :hint="'1年到期余值：'+ parseFloat((editedItem.rentInfo.official_price > 0 ? editedItem.rentInfo.official_price * parseFloat((editedItem.rentInfo.SV._1_year_ratio/100).toFixed(4)) : editedItem.rentInfo.purchase_price * parseFloat((editedItem.rentInfo.SV._1_year_ratio/100).toFixed(4))).toFixed(2))"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n6">
                <v-text-field
                  v-model="editedItem.rentInfo.SV._2_year_ratio"
                  :rules="[rules.required]"
                  type="number"
                  label="2年余值比例"
                  suffix="%"
                  :hint="'2年到期余值：'+ parseFloat((editedItem.rentInfo.official_price > 0 ? editedItem.rentInfo.official_price * parseFloat((editedItem.rentInfo.SV._2_year_ratio/100).toFixed(4)) : editedItem.rentInfo.purchase_price * parseFloat((editedItem.rentInfo.SV._2_year_ratio/100).toFixed(4))).toFixed(2))"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n6">
                <v-text-field
                  v-model="editedItem.rentInfo.SV._3_year_ratio"
                  :rules="[rules.required]"
                  type="number"
                  label="3年余值比例"
                  suffix="%"
                  :hint="'3年到期余值：'+ parseFloat((editedItem.rentInfo.official_price > 0 ? editedItem.rentInfo.official_price * parseFloat((editedItem.rentInfo.SV._3_year_ratio/100).toFixed(4)) : editedItem.rentInfo.purchase_price * parseFloat((editedItem.rentInfo.SV._3_year_ratio/100).toFixed(4))).toFixed(2))"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n2">
                <v-text-field
                  v-model="editedItem.rentInfo.SV._1_year_recycle"
                  :rules="[rules.required]"
                  type="number"
                  label="1年回收残值"
                  prefix="¥"
                  suffix="元"
                  :hint="'1年回收比例：'+ parseFloat((editedItem.rentInfo.official_price > 0 ? editedItem.rentInfo.SV._1_year_recycle / editedItem.rentInfo.official_price * 100 : editedItem.rentInfo.SV._1_year_recycle / editedItem.rentInfo.purchase_price * 100).toFixed(2)) +'%'"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n2">
                <v-text-field
                  v-model="editedItem.rentInfo.SV._2_year_recycle"
                  :rules="[rules.required]"
                  type="number"
                  label="2年回收残值"
                  prefix="¥"
                  suffix="元"
                  :hint="'2年回收比例：'+ parseFloat((editedItem.rentInfo.official_price > 0 ? editedItem.rentInfo.SV._2_year_recycle / editedItem.rentInfo.official_price * 100 : editedItem.rentInfo.SV._2_year_recycle / editedItem.rentInfo.purchase_price * 100).toFixed(2)) +'%'"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="mt-n2">
                <v-text-field
                  v-model="editedItem.rentInfo.SV._3_year_recycle"
                  :rules="[rules.required]"
                  type="number"
                  label="3年回收残值"
                  prefix="¥"
                  suffix="元"
                  :hint="'3年回收比例：'+ parseFloat((editedItem.rentInfo.official_price > 0 ? editedItem.rentInfo.SV._3_year_recycle / editedItem.rentInfo.official_price * 100 : editedItem.rentInfo.SV._3_year_recycle / editedItem.rentInfo.purchase_price * 100).toFixed(2)) +'%'"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3" class="mt-n2">
                <v-text-field
                  v-model="editedItem.rentInfo.warranty._2_year_inside"
                  :rules="[rules.required, rules.negative]"
                  type="number"
                  label="2年延保单价"
                  prefix="¥"
                  suffix="元"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="mt-n2">
                <v-text-field
                  v-model="editedItem.rentInfo.warranty._3_year_inside"
                  :rules="[rules.required, rules.negative]"
                  type="number"
                  label="3年延保单价"
                  prefix="¥"
                  suffix="元"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="mt-n2">
                <v-text-field
                  v-model="editedItem.rentInfo.warranty._2_year_retail"
                  :rules="[rules.required, rules.negative]"
                  type="number"
                  label="2年延保零售"
                  prefix="¥"
                  suffix="元"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3" class="mt-n2">
                <v-text-field
                  v-model="editedItem.rentInfo.warranty._3_year_retail"
                  :rules="[rules.required, rules.negative]"
                  type="number"
                  label="3年延保零售"
                  prefix="¥"
                  suffix="元"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <input id="files" type="file" name="file" ref="uploadInput" accept="image/*" multiple @change="detectFiles($event)" />
                <v-subheader>产品图片</v-subheader>
                <v-row align="center" justify="center">
                  <v-card v-for="(n, i) in this.editedItem.pictures" :key="i" class="ma-2" outlined tile>
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-img :src="ossURL+'/'+n" class="ma-2" :max-width="125">
                          <v-fade-transition>
                            <v-overlay v-if="hover" absolute class="align-center" color="white lighten-5">
                              <v-btn icon color="primary" @click="addImage(i)"><v-icon>backup</v-icon></v-btn>
                              <v-btn icon color="primary" @click="removeImage(i)"><v-icon>clear</v-icon></v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </v-img>
                      </template>
                    </v-hover>
                  </v-card>
                  <v-card class="ma-3 pa-6" outlined tile hover @click="addImage(-1)"><v-icon>add</v-icon></v-card>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dlgConflict" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">产品型号{{editedItem.part_number}}已经存在</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="returnEdit">修改原有产品</v-btn>
          <v-btn color="secondary" text @click="clearPartNo">重新填写型号</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgInfo" persistent scrollable width="700">
      <widgets-product-dialogs v-if="activeType === 'product'" :id="activeId" :key="activeId" v-on:Close="closeItem"></widgets-product-dialogs>
      <v-card v-if="activeType === 'import'">
        <v-card-title>
          批量导入
          <v-spacer></v-spacer>
          <v-btn icon @click="closeItem"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <p>1. 下载设备模版，填写产品信息.</p>
                <v-btn text @click="downloadTemplate()">下载模版<v-icon>file_download</v-icon></v-btn>
              </v-col>
              <v-col cols="6">
                <input
                  id="import_file"
                  type="file"
                  name="import_file"
                  ref="uploadImport"
                  accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :multiple="false"
                  @change="importFile($event)"/>
                <p>2. 上传填好的文件.</p>
                <v-btn text @click="uploadImportFile()"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="importEnd">
              <v-col cols="12">
                <v-divider></v-divider>
                <v-alert
                  outlined
                  type="error"
                  text
                >
                  导入失败：<ul><li v-for="(fail,index) in importFailed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
                </v-alert>
                <v-alert
                  outlined
                  type="success"
                  text
                >
                  成功导入：{{importSuccess}} 条记录
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeItem">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {
  ACTIV_PRODUCT,
  CHECK_PRODUCT, EDIT_PRODUCT,
  FETCH_PRODUCT,
  FETCH_PRODUCT_LIST,
  FETCH_PRODUCTTYPE_LIST, FETCH_UPLOAD,
  FETCH_VENDOR_LIST, PUBLISH_PRODUCT
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import Util from "@/common/util";
import {SET_ERROR} from "@/store/mutations.type";
import axios from "axios";
import XLSX from "xlsx";
import ApiService from "@/common/http";

export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
        negative: val => ( val >= 0) || '不能小于0'
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedItem: {
        _id: '',
        name: '',
        product_type: '',
        part_number: '',
        product_code: '',
        erp_code: '',
        model_number: '',
        vendor: '',
        cost_price: 0,
        sales_price: 0,
        guarantee: 12,
        guarantee_year: 1,
        guarantee_month: 0,
        pictures: [],
        customFields: [],
        ramSize: 0,
        networkModel: [],
        isRent: false,
        selected: [],
        remarks: '',
        original_part_number: '',
        rentInfo: {
          official_price: 0.00,
          purchase_price: 0.00,
          SV: {
            _1_year_ratio: 0,
            _2_year_ratio: 0,
            _3_year_ratio: 0,
            _1_year_recycle: 0.00,
            _2_year_recycle: 0.00,
            _3_year_recycle: 0.00,
          },
          warranty: {
            _2_year_inside: 0,
            _3_year_inside: 0,
            _2_year_retail: 0,
            _3_year_retail: 0,
          }
        }
      },
      defaultItem: {
        _id: '',
        name: '',
        product_type: '',
        part_number: '',
        product_code: '',
        erp_code: '',
        model_number: '',
        vendor: '',
        cost_price: 0,
        sales_price: 0,
        guarantee: 12,
        guarantee_year: 1,
        guarantee_month: 0,
        pictures: [],
        customFields: [],
        ramSize: 0,
        networkModel: [],
        isRent: false,
        selected: [],
        remarks: '',
        original_part_number: '',
        rentInfo: {
          official_price: 0.00,
          purchase_price: 0.00,
          SV: {
            _1_year_ratio: 0,
            _2_year_ratio: 0,
            _3_year_ratio: 0,
            _1_year_recycle: 0.00,
            _2_year_recycle: 0.00,
            _3_year_recycle: 0.00,
          },
          warranty: {
            _2_year_inside: 0,
            _3_year_inside: 0,
            _2_year_retail: 0,
            _3_year_retail: 0,
          }
        }
      },
      customFields: [],
      filterDrawer: false,
      query: {
        key: '',
        filterItem: ['default','repair','order','rent'],
        partno: '',
        isEnable: true,
        vendor: [],
        type: [],
      },
      defaultQuery: {
        key: '',
        filterItem: ['default','repair','order','rent'],
        partno: '',
        isEnable: true,
        vendor: [],
        type: [],
      },
      headerScale: false,
      headerTable: [
        {text: '名称', width: '125px', value: 'name'},
        {text: '', value: 'scale', active: true, sortable: false, width: '20px', class: 'px-0'},
        {text: '产品型号', width: '125px', value: 'part_number'},
        {text: '简称', width: '125px', value: 'product_code'},
        {text: 'ERP物料号', width: '125px', value: 'erp_code'},
        {text: '品牌厂商', width: '125px', value: 'vendor'},
        {text: '产品类型', width: '125px', value: 'product_type'},
        {text: '操作', width: '85px', value: 'actions', sortable: false}
      ],
      optionsTable: {},
      Upload: {
        fileIndex: -1,
        uploading: false,
        progressUpload: 0,
      },
      dlgInfo: false,
      activeType: 'product',
      activeId: null,
      dlgConflict: false,
      importExcelHeader: ['*产品全称', '产品简称', '*产品类型', '*供应商（品牌）', '*产品型号', 'ERP物料号', '部件号',
        '设备服务可用', '维修服务可用', '订单服务可用', '租赁服务可用',
        '内存大小', '网路制式', '保修期：月', '销售单价',
        '官网价格', '采购价格', '1年余值比例', '2年余值比例', '3年余值比例', '1年回收残值', '2年回收残值', '3年回收残值',
        '2年延保单价', '3年延保单价', '2年延保零售', '3年延保零售'],
      importExcelNotice: [['请使用此模版批量导入产品，*为必填项目。']],
      importSuccess: 0,
      importFailed: [],
      importEnd: false,
      exportEnd: true,
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_PRODUCTTYPE_LIST, 'limit=-1&isEnable=true'),
      store.dispatch(FETCH_VENDOR_LIST, 'limit=-1&isEnable=true'),
    ]).then(() => {
      next();
    });
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "vendorList",
      "typeList",
      "currentEmployer",
      "productLoading",
      "productList",
      "productCount",
      "uploadSign"]),
    ramSize () {
      return Util.categories('ramSize')
    },
    networkModel () {
      return Util.categories('networkModel')
    },
    ProductModule () {
      return Util.categories('ProductModule')
    },
    ProductEnable () {
      return Util.categories('ProductEnable')
    }
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  methods: {
    openFilter() {
      this.filterDrawer = !this.filterDrawer
    },
    setFilter() {
      this.$router.push({ query: {} });
      this.getDataList(this.query)
    },
    searchItem() {
      this.optionsTable.page = 1
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    clearSearchItem() {
      this.optionsTable.page = 1
      this.query.key = ''
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    getDataList() {
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + encodeURIComponent(this.query.key)
      if (this.query.partno) urlQuery += '&partno=' + encodeURIComponent(this.query.partno)
      if (this.query.vendor.length) urlQuery += '&vendor='+ this.query.vendor
      if (this.query.type.length) urlQuery += '&type='+ this.query.type
      if (this.query.filterItem.length) urlQuery += '&selected='+ this.query.filterItem
      if (this.query.isEnable !== '' && this.query.isEnable !== undefined) urlQuery += '&isEnable='+ this.query.isEnable
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_PRODUCT_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    openItem(itemId) {
      this.activeType = 'product'
      this.activeId = itemId
      this.dlgInfo = true
    },
    setFields() {
      let selectedItem = this.typeList.find(type => type._id === this.editedItem.product_type)
      this.customFields = selectedItem.customFields
      this.$forceUpdate()
    },
    activeItem(item, reply) {
      if (confirm(`确定要 ${reply ? '启用' : '禁用'} ${item.name}？`)) {
        store.dispatch(ACTIV_PRODUCT, {_id: item._id, reply})
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
    closeItem() {
      this.dlgInfo = false
    },
    editItem(item) {
      if (item) {
        store.dispatch(FETCH_PRODUCT, item._id)
          .then((data) => {
            this.editedItem = Object.assign({}, data)
            this.editedItem.original_part_number = this.editedItem.part_number
            if (!this.editedItem.selected.length) this.editedItem.selected.push('default')
            this.editedItem.cost_price = data.cost_price.$numberDecimal;
            this.editedItem.sales_price = data.sales_price.$numberDecimal;
            this.editedItem.guarantee_year = parseInt(data.guarantee / 12);
            this.editedItem.guarantee_month = data.guarantee % 12;
            this.editedItem.ramSize = data.it_info.ram;
            this.editedItem.networkModel = data.it_info.network;
            let selectedItem = this.typeList.find(type => type._id === this.editedItem.product_type._id)
            this.customFields = selectedItem.customFields
            this.customFields.forEach(field => {
              let beforeField = this.editedItem.customFields.find(v => v._id === field._id)
              if (beforeField) field['value'] = beforeField.value
            })
            if (data.isRent && !this.editedItem.selected.includes('rent')) this.editedItem.selected.push('rent')
            if (data.rentInfo) {
              this.editedItem.rentInfo = Object.assign({}, data.rentInfo)
              this.editedItem.rentInfo.official_price = data.rentInfo.official_price.$numberDecimal
              this.editedItem.rentInfo.purchase_price = data.rentInfo.purchase_price.$numberDecimal
              this.editedItem.rentInfo.SV._1_year_ratio = parseFloat((data.rentInfo.SV._1_year_ratio*100).toFixed(4))
              this.editedItem.rentInfo.SV._2_year_ratio = parseFloat((data.rentInfo.SV._2_year_ratio*100).toFixed(4))
              this.editedItem.rentInfo.SV._3_year_ratio = parseFloat((data.rentInfo.SV._3_year_ratio*100).toFixed(4))
              this.editedItem.rentInfo.SV._1_year_recycle = data.rentInfo.SV._1_year_recycle.$numberDecimal
              this.editedItem.rentInfo.SV._2_year_recycle = data.rentInfo.SV._2_year_recycle.$numberDecimal
              this.editedItem.rentInfo.SV._3_year_recycle = data.rentInfo.SV._3_year_recycle.$numberDecimal
              this.editedItem.rentInfo.warranty._2_year_inside = data.rentInfo.warranty._2_year_inside.$numberDecimal
              this.editedItem.rentInfo.warranty._3_year_inside = data.rentInfo.warranty._3_year_inside.$numberDecimal
              this.editedItem.rentInfo.warranty._2_year_retail = data.rentInfo.warranty._2_year_retail.$numberDecimal
              this.editedItem.rentInfo.warranty._3_year_retail = data.rentInfo.warranty._3_year_retail.$numberDecimal
            }
          })
      } else {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.customFields = []
      }
      this.dlgTitle = item ? '编辑产品' : '新增产品'
      this.dlgEdit = true
    },
    checkItem() {
      if (this.editedItem.part_number) {
        if (!this.editedItem.original_part_number || this.editedItem.part_number !== this.editedItem.original_part_number) {
          store.dispatch(CHECK_PRODUCT, 'partno='+ encodeURIComponent(this.editedItem.part_number))
            .then((data) => {
              if (data.query.count > 0) this.dlgConflict = true
            })
            .catch((error) => {
              store.commit(SET_ERROR, { msg: error })
            })
        }
      }
    },
    clearPartNo() {
      this.editedItem.part_number = ''
      this.dlgConflict = false
    },
    returnEdit() {
      this.query.key = encodeURIComponent(this.editedItem.part_number)
      this.query.filterItem = []
      this.getDataList(this.query)
      this.dlgConflict = false
      this.close()
    },
    async detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      await Promise.all(Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      }))
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', 'product/'+ this.uploadSign.key +'/'+ this.fileName);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };
      await axios.post(this.uploadSign.host, param, config)
        .then(async () => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          if (this.Upload.fileIndex > -1) {
            await this.editedItem.pictures.splice(this.Upload.fileIndex, 1, param.get('key'))
          } else {
            await this.editedItem.pictures.push(param.get('key'));
          }
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
    addImage(index) {
      this.Upload.fileIndex = index
      store.dispatch(FETCH_UPLOAD)
        .then(() => {
          this.$refs.uploadInput.click()
        })
        .catch(err => {
          store.commit(SET_ERROR, { msg: err })
        })
    },
    removeImage(index) {
      this.editedItem.pictures.splice(index, 1)
    },
    downloadTemplate() {
      let ws = XLSX.utils.aoa_to_sheet(this.importExcelNotice)
      XLSX.utils.sheet_add_json(ws, [
        {
          "*产品全称": "iphone 8 plus 128G",
          "产品简称": "iPhone 8",
          "*产品类型": "iPhone",
          "*供应商（品牌）": "Apple",
          "*产品型号": "MXCNAB/TEST",
          "ERP物料号": "",
          "部件号": "A1999",
          "设备服务可用": "是",
          "维修服务可用": "否",
          "订单服务可用": "否",
          "租赁服务可用": "是",
          "内存大小": "128G",
          "网路制式": "蜂窝网络,无线局域网",
          "保修期：月": "12",
          "销售单价": "12888.88",
          "官网价格": "12888.88",
          "采购价格": "10888.88",
          "1年余值比例": "15%",
          "2年余值比例": "10%",
          "3年余值比例": "5%",
          "1年回收残值": "7888.99",
          "2年回收残值": "3488.99",
          "3年回收残值": "1288.99",
          "2年延保单价": "128",
          "3年延保单价": "249",
          "2年延保零售": "1028",
          "3年延保零售": "2049",
        }
      ], {header: this.importExcelHeader, origin: 'A2'})

      const merge = [
        {s: {r:0, c:0}, e: {r:0, c:this.importExcelHeader.length - 1}}
      ]
      ws['!merges'] = merge
      ws.A1.s = {alignment:{ wrapText: true }};
      let wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Import_Product')
      XLSX.writeFile(wb, 'ESP_产品导入模版.xlsx')

    },
    uploadImportFile() {
      this.$refs.uploadImport.click()
    },
    submit() {
      let strInsert = {}
      strInsert.name = this.editedItem.name
      if (typeof this.editedItem.product_type === "object") {
        strInsert.product_type = this.editedItem.product_type._id
      } else {
        strInsert.product_type = this.editedItem.product_type
      }
      strInsert.part_number = this.editedItem.part_number
      strInsert.product_code = this.editedItem.product_code
      strInsert.erp_code = this.editedItem.erp_code
      strInsert.model_number = this.editedItem.model_number
      strInsert.remarks = this.editedItem.remarks
      strInsert.pictures = this.editedItem.pictures || []
      if (typeof this.editedItem.vendor === "object") {
        strInsert.vendor = this.editedItem.vendor._id
      } else {
        strInsert.vendor = this.editedItem.vendor
      }
      strInsert.cost_price = parseFloat(this.editedItem.cost_price)
      strInsert.sales_price = parseFloat(this.editedItem.sales_price)
      strInsert.guarantee = this.editedItem.guarantee_year * 12 + this.editedItem.guarantee_month
      strInsert.ramSize = this.editedItem.ramSize
      strInsert.networkModel = this.editedItem.networkModel
      if (this.customFields.length) {
        strInsert.customFields = []
        this.customFields.forEach(item => {
          if (item.value) strInsert.customFields.push({ _id: item._id, value: item.value })
        })
      }
      strInsert.selected = this.editedItem.selected
      strInsert.isRent = this.editedItem.selected.includes('rent') || false
      if (strInsert.isRent) {
        strInsert.rentInfo = Object.assign({}, this.editedItem.rentInfo)
        strInsert.rentInfo.SV._1_year_ratio = parseFloat((strInsert.rentInfo.SV._1_year_ratio/100).toFixed(4))
        strInsert.rentInfo.SV._2_year_ratio = parseFloat((strInsert.rentInfo.SV._2_year_ratio/100).toFixed(4))
        strInsert.rentInfo.SV._3_year_ratio = parseFloat((strInsert.rentInfo.SV._3_year_ratio/100).toFixed(4))
      }
      if (this.editedItem._id) {
        strInsert._id = this.editedItem._id
        store.dispatch(EDIT_PRODUCT, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.query.key = ''
            this.query.filterItem = []
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_PRODUCT, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.query.key = ''
            this.query.filterItem = []
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },
    close () {
      this.dlgTitle = ''
      this.dlgEdit = false
      store.dispatch(FETCH_PRODUCTTYPE_LIST, 'limit=-1&isEnable=true')
      store.dispatch(FETCH_VENDOR_LIST, 'limit=-1&isEnable=true')
      this.editedItem.pictures.length = 0
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    importItem() {
      this.activeType = 'import'
      this.dlgInfo = true
    },
    importFile(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importProduct(fileList[x]);
      })
    },
    importProduct(file) {
      let param = new FormData();
      param.append('file', file);
      ApiService.post('/product/import', param)
        .then((data) => {
          store.dispatch(FETCH_PRODUCT_LIST)
          this.importSuccess = data.data.Success
          this.importFailed = data.data.Failed
          this.$refs.uploadImport.value = '';
          this.importEnd = true
        })
        .catch((err) => {
          this.$refs.uploadImport.value = '';
          this.importSuccess = 0
          this.importFailed = []
          this.importEnd = true
          let arrErr = [];
          if (err.response.data) {
            if (err.response.data.code === 11000) {
              arrErr = err.response.data.writeErrors.map(obj => { return obj.op.personal.name });
            }
          }
          if (arrErr.length > 0) {
            this.$store.commit(SET_ERROR, {msg: '请检查以下产品数据：'+ arrErr});
          } else {
            this.importFailed = [{line: 2, error: err.response.data.message}]
          }
        });
    },
    exportItem(){
      if (this.exportEnd) {
        this.exportFile(this.query)
      }
    },
    exportFile() {
      this.exportEnd = false
      const {sortBy, sortDesc} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + encodeURIComponent(this.query.key)
      if (this.query.partno) urlQuery += '&partno=' + encodeURIComponent(this.query.partno)
      if (this.query.vendor.length) urlQuery += '&vendor='+ this.query.vendor
      if (this.query.type.length) urlQuery += '&type='+ this.query.type
      if (this.query.filterItem.length) urlQuery += '&selected='+ this.query.filterItem
      if (this.query.isEnable !== '' && this.query.isEnable !== undefined) urlQuery += '&isEnable='+ this.query.isEnable

      if (Object.keys(this.optionsTable).length > 0) {
        ApiService.download('/product/export?sort=' + sort + urlQuery, {
          responseType:'blob'
        })
          .then(resp => {
            let fileName = 'ESP产品清单.xlsx';
            let contentDisposition = resp.headers['content-disposition'];
            if (contentDisposition) {
              fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
            }
            let blob = new Blob([resp.data], {type: 'application/octet-stream'});
            if (window.navigator.msSaveOrOpenBlob) { //支持IE
              navigator.msSaveBlob(blob, fileName);
            } else {
              let link = document.createElement('a');
              link.style.display = "none";
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              this.exportEnd = true
              //释放内存
              window.URL.revokeObjectURL(link.href);
              document.body.removeChild(link);
            }
          })
          .catch(err => { // 请求失败处理
            this.exportEnd = true
            store.commit(SET_ERROR, {msg: err});
          });
      }

    },
    itemRowBackground: function (item) {
      if (!item.isEnable) return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
    },
  }
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>

