var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.stateList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.stateCount,
      "loading": _vm.stateLoading,
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "loading-text": "数据读取中... 请稍后",
      "item-key": "name",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("设备状态列表")]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer'), _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "outlined": "",
            "fab": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('create_item', '', '新增设备状态');
            }
          }
        }, [_c('v-icon', [_vm._v("add")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          class: item.isEnable ? '' : 'text-decoration-line-through'
        }, [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.isEdit ? _c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('edit_item', item, '编辑设备状态');
            }
          }
        }, [_vm._v("edit")]) : _vm._e(), !item.isEnable && item.isEdit ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_item', item, true);
            }
          }
        }, [_vm._v("check_circle_outline")]) : _vm._e(), item.isEnable && item.isEdit ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_item', item, false);
            }
          }
        }, [_vm._v("block")]) : _vm._e()];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("Reset")])];
      },
      proxy: true
    }])
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "状态名称",
      "hint": "请输入设备状态名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }