<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>企业管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="doAction('search_enterprise')"
          dark
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索企业名称"
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
        {{enterpriseCount}}家企业
        <v-btn icon @click.stop="doAction('filter_enterprise')">
          <v-icon>filter_list</v-icon>
        </v-btn>
        <v-btn icon @click="doAction('create_enterprise')">
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-row class="fill-height overflow-y-auto" v-if="enterpriseList.length">
      <v-col lg="4" md="4" sm="6" cols="12" v-for="(enterprise, index) in enterpriseList" :key="enterprise._id">
          <v-card outlined hover :ripple="false" :color="enterprise.isBlock ? 'red lighten-4' : ''">
            <v-card-title>
<!--              <span class="d-inline-block text-truncate"><v-badge :color="enterprise.isBlock ? 'red' : 'green'" inline dot></v-badge>{{enterprise.name}}</span>-->
              <span class="d-inline-block text-truncate">{{enterprise.name}}</span>
              </v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon small>contact_phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle>{{enterprise.contact_name}} - {{enterprise.contact_phone}}</v-list-item-subtitle>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-icon>
                    <v-icon small>room</v-icon>
                  </v-list-item-icon>
                  <v-list-item-subtitle>{{enterprise.city}} - {{enterprise.address}}</v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-card-actions>
<!--              <v-badge :color="enterprise.isBlock ? 'red' : 'green'" inline dot></v-badge><v-subheader>{{enterprise.createTime | formatDate}} 创建</v-subheader>-->
              <v-spacer></v-spacer>
              <!-- <v-btn icon @click="openRisk(index)">
                <v-icon v-if="index === 1" color="red">new_releases</v-icon>
                <v-icon v-else>verified</v-icon>
              </v-btn> -->
              <v-btn icon @click="openInventory(index)" v-if="enterprise.inventory_warning && enterprise.inventory_warning.length">
                <v-icon v-if="enterprise.inventory_warning.find(item => item.minimum >= item.count)" color="red">battery_charging_full</v-icon>
                <v-icon v-else>battery_full</v-icon>
              </v-btn>
              <v-btn icon @click="openService(index)" v-if="enterprise.service_catalog && enterprise.service_catalog.length">
                <v-icon v-if="enterprise.service_catalog.find(item => diffDays(item.end_time) > 0)">handshake</v-icon>
                <v-icon v-else-if="enterprise.service_catalog.find(item => diffDays(item.end_time) > -168)" color="red">handshake</v-icon>
                <v-icon v-else-if="enterprise.service_catalog.find(item => diffDays(item.end_time) > -720)" color="orange">handshake</v-icon>
                <v-icon v-else>handshake</v-icon>
              </v-btn>
              <v-btn icon @click="doAction('open_enterprise', enterprise)"><v-icon>read_more</v-icon></v-btn>
            </v-card-actions>
            <v-expand-transition>
              <v-card v-if="cardRisk[index]" class="transition-fast-in-fast-out v-card--reveal overflow-y-auto" style="height: 100%;">
                <v-card-title>授信额度<v-spacer></v-spacer><v-icon @click="closeRisk(index)">close</v-icon></v-card-title>
                <v-card-text>
                  <v-list subheader dense>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>paid</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-if="index === 1">25,000.00</span>
                          <span v-else>5,200,000.00</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          授信额度
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon @click="openCreateRisk(enterprise)">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon>production_quantity_limits</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-if="index === 1" class="red--text">36,125.00</span>
                          <span v-else>1,259,910.00</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          当期应收
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row>
                          <v-col>
                            <p>M1</p>
                            <p>1,000,000.00</p>
                          </v-col>
                          <v-col>
                            <p>M2</p>
                            <p>250,000.00</p>
                          </v-col>
                          <v-col>
                            <p>M3以上</p>
                            <p>9,910.00</p>
                          </v-col>
                        </v-row>
                        <!-- <v-list-item-title>
                          <span v-if="index === 1" class="red--text">36,125.00</span>
                          <span v-else>1,000,000.00</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          M1
                        </v-list-item-subtitle> -->
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-expand-transition>
            <v-expand-transition>
              <v-card v-if="cardService[index]" class="transition-fast-in-fast-out v-card--reveal overflow-y-auto" style="height: 100%;">
                <v-card-title>服务协议<v-spacer></v-spacer><v-icon @click="closeService(index)">close</v-icon></v-card-title>
                <v-card-text>
                  <v-list subheader dense>
                    <v-list-item v-for="(service, i) in enterprise.service_catalog" :key="'service_'+ i">
                      <v-list-item-icon>
                        <v-icon v-if="diffDays(service.end_time) > 0">remove_circle</v-icon>
                        <v-icon v-else-if="diffDays(service.end_time) > -168" color="red">error</v-icon>
                        <v-icon v-else-if="diffDays(service.end_time) > -720" color="orange">circle_notifications</v-icon>
                        <v-icon v-else>check_circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-for="catalog in service.catalog" :key="catalog._id">
                            {{catalog.name}}
                          </span></v-list-item-title>
                        <v-list-item-subtitle>
                          {{ service.end_time | formatDate }} - {{ service.end_time | fromDate }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-expand-transition>
            <v-expand-transition>
              <v-card v-if="cardInventory[index]" class="transition-fast-in-fast-out v-card--reveal overflow-y-auto" style="height: 100%;">
                <v-card-title>库存设备<v-spacer></v-spacer><v-icon @click="closeInventory(index)">close</v-icon></v-card-title>
                <v-card-text>
                  <v-list subheader dense>
                    <v-list-item v-for="(inventory, i) in enterprise.inventory_warning" :key="'inventory_'+ i">
                      <v-list-item-icon>
                        <v-icon v-if="inventory.minimum < inventory.count">check_circle</v-icon>
                        <v-icon v-else color="error">error</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ inventory.product.name }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <span :class="inventory.minimum >= inventory.count ? 'error--text' : ''">库存：{{inventory.count}}</span>
                          - 预警：{{inventory.minimum}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
<!--                <v-card-actions>-->
<!--                  <v-spacer></v-spacer>-->
<!--                  <v-btn text @click="closeInventory(index)">关闭</v-btn>-->
<!--                </v-card-actions>-->
              </v-card>
            </v-expand-transition>
          </v-card>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-progress-circular
          v-if="this.query.page * this.query.limit < enterpriseCount"
          indeterminate
          color="secondary"
          width="2"
          v-intersect="{
          handler: infiniteScrolling,
          options: {
              threshold: [1.0]
          }
        }"
        ></v-progress-circular>
        <span v-else class="title font-weight-light">已展示{{enterpriseCount}}家企业信息，没有更多数据了。</span>
      </v-col>
    </v-row>
    <v-row v-else>
      <span class="bg"></span>
    </v-row>
    <v-fab-transition>
      <v-btn
        class="transition-swing"
        v-scroll="onScroll"
        v-show="fab"
        color="secondary"
        fab
        dark
        fixed
        bottom
        right
        large
        @click="toTop"
        style="z-index: 6"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">隶属集团</h2>
        <v-autocomplete class="mt-2" v-model="query.ent_group" :items="entGroupList" item-text="name"
          item-value="_id" autocomplete="off" outlined dense label="隶属集团" hint="请选择隶属集团" chips deletable-chips multiple
          small-chips>
        </v-autocomplete>
        <h2 class="subtitle-2 mt-2">企业类型</h2>
        <v-chip-group v-model="query.corp_type" column multiple>
          <v-chip v-for="typef in corpTypedef" :key="typef.code" :value="typef.code" filter label outlined>{{typef.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">企业状态</h2>
        <v-chip-group v-model="query.isBlock" column multiple>
          <v-chip :value="false" filter label outlined>正常运营</v-chip>
          <v-chip :value="true" filter label outlined>暂停运营</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">包含服务</h2>
        <v-chip-group v-if="catalogList.length" v-model="query.service_catalog" column multiple>
          <v-chip v-for="catalog in flatGroupCatalog(catalogList).filter(c => c['group'])" :key="catalog._id" :value="catalog._id" filter label outlined>{{catalog.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">服务到期</h2>
        <v-chip-group v-model="query.end_time" column>
          <v-chip v-for="datetiem in expiryDate" :key="datetiem.code" :value="datetiem.code" filter label outlined>{{datetiem.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">信用额度</h2>
        <v-chip-group column>
          <v-chip v-for="item in creditType" :key="item.code" :value="item.code" filter label outlined>{{item.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">应收预警</h2>
        <v-chip-group column>
          <v-chip v-for="item in receivableWarning" :key="item.code" :value="item.code" filter label outlined>{{item.name}}</v-chip>
        </v-chip-group>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doAction('clear_filter')">清空</v-btn>
          <v-btn color="secondary" text @click="doAction('set_filter')">确认</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
      <widgets-enterprise-dialogs :id="activeId" :key="activeId" v-on:Close="doAction('close_info')"></widgets-enterprise-dialogs>
    </v-dialog>
    <v-dialog v-model="dialogRiskTip" width="500">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">信用审批<v-spacer></v-spacer><v-icon @click="closeCreateRisk()">close</v-icon></v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  您正在为企业「{{riskName}}」 进行授信审批申请，基于企业已存资料，还需要补充以下内容：
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="risk.editedItem.businessType"
                    :items="businessTypeList"
                    item-text="name"
                    item-value="code"
                    autocomplete="off"
                    outlined
                    label="业务类型"
                    hint="请选择业务类型"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    v-model="risk.editedItem.isG500"
                    label="500强企业"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="risk.editedItem.cusType"
                    :items="cusTypeList"
                    :rules="[rules.required]"
                    item-text="name"
                    item-value="code"
                    autocomplete="off"
                    outlined
                    label="公司性质"
                    hint="请选择公司性质"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="risk.editedItem.cusContact"
                    :rules="[rules.required]"
                    type="text"
                    label="客户联系人"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="risk.editedItem.peopleNumber"
                    :rules="[rules.required]"
                    type="number"
                    label="职员人数"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="risk.editedItem.payCondition"
                    :items="payConditionList"
                    :rules="[rules.required]"
                    item-text="name"
                    item-value="code"
                    autocomplete="off"
                    outlined
                    label="付款条件"
                    hint="请选择付款条件"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="risk.editedItem.oldDays"
                    type="number"
                    label="原账期天数"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="risk.editedItem.oldQuota"
                    type="number"
                    label="原额度"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="risk.editedItem.days"
                    :rules="risk.editedItem.payCondition == 1 ? [] : [rules.required]"
                    :disabled="risk.editedItem.payCondition == 1"
                    type="number"
                    label="申请账期"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="risk.editedItem.quota"
                    :rules="risk.editedItem.payCondition == 1 ? [] : [rules.required]"
                    :disabled="risk.editedItem.payCondition == 1"
                    type="number"
                    label="申请额度"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="risk.editedItem.finishCus"
                    :rules="[rules.required]"
                    type="text"
                    label="最终客户"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-textarea
                    v-model="risk.editedItem.enemy"
                    :rules="[rules.required]"
                    outlined
                    auto-grow
                    rows="3"
                    label="主要竞争对手"
                    value=""
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="risk.editedItem.cusDesc"
                    :rules="[rules.required]"
                    outlined
                    auto-grow
                    rows="3"
                    label="客户情况"
                    value=""
                  ></v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-textarea
                    v-model="risk.editedItem.serviceDesc"
                    :rules="[rules.required]"
                    outlined
                    auto-grow
                    rows="3"
                    label="业务机会描述"
                    value=""
                  ></v-textarea>
                </v-col>
                <v-col cols="6">
                  <v-textarea
                    v-model="risk.editedItem.relaCus"
                    outlined
                    auto-grow
                    rows="3"
                    label="关联公司"
                    value=""
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="risk.editedItem.cc"
                      :items="accountList"
                      :rules="[rules.selected]"
                      item-text="personal.name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      chips
                      small-chips
                      deletable-chips
                      label="抄送商务人员"
                      hint="请查找并选择抄送商务人员"
                      multiple
                    >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" depressed @click="submit" :disabled="!valid"><v-icon left>done</v-icon>授信审批</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import Util from '@/common/util';
import { mapGetters } from "vuex";
import moment from "moment";
import store from "@/store";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_ENTERPRISE_LIST,
  FETCH_CATALOG_LIST,
  FETCH_ACCOUNT_LIST,
  PUBLISH_RISK_APPROVAL,
  FETCH_ENTGROUP_LIST,
} from "@/store/actions.type";
export default {
  data: () => ({
    riskName: '',
    isG500: false,
    dialogRiskTip: false,
    businessType: '',
    enterpriseList: [],
    filterDrawer: false,
    fab: false,
    dlgInfo: false,
    valid: true,
    query: {
      key: '',
      ent_group: [],
      corp_type: [],
      isBlock: [],
      service_catalog: [],
      end_time: '',
      page: 1,
      limit: 10,
    },
    defaultQuery: {
      key: '',
      ent_group: [],
      corp_type: [],
      isBlock: [],
      service_catalog: [],
      end_time: '',
      page: 1,
      limit: 10,
    },
    activeId: null,
    cardService: [],
    cardInventory: [],
    cardRisk: [],
    creditType: [
      { code: 1, name: '正常'},
      { code: 2, name: '额度不足'},
      { code: 3, name: '无额度'},
    ],
    receivableWarning: [
      { code: 1, name: '正常'},
      { code: 2, name: '超出信用'}
    ],
    rules: {
      required: value => (value === 0 || !!value) || '请输入内容.',
      selected: value => {
        if (!value || value.length < 1) {
          return '请至少选择一个选项。'
        } else {
          return true;
        }
      },
    },
    risk: {
      editedItem: {
        enterpriseId: '',
        cusType: '',
        cusContact: '',
        peopleNumber: '',
        payCondition: '',
        oldDays: '',
        oldQuota: '',
        days: '',
        quota: '',
        enemy: '',
        cusDesc: '',
        serviceDesc: '',
        finishCus: '',
        relaCus: '',
        cc: [],
      },
    },
  }),
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_CATALOG_LIST),
    ]).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters(["currentUser", "enterpriseLoading", "enterpriseCount", "catalogList", "accountList", 'entGroupList',]),
    corpTypedef() {
      return Util.categories('corpType')
    },
    expiryDate() {
      return Util.categories('expiry')
    },
    businessTypeList () {
      return Util.categories('RiskBusinessType')
    },
    payConditionList () {
      return Util.categories('PayCondition')
    },
    cusTypeList () {
      return Util.categories('CusType')
    },
  },
  async mounted() {
    if (Object.keys(this.$route.query).length > 0) this.query = Object.assign(this.query, this.$route.query)
    await this.getEntGroupList();
  
    this.getList()
  },
  created() {
    this.$store.dispatch(FETCH_ACCOUNT_LIST)
  },
  methods: {
    getList() {
      let strQuery = '';
      strQuery += '&type=0';
      if (this.query.key) strQuery += '&key='+ this.query.key
      if (this.query.isBlock.length && this.query.isBlock.length <= 1) strQuery += '&isBlock='+ this.query.isBlock
      if (this.query.ent_group.length) strQuery += '&ent_group='+ this.query.ent_group;
      if (this.query.corp_type.length) strQuery += '&corp_type='+ this.query.corp_type
      if (this.query.service_catalog.length) strQuery += '&service_catalog='+ this.query.service_catalog
      if (this.query.end_time) strQuery += '&end_time='+ this.query.end_time
      if (this.query.page) strQuery += '&page='+ this.query.page
      if (this.query.limit) strQuery += '&limit='+ this.query.limit
      store.dispatch(FETCH_ENTERPRISE_LIST, strQuery)
        .then((data) => {
          this.enterpriseList.push(...data.enterprise)
          this.cardService = Array(data.enterprise.length).fill(false)
          this.cardInventory = Array(data.enterprise.length).fill(false)
        })
    },
    doAction(action, item = {}) {
      switch (action) {
        case 'create_enterprise': {
          // this.$store.commit(SET_ERROR, {msg: '不能创建'});
          this.$router.push({path:'/dealer/enterprise/edit/0'})
          break
        }
        case 'open_enterprise': {
          this.activeId = item._id
          this.dlgInfo = true
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          break
        }
        case 'search_enterprise': {
          this.query.page = 1;
          this.query.limit = 20;
          this.enterpriseList = [];
          this.cardService = [];
          this.cardInventory = [];
          this.getList();
          break
        }
        case 'filter_enterprise': {
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'set_filter': {
          this.query.page = 1;
          this.query.limit = 20;
          this.enterpriseList = [];
          this.cardService = [];
          this.cardInventory = [];
          this.getList();
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'clear_filter': {
          this.query = Object.assign({}, this.defaultQuery)
          this.enterpriseList = []
          this.cardService = []
          this.cardInventory = []
          this.getList()
          this.filterDrawer = !this.filterDrawer
          break
        }
      }
    },
    infiniteScrolling() {
      this.query.page++;
      this.getList();
    },
    onScroll () {
      if (typeof window === 'undefined') return
      const top = (
        window.pageYOffset ||
        document.documentElement.offsetTop ||
        0
      )
      this.fab = top > 300
    },
    openService(index) {
      this.cardService[index] = true
      this.$forceUpdate()

    },
    closeService(index) {
      this.cardService[index] = false
      this.$forceUpdate()
    },
    openInventory(index) {
      this.cardInventory[index] = true
      this.$forceUpdate()

    },
    closeInventory(index) {
      this.cardInventory[index] = false
      this.$forceUpdate()
    },
    toTop () {
      if (this.$route.hash) {
        this.$router.push({ hash: '' })
      }
      this.$vuetify.goTo(0)
    },
    unique(arrDuplicate) {
      if (arrDuplicate.length) {
        let arrService = [];
        arrDuplicate.forEach(service => {
          service.catalog.forEach(catalog => {
            arrService.push({...catalog, end_time: service.end_time})
          })
        })
        return arrService
      }
    },
    diffDays(endTime) {
      let nowTime = moment();
      return nowTime.diff(endTime, 'hours')
    },
    flatGroupCatalog(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.children.forEach((catalogItem) => {
          arrRoles.push({ _id: catalogItem._id, name: catalogItem.name, group: groupItem.name })
        })
        arrRoles.push({ divider: true })
      })
      return arrRoles
    },
    openRisk(index) {
      this.cardRisk[index] = true
      this.$forceUpdate()
    },
    closeRisk(index) {
      this.cardRisk[index] = false
      this.$forceUpdate()
    },
    openCreateRisk(enterprise) {
      this.dialogRiskTip = true
      this.riskName = enterprise.name;
      this.risk.editedItem.enterpriseId = enterprise._id;
      this.$forceUpdate()
    },
    closeCreateRisk() {
      this.dialogRiskTip = false
      this.riskName = ''
      this.$forceUpdate()
    },
    submit() {
      let riskApproval = {
        enterpriseId: this.risk.editedItem.enterpriseId,
        cusType: this.risk.editedItem.cusType,
        cusContact: this.risk.editedItem.cusContact,
        peopleNumber: parseInt(this.risk.editedItem.peopleNumber),
        payCondition: this.risk.editedItem.payCondition,
        oldDays: parseInt(this.risk.editedItem.oldDays),
        oldQuota: parseFloat(this.risk.editedItem.oldQuota),
        days: parseInt(this.risk.editedItem.days),
        quota: parseFloat(this.risk.editedItem.quota),
        enemy: this.risk.editedItem.enemy,
        cusDesc: this.risk.editedItem.cusDesc,
        serviceDesc: this.risk.editedItem.serviceDesc,
        finishCus: this.risk.editedItem.finishCus,
        relaCus: this.risk.editedItem.relaCus,
        cc: this.risk.editedItem.cc,
      };

      this.$store.dispatch(PUBLISH_RISK_APPROVAL, riskApproval)
        .then(() => {
          this.$store.commit(SET_ERROR, { msg: '创建成功', color: 'primary' });
          this.closeCreateRisk();
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, { msg: err.response.data.message });
        });
    },
    async getEntGroupList() {
      store.dispatch(FETCH_ENTGROUP_LIST, 'limit=-1')
    },
  }
};
</script>
<style scoped>
  .hideText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    background: url( '/static/empty_data.png') no-repeat center center;
    background-size: contain;
    transform: scale(0.15);
  }
  .v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
  }
</style>
