<template>
  <v-container>
    <v-dialog
      :value="!report.sumCount || !caseStatue.data || !report.caseCountYear"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          数据加载中...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row dense v-if="report.sumCount">
      <v-col cols="12" md="6" lg="3">
        <report-card-count icon="business" :updateTime="report.sumCount.createTime" :count="report.sumCount.enterprise" label="入驻企业总数" path="/dealer/enterprise"></report-card-count>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <report-card-count icon="devices" :updateTime="report.sumCount.createTime" :count="report.sumCount.asset" label="已录入设备总数" path="/dealer/asset/list"></report-card-count>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <report-card-count icon="person" :updateTime="report.sumCount.createTime" :count="report.sumCount.order" label="企业订单总数" path="/dealer/workbench/order/list"></report-card-count>
      </v-col>
      <v-col cols="12" md="6" lg="3">
        <report-card-count icon="assignment" :updateTime="report.sumCount.createTime" :count="report.sumCount.case" label="处理请求总数" path="/dealer/workbench"></report-card-count>
      </v-col>
    </v-row>
    <v-row v-if="report">
      <v-col cols="12" md="6" lg="3" v-if="report.enterpriseHistory && report.enterpriseHistory.data">
        <report-card-history :updateTime="report.enterpriseHistory.createTime" :labels="report.enterpriseHistory.data.map(item => item.date)" :value="report.enterpriseHistory.data.map(item => item.count)" title="新增企业"></report-card-history>
      </v-col>
      <v-col cols="12" md="6" lg="3" v-if="report.assetHistory && report.assetHistory.data">
        <report-card-history :updateTime="report.assetHistory.createTime" :labels="report.assetHistory.data.map(item => item.date)" :value="report.assetHistory.data.map(item => item.count)" title="新增设备"></report-card-history>
      </v-col>
      <v-col cols="12" md="6" lg="3" v-if="report.orderHistory && report.orderHistory.data">
        <report-card-history :updateTime="report.orderHistory.createTime" :labels="report.orderHistory.data.map(item => item.date)" :value="report.orderHistory.data.map(item => item.count)" title="企业订单"></report-card-history>
      </v-col>
      <v-col cols="12" md="6" lg="3" v-if="report.caseHistory && report.caseHistory.data">
        <report-card-history :updateTime="report.caseHistory.createTime" :labels="report.caseHistory.data.map(item => item.date)" :value="report.caseHistory.data.map(item => item.count)" title="服务请求"></report-card-history>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-iterator
          :items="caseStatue.data"
          item-key="name"
          :items-per-page="4"
          hide-default-footer
          no-data-text="统计数据分析中..."
        >
          <template v-slot:default="props">
            <v-row>
              <v-col v-for="item in props.items" :key="item.name" cols="12" sm="6" md="4" lg="4">
                <v-card outlined>
                  <v-card-title class="title font-weight-light">
                    {{ item.name }}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item link @click="gotoPath(item.code, 'pending')">
                      <v-list-item-content>
                        <v-list-item-title>处理中:</v-list-item-title>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.pending }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
                    </v-list-item>
                    <v-list-item :link="item.code === 'service'" @click="gotoPath(item.code, 'warning')">
                      <v-list-item-content>
                        <v-list-item-title>即将超期:</v-list-item-title>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                      <v-list-item-content>
                        <v-list-item-title v-if="item.code === 'service'"><span class="orange--text">{{ item.warning }}</span></v-list-item-title>
                      </v-list-item-content>
                      <v-icon v-if="item.code === 'service'" class="subheading font-weight-light">arrow_forward</v-icon>
                    </v-list-item>
                    <v-list-item :link="item.code === 'service'" @click="gotoPath(item.code, 'overdue')">
                      <v-list-item-content>
                        <v-list-item-title>超期处理:</v-list-item-title>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                      <v-list-item-content>
                        <v-list-item-title v-if="item.code === 'service'"><span class="secondary--text">{{ item.overdue }}</span></v-list-item-title>
                      </v-list-item-content>
                      <v-icon v-if="item.code === 'service'" class="subheading font-weight-light">arrow_forward</v-icon>
                    </v-list-item>
                    <v-list-item link @click="gotoPath(item.code, 'complete')">
                      <v-list-item-content>
                        <v-list-item-title>已完成:</v-list-item-title>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.complete }}</v-list-item-title>
                      </v-list-item-content>
                      <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-card-text class="py-1">
                    <v-icon class="mr-2" small>schedule</v-icon>
                    <span class="caption font-weight-light">更新时间：{{caseStatue.createTime | fromDate}}</span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
    <v-row v-if="report">
      <v-col cols=12 v-if="report.caseCountYear && report.caseCountYear.data">
        <v-card outlined class="mx-auto">
          <v-card-title class="title font-weight-light">
            服务请求{{report.caseCountYear.year}}年度热点图
            <v-spacer></v-spacer>
            <v-select
              autocomplete="off"
              v-model="catalogCountYear"
              :items="BusinessType"
              @change="updateHeatMap"
              label="请选择业务类型"
              item-text="name"
              item-value="code"
              hide-details
              outlined
              dense
            ></v-select>
          </v-card-title>
          <v-divider></v-divider>
          <report-heat-map width="100%" height="300px" :data="report.caseCountYear.data" :year="report.caseCountYear.year" :max="report.caseCountYear.maxValue" :updateTime="report.caseCountYear.createTime"></report-heat-map>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <v-icon class="mr-2" small>schedule</v-icon>
            <span class="caption font-weight-light">更新时间：{{report.caseCountYear.createTime | fromDate}}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import store from "@/store";
import {
  FETCH_REPORT_ALL_COUNT,
  FETCH_REPORT_CASE_HISTORY,
  FETCH_REPORT_ASSET_HISTORY,
  FETCH_REPORT_ENTERPRISE_HISTORY,
  FETCH_REPORT_ORDER_HISTORY,
  FETCH_REPORT_CASE_STATUS,
  FETCH_REPORT_CASE_COUNT_YEAR,
} from "@/store/actions.type";
import Util from "@/common/util";

export default {
  data() {
    return {
      report:{
        sumCount: {},
        caseHistory: [],
        assetHistory: [],
        accountHistory: [],
        enterpriseHistory: [],
        caseCountYear: {},
      },
      catalogCountYear: 'service',
      caseStatue: [],
    }
  },
  created() {
    this.initialize()
  },
  computed: {
    BusinessType () {
      return Util.categories('BusinessType')
    },
  },
  methods: {
    async initialize() {
      const sumCount = await store.dispatch(FETCH_REPORT_ALL_COUNT, '?source=cache')
      const caseHistory = await store.dispatch(FETCH_REPORT_CASE_HISTORY, '?source=cache')
      const assetHistory = await store.dispatch(FETCH_REPORT_ASSET_HISTORY, '?source=cache')
      const enterpriseHistory = await store.dispatch(FETCH_REPORT_ENTERPRISE_HISTORY, '?source=cache')
      const orderHistory = await store.dispatch(FETCH_REPORT_ORDER_HISTORY, '?source=cache')
      const caseCountYear = await store.dispatch(FETCH_REPORT_CASE_COUNT_YEAR, '?source=cache')
      const statusList = await store.dispatch(FETCH_REPORT_CASE_STATUS, '?source=cache')

      this.report.sumCount = sumCount
      this.report.caseHistory = caseHistory
      this.report.assetHistory = assetHistory
      this.report.orderHistory = orderHistory
      this.report.enterpriseHistory = enterpriseHistory
      this.report.caseCountYear = caseCountYear
      this.caseStatue = statusList
      this.$forceUpdate()
    },
    flatGroupCatalog(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.children.forEach((catalogItem) => {
          arrRoles.push({ _id: catalogItem._id, name: catalogItem.name, group: groupItem.name, process: catalogItem.process })
        })
        arrRoles.push({ divider: true })
      })
      return arrRoles
    },
    updateHeatMap() {
      if (this.catalogCountYear && this.catalogCountYear.length) {
        store.dispatch(FETCH_REPORT_CASE_COUNT_YEAR, '?source=cache&service='+ this.catalogCountYear)
          .then((data) => {
            this.report.caseCountYear = data
            this.$forceUpdate()
          })
      } else {
        store.dispatch(FETCH_REPORT_CASE_COUNT_YEAR, '?source=cache')
          .then((data) => {
            this.report.caseCountYear = data
            this.$forceUpdate()
          })
      }
    },
    gotoPath(path, query) {
      let dataTime = new Date();
      switch (query) {
        case 'pending': {
          if (path === 'service') this.$router.push({path: '/dealer/workbench/service/list?&status=0,1'})
          if (path === 'repair') this.$router.push({path: '/dealer/workbench/maintain/list?&status=0,1,2,3'})
          if (path === 'consult') this.$router.push({path: '/dealer/workbench/advisory/list?&status=0,1,2,3'})
          break
        }
        case 'complete': {
          if (path === 'service') this.$router.push({path: '/dealer/workbench/service/list?&status=2,4'})
          if (path === 'repair') this.$router.push({path: '/dealer/workbench/maintain/list?&status=4,8'})
          if (path === 'consult') this.$router.push({path: '/dealer/workbench/advisory/list?&status=2,3,4,8'})
          break
        }
        case 'warning': {
          if (path === 'service') this.$router.push({path: '/dealer/workbench/service/list?status=0,1&deadline='+ dataTime.setTime(dataTime.getTime() + (4 * 3600 * 1000))})
          break
        }
        case 'overdue': {
          if (path === 'service') this.$router.push({path: '/dealer/workbench/service/list?status=0,1&deadline='+ dataTime.setDate(dataTime.getDate())})
          break
        }
      }
    }
  }
};
</script>
<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.hideText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
