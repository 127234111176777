var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.vendorList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.vendorCount,
      "loading": _vm.vendorLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "item-class": _vm.itemRowBackground,
      "fixed-header": "",
      "height": "600",
      "item-key": "name",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("品牌厂商列表")]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-text-field', {
          attrs: {
            "label": "关键字搜索（按回车）",
            "prepend-inner-icon": "search",
            "background-color": _vm.$vuetify.theme.dark ? '' : 'grey lighten-4',
            "solo": "",
            "flat": "",
            "dense": "",
            "hide-details": "",
            "clearable": ""
          },
          on: {
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.searchItem.apply(null, arguments);
            },
            "click:clear": _vm.clearSearchItem
          },
          model: {
            value: _vm.query.key,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "key", $$v);
            },
            expression: "query.key"
          }
        }), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('create_vendor', '', '新增品牌厂商');
            }
          }
        }, [_c('v-icon', [_vm._v("add")]), _vm._v(" 新建品牌厂商 ")], 1), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.openFilter.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "header.scale",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "mx-2",
          attrs: {
            "size": "18"
          },
          on: {
            "click": function click($event) {
              _vm.headerScale = !_vm.headerScale;
            }
          }
        }, [_vm._v(_vm._s(_vm.headerScale ? 'keyboard_backspace' : 'keyboard_tab'))])];
      },
      proxy: true
    }, {
      key: "item.address",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          staticClass: "text-truncate",
          style: _vm.headerScale ? '' : 'max-width: 125px;'
        }, [_vm._v(_vm._s(item.address))])];
      }
    }, {
      key: "item.contactName",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', [_vm._v(_vm._s(item.contactName) + " " + _vm._s(item.contactPhone) + " " + _vm._s(item.contactEmail))])];
      }
    }, {
      key: "item.purchaser",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return _vm._l(item.purchaser, function (account) {
          return _c('widgets-employee-dialogs', {
            key: account._id,
            attrs: {
              "account": account._id,
              "divider": "- "
            }
          }, [_vm._v(" " + _vm._s(account.personal.name ? account.personal.name : '*未设置姓名') + " ")]);
        });
      }
    }, {
      key: "item.department",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(_vm._s(item.department ? item.department.name : ''))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('edit_vendor', item, '编辑品牌厂商');
            }
          }
        }, [_vm._v("edit")]), !item.isEnable ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_vendor', item, true);
            }
          }
        }, [_vm._v("check_circle_outline")]) : _vm._e(), item.isEnable ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_vendor', item, false);
            }
          }
        }, [_vm._v("block")]) : _vm._e()];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("重新获取数据")])];
      },
      proxy: true
    }])
  }), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("是否启用")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "mandatory": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.isEnable,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isEnable", $$v);
      },
      expression: "query.isEnable"
    }
  }, _vm._l(_vm.ProductEnable, function (enable) {
    return _c('v-chip', {
      key: enable.code,
      attrs: {
        "value": enable.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(enable.name))]);
  }), 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.dlgTitle))]), _c('v-card-text', {
    staticStyle: {
      "height": "500px"
    }
  }, [_c('v-container', [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "品牌厂商名称",
      "hint": "请输入品牌厂商名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "联系人",
      "hint": "请输入品牌厂商联系人姓名",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.contactName,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "contactName", $$v);
      },
      expression: "editedItem.contactName"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "联系电话",
      "hint": "请输入品牌厂商联系电话",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.contactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "contactPhone", $$v);
      },
      expression: "editedItem.contactPhone"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.email],
      "type": "text",
      "label": "联系邮件",
      "hint": "请输入品牌厂商联系邮件",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.contactEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "contactEmail", $$v);
      },
      expression: "editedItem.contactEmail"
    }
  }), _c('v-text-field', {
    attrs: {
      "error-messages": _vm.editedItem.msgAddress,
      "clearable": _vm.editedItem.msgAddress !== '',
      "type": "text",
      "label": "品牌厂商地址",
      "hint": "品牌厂商实际地址",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-fade-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.editedItem.chkAddress ? _c('v-progress-circular', {
          attrs: {
            "width": "2",
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.editedItem.address,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "address", $$v);
      },
      expression: "editedItem.address"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.getDeptList(_vm.departments[0]),
      "item-text": "name",
      "item-value": "value",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "请选择外采负责部门"
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_employee_list');
      }
    },
    model: {
      value: _vm.editedItem.department,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "department", $$v);
      },
      expression: "editedItem.department"
    }
  }), _c('v-autocomplete', {
    ref: "selectPurchaser",
    attrs: {
      "items": _vm.accountList,
      "disabled": !_vm.selectPurchaser,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "multiple": "",
      "deletable-chips": "",
      "clearable": "",
      "label": "采购人员 空代表部门内人员都可处理外采请求",
      "hint": "请选择外采人员"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.purchaser,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "purchaser", $$v);
      },
      expression: "editedItem.purchaser"
    }
  }), _c('v-alert', {
    attrs: {
      "type": "info"
    }
  }, [_c('h1', {
    staticClass: "text-h6"
  }, [_vm._v("厂商标示")]), _c('div', [_vm._v("将在【维修服务报告】中展示，"), _c('strong', {
    staticClass: "text--secondary"
  }, [_vm._v("请勿使用非正式的图片！")]), _vm._v("默认显示经销商LOGO。")]), _c('div', [_vm._v("建议采用透明或白色背景，黑色前景的图片。")]), _c('v-divider', {
    staticClass: "my-4 info",
    staticStyle: {
      "opacity": "0.22"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-card', {
    staticClass: "d-flex justify-center",
    attrs: {
      "color": "white",
      "flat": "",
      "loading": _vm.Upload.uploading ? 'secondary' : ''
    }
  }, [_c('v-img', {
    staticClass: "gray-img",
    attrs: {
      "src": _vm.ossURL + (_vm.editedItem.imgLogo ? '/' + _vm.editedItem.imgLogo : '/default/logo2x.png'),
      "aspect-ratio": "1",
      "max-width": "120px",
      "contain": ""
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.uploadLogo
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("upload")]), _vm._v("上传")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }