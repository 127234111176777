<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{title_page()}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :disabled="!valid" icon @click="submit">
          <v-icon>save</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="valid" @submit.prevent="submit">
          <v-expansion-panels v-model="openPanel" multiple accordion flat>
            <v-expansion-panel>
              <v-expansion-panel-header>基本信息</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.name"
                      :rules="[rules.required]"
                      type="text"
                      label="模型名称"
                      hint="请输入模型名称"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.businessType"
                      :items="businessTypeList"
                      :rules="[rules.selected]"
                      item-text="name"
                      item-value="code"
                      autocomplete="off"
                      outlined
                      label="业务类型"
                      hint="请选择业务类型"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>区域层面</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.GDPTotalScore1"
                      type="number"
                      label="生产总值得分"
                      hint="地区生产总值（GDP）>= 国内三线（70城）平均地区生产总"
                      outlined
                      prepend-inner-icon="trending_up"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.GDPTotalScore2"
                      type="number"
                      label="生产总值得分"
                      hint="地区生产总值（GDP） < 国内三线（70城）平均地区生产总"
                      outlined
                      prepend-inner-icon="trending_down"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.GDPGrowthRateScore1"
                      type="number"
                      label="GDP增长率得分"
                      hint="地区生产总值增长率 >= 国内GDP增长率"
                      outlined
                      prepend-inner-icon="trending_up"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.GDPGrowthRateScore2"
                      type="number"
                      label="GDP增长率得分"
                      hint="地区生产总值增长率 < 国内GDP增长率"
                      outlined
                      prepend-inner-icon="trending_down"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.GDPPerCapitaScore1"
                      type="number"
                      label="当地人均GDP得分"
                      hint="当地人均GDP(元) >= 全国人均GDP（元）"
                      outlined
                      prepend-inner-icon="trending_up"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.GDPPerCapitaScore2"
                      type="number"
                      label="当地人均GDP得分"
                      hint="当地人均GDP(元) < 全国人均GDP（元）"
                      outlined
                      prepend-inner-icon="trending_down"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.landDependenceRatio"
                      type="number"
                      label="土地依赖值"
                      suffix="%"
                      hint="请输入全国平均土地依赖值"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.landDependenceScore1"
                      type="number"
                      label="土地依赖得分"
                      hint="土地依赖 >= 平均土地依赖值"
                      outlined
                      prepend-inner-icon="trending_up"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.landDependenceScore2"
                      type="number"
                      label="土地依赖得分"
                      hint="土地依赖 < 平均土地依赖值"
                      outlined
                      prepend-inner-icon="trending_down"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.debtBudgetRatio"
                      type="number"
                      label="政府债务负担得分"
                      suffix="%"
                      hint="请输入政府债务负担得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.debtBudgetScore1"
                      type="number"
                      label="政府债务负担得分"
                      hint="地方政府债务余额/一般公共预算收入(%) >= 政府债务负担得分"
                      outlined
                      prepend-inner-icon="trending_up"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.debtBudgetScore2"
                      type="number"
                      label="政府债务负担得分"
                      hint="地方政府债务余额/一般公共预算收入(%) < 政府债务负担得分"
                      outlined
                      prepend-inner-icon="trending_down"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.budgetRevenueScore1"
                      type="number"
                      label="一般预算收入得分"
                      hint="一般预算收入 >= 国内三线（70城）平均一般预算收入"
                      outlined
                      prepend-inner-icon="trending_up"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.budgetRevenueScore2"
                      type="number"
                      label="一般预算收入得分"
                      hint="一般预算收入 < 国内三线（70城）平均一般预算收入"
                      outlined
                      prepend-inner-icon="trending_down"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.budgetRevenueGrowthRateScore1"
                      type="number"
                      label="一般预算收入增长率得分"
                      hint="一般预算收入增长率 >= 国内一般预算收入增长率"
                      outlined
                      prepend-inner-icon="trending_up"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.budgetRevenueGrowthRateScore2"
                      type="number"
                      label="一般预算收入增长率得分"
                      hint="一般预算收入增长率 < 国内一般预算收入增长率"
                      outlined
                      prepend-inner-icon="trending_down"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.selfSufficiencyRateScore1"
                      type="number"
                      label="财政自给率得分"
                      hint="财政自给率 >= 国内财政自给率"
                      outlined
                      prepend-inner-icon="trending_up"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.selfSufficiencyRateScore2"
                      type="number"
                      label="财政自给率得分"
                      hint="财政自给率 < 国内财政自给率"
                      outlined
                      prepend-inner-icon="trending_down"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.level1"
                      type="number"
                      label="一线城市得分"
                      hint="请输入一线城市得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem['level1.1']"
                      type="number"
                      label="新一线城市得分"
                      hint="请输入新一线城市得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.level2"
                      type="number"
                      label="二线城市得分"
                      hint="请输入二线城市得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.level3"
                      type="number"
                      label="三线城市（新兴）得分"
                      hint="请输入三线城市（新兴）得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem['level3.1']"
                      type="number"
                      label="三线城市（起步）得分"
                      hint="请输入三线城市（起步）得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.level4"
                      type="number"
                      label="四五线城市得分"
                      hint="请输入四五线城市得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.author"
                      type="number"
                      label="舆情（城市）得分"
                      hint="自动带出"
                      outlined
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>企业层面</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6" class="d-flex" v-for="(item, i) in editedItem.industry" :key="'_industry_'+ i">
                    <v-text-field
                      v-model="item.name"
                      type="text"
                      label="所处行业"
                      hint="请输入所处行业"
                    ></v-text-field>
                    <v-text-field
                      class="ml-2"
                      v-model="item.score"
                      type="number"
                      label="得分"
                      hint="请输入得分"
                      outlined
                      :append-outer-icon="i === 0 ? 'add' : 'remove'"
                      @click:append-outer="i === 0 ? doAction('add_industry') : doAction('remove_industry', item, i)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" class="d-flex" v-for="(item, i) in editedItem.enterpriseScale" :key="'_scale_'+ i">
                    <v-text-field
                      v-model="item.score"
                      type="number"
                      :label="item.name"
                      hint="请输入得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" class="d-flex" v-for="(item, i) in editedItem.establishYear" :key="'_years_'+ i">
                    <v-text-field
                      v-model="item.score"
                      type="number"
                      :label="item.name"
                      hint="请输入得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="d-flex" v-for="(item, i) in editedItem.registerCapital" :key="'_register_'+ i">
                    <v-text-field
                      v-model="item.score"
                      type="number"
                      :label="item.name"
                      hint="请输入得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="d-flex" v-for="(item, i) in editedItem.paidCapital" :key="'_registed_'+ i">
                    <v-text-field
                      v-model="item.score"
                      type="number"
                      :label="item.name"
                      hint="请输入得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.humanHealthParam[0]"
                      type="number"
                      label="人力健康参数:下降"
                      hint="请输入得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.humanHealthParam[1]"
                      type="number"
                      label="人力健康参数:持平"
                      hint="请输入得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.humanHealthParam[2]"
                      type="number"
                      label="人力健康参数:上升"
                      hint="请输入得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-for="(item, i) in editedItem.exception" :key="'_exception_'+ i" class="d-flex align-center">
                  <v-col cols="6" class="d-flex align-center">
                    <v-switch v-model="item.condition">
                      <template v-slot:label>
                        {{item.excepType | stringException}}:{{item.condition ? '任意' : '全部'}}
                      </template>
                    </v-switch>
                    <v-text-field
                      v-model="item.score"
                      type="number"
                      label="得分"
                      hint="请输入得分"
                      outlined
                      class="mx-2"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-row v-for="(label, j) in item.labels" :key="'_labels_'+ i +'_'+j" class="d-flex align-center">
                      <v-col cols="6">
                        <v-text-field
                          v-model="label.label"
                          type="string"
                          label="关键字"
                          hint="请输入标签关键字"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          class="ml-2"
                          v-model="label.min"
                          type="number"
                          label="请输入关键字对应金额或出现次数"
                          hint="请输入次数或金额"
                          outlined
                          :append-outer-icon="j === 0 ? 'add' : 'remove'"
                          @click:append-outer="j === 0 ? doAction('add_label', i) : doAction('remove_label', i, i)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12"><v-divider inset></v-divider></v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>我司层面</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="3" v-for="(item, i) in editedItem.cooperationYear" :key="'_cooyear_'+ i">
                    <v-text-field
                      v-model="item.score"
                      type="number"
                      :label="'合作'+item.name"
                      hint="请输入与我司合作时长得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" v-for="(item, i) in editedItem.paymentPeriod" :key="'_payper_'+ i">
                    <v-text-field
                      v-model="item.score"
                      type="number"
                      :label="'付款账期'+item.name"
                      hint="请输入付款条件（账期）得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" v-for="(item, i) in editedItem.historyAmount" :key="'_historyamt_'+ i">
                    <v-text-field
                      v-model="item.score"
                      type="number"
                      :label="'交易金额'+item.name"
                      hint="请输入历史交易金额得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" v-for="(item, i) in editedItem.maxOverdueDay" :key="'_maxover_'+ i">
                    <v-text-field
                      v-model="item.score"
                      type="number"
                      :label="'最长逾期时间'+item.name"
                      hint="请输入最长逾期时间得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" v-for="(item, i) in editedItem.overdueAmountRatio" :key="'_maxamount_'+ i">
                    <v-text-field
                      v-model="item.score"
                      type="number"
                      :label="'逾期金额占比'+item.name"
                      hint="请输入逾期金额/交易金额占比得分"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" depressed @click="submit" :disabled="!valid"><v-icon left>save</v-icon>保 存</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import store from "@/store";
import Util from '@/common/util';
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  PUBLISH_RISK_MODEL,
  EDIT_RISK_MODEL,
  FETCH_RISK_MODEL,
} from "@/store/actions.type";

export default {
  data: () => ({
    title_page: function () {
      return (this.$route.params.id !== '1' && this.$route.params.id !== '0') ? (this.$route.query.copy == '1' ? '复制模型' : '编辑模型') : '新建模型';
    },
    openPanel: [0],
    btnFab: false,
    editedItem: {
      name: '',
      businessType: 0,
      GDPTotalScore1: null,
      GDPTotalScore2: null,
      industry: [{
        type: '',
        score: null,
      }],
      enterpriseScale: [
        {
          name: '微型（XS）',
          code: 'XS',
          score: null,
        },
        {
          name: '小型（S）',
          code: 'S',
          score: null,
        },
        {
          name: '中型（M）',
          code: 'M',
          score: null,
        },
        {
          name: '大型（L）',
          code: 'L',
          score: null,
        },
      ],
      establishYear: [
        {
          name: '0-3年',
          min: 0,
          max: 3,
          score: null,
        },
        {
          name: '3-5年',
          min: 3,
          max: 5,
          score: null,
        },
        {
          name: '5-10年',
          min: 5,
          max: 10,
          score: null,
        },
        {
          name: '10-15年',
          min: 10,
          max: 15,
          score: null,
        },
        {
          name: '15年以上',
          min: 15,
          max: null,
          score: null,
        },
      ],
      registerCapital: [
        {
          name: '注册资金合人民币100万元以下',
          min: 0,
          max: 1000000,
          score: null,
        },
        {
          name: '注册资金合人民币100万元—1000万元',
          min: 1000000,
          max: 10000000,
          score: null,
        },
        {
          name: '注册资金合人民币1000万元—5000万元',
          min: 10000000,
          max: 50000000,
          score: null,
        },
        {
          name: '注册资金合人民币5000万元—1亿元',
          min: 50000000,
          max: 100000000,
          score: null,
        },
        {
          name: '注册资金合人民币1亿元及以上',
          min: 100000000,
          max: null,
          score: null,
        },
      ],
      paidCapital: [
        {
          name: '实缴资金合人民币100万元以下',
          min: 0,
          max: 1000000,
          score: null,
        },
        {
          name: '实缴资金合人民币100万元—1000万元',
          min: 1000000,
          max: 10000000,
          score: null,
        },
        {
          name: '实缴资金合人民币1000万元—5000万元',
          min: 10000000,
          max: 50000000,
          score: null,
        },
        {
          name: '实缴资金合人民币5000万元—1亿元',
          min: 50000000,
          max: 100000000,
          score: null,
        },
        {
          name: '实缴资金合人民币1亿元及以上',
          min: 100000000,
          max: null,
          score: null,
        },
      ],
      humanHealthParam: [null, null, null],
      cooperationYear: [
        {
          name: '1-2年',
          min: 1,
          max: 2,
          score: null,
        },
        {
          name: '3-4年',
          min: 3,
          max: 4,
          score: null,
        },
        {
          name: '5-7年',
          min: 5,
          max: 7,
          score: null,
        },
        {
          name: '8年及以上',
          min: 8,
          max: null,
          score: null,
        },
      ],
      paymentPeriod: [
        {
          name: '0天',
          min: 0,
          max: 0,
          score: null,
        },
        {
          name: '1-30天',
          min: 1,
          max: 30,
          score: null,
        },
        {
          name: '31-45天',
          min: 31,
          max: 45,
          score: null,
        },
        {
          name: '46-60天',
          min: 46,
          max: 60,
          score: null,
        },
        {
          name: '61-90天',
          min: 61,
          max: 90,
          score: null,
        },
        {
          name: '90天以上',
          min: 91,
          max: null,
          score: null,
        },
      ],
      historyAmount: [
        {
          name: '500W以下',
          min: 0,
          max: 5000000,
          score: null,
        },
        {
          name: '500W-1000W',
          min: 5000000,
          max: 10000000,
          score: null,
        },
        {
          name: '1000W-2000W',
          min: 10000000,
          max: 20000000,
          score: null,
        },
        {
          name: '2000W-3000W',
          min: 2000000,
          max: 30000000,
          score: null,
        },
        {
          name: '3000W-5000W',
          min: 3000000,
          max: 50000000,
          score: null,
        },
        {
          name: '5000W-10000W',
          min: 5000000,
          max: 100000000,
          score: null,
        },
        {
          name: '10000W-50000W',
          min: 100000000,
          max: 500000000,
          score: null,
        },
        {
          name: '50000W及以上',
          min: 500000000,
          max: null,
          score: null,
        },
      ],
      maxOverdueDay: [
        {
          name: '0天',
          min: 0,
          max: 0,
          score: null,
        },
        {
          name: '1-30天',
          min: 1,
          max: 30,
          score: null,
        },
        {
          name: '31-60天',
          min: 31,
          max: 60,
          score: null,
        },
        {
          name: '61-90天',
          min: 61,
          max: 90,
          score: null,
        },
        {
          name: '91-120天',
          min: 91,
          max: 120,
          score: null,
        },
        {
          name: '120天以上',
          min: 121,
          max: null,
          score: null,
        },
      ],
      overdueAmountRatio: [
        {
          name: '0',
          min: 0,
          max: 0,
          score: null,
        },
        {
          name: '0-5%',
          min: 0,
          max: 0.05,
          score: null,
        },
        {
          name: '5%-10%',
          min: 0.05,
          max: 0.1,
          score: null,
        },
        {
          name: '10%-20%',
          min: 0.1,
          max: 0.2,
          score: null,
        },
        {
          name: '20%-30%',
          min: 0.2,
          max: 0.3,
          score: null,
        },
        {
          name: '30%-40%',
          min: 0.3,
          max: 0.4,
          score: null,
        },
        {
          name: '40%-50%',
          min: 0.4,
          max: 0.5,
          score: null,
        },
        {
          name: '50%以上',
          min: 0.5,
          max: null,
          score: null,
        },
      ],
      exception: [
        {
          excepType: 1,
          condition: 0,
          score: 0,
          labels: [{
            label: '',
            unit: 1,
            min: 0,
            max: 0,
            score: null
          }],
        },
        {
          excepType: 2,
          condition: 0,
          score: 0,
          labels: [{
            label: '',
            unit: 1,
            min: 0,
            max: 0,
            score: null
          }],
        },
        {
          excepType: 3,
          condition: 0,
          score: 0,
          labels: [{
            label: '',
            unit: 1,
            min: 0,
            max: 0,
            score: null
          }],
        },
        {
          excepType: 4,
          condition: 0,
          score: 0,
          labels: [{
            label: '',
            unit: 1,
            min: 0,
            max: 0,
            score: null
          }],
        },
        {
          excepType: 0,
          condition: 0,
          score: 0,
          labels: [{
            label: '',
            unit: 1,
            min: 0,
            max: 0,
            score: null
          }],
        },
      ]
    },
    dataTimeMenu: false,
    rules: {
      required: value => (value === 0 || !!value) || '请输入内容.',
      phonenum: value => {
        if (value) {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        } else {
          return true;
        }
      },
      selected: value => {
        if (!value || value.length < 1) {
          return '请至少选择一个选项。'
        } else {
          return true;
        }
      },
      email: value => {
        if (value) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        } else {
          return true;
        }
      },
    },
    valid: true,
  }),
  watch: {},

  computed: {
    ...mapGetters(["currentUser"]),
    businessTypeList () {
      return Util.categories('RiskBusinessType')
    },
  },
  beforeCreate(){
  },
  created () {
    if (this.$route.params.id !== '0') this.getRiskModel(this.$route.params.id)
  },
  methods: {
    goBack() {
      this.editedItem = Object.assign({}, {});
      this.$router.push({path: '/dealer/system/risk/model'});
    },
    getRiskModel(id) {
      this.$store.dispatch(FETCH_RISK_MODEL, id)
        .then(data => {
          this.editedItem = Object.assign({}, data)

          this.editedItem.landDependenceRatio = parseFloat(data.landDependenceRatio*100).toFixed(2);
          this.editedItem.debtBudgetRatio = parseFloat(data.debtBudgetRatio*100).toFixed(2);

          const level1 = data.cityLevel.find(n => n.code == 'level1');
          if(level1) {
            this.editedItem.level1 = level1.score;
          }

          const level1_1 = data.cityLevel.find(n => n.code == 'level1.1');
          if(level1_1) {
            this.editedItem['level1.1'] = level1_1.score;
          }

          const level2 = data.cityLevel.find(n => n.code == 'level2');
          if(level2) {
            this.editedItem.level2 = level2.score;
          }

          const level3 = data.cityLevel.find(n => n.code == 'level3');
          if(level3) {
            this.editedItem.level3 = level3.score;
          }

          const level3_1 = data.cityLevel.find(n => n.code == 'level3.1');
          if(level3_1) {
            this.editedItem['level3.1'] = level3_1.score;
          }

          const level4 = data.cityLevel.find(n => n.code == 'level4');
          if(level4) {
            this.editedItem.level4 = level4.score;
          }

          if (!this.editedItem.industry) {
            this.editedItem.industry = [{
              name: '',
              score: null,
            }];
          }
          if (!(Array.isArray(this.editedItem.exception) && this.editedItem.exception.length > 0)) {
            this.editedItem.exception = [
              {
                excepType: 1,
                condition: 0,
                score: 0,
                labels: [{
                  label: '',
                  unit: 1,
                  min: 0,
                  max: 0,
                  score: null
                }],
              },
              {
                excepType: 2,
                condition: 0,
                score: 0,
                labels: [{
                  label: '',
                  unit: 1,
                  min: 0,
                  max: 0,
                  score: null
                }],
              },
              {
                excepType: 3,
                condition: 0,
                score: 0,
                labels: [{
                  label: '',
                  unit: 1,
                  min: 0,
                  max: 0,
                  score: null
                }],
              },
              {
                excepType: 4,
                condition: 0,
                score: 0,
                labels: [{
                  label: '',
                  unit: 1,
                  min: 0,
                  max: 0,
                  score: null
                }],
              }
            ];
          }

          if (!(Array.isArray(this.editedItem.enterpriseScale) && this.editedItem.enterpriseScale.length > 0)) {
            this.editedItem.enterpriseScale = [
              {
                name: '微型（XS）',
                code: 'XS',
                score: null,
              },
              {
                name: '小型（S）',
                code: 'S',
                score: null,
              },
              {
                name: '中型（M）',
                code: 'M',
                score: null,
              },
              {
                name: '大型（L）',
                code: 'L',
                score: null,
              },
            ];
          }

          if (!(Array.isArray(this.editedItem.establishYear) && this.editedItem.establishYear.length > 0)) {
            this.editedItem.establishYear = [
              {
                name: '0-3年',
                min: 0,
                max: 3,
                score: null,
              },
              {
                name: '3-5年',
                min: 3,
                max: 5,
                score: null,
              },
              {
                name: '5-10年',
                min: 5,
                max: 10,
                score: null,
              },
              {
                name: '10-15年',
                min: 10,
                max: 15,
                score: null,
              },
              {
                name: '15年以上',
                min: 15,
                max: null,
                score: null,
              },
            ];
          }

          if (!(Array.isArray(this.editedItem.registerCapital) && this.editedItem.registerCapital.length > 0)) {
            this.editedItem.registerCapital = [
              {
                name: '注册资金合人民币100万元以下',
                min: 0,
                max: 1000000,
                score: null,
              },
              {
                name: '注册资金合人民币100万元—1000万元',
                min: 1000000,
                max: 10000000,
                score: null,
              },
              {
                name: '注册资金合人民币1000万元—5000万元',
                min: 10000000,
                max: 50000000,
                score: null,
              },
              {
                name: '注册资金合人民币5000万元—1亿元',
                min: 50000000,
                max: 100000000,
                score: null,
              },
              {
                name: '注册资金合人民币1亿元及以上',
                min: 100000000,
                max: null,
                score: null,
              },
            ];
          }

          if (!(Array.isArray(this.editedItem.paidCapital) && this.editedItem.paidCapital.length > 0)) {
            this.editedItem.paidCapital = [
              {
                name: '实缴资金合人民币100万元以下',
                min: 0,
                max: 1000000,
                score: null,
              },
              {
                name: '实缴资金合人民币100万元—1000万元',
                min: 1000000,
                max: 10000000,
                score: null,
              },
              {
                name: '实缴资金合人民币1000万元—5000万元',
                min: 10000000,
                max: 50000000,
                score: null,
              },
              {
                name: '实缴资金合人民币5000万元—1亿元',
                min: 50000000,
                max: 100000000,
                score: null,
              },
              {
                name: '实缴资金合人民币1亿元及以上',
                min: 100000000,
                max: null,
                score: null,
              },
            ];
          }

          if (!(Array.isArray(this.editedItem.humanHealthParam) && this.editedItem.humanHealthParam.length > 0)) {
            this.editedItem.humanHealthParam = [null, null, null];
          }

          if (!(Array.isArray(this.editedItem.cooperationYear) && this.editedItem.cooperationYear.length > 0)) {
            this.editedItem.cooperationYear = [
              {
                name: '1-2年',
                min: 1,
                max: 2,
                score: null,
              },
              {
                name: '3-4年',
                min: 3,
                max: 4,
                score: null,
              },
              {
                name: '5-7年',
                min: 5,
                max: 7,
                score: null,
              },
              {
                name: '8年及以上',
                min: 8,
                max: null,
                score: null,
              },
            ];
          }

          if (!(Array.isArray(this.editedItem.paymentPeriod) && this.editedItem.paymentPeriod.length > 0)) {
            this.editedItem.paymentPeriod = [
              {
                name: '0天',
                min: 0,
                max: 0,
                score: null,
              },
              {
                name: '1-30天',
                min: 1,
                max: 30,
                score: null,
              },
              {
                name: '31-45天',
                min: 31,
                max: 45,
                score: null,
              },
              {
                name: '46-60天',
                min: 46,
                max: 60,
                score: null,
              },
              {
                name: '61-90天',
                min: 61,
                max: 90,
                score: null,
              },
              {
                name: '90天以上',
                min: 91,
                max: null,
                score: null,
              },
            ];
          }

          if (!(Array.isArray(this.editedItem.historyAmount) && this.editedItem.historyAmount.length > 0)) {
            this.editedItem.historyAmount = [
              {
                name: '500W以下',
                min: 0,
                max: 5000000,
                score: null,
              },
              {
                name: '500W-1000W',
                min: 5000000,
                max: 10000000,
                score: null,
              },
              {
                name: '1000W-2000W',
                min: 10000000,
                max: 20000000,
                score: null,
              },
              {
                name: '2000W-3000W',
                min: 2000000,
                max: 30000000,
                score: null,
              },
              {
                name: '3000W-5000W',
                min: 3000000,
                max: 50000000,
                score: null,
              },
              {
                name: '5000W-10000W',
                min: 5000000,
                max: 100000000,
                score: null,
              },
              {
                name: '10000W-50000W',
                min: 100000000,
                max: 500000000,
                score: null,
              },
              {
                name: '50000W及以上',
                min: 500000000,
                max: null,
                score: null,
              },
            ];
          }

          if (!(Array.isArray(this.editedItem.maxOverdueDay) && this.editedItem.maxOverdueDay.length > 0)) {
            this.editedItem.maxOverdueDay = [
              {
                name: '0天',
                min: 0,
                max: 0,
                score: null,
              },
              {
                name: '1-30天',
                min: 1,
                max: 30,
                score: null,
              },
              {
                name: '31-60天',
                min: 31,
                max: 60,
                score: null,
              },
              {
                name: '61-90天',
                min: 61,
                max: 90,
                score: null,
              },
              {
                name: '91-120天',
                min: 91,
                max: 120,
                score: null,
              },
              {
                name: '120天以上',
                min: 121,
                max: null,
                score: null,
              },
            ];
          }

          if (!(Array.isArray(this.editedItem.overdueAmountRatio) && this.editedItem.overdueAmountRatio.length > 0)) {
            this.editedItem.overdueAmountRatio = [
              {
                name: '0',
                min: 0,
                max: 0,
                score: null,
              },
              {
                name: '0-5%',
                min: 0,
                max: 0.05,
                score: null,
              },
              {
                name: '5%-10%',
                min: 0.05,
                max: 0.1,
                score: null,
              },
              {
                name: '10%-20%',
                min: 0.1,
                max: 0.2,
                score: null,
              },
              {
                name: '20%-30%',
                min: 0.2,
                max: 0.3,
                score: null,
              },
              {
                name: '30%-40%',
                min: 0.3,
                max: 0.4,
                score: null,
              },
              {
                name: '40%-50%',
                min: 0.4,
                max: 0.5,
                score: null,
              },
              {
                name: '50%以上',
                min: 0.5,
                max: null,
                score: null,
              },
            ];
          }
        });
    },
    submit(goto = '') {
      let strInsert = {}

      strInsert.name = this.editedItem.name;
      strInsert.businessType = this.editedItem.businessType
      strInsert.GDPTotalScore1 = parseFloat(this.editedItem.GDPTotalScore1);
      strInsert.GDPTotalScore2 = parseFloat(this.editedItem.GDPTotalScore2);
      strInsert.GDPGrowthRateScore1 = parseFloat(this.editedItem.GDPGrowthRateScore1);
      strInsert.GDPGrowthRateScore2 = parseFloat(this.editedItem.GDPGrowthRateScore2);
      strInsert.GDPPerCapitaScore1 = parseFloat(this.editedItem.GDPPerCapitaScore1);
      strInsert.GDPPerCapitaScore2 = parseFloat(this.editedItem.GDPPerCapitaScore2);
      strInsert.landDependenceRatio = parseFloat((this.editedItem.landDependenceRatio/100).toFixed(4));
      strInsert.landDependenceScore1 = parseFloat(this.editedItem.landDependenceScore1);
      strInsert.landDependenceScore2 = parseFloat(this.editedItem.landDependenceScore2);
      strInsert.budgetRevenueScore1 = parseFloat(this.editedItem.budgetRevenueScore1);
      strInsert.budgetRevenueScore2 = parseFloat(this.editedItem.budgetRevenueScore2);
      strInsert.budgetRevenueGrowthRateScore1 = parseFloat(this.editedItem.budgetRevenueGrowthRateScore1);
      strInsert.budgetRevenueGrowthRateScore2 = parseFloat(this.editedItem.budgetRevenueGrowthRateScore2);
      strInsert.debtBudgetRatio = parseFloat((this.editedItem.debtBudgetRatio/100).toFixed(4));
      strInsert.debtBudgetScore1 = parseFloat(this.editedItem.debtBudgetScore1);
      strInsert.debtBudgetScore2 = parseFloat(this.editedItem.debtBudgetScore2);
      strInsert.selfSufficiencyRateScore1 = parseFloat(this.editedItem.selfSufficiencyRateScore1);
      strInsert.selfSufficiencyRateScore2 = parseFloat(this.editedItem.selfSufficiencyRateScore2);

      strInsert.cityLevel = [];
      strInsert.cityLevel.push({
        code: 'level1',
        score: this.editedItem.level1 || null,
      });

      strInsert.cityLevel.push({
        code: 'level1.1',
        score: this.editedItem['level1.1'] || null,
      });

      strInsert.cityLevel.push({
        code: 'level2',
        score: this.editedItem.level2 || null,
      });

      strInsert.cityLevel.push({
        code: 'level3',
        score: this.editedItem.level3 || null,
      });

      strInsert.cityLevel.push({
        code: 'level3.1',
        score: this.editedItem['level3.1'] || null,
      });

      strInsert.cityLevel.push({
        code: 'level4',
        score: this.editedItem.level4 || null,
      });

      strInsert.industry = this.editedItem.industry;
      for (const item of strInsert.industry) {
        if(!item.score) {
          item.score = null;
        }
      }
      strInsert.enterpriseScale = this.editedItem.enterpriseScale;
      for (const item of strInsert.enterpriseScale) {
        if(!item.score) {
          item.score = null;
        }
      }
      strInsert.establishYear = this.editedItem.establishYear;
      for (const item of strInsert.establishYear) {
        if(!item.score) {
          item.score = null;
        }
      }
      strInsert.registerCapital = this.editedItem.registerCapital;
      for (const item of strInsert.registerCapital) {
        if(!item.score) {
          item.score = null;
        }
      }
      strInsert.paidCapital = this.editedItem.paidCapital;
      for (const item of strInsert.paidCapital) {
        if(!item.score) {
          item.score = null;
        }
      }
      strInsert.humanHealthParam = this.editedItem.humanHealthParam;
      strInsert.humanHealthParam.forEach((item, i, arr) => {
        if(!item) {
          arr[i] = null;
        }
      });
      strInsert.cooperationYear = this.editedItem.cooperationYear;
      for (const item of strInsert.cooperationYear) {
        if(!item.score) {
          item.score = null;
        }
      }
      strInsert.paymentPeriod = this.editedItem.paymentPeriod;
      for (const item of strInsert.paymentPeriod) {
        if(!item.score) {
          item.score = null;
        }
      }
      strInsert.historyAmount = this.editedItem.historyAmount;
      for (const item of strInsert.historyAmount) {
        if(!item.score) {
          item.score = null;
        }
      }
      strInsert.maxOverdueDay = this.editedItem.maxOverdueDay;
      for (const item of strInsert.maxOverdueDay) {
        if(!item.score) {
          item.score = null;
        }
      }
      strInsert.overdueAmountRatio = this.editedItem.overdueAmountRatio;
      for (const item of strInsert.overdueAmountRatio) {
        if(!item.score) {
          item.score = null;
        }
      }
      strInsert.exception = this.editedItem.exception;
      for (const item of strInsert.exception) {
        if (!item.score) {
          item.score = null;
        }
      }

      strInsert.remarks = this.editedItem.remarks;

      if (this.editedItem._id) {
        if (this.$route.query.copy == '1') {
          store.dispatch(PUBLISH_RISK_MODEL, strInsert)
            .then(() => {
              store.commit(SET_ERROR, { msg: '复制成功', color: 'primary' });
              this.$router.push({ path: '/dealer/system/risk/model' });
            })
            .catch((error) => {
              store.commit(SET_ERROR, { msg: error.response.data.message });
            })
        } else {
          strInsert._id = this.editedItem._id
          store.dispatch(EDIT_RISK_MODEL, strInsert)
            .then(() => {
              store.commit(SET_ERROR, { msg: '修改成功', color: 'primary' });
              this.$router.push({ path: '/dealer/system/risk/model' });
            })
            .catch((error) => {
              store.commit(SET_ERROR, { msg: error.response.data.message });
            })
        }
      } else {
        store.dispatch(PUBLISH_RISK_MODEL, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.$router.push({path: '/dealer/system/risk/model'});
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

      if (goto) {
        this.$router.push({path: goto});
      }
    },
    doAction(action, item, index) {
      switch (action) {
        case 'add_industry': {
          this.editedItem.industry.push({
            name: '',
            score: null,
          });
          break
        }
        case 'remove_industry': {
          this.editedItem.industry.splice(index, 1);
          break
        }
        case 'add_label': {
          this.editedItem.exception[item].labels.push({
            label: '',
            unit: 0,
            min: 0,
            max: 0,
            score: null
          });
          break
        }
        case 'remove_label': {
          this.editedItem.exception[item].labels.splice(index, 1);
          break
        }
      }
    },
  }
}
</script>
<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0,0,0,0);
  }
  .scroll {
    overflow-y: scroll
  }
</style>
