var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticStyle: {
      "height": "300px"
    },
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-alert', {
    attrs: {
      "text": "",
      "outlined": "",
      "color": "secondary",
      "icon": "admin_panel_settings"
    }
  }, [_vm._v(" 目前仅开放运营及经销商登陆，企业管理及员工请从esp.mdaas.cn登陆使用ESP平台。 ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }