<template>
  <div class="fluid">
    <v-data-table
      :headers="headerTable"
      :items="typeList"
      :options.sync="optionsTable"
      :server-items-length="typeCount"
      :loading="typeLoading"
      :item-class="itemRowBackground"
      loading-text="数据读取中... 请稍后"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      fixed-header
      height="600"
      item-key="name"
      transition
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>产品类别列表</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="secondary" depressed @click="doAction('create_item', '', '新增产品类别')">
            <v-icon left>add</v-icon>
            新建类别
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.noCount="{ item }">
        {{item.noCount ? '是' : '否'}}
      </template>
      <template v-slot:item.noSN="{ item }">
        {{item.noSN ? '是' : '否'}}
      </template>
      <template v-slot:item.customFields="{ item }">
        {{item.customFields.map(field => field.name)}}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="doAction('edit_item', item, '编辑产品类别')">edit</v-icon>
        <v-icon small @click="doAction('active_item', item, true)" v-if="!item.isEnable">check_circle_outline</v-icon>
        <v-icon small @click="doAction('active_item', item, false)" v-if="item.isEnable">block</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataList()">Reset</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dlgEdit" persistent max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[rules.required]"
                  type="text"
                  label="类别名称"
                  hint="请输入产品类别名称"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="mt-n6">
                <v-switch v-model="editedItem.noCount">
                  <template v-slot:label>
                    不参与设备统计
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="" class="mx-2" v-bind="attrs" v-on="on" small>
                          help_outline
                        </v-icon>
                      </template>
                      <span>设备统计数据将统计全部产品类别，不需要加入统计数据的如配件等，请打开此项目。</span>
                    </v-tooltip>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="6" class="mt-n6">
                <v-switch v-model="editedItem.noSN">
                  <template v-slot:label>
                    不需要序列号
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="" class="mx-2" v-bind="attrs" v-on="on" small>
                          help_outline
                        </v-icon>
                      </template>
                      <span>创建设备资料时需要产品的序列号，不需要序列号时，请打开此项目。</span>
                    </v-tooltip>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="2">固定字段</v-col>
              <v-col cols="10">产品名称、部件代码、型号、供应商、成本、保修期、图片、备注</v-col>
              <v-col cols="2">自定义字段</v-col>
              <v-col cols="10">
                <v-chip
                  v-for="(item, index) in editedItem.customFields"
                  :key="index"
                  @click:close="deleteField(index)"
                  class="mx-2"
                  small
                  close
                >{{item.name}}</v-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <widgets-product-field v-on:Complete="addField"></widgets-product-field>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_PRODUCTTYPE_LIST,
  PUBLISH_PRODUCTTYPE,
  ACTIV_PRODUCTTYPE,
  EDIT_PRODUCTTYPE,
} from "@/store/actions.type";
export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedIndex: -1,
      editedItem: {
        _id: '',
        name: '',
        customFields:[],
        noCount: false,
        noSN: false,
      },
      defaultItem: {
        _id: '',
        name: '',
        customFields:[],
        noCount: false,
        noSN: false,
      },
      query: {
        key: '',
      },
      defaultQuery: {
        key: '',
      },
      headerTable: [
        {text: '名称', value: 'name'},
        {text: '不参与统计', value: 'noCount'},
        {text: '不需要SN', value: 'noSN'},
        {text: '自定义字段', value: 'customFields'},
        {text: '操作', value: 'actions', align: 'end', sortable: false}
      ],
      optionsTable: {},
    };
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "typeLoading",
      "typeList",
      "typeCount"]),
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  mounted() {},
  methods: {
    getDataList(query = {}) {
      // 获取分页信息
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (query.key) urlQuery += '&key=' + query.key
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_PRODUCTTYPE_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    doAction(action, item, customitem) {
      switch (action) {
        case 'search_key': {
          if (this.query.key !== '') {
            this.getDataList({key: this.query.key})
          } else {
            this.getDataList()
          }
          break
        }
        case 'create_item': case 'edit_item': {
          this.editedIndex = this.typeList.indexOf(item)
          this.editedItem = Object.assign(this.defaultItem, item)
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
        case 'active_item': {
          if (confirm(`确定要 ${customitem ? '启用' : '禁用'} ${item.name}？`)) {
            store.dispatch(ACTIV_PRODUCTTYPE, {_id: item._id, reply: customitem})
              .then(() => {
                store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
                this.getDataList()
                this.close()
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          }
          break
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    close () {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    addField(data) {
      if (this.editedItem.customFields === undefined) this.editedItem.customFields = []
      this.editedItem.customFields.push(data)
      this.$forceUpdate()
    },
    deleteField(index) {
      this.editedItem.customFields.splice(index, 1)
      this.$forceUpdate()
    },
    itemRowBackground: function (item) {
      if (!item.isEnable) return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
    },
    submit() {
      let strInsert = {}
      strInsert.name = this.editedItem.name
      strInsert.noCount = this.editedItem.noCount
      strInsert.noSN = this.editedItem.noSN
      strInsert.customFields = this.editedItem.customFields

      if (this.editedIndex !== -1) {
        strInsert._id = this.editedItem._id
        store.dispatch(EDIT_PRODUCTTYPE, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_PRODUCTTYPE, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },
  }
}
</script>
