<template>
  <div class="fluid">
    <v-data-table
      :headers="headerTable"
      :items="rentIndustryList"
      :options.sync="optionsTable"
      :server-items-length="rentIndustryCount"
      :loading="rentIndustryLoading"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      loading-text="数据读取中... 请稍后"
      item-key="name"
      transition
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>行业信息</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" depressed @click="createItem()" color="secondary">创建行业信息</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <span :class="!item.isEnable ? 'text-decoration-line-through v-list-item--link' : 'v-list-item--link'" @click="editItem(item)">{{item.name}}</span>
      </template>
      <template v-slot:item.isEnable="{ item }">
        {{item.isEnable ? '是' : '否'}}
      </template>
      <template v-slot:item.updateTime="{ item }">
        {{item.createTime | formatTime}}
      </template>
      <template v-slot:item.editor="{ item }">
        <widgets-employee-dialogs :account="item.editor._id" class="v-list-item--link">{{item.editor.personal ? item.editor.personal.name : '*未设置姓名'}}</widgets-employee-dialogs>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="setEnable(item, true)" v-if="!item.isEnable">check_circle_outline</v-icon>
        <v-icon small @click="setEnable(item, false)" v-else>block</v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dlgEdit" persistent scrollable max-width="700px">
      <v-form v-model="valid" @submit.prevent="submitItem">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeItem"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row justify="center" align="center">
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[rules.required]"
                  type="text"
                  label="行业名称"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.code"
                  :rules="[rules.required]"
                  type="text"
                  label="行业代码"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.GB.name"
                  type="text"
                  label="国标行业名称"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.GB.code"
                  type="text"
                  label="国标行业代码"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                运营资产评估
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="10" class="my-5">
                <vue-slider
                  ref="slider"
                  v-model="arrSlider"
                  v-bind="slider_options"
                >
                </vue-slider>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.eval_init_scale"
                  :rules="[rules.required]"
                  type="number"
                  :label="'小于'+ editedItem.evaluation.map(item => item.start)[0]"
                  suffix="%"
                  step="0.01"
                  hide-details
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.evaluation[0].scale"
                  :rules="[rules.required]"
                  type="number"
                  :label="(parseFloat(editedItem.evaluation.map(item => item.start)[0]) + 0.01).toFixed(2) +' 至 '+editedItem.evaluation.map(item => item.start)[1]"
                  suffix="%"
                  step="0.01"
                  hide-details
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.evaluation[1].scale"
                  :rules="[rules.required]"
                  type="number"
                  :label="(parseFloat(editedItem.evaluation.map(item => item.start)[1]) + 0.01).toFixed(2) +' 至 '+editedItem.evaluation.map(item => item.start)[2]"
                  suffix="%"
                  step="0.01"
                  hide-details
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.evaluation[2].scale"
                  :rules="[rules.required]"
                  type="number"
                  :label="(parseFloat(editedItem.evaluation.map(item => item.start)[2]) + 0.01).toFixed(2) +' 至 '+editedItem.evaluation.map(item => item.start)[3]"
                  suffix="%"
                  step="0.01"
                  hide-details
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.evaluation[3].scale"
                  :rules="[rules.required]"
                  type="number"
                  :label="(parseFloat(editedItem.evaluation.map(item => item.start)[3]) + 0.01).toFixed(2) +' 至 '+editedItem.evaluation.map(item => item.start)[4]"
                  suffix="%"
                  step="0.01"
                  hide-details
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.evaluation[4].scale"
                  :rules="[rules.required]"
                  type="number"
                  :label="(parseFloat(editedItem.evaluation.map(item => item.start)[4]) + 0.01).toFixed(2) +' 至 '+editedItem.evaluation.map(item => item.start)[5]"
                  suffix="%"
                  step="0.01"
                  hide-details
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.evaluation[5].scale"
                  :rules="[rules.required]"
                  type="number"
                  :label="(parseFloat(editedItem.evaluation.map(item => item.start)[5]) + 0.01).toFixed(2) +' 至 '+editedItem.evaluation.map(item => item.start)[6]"
                  suffix="%"
                  step="0.01"
                  hide-details
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.evaluation[6].scale"
                  :rules="[rules.required]"
                  type="number"
                  :label="(parseFloat(editedItem.evaluation.map(item => item.start)[6]) + 0.01).toFixed(2) +' 至 '+editedItem.evaluation.map(item => item.start)[7]"
                  suffix="%"
                  step="0.01"
                  hide-details
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.evaluation[7].scale"
                  :rules="[rules.required]"
                  type="number"
                  :label="(parseFloat(editedItem.evaluation.map(item => item.start)[7]) + 0.01).toFixed(2) +' 至 '+editedItem.evaluation.map(item => item.start)[8]"
                  suffix="%"
                  step="0.01"
                  hide-details
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.evaluation[8].scale"
                  :rules="[rules.required]"
                  type="number"
                  :label="'大于'+ editedItem.evaluation.map(item => item.start)[8]"
                  suffix="%"
                  step="0.01"
                  hide-details
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeItem">取消</v-btn>
            <v-btn depressed color="secondary" @click="submitItem" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {SET_ERROR} from "@/store/mutations.type";
import {
  FETCH_RENT_INDUSTRY_LIST,
  PUBLISH_RENT_INDUSTRY,
  EDIT_RENT_INDUSTRY,
  ACTIVE_RENT_INDUSTRY,
  FETCH_RENT_INDUSTRY,
} from "@/store/actions.type";
import VueSlider from 'vue-slider-component'

export default {
  components: {
    VueSlider
  },
  data() {
    return {
      query: {
        key: '',
      },
      defaultQuery: {
        key: '',
      },
      headerTable: [
        {text: '行业名称', value: 'name'},
        {text: '行业代码', value: 'code' },
        {text: 'GB行业代码', value: 'GB.name' },
        {text: 'GB行业名称', value: 'GB.code' },
        {text: '是否启用', value: 'isEnable' },
        {text: '更新时间', value: 'updateTime' },
        {text: '最后编辑', value: 'editor' },
        {text: '操作', value: 'action', sortable: false}
      ],
      optionsTable: {},
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedItem: {
        name: null,
        code: null,
        GB: {
          name: null,
          code: null,
        },
        evaluation: [
          { start: 0, end: 0, scale: 0 },
          { start: 0, end: 0, scale: 0 },
          { start: 0, end: 0, scale: 0 },
          { start: 0, end: 0, scale: 0 },
          { start: 0, end: 0, scale: 0 },
          { start: 0, end: 0, scale: 0 },
          { start: 0, end: 0, scale: 0 },
          { start: 0, end: 0, scale: 0 },
          { start: 0, end: 0, scale: 0 },
          { start: 0, end: 0, scale: 0 },
        ],
      },
      defaultItem: {
        name: null,
        code: null,
        GB: {
          name: null,
          code: null,
        },
        eval_init_scale: 0,
        evaluation: [
          { start: -4.6, end: -3.89, scale: 2.5 },
          { start: -3.9, end: -3.19, scale: 5 },
          { start: -3.2, end: -2.49, scale: 7.5 },
          { start: -2.5, end: -1.79, scale: 10 },
          { start: -1.8, end: -1.09, scale: 12.5 },
          { start: -1.1, end: -0.39, scale: 15 },
          { start: -0.4, end: 0.29, scale: 17.5 },
          { start: 0.3, end: 0.99, scale: 20 },
          { start: 1.0, end: 2, scale: 25 },
        ],
      },
      slider_options: {
        dotSize: 14,
        width: 'auto',
        height: 4,
        contained: true,
        direction: 'ltr',
        data: null,
        dataLabel: 'label',
        dataValue: 'value',
        min: -5,
        max: 2,
        interval: 0.01,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: false,
        lazy: false,
        tooltip: 'always',
        tooltipPlacement: 'top',
        useKeyboard: false,
        keydownHook: null,
        dragOnClick: false,
        enableCross: false,
        fixed: false,
        order: true,
        marks: {
          '-5': '高',
          '-2': '较高',
          '1': '有限',
        },
        process: true,
      }
    }
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "rentIndustryLoading",
      "rentIndustryList",
      "rentIndustryCount",
    ]),
    arrSlider: {
      get() {
        return this.editedItem.evaluation.map(item => item.start)
      },
      set(val) {
        val.map((item,index) => this.editedItem.evaluation[index].start = item)
      }
    }
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.closeItem()
    },
  },
  methods: {
    getDataList(query = {}) {
      // 获取分页信息
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (query.key) urlQuery += '&key=' + query.key
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_RENT_INDUSTRY_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    createItem() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dlgTitle = '创建行业信息'
      this.dlgEdit = true
    },
    editItem(item) {
      store.dispatch(FETCH_RENT_INDUSTRY, item._id)
        .then((data) => {
          this.editedItem = JSON.parse(JSON.stringify(Object.assign(this.editedItem, data)))
          this.dlgTitle = '编辑行业信息'
          this.dlgEdit = true
        })
    },
    closeItem() {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    searchItem() {
      if (this.query.key !== '') {
        this.getDataList({key: this.query.key})
      } else {
        this.getDataList()
      }
    },
    submitItem() {
      if (this.editedItem._id) {
        store.dispatch(EDIT_RENT_INDUSTRY, this.editedItem)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.closeItem()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_RENT_INDUSTRY, this.editedItem)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getDataList()
            this.closeItem()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
    setEnable(item, value) {
      if (confirm(`确定要 ${value ? '启用' : '禁用'} ${item.name}？`)) {
        store.dispatch(ACTIVE_RENT_INDUSTRY, {_id: item._id, reply: value})
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.closeItem()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
  }

}

</script>
<style>
/* component style */
.vue-slider-disabled .vue-slider-rail {
  background-color: #ccc;
}
.vue-slider-disabled .vue-slider-dot-handle {
  background-color: #666;
}
.vue-slider-disabled .vue-slider-process {
  background-color: #666;
}
.vue-slider-disabled .vue-slider-mark-step {
  background-color: #666;
}
.vue-slider-disabled .vue-slider-mark-step-active {
  background-color: #ccc;
}

/* rail style */
.vue-slider-rail {
  background-color: #424242;
  border-radius: 15px;
}

/* process style */
.vue-slider-process {
  background-color: #d41746;
  border-radius: 15px;
}

/* mark style */
.vue-slider-mark {
  z-index: 4;
}
.vue-slider-mark-step {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #d41746;
}
.vue-slider-mark-step-active {
  background-color: #424242;
}

.vue-slider-mark-label {
  font-size: 14px;
  white-space: nowrap;
}
/* dot style */
.vue-slider-dot-handle {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #d41746;
  box-sizing: border-box;
}
.vue-slider-dot-handle::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 200%;
  height: 200%;
  background-color: rgba(212, 23, 70, 0.38);
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: -1;
  transition: transform 0.2s;
}
.vue-slider-dot-handle-focus::after {
  transform: translate(-50%, -50%) scale(1);
}

.vue-slider-dot-handle-disabled {
  cursor: not-allowed;
  background-color: #666 !important;
}

.vue-slider-dot-tooltip {
  visibility: visible;
}
.vue-slider-dot-tooltip-show .vue-slider-dot-tooltip-inner {
  opacity: 1;
}
.vue-slider-dot-tooltip-show .vue-slider-dot-tooltip-inner-top {
  transform: rotateZ(-45deg);
}
.vue-slider-dot-tooltip-show .vue-slider-dot-tooltip-inner-bottom {
  transform: rotateZ(135deg);
}
.vue-slider-dot-tooltip-show .vue-slider-dot-tooltip-inner-left {
  transform: rotateZ(-135deg);
}
.vue-slider-dot-tooltip-show .vue-slider-dot-tooltip-inner-right {
  transform: rotateZ(45deg);
}

.vue-slider-dot-tooltip-inner {
  border-radius: 50% 50% 50% 0px;
  background-color: #d41746;
  opacity: 0;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.2s linear;
}
.vue-slider-dot-tooltip-inner-top {
  transform: translate(0, 50%) scale(0.01) rotate(-45deg);
}
.vue-slider-dot-tooltip-inner-bottom {
  transform: translate(0, -50%) scale(0.01) rotateZ(135deg);
}
.vue-slider-dot-tooltip-inner-left {
  transform: translate(50%, 0) scale(0.01) rotateZ(-135deg);
}
.vue-slider-dot-tooltip-inner-right {
  transform: translate(-50%, 0) scale(0.01) rotateZ(45deg);
}
.vue-slider-dot-tooltip-text {
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
}

.vue-slider-dot-tooltip-inner-top .vue-slider-dot-tooltip-text {
  transform: rotateZ(45deg);
}
.vue-slider-dot-tooltip-inner-bottom .vue-slider-dot-tooltip-text {
  transform: rotateZ(-135deg);
}
.vue-slider-dot-tooltip-inner-left .vue-slider-dot-tooltip-text {
  transform: rotateZ(135deg);
}
.vue-slider-dot-tooltip-inner-right .vue-slider-dot-tooltip-text {
  transform: rotateZ(-45deg);
}

/*# sourceMappingURL=material.css.map */
</style>
