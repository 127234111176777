<template>
  <v-container>
    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-btn icon @click="$router.push('/dealer/workbench')">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>外采请求列表</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="searchItem"
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索请求编号、企业PO号..."
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
      </v-toolbar>
      <v-alert dense type="info" class="ma-2" dismissible>
        外采请求是交付企业订单产品时需要进行外部采购的产品清单，通过外采请求处理完成采购成本、利润率估算等内容。
      </v-alert>
      <v-data-table
        :options.sync="optionsItem"
        :headers="computedHeaders"
        :items="purchaseList"
        :server-items-length="purchaseCount"
        :loading="purchaseLoading"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10, 15, 30, 50]}"
        class="elevation-0 mt-4"
        transition
        fixed-header
        height="600"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-tabs align-with-title v-model="active_tab">
              <v-tabs-slider color="secondary"></v-tabs-slider>
              <v-tab @click="todoList('all')">全部工单</v-tab>
              <v-tab @click="todoList('todo')" v-if="todoPurchaseCount">
                <v-badge color="secondary" :content="todoPurchaseCount">
                  我的待办
                </v-badge>
              </v-tab>
              <v-tab @click="todoList('follow')">我的关注</v-tab>
            </v-tabs>
            <widgets-custom-header :defaultHeader="defaultHeader" :headerList="headerItem" :version=3 tableName="purchaseHeader" v-on:Cancel="cancelHeader" v-on:Default="setDefaultHeader" v-on:Save="setHeader"></widgets-custom-header>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="filterDrawer = !filterDrawer">
                  <v-icon small>filter_list</v-icon>
                </v-btn>
              </template>
              <span>过滤数据</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item.request_number="{ item }">
          <td nowrap="true">
            <v-btn icon color="secondary" v-if="item.isMark" @click="markCase(item._id, false)"><v-icon transition="scale-transition">star</v-icon></v-btn>
            <v-btn icon v-else @click="markCase(item._id, true)"><v-icon transition="scale-transition">star_outline</v-icon></v-btn>
            <v-badge color="secondary" dot :value="item.editor._id === currentUser._id && item.status === 0">
              <purchase-dialogs :getId="item._id" :key="item._id" @Update="getList(query)">
                {{item.request_number}}
                <v-icon v-if="item.editor._id === currentUser._id && item.status === 1" right small>create</v-icon>
              </purchase-dialogs>
            </v-badge>
          </td>
        </template>
        <template v-slot:item.enterprise="{ item }">
          <td nowrap="true">{{ item.enterprise.name }}-{{item.branch}}</td>
        </template>
        <template v-slot:item.enterprise="{ item }">
          <td nowrap="true" v-if="item.enterprise" class="v-list-item--link text-truncate" @click="openEnterInfo(item.enterprise._id)">
            {{ item.enterprise.name }}<br v-if="item.branch">{{item.branch}}
          </td>
        </template>
        <template v-slot:item.product_name_list="{ item }">
          <td nowrap="true">
            <div class="my-2" v-for="(pn,i) in item.product_name_list" :key="i">
              <v-chip label small style="width: 200px;">
                <span class="text-truncate" style="max-width: 200px;">{{ pn }}</span>
              </v-chip>
            </div>
          </td>
        </template>
        <template v-slot:item.product_part_list="{ item }">
          <td nowrap="true">
            <div class="my-2" v-for="(pn,i) in item.product_part_list" :key="i">
              <v-chip label small style="width: 150px;">
                <span class="text-truncate" style="max-width: 150px;">{{ pn }}</span>
              </v-chip>
            </div>
          </td>
        </template>
        <template v-slot:item.product_type_list="{ item }">
          <td nowrap="true">
            <div class="my-2" v-for="(pn,i) in item.product_type_list" :key="i">
              <v-chip label small style="width: 150px;">
                <span class="text-truncate" style="max-width: 150px;">{{ pn }}</span>
              </v-chip>
            </div>
          </td>
        </template>
        <template v-slot:item.product_vendor_list="{ item }">
          <td nowrap="true">
            <div class="my-2" v-for="(pn,i) in item.product_vendor_list" :key="i">
              <v-chip label small style="width: 150px;">
                <span class="text-truncate" style="max-width: 150px;">{{ pn }}</span>
              </v-chip>
            </div>
          </td>
        </template>
        <template v-slot:item.product_quantity_list="{ item }">
          <div class="my-2" v-for="(pn,i) in item.product_quantity_list" :key="i">
            <v-chip label small>
              <span style="min-width:40px;" class="text-center">{{ pn }}</span>
            </v-chip>
          </div>
        </template>
        <template v-slot:item.product_sales_price_list="{ item }">
          <div class="my-2" v-for="(pn,i) in item.product_sales_price_list" :key="i">
            <v-chip label small>
              <span style="min-width:75px;" class="text-right">{{ pn.$numberDecimal | formatMoney }}</span>
            </v-chip>
          </div>
        </template>
        <template v-slot:item.product_purchase_price_list="{ item }">
          <div class="my-2" v-for="(pn,i) in item.product_purchase_price_list" :key="i">
            <v-chip label small>
              <span style="min-width:75px;" class="text-right">{{ pn.$numberDecimal | formatMoney }}</span>
            </v-chip>
          </div>
        </template>
        <template v-slot:item.product_purchase_margin_list="{ item }">
          <div class="my-2" v-for="(pn,i) in item.product_purchase_margin_list" :key="i">
            <v-chip label small>
              <span style="min-width:75px;" class="text-right">{{ parseFloat(pn * 100).toFixed(2) }}%</span>
            </v-chip>
          </div>
        </template>
        <template v-slot:item.product_purchase_area_list="{ item }">
          <div class="my-2" v-for="(pn,i) in item.product_purchase_area_list" :key="i">
            <v-chip label small>
              <span style="min-width:75px;" class="text-right">{{ pn }}</span>
            </v-chip>
          </div>
        </template>
        <template v-slot:item.product_purchase_delivery_list="{ item }">
          <div class="my-2" v-for="(pn,i) in item.product_purchase_delivery_list" :key="i">
            <v-chip label small>
              <span style="min-width:75px;" class="text-right">{{ pn }}</span>
            </v-chip>
          </div>
        </template>
        <template v-slot:item.editor="{ item }">
          <td nowrap="true" v-if="item.editor && item.editor.personal">{{ item.editor.personal.name }}</td>
        </template>
        <template v-slot:item.updateTime="{ item }">
          <td nowrap="true">{{ item.updateTime | formatTime }}</td>
        </template>
        <template v-slot:item.status="{ item }">
          <td nowrap="true">
            <v-chip label small v-if="item.status === 0" color="secondary">待处理</v-chip>
            <v-chip label small v-else-if="item.status === 1" color="primary">处理中</v-chip>
            <v-chip label small v-else-if="item.status === 2">已取消</v-chip>
            <v-chip label small v-else>已完成</v-chip>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="enterInfoDlg" scrollable max-width="600px" persistent>
      <widgets-enterprise-dialogs
        :id="activeId"
        :key="activeId"
        v-on:Close="closeEnterInfo"
      ></widgets-enterprise-dialogs>
    </v-dialog>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">处理状态</h2>
        <v-chip-group v-if="purchaseStatus.length" v-model="query.status" column multiple @change="setFilter">
          <v-chip v-for="orders in purchaseStatus" :key="orders.code" :value="orders.code" filter label outlined>{{orders.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2">客户</h2>
        <v-autocomplete
          v-model="query.enterprise"
          label="请输入客户名称"
          autocomplete="off"
          :items="dealer_enterprise"
          item-text="name"
          item-value="_id"
          hide-no-data
          outlined
          dense
          clearable
          @change="setFilterBranch"
        ></v-autocomplete>
        <v-autocomplete
          autocomplete="off"
          v-model="query.branch"
          :items="arrBranch"
          @change="setFilter"
          label="分支机构"
          hint="请选择企业的分支机构"
          outlined
          dense
          clearable
        ></v-autocomplete>
        <h2 class="subtitle-2">产品类型</h2>
        <v-chip-group v-model="query.type" column multiple @change="setFilter">
          <v-chip v-for="item in typeList" :key="item._id" :value="item._id" filter label outlined>{{item.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">厂商品牌</h2>
        <v-autocomplete
          class="mt-2"
          v-model="query.vendor"
          :items="vendorList"
          @change="setFilter"
          item-text="name"
          item-value="_id"
          autocomplete="off"
          outlined
          dense
          label="厂商品牌"
          hint="请选择产品厂商品牌"
          chips
          deletable-chips
          multiple
          small-chips
        >
        </v-autocomplete>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDrawer = !filterDrawer">隐藏</v-btn>
          <v-btn color="secondary" text @click="clearFilter">清空</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import store from "@/store";
import { mapGetters } from "vuex";
import {
  FETCH_DEALER_ENTERPRISE,
  FETCH_PURCHASE_LIST,
  ADD_MARK_PURCHASE,
  REMOVE_MARK_PURCHASE, FETCH_PRODUCTTYPE_LIST, FETCH_VENDOR_LIST,
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import Util from "@/common/util";

export default {
  data() {
    return {
      query: {
        key: "",
        enterprise: "",
        branch: '',
        status: [],
        vendor: [],
        type: [],
      },
      defaultQuery: {
        key: "",
        enterprise: "",
        branch: '',
        status: [],
        vendor: [],
        type: [],
      },
      headerItem: [],
      optionsItem: {},
      defaultHeader: [
        { text: "工单编号", value: "request_number" },
        { text: '产品名称', value: 'product_name_list', sortable: false},
        { text: '企业', value: 'enterprise', align: " d-none", sortable: false},
        { text: '产品型号', value: 'product_part_list', align: " d-none", sortable: false},
        { text: '产品类别', value: 'product_type_list', align: " d-none", sortable: false},
        { text: '品牌厂商', value: 'product_vendor_list', align: " d-none", sortable: false},
        { text: '产品数量', value: 'product_quantity_list', align: " d-none", sortable: false},
        { text: '销售单价', value: 'product_sales_price_list', align: " d-none", sortable: false},
        { text: '采购单价', value: 'product_purchase_price_list', sortable: false},
        { text: '利润率', value: 'product_purchase_margin_list', sortable: false},
        { text: '采购区域', value: 'product_purchase_area_list', align: " d-none", sortable: false},
        { text: '采购货期', value: 'product_purchase_delivery_list', align: " d-none", sortable: false},
        { text: '采购处理人', value: 'editor'},
        { text: '更新时间', value: 'updateTime'},
        { text: '状态', value: 'status', align: " d-none", width: '100px'},
      ],
      active_tab: 0,
      filterDrawer: false,
      arrBranch: [],
      activeId: '',
      enterInfoDlg: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_DEALER_ENTERPRISE),
      store.dispatch(FETCH_PRODUCTTYPE_LIST, 'isEnable=true'),
      store.dispatch(FETCH_VENDOR_LIST, 'limit=-1&isEnable=true'),
    ]).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "dealer_enterprise",
      "purchaseLoading",
      "purchaseList",
      "purchaseCount",
      "todoPurchaseCount",
      "vendorList",
      "typeList",
    ]),
    computedHeaders() {
      let arrHeaderItem = this.headerItem
      return arrHeaderItem.filter(item => !item.align)
    },
    purchaseStatus () {
      return Util.categories('purchaseStatus')
    },
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
  },
  created() {
    this.getHeader('purchaseHeader', 3)
  },
  methods: {
    getHeader(model, version) {
      let userHeader = this.currentUser[model];
      if (userHeader.content.length) {
        if (userHeader.version < version) {
          this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
        } else {
          this.headerItem = JSON.parse(JSON.stringify(userHeader.content))
        }
      } else {
        this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
      }
    },
    searchItem() {
      this.getList(this.query);
    },
    todoList(query = 'all') {
      this.query = Object.assign({}, this.defaultQuery)
      if (query === 'todo') this.query.todo = 'yes'
      if (query === 'follow') this.query.follow = this.currentUser._id
      this.getList();
    },
    markCase(itemId, result) {
      let actCode = ADD_MARK_PURCHASE
      if (!result) actCode = REMOVE_MARK_PURCHASE
      store.dispatch(actCode, itemId)
        .then(() => {
          store.commit(SET_ERROR, {msg: '操作成功', color: 'primary'});
          this.getList()
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    getList(query = {}) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query)
        if (this.query.status && !Array.isArray(this.query.status)) this.query.status = this.query.status.split(',')
      }

      let urlQuery = "";
      if (query.key) urlQuery += "&key=" + query.key
      if (query.enterprise) urlQuery += "&enterprise=" + query.enterprise
      if (query.branch) urlQuery += '&branch='+ query.branch
      if (this.query.vendor.length) urlQuery += '&vendor='+ this.query.vendor
      if (this.query.type.length) urlQuery += '&type='+ this.query.type
      if (this.query.status.length) urlQuery += '&status='+ query.status
      if (this.query.todo) urlQuery += '&todo='+ this.query.todo
      if (this.query.follow) urlQuery += '&follow='+ this.query.follow
      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_PURCHASE_LIST, "page=" + page + "&limit=" + itemsPerPage + "&sort=" + sort + urlQuery)
      }
    },
    cancelHeader() {
      this.getHeader('purchaseHeader', 3)
      this.$forceUpdate()
    },
    setDefaultHeader() {

    },
    setHeader() {
      this.getHeader('purchaseHeader', 3)
      this.$forceUpdate()
    },
    setFilter() {
      this.$router.push({ query: {} });
      this.getList(this.query)
    },
    setFilterBranch() {
      if (this.query.enterprise) {
        let objEnterprise = this.dealer_enterprise.find(v => v._id === this.query.enterprise)
        if (objEnterprise && objEnterprise.branch) this.arrBranch = [...objEnterprise.branch]
      } else {
        this.arrBranch = []
      }
      this.setFilter()
    },
    openEnterInfo(id) {
      this.activeId = id;
      this.enterInfoDlg = true;
    },
    closeEnterInfo() {
      this.enterInfoDlg = false;
    },
    clearFilter() {
      this.query = Object.assign({}, this.defaultQuery);
      this.$router.push({ query: {} });
      this.getList();
    }
  }
}
</script>
<style scoped>

</style>
