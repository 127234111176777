var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "secondary",
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/dealer/workbench/rent/list');
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('span', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.offerData.title))]), _c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editOffer('title', '报价名称');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("edit")])], 1), _vm.offerData.expiry_date <= new Date().toISOString() ? _c('v-chip', {
    staticClass: "ml-4",
    attrs: {
      "label": "",
      "small": "",
      "outlined": ""
    }
  }, [_vm._v("已过期")]) : _vm.offerData.status == '0' ? _c('v-chip', {
    staticClass: "ml-4",
    attrs: {
      "label": "",
      "small": "",
      "outlined": ""
    }
  }, [_vm._v("已保存")]) : _vm.offerData.status == '1' ? _c('v-chip', {
    staticClass: "ml-4",
    attrs: {
      "label": "",
      "small": "",
      "outlined": ""
    }
  }, [_vm._v("已审核")]) : _vm.offerData.status == '2' ? _c('v-chip', {
    staticClass: "ml-4",
    attrs: {
      "label": "",
      "small": "",
      "outlined": ""
    }
  }, [_vm._v("已更新")]) : _vm._e(), _c('v-spacer')], 1), _c('v-divider'), _c('v-card-text', [_c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "accordion": "",
      "flat": ""
    },
    model: {
      value: _vm.offerPanels,
      callback: function callback($$v) {
        _vm.offerPanels = $$v;
      },
      expression: "offerPanels"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("基本信息")])]), _c('v-expansion-panel-content', [_c('v-sheet', {
    staticClass: "mx-auto"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_c('v-img', {
    attrs: {
      "src": "/static/logo2x.png",
      "width": "40"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("客户")]), _vm.offerData.enterprise ? _c('v-list-item-subtitle', {
    staticClass: "v-list-item--link"
  }, [_c('span', {
    on: {
      "click": function click($event) {
        return _vm.openEnterInfo(_vm.offerData.enterprise.id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.offerData.enterprise.name) + " ")])]) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("产品及规格")]), _vm.offerData.product_list ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.offerData.product_list.length > 1 ? "系列产品" : _vm.offerData.product_list[0].name) + " ")]) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editOffer('rent_type', '租赁方式');
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("租赁方式"), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.offerData.rent_type === 0 ? "融资租赁" : "经营性租赁"))])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editOffer('lease_start', '起租时间');
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("起租时间"), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.offerData.lease_start)))])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("销售人员")]), _vm.offerData.creator ? _c('v-list-item-subtitle', [_c('widgets-employee-dialogs', {
    staticClass: "v-list-item--link",
    attrs: {
      "account": _vm.offerData.creator._id
    }
  }, [_vm._v(_vm._s(_vm.offerData.creator.personal ? _vm.offerData.creator.personal.name : "*未设置姓名")), _c('v-icon', {
    staticClass: "mx-2"
  }, [_vm._v("read_more")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title')], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editOffer('payment_period', '账期');
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("账期"), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.offerData.payment_period) + "天")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" 官网价格 "), _vm.offerData.product_list ? _c('span', {
    staticClass: "grey--text ml-2 body-2"
  }, [_vm._v(" " + _vm._s(_vm.offerData.product_list.length > 1 ? "系列产品" : "¥" + _vm.offerData.product_list[0].official_price.$numberDecimal))]) : _vm._e()]), _c('v-list-item-title', [_vm._v(" 采购价格 "), _vm.offerData.product_list ? _c('span', {
    staticClass: "grey--text ml-2 body-2"
  }, [_vm._v(" " + _vm._s(_vm.offerData.product_list.length > 1 ? "系列产品" : "¥" + _vm.offerData.product_list[0].purchase_price.$numberDecimal))]) : _vm._e()])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editOffer('lease_term', '租期');
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("租期"), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.offerData.lease_term / 365) + "年")])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("结束时间")]), _vm.offerData.lease_start ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(new Date(new Date(_vm.offerData.lease_start).setFullYear(new Date(_vm.offerData.lease_start).getFullYear() + _vm.offerData.lease_term / 365)).toISOString().substr(0, 10)))]) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("核价助理")]), _vm.offerData.reviewer ? _c('v-list-item-subtitle', [_c('widgets-employee-dialogs', {
    staticClass: "v-list-item--link",
    attrs: {
      "account": _vm.offerData.reviewer._id
    }
  }, [_vm._v(_vm._s(_vm.offerData.reviewer.personal ? _vm.offerData.reviewer.personal.name : "*未设置姓名")), _c('v-icon', {
    staticClass: "mx-2"
  }, [_vm._v("read_more")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title')], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editOffer('payment_day', '每月付款日');
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("每月付款日"), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.offerData.payment_day))])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("产品总数量")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.offerData.quantity_total))])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editOffer('payment_cycle', '支付周期');
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("支付周期"), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("edit")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.offerData.payment_cycle === 0 ? "月付" : "季付"))])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("总期数")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.offerData.NPER))])], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("核价过期时间")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.offerData.expiry_date)))])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("产品列表")])]), _c('v-expansion-panel-content', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "headers": _vm.multheaders,
      "items": _vm.linkedProduct,
      "item-key": "index",
      "hide-default-footer": "",
      "items-per-page": 100
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": "primary",
            "dark": "",
            "small": ""
          },
          on: {
            "click": function click($event) {
              _vm.productDialog = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("link")]), _vm._v(" 关联产品 ")], 1), _c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": "primary",
            "dark": "",
            "small": ""
          },
          on: {
            "click": _vm.editProInfo
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("add")]), _vm._v(" 新增产品 ")], 1)];
      },
      proxy: true
    }, {
      key: "body.append",
      fn: function fn() {
        return [_c('tr', {
          staticClass: "grey lighten-3 secondary--text",
          staticStyle: {
            "width": "100%"
          }
        }, [_c('td', [_vm._v("合计")]), _c('td'), _c('td'), _c('td'), _c('td', [_c('div', {
          staticClass: "pl-4"
        }, [_vm._v(_vm._s(_vm.offerData.quantity_total))])]), _c('td'), _c('td'), _c('td'), _c('td'), _c('td'), _vm.offerData.rent_price_sum_total ? _c('td', [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(_vm.offerData.rent_price_sum_total.$numberDecimal).toFixed(2))) + " ")]) : _vm._e(), _vm.offerData.rent_price_total_total ? _c('td', [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(_vm.offerData.rent_price_total_total.$numberDecimal).toFixed(2))) + " ")]) : _vm._e(), _vm.offerData.purchase_price_total ? _c('td', [_c('span', {
          staticClass: "pl-4"
        }, [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(_vm.offerData.purchase_price_total.$numberDecimal).toFixed(2))) + " ")])]) : _vm._e(), _c('td'), _c('td', [_c('span', {
          staticClass: "ml-6"
        }, [_vm._v(" " + _vm._s(Number(_vm.offerData.rent_ros_total * 100).toFixed(2)) + "% ")])]), _c('td', [_c('span', [_vm._v(" " + _vm._s(_vm.offerData.rentWin ? "租" : "买") + " ")]), _c('v-btn', {
          staticClass: "ml-1",
          attrs: {
            "icon": "",
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openCompareDlg('');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "blue"
          }
        }, [_vm._v("compare_arrows")])], 1)], 1), _c('td'), _vm.pLevel == 2 || _vm.pLevel == 3 ? _c('td') : _vm._e(), _vm.pLevel == 2 || _vm.pLevel == 3 ? _c('td') : _vm._e(), _vm.pLevel == 3 ? _c('td') : _vm._e(), _vm.pLevel == 3 && _vm.offerData.rent_cost_unit_total ? _c('td', [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(_vm.offerData.rent_cost_unit_total.$numberDecimal).toFixed(2))) + " ")]) : _vm._e(), _vm.pLevel == 3 ? _c('td') : _vm._e(), _vm.pLevel == 3 ? _c('td') : _vm._e(), _vm.pLevel == 3 ? _c('td') : _vm._e(), _vm.pLevel == 3 ? _c('td') : _vm._e(), _vm.pLevel == 3 ? _c('td') : _vm._e(), _vm.pLevel == 2 || _vm.pLevel == 3 ? _c('td') : _vm._e(), _c('td', [_vm._v(_vm._s(Number(_vm.offerData.gross_margin_own_total * 100).toFixed(2)) + "%")]), _c('td', [_vm._v(_vm._s(Number(_vm.offerData.gross_margin_year_total * 100).toFixed(2)) + "%")])])];
      },
      proxy: true
    }, {
      key: "item.liyun",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.liyun
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "liyun", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "liyun", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('div', {
                staticClass: "mt-4 subtitle-1"
              }, [_vm._v("修改：销售利润率")]), _c('v-text-field', {
                attrs: {
                  "label": "利润率",
                  "single-line": "",
                  "suffix": "%"
                },
                model: {
                  value: props.item.liyun,
                  callback: function callback($$v) {
                    _vm.$set(props.item, "liyun", $$v);
                  },
                  expression: "props.item.liyun"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": "",
            "color": "warning"
          }
        }, [_vm._v("edit")]), _vm._v(" " + _vm._s(props.item.liyun + "%") + " ")], 1)])];
      }
    }, {
      key: "item.remove",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.removeLinked(item);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("remove")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("移除")])])];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.product_id ? _c('td', {
          staticClass: "text-truncate",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "nowrap": "true",
            "width": "180"
          },
          on: {
            "click": function click($event) {
              return _vm.openProInfo(item.product_id);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v("dashboard")]), _vm._v(" " + _vm._s(item.product_code || item.name) + " ")], 1) : _c('td', {
          staticClass: "text-truncate",
          attrs: {
            "nowrap": "true",
            "width": "180"
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "small": ""
          }
        }, [_vm._v("dashboard_customize")]), _vm._v(" " + _vm._s(item.product_code || item.name) + " ")], 1)];
      }
    }, {
      key: "item.official_price",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(Number(item.official_price.$numberDecimal).toFixed(2))))])];
      }
    }, {
      key: "item.product_price_unit",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(_vm._s(!item.isWarranty ? "¥" + Number(item.product_price_unit.$numberDecimal).toFixed(2) : "-"))])];
      }
    }, {
      key: "item.isWarranty",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('product_list', '是否延保', item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v(" " + _vm._s(item.isWarranty ? "是" : "否") + " ")], 1)];
      }
    }, {
      key: "item.quantity",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('product_list', '数量', item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v(_vm._s(item.quantity))], 1)];
      }
    }, {
      key: "item.warranty_price_unit",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm.offerData.lease_term > 365 ? _c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('product_list', '延保单价', item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v("¥" + _vm._s(Number(item.warranty_price_unit.$numberDecimal).toFixed(2)))], 1) : _c('span', [_vm._v("¥" + _vm._s(Number(item.warranty_price_unit.$numberDecimal).toFixed(2)))])];
      }
    }, {
      key: "item.deposit_price",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('product_list', '保证金', item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v("¥" + _vm._s(_vm._f("formatMoney")(Number(item.deposit_price.$numberDecimal).toFixed(2))))], 1)];
      }
    }, {
      key: "item.rent_price_unit",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('span', [_vm._v(_vm._s(item.isWarranty ? "¥" + Number(item.rent_price_unit.$numberDecimal).toFixed(2) : "-"))])];
      }
    }, {
      key: "item.rent_price_sum",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('span', [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(Number(item.rent_price_sum.$numberDecimal).toFixed(2))))])];
      }
    }, {
      key: "item.rent_price_total",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('span', [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(Number(item.rent_price_total.$numberDecimal).toFixed(2))))])];
      }
    }, {
      key: "item.purchase_price",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('product_list', '采购单价', item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v("¥" + _vm._s(_vm._f("formatMoney")(Number(item.purchase_price.$numberDecimal).toFixed(2))))], 1)];
      }
    }, {
      key: "item.residual_values_unit",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('product_list', '到期余值', item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v("¥" + _vm._s(_vm._f("formatMoney")(Number(item.residual_values_unit.$numberDecimal).toFixed(2))))], 1)];
      }
    }, {
      key: "item.rent_cost_unit",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('span', [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(Number(item.rent_cost_unit.$numberDecimal).toFixed(2))))])];
      }
    }, {
      key: "item.ros_rents",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('product_list', '报价系数', item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v(" " + _vm._s((Number(item.ros_rents) * 100).toFixed(2)) + "% ")], 1)];
      }
    }, {
      key: "item.ros_sales",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('ros_sales', '销售利润率', item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v(" " + _vm._s((Number(item.ros_sales) * 100).toFixed(2)) + "% ")], 1)];
      }
    }, {
      key: "item.sales_total",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.sales_total.$numberDecimal).toFixed(2)))])];
      }
    }, {
      key: "item.CPA",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('CPA', '客户资金ROE', item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v(" " + _vm._s((Number(item.CPA) * 100).toFixed(2)) + "% ")], 1)];
      }
    }, {
      key: "item.rent_convert_cash",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.rent_convert_cash).toFixed(2)))])];
      }
    }, {
      key: "item.rentWin",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [_c('span', [_vm._v(_vm._s(item.rentWin ? "租" : "买"))]), _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "icon": "",
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.openCompareDlg(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "blue"
          }
        }, [_vm._v("compare_arrows")])], 1)];
      }
    }, {
      key: "item.max_recycle_price",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.max_recycle_price.$numberDecimal).toFixed(2)))])];
      }
    }, {
      key: "item.cv_init",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('cv_init', '风控系数', item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v(" " + _vm._s((Number(item.cv_init) * 100).toFixed(2)) + "% ")], 1)];
      }
    }, {
      key: "item.cv_period",
      fn: function fn(_ref24) {
        var item = _ref24.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('cv_period', '风险账期', item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v(" " + _vm._s(item.cv_period) + "天 ")], 1)];
      }
    }, {
      key: "item.APR",
      fn: function fn(_ref25) {
        var item = _ref25.item;
        return [_c('span', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.editOffer('product_list', '资金成本', item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("edit")]), _vm._v(" " + _vm._s((Number(item.APR) * 100).toFixed(2)) + "% ")], 1)];
      }
    }, {
      key: "item.gross_profit_unit",
      fn: function fn(_ref26) {
        var item = _ref26.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.gross_profit_unit).toFixed(2)))])];
      }
    }, {
      key: "item.residual_values_ratio",
      fn: function fn(_ref27) {
        var item = _ref27.item;
        return [_c('span', [_vm._v(_vm._s((Number(item.residual_values_ratio) * 100).toFixed(2)) + "%")])];
      }
    }, {
      key: "item.rent_buy_ratio",
      fn: function fn(_ref28) {
        var item = _ref28.item;
        return [_c('span', [_vm._v(_vm._s((Number(item.rent_buy_ratio) * 100).toFixed(2)) + "%")])];
      }
    }, {
      key: "item.equal_total_payment",
      fn: function fn(_ref29) {
        var item = _ref29.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.equal_total_payment.$numberDecimal).toFixed(2)))])];
      }
    }, {
      key: "item.difference_value",
      fn: function fn(_ref30) {
        var item = _ref30.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.difference_value.$numberDecimal).toFixed(2)))])];
      }
    }, {
      key: "item.IRR_composite",
      fn: function fn(_ref31) {
        var item = _ref31.item;
        return [_c('span', [_vm._v(_vm._s((Number(item.IRR_composite) * 100).toFixed(2)) + "%")])];
      }
    }, {
      key: "item.IRR",
      fn: function fn(_ref32) {
        var item = _ref32.item;
        return [_c('span', [_vm._v(_vm._s((Number(item.IRR) * 100).toFixed(2)) + "%")])];
      }
    }, {
      key: "item.gross_margin_own",
      fn: function fn(_ref33) {
        var item = _ref33.item;
        return [_c('span', [_vm._v(_vm._s((Number(item.gross_margin_own) * 100).toFixed(2)) + "%")])];
      }
    }, {
      key: "item.gross_margin_year",
      fn: function fn(_ref34) {
        var item = _ref34.item;
        return [_c('span', [_vm._v(_vm._s((Number(item.gross_margin_year) * 100).toFixed(2)) + "%")])];
      }
    }, {
      key: "expanded-item",
      fn: function fn(_ref35) {
        var headers = _ref35.headers,
            item = _ref35.item;
        return [_c('td', {
          attrs: {
            "colspan": headers.length,
            "align": "right"
          }
        }, _vm._l(item.IRR_list, function (num, i) {
          return _c('span', {
            key: i,
            staticClass: "ml-3"
          }, [_c('span', {
            staticClass: "\n                        red\n                        caption\n                        white--text\n                        secondary\n                        lighten-3\n                      ",
            staticStyle: {
              "width": "20px",
              "height": "18px",
              "border-radius": "4px",
              "line-height": "18px",
              "text-align": "center"
            }
          }, [_vm._v(_vm._s(i + 1))]), _c('span', {
            staticClass: "caption ml-1"
          }, [_vm._v(_vm._s(Number(num.$numberDecimal).toFixed(2)))])]);
        }), 0)];
      }
    }])
  })], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("付款计划表")])]), _c('v-expansion-panel-content', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "headers": _vm.payheaders,
      "items": _vm.offerData.payment_list,
      "item-key": "name",
      "hide-default-footer": "",
      "items-per-page": 36
    },
    scopedSlots: _vm._u([{
      key: "item.startDate",
      fn: function fn(_ref36) {
        var item = _ref36.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.startDate)))])];
      }
    }, {
      key: "item.endDate",
      fn: function fn(_ref37) {
        var item = _ref37.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.endDate)))])];
      }
    }, {
      key: "item.paymentDate",
      fn: function fn(_ref38) {
        var item = _ref38.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.paymentDate)))])];
      }
    }, {
      key: "item.paymentAmount",
      fn: function fn(_ref39) {
        var item = _ref39.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(" ¥" + _vm._s(_vm._f("formatMoney")(Number(item.paymentAmount.$numberDecimal).toFixed(2))) + " ")])];
      }
    }])
  })], 1)], 1), _c('v-expansion-panel', [_c('v-divider'), _c('v-expansion-panel-header', [_c('span', {
    staticClass: "panelTitle"
  }, [_vm._v("报价单")])]), _c('v-expansion-panel-content', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "headers": _vm.quotationHearder,
      "items": _vm.quoteList,
      "options": _vm.optionsItem,
      "server-items-length": _vm.rentQuoteSheetCount,
      "footer-props": {
        'items-per-page-options': [5, 10, 15]
      },
      "item-key": "version",
      "no-data-text": "暂无报价单"
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.title",
      fn: function fn(_ref40) {
        var item = _ref40.item;
        return [_c('td', [_c('widgets-quote-dialogs', {
          key: item._id,
          attrs: {
            "getId": item._id
          },
          on: {
            "UpdateQuote": _vm.getQuoteList
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1)];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref41) {
        var item = _ref41.item;
        return [_c('td', [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.isDownload",
      fn: function fn(_ref42) {
        var item = _ref42.item;
        return [_c('td', [!item.isDownload ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "secondary"
          }
        }, [_vm._v("未下载")]) : _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已下载")])], 1)];
      }
    }, {
      key: "item.actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("search")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm.pLevel === 3 ? _c('v-btn', {
    attrs: {
      "color": "warning",
      "text": ""
    },
    on: {
      "click": _vm.editParams
    }
  }, [_vm._v(" 修改参数 ")]) : _vm._e(), _vm.pLevel > 0 && _vm.offerData.expiry_date > new Date().toISOString() && (_vm.offerData.status === 0 || _vm.offerData.status === 2) ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.confirmApprove
    }
  }, [_vm._v(" 审核 ")]) : _vm._e(), _vm.offerData.expiry_date > new Date().toISOString() && _vm.offerData.status === 1 ? _c('v-btn', {
    attrs: {
      "color": "secondary darken-1",
      "text": ""
    },
    on: {
      "click": _vm.openNewQuote
    }
  }, [_vm._v(" 生成报价单 ")]) : _vm._e()], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "800px",
      "persistent": ""
    },
    model: {
      value: _vm.comDialog,
      callback: function callback($$v) {
        _vm.comDialog = $$v;
      },
      expression: "comDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._v("对比方案")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeComDlg
    }
  }, [_c('v-icon', [_vm._v("clear")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "px-0"
  }, [_c('div', {
    ref: "compareInfo",
    staticClass: "px-6"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isClientV,
      expression: "isClientV"
    }],
    ref: "waterMark",
    staticClass: "watermarkWrap"
  }, _vm._l(_vm.watermarkNum, function (i, index) {
    return _c('div', {
      key: index,
      staticClass: "wm",
      style: "color:".concat(_vm.textColor, ";transform: rotate(").concat(_vm.rotate, "deg);width:").concat(_vm.wm_w, "%;height:").concat(_vm.wm_h, "%")
    }, [_vm._v(" " + _vm._s(_vm.watermarkText) + " ")]);
  }), 0), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_c('v-img', {
    attrs: {
      "src": "/static/logo2x.png",
      "width": "40"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("客户")]), _vm.offerData.enterprise ? _c('v-list-item-title', {
    staticStyle: {
      "white-space": "normal"
    }
  }, [_c('span', {
    staticClass: "grey--text body-2"
  }, [_vm._v(_vm._s(_vm.offerData.enterprise.name))])]) : _vm._e()], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("产品及规格")]), _c('v-list-item-title', [_c('span', {
    staticClass: "grey--text body-2"
  }, [_vm._v(_vm._s(_vm.compareItem.name || "系列产品"))])])], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title')], 1)], 1)], 1)], 1), !_vm.isClientV ? _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("产品总数量")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.compareItem.quantity))])], 1)], 1)], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "three-line": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" 官网价格 "), _vm.compareItem.official_price ? _c('span', {
    staticClass: "grey--text ml-2 body-2"
  }, [_vm._v("¥" + _vm._s(Number(_vm.compareItem.official_price.$numberDecimal).toFixed(2)))]) : _c('span', {
    staticClass: "grey--text ml-2 body-2"
  }, [_vm._v("系列产品")])]), !_vm.isClientV ? _c('v-list-item-title', [_vm._v(" 采购价格 "), _vm.compareItem.purchase_price ? _c('span', {
    staticClass: "grey--text ml-2 body-2"
  }, [_vm._v("¥" + _vm._s(Number(_vm.compareItem.purchase_price.$numberDecimal).toFixed(2)))]) : _c('span', {
    staticClass: "grey--text ml-2 body-2"
  }, [_vm._v("系列产品")])]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    staticClass: "fill-height",
    attrs: {
      "color": "blue",
      "dark": ""
    }
  }, [_c('v-card-title', {
    staticClass: "font-weight-regular blue lighten-2"
  }, [_vm._v(" 销售方案 "), _c('v-chip', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.compareItem.rentWin,
      expression: "!compareItem.rentWin"
    }],
    staticClass: "ml-2",
    attrs: {
      "color": "orange",
      "label": "",
      "small": ""
    }
  }, [_vm._v("Better One")])], 1), _c('v-divider'), _c('v-card-text', [_c('v-list', {
    attrs: {
      "subheader": "",
      "dark": "",
      "color": "blue"
    }
  }, [!_vm.isClientV ? _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("销售利润率")])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.compareItem.ros_sales) + "%")])], 1)], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("销售报价")])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v("¥" + _vm._s(_vm.compareItem.sales_unit))])], 1)], 1)], 1)], 1), _c('v-list', {
    staticClass: "footerList",
    attrs: {
      "subheader": "",
      "light": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.isClientV ? "采购额机会成本" : "销售额（终值）"))])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v("¥" + _vm._s(Number(_vm.compareItem.purchase_price_final).toFixed(2)))])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title')], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v("----")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.isClientV ? "采购单价" : "销售额（现值）"))])], 1), _c('v-list-item-action', [_vm.compareItem.sales_total ? _c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v("¥" + _vm._s(Number(_vm.compareItem.sales_total.$numberDecimal).toFixed(2)))]) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    staticClass: "fill-height",
    attrs: {
      "color": "secondary lighten-1",
      "dark": ""
    }
  }, [_c('v-card-title', {
    staticClass: "font-weight-regular secondary lighten-2"
  }, [_vm._v(" 租赁方案 "), _c('v-chip', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.compareItem.rentWin,
      expression: "compareItem.rentWin"
    }],
    staticClass: "ml-2",
    attrs: {
      "color": "orange",
      "label": "",
      "small": ""
    }
  }, [_vm._v("Better One")])], 1), _c('v-divider'), _c('v-card-text', [_c('v-list', {
    attrs: {
      "subheader": "",
      "dark": "",
      "color": "secondary lighten-1"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("租赁方式")])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.compareItem.rent_type === 0 ? "融资租赁" : "经营性租赁"))])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("租期")])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.offerData.lease_term / 365) + "年")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("支付周期")])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.offerData.payment_cycle === 0 ? "月付" : "季付"))])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("总期数")])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.offerData.NPER))])], 1)], 1), !_vm.isClientV && _vm.compareItem.residual_values_unit ? _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("到期余值")])], 1), _c('v-list-item-action', [_vm.compareItem.residual_values_unit ? _c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v("¥" + _vm._s(Number(_vm.compareItem.residual_values_unit.$numberDecimal).toFixed(2)))]) : _vm._e()], 1)], 1) : _vm._e(), !_vm.isClientV && _vm.compareItem.max_recycle_price ? _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("最高残值")])], 1), _c('v-list-item-action', [_vm.compareItem.max_recycle_price ? _c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v("¥" + _vm._s(Number(_vm.compareItem.max_recycle_price.$numberDecimal).toFixed(2)))]) : _vm._e()], 1)], 1) : _vm._e(), !_vm.isClientV ? _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("报价系数")])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.compareItem.ros_rents) + "%")])], 1)], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("设备租金单价")])], 1), _c('v-list-item-action', [_vm.compareItem.product_price_unit ? _c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v("¥" + _vm._s(Number(_vm.compareItem.product_price_unit.$numberDecimal).toFixed(2)))]) : _vm._e()], 1)], 1)], 1)], 1), _c('v-list', {
    attrs: {
      "subheader": "",
      "light": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("设备总租金")])], 1), _c('v-list-item-action', [_vm.compareItem.rent_price_total ? _c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v("¥" + _vm._s(Number(_vm.compareItem.rent_price_total.$numberDecimal).toFixed(2)))]) : _vm._e()], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("客户资金ROE")])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.compareItem.CPA) + "%")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("设备总租金折现")])], 1), _c('v-list-item-action', [_vm.compareItem.rent_convert_cash ? _c('v-list-item-action-text', {
    staticClass: "subtitle-1 secondary--text"
  }, [_vm._v("¥" + _vm._s(Number(_vm.compareItem.rent_convert_cash).toFixed(2)))]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _vm.isClientV ? _c('p', {
    staticClass: "pb-6 caption text-right"
  }, [_vm._v(" * 仅供参考 不作为报价依据 ")]) : _vm._e()], 1)]), _c('v-divider'), _c('v-card-actions', [_c('v-switch', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          }
        }, [_vm._v(_vm._s(_vm.isClientV ? "客户视角" : "销售视角"))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.isClientV,
      callback: function callback($$v) {
        _vm.isClientV = $$v;
      },
      expression: "isClientV"
    }
  }), _vm.isClientV ? _c('v-btn', {
    staticClass: "mr-4",
    attrs: {
      "color": "secondary darken-1",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.dlComparePdf('pic');
      }
    }
  }, [_vm._v(" 下载 ")]) : _vm._e(), _c('v-spacer'), !_vm.isTotalCompare ? _c('span', {
    staticStyle: {
      "width": "120px"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "销售利润率",
      "dense": "",
      "hide-details": "",
      "suffix": "%"
    },
    model: {
      value: _vm.compareItem.ros_sales,
      callback: function callback($$v) {
        _vm.$set(_vm.compareItem, "ros_sales", $$v);
      },
      expression: "compareItem.ros_sales"
    }
  })], 1) : _vm._e(), !_vm.isTotalCompare ? _c('span', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "120px"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "报价系数",
      "dense": "",
      "hide-details": "",
      "suffix": "%"
    },
    model: {
      value: _vm.compareItem.ros_rents,
      callback: function callback($$v) {
        _vm.$set(_vm.compareItem, "ros_rents", $$v);
      },
      expression: "compareItem.ros_rents"
    }
  })], 1) : _vm._e(), _c('span', {
    staticClass: "ml-2",
    staticStyle: {
      "width": "120px"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "客户资金ROE",
      "dense": "",
      "hide-details": "",
      "suffix": "%"
    },
    model: {
      value: _vm.compareItem.CPA,
      callback: function callback($$v) {
        _vm.$set(_vm.compareItem, "CPA", $$v);
      },
      expression: "compareItem.CPA"
    }
  })], 1), _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "color": "black darken-1",
      "text": ""
    },
    on: {
      "click": _vm.reCountCompare
    }
  }, [_vm._v(" 重新计算 ")])], 1)], 1)], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "temporary": "",
      "right": "",
      "width": "500",
      "height": "100%"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', {
          staticClass: "pa-2"
        }, [_c('v-btn', {
          attrs: {
            "outlined": "",
            "color": "grey"
          },
          on: {
            "click": function click($event) {
              return _vm.closeDrawer();
            }
          }
        }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "outlined": "",
            "color": "secondary",
            "disabled": !_vm.validParams
          },
          on: {
            "click": _vm.submitEdit
          }
        }, [_vm._v("提交")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.changedrawer,
      callback: function callback($$v) {
        _vm.changedrawer = $$v;
      },
      expression: "changedrawer"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "flat": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "headline py-2"
  }, [_vm._v(_vm._s(_vm.offerData.title) + " 参数调整")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-sheet', {
    attrs: {
      "min-height": "500"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-2",
    attrs: {
      "color": "primary"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "#fff"
    }
  }, [_vm._v("参数")])]), _c('v-form', {
    model: {
      value: _vm.validParams,
      callback: function callback($$v) {
        _vm.validParams = $$v;
      },
      expression: "validParams"
    }
  }, [_vm.pLevel === 3 ? _c('div', {
    staticClass: "mt-6"
  }, [_c('v-text-field', {
    attrs: {
      "label": "报价有效期",
      "autocomplete": "off",
      "rules": [_vm.rules.required],
      "type": "number",
      "clearable": "",
      "dense": "",
      "outlined": "",
      "suffix": "天"
    },
    model: {
      value: _vm.parameter.period,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter, "period", $$v);
      },
      expression: "parameter.period"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "一年利率",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.APR.base,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.APR, "base", $$v);
      },
      expression: "parameter.APR.base"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "二年利率",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.APR.increase_2_year,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.APR, "increase_2_year", $$v);
      },
      expression: "parameter.APR.increase_2_year"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "三年利率",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.APR.increase_3_year,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.APR, "increase_3_year", $$v);
      },
      expression: "parameter.APR.increase_3_year"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "四年利率",
      "rules": [_vm.rules.required],
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.APR.increase_4_year,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.APR, "increase_4_year", $$v);
      },
      expression: "parameter.APR.increase_4_year"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "账期年利率",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.APR.annual,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.APR, "annual", $$v);
      },
      expression: "parameter.APR.annual"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "风险控制系数",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.CV.init,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.CV, "init", $$v);
      },
      expression: "parameter.CV.init"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "风险账期",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "dense": "",
      "outlined": "",
      "suffix": "天"
    },
    model: {
      value: _vm.parameter.CV.period,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.CV, "period", $$v);
      },
      expression: "parameter.CV.period"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "客户资金ROE",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.CPA,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter, "CPA", $$v);
      },
      expression: "parameter.CPA"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "label": "自有资金",
      "suffix": "%",
      "clearable": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.parameter.OC,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter, "OC", $$v);
      },
      expression: "parameter.OC"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "1年12期",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.ETP._1_year_12,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.ETP, "_1_year_12", $$v);
      },
      expression: "parameter.ETP._1_year_12"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "2年24期",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.ETP._2_year_24,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.ETP, "_2_year_24", $$v);
      },
      expression: "parameter.ETP._2_year_24"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "3年36期",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.ETP._3_year_36,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.ETP, "_3_year_36", $$v);
      },
      expression: "parameter.ETP._3_year_36"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "1年4期",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.ETP._1_year_4,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.ETP, "_1_year_4", $$v);
      },
      expression: "parameter.ETP._1_year_4"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "2年8期",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.ETP._2_year_8,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.ETP, "_2_year_8", $$v);
      },
      expression: "parameter.ETP._2_year_8"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "3年12期",
      "rules": [_vm.rules.required],
      "autocomplete": "off",
      "type": "number",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.parameter.ETP._3_year_12,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter.ETP, "_3_year_12", $$v);
      },
      expression: "parameter.ETP._3_year_12"
    }
  }), _c('v-textarea', {
    attrs: {
      "label": "报价单备注信息",
      "autocomplete": "off",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "no-resize": ""
    },
    model: {
      value: _vm.parameter.comments,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter, "comments", $$v);
      },
      expression: "parameter.comments"
    }
  }), _c('v-textarea', {
    attrs: {
      "label": "报价单声明信息",
      "autocomplete": "off",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "no-resize": ""
    },
    model: {
      value: _vm.parameter.statement,
      callback: function callback($$v) {
        _vm.$set(_vm.parameter, "statement", $$v);
      },
      expression: "parameter.statement"
    }
  })], 1) : _vm._e()])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "600px",
      "persistent": ""
    },
    model: {
      value: _vm.enterInfoDlg,
      callback: function callback($$v) {
        _vm.enterInfoDlg = $$v;
      },
      expression: "enterInfoDlg"
    }
  }, [_c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": _vm.closeEnterInfo
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600px",
      "persistent": ""
    },
    model: {
      value: _vm.editDlg,
      callback: function callback($$v) {
        _vm.editDlg = $$v;
      },
      expression: "editDlg"
    }
  }, [_c('v-form', {
    model: {
      value: _vm.editValid,
      callback: function callback($$v) {
        _vm.editValid = $$v;
      },
      expression: "editValid"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(this.editDlgTitle))]), _c('v-card-text', [_vm.editDlgTitle === '报价名称' ? _c('v-text-field', {
    attrs: {
      "label": "报价名称",
      "autocomplete": "off",
      "clearable": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editOfferData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editOfferData, "title", $$v);
      },
      expression: "editOfferData.title"
    }
  }) : _vm._e(), _vm.editDlgTitle === '租赁方式' ? _c('v-select', {
    attrs: {
      "items": _vm.rentType,
      "label": "租赁方式",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editOfferData.rent_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editOfferData, "rent_type", $$v);
      },
      expression: "editOfferData.rent_type"
    }
  }) : _vm._e(), _vm.editDlgTitle === '租期' ? _c('v-select', {
    attrs: {
      "label": "租期（年）",
      "outlined": "",
      "item-text": "name",
      "item-value": "code",
      "dense": "",
      "items": _vm.leaseTerm
    },
    model: {
      value: _vm.editOfferData.lease_term,
      callback: function callback($$v) {
        _vm.$set(_vm.editOfferData, "lease_term", $$v);
      },
      expression: "editOfferData.lease_term"
    }
  }) : _vm._e(), _vm.editDlgTitle === '支付周期' ? _c('v-select', {
    attrs: {
      "items": _vm.paymentCycle,
      "label": "支付周期",
      "outlined": "",
      "item-text": "name",
      "item-value": "code",
      "dense": ""
    },
    model: {
      value: _vm.editOfferData.payment_cycle,
      callback: function callback($$v) {
        _vm.$set(_vm.editOfferData, "payment_cycle", $$v);
      },
      expression: "editOfferData.payment_cycle"
    }
  }) : _vm._e(), _vm.editDlgTitle === '账期' ? _c('v-text-field', {
    attrs: {
      "label": "账期（天）",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editOfferData.payment_period,
      callback: function callback($$v) {
        _vm.$set(_vm.editOfferData, "payment_period", $$v);
      },
      expression: "editOfferData.payment_period"
    }
  }) : _vm._e(), _vm.editDlgTitle === '起租时间' ? _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref43) {
        var on = _ref43.on,
            attrs = _ref43.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "起租时间",
            "readonly": "",
            "rules": [_vm.rules.required],
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.editOfferData.lease_start,
            callback: function callback($$v) {
              _vm.$set(_vm.editOfferData, "lease_start", $$v);
            },
            expression: "editOfferData.lease_start"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 2777124014),
    model: {
      value: _vm.startMenu,
      callback: function callback($$v) {
        _vm.startMenu = $$v;
      },
      expression: "startMenu"
    }
  }, [_c('v-date-picker', {
    on: {
      "input": function input($event) {
        _vm.startMenu = false;
      }
    },
    model: {
      value: _vm.editOfferData.lease_start,
      callback: function callback($$v) {
        _vm.$set(_vm.editOfferData, "lease_start", $$v);
      },
      expression: "editOfferData.lease_start"
    }
  })], 1) : _vm._e(), _vm.editDlgTitle === '每月付款日' ? _c('v-select', {
    attrs: {
      "items": Array.from({
        length: 28
      }, function (v, k) {
        return k + 1;
      }),
      "label": "每月付款日",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editOfferData.payment_day,
      callback: function callback($$v) {
        _vm.$set(_vm.editOfferData, "payment_day", $$v);
      },
      expression: "editOfferData.payment_day"
    }
  }) : _vm._e(), _vm.editDlgTitle === '是否延保' ? _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "color": "secondary",
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "body-2"
        }, [_vm._v(_vm._s(_vm.editProduct.isWarranty ? "是否延保：是" : "是否延保：否"))])];
      },
      proxy: true
    }], null, false, 3405702463),
    model: {
      value: _vm.editProduct.isWarranty,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "isWarranty", $$v);
      },
      expression: "editProduct.isWarranty"
    }
  }) : _vm._e(), _vm.editDlgTitle === '数量' ? _c('v-text-field', {
    attrs: {
      "label": "数量",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editProduct.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "quantity", $$v);
      },
      expression: "editProduct.quantity"
    }
  }) : _vm._e(), _vm.editDlgTitle === '延保单价' ? _c('v-text-field', {
    attrs: {
      "label": "价格",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editProduct.warranty_price_unit,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "warranty_price_unit", $$v);
      },
      expression: "editProduct.warranty_price_unit"
    }
  }) : _vm._e(), _vm.editDlgTitle === '到期余值' ? _c('v-text-field', {
    attrs: {
      "label": "到期余值",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editProduct.residual_values_unit,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "residual_values_unit", $$v);
      },
      expression: "editProduct.residual_values_unit"
    }
  }) : _vm._e(), _vm.editDlgTitle === '保证金' ? _c('v-text-field', {
    attrs: {
      "label": "保证金",
      "hint": _vm.deposit_hint,
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editProduct.deposit_price,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "deposit_price", $$v);
      },
      expression: "editProduct.deposit_price"
    }
  }) : _vm._e(), _vm.editDlgTitle === '资金成本' ? _c('v-text-field', {
    attrs: {
      "label": "资金成本",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number",
      "suffix": "%"
    },
    model: {
      value: _vm.editProduct.APR,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "APR", $$v);
      },
      expression: "editProduct.APR"
    }
  }) : _vm._e(), _vm.editDlgTitle === '采购单价' ? _c('v-text-field', {
    attrs: {
      "label": "采购单价",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editProduct.purchase_price,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "purchase_price", $$v);
      },
      expression: "editProduct.purchase_price"
    }
  }) : _vm._e(), _vm.editDlgTitle === '报价系数' ? _c('v-text-field', {
    attrs: {
      "label": "报价系数",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number",
      "suffix": "%"
    },
    model: {
      value: _vm.editProduct.ros_rents,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "ros_rents", $$v);
      },
      expression: "editProduct.ros_rents"
    }
  }) : _vm._e(), _vm.editDlgTitle === '销售利润率' ? _c('v-text-field', {
    attrs: {
      "label": "销售利润率",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number",
      "suffix": "%"
    },
    model: {
      value: _vm.editProduct.ros_sales,
      callback: function callback($$v) {
        _vm.$set(_vm.editProduct, "ros_sales", $$v);
      },
      expression: "editProduct.ros_sales"
    }
  }) : _vm._e(), _vm.editDlgTitle === '客户资金ROE' ? _c('v-text-field', {
    attrs: {
      "label": "客户资金ROE",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number",
      "suffix": "%"
    },
    model: {
      value: _vm.editNormalParams.CPA,
      callback: function callback($$v) {
        _vm.$set(_vm.editNormalParams, "CPA", $$v);
      },
      expression: "editNormalParams.CPA"
    }
  }) : _vm._e(), _vm.editDlgTitle === '风控系数' ? _c('v-text-field', {
    attrs: {
      "label": "风控系数",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number",
      "suffix": "%"
    },
    model: {
      value: _vm.editNormalParams.cv_init,
      callback: function callback($$v) {
        _vm.$set(_vm.editNormalParams, "cv_init", $$v);
      },
      expression: "editNormalParams.cv_init"
    }
  }) : _vm._e(), _vm.editDlgTitle === '风险账期' ? _c('v-text-field', {
    attrs: {
      "label": "风险账期",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editNormalParams.cv_period,
      callback: function callback($$v) {
        _vm.$set(_vm.editNormalParams, "cv_period", $$v);
      },
      expression: "editNormalParams.cv_period"
    }
  }) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("关闭")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.editValid
    },
    on: {
      "click": _vm.submitEdit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "800px",
      "persistent": ""
    },
    model: {
      value: _vm.newQuoteDlg,
      callback: function callback($$v) {
        _vm.newQuoteDlg = $$v;
      },
      expression: "newQuoteDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("创建报价单")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "报价单名称",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.quoteTitle,
      callback: function callback($$v) {
        _vm.quoteTitle = $$v;
      },
      expression: "quoteTitle"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.newQuoteDlg = false;
      }
    }
  }, [_vm._v("关闭")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.createQuote
    }
  }, [_vm._v("保存")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "700"
    },
    model: {
      value: _vm.proInfoDlg,
      callback: function callback($$v) {
        _vm.proInfoDlg = $$v;
      },
      expression: "proInfoDlg"
    }
  }, [_c('widgets-product-dialogs', {
    key: _vm.activeProId,
    attrs: {
      "id": _vm.activeProId
    },
    on: {
      "Close": function Close($event) {
        _vm.proInfoDlg = false;
      }
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "800px"
    },
    model: {
      value: _vm.productDialog,
      callback: function callback($$v) {
        _vm.productDialog = $$v;
      },
      expression: "productDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._v("关联产品")]), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "label": "搜索产品",
      "outlined": "",
      "dense": "",
      "prepend-icon": "search",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchProduct.apply(null, arguments);
      }
    },
    model: {
      value: _vm.queryPro.key,
      callback: function callback($$v) {
        _vm.$set(_vm.queryPro, "key", $$v);
      },
      expression: "queryPro.key"
    }
  })], 1), _c('v-divider'), _c('v-card-text', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "headers": _vm.productHeaders,
      "items": _vm.productList,
      "options": _vm.optionsItemPro,
      "server-items-length": _vm.productCount,
      "item-key": "product_id",
      "show-select": "",
      "footer-props": {
        'items-per-page-options': [5, 10, 15]
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItemPro = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref44) {
        var item = _ref44.item;
        return [_c('td', {
          staticClass: "text-truncate",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "nowrap": "true",
            "width": "240"
          },
          on: {
            "click": function click($event) {
              return _vm.openProInfo(item.product_id);
            }
          }
        }, [_vm._v(" " + _vm._s(item.product_code || item.name) + " ")])];
      }
    }, {
      key: "item.official",
      fn: function fn(_ref45) {
        var item = _ref45.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.official_price.$numberDecimal).toFixed(2)))])];
      }
    }, {
      key: "item.purchase",
      fn: function fn(_ref46) {
        var item = _ref46.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.purchase_price.$numberDecimal).toFixed(2)))])];
      }
    }, {
      key: "item.oneLeft",
      fn: function fn(_ref47) {
        var item = _ref47.item;
        return [_c('span', [_vm._v("¥" + _vm._s(parseFloat((item.official_price.$numberDecimal > 0 ? item.official_price.$numberDecimal * item.SV._1_year_ratio : item.purchase_price.$numberDecimal * item.SV._1_year_ratio).toFixed(2)).toFixed(2)))])];
      }
    }, {
      key: "item.twoLeft",
      fn: function fn(_ref48) {
        var item = _ref48.item;
        return [_c('span', [_vm._v("¥" + _vm._s(parseFloat((item.official_price.$numberDecimal > 0 ? item.official_price.$numberDecimal * item.SV._2_year_ratio : item.purchase_price.$numberDecimal * item.SV._2_year_ratio).toFixed(2)).toFixed(2)))])];
      }
    }, {
      key: "item.threeLeft",
      fn: function fn(_ref49) {
        var item = _ref49.item;
        return [_c('span', [_vm._v("¥" + _vm._s(parseFloat((item.official_price.$numberDecimal > 0 ? item.official_price.$numberDecimal * item.SV._3_year_ratio : item.purchase_price.$numberDecimal * item.SV._3_year_ratio).toFixed(2)).toFixed(2)))])];
      }
    }, {
      key: "item.twoDelay",
      fn: function fn(_ref50) {
        var item = _ref50.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.warranty._2_year_inside.$numberDecimal).toFixed(2)))])];
      }
    }, {
      key: "item.threeDelay",
      fn: function fn(_ref51) {
        var item = _ref51.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.warranty._3_year_inside.$numberDecimal).toFixed(2)))])];
      }
    }, {
      key: "item.delayPrice",
      fn: function fn(_ref52) {
        var item = _ref52.item;
        return [_c('span', [_vm._v(_vm._s(item.isDelay ? item.delayPrice : "0"))])];
      }
    }]),
    model: {
      value: _vm.chooseProduct,
      callback: function callback($$v) {
        _vm.chooseProduct = $$v;
      },
      expression: "chooseProduct"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "grey darken-1",
      "text": ""
    },
    on: {
      "click": _vm.closeChoose
    }
  }, [_vm._v(" 关闭 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1",
      "text": "",
      "disabled": !_vm.chooseProduct.length
    },
    on: {
      "click": _vm.linkChoose
    }
  }, [_vm._v(" 确定 ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.newDialog,
      callback: function callback($$v) {
        _vm.newDialog = $$v;
      },
      expression: "newDialog"
    }
  }, [_c('v-form', {
    model: {
      value: _vm.validNewPro,
      callback: function callback($$v) {
        _vm.validNewPro = $$v;
      },
      expression: "validNewPro"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._v("新增产品")]), _c('v-spacer')], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "产品名称",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.newProInfo.name,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "name", $$v);
      },
      expression: "newProInfo.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "error-messages": _vm.errPartNo,
      "type": "text",
      "label": "产品型号",
      "hint": "请输入产品型号 如：MGXC2CH/A",
      "outlined": "",
      "dense": ""
    },
    on: {
      "input": _vm.checkItem
    },
    model: {
      value: _vm.newProInfo.part_number,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "part_number", $$v);
      },
      expression: "newProInfo.part_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.vendorList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "产品供应商",
      "hint": "请选择产品供应商"
    },
    model: {
      value: _vm.newProInfo.product_vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "product_vendor", $$v);
      },
      expression: "newProInfo.product_vendor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.typeList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "产品类型",
      "hint": "请选择产品类型"
    },
    model: {
      value: _vm.newProInfo.product_type,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "product_type", $$v);
      },
      expression: "newProInfo.product_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "数量",
      "rules": [_vm.rules.required],
      "type": "number",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.newProInfo.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "quantity", $$v);
      },
      expression: "newProInfo.quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "官网价格",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "prefix": "¥"
    },
    model: {
      value: _vm.newProInfo.official_price,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "official_price", $$v);
      },
      expression: "newProInfo.official_price"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "采购价",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "prefix": "¥"
    },
    model: {
      value: _vm.newProInfo.purchase_price,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "purchase_price", $$v);
      },
      expression: "newProInfo.purchase_price"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "1年余值比例",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.newProInfo.SV._1_year_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo.SV, "_1_year_ratio", $$v);
      },
      expression: "newProInfo.SV._1_year_ratio"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "2年余值比例",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.newProInfo.SV._2_year_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo.SV, "_2_year_ratio", $$v);
      },
      expression: "newProInfo.SV._2_year_ratio"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "3年余值比例",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.newProInfo.SV._3_year_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo.SV, "_3_year_ratio", $$v);
      },
      expression: "newProInfo.SV._3_year_ratio"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "justify": "center"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "color": "secondary"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "body-2"
        }, [_vm._v(_vm._s(_vm.newProInfo.isWarranty ? "是否延保：是" : "是否延保：否"))]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref53) {
              var on = _ref53.on,
                  attrs = _ref53.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "ml-6",
                attrs: {
                  "color": "primary",
                  "dark": "",
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" info ")])];
            }
          }])
        }, [_c('span', {
          staticClass: "caption"
        }, [_vm._v("请联系MDAAS产品负责人获取延保单价")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.newProInfo.isWarranty,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "isWarranty", $$v);
      },
      expression: "newProInfo.isWarranty"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "2年延保成本单价",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "prefix": "¥",
      "hide-details": ""
    },
    model: {
      value: _vm.newProInfo.warranty._2_year_inside,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo.warranty, "_2_year_inside", $$v);
      },
      expression: "newProInfo.warranty._2_year_inside"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "3年延保成本单价",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "prefix": "¥",
      "hide-details": ""
    },
    model: {
      value: _vm.newProInfo.warranty._3_year_inside,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo.warranty, "_3_year_inside", $$v);
      },
      expression: "newProInfo.warranty._3_year_inside"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "grey darken-1",
      "text": ""
    },
    on: {
      "click": _vm.closeEditPro
    }
  }, [_vm._v(" 取消 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1",
      "text": "",
      "disabled": !_vm.validNewPro
    },
    on: {
      "click": _vm.saveEditPro
    }
  }, [_vm._v(" 提交 ")])], 1)], 1)], 1)], 1), _c('v-overlay', {
    attrs: {
      "value": _vm.overlay,
      "z-index": "9999"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }