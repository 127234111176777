var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "secondary",
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("设备列表")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "flat": "",
      "dense": "",
      "hide-details": "",
      "label": "搜索设备名称、序列号、订单号或者工单号...",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.doAction('search_item');
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 设备列表默认展示当前经销商下所有设备，筛选某个企业设备后可以批量修改和导入设备。 "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("导入设备每次最大支持10000条")])]), _c('v-data-table', {
    attrs: {
      "headers": _vm.computedHeaders,
      "items": _vm.assetList,
      "options": _vm.optionsItem,
      "server-items-length": _vm.assetCount,
      "loading": _vm.assetLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 20, 50]
      },
      "item-class": _vm.row_class,
      "item-key": "_id",
      "return-object": "false",
      "fixed-header": "",
      "height": "600",
      "show-select": !!_vm.query.enterprise
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_vm.hasEnterpriseList.length ? _c('v-toolbar-title', [_c('v-icon', {
          staticClass: "mr-4"
        }, [_vm._v("devices")]), _vm.query.enterprise ? _c('span', [_vm._v(_vm._s(_vm.hasEnterpriseList.find(function (item) {
          return item._id === _vm.query.enterprise;
        }).name))]) : _vm._e(), _vm.query.typein ? _c('span', [_vm._v("配件列表")]) : _c('span', [_vm._v("设备列表")])], 1) : _vm._e(), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _vm.query.enterprise && (_vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Asset_Manage')) ? _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "disabled": !_vm.selectedItem.length
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('edit_batch', _vm.selectedItem, '批量修改');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("create")]), _vm._v(" 批量修改 ")], 1) : _vm._e(), _vm.query.enterprise && (_vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Service_Edit')) ? _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "ma-2",
                attrs: {
                  "text": "",
                  "disabled": !_vm.selectedItem.length
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("assignment")]), _vm._v(" 申请服务 ")], 1)];
            }
          }], null, false, 481079963)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, _vm._l(_vm.availableService, function (service) {
          return _c('v-list-item', {
            key: service._id,
            on: {
              "click": function click($event) {
                return _vm.doAction('create_case', service);
              }
            }
          }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(service.profileIcon))])], 1), _c('v-list-item-title', [_vm._v(_vm._s(service.name))])], 1);
        }), 1)], 1) : _vm._e(), _c('v-spacer'), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Asset_Manage') ? _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "secondary",
                  "depressed": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("add")]), _vm._v(" 新建设备 ")], 1)];
            }
          }], null, false, 688881315)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.doAction('create_item', _vm.query.enterprise, '新增设备');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("add_box")])], 1), _c('v-list-item-title', [_vm._v("通过表单增加单台设备")])], 1), _vm.query.enterprise ? _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.doAction('import_item', _vm.query.enterprise, '新增多台设备');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("library_add")])], 1), _c('v-list-item-title', [_vm._v("通过文件增加多台设备")])], 1) : _vm._e()], 1)], 1) : _vm._e(), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "disabled": !_vm.query.enterprise,
                  "loading": _vm.downloading
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('export_item');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("save_alt")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("导出数据")])]), _c('widgets-custom-header', {
          attrs: {
            "defaultHeader": _vm.defaultHeader,
            "headerList": _vm.headerItem,
            "version": 2,
            "tableName": "assetHeader"
          },
          on: {
            "Cancel": function Cancel($event) {
              return _vm.doAction('cancel_header');
            },
            "Default": function Default($event) {
              return _vm.doAction('default_header');
            },
            "Save": function Save($event) {
              return _vm.doAction('set_header');
            }
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('filter_item');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.name",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-asset-dialogs', {
          attrs: {
            "getId": item._id
          }
        }, [_c('div', [item.isLock ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("lock")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("设备锁定中")])]) : _vm._e(), _vm._v(" " + _vm._s(item.name) + " "), item.asset_type === 3 ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on,
                  attrs = _ref7.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("currency_exchange")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("企业租赁设备")])]) : item.ownerType === 'Enterprise' ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on,
                  attrs = _ref8.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("corporate_fare")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("企业自有设备")])]) : item.ownerType === 'Account' ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref9) {
              var on = _ref9.on,
                  attrs = _ref9.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("person")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("个人自有设备")])]) : _vm._e()], 1)])], 1)];
      }
    }, {
      key: "item.state",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.state ? item.state.name : item.state))])];
      }
    }, {
      key: "item.product",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.product ? _c('td', {
          staticClass: "v-list-item--link text-truncate",
          staticStyle: {
            "max-width": "150px"
          },
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('open_product', item.product);
            }
          }
        }, [_vm._v(_vm._s(item.product.name) + " - " + _vm._s(item.product.part_number))]) : _vm._e()];
      }
    }, {
      key: "item.enterprise",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [item.enterprise ? _c('td', {
          staticClass: "v-list-item--link",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('open_enterprise', item.enterprise);
            }
          }
        }, [_vm._v(_vm._s(item.enterprise.name))]) : _vm._e()];
      }
    }, {
      key: "item.branch",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.branch))])];
      }
    }, {
      key: "item.account",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [item.account ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-employee-dialogs', {
          staticClass: "v-list-item--link",
          attrs: {
            "account": item.account._id
          }
        }, [_vm._v(_vm._s(item.account.personal ? item.account.personal.name : '*未设置姓名'))])], 1) : _vm._e()];
      }
    }, {
      key: "item.serial_number",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [item.product_type && item.product_type.noSN ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("无序列号")]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.serial_number))])];
      }
    }, {
      key: "item.label",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.label))])];
      }
    }, {
      key: "item.asset_from",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.asset_from))])];
      }
    }, {
      key: "item.asset_state",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.asset_state.map(function (state) {
          return _vm.assetState.find(function (o) {
            return o.code === state;
          }).name;
        })))])];
      }
    }, {
      key: "item.transport.state",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [item.transport && item.transport.state === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("在途")]) : item.transport && item.transport.state === 1 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("揽收")]) : item.transport && item.transport.state === 2 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("疑难")]) : item.transport && item.transport.state === 3 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("签收")]) : item.transport && item.transport.state === 4 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("退签")]) : item.transport && item.transport.state === 5 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("派件")]) : item.transport && item.transport.state === 14 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("拒签")]) : _c('td', [_vm._v("-")])];
      }
    }, {
      key: "item.transport.delivery_address",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [item.transport ? _c('td', {
          staticClass: "v-list-item--link text-truncate",
          staticStyle: {
            "max-width": "150px"
          },
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.transport.delivery_address))]) : _vm._e()];
      }
    }, {
      key: "item.guaranteed_type",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm.guaranteedType.filter(function (g) {
          return item.guaranteed_type.includes(g.code);
        }).map(function (g) {
          return g.name;
        })))])];
      }
    }, {
      key: "item.guaranteed_time",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [item.guaranteed_time ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.guaranteed_time)) + " - " + _vm._s(_vm._f("fromDate")(item.guaranteed_time)))]) : _vm._e()];
      }
    }, {
      key: "item.guaranteed_limit",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [item.guaranteed_limit >= 12 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(parseInt(item.guaranteed_limit / 12)) + "年 " + _vm._s(item.guaranteed_limit % 12 > 0 ? item.guaranteed_limit % 12 + '个月' : ''))]) : item.guaranteed_limit ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.guaranteed_limit) + "个月")]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        })];
      }
    }, {
      key: "item.guaranteed_deadline",
      fn: function fn(_ref24) {
        var item = _ref24.item;
        return [item.guaranteed_deadline ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.guaranteed_deadline)) + " - " + _vm._s(_vm._f("fromDate")(item.guaranteed_deadline)))]) : _vm._e()];
      }
    }, {
      key: "item.purchase_time",
      fn: function fn(_ref25) {
        var item = _ref25.item;
        return [item.purchase_time ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.purchase_time)))]) : _vm._e()];
      }
    }, {
      key: "item.warranty_time",
      fn: function fn(_ref26) {
        var item = _ref26.item;
        return [item.warranty_time >= 12 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(parseInt(item.warranty_time / 12)) + "年 " + _vm._s(item.warranty_time % 12 > 0 ? item.warranty_time % 12 + '个月' : ''))]) : item.warranty_time ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.warranty_time) + "个月")]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.warranty_time))])];
      }
    }, {
      key: "item.warranty_deadline",
      fn: function fn(_ref27) {
        var item = _ref27.item;
        return [item.warranty_deadline ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.warranty_deadline)) + " - " + _vm._s(_vm._f("fromDate")(item.warranty_deadline)))]) : _vm._e()];
      }
    }, {
      key: "item.warehouse",
      fn: function fn(_ref28) {
        var item = _ref28.item;
        return [item.warehouse ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.warehouse.name))]) : _vm._e()];
      }
    }, {
      key: "item.warehouse_time",
      fn: function fn(_ref29) {
        var item = _ref29.item;
        return [item.warehouse_time ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.warehouse_time)))]) : _vm._e()];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref30) {
        var item = _ref30.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.isABM",
      fn: function fn(_ref31) {
        var item = _ref31.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.isABM ? '是' : '否'))])];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref32) {
        var item = _ref32.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.rent_info.rent_type",
      fn: function fn(_ref33) {
        var item = _ref33.item;
        return [item.asset_type === 3 && item.rent_info ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.rent_info.rent_type === 0 ? '融资租赁' : '经营租赁'))]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.rent_info.lease_start",
      fn: function fn(_ref34) {
        var item = _ref34.item;
        return [item.asset_type === 3 && item.rent_info && item.rent_info.lease_start ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.rent_info.lease_start)))]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.rent_info.lease_term",
      fn: function fn(_ref35) {
        var item = _ref35.item;
        return [item.asset_type === 3 && item.rent_info && item.rent_info.lease_term ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.rent_info.lease_term / 365) + " 年")]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.rent_info.payment_cycle",
      fn: function fn(_ref36) {
        var item = _ref36.item;
        return [item.asset_type === 3 && item.rent_info ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.rent_info.payment_cycle === '0' ? '月付' : item.rent_info.payment_cycle === '1' ? '季付' : '半年付'))]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.rent_info.total_period",
      fn: function fn(_ref37) {
        var item = _ref37.item;
        return [item.asset_type === 3 && item.rent_info ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.rent_info.total_period))]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.rent_info.expiry_date",
      fn: function fn(_ref38) {
        var item = _ref38.item;
        return [item.asset_type === 3 && item.rent_info && item.rent_info.expiry_date ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.rent_info.expiry_date)))]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }]),
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  })], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.filterDrawer = !_vm.filterDrawer;
            }
          }
        }, [_vm._v("隐藏")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('clear_filter');
            }
          }
        }, [_vm._v("清空")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("企业与用户")]), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.hasEnterpriseList,
      "label": "企业",
      "hint": "请选择一家企业",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_employee_list');
      }
    },
    model: {
      value: _vm.query.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "enterprise", $$v);
      },
      expression: "query.enterprise"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.arrBranch,
      "label": "分支机构",
      "hint": "请选择企业的分支机构",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.branch,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "branch", $$v);
      },
      expression: "query.branch"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.queryAccountList,
      "loading": _vm.queryAccountListLoading,
      "search-input": _vm.searchQueryAccount,
      "cache-items": "",
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "使用人员",
      "hint": "请选择设备使用人员",
      "clearable": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchQueryAccount = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchQueryAccount = $event;
      },
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.query.account,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "account", $$v);
      },
      expression: "query.account"
    }
  }), _vm._l(_vm.assetFieldList, function (field) {
    return _c('div', {
      key: field._id
    }, [_c('h2', {
      staticClass: "subtitle-2 mt-2"
    }, [_vm._v(_vm._s(field.name))]), field.format.type === 'text' ? _c('v-text-field', {
      attrs: {
        "type": "text",
        "label": field.name,
        "outlined": "",
        "dense": "",
        "clearable": ""
      },
      on: {
        "change": function change($event) {
          return _vm.doAction('set_filter');
        },
        "click:clear": function clickClear($event) {
          return _vm.doAction('set_filter');
        }
      },
      model: {
        value: _vm.query[field._id],
        callback: function callback($$v) {
          _vm.$set(_vm.query, field._id, $$v);
        },
        expression: "query[field._id]"
      }
    }) : _vm._e(), field.format.type === 'selects' ? _c('v-chip-group', {
      attrs: {
        "column": "",
        "multiple": ""
      },
      on: {
        "change": function change($event) {
          return _vm.doAction('set_filter');
        }
      },
      model: {
        value: _vm.query[field._id],
        callback: function callback($$v) {
          _vm.$set(_vm.query, field._id, $$v);
        },
        expression: "query[field._id]"
      }
    }, _vm._l(field.format.items, function (item) {
      return _c('v-chip', {
        key: item,
        attrs: {
          "value": item,
          "filter": "",
          "label": "",
          "outlined": ""
        }
      }, [_vm._v(_vm._s(item))]);
    }), 1) : _vm._e()], 1);
  }), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("设备归属")]), _vm.assetOwner.length ? _c('v-chip-group', {
    attrs: {
      "column": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.owner,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "owner", $$v);
      },
      expression: "query.owner"
    }
  }, _vm._l(_vm.assetOwner, function (owner) {
    return _c('v-chip', {
      key: owner.code,
      attrs: {
        "value": owner.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(owner.name))]);
  }), 1) : _vm._e(), _vm.query.owner === 'rental' ? _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("起租时间")]) : _vm._e(), _vm.query.owner === 'rental' ? _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref39) {
        var on = _ref39.on,
            attrs = _ref39.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "开始日期",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "click:clear": function clickClear($event) {
              _vm.query.rental_create_startdate = '';

              _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.rental_create_startdate,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "rental_create_startdate", $$v);
            },
            expression: "query.rental_create_startdate"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 1585814726),
    model: {
      value: _vm.menuRentalStartDate,
      callback: function callback($$v) {
        _vm.menuRentalStartDate = $$v;
      },
      expression: "menuRentalStartDate"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuRentalStartDate = false;
      }
    },
    model: {
      value: _vm.query.rental_create_startdate,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "rental_create_startdate", $$v);
      },
      expression: "query.rental_create_startdate"
    }
  })], 1) : _vm._e(), _vm.query.owner === 'rental' ? _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref40) {
        var on = _ref40.on,
            attrs = _ref40.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "结束日期",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "click:clear": function clickClear($event) {
              _vm.query.rental_create_enddate = '';

              _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.rental_create_enddate,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "rental_create_enddate", $$v);
            },
            expression: "query.rental_create_enddate"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 4073926561),
    model: {
      value: _vm.menuRentalEndDate,
      callback: function callback($$v) {
        _vm.menuRentalEndDate = $$v;
      },
      expression: "menuRentalEndDate"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuRentalEndDate = false;
      }
    },
    model: {
      value: _vm.query.rental_create_enddate,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "rental_create_enddate", $$v);
      },
      expression: "query.rental_create_enddate"
    }
  })], 1) : _vm._e(), _vm.query.owner === 'rental' ? _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("租赁到期")]) : _vm._e(), _vm.query.owner === 'rental' ? _c('v-chip-group', {
    attrs: {
      "column": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.rental_deadline,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "rental_deadline", $$v);
      },
      expression: "query.rental_deadline"
    }
  }, _vm._l(_vm.rentalDeadline, function (datetiem) {
    return _c('v-chip', {
      key: datetiem.code,
      attrs: {
        "value": datetiem.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(datetiem.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("设备状态")]), _vm.stateList.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.state,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "state", $$v);
      },
      expression: "query.state"
    }
  }, _vm._l(_vm.stateList, function (state) {
    return _c('v-chip', {
      key: state._id,
      attrs: {
        "value": state._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(state.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("保修到期")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.warranty_deadline,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "warranty_deadline", $$v);
      },
      expression: "query.warranty_deadline"
    }
  }, _vm._l(_vm.expiryDate, function (datetiem) {
    return _c('v-chip', {
      key: datetiem.code,
      attrs: {
        "value": datetiem.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(datetiem.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("延保类型")]), _vm.guaranteedType.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.guaranteed_type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "guaranteed_type", $$v);
      },
      expression: "query.guaranteed_type"
    }
  }, _vm._l(_vm.guaranteedType, function (guaranteed_type) {
    return _c('v-chip', {
      key: guaranteed_type.code,
      attrs: {
        "value": guaranteed_type.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(guaranteed_type.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("延保到期")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.guaranteed_deadline,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "guaranteed_deadline", $$v);
      },
      expression: "query.guaranteed_deadline"
    }
  }, _vm._l(_vm.expiryDate, function (datetiem) {
    return _c('v-chip', {
      key: datetiem.code,
      attrs: {
        "value": datetiem.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(datetiem.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("快递信息")]), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "快递单号",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "hide-details": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      },
      "click:clear": function clickClear($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.express_no,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "express_no", $$v);
      },
      expression: "query.express_no"
    }
  }), _vm.expressState.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.express_state,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "express_state", $$v);
      },
      expression: "query.express_state"
    }
  }, _vm._l(_vm.expressState, function (state) {
    return _c('v-chip', {
      key: state.code,
      attrs: {
        "value": state.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(state.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("设备情况")]), _vm.assetState.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.asset_state,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "asset_state", $$v);
      },
      expression: "query.asset_state"
    }
  }, _vm._l(_vm.assetState, function (asset_state) {
    return _c('v-chip', {
      key: asset_state.code,
      attrs: {
        "value": asset_state.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(asset_state.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("更新时间")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.updateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "updateTime", $$v);
      },
      expression: "query.updateTime"
    }
  }, _vm._l(_vm.updateTime, function (datetiem) {
    return _c('v-chip', {
      key: datetiem.code,
      attrs: {
        "value": datetiem.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(datetiem.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("所在仓库")]), _vm.warehouseList.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.warehouse,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "warehouse", $$v);
      },
      expression: "query.warehouse"
    }
  }, _vm._l(_vm.warehouseList, function (warehouse) {
    return _c('v-chip', {
      key: warehouse._id,
      attrs: {
        "value": warehouse._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(warehouse.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("产品品牌")]), _vm.vendorList.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_product_list');
      }
    },
    model: {
      value: _vm.query.vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "vendor", $$v);
      },
      expression: "query.vendor"
    }
  }, _vm._l(_vm.vendorList, function (vendor) {
    return _c('v-chip', {
      key: vendor._id,
      attrs: {
        "value": vendor._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(vendor.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("产品类型")]), _vm.typeList.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_product_list');
      }
    },
    model: {
      value: _vm.query.productType,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "productType", $$v);
      },
      expression: "query.productType"
    }
  }, _vm._l(_vm.typeList.filter(function (t) {
    return t.isEnable;
  }), function (prd_type) {
    return _c('v-chip', {
      key: prd_type._id,
      attrs: {
        "value": prd_type._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(prd_type.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("关联产品")]), _vm.productList.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.product,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "product", $$v);
      },
      expression: "query.product"
    }
  }, _vm._l(_vm.productList, function (product) {
    return _c('v-chip', {
      key: product._id,
      attrs: {
        "value": product._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(product.name))]);
  }), 1) : _vm._e()], 2)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'enterprise' ? _c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.doAction('close_info');
      }
    }
  }) : _vm._e(), _vm.activeType === 'product' ? _c('widgets-product-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.doAction('close_info');
      },
      "Goto": function Goto($event) {
        return _vm.doAction('goto', $event);
      }
    }
  }) : _vm._e(), _vm.activeType === 'batch' ? _c('v-card', [_c('v-card-title', [_vm._v(" 批量修改 - " + _vm._s(_vm.query.enterprise ? _vm.hasEnterpriseList.find(function (item) {
    return item._id === _vm.query.enterprise;
  }).name : '') + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_info');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.batchSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.batchUpdate.valid,
      callback: function callback($$v) {
        _vm.$set(_vm.batchUpdate, "valid", $$v);
      },
      expression: "batchUpdate.valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.batchUpdate.fields, function (item, index) {
    return _c('v-row', {
      key: 'batch_field_' + index,
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [item.name === 'name' ? _c('v-text-field', {
      attrs: {
        "rules": [_vm.rules.required],
        "type": "text",
        "label": "设备名称",
        "hint": "设备名称是该设备的标示，如开发电脑，新员工笔记本等",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e(), item.name === 'state' ? _c('v-autocomplete', {
      attrs: {
        "items": _vm.stateList,
        "rules": [_vm.rules.selected],
        "item-text": "name",
        "item-value": "_id",
        "autocomplete": "off",
        "label": "设备状态",
        "hint": "设备状态用来记录当前设备的状态，可在系统后台维护状态类型。",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e(), item.name === 'product' ? _c('v-autocomplete', {
      attrs: {
        "items": _vm.productList,
        "rules": [_vm.rules.selected],
        "item-text": "name",
        "item-value": "_id",
        "autocomplete": "off",
        "outlined": "",
        "dense": "",
        "label": "关联产品",
        "hint": "请选择设备关联的产品"
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function fn(data) {
          return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.vendor.name) + " - " + _vm._s(data.item.name) + " - " + _vm._s(data.item.part_number))]), data.item.it_info ? _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.it_info.ram) + "G - " + _vm._s(data.item.it_info.network))]) : _vm._e()], 1)];
        }
      }], null, true),
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e(), item.name === 'account' ? _c('v-autocomplete', {
      attrs: {
        "items": _vm.itemAccountList,
        "loading": _vm.itemAccountListLoading,
        "search-input": _vm.searchItemAccount,
        "cache-items": "",
        "item-text": "personal.name",
        "item-value": "_id",
        "autocomplete": "off",
        "outlined": "",
        "dense": "",
        "label": "使用人员",
        "hint": "请选择设备使用人员",
        "clearable": ""
      },
      on: {
        "update:searchInput": function updateSearchInput($event) {
          _vm.searchItemAccount = $event;
        },
        "update:search-input": function updateSearchInput($event) {
          _vm.searchItemAccount = $event;
        }
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function fn(data) {
          return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
        }
      }], null, true),
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e(), item.name === 'asset_from' ? _c('v-text-field', {
      attrs: {
        "type": "text",
        "label": "设备来源",
        "hint": "设备获得方式，如采购、回收等",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e(), item.name === 'label' ? _c('v-combobox', {
      attrs: {
        "label": "设备标签",
        "hint": "使用回车键可以输入多个标签。如内部设备代码、编码等信息",
        "deletable-chips": "",
        "multiple": "",
        "small-chips": "",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e(), item.name === 'asset_state' ? _c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": _vm.assetState,
        "label": "设备情况",
        "hint": "请选择设备属性",
        "item-text": "name",
        "item-value": "code",
        "deletable-chips": "",
        "multiple": "",
        "small-chips": "",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e(), item.name === 'guaranteed_type' ? _c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": _vm.guaranteedType,
        "label": "延保类型",
        "hint": "请选择延保类型",
        "item-text": "name",
        "item-value": "code",
        "outlined": "",
        "dense": "",
        "deletable-chips": "",
        "multiple": "",
        "small-chips": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e(), item.name === 'guaranteed_time' ? _c('v-menu', {
      attrs: {
        "close-on-content-click": false,
        "transition": "scale-transition",
        "offset-y": "",
        "min-width": "290px"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref41) {
          var on = _ref41.on,
              attrs = _ref41.attrs;
          return [_c('v-text-field', _vm._g(_vm._b({
            attrs: {
              "label": "延保开始时间",
              "readonly": "",
              "outlined": "",
              "dense": ""
            },
            model: {
              value: item.value,
              callback: function callback($$v) {
                _vm.$set(item, "value", $$v);
              },
              expression: "item.value"
            }
          }, 'v-text-field', attrs, false), on))];
        }
      }], null, true),
      model: {
        value: _vm.batchUpdate.guaranteed_time,
        callback: function callback($$v) {
          _vm.$set(_vm.batchUpdate, "guaranteed_time", $$v);
        },
        expression: "batchUpdate.guaranteed_time"
      }
    }, [_c('v-date-picker', {
      attrs: {
        "no-title": "",
        "scrollable": ""
      },
      on: {
        "input": function input($event) {
          _vm.batchUpdate.guaranteed_time = false;
        }
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.name === 'guaranteed_limit' ? _c('v-text-field', {
      attrs: {
        "suffix": "月",
        "type": "number",
        "label": "延保期限",
        "hint": "延保期限单位月",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", _vm._n($$v));
        },
        expression: "item.value"
      }
    }) : _vm._e(), item.name === 'purchase_time' ? _c('v-menu', {
      attrs: {
        "close-on-content-click": false,
        "transition": "scale-transition",
        "offset-y": "",
        "min-width": "290px"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref42) {
          var on = _ref42.on,
              attrs = _ref42.attrs;
          return [_c('v-text-field', _vm._g(_vm._b({
            attrs: {
              "label": "购买日期",
              "readonly": "",
              "outlined": "",
              "dense": ""
            },
            model: {
              value: item.value,
              callback: function callback($$v) {
                _vm.$set(item, "value", $$v);
              },
              expression: "item.value"
            }
          }, 'v-text-field', attrs, false), on))];
        }
      }], null, true),
      model: {
        value: _vm.batchUpdate.purchase_time,
        callback: function callback($$v) {
          _vm.$set(_vm.batchUpdate, "purchase_time", $$v);
        },
        expression: "batchUpdate.purchase_time"
      }
    }, [_c('v-date-picker', {
      attrs: {
        "no-title": "",
        "scrollable": ""
      },
      on: {
        "input": function input($event) {
          _vm.batchUpdate.purchase_time = false;
        }
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.name === 'warranty_time' ? _c('v-text-field', {
      attrs: {
        "suffix": "月",
        "type": "number",
        "label": "厂商质保期",
        "hint": "厂商质保期在产品管理中可以设置默认值",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", _vm._n($$v));
        },
        expression: "item.value"
      }
    }) : _vm._e(), item.name === 'warehouse' ? _c('v-autocomplete', {
      attrs: {
        "items": _vm.warehouseList,
        "item-text": "name",
        "item-value": "_id",
        "autocomplete": "off",
        "outlined": "",
        "dense": "",
        "clearable": "",
        "label": "仓库",
        "hint": "请选择设备所在仓库"
      },
      scopedSlots: _vm._u([{
        key: "item",
        fn: function fn(data) {
          return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name) + " - " + _vm._s(data.item.center_code))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.address))])], 1)];
        }
      }], null, true),
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e(), item.name === 'warehouse_time' ? _c('v-menu', {
      attrs: {
        "close-on-content-click": false,
        "transition": "scale-transition",
        "offset-y": "",
        "min-width": "290px"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref43) {
          var on = _ref43.on,
              attrs = _ref43.attrs;
          return [_c('v-text-field', _vm._g(_vm._b({
            attrs: {
              "label": "入库日期",
              "readonly": "",
              "outlined": "",
              "dense": ""
            },
            model: {
              value: item.value,
              callback: function callback($$v) {
                _vm.$set(item, "value", $$v);
              },
              expression: "item.value"
            }
          }, 'v-text-field', attrs, false), on))];
        }
      }], null, true),
      model: {
        value: _vm.batchUpdate.warehouse_time,
        callback: function callback($$v) {
          _vm.$set(_vm.batchUpdate, "warehouse_time", $$v);
        },
        expression: "batchUpdate.warehouse_time"
      }
    }, [_c('v-date-picker', {
      attrs: {
        "no-title": "",
        "scrollable": ""
      },
      on: {
        "input": function input($event) {
          _vm.batchUpdate.warehouse_time = false;
        }
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.name === 'remarks' ? _c('v-textarea', {
      attrs: {
        "label": "设备备注",
        "hint": "请输入设备备注信息",
        "rows": "4",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _vm._e()], 1), _c('v-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.doAction('remove_batch_field', index);
        }
      }
    }, [_c('v-icon', [_vm._v("remove")])], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('v-divider'), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Asset_Manage') ? _c('v-card-actions', [_c('v-menu', {
    attrs: {
      "max-height": "250px",
      "top": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref44) {
        var on = _ref44.on,
            attrs = _ref44.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("add")]), _vm._v("增加批量修改字段")], 1)];
      }
    }], null, false, 1807413905)
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.filterSelected(_vm.batchUpdate.assetFields, _vm.batchUpdate.fields), function (item, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.doAction('add_batch_field', item);
        }
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(item.text))])], 1);
  }), 1)], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.batchUpdate.showAsset = !_vm.batchUpdate.showAsset;
      }
    }
  }, [_c('v-subheader', [_vm._v("批量处理设备：" + _vm._s(_vm.selectedItem.length))]), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(_vm._s(_vm.batchUpdate.showAsset ? 'keyboard_arrow_up' : 'keyboard_arrow_down'))])], 1), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_info');
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.batchUpdate.valid
    },
    on: {
      "click": _vm.batchSubmit
    }
  }, [_vm._v("确认")])], 1) : _vm._e(), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.batchUpdate.showAsset,
      expression: "batchUpdate.showAsset"
    }]
  }, [_c('v-divider'), _c('v-card-text', _vm._l(_vm.selectedItem, function (selected, index) {
    return _c('v-chip', {
      key: selected._id,
      staticClass: "ma-2",
      attrs: {
        "close": "",
        "label": "",
        "small": ""
      },
      on: {
        "click:close": function clickClose($event) {
          return _vm.doAction('close_selected', index);
        }
      }
    }, [_vm._v(_vm._s(selected.name))]);
  }), 1)], 1)])], 1) : _vm._e(), _vm.activeType === 'import' ? _c('v-card', [_c('v-card-title', [_vm._v(" 批量导入 - " + _vm._s(_vm.query.enterprise ? _vm.hasEnterpriseList.find(function (item) {
    return item._id === _vm.query.enterprise;
  }).name : '') + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_info');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载设备模版，填写设备信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('download_template');
      }
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importFiles($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": "",
      "loading": !_vm.importEnd
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('upload_asset');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1), _vm.importFailed.length || _vm.importResult ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _vm.importFailed.length === 0 ? _c('span', [_vm._v("无")]) : _c('ul', _vm._l(_vm.importFailed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.importResult.insertSum) + " 条记录； 更新：" + _vm._s(_vm.importResult.updateSum) + " 条记录； ")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-divider'), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Asset_Manage') ? _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_info');
      }
    }
  }, [_vm._v("关闭")])], 1) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }