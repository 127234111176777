<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center" style="height: 300px;">
          <v-alert
            text
            outlined
            color="secondary"
            icon="admin_panel_settings"
          >
            目前仅开放运营及经销商登陆，企业管理及员工请从esp.mdaas.cn登陆使用ESP平台。
          </v-alert>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  created() {
    window.getApp.$emit("APP_DRAWER_HIDE");
  },
  computed: {},
  mounted() {},
  methods: {},
  directives: {}
}
</script>
