<template>
  <v-container>
    <v-card>
      <v-form v-model="valid" @submit.prevent="submit">
        <v-toolbar class="secondary" dark flat>
          <v-btn icon @click="goBack()">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>{{title_page()}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-expansion-panels v-model="openPanel" multiple accordion flat>
            <v-expansion-panel>
              <v-expansion-panel-header>基本信息</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.name"
                      :rules="[rules.required]"
                      type="text"
                      label="设备名称*"
                      hint="设备名称是该设备的标示，如开发电脑，新员工笔记本等"
                      prepend-icon="bookmark"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      class="ml-4"
                      v-model="editedItem.state"
                      :items="stateList"
                      :rules="[rules.selected]"
                      item-text="name"
                      item-value="_id"
                      autocomplete="off"
                      label="设备状态*"
                      hint="设备状态用来记录当前设备的状态，可在系统后台维护状态类型。"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.vendor"
                      :items="vendorList"
                      :rules="[rules.selected]"
                      @change="doAction('set_product_list')"
                      item-text="name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      dense
                      prepend-icon="devices"
                      label="产品供应商*"
                      hint="请选择产品供应商"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      class="mx-4"
                      v-model="editedItem.productType"
                      :items="typeList"
                      @change="doAction('set_product_list')"
                      item-text="name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      dense
                      clearable
                      label="产品类型"
                      hint="请选择产品类型"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.product"
                      :items="editedItem.product ? productList : productList.filter(item => item.selected.includes('default'))"
                      :rules="[rules.selected]"
                      @change="doAction('set_warranty_time')"
                      item-text="name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      dense
                      label="关联产品*"
                      hint="请选择设备关联的产品"
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{data.item.name}} - {{data.item.part_number}}</v-list-item-title>
                          <v-list-item-subtitle v-if="data.item.it_info">{{data.item.it_info.ram}}G - {{data.item.it_info.network}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-if="!noSN"
                      v-model="editedItem.serial_number"
                      :rules="[rules.required]"
                      type="text"
                      prepend-icon="style"
                      label="设备序列号*"
                      hint="设备序列号是设备的唯一标示"
                      outlined
                      dense
                    >
                    </v-text-field>
                    <v-text-field
                      v-else
                      type="text"
                      prepend-icon="style"
                      label="设备序列号"
                      value="所选产品不需要序列号"
                      outlined
                      dense
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-combobox
                      class="mx-4"
                      v-model="editedItem.label"
                      label="设备标签"
                      hint="使用回车键可以输入多个标签。如内部设备代码、编码等信息"
                      deletable-chips
                      multiple
                      small-chips
                      outlined
                      dense
                    >
                    </v-combobox>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.out_number"
                      type="text"
                      label="外部编号"
                      hint="如企业资产编号等"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                      v-model="purchase_time"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.purchase_time"
                          prepend-icon="shopping_cart"
                          label="购买日期"
                          readonly
                          v-bind="attrs"
                          outlined
                          dense
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.purchase_time" @input="doAction('set_warranty_deadline')" no-title scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="mx-4"
                      v-model.number="editedItem.warranty_time"
                      @change="doAction('set_warranty_deadline')"
                      suffix="月"
                      type="number"
                      label="厂商质保期"
                      hint="厂商质保期在产品管理中可以设置默认值"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.warranty_deadline"
                      type="date"
                      label="质保到期"
                      hint="质保到期时间"
                      outlined
                      dense
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      v-model="editedItem.warehouse"
                      :items="warehouseList"
                      item-text="name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      dense
                      clearable
                      prepend-icon="home_work"
                      label="仓库"
                      hint="请选择设备所在仓库"
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{data.item.name}} - {{data.item.center_code}}</v-list-item-title>
                          <v-list-item-subtitle>{{data.item.address}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      v-model="warehouse_time"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="ml-4"
                          v-model="editedItem.warehouse_time"
                          label="入库日期"
                          readonly
                          v-bind="attrs"
                          outlined
                          dense
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.warehouse_time" @input="warehouse_time = false" no-title scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.enterprise"
                      :loading="enterpriseLoading"
                      :items="enterpriseList"
                      :search-input.sync="searchEnterprise"
                      @change="doAction('set_employee_list')"
                      item-text="name"
                      item-value="_id"
                      autocomplete="off"
                      hide-no-data
                      outlined
                      dense
                      clearable
                      label="所属企业"
                      hint="输入企业名称进行检索"
                      prepend-icon="corporate_fare"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      class="mx-4"
                      v-model="editedItem.branch"
                      :items="branchList"
                      autocomplete="off"
                      outlined
                      dense
                      clearable
                      label="下属企业"
                      hint="请选择设备所属下属企业"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.account"
                      :items="employeeList"
                      :loading="employeeLoading"
                      :search-input.sync="searchEmployee"
                      @change="doAction('set_asset_user')"
                      item-text="personal.name"
                      item-value="_id"
                      autocomplete="off"
                      outlined
                      dense
                      label="使用人员"
                      hint="请选择设备使用人员"
                    >
                      <template v-slot:selection="data">
                        {{data.item.personal.name || '未设置名称'}}
                      </template>
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                          <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>详细信息</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row no-gutters>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedItem.asset_from"
                      type="text"
                      label="设备来源"
                      hint="设备获得方式，如采购、回收等"
                      prepend-icon="device_unknown"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-autocomplete
                      autocomplete="off"
                      class="ml-4"
                      v-model="editedItem.asset_state"
                      :items="assetState"
                      multiple
                      label="设备情况"
                      hint="请选择设备使用情况"
                      item-text="name"
                      item-value="code"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      autocomplete="off"
                      v-model="editedItem.guaranteed_type"
                      :items="guaranteedType"
                      label="延保类型"
                      hint="请选择延保类型"
                      item-text="name"
                      item-value="code"
                      prepend-icon="construction"
                      outlined
                      dense
                      deletable-chips
                      multiple
                      small-chips
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                      v-model="guaranteed_time"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.guaranteed_time"
                          label="延保开始时间"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          outlined
                          dense
                          clearable
                          @click:clear="doAction('clearGuaranteed')"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.guaranteed_time" @input="doAction('set_guaranteed_deadline')" no-title scrollable></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="mx-4"
                      v-model.number="editedItem.guaranteed_limit"
                      @change="doAction('set_guaranteed_deadline')"
                      suffix="月"
                      type="number"
                      label="延保期限"
                      hint="延保期限单位月"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.guaranteed_deadline"
                      type="date"
                      label="延保到期"
                      hint="延保到期时间"
                      outlined
                      dense
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="editedItem.remarks"
                      label="备注"
                      hint="请输入设备备注信息"
                      prepend-icon="comment_bank"
                      rows="4"
                      outlined
                      dense
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-list subheader dense>
                      <v-subheader >附件清单</v-subheader>
                      <v-list-item v-for="(file, i) in editedItem.files" :key="file.href">
                        <v-list-item-avatar>
                          <v-icon>text_snippet</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="file.name"></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="d-inline">
                          <v-btn icon><v-icon @click="doAction('remove_file', '', i)">delete</v-icon></v-btn>
                          <v-btn icon><v-icon @click="doAction('download_file', '', file.href)">get_app</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-progress-linear color="secondary" v-if="Upload.uploading" :value="Upload.progressUpload"></v-progress-linear>
                          <input type="file" ref="uploadFile" :multiple="false" @change="detectFiles($event)" />
                          <v-list-item-title class="text-center"><v-btn text @click="doAction('upload_file', '', -1)"><v-icon left>cloud_upload</v-icon>上传附件</v-btn></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" class="my-4">
                    <v-btn text color="secondary" @click="showITInfo = !showITInfo">{{showITInfo ? '隐藏' : '编辑'}}IT属性</v-btn>
                    <v-btn text color="secondary" @click="showRentInfo = !showRentInfo">{{showRentInfo ? '隐藏' : '编辑'}}租赁属性</v-btn>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="showITInfo">
                  <v-col cols="4">
                    <v-text-field
                      class="ml-8"
                      v-model="editedItem.apple_id"
                      type="string"
                      label="Apple ID"
                      hint="请输入登录Apple的用户名称"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="mx-4"
                      v-model="editedItem.apple_password"
                      type="string"
                      label="Apple Password"
                      hint="请输入对应Apple ID的用户名的密码"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      autocomplete="off"
                      v-model="editedItem.isABM"
                      :items="[{ name: '是', code: true },{ name: '否', code: false }]"
                      label="是否ABM"
                      hint="请选择设备是否ABM"
                      item-text="name"
                      item-value="code"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      autocomplete="off"
                      v-model="editedItem.sim_verified"
                      :items="[{ name: '是', code: true },{ name: '否', code: false }]"
                      label="SIM卡实名"
                      hint="请选择SIM卡是否已经实名认证"
                      item-text="name"
                      item-value="code"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="ml-8"
                      v-model="editedItem.sim_code"
                      type="string"
                      label="SIM卡号"
                      hint="请输入设备的SIM卡号"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="mx-4"
                      v-model="editedItem.mac_address"
                      type="string"
                      label="MAC地址"
                      hint="请输入设备的MAC地址"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.imei_number"
                      type="string"
                      label="IMEI编号"
                      hint="请输入设备的IMEI编号"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="ml-8"
                      v-model="editedItem.iccid_code"
                      type="string"
                      label="ICCID串号"
                      hint="请输入设备的ICCID串号"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="showRentInfo">
                  <v-col cols="4">
                    <v-autocomplete
                      class="ml-8"
                      autocomplete="off"
                      v-model="editedItem.rent_info.rent_type"
                      :items="rentType"
                      label="租赁方式"
                      item-text="name"
                      item-value="code"
                      outlined
                      dense
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      class="mx-4"
                      autocomplete="off"
                      v-model="editedItem.rent_info.payment_day"
                      :items="Array.from({ length: 28 }, (v, k) => k + 1)"
                      label="客户付款日"
                      item-text="name"
                      item-value="code"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.rent_info.payment_period"
                      label="账期（天）"
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                      v-model="editedItem.showLeaseStartMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          class="ml-8"
                          v-model="editedItem.rent_info.lease_start"
                          label="起租日期"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.rent_info.lease_start"
                        @input="editedItem.showLeaseStartMenu = false"
                        @change="setLeaseEndDate"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      class="mx-4"
                      autocomplete="off"
                      v-model="editedItem.rent_info.lease_term"
                      :items="leaseTerm"
                      @change="setLeaseEndDate"
                      item-text="name"
                      item-value="code"
                      label="租期（年）"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      autocomplete="off"
                      v-model="editedItem.rent_info.payment_cycle"
                      :items="paymentCycle"
                      @change="setLeaseEndDate"
                      item-text="name"
                      item-value="code"
                      label="支付周期"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="ml-8"
                      v-model="editedItem.rent_info.total_period"
                      label="期数"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="mx-4"
                      v-model="editedItem.rent_info.expiry_date"
                      label="到期时间"
                      outlined
                      dense
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4"></v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="ml-8"
                      v-model="editedItem.rent_info.stakeholders.investor"
                      label="投资方名称"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="mx-4"
                      v-model="editedItem.rent_info.stakeholders.agent"
                      label="代理商名称"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.rent_info.stakeholders.recycler"
                      label="回收商名称"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      class="ml-8"
                      v-model="editedItem.rent_info.stakeholders.party"
                      label="签约方名称"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>自定义信息</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row no-gutters v-for="(item, i) in customFields" :key="i">
                  <v-col cols="12" v-if="item.format.type === 'text'">
                    <v-text-field
                      v-if="item.format.mask"
                      v-model="item.value"
                      v-mask="item.format.mask"
                      :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                      :label="item.format.rules.includes('required')?item.name+'*':item.name"
                      outlined
                      dense>
                    </v-text-field>
                    <v-text-field
                      v-else
                      v-model="item.value"
                      :label="item.format.rules.includes('required')?item.name+'*':item.name"
                      :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                      outlined
                      dense>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="item.format.type === 'selects'">
                    <v-autocomplete
                      autocomplete="off"
                      v-model="item.value"
                      :items="item.format.items"
                      :label="item.format.rules.includes('selected')?item.name+'*':item.name"
                      :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                      outlined
                      dense
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <widgets-custom-field v-if="editedItem.enterprise" module="Asset" :ownerId="editedItem.enterprise" ownerType="Enterprise" v-on:Complete="doAction('update_field')"></widgets-custom-field>
          <v-spacer></v-spacer>
          <v-btn text @click="goBack()">取消</v-btn>
          <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>
<script>
import Util from '@/common/util';
import moment from "moment";
import axios from "axios";
import store from "@/store";
import {mapGetters} from "vuex";
import {PURGE_FIELD, SET_ERROR} from "@/store/mutations.type";
import {
  FETCH_UPLOAD,
  FETCH_URL,
  FETCH_ASSETSTATE_LIST,
  FETCH_PRODUCTTYPE_LIST,
  FETCH_VENDOR_LIST,
  FETCH_PRODUCT_LIST,
  FETCH_WAREHOUSE_LIST,
  FETCH_ENTERPRISE_LIST,
  FETCH_EMPLOYEE_LIST,
  FETCH_FIELDS, PUBLISH_ASSET, FETCH_ASSET, EDIT_ASSET
} from "@/store/actions.type";

export default {
  data() {
    return {
      title_page: function () {
        return (this.$route.params.id !== '1' && this.$route.params.id !== '0') ? '编辑设备信息' : '创建设备信息';
      },
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
        confirmpass: () => {
          if (this.editedEmployee.password !== this.editedEmployee.repassword) {
            return '两次输入必须一致'
          } else {
            return true
          }
        }
      },
      valid: true,
      editedItem: {
        ownerType: null,
        ownerId: null,
        name: '',
        state: '',
        serial_number: '',
        vendor: '',
        productType: '',
        product:'',
        customFields: [],
        label: [],
        out_number: '',
        purchase_time: '',
        warranty_time: null,
        warranty_deadline: '',
        warehouse: null,
        warehouse_time: '',
        asset_from: '',
        asset_state: null,
        guaranteed_type: [],
        guaranteed_time: '',
        guaranteed_limit: null,
        guaranteed_deadline: '',
        remarks: '',
        imei_number: '',
        mac_address: '',
        apple_id: '',
        apple_password: '',
        sim_code: '',
        iccid_code: '',
        isABM: '',
        sim_verified: false,
        enterprise: '',
        account: '',
        user: {},
        files: [],
        rent_info: {
          payment_day: '',
          rent_type: null,
          lease_start: null,
          lease_term: 365,
          payment_cycle: 0,
          payment_period: null,
          total_period: 0,
          expiry_date: null,
          stakeholders: {
            investor: null,
            agent: null,
            recycler: null,
            party: null,
          },
        },
        showLeaseStartMenu: false,
      },
      defaultEditedItem: {
        ownerType: null,
        ownerId: null,
        name: '',
        state: '',
        serial_number: '',
        vendor: '',
        productType: '',
        product:'',
        customFields: [],
        label: [],
        out_number: '',
        purchase_time: '',
        warranty_time: null,
        warranty_deadline: '',
        warehouse: null,
        warehouse_time: '',
        asset_from: '',
        asset_state: null,
        guaranteed_type: [],
        guaranteed_time: '',
        guaranteed_limit: null,
        guaranteed_deadline: '',
        remarks: '',
        imei_number: '',
        mac_address: '',
        apple_id: '',
        apple_password: '',
        sim_code: '',
        iccid_code: '',
        isABM: '',
        sim_verified: false,
        enterprise: '',
        account: '',
        user: {},
        files: [],
        rent_info: {
          payment_day: '',
          rent_type: null,
          lease_start: null,
          lease_term: 365,
          payment_cycle: 0,
          payment_period: null,
          total_period: 0,
          expiry_date: null,
          stakeholders: {
            investor: null,
            agent: null,
            recycler: null,
            party: null,
          },
        },
        showLeaseStartMenu: false,
      },
      branchList:[],
      purchase_time: false,
      warehouse_time: false,
      guaranteed_time: false,
      openPanel: [0],
      showITInfo: false,
      showRentInfo: false,
      searchEnterprise: null,
      Upload: {
        fileIndex: -1,
        uploading: false,
        progressUpload: 0,
      },
      searchEmployee: null,
      noCount: false,
      noSN: false,
    }
  },
  created() {
    Promise.all([
      store.dispatch(FETCH_ASSETSTATE_LIST, 'isEnable=true'),
      store.dispatch(FETCH_PRODUCTTYPE_LIST, 'isEnable=true'),
      store.dispatch(FETCH_VENDOR_LIST, 'limit=-1&isEnable=true'),
      store.dispatch(FETCH_PRODUCT_LIST, 'isEnable=true&limit=200'),
      store.dispatch(FETCH_WAREHOUSE_LIST, 'isEnable=true'),
    ]).then(() => {
      if (this.$route.params.id !== '0') {
        this.getAsset(this.$route.params.id)
      }
    });

  },
  mounted() {},
  computed: {
    ...mapGetters([
      "currentEmployer",
      "stateList",
      "vendorList",
      "typeList",
      "productList",
      "warehouseList",
      "enterpriseList",
      "enterpriseLoading",
      "employeeList",
      "employeeLoading",
      "customFields",
      "uploadSign"]),
    assetType () {
      return Util.categories('assetType')
    },
    assetState () {
      return Util.categories('assetState')
    },
    guaranteedType () {
      return Util.categories('guaranteedType')
    },
    rentType() {
      return Util.categories("rentType");
    },
    leaseTerm() {
      return Util.categories("leaseTerm");
    },
    paymentCycle() {
      return Util.categories("payment_cycle");
    }
  },
  watch: {
    searchEnterprise (val) {
      val && val !== this.editedItem.enterprise && this.doAction('search_enterprise', val)
    },
    searchEmployee (val) {
      val && val !== this.editedItem.account && this.doAction('search_employee', val)
    },
  },
  methods: {
    goBack() {
      this.closeEdit()
      this.$router.push({path: '/dealer/asset/list'})
    },
    getAsset(id) {
      store.dispatch(FETCH_ASSET, id)
        .then(data => {
          if (data.product.product_type.noSN) this.noSN = true
          this.editedItem = Object.assign({}, data)
          this.editedItem._id = data._id
          if (this.editedItem.rent_info && this.editedItem.asset_type === 3) {
            this.editedItem.rent_info.rent_type = this.editedItem.rent_info.rent_type !== undefined && this.editedItem.rent_info.rent_type.toString()
            this.editedItem.rent_info.payment_cycle = this.editedItem.rent_info.payment_cycle !== undefined && this.editedItem.rent_info.payment_cycle.toString()
            this.editedItem.rent_info.lease_start = this.$options.filters['formatDate'](this.editedItem.rent_info.lease_start)
            this.editedItem.rent_info.expiry_date = this.$options.filters['formatDate'](this.editedItem.rent_info.expiry_date)
            if (!this.editedItem.rent_info.stakeholders) {
              this.editedItem.rent_info.stakeholders = {
                investor: '',
                agent: '',
                recycler: '',
                party: '',
              }
            }
            this.setLeaseEndDate()
          } else {
            this.editedItem.rent_info = Object.assign({}, this.defaultEditedItem.rent_info)
          }
          if (this.editedItem.state) this.editedItem.state = data.state._id
          this.editedItem.vendor = data.product.vendor._id
          this.editedItem.productType = data.product.product_type._id
          if (this.editedItem.vendor && this.editedItem.productType) this.doAction('set_product_list')
          this.editedItem.product = data.product._id
          if (this.editedItem.purchase_time) this.editedItem.purchase_time = this.$options.filters['formatDate'](data.purchase_time)
          if (this.editedItem.warranty_deadline) this.editedItem.warranty_deadline = this.$options.filters['formatDate'](data.warranty_deadline)
          if (this.editedItem.warehouse_time) this.editedItem.warehouse_time = this.$options.filters['formatDate'](data.warehouse_time)
          if (this.editedItem.enterprise) {
            this.doAction('search_enterprise', this.editedItem.enterprise.name)
            this.branchList = this.editedItem.enterprise.branch
            this.editedItem.enterprise = this.editedItem.enterprise._id
            this.getFieldList();
            if (this.editedItem.account) {
              this.editedItem.account = data.account._id
              this.doAction('search_employee', data.account.username)
            } else {
              this.doAction('set_employee_list')
            }
          }
          if (this.editedItem.warehouse) this.editedItem.warehouse = data.warehouse._id
          if (this.editedItem.guaranteed_time) this.editedItem.guaranteed_time = this.$options.filters['formatDate'](data.guaranteed_time)
          if (this.editedItem.guaranteed_deadline) this.editedItem.guaranteed_deadline = this.$options.filters['formatDate'](data.guaranteed_deadline)
        })
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        })
    },
    doAction(action, item = {}, additional) {
      switch (action) {
        case 'set_product_list': {
          let strQuery = 'isEnable=true&limit=1000'
          if (this.editedItem.vendor) strQuery += '&vendor='+ this.editedItem.vendor
          if (this.editedItem.productType) strQuery += '&type='+ this.editedItem.productType
          store.dispatch(FETCH_PRODUCT_LIST, strQuery)
          break
        }
        case 'set_warranty_time': {
          if (this.editedItem.product) {
            let objProduct = this.productList.find(o => o._id === this.editedItem.product)
            this.editedItem.vendor = objProduct.vendor._id
            this.editedItem.productType = objProduct.product_type._id
            this.noCount = objProduct.product_type.noCount || false
            this.noSN = objProduct.product_type.noSN || false
            if (objProduct.guarantee) {
              this.editedItem.warranty_time = objProduct.guarantee
            } else {
              this.editedItem.warranty_time = 0
            }
            if (this.editedItem.purchase_time) this.doAction('set_warranty_deadline')
          }
          break
        }
        case 'set_warranty_deadline': {
          this.purchase_time = false
          if (this.editedItem.warranty_time && this.editedItem.purchase_time) {
            let purchase_time = moment(this.editedItem.purchase_time)
            purchase_time = purchase_time.format('YYYY-MM-DD')
            let warranty_deadline = moment(purchase_time).add(this.editedItem.warranty_time, 'M')
            warranty_deadline = moment(warranty_deadline).subtract(1, 'd')
            warranty_deadline = moment(warranty_deadline).format('YYYY-MM-DD')
            this.editedItem.warranty_deadline = warranty_deadline
            this.$forceUpdate()
          }
          break
        }
        case 'set_employee_list': {
          if (this.editedItem.enterprise) {
            this.getFieldList();
            store.dispatch(FETCH_EMPLOYEE_LIST, '&enterprise='+ this.editedItem.enterprise)
            if (this.enterpriseList.length) {
              let objCorp = this.enterpriseList.find(o => o._id === this.editedItem.enterprise);
              if (objCorp) this.branchList = objCorp.branch;
            }
          } else {
            store.commit(PURGE_FIELD)
          }
          break
        }
        case 'set_asset_user': {
          let user = this.employeeList.find(o => o._id === this.editedItem.account)
          this.editedItem.user = {
            name: user.personal.name || '',
            code: user.employer.employeeNo || '',
            phone: user.phone,
            email: user.username,
            city: user.personal.city || '',
          }
          break
        }
        case 'search_enterprise': {
          this.$store.dispatch(FETCH_ENTERPRISE_LIST, 'isBlock=false&&key='+ item)
          break
        }
        case 'search_employee': {
          let enterprise = this.currentEmployer.ownerId._id
          if (this.editedItem.enterprise) enterprise = this.editedItem.enterprise
          store.dispatch(FETCH_EMPLOYEE_LIST, '&enterprise='+ enterprise +'&key='+ item)

          break
        }
        case 'set_guaranteed_deadline': {
          this.guaranteed_time = false
          // console.log('this.editedItem.guaranteed_time =>'+ this.editedItem.guaranteed_time)
          // console.log('this.editedItem.guaranteed_limit =>'+ this.editedItem.guaranteed_limit)
          if (this.editedItem.guaranteed_time && this.editedItem.guaranteed_limit) {
            let guaranteed_time = moment(this.editedItem.guaranteed_time)
            guaranteed_time = guaranteed_time.format('YYYY-MM-DD')
            let guaranteed_deadline = moment(guaranteed_time).add(this.editedItem.guaranteed_limit, 'M')
            guaranteed_deadline = moment(guaranteed_deadline).subtract(1, 'd')
            guaranteed_deadline = moment(guaranteed_deadline).format('YYYY-MM-DD')
            this.editedItem.guaranteed_deadline = guaranteed_deadline
            this.$forceUpdate()
          }

          // else {
          //   this.editedItem.guaranteed_type = null
          //   this.editedItem.guaranteed_time = null
          //   this.editedItem.guaranteed_limit = null
          //   this.editedItem.guaranteed_deadline = null
          // }
          break
        }
        case 'clearGuaranteed': {
          this.editedItem.guaranteed_limit = null
          this.editedItem.guaranteed_deadline = null
          break
        }
        case 'update_field': {
          this.getFieldList();
          break
        }
        case 'upload_file': {
          this.Upload.fileIndex = additional
          this.$store.dispatch(FETCH_UPLOAD, 'private')
            .then(this.$refs.uploadFile.click())
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });

          break
        }
        case 'download_file': {
          this.$store.dispatch(FETCH_URL, additional)
            .then((fileurl) => {
              window.location.href = fileurl;
            })
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'remove_file': {
          this.editedItem.files.splice(additional, 1)
          break
        }
        default: {
          console.log('action not exec!')
          break
        }
      }
    },
    detectFiles (e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      let fileFullName = file.name,
        fileSuffix = fileFullName.substring(fileFullName.lastIndexOf('.') + 1);
      this.Upload.uploading = true
      let param = new FormData()
      param.append('name', fileFullName);
      param.append('key', 'asset/'+ this.uploadSign.key +'/'+ fileFullName);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, fileFullName);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };
      await axios.post(this.uploadSign.host, param, config)
        .then(async () => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          this.$refs.uploadFile.value = '';

          if (this.Upload.fileIndex > -1) {
            await this.editedItem.files.splice(this.Upload.fileIndex, 1, {
              suffix: fileSuffix,
              name: fileFullName,
              href: param.get('key'),
            })
          } else {
            await this.editedItem.files.push({
              suffix: fileSuffix,
              name: fileFullName,
              href: param.get('key'),
            })
          }
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    async getFieldList(mod = 'Asset') {
      store.dispatch(FETCH_FIELDS, this.editedItem.enterprise+'/'+mod)
        .then(async () => {
          await addModelName(this.customFields)
          this.customFields.forEach(field => {
            field['value'] = this.editedItem[field._id]
          })
        })
    },
    closeEdit() {
      this.editedItem = Object.assign({}, this.defaultEditedItem)
      this.editedItem.user = Object.assign({}, this.defaultEditedItem.user)
      this.editedItem.rent_info = Object.assign({}, this.defaultEditedItem.rent_info)
      this.editedItem.rent_info.stakeholders = Object.assign({}, this.defaultEditedItem.rent_info.stakeholders)
      store.commit(PURGE_FIELD)
    },
    submit() {
      this.editedItem.customFields = []
      if (this.customFields && this.customFields.length) {
        this.customFields.forEach(item => {
          this.editedItem.customFields.push({ _id: item._id, name: item.name, value: item.value })
        })
      }
      if (this.editedItem._id) {
        if (this.editedItem.asset_state !== null) {
          if (!Array.isArray(this.editedItem.asset_state)) {
            this.editedItem.asset_state = this.editedItem.asset_state.toString().split(',')
          }
        }
        store.dispatch(EDIT_ASSET, this.editedItem)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改完成', color: 'primary'});
            this.goBack()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_ASSET, this.editedItem)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.goBack()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
    setLeaseEndDate() {
      if (this.editedItem.rent_info.lease_term) {
        if (this.editedItem.rent_info.lease_start) {
          let date = new Date(this.editedItem.rent_info.lease_start).getTime() - 1000*60*60*24
          let startDate = new Date(date)
          let year = startDate.getFullYear() + (this.editedItem.rent_info.lease_term / 365)
          let endtDate = new Date(startDate.setFullYear(year))
          this.editedItem.rent_info.expiry_date = this.$options.filters['formatDate'](endtDate)
        }
        if (this.editedItem.rent_info.payment_cycle) {
          if (this.editedItem.rent_info.payment_cycle === '0') {
            this.editedItem.rent_info.total_period = this.editedItem.rent_info.lease_term / 365 * 12
          }else if (this.editedItem.rent_info.payment_cycle === '1') {
            this.editedItem.rent_info.total_period = this.editedItem.rent_info.lease_term / 365 * 4
          }else {
            this.editedItem.rent_info.total_period = this.editedItem.rent_info.lease_term / 365 * 2
          }
        }
      }
    },
  },
}
function addModelName(arr) {
  arr.forEach(obj => {
    obj.value = ''
  })
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
