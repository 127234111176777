var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("维修诊断服务")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "flat": "",
      "dense": "",
      "hide-details": "",
      "label": "搜索服务编号、企业工单编号、设备序列号...",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.doAction('search_item');
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 工作台是集中处理日常工作服务请求、待办事项的区域，在工作台中可以查找、编辑和完成服务请求。 ")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.computedHeaders,
      "items": _vm.caseList,
      "options": _vm.optionsItem,
      "server-items-length": _vm.caseCount,
      "loading": _vm.caseLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "item-key": "_id",
      "return-object": "false",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-tabs', {
          attrs: {
            "align-with-title": ""
          },
          model: {
            value: _vm.active_tab,
            callback: function callback($$v) {
              _vm.active_tab = $$v;
            },
            expression: "active_tab"
          }
        }, [_c('v-tabs-slider', {
          attrs: {
            "color": "secondary"
          }
        }), _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('esp');
            }
          }
        }, [_vm._v("ESP工单")]), _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('gongbei');
            }
          }
        }, [_vm._v("公贝工单")])], 1), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer'), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Maintain_Edit') ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "secondary",
                  "depressed": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('select_service', _vm.query.enterprise, '选择企业和服务');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("add")]), _vm._v("新建工单 ")], 1)];
            }
          }], null, false, 1393026430)
        }, [_c('span', [_vm._v("通过表单增加请求")])]) : _vm._e(), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "loading": _vm.downloading
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('export_item');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("save_alt")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("导出数据")])]), _c('widgets-custom-header', {
          attrs: {
            "defaultHeader": _vm.defaultHeader,
            "headerList": _vm.headerItem,
            "version": 0,
            "tableName": "repairHeader"
          },
          on: {
            "Cancel": function Cancel($event) {
              return _vm.doAction('cancel_header');
            },
            "Default": function Default($event) {
              return _vm.doAction('default_header');
            },
            "Save": function Save($event) {
              return _vm.doAction('set_header');
            }
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('filter_item');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.case_number",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.isEdit && !item.isSelf ? _c('v-badge', {
          attrs: {
            "color": "secondary",
            "dot": ""
          }
        }, [_c('widgets-case-repair', {
          key: item._id,
          attrs: {
            "getId": item._id
          },
          on: {
            "Close": _vm.closeCase
          }
        }, [_vm._v(" " + _vm._s(item.case_number) + " ")])], 1) : _c('widgets-case-repair', {
          key: item._id,
          attrs: {
            "getId": item._id
          },
          on: {
            "Close": _vm.closeCase
          }
        }, [_vm._v(" " + _vm._s(item.case_number) + " "), item.isSelf && item.status < 2 ? _c('v-icon', {
          attrs: {
            "right": "",
            "small": ""
          }
        }, [_vm._v("create")]) : _vm._e()], 1)], 1)];
      }
    }, {
      key: "item.out_req_id",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.out_req_id))])];
      }
    }, {
      key: "item.out_req_type",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.out_req_type))])];
      }
    }, {
      key: "item.return_policy",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.return_policy ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm.repairPolicy.find(function (p) {
          return p.code === item.return_policy;
        }).name))]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        })];
      }
    }, {
      key: "item.catalog",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.catalog.name))])];
      }
    }, {
      key: "item.countTime",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("fromDate")(item.createTime)))])];
      }
    }, {
      key: "item.link_asset.serial_number",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.link_asset[0] ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.link_asset[0].serial_number))]) : _vm._e()];
      }
    }, {
      key: "item.total_sla.expectTime",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.total_sla && item.total_sla.status === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-baseTimer', {
          attrs: {
            "endTime": item.total_sla.expectTime,
            "alertThreshold": item.total_sla.sla_limit / 2,
            "warningThreshold": item.total_sla.sla_limit,
            "type": "text"
          }
        })], 1) : item.total_sla ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.total_sla.status === 1 ? '完成' : '超时完成'))]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.enterprise",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [item.enterprise ? _c('td', {
          staticClass: "v-list-item--link",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('open_enterprise', item.enterprise);
            }
          }
        }, [_vm._v(_vm._s(item.enterprise.name))]) : _vm._e()];
      }
    }, {
      key: "item.creator",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [item.creator ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-employee-dialogs', {
          staticClass: "v-list-item--link",
          attrs: {
            "account": item.creator._id
          }
        }, [_vm._v(_vm._s(item.creator.personal ? item.creator.personal.name : '*未设置姓名'))])], 1) : _vm._e()];
      }
    }, {
      key: "item.editor",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [item.editor ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-employee-dialogs', {
          staticClass: "v-list-item--link",
          attrs: {
            "account": item.editor._id
          }
        }, [_vm._v(_vm._s(item.editor.personal ? item.editor.personal.name : '*未设置姓名'))])], 1) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.account",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [item.account ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-employee-dialogs', {
          staticClass: "v-list-item--link",
          attrs: {
            "account": item.account._id
          }
        }, [_vm._v(_vm._s(item.account.personal ? item.account.personal.name : '*未设置姓名'))])], 1) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.link_asset",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [item.link_asset && item.link_asset.length && item.link_asset[0] && item.link_asset[0].product ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-asset-dialogs', {
          attrs: {
            "getId": item.link_asset[0]._id,
            "caseType": "repair"
          }
        }, [_vm._v(_vm._s(item.link_asset[0].product.name))])], 1) : _vm._e()];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.status === 0 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "secondary"
          }
        }, [_vm._v("待受理")]) : item.status === 1 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "primary"
          }
        }, [_vm._v(_vm._s(item.gsx_create_time ? '检测完成' : '检测中'))]) : item.status === 2 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v(_vm._s(item.repaired_time ? '维修完成' : '维修中'))]) : item.status === 3 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("待取机")]) : item.status === 4 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("服务结束")]) : item.status === 8 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("取消服务")]) : _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已完成")])], 1)];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }]),
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  })], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.filterDrawer = !_vm.filterDrawer;
            }
          }
        }, [_vm._v("隐藏")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('clear_filter');
            }
          }
        }, [_vm._v("清空")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 my-2"
  }, [_vm._v("基本信息")]), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.dealer_enterprise,
      "label": "企业",
      "hint": "请选择一家企业",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_employee_list');
      }
    },
    model: {
      value: _vm.query.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "enterprise", $$v);
      },
      expression: "query.enterprise"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.listApplicant.filter(function (item) {
        return item.personal && item.personal.name;
      }),
      "loading": _vm.searchEmployee.loadingApplicant,
      "search-input": _vm.searchEmployee.searchApplicant,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "服务提交人员",
      "hint": "请选择工单提交人员",
      "clearable": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        return _vm.$set(_vm.searchEmployee, "searchApplicant", $event);
      },
      "update:search-input": function updateSearchInput($event) {
        return _vm.$set(_vm.searchEmployee, "searchApplicant", $event);
      },
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.query.creator,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "creator", $$v);
      },
      expression: "query.creator"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.listAccount.filter(function (item) {
        return item.personal && item.personal.name;
      }),
      "loading": _vm.searchEmployee.loadingAccount,
      "search-input": _vm.searchEmployee.searchAccount,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "设备使用人员",
      "hint": "请选择设备使用人员",
      "clearable": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        return _vm.$set(_vm.searchEmployee, "searchAccount", $event);
      },
      "update:search-input": function updateSearchInput($event) {
        return _vm.$set(_vm.searchEmployee, "searchAccount", $event);
      },
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.query.account,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "account", $$v);
      },
      expression: "query.account"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "设备序列号",
      "hint": "设备序列号是设备的唯一标示",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      },
      "click:clear": function clickClear($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.serial_number,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "serial_number", $$v);
      },
      expression: "query.serial_number"
    }
  }), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("服务类型")]), _vm.repairType.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.out_req_type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "out_req_type", $$v);
      },
      expression: "query.out_req_type"
    }
  }, _vm._l(_vm.repairType, function (type) {
    return _c('v-chip', {
      key: type.code,
      attrs: {
        "value": type.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(type.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("三包策略")]), _vm.repairPolicy.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.return_policy,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "return_policy", $$v);
      },
      expression: "query.return_policy"
    }
  }, _vm._l(_vm.repairPolicy, function (policy) {
    return _c('v-chip', {
      key: policy.code,
      attrs: {
        "value": policy.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(policy.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("工单状态")]), _vm.caseStatus.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.status,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "status", $$v);
      },
      expression: "query.status"
    }
  }, _vm._l(_vm.caseStatus, function (cases) {
    return _c('v-chip', {
      key: cases.code,
      attrs: {
        "value": cases.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(cases.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("SLA过期时间")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.deadline,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "deadline", $$v);
      },
      expression: "query.deadline"
    }
  }, _vm._l(_vm.SLAExpiry, function (datetiem) {
    return _c('v-chip', {
      key: datetiem.code,
      attrs: {
        "value": datetiem.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(datetiem.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("创建时间")]), _c('v-menu', {
    ref: "menuStartCreateTime",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref20) {
        var on = _ref20.on,
            attrs = _ref20.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "开始时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "change": function change($event) {
              return _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.startCreateTime,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "startCreateTime", $$v);
            },
            expression: "query.startCreateTime"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menuStartCreateTime,
      callback: function callback($$v) {
        _vm.menuStartCreateTime = $$v;
      },
      expression: "menuStartCreateTime"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuStartCreateTime = false;

        _vm.clearTime(['createTime']);
      }
    },
    model: {
      value: _vm.query.startCreateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "startCreateTime", $$v);
      },
      expression: "query.startCreateTime"
    }
  })], 1), _c('v-menu', {
    ref: "menuStartCreateTime",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref21) {
        var on = _ref21.on,
            attrs = _ref21.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "结束时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "change": function change($event) {
              return _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.endCreateTime,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "endCreateTime", $$v);
            },
            expression: "query.endCreateTime"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menuEndCreateTime,
      callback: function callback($$v) {
        _vm.menuEndCreateTime = $$v;
      },
      expression: "menuEndCreateTime"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuEndCreateTime = false;

        _vm.clearTime(['createTime']);
      }
    },
    model: {
      value: _vm.query.endCreateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "endCreateTime", $$v);
      },
      expression: "query.endCreateTime"
    }
  })], 1), _c('v-chip-group', {
    attrs: {
      "column": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.createTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "createTime", $$v);
      },
      expression: "query.createTime"
    }
  }, _vm._l(_vm.updateTime, function (datetiem) {
    return _c('v-chip', {
      key: datetiem.code,
      attrs: {
        "value": datetiem.code,
        "filter": "",
        "label": "",
        "outlined": ""
      },
      on: {
        "change": function change($event) {
          return _vm.clearTime(['startCreateTime', 'endCreateTime']);
        }
      }
    }, [_vm._v(_vm._s(datetiem.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("更新时间")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.updateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "updateTime", $$v);
      },
      expression: "query.updateTime"
    }
  }, _vm._l(_vm.updateTime, function (datetiem) {
    return _c('v-chip', {
      key: datetiem.code,
      attrs: {
        "value": datetiem.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(datetiem.name))]);
  }), 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgService,
      callback: function callback($$v) {
        _vm.dlgService = $$v;
      },
      expression: "dlgService"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('service');
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.dealer_enterprise,
      "rules": [_vm.rules.selected],
      "label": "企业",
      "hint": "请选择一家企业",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_service_list');
      }
    },
    model: {
      value: _vm.editedCase.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.editedCase, "enterprise", $$v);
      },
      expression: "editedCase.enterprise"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.availableService,
      "rules": [_vm.rules.selected],
      "label": "服务",
      "hint": "请选择开单的服务",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedCase.service,
      callback: function callback($$v) {
        _vm.$set(_vm.editedCase, "service", $$v);
      },
      expression: "editedCase.service"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dlgService = false;
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": function click($event) {
        return _vm.submit('service');
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'enterprise' ? _c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.doAction('close_info');
      }
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }