<template>
  <div class="fluid">
    <v-row justify="space-between">
      <v-col cols="4" dense>
        <v-treeview
          :active.sync="deptActive"
          :items="departments"
          :open.sync="deptOpenTree"
          item-key="_id"
          transition
          hoverable
          activatable
          dense
          class="text-button"
        >
          <template v-slot:append="{ item }">
            {{  countEmployee(item) }} 人
          </template>
          <template v-slot:prepend="{ item }">
            <v-icon
              v-if="item.children"
              v-text="`${item.type === 1 ? 'account_tree' : 'folder_shared'}`"
            ></v-icon>
          </template>
        </v-treeview>
        <v-btn block outlined color="secondary" class="mt-2 ma-2" :disabled="!selectedDept" @click="doAction('create_dept', selectedDept)">
          <v-icon left>add</v-icon>增加部门
        </v-btn>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <v-scroll-y-transition mode="out-in">
          <div
            v-if="!selectedDept"
            class="title font-weight-light"
            style="align-self: center;"
          >
            选择一个部门
          </div>
          <v-card flat v-else>
            <v-toolbar dense flat>
              <v-toolbar-title>
                <v-icon class="mr-2" v-if="selectedDept.children">account_tree</v-icon>
                <v-icon class="mr-2" v-else>folder_shared</v-icon>
                {{selectedDept.name}}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn class="ma-2" outlined fab x-small v-if="!selectedDept.isEdit" @click="doAction('edit_managers', selectedDept)">
                <v-icon>create</v-icon>
              </v-btn>
              <v-btn class="ma-2" outlined fab x-small v-if="selectedDept.isEdit" @click="doAction('edit_dept', selectedDept)">
                <v-icon>create</v-icon>
              </v-btn>
              <v-btn class="ma-2" outlined fab x-small v-if="selectedDept.isEdit" @click="doAction('delete_dept', selectedDept)">
                <v-icon>delete</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-list-item dense @click="doAction('open_dept', item)" v-for="(item, i) in selectedDept.children" :key="i">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>keyboard_arrow_right</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item dense @click="doAction('create_dept', selectedDept)">
              <v-list-item-content>
                <v-list-item-title>创建子部门</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>add</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-data-table
              :headers="headerEmployee"
              :items="accountList"
              :options.sync="employee_options"
              :server-items-length="accountCount"
              :loading="accountLoading"
              :item-class="row_class"
              loading-text="数据读取中... 请稍后"
              :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
              item-key="_id"
              dense
              transition
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title><v-icon class="mr-2">groups</v-icon>部门人员</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn class="ma-2" outlined fab x-small @click="doAction('create_employee', selectedDept)">
                    <v-icon>person_add</v-icon>
                  </v-btn>
                  <v-btn class="ma-2" outlined fab x-small @click="doAction('import_employee', selectedDept)">
                    <v-icon>group_add</v-icon>
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.personal.name="{ item }">
                <widgets-employee-dialogs :account="item._id" v-on:UpdateEmployee="doAction('reload_tree')">{{item.personal.name ? item.personal.name : '*未设置姓名'}}</widgets-employee-dialogs>
                <v-chip v-if="selectedDept.managers.includes(item._id)" x-small label class="ml-1">负责人</v-chip>
              </template>
            </v-data-table>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDept" scrollable persistent max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            {{dialogTitle}}
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDept"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text v-if="dialogTitle === '新建部门'">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :rules="[rules.required]"
                    :value="editedDept.parent.name"
                    label="上级部门"
                    disabled
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                    v-model="editedDept.name"
                    :rules="[rules.required]"
                    type="text"
                    label="部门名称"
                    hint="请输入部门名称"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="dialogTitle === '指定负责人'">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="editedDept.managers"
                    :items="accountList"
                    item-text="personal.name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择部门负责人"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="dialogTitle === '编辑部门'">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :rules="[rules.required]"
                    :value="selDeptActive.length > 0 ? selDeptActive[0].name : ''"
                    label="上级部门"
                    disabled
                    outlined
                    dense
                    style="display:none"
                  ></v-text-field>
                  <v-menu
                    transition="slide-y-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn outlined dense block class="mb-6" v-bind="attrs" v-on="on">{{selDeptActive.length > 0 ? selDeptActive[0].name : ''}}<v-spacer></v-spacer><v-icon right dark>arrow_drop_down</v-icon></v-btn>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-treeview
                          :items="departments"
                          :active.sync="selDeptActive"
                          item-key="_id"
                          item-disabled="locked"
                          hoverable
                          activatable
                          return-object
                        >
                        </v-treeview>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <v-text-field
                    v-model="editedDept.name"
                    :rules="[rules.required]"
                    type="text"
                    label="部门名称"
                    hint="请输入部门名称"
                    outlined
                    dense
                  ></v-text-field>
                  <v-autocomplete
                    v-model="editedDept.managers"
                    :items="accountList"
                    item-text="personal.name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择部门负责人"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="dialogTitle === '新增人员'">
            <v-container>
              <v-row align="start" justify="center">
                <v-col cols="2" class="text-center">
                  <input
                    id="files"
                    type="file"
                    name="file"
                    ref="uploadInput"
                    accept="image/*"
                    :multiple="false"
                    @change="detectFiles($event)"/>
                  <v-hover v-slot:default="{ hover }">
                    <v-avatar>

                      <v-img
                        :src="editedEmployee.profile ? ossURL +'/'+ editedEmployee.profile : '/static/grey_silhouette.png'"
                        alt="设置照片"
                        aspect-ratio="1">
                        <v-progress-circular
                          color="secondary"
                          rotate="360"
                          size="48"
                          width="5"
                          v-if="Upload.uploading"
                          :value="Upload.progressUpload">
                        </v-progress-circular>
                      </v-img>
                      <div v-if="hover" class="show_upload" style="height: 100%; cursor: pointer">
                        <v-icon @click="doAction('upload_avatar', editedEmployee)" >add_a_photo</v-icon>
                      </div>
                    </v-avatar>
                  </v-hover>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="editedEmployee.name"
                    type="text"
                    label="员工姓名"
                    hint="员工姓名为用户的真实姓名"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">https</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="editedEmployee.username"
                    :rules="[rules.required, rules.email]"
                    :error-messages="editedEmployee.msgName"
                    :clearable="editedEmployee.msgName !== ''"
                    type="text"
                    label="电子邮件"
                    hint="电子邮件将作为登陆名称使用"
                    outlined
                    dense
                    required
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="editedEmployee.chkName"
                          :width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-scale-transition>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">vpn_key</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="editedEmployee.password"
                    :rules="[rules.required]"
                    :type="editedEmployee.showPass ? 'text' : 'password'"
                    :append-icon="editedEmployee.showPass ? 'visibility_off' : 'visibility'"
                    label="账号密码"
                    hint="请牢记此密码，密码将作为登陆凭证"
                    outlined
                    dense
                    required
                    @click:append="editedEmployee.showPass = !editedEmployee.showPass"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">stay_current_portrait</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="editedEmployee.phone"
                    :rules="[rules.phonenum]"
                    :error-messages="editedEmployee.msgPhone"
                    :clearable="editedEmployee.msgPhone !== ''"
                    type="number"
                    label="手机号码"
                    hint="电话号码将用于恢复密码和登陆凭证"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="editedEmployee.chkPhone"
                          :width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-scale-transition>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">account_tree</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="editedEmployee.department"
                    :items="getDeptList(departments[0])"
                    :rules="[rules.selected]"
                    item-text="name"
                    item-value="value"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择所属部门"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">perm_identity</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="editedEmployee.roles"
                    :items="flatGroupRoles(groupList)"
                    :rules="[rules.selected]"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择所属角色"
                    multiple
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-action>
                          <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">store</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="editedEmployee.branch"
                    :items="branchList"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    label="请选择所属门店"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">assignment_ind</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="editedEmployee.employeeNo"
                    type="text"
                    label="员工编号"
                    hint="员工编号为企业内部的员工编号"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6" v-for="(item, i) in customFields" :key="i">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">list</v-icon>
                </v-col>
                <v-col cols="10" v-if="item.format.type === 'text'">
                  <v-text-field
                    v-if="item.format.mask"
                    v-model="item.value"
                    v-mask="item.format.mask"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense>
                  </v-text-field>
                  <v-text-field
                    v-else
                    v-model="item.value"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="10" v-if="item.format.type === 'selects'">
                  <v-autocomplete
                    autocomplete="off"
                    v-model="item.value"
                    :items="item.format.items"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="dialogTitle === '导入人员'">
            <v-container>
              <!--<v-row>-->
                <!--<v-col cols="12" class="text-center">-->
                  <!--<v-btn-toggle v-model="toggle_import" mandatory dense>-->
                    <!--<v-btn>批量导入人员</v-btn>-->
                    <!--<v-btn>批量修改人员</v-btn>-->
                  <!--</v-btn-toggle>-->
                <!--</v-col>-->
              <!--</v-row>-->
              <v-row>
                <v-col cols="6">
                  <p>1. 下载人员模版，填写成员信息.</p>
                  <v-btn text @click="doAction('download_template')">下载模版<v-icon>file_download</v-icon></v-btn>
                </v-col>
                <v-col cols="6">
                  <input
                    id="import_file"
                    type="file"
                    name="import_file"
                    ref="uploadImport"
                    accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :multiple="false"
                    @change="importFiles($event)"/>
                  <p>2. 上传填好的文件.</p>
                  <v-btn text @click="doAction('upload_employee')"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
                </v-col>
              </v-row>
              <!--<v-row v-if="toggle_import === 1">-->
                <!--<v-col cols="12">-->
                  <!--<v-alert-->
                    <!--outlined-->
                    <!--type="error"-->
                    <!--text-->
                  <!--&gt;-->
                    <!--测试阶段 - 暂未开放功能-->
                  <!--</v-alert>-->
                <!--</v-col>-->
              <!--</v-row>-->
              <v-row v-if="importEnd">
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-alert
                    outlined
                    type="error"
                    text
                  >
                    导入失败：<ul><li v-for="(fail,index) in importFailed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
                  </v-alert>
                  <v-alert
                    outlined
                    type="success"
                    text
                  >
                    成功导入：{{importSuccess}} 条记录
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <widgets-custom-field module="Account" :ownerId="currentEmployer.ownerId._id" ownerType="Dealer" v-if="dialogTitle === '新增人员'" v-on:Complete="doAction('update_field')"></widgets-custom-field>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDept">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid" v-if="dialogTitle !== '导入人员'">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash'
import ApiService from "@/common/http";
import axios from "axios";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import XLSX from 'xlsx'
import {
  FETCH_DEPT_LIST,
  CREATE_DEPT,
  REMOVE_DEPT,
  FETCH_ACCOUNT_LIST,
  EDIT_DEPT,
  CHECK_USERNAME,
  CHECK_USERPHONE,
  CREATE_EMPLOYEE,
  FETCH_FIELDS,
  FETCH_GROUP_LIST,
  FETCH_UPLOAD,
  FETCH_BRANCH_LIST,
} from "@/store/actions.type";
export default {
  data() {
    return {
      tab: null,
      deptActive:[],
      deptOpenTree: [],
      dialogDept: false,
      dialogSelDept: false,
      dialogTitle:'',
      valid: true,
      selDeptActive: [],
      editedDept: {
        _id: '',
        name: '',
        ownerType: 'Dealer',
        ownerId: '',
        managers: [],
        parent: {},
        parentId: '',
        openTree: [],
      },
      defaultDept: {
        _id: '',
        name: '',
        ownerType: 'Dealer',
        ownerId: '',
        managers: [],
        parent: {},
        parentId: '',
        openTree: [],
      },
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      headerEmployee: [
        { text: '姓名', value: 'personal.name' },
        { text: '电子邮件', value: 'username' },
        { text: '手机号码', value: 'phone' },
        { text: '员工编号', value: 'employer.employeeNo' },
      ],
      employee_options: {},
      editedEmployee: {
        _id: '',
        username: '',
        password: '',
        phone: '',
        name: '',
        profile: '',
        department: [],
        employeeNo: '',
        roles: [],
        chkName: false,
        msgName: '',
        chkPhone: false,
        msgPhone: '',
        showPass: false,
        selDeptActive:[],
      },
      defaultEmployee: {
        _id: '',
        username: '',
        password: '',
        phone: '',
        name: '',
        profile: '',
        department: [],
        employeeNo: '',
        roles: [],
        chkName: false,
        msgName: '',
        chkPhone: false,
        msgPhone: '',
        showPass: false,
        selDeptActive:[],
      },
      toggle_import: undefined,
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      importExcelHeader: ['姓名', '电子邮件', '手机号码', '部门', '角色', '门店', '员工编号'],
      importExcelNotice: [['部门上下级用-符号分割，角色不用填写分组名称']],
      importSuccess: 0,
      importFailed: [],
      importEnd: false
    }
  },
  created() {},
  computed: {
    ...mapGetters([
      "departments",
      "deptLoading",
      "currentEmployer",
      'accountLoading',
      'accountList',
      'accountCount',
      'customFields',
      'groupList',
      'uploadSign',
      'branchList']),
    selectedDept () {
      if (!this.deptActive.length) return undefined

      const _id = this.deptActive[0]
      let result = null

      for (let obj of this.departments) {
        result = findId(obj, _id)
        if (result) {
          break
        }
      }
      return result
    },
  },
  watch: {
    dialogDept (val) {
      val || this.closeDept()
    },
    selectedDept () {
      if (this.deptActive.length) this.getEmployee({department: this.deptActive[0]})
    },
    employee_options: {
      handler () {
        this.getEmployee({department: this.deptActive[0]})
      },
      deep: true,
    },
    'editedEmployee.username'() {
      this.checkName();
    },
    'editedEmployee.phone'() {
      this.checkPhone();
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    goBack() {
      this.$router.push({path: '/dealer/system'});
    },
    getFieldList(mod = 'Account') {
      this.$store.dispatch(FETCH_FIELDS, this.currentEmployer.ownerId._id+'/'+mod)
        .then(() => {
          addModelName(this.customFields)
        })
    },
    getRolesList() {
      this.$store.dispatch(FETCH_GROUP_LIST, 'owner='+ this.currentEmployer.ownerId._id)
    },
    getBranchList() {
      this.$store.dispatch(FETCH_BRANCH_LIST)
    },
    getList(open = [], active) {
      this.$store.dispatch(FETCH_DEPT_LIST, 'owner='+ this.currentEmployer.ownerId._id)
        .then(() => {
          addDepth(this.departments)
          this.deptOpenTree = open
          this.deptActive = [active]
        })

    },
    getEmployee(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.employee_options;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      const { key, ownertype, ownerid, department, roles } = query;
      let urlQuery = '';
      if (ownertype && ownertype.abbr) urlQuery += '&ownertype='+ ownertype.abbr;
      if (department && department !== -1) urlQuery += '&department='+ department;
      if (roles && roles._id) urlQuery += '&roles='+ roles._id;
      if (ownerid) urlQuery += '&ownerid='+ ownerid;
      if (key) urlQuery += '&key='+ key;
      if (Object.keys(this.employee_options).length > 0) {
        this.$store.dispatch(FETCH_ACCOUNT_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    closeDept () {
      this.dialogDept = false
      this.$nextTick(() => {
        this.editedDept = Object.assign({}, this.defaultDept)
        this.editedEmployee = Object.assign({}, this.defaultEmployee)
        this.importSuccess = 0
        this.importFailed.length = 0
        this.importEnd = false
      })
    },
    doAction(action, item) {
      switch (action) {
        case 'create_dept': {
          if (item && item.depth === 3) {
            this.$store.commit(SET_ERROR, {msg: '不能创建下级部门', color: 'error'});
            return
          }
          this.dialogTitle = '新建部门';
          this.editedDept.parent = item ? item : this.departments[0]
          this.dialogDept = true
          break;
        }
        case 'open_dept': {
          this.deptOpenTree.push(this.selectedDept._id)
          this.deptOpenTree.push(item._id)
          this.getList(this.deptOpenTree, item._id)
          break;
        }
        case 'delete_dept': {
          if (this.countEmployee(item) > 0) {
            this.$store.commit(SET_ERROR, {msg: '请删除部门下人员后再删除部门', color: 'error'});
            return
          }
          if (confirm('确定要删除 '+ item.name +'及下级部门, 删除后该部门员工将移除该部门信息。')) {
            if (confirm('该操作不能撤销或恢复，请再次确认是否删除。')) {
              this.$store.dispatch(REMOVE_DEPT, item._id)
                .then(() => {
                  this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'});
                  this.deptOpenTree.pop()
                  this.getList(this.deptOpenTree, item.parentId)
                })
                .catch((error) => {
                  this.$store.commit(SET_ERROR, {msg: error});
                })
            }
          }
          break;
        }
        case 'edit_dept': {
          let parent = null
          for (let obj of this.departments) {
            parent = findId(obj, item.parentId)
            if (parent) {
              break
            }
          }
          this.dialogTitle = '编辑部门';
          this.editedDept['_id'] = item._id;
          this.editedDept['name'] = item.name;
          this.editedDept['managers'] = item.managers;
          this.editedDept['openTree'] = this.deptOpenTree;
          this.editedDept['parentId'] = item.parentId;
          this.selDeptActive = [parent];
          this.dialogDept = true;
          break;
        }
        case 'edit_managers': {
          this.dialogTitle = '指定负责人';
          this.editedDept['_id'] = item._id;
          this.editedDept['managers'] = item.managers;
          this.dialogDept = true;
          break;
        }
        case 'create_employee': {
          let deptId = null
          for (let obj of this.departments) {
            deptId = findId(obj, item._id)
            if (parent) {
              break
            }
          }
          this.dialogTitle = '新增人员';
          this.editedEmployee.department = [];
          this.editedEmployee.department.push(deptId._id);
          this.getFieldList('Account');
          this.getRolesList();
          this.getBranchList();
          this.dialogDept = true;
          break;
        }
        case 'import_employee': {
          this.dialogTitle = '导入人员';
          this.dialogDept = true;
          break;
        }
        case 'update_field': {
          this.getFieldList('Account');
          break;
        }
        case 'upload_avatar': {
          this.$store.dispatch(FETCH_UPLOAD)
            .then(this.$refs.uploadInput.click())
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
          break;
        }
        case 'upload_employee': {
          this.$refs.uploadImport.click()
          break;
        }
        case 'download_template': {
          this.$store.dispatch(FETCH_FIELDS, this.currentEmployer.ownerId._id+'/Account')
            .then(() => {
              this.customFields.forEach(item => {
                if (!this.importExcelHeader.includes(item.name)) this.importExcelHeader.push(item.name)
              })

              let ws = XLSX.utils.aoa_to_sheet(this.importExcelNotice)
              XLSX.utils.sheet_add_json(ws, [
                {'姓名': '张力（样例数据）', '电子邮件': 'test@test.com', '手机号码': 13800138000, '部门': '客户服务中心-热线', '角色': this.currentEmployer.roles[0].name, '门店': '第一门店', '员工编号': 'A001'}
              ], {header: this.importExcelHeader, origin: 'A2'})
              const merge = [
                {s: {r:0, c:0}, e: {r:0, c:this.importExcelHeader.length - 1}}
              ]
              ws['!merges'] = merge
              // if(!ws.A1.c) ws.A1.c = [];
              // ws.A1.c.hidden = true;
              // ws.A1.c.push({a:"ESP", t:"导入说明\n"});
              ws.A1.s = {alignment:{ wrapText: true }};
              let wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, ws, 'Import_Employee')
              XLSX.writeFile(wb, this.currentEmployer.ownerId.name +'_人员模版.xlsx')
            })
          break;
        }
        case 'reload_tree' : {
          this.getEmployee({department: this.deptActive[0]})
          this.getList(this.deptOpenTree, this.deptActive[0])
          break;
        }
      }
    },
    submit() {
      switch (this.dialogTitle) {
        case '新建部门': {
          let strInsert = {}
          strInsert.name = this.editedDept.name
          strInsert.parentid = this.editedDept.parent._id
          this.$store.dispatch(CREATE_DEPT, strInsert)
            .then((data)=> {
              this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
              this.deptOpenTree.push(data.parentId)
              this.deptOpenTree.push(data._id)
              this.getList(this.deptOpenTree, data._id)
              this.closeDept()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break;
        }
        case '编辑部门': {
          if (!this.selDeptActive.length) {
            this.$store.commit(SET_ERROR, {msg: '必须选择上级部门', color: 'error'});
            return
          }
          let strUpdate = {}
          let id = this.editedDept._id
          strUpdate._id = id
          strUpdate.name = this.editedDept.name
          strUpdate.parentid = this.selDeptActive[0]._id
          strUpdate.managers = this.editedDept.managers
          if (id === strUpdate.parentid) {
            this.$store.commit(SET_ERROR, {msg: '上级部门不能设置为自身', color: 'error'});
            return
          }
          this.$store.dispatch(EDIT_DEPT, strUpdate)
            .then((data)=> {
              this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
              this.deptOpenTree.push(data.parentId)
              this.deptOpenTree.push(data._id)
              this.getList(this.deptOpenTree, data._id)
              this.closeDept()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error});
            })
          break;
        }
        case '指定负责人': {
          let strUpdate = {}
          let id = this.editedDept._id
          strUpdate._id = id
          strUpdate.managers = this.editedDept.managers
          this.$store.dispatch(EDIT_DEPT, strUpdate)
            .then((data)=> {
              this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
              this.deptOpenTree.push(data.parentId)
              this.deptOpenTree.push(data._id)
              this.getList(this.deptOpenTree, data._id)
              this.closeDept()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error});
            })
          break;
        }
        case '新增人员': {
          let strInsert = {}
          strInsert['personal'] = {}
          strInsert['employer'] = {}
          strInsert['customFields'] = []
          strInsert.username = this.editedEmployee.username
          strInsert.password = this.editedEmployee.password
          strInsert.phone = this.editedEmployee.phone
          strInsert['employer'].department = this.editedEmployee.department
          strInsert['employer'].roles = this.editedEmployee.roles
          if (this.customFields.length) {
            this.customFields.forEach(item => {
              strInsert['customFields'].push({ _id: item._id, value: item.value })
            })
          }
          if (this.editedEmployee.name) strInsert['personal'].name = this.editedEmployee.name
          if (this.editedEmployee.profile) strInsert['personal'].profile = this.editedEmployee.profile
          if (this.editedEmployee.employeeNo) strInsert['employer'].employeeNo = this.editedEmployee.employeeNo;
          if (this.editedEmployee.branch) strInsert['employer'].branch = this.editedEmployee.branch;

          this.$store.dispatch(CREATE_EMPLOYEE, strInsert)
            .then((data) => {
              this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
              this.getList(this.deptOpenTree, data.employer.department[0])
              this.$store.dispatch(FETCH_ACCOUNT_LIST, '&department='+ data.employer.department[0])
              this.closeDept()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break;
        }
      }
    },
    importFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importEmployee(fileList[x]);
      })
    },
    importEmployee(file) {
      let param = new FormData();
      param.append('file', file);
      ApiService.post('/employee/import', param)
        .then((data) => {
          this.importSuccess = data.data.Success
          this.importFailed = data.data.Failed
          this.$refs.uploadImport.value = '';
          this.importEnd = true
        })
        .catch((err) => {
          this.$refs.uploadImport.value = '';
          this.importSuccess = 0
          this.importFailed = []
          this.importEnd = true
          let arrErr = [];
          if (err.response.data) {
            if (err.response.data.code === 11000) {
              arrErr = err.response.data.writeErrors.map(obj => { return obj.op.personal.name });
            }
          }
          if (arrErr.length > 0) {
            this.$store.commit(SET_ERROR, {msg: '请检查以下员工数据：'+ arrErr});
          } else {
            this.importFailed = [{line: 2, error: err.response.data.message}]
          }
        });
      this.$store.dispatch(FETCH_DEPT_LIST)
      this.getList(this.deptOpenTree, this.deptActive[0])
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.currentEmployer.ownerId._id +'/avatar/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.editedEmployee.profile = this.currentEmployer.ownerId._id +'/avatar/'+ this.uploadSign.key;
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    countEmployee(obj, countKey = 'employeeCount') {
      if (obj.type === 1) {
        return obj[countKey];
      } else {
        return sumChildren(obj);
      }
    },
    getDeptList(obj) {
      let arrObj = [];
      arrObj.push({name: obj.name, value: obj._id})
      if (obj.children) {
        for (let item_1 of obj.children) {
          arrObj.push({name: obj.name +'>>'+ item_1.name, value: item_1._id})
          if (item_1.children) {
            for (let item_2 of item_1.children) {
              arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name, value: item_2._id})
              if (item_2.children) {
                for (let item_3 of item_2.children) {
                  arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name +'>>'+ item_3.name, value: item_3._id})
                }
              }
            }
          }
        }
      }
      return arrObj;
    },
    checkName: _.debounce(function () {
      if (this.editedEmployee.username) {
        this.editedEmployee.chkName = true;
        this.$store.dispatch(CHECK_USERNAME, this.editedEmployee.username)
          .then(() => {
            this.editedEmployee.msgName = '电子邮件已被注册，请更换其它地址';
            this.editedEmployee.chkName = false;
          })
          .catch(() => {
            this.editedEmployee.msgName = '';
            this.editedEmployee.chkName = false;
          })
      }
    }, 1000),
    checkPhone: _.debounce(function () {
      if (this.editedEmployee.phone) {
        this.editedEmployee.chkPhone = true;
        this.$store.dispatch(CHECK_USERPHONE, this.editedEmployee.phone)
          .then(() => {
            this.editedEmployee.msgPhone = '手机号码已被注册，请更换其它号码';
            this.editedEmployee.chkPhone = false;
          })
          .catch(() => {
            this.editedEmployee.msgPhone = '';
            this.editedEmployee.chkPhone = false;
          })
      }
    }, 1000),
    flatGroupRoles(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.roles.forEach((rolesItem) => {
          arrRoles.push({ _id: rolesItem._id, name: rolesItem.name, group: groupItem.name })
        })
        arrRoles.push({ divider: true })
      })
      return arrRoles
    },
    row_class(item) {
      if (item.isBlock) {
        return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
      }
    }
  },
  directives: {}
}
function findId(obj, targetId) {
  if (obj._id === targetId) return obj
  if (obj.children) {
    for (let item of obj.children) {
      let check = findId(item, targetId)
      if (check) return check
    }
  }
  return null
}
function sumChildren(obj, key = 'employeeSum') {
  let count = obj[key]
  if (obj.children) {
    for (let item of obj.children) {
      count += sumChildren(item)
    }
  }
  return count
}
function addDepth(arr, depth = 0) {
  arr.forEach(obj => {
    obj.depth = depth
    depth === 3 ? obj.locked = true : obj.locked = false;
    addDepth(obj.children, depth + 1)
  })
}
function addModelName(arr) {
  arr.forEach(obj => {
    obj.value = ''
  })
}

</script>
<style scoped>
  .show_upload {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
</style>
