var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("测试工具")]), _c('v-spacer')], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 仅供开发和管理员使用的系统工具，可能会对系统造成不可逆的损坏，请谨慎使用... ")]), _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v("条形码生成器")]), _c('v-card-text', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "label": "请输入需要转换的条形码字符串",
      "placeholder": "输入单个或者多个字符串，可以从EXCEL直接粘贴多行字符串"
    },
    on: {
      "change": _vm.createSvg
    },
    model: {
      value: _vm.editBarcode,
      callback: function callback($$v) {
        _vm.editBarcode = $$v;
      },
      expression: "editBarcode"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.createBarcode
    }
  }, [_vm._v("生成")]), _c('v-menu', {
    attrs: {
      "transition": "slide-y-transition",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "depressed": "",
            "color": "secondary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("增加批量修改字段")])];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.arrBarcodeOptions, function (item, index) {
    return _c('v-list-item', {
      key: index,
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.createBarcode(item);
        }
      }
    }, [_c('v-list-item-title', [_vm._v(_vm._s(item.text))])], 1);
  }), 1)], 1)], 1), _vm.arrBarcode && _vm.arrBarcode.length ? _c('v-card-text', [_c('v-row', _vm._l(_vm.arrBarcode, function (code, index) {
    return _c('v-col', {
      key: index + '_code',
      attrs: {
        "cols": "6"
      }
    }, [_c('v-sheet', {
      attrs: {
        "color": "white",
        "elevation": "1"
      }
    }, [_vm._v(" format: " + _vm._s(_vm.formatBarcode) + " | code: " + _vm._s(code) + " "), _c('svg', {
      staticClass: "barcode",
      attrs: {
        "jsbarcode-format": _vm.formatBarcode,
        "jsbarcode-value": code,
        "jsbarcode-textmargin": "0",
        "jsbarcode-fontoptions": "bold"
      }
    })])], 1);
  }), 1)], 1) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-divider'), _vm._v("Global：" + _vm._s(_vm.Global) + " "), _c('v-divider'), _vm._v("Online：" + _vm._s(_vm.users.length) + " ")], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.users, function (account) {
    return _c('v-chip', {
      key: account.userID,
      staticClass: "ma-2",
      attrs: {
        "color": account.connected ? 'secondary' : 'primary',
        "text-color": "white"
      }
    }, [_c('v-avatar', {
      attrs: {
        "left": ""
      }
    }, [account.connected ? _c('v-icon', [_vm._v("account_circle")]) : _c('v-icon', [_vm._v("offline_bolt")])], 1), _vm._v(" " + _vm._s(account.username) + " ")], 1);
  }), 1)], 1)], 1), _c('v-row', [_c('v-divider')], 1), _c('v-row')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }