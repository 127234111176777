<template>
  <v-container>
    <v-form v-model="validOrder">
      <v-card>
        <v-toolbar class="secondary" dark flat>
          <v-btn icon @click="$router.push('/dealer/workbench/order/list')">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>新建订单</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text :disabled="!validOrder || !linkedProduct.length" @click="showRiskTip">
            保存
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-subheader>基本信息</v-subheader>
              <v-divider class="mb-4"></v-divider>
              <v-text-field
                v-model="orderItem.po_number"
                label="PO号"
                outlined
                :rules="[rules.required]"
                dense
                prepend-icon="content_paste"
              ></v-text-field>
              <v-text-field
                v-if="orderType !== 'rent'"
                v-model="orderItem.out_number"
                label="DT号"
                outlined
                :rules="[rules.required]"
                dense
                prepend-icon="list_alt"
              ></v-text-field>
              <v-autocomplete
                v-model="orderItem.enterprise"
                label="客户"
                autocomplete="off"
                :items="enterpriseList"
                :loading="isEnterpriseLoading"
                :search-input.sync="searchEnterprise"
                item-text="name"
                item-value="_id"
                :rules="[rules.required]"
                @change="setInvoiceAndMail"
                hide-no-data
                outlined
                dense
                prepend-icon="business"
              ></v-autocomplete>
              <v-autocomplete
                v-if="enterpriseList && enterpriseList.length"
                v-model="orderItem.branch"
                label="分支机构"
                autocomplete="off"
                clearable
                :items="enterpriseList[0].branch"
                @change="changeBranch"
                hide-no-data
                outlined
                dense
                prepend-icon="call_split"
              ></v-autocomplete>
              <v-autocomplete
                v-model="orderItem.sales"
                label="分配销售"
                item-text="personal.name"
                :rules="[rules.required]"
                item-value="_id"
                :loading="isSalesLoading"
                :search-input.sync="searchSales"
                :items="salesList"
                outlined
                dense
                prepend-icon="person_outline"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{
                        data.item.personal.name
                          ? data.item.personal.name
                          : "未设置名称"
                      }}</v-list-item-title>
                    <v-list-item-subtitle
                    >{{ data.item.username }} -
                      {{ data.item.phone }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
<!--              <v-select-->
<!--                v-if="orderType === 'rent'"-->
<!--                v-model="orderItem.payment_day"-->
<!--                :items="Array.from({ length: 28 }, (v, k) => k + 1)"-->
<!--                label="客户付款日"-->
<!--                outlined-->
<!--                dense-->
<!--                prepend-icon="payment"-->
<!--                clearable-->
<!--              ></v-select>-->
              <v-select
                v-if="orderType === 'rent'"
                v-model="orderItem.rent_type"
                :items="rentType"
                label="租赁方式"
                :rules="[rules.selected]"
                item-text="name"
                item-value="code"
                outlined
                dense
                prepend-icon="published_with_changes"
              ></v-select>
<!--              <v-menu-->
<!--                v-if="orderType === 'rent'"-->
<!--                v-model="startMenu"-->
<!--                :close-on-content-click="false"-->
<!--                :nudge-right="40"-->
<!--                transition="scale-transition"-->
<!--                offset-y-->
<!--                min-width="auto"-->
<!--              >-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                  <v-text-field-->
<!--                    v-model="orderItem.lease_start"-->
<!--                    label="起租日期"-->
<!--                    prepend-icon="date_range"-->
<!--                    readonly-->
<!--                    :rules="[rules.required]"-->
<!--                    v-bind="attrs"-->
<!--                    v-on="on"-->
<!--                    outlined-->
<!--                    dense-->
<!--                  ></v-text-field>-->
<!--                </template>-->
<!--                <v-date-picker-->
<!--                  v-model="orderItem.lease_start"-->
<!--                  @input="startMenu = false"-->
<!--                  @change="setEndDate"-->
<!--                ></v-date-picker>-->
<!--              </v-menu>-->
              <v-select
                v-if="orderType === 'rent'"
                v-model="orderItem.lease_term"
                label="租期（年）"
                outlined
                :rules="[rules.selected]"
                item-text="name"
                item-value="code"
                dense
                :items="leaseTerm"
                prepend-icon="date_range"
                @change="setEndDate"
              ></v-select>
              <v-select
                v-if="orderType === 'rent'"
                v-model="orderItem.payment_cycle"
                :items="paymentCycle"
                label="支付周期"
                :rules="[rules.selected]"
                outlined
                item-text="name"
                item-value="code"
                dense
                prepend-icon="date_range"
                @change="setEndDate"
              ></v-select>
              <v-text-field
                v-if="orderType === 'rent'"
                v-model="orderItem.payment_period"
                label="账期（天）"
                :rules="[rules.required]"
                outlined
                dense
                type="number"
                prepend-icon="date_range"
              ></v-text-field>
              <v-text-field
                v-if="orderType === 'rent'"
                v-model="orderItem.total_period"
                label="期数"
                outlined
                dense
                disabled
                prepend-icon="date_range"
              ></v-text-field>
<!--              <v-text-field-->
<!--                v-if="orderType === 'rent'"-->
<!--                v-model="orderItem.endDate"-->
<!--                label="到期时间"-->
<!--                outlined-->
<!--                dense-->
<!--                disabled-->
<!--                prepend-icon="date_range"-->
<!--              ></v-text-field>-->
<!--              <v-text-field-->
<!--                v-if="orderType === 'rent'"-->
<!--                v-model="orderItem.stakeholders.investor"-->
<!--                label="投资方名称"-->
<!--                outlined-->
<!--                dense-->
<!--                prepend-icon="account_circle"-->
<!--              ></v-text-field>-->
<!--              <v-text-field-->
<!--                v-if="orderType === 'rent'"-->
<!--                v-model="orderItem.stakeholders.agent"-->
<!--                label="代理商名称"-->
<!--                outlined-->
<!--                dense-->
<!--                prepend-icon="account_circle"-->
<!--              ></v-text-field>-->
<!--              <v-text-field-->
<!--                v-if="orderType === 'rent'"-->
<!--                v-model="orderItem.stakeholders.recycler"-->
<!--                label="指定回收商"-->
<!--                outlined-->
<!--                dense-->
<!--                prepend-icon="account_circle"-->
<!--              ></v-text-field>-->
<!--              <v-text-field-->
<!--                v-if="orderType === 'rent'"-->
<!--                v-model="orderItem.stakeholders.party"-->
<!--                label="签约方"-->
<!--                outlined-->
<!--                dense-->
<!--                prepend-icon="account_circle"-->
<!--              ></v-text-field>-->
              <v-select
                v-if="orderType === 'rent'"
                v-model="orderItem.stakeholders.party"
                :items="PartyList"
                label="签约方"
                :rules="[rules.selected]"
                outlined
                item-text="name"
                item-value="code"
                dense
                prepend-icon="gavel"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-subheader>
                <span>发票信息</span>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  @click="editEnterprise('invoice')"
                  v-if="orderItem.enterprise"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider class="mb-4"></v-divider>
              <v-scroll-y-transition mode="out-in">
                <v-card outlined v-if="orderItem.enterprise">
                  <v-list :class="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>发票类型</v-list-item-title>
                        <v-list-item-subtitle v-if="defaultInvoice.invoice_type !== null">{{
                          defaultInvoice.invoice_type === 0
                            ? "增值税普通发票"
                            : "增值税专用发票"
                        }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-else>无</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>发票抬头</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultInvoice.title || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>信用代码</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultInvoice.license_code || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>开户行</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultInvoice.bank_name || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>银行账号</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultInvoice.bank_account || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>企业地址</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultInvoice.license_address || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>企业电话</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultInvoice.license_telephone || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>发票接收人</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultInvoice.delivery_name || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>接收人电话</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultInvoice.delivery_phone || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>发票邮寄地址</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultInvoice.delivery_address || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-scroll-y-transition>
              <v-subheader>
                <span>收货信息</span>
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  small
                  @click="editEnterprise('delivery')"
                  v-if="orderItem.enterprise"
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider class="mb-4"></v-divider>
              <v-scroll-y-transition mode="out-in">
                <v-card outlined v-if="orderItem.enterprise">
                  <v-list :class="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>收货人姓名</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultDelivery.name || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>收货人电话</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultDelivery.phone || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>收货人地址</v-list-item-title>
                        <v-list-item-subtitle>{{
                          defaultDelivery.address || "无"
                        }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-scroll-y-transition>
            </v-col>
          </v-row>
          <v-divider class="my-6"></v-divider>
          <v-tabs>
            <v-tab>产品</v-tab>
            <v-tab>附件</v-tab>
            <v-tab>备注</v-tab>
            <v-tab-item>
              <v-card-text class="px-0">
                <v-data-table
                  :headers="proHeaders"
                  :items="linkedProduct"
                  item-key="index"
                  no-data-text="暂无产品"
                  :items-per-page="100"
                  hide-default-footer
                  dense
                >
                  <template v-slot:body.append v-if="linkedProduct.length">
                    <tr class="secondary--text">
                      <td>合计</td>
                      <td></td>
                      <td></td>
                      <td v-if="orderType === 'rent'"></td>
                      <td>
                        <span>
                          {{ total_quantity }}
                        </span>
                      </td>
                      <td></td>
                      <td>¥{{ Number(total_price).toFixed(2) | formatMoney }}</td>
                      <td></td>
                    </tr>
                  </template>
                  <template v-slot:item.part_number="{ item }">
                    <td
                      nowrap="true"
                      class="text-truncate"
                      style="cursor: pointer"
                      @click="editProInfo(item)"
                    >
                      <v-icon small>edit</v-icon>
                      {{ item.part_number || '暂无'}}
                    </td>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <td
                      nowrap="true"
                      class="text-truncate"
                    >
                      {{ item.name }}
                    </td>
                  </template>
                  <template v-slot:item.product_text="{ item, index }">
                    <td
                      style="max-width: 150px; cursor: pointer;"
                      :nowrap="open[index]?false:true"
                      :class="!open[index]?'text-truncate text-decoration-underline':'text-decoration-underline'"
                      @click="showText(index)"
                    >
                      {{ item.product_text }}
                    </td>
                  </template>
                  <template v-slot:item.quantity="props">
                    <div>
                      {{ props.item.quantity }}
                    </div>
                  </template>
                  <template v-slot:item.total_period>
                  <div>
                    {{ orderItem.total_period }}
                  </div>
                </template>
                  <template v-slot:item.price="props">
                    <div v-if="orderType === 'rent'">
                      ¥{{ props.item.rent_price }}
                    </div>
                    <div v-else>
                      ¥{{ props.item.sales_price }}
                    </div>
                  </template>
                  <template v-slot:item.total="{ item }">
                    <div v-if="orderType === 'rent'">
                      ¥{{ Number(item.quantity * item.rent_price * orderItem.total_period ).toFixed(4) | formatMoneyDecimal }}
                    </div>
                    <div v-else>
                      ¥{{ Number(item.quantity * item.sales_price).toFixed(4) | formatMoneyDecimal }}
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          icon
                          @click="removeLinked(item)"
                        >
                          <v-icon>remove</v-icon>
                        </v-btn>
                      </template>
                      <span>移除</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-row>
                  <v-col class="d-flex justify-end">
                    <v-btn text @click="editProInfo('')">
                      <v-icon left>link</v-icon>关联产品
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text>
                <v-list subheader dense>
                  <v-list-item v-for="(file, i) in editedItem.files" :key="file.href">
                    <v-list-item-avatar>
                      <v-icon>text_snippet</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="file.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="d-inline">
                      <v-btn icon>
                        <v-icon @click="removeAttach(i)">delete</v-icon>
                      </v-btn>
                      <v-btn icon>
                        <v-icon @click="downloadAttach(file.href)">get_app</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title align="end">
                        <v-btn text @click="createAttach()">
                          <v-icon left>attach_file</v-icon>上传附件
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text>
                <v-list dense>
                  <template v-for="(item, index) in editedItem.remarks">
                    <v-divider v-if="item.divider" :key="index + '_remarks_hr'" :inset="item.inset"></v-divider>
                    <v-list-item v-else :key="index + '_remarks_title'" three-line>
                      <widgets-employee-dialogs :account="item.account">
                        <v-list-item-avatar>
                          <v-img :src="item.avatar ? ossURL + '/' + item.avatar : '/static/grey_silhouette.png'"></v-img>
                        </v-list-item-avatar>
                      </widgets-employee-dialogs>
                      <v-list-item-content>
                        <v-list-item-title>{{item.title}} {{ item.createTime | fromDate }} {{item.private ? ' - 仅个人可见' : ''}}</v-list-item-title>
                        {{item.context}}
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon @click="removeRemark(item)">delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title align="end">
                        <v-btn text @click="createRemarks()">
                          <v-icon left>rate_review</v-icon>增加备注
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            :disabled="!validOrder || !linkedProduct.length"
            @click="showRiskTip()"
            v-if="orderType === 'rent'"
          >
            保存后续租新订单
          </v-btn>
          <v-btn
            color="secondary"
            depressed
            :disabled="!validOrder || !linkedProduct.length"
            @click="showRiskTip()"
          >
            <v-icon left>save</v-icon>保 存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog v-model="dlgEnterprise" persistent scrollable max-width="600px">
      <v-form v-model="validEnterprise" @submit.prevent="submitEmployee">
        <v-card>
          <v-card-title>
            <span class="headline">创建新客户</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="dlgEnterprise = false"
              ><v-icon>close</v-icon></v-btn
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row align="center" justify="center">
              <v-col cols="10">
                <v-text-field
                  v-model="newEnterprise.editedItem.name"
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="客户名称"
                  hint="请输入客户的名称，该名称为全局唯一命名"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="10">
                <v-text-field
                  v-model="newEnterprise.editedItem.name_code"
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="客户简称"
                  hint="请输入客户的简称，该简称来自S1系统。"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="10">
                <v-text-field
                  v-model="newEnterprise.editedItem.contact_name"
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="联系人"
                  hint="请输入客户的联系人，联系人不公开仅用于经销商与客户联系使用"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="10">
                <v-text-field
                  v-model="newEnterprise.editedItem.contact_phone"
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="联系电话"
                  hint="请输入企业的联系电话，联系电话不公开仅用于经销商与客户联系使用"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="10">
                <v-text-field
                  v-model="newEnterprise.editedItem.address"
                  :error-messages="newEnterprise.editedItem.msgAddress"
                  :clearable="newEnterprise.editedItem.msgAddress !== ''"
                  outlined
                  dense
                  :rules="[rules.required]"
                  label="详细地址"
                  hint="请输入完整的地址，地址必须按正规格式输入"
                >
                  <template v-slot:append>
                    <v-fade-transition leave-absolute>
                      <v-progress-circular
                        v-if="newEnterprise.editedItem.chkAddress"
                        width="2"
                        size="24"
                        color="secondary"
                        indeterminate
                      ></v-progress-circular>
                    </v-fade-transition>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="dlgEnterprise = false">取消</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="submitCreateEnterprise"
              :disabled="!validEnterprise"
              >创建</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="proInfoDlg" persistent scrollable width="700">
      <widgets-product-dialogs
        :id="activeId"
        :key="activeId"
        v-on:Close="proInfoDlg = false"
      ></widgets-product-dialogs>
    </v-dialog>
    <v-dialog v-model="dlgRemarks" persistent max-width="550px">
      <v-card>
        <v-card-title>
          增加备注
          <v-spacer></v-spacer>
          <v-btn icon @click="closeRemarks"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-switch v-model="editedRemark.private" :label="`仅个人可见: ${editedRemark.private ? '是' : '否'}`"></v-switch>
              <v-textarea
                outlined
                auto-grow
                rows="3"
                v-model="editedRemark.context"
                label="备注内容"
                value=""
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeRemarks()">取消</v-btn>
          <v-btn text color="secondary"
                 :disabled="!editedRemark.context"
                 @click="addRemarks(editedRemark)">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgAttach" persistent max-width="550px">
      <v-card v-if="editedAttach.progressUpload && editedAttach.progressUpload.length" color="secondary" dark>
        <v-card-title>文件上传中...</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" v-for="(progress, i) in editedAttach.progressUpload" :key="'progress_'+ i">
              {{editedAttach.files[i].name}}
              <v-progress-linear
                color="white"
                :value="progress"
              >
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          上传附件
          <v-spacer></v-spacer>
          <v-btn icon @click="closeAttach()"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-switch v-model="editedAttach.public" :label="`客户可见: ${editedAttach.public ? '是' : '否'}`"></v-switch>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="editedAttach.files"
                label="单击此处选择文件上传"
                outlined
                dense
                small-chips
                multiple
                show-size
                counter
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeAttach()">取消</v-btn>
          <v-btn text color="secondary"
                 :disabled="!editedAttach.files.length"
                 @click="addAttach()">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newProDlg" scrollable persistent max-width="600px">
      <v-form v-model="validNewPro" @submit.prevent="saveEditPro">
        <v-card>
          <v-card-title>
            <span>关联产品</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeEditPro"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 500px;">
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  ref="myBox"
                  v-model="newProInfo.part_number"
                  :items="partNoBox"
                  :search-input.sync="checkItem"
                  @change="changeProInfo"
                  type="text"
                  return-object
                  item-text="part_number"
                  item-value="part_number"
                  label="产品型号"
                  :error-messages="errPartNo"
                  hint="请输入产品型号 如：MGXC2CH/A"
                  outlined
                  :loading="isCheckProLoading"
                  dense
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="newProInfo.name"
                  label="产品名称"
                  :rules="[rules.required]"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="newProInfo.quantity"
                  label="数量"
                  :rules="[rules.required, rules.negative]"
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="orderType === 'purchase'">
                <v-text-field
                  v-model="newProInfo.sales_price"
                  type="number"
                  label="销售价格"
                  :rules="[rules.required]"
                  outlined
                  dense
                  prefix="¥"
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-else>
                <v-text-field
                  v-model="newProInfo.rent_price"
                  type="number"
                  label="租赁价格"
                  :rules="[rules.required]"
                  outlined
                  dense
                  prefix="¥"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="newProInfo.vendor"
                  :items="vendorList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  label="产品品牌"
                  hint="请选择产品品牌"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="newProInfo.product_type"
                  :items="typeList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  label="产品类型"
                  hint="请选择产品类型"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="newProInfo.product_text"
                  label="产品描述"
                  no-resize
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  autocomplete="off"
                  v-model="newProInfo.guaranteed_type"
                  :items="guaranteedType"
                  :rules="[rules.selected]"
                  label="延保类型"
                  item-text="name"
                  item-value="code"
                  outlined
                  dense
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="mx-4"
                  v-model.number="newProInfo.guaranteed_limit"
                  suffix="月"
                  type="number"
                  label="延保期限"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  autocomplete="off"
                  v-model="newProInfo.isABM"
                  :items="[{ name: '是', code: true },{ name: '否', code: false }]"
                  label="是否ABM"
                  hint="请选择设备是否ABM"
                  item-text="name"
                  item-value="code"
                  outlined
                  dense
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  autocomplete="off"
                  v-model="newProInfo.preinstall_software"
                  :items="preSoftware"
                  label="预装软件"
                  item-text="name"
                  item-value="code"
                  outlined
                  dense
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  autocomplete="off"
                  v-model="newProInfo.provide_service"
                  :items="proService"
                  label="包含服务"
                  item-text="name"
                  item-value="code"
                  outlined
                  dense
                  deletable-chips
                  multiple
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="closeEditPro">
              取消
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="saveEditPro"
              :disabled="!validNewPro"
            >
              提交
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dlgConflict" max-width="500px" persistent>
      <v-card>
        <v-card-title class="text-h5"
          >产品型号{{
            newProInfo.part_number
          }}已经存在，请重新填写产品型号或关联现有型号产品。</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="clearPartNo"
            >重新填写型号</v-btn
          >
          <v-btn color="secondary" text @click="linkPresent"
            >关联现有产品</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgEdit" persistent max-width="600px">
      <v-form v-model="validEdit" @submit.prevent="submitEditEnterprise">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dlgTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-row v-if="dlgTitle === '发票信息'">
              <v-col cols="12">
                <v-radio-group v-model="editInvoice.invoice_type" row>
                  <v-radio label="增值税普通发票" :value="0"></v-radio>
                  <v-radio label="增值税专用发票" :value="1"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="抬头名称"
                  dense
                  hide-details
                  v-model="editInvoice.title"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="统一社会信用代码"
                  dense
                  hide-details
                  v-model="editInvoice.license_code"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="开户行"
                  dense
                  hide-details
                  v-model="editInvoice.bank_name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="银行账号"
                  dense
                  hide-details
                  v-model="editInvoice.bank_account"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="企业地址"
                  dense
                  hide-details
                  v-model="editInvoice.license_address"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="企业电话"
                  dense
                  hide-details
                  v-model="editInvoice.license_telephone"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="*发票接收人"
                  :rules="[rules.required]"
                  dense
                  hide-details
                  v-model="editInvoice.delivery_name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="*接收人电话"
                  :rules="[rules.required]"
                  dense
                  hide-details
                  v-model="editInvoice.delivery_phone"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="*发票邮寄地址"
                  :rules="[rules.required]"
                  dense
                  hide-details
                  v-model="editInvoice.delivery_address"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="dlgTitle === '收货信息'">
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="收货人姓名"
                  dense
                  hide-details
                  v-model="delivery_info.name"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="收货人电话"
                  dense
                  hide-details
                  v-model="delivery_info.phone"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  label="收货人地址"
                  dense
                  hide-details
                  v-model="delivery_info.address"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeEditEnterprise()">取消</v-btn>
            <v-btn color="secondary" text @click="submitEditEnterprise" :disabled="!validEdit"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogRiskTip" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">风控提示</v-card-title>
        <v-card-text>
          <v-container>
            <v-alert type="info" color="orange">
              由于订单所属客户的授信额度不足或未进行授信审批，点击确认后订单客户将进行授信审批，审批通过后该订单可进行后续操作。
            </v-alert>
            <v-row>
              <v-col cols="12">
                在进行授信审批时，您还需要补充以下内容：
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="businessType"
                  :items="businessTypeList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="code"
                  autocomplete="off"
                  outlined
                  label="业务类型"
                  hint="请选择业务类型"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="isG500"
                  label="500强企业"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="!validOrder || !linkedProduct.length"
            @click="submitOrder('lease')"
            v-if="orderType === 'rent'"
          >
            创建后续租新订单
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!validOrder || !linkedProduct.length"
            @click="submitOrder()"
          >
            直接创建订单
          </v-btn>
          <v-btn color="secondary" depressed @click="$router.push('/dealer/workbench/order/list');"><v-icon left>done</v-icon>授信审批</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import store from "@/store";
import { mapGetters } from "vuex";
import {
  FETCH_PRODUCT_LIST,
  FETCH_UPLOAD,
  FETCH_URL,
  FETCH_PRODUCTTYPE_LIST,
  FETCH_VENDOR_LIST,
  CHECK_PRODUCT,
  FETCH_ENTERPRISE_LIST,
  PUBLISH_ORDER_PURCHASE,
  FETCH_ENTERPRISE,
  FETCH_ORDER_PURCHASE,
  INIT_ORDER_PURCHASE, FETCH_ACCOUNT_LIST,
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
import Util from "@/common/util";
export default {
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      validOrder: true,
      orderItem: {
        out_number: "",
        po_number: "",
        enterprise: "",
        branch: "",
        sales: "",
        payment_day: "",
        rent_type: "",
        lease_start: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        lease_term: "",
        payment_cycle: "",
        payment_period: "",
        total_period: 1,
        endDate: "",
        stakeholders: {
          investor: '', // 投资方名称
          agent: '', // 代理商名称
          recycler: '', // 指定回收商
          party: '', // 签约方
        },
      },
      salesList: [],
      isSalesLoading: false,
      searchSales: null,
      isEnterpriseLoading: false,
      searchEnterprise: null,
      enterpriseList: [],
      dlgEnterprise: false,
      validEnterprise: true,
      newEnterprise: {
        editedItem: {
          name: "",
          name_code: "",
          contact_name: "",
          contact_phone: "",
          address: "",
          addressInfo: "",
          msgAddress: "",
          chkAddress: false,
        },
        defaultItem: {
          name: "",
          name_code: "",
          contact_name: "",
          contact_phone: "",
          address: "",
          addressInfo: "",
          msgAddress: "",
          chkAddress: false,
        },
      },
      proHeaders: [
        { text: "产品型号", value: "part_number" },
        { text: "产品名称", value: "name" },
        { text: "产品描述", value: "product_text" },
        { text: "数量", value: "quantity" },
        { text: "单价", value: "price" },
        { text: "小计", value: "total" },
        { text: "移除", value: "actions" },
      ],
      productHeaders: [
        { text: "产品名称", value: "name" },
        { text: "产品型号", value: "part_number" },
        { text: "品牌", value: "vendor.name" },
        { text: "产品类型", value: "product_type.name" },
      ],
      linkedProduct: [],
      productList: [],
      query: {
        key: "",
      },
      proInfoDlg: false,
      activeId: "",
      total_quantity: 0,
      total_price: 0,
      editedItem: {
        files: [],
        remarks: [],
      },
      dlgRemarks: false,
      editedRemark: {
        title: "",
        context: "",
        private: false,
      },
      defaultRemarks: {
        title: "",
        context: "",
        private: false,
      },
      dlgAttach: false,
      editedAttach: {
        files: [],
        progressUpload: [],
        public: false
      },
      defaultAttach: {
        files: [],
        progressUpload: [],
        public: false
      },
      newProDlg: false,
      validNewPro: true,
      newProInfo: {
        name: "",
        quantity: "",
        sales_price: "",
        rent_price: "",
        vendor: "",
        part_number: "",
        product_type: "",
        product_text: "",
        guaranteed_type: [0],
        preinstall_software: [],
        provide_service: [],
        guaranteed_limit: 0,
        isABM: false,
      },
      defaultProInfo: {
        name: "",
        quantity: "",
        sales_price: "",
        rent_price: "",
        vendor: "",
        part_number: "",
        product_type: "",
        product_text: "",
        guaranteed_type: [0],
        preinstall_software: [],
        provide_service: [],
        guaranteed_limit: 0,
        isABM: false,
      },
      editMoreProduct: false,
      editedIndex: -1,
      dlgConflict: false,
      editInvoice: {
        title: "",
        license_code: "",
        invoice_type: 0,
        bank_name: "",
        bank_account: "",
        license_address: "",
        license_telephone: "",
        delivery_address: "",
        delivery_name: "",
        delivery_phone: "",
      },
      defaultInvoice: {
        title: "",
        license_code: "",
        invoice_type: 0,
        bank_name: "",
        bank_account: "",
        license_address: "",
        license_telephone: "",
        delivery_address: "",
        delivery_name: "",
        delivery_phone: "",
      },
      delivery_info: {
        name: "",
        phone: "",
        address: "",
      },
      defaultDelivery: {
        name: "",
        phone: "",
        address: "",
      },
      dlgEdit: false,
      dlgTitle: "",
      validEdit: true,
      oldEnterpriseId: "",
      orderData: {},
      proBox: [],
      isCheckProLoading: false,
      errPartNo: "",
      oldPartNo: "",
      partNoBox: [],
      checkItem: null,
      open: {},
      orderType: '',
      startMenu: false,
      parentId: '',
      dialogRiskTip: false,
      isG500: false,
      businessType: ''
    };
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_PRODUCTTYPE_LIST, "limit=-1&isEnable=true"),
      store.dispatch(FETCH_VENDOR_LIST, "limit=-1&isEnable=true"),
    ]).then(() => {
      next();
    });
  },
  mounted() {
    this.initOrderInfo();
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "enterprise",
      "productCount",
      "uploadSign",
      "vendorList",
      "typeList",
      "orderPurchaseInit",
    ]),
    rentType() {
      return Util.categories("rentType");
    },
    leaseTerm() {
      return Util.categories("leaseTerm");
    },
    paymentCycle() {
      return Util.categories("payment_cycle");
    },
    guaranteedType () {
      return Util.categories('guaranteedType')
    },
    preSoftware() {
      return Util.categories('preSoftware')
    },
    proService() {
      return Util.categories('proService')
    },
    PartyList() {
      return Util.categories('PartyList')
    },
    businessTypeList () {
      return Util.categories('RiskBusinessType')
    },
  },
  created() {},
  watch: {
    linkedProduct() {
      this.countMoney();
    },
    searchEnterprise(val) {
      if (this.countDown) {
        clearTimeout(this.countDown);
      }
      this.countDown = setTimeout(() => {
        if (this.isEnterpriseLoading) return;
        this.isEnterpriseLoading = true;
        this.orderItem.branch = '';
        store
          .dispatch(FETCH_ENTERPRISE_LIST, "&key=" + val)
          .then((data) => {
            this.enterpriseList = data.enterprise;
            this.isEnterpriseLoading = false;
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
            this.isEnterpriseLoading = false;
          });
      }, 500);
    },
    searchSales(val) {
      if (this.countDown) {
        clearTimeout(this.countDown);
      }
      this.countDown = setTimeout(() => {
        if (this.isSalesLoading) return;
        this.isSalesLoading = true;
        store
          .dispatch(FETCH_ACCOUNT_LIST, "&key=" + val + "&roles="+ this.orderPurchaseInit.sales_roles)
          .then((data) => {
            if (data) {
              const { employees } = data;
              this.salesList = employees;
            }
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
          })
          .finally(() => (this.isSalesLoading = false));
      }, 500);
    },
    checkItem(val) {
      this.errPartNo = "";
      if (val == "" || !val) {
        clearTimeout(this.countDownPro);
        return;
      } else {
        if (this.countDownPro) {
          clearTimeout(this.countDownPro);
        }
        this.countDownPro = setTimeout(() => {
          if (this.isCheckProLoading) return;
          this.isCheckProLoading = true;
          let type = ''
          this.orderType === 'purchase' ? type = 'order' : type = 'rent'
          store
            .dispatch(CHECK_PRODUCT, "selected="+ type +"&limit=200&isEnable=true&partno=" + val)
            .then((data) => {
              this.partNoBox = data.product;
              this.isCheckProLoading = false;
            })
            .catch((error) => {
              this.isCheckProLoading = false;
              store.commit(SET_ERROR, { msg: error });
            });
        }, 500);
      }
    },
    'editedAttach.progressUpload': {
      handler: function (val) {
        if (val.reduce((a, b) => a + b, 0) / val.length === 100) this.closeAttach()

      },
      deep: true
    }
  },
  methods: {
    setEndDate() {
      if (this.orderItem.lease_term) {
        if (this.orderItem.lease_start) {
          let date = new Date(this.orderItem.lease_start).getTime() - 1000*60*60*24
          let startDate = new Date(date)
          let year = startDate.getFullYear() + (this.orderItem.lease_term / 365)
          let endtDate = new Date(startDate.setFullYear(year))
          this.orderItem.endDate = this.$options.filters['formatDate'](endtDate)
        }
        if (this.orderItem.payment_cycle) {
          if (this.orderItem.payment_cycle === '0') {
            this.orderItem.total_period = this.orderItem.lease_term / 365 * 12
          } else if (this.orderItem.payment_cycle === '1') {
            this.orderItem.total_period = this.orderItem.lease_term / 365 * 4
          } else if (this.orderItem.payment_cycle === '2') {
            this.orderItem.total_period = this.orderItem.lease_term / 365 * 2
          } else {
            this.orderItem.total_period = this.orderItem.lease_term / 365
          }
          this.countMoney()
        }
      }
    },
    showText(i) {
      this.open[i]= !this.open[i]
      this.$forceUpdate()
    },
    changeBranch(val) {
      if(val) {
        this.defaultInvoice = {
          title : '',
          license_code : '',
          invoice_type : 0,
          bank_name : '',
          bank_account : '',
          license_address : '',
          license_telephone : '',
        }
      }else {
        this.editInvoice = Object.assign({}, this.defaultInvoice);
      }
    },
    changeProInfo() {
      let t = typeof this.newProInfo.part_number;
      if (t === "string") {
        return
      } else if (t === "object" && this.newProInfo.part_number) {
        let pro = this.newProInfo.part_number;
        this.newProInfo.name = pro.name;
        this.newProInfo.quantity = this.newProInfo.quantity || 1;
        if (pro.vendor) this.newProInfo.vendor = pro.vendor._id;
        if (pro.product_type) this.newProInfo.product_type = pro.product_type._id;
        if (pro.product_text) this.newProInfo.product_text = pro.product_text;
        this.newProInfo.part_number = pro.part_number;
      }
    },
    initOrderInfo() {
      this.orderType = this.$route.query.orderType
      if (this.$route.query.orderId && (this.$route.query.type === "copy" || this.$route.query.type === "lease")) {
        this.getOrder(this.$route.query.orderId);
        if (this.$route.query.type === "lease") this.parentId = this.$route.query.orderId
      }
      if (this.orderType === 'rent') this.proHeaders.splice(3, 0, { text: "期数", value: "total_period" })


      store.dispatch(INIT_ORDER_PURCHASE).then((data) => {
        let sales = data.sales_roles.join(",");
        store.dispatch(FETCH_ACCOUNT_LIST, "roles=" + sales).then((item) => {
          this.salesList = item.employees;
          if (this.currentUser.permission.includes('Order_Sale')) this.orderItem.sales = this.currentUser._id
        })
      })
    },
    getOrder(id) {
      store.dispatch(FETCH_ORDER_PURCHASE, { query: id }).then((data) => {
        this.orderData = data;
        this.orderItem.po_number = this.orderData.po_number;
        this.orderItem.out_number = this.orderData.out_number;
        // this.enterpriseList.push(this.orderData.enterprise);


        this.orderItem.sales = this.orderData.sales && this.orderData.sales._id;
        this.defaultInvoice = Object.assign({}, this.orderData.invoice_info);
        this.defaultDelivery = Object.assign({}, this.orderData.delivery_info);
        if (this.orderType === 'rent') {
          this.orderItem.payment_day = this.orderData.rent_info.payment_day
          this.orderItem.rent_type = this.orderData.rent_info.rent_type.toString()
          this.orderItem.lease_start = this.$options.filters['formatDate'](this.orderData.rent_info.lease_start)
          this.orderItem.lease_term = this.orderData.rent_info.lease_term
          this.orderItem.payment_cycle = this.orderData.rent_info.payment_cycle.toString()
          this.orderItem.payment_period = this.orderData.rent_info.payment_period
          this.orderItem.total_period = this.orderData.rent_info.total_period
          this.orderItem.expiry_date = this.$options.filters['formatDate'](this.orderData.rent_info.expiry_date)
          this.orderItem.stakeholders.agent = this.orderData.rent_info.stakeholders.agent
          this.orderItem.stakeholders.investor = this.orderData.rent_info.stakeholders.investor
          this.orderItem.stakeholders.party = this.orderData.rent_info.stakeholders.party
          this.orderItem.stakeholders.recycler = this.orderData.rent_info.stakeholders.recycler
          this.setEndDate()
        }
        if (this.isEnterpriseLoading) return;
        this.isEnterpriseLoading = true;
        store
          .dispatch(FETCH_ENTERPRISE_LIST, "&key=" + this.orderData.enterprise.name)
          .then((data) => {
            this.enterpriseList =data.enterprise;
            this.orderItem.enterprise = this.orderData.enterprise._id;
            this.orderItem.branch = this.orderData.branch;
            setTimeout(() => {
              this.isEnterpriseLoading = false;
            }, 1500);
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
            this.isEnterpriseLoading = false;
          });
        this.linkedProduct = this.orderData.product_list.map((item) => {
          let p = Object.assign({}, item);
          p.name = p.product_name;
          if (this.orderType === 'purchase') {
            p.sales_price = p.sales_price.$numberDecimal;
          }else {
            p.rent_price = p.rent_price.$numberDecimal;
          }
          p.vendor = p.product_vendor
          return p;
        });
      });
    },
    submitEditEnterprise() {
      if (this.dlgTitle === "发票信息") {
        this.dlgEdit = false;
        this.dlgTitle = "";
        this.defaultInvoice = Object.assign({}, this.editInvoice);
      } else if (this.dlgTitle === "收货信息") {
        this.dlgEdit = false;
        this.dlgTitle = "";
        this.defaultDelivery = Object.assign({}, this.delivery_info);
      }
    },
    editEnterprise(type) {
      if (type == "invoice") {
        this.dlgTitle = "发票信息";
        this.editInvoice = Object.assign({}, this.defaultInvoice);
      } else if (type == "delivery") {
        this.dlgTitle = "收货信息";
        this.delivery_info = Object.assign({}, this.defaultDelivery);
      }
      this.dlgEdit = true;
    },
    closeEditEnterprise() {
      this.dlgEdit = false;
      this.dlgTitle = "";
      this.editInvoice = Object.assign({}, this.defaultInvoice);
      this.delivery_info = Object.assign({}, this.defaultDelivery);
    },
    setInvoiceAndMail() {
      if (this.orderItem.enterprise) {
        this.getEnterprise(this.orderItem.enterprise);
      } else {
        // this.editInvoice = Object.assign({}, this.defaultInvoice);
        this.delivery_info = Object.assign({}, this.defaultDelivery);
      }
    },
    getEnterprise(id) {
      this.$store.dispatch(FETCH_ENTERPRISE, id).then((data) => {
        this.enterpriseInfo = data;
        if (this.enterpriseInfo.delivery_info) {
          if (id !== this.oldEnterpriseId) {
            this.oldEnterpriseId = id;
            this.defaultDelivery = Object.assign(
              {},
              this.enterpriseInfo.delivery_info
            );
          }
        } else {
          this.oldEnterpriseId = "";
          this.defaultDelivery = {
            name: "",
            phone: "",
            address: "",
          };
        }
      });
    },
    openCreateEnterprise() {
      this.$nextTick(() => {
        this.newEnterprise.editedItem = Object.assign(
          {},
          this.newEnterprise.defaultItem
        );
      });
      this.dlgEnterprise = true;
    },
    submitCreateEnterprise() {
      console.log("创建客户");
    },
    openProInfo(id) {
      this.activeId = id;
      this.proInfoDlg = true;
    },
    countMoney() {
      let rental_period = this.orderItem.total_period || 1;
      this.total_quantity = 0;
      this.total_price = 0;
      this.linkedProduct.forEach((item) => {
        this.total_quantity += Number(item.quantity);
        if (this.orderType === 'rent') {
          this.total_price += item.rent_price * item.quantity * rental_period;
        }else {
          this.total_price += item.sales_price * item.quantity;
        }
      });
    },
    createAttach() {
      this.dlgAttach = true
    },
    async addAttach() {
      if (this.editedAttach.files && this.editedAttach.files.length) {
        let filePublic = this.editedAttach.public
        store.dispatch(FETCH_UPLOAD, "private").then(() => {
          this.editedAttach.progressUpload = new Array(this.editedAttach.files.length).fill(0);
          Promise.all(this.editedAttach.files.map((file, index) => {
            this.uploadAttach(file, index).then((result) => {
              this.editedItem.files.push(Object.assign(result, {public: filePublic}));
            })
          }))
        })
      }
    },
    uploadAttach(file, index) {
      return new Promise((resolve, reject) => {
        let fileFullName = file.name,
          fileSuffix = fileFullName.substring(fileFullName.lastIndexOf(".") + 1);
        fileFullName = fileFullName.replace(/[%#&]/g,"");

        let param = new FormData();
        param.append("name", fileFullName);
        param.append("key", "order/" + this.uploadSign.key + "/" + fileFullName);
        param.append("policy", this.uploadSign.policy);
        param.append("OSSAccessKeyId", this.uploadSign.OSSAccessKeyId);
        param.append("success_action_status", 200);
        param.append("signature", this.uploadSign.signature);
        param.append("file", file, fileFullName);

        let config = {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            this.$set(this.editedAttach.progressUpload,index,Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            ))
          },
        };
        axios.post(this.uploadSign.host, param, config)
          .then(() => {
            resolve({
              suffix: fileSuffix,
              name: fileFullName,
              href: param.get("key")
            })
          })
          .catch((error) => {
            reject(error.message)
          });
      });
    },
    removeAttach(index) {
      this.editedItem.files.splice(index, 1);
    },
    downloadAttach(href) {
      store.dispatch(FETCH_URL, href)
        .then((url) => {
          window.location.href = url
        }).catch((err) => {
          store.commit(SET_ERROR, { msg: err })
        })
    },
    closeAttach() {
      this.dlgAttach = false
      this.$nextTick(() =>{
        this.editedAttach = Object.assign({}, this.defaultAttach)
      })
    },
    createRemarks() {
      this.dlgRemarks = true;
    },
    addRemarks(objRemarks) {
      objRemarks["account"] = this.currentUser._id;
      objRemarks["avatar"] = this.currentUser.personal.profile || "";
      objRemarks['createTime'] = new Date()
      this.editedItem.remarks.push(Object.assign({}, objRemarks));
      this.closeRemarks()
    },
    removeRemark(item) {
      this.editedItem.remarks.splice(this.editedItem.remarks.indexOf(item), 2);
    },
    closeRemarks() {
      this.dlgRemarks = false;
      this.$nextTick(() => {
        this.editedRemark = Object.assign({}, this.defaultRemarks);
      });
    },
    editProInfo(item) {
      if (item) {
        let type = ''
        this.orderType === 'purchase' ? type = 'order' : type = 'rent'
        store.dispatch(CHECK_PRODUCT, "selected="+ type +"&limit=200&isEnable=true&partno=" + item.part_number).then((data) => {

          this.partNoBox = data.product;
          this.isCheckProLoading = false;
          this.newProInfo = JSON.parse(JSON.stringify(item));
          this.editedIndex = this.linkedProduct.indexOf(item);
          this.oldPartNo = item.part_number;
        }).catch((error) => {
          this.isCheckProLoading = false;
          store.commit(SET_ERROR, { msg: error });
        });
      }
      this.newProDlg = true;
    },
    saveEditPro() {
      this.$refs.myBox.blur();
      this.$nextTick(() => {
        this.newProInfo.sales_price = Number(this.newProInfo.sales_price);
        if (this.editedIndex > -1) {
          let num = -1;
          this.linkedProduct.map((item) => {
            if (
              item.part_number &&
              item.part_number === this.newProInfo.part_number
            )
              num += 1;
          });
          if (num > -1) {
            if (this.oldPartNo === this.newProInfo.part_number) {
              Object.assign(
                this.linkedProduct[this.editedIndex],
                this.newProInfo
              );
              this.countMoney();
              this.closeEditPro();
            } else {
              this.errPartNo = "重新填写";
              store.commit(SET_ERROR, {
                msg: "列表中已包含此型号产品",
                color: "primary",
              });
            }
          } else {
            Object.assign(this.linkedProduct[this.editedIndex], this.newProInfo);
            this.countMoney();
            this.closeEditPro();
          }
        } else {
          let num = -1;
          this.linkedProduct.map((item) => {
            if (
              item.part_number &&
              item.part_number === this.newProInfo.part_number
            )
              num += 1;
          });
          if (num > -1) {
            this.errPartNo = "重新填写";
            store.commit(SET_ERROR, {
              msg: "产品列表中已包含此型号产品",
              color: "primary",
            });
          } else {
            this.linkedProduct.push(this.newProInfo);
            this.countMoney();
            this.closeEditPro();
          }
        }
      });
    },
    closeEditPro() {
      this.newProDlg = false;
      this.$nextTick(() => {
        this.newProInfo = JSON.parse(JSON.stringify(this.defaultProInfo));
        this.editedIndex = -1;
        this.oldPartNo = "";
        this.errPartNo = ''
      });
    },
    clearPartNo() {
      this.newProInfo.part_number = "";
      this.dlgConflict = false;
    },
    linkPresent() {
      let num = -1;
      this.linkedProduct.map((item) => {
        if (item.part_number === this.newProInfo.part_number) num += 1;
      });
      if (num > -1) {
        store.commit(SET_ERROR, {
          msg: "关联列表中已包含此产品",
          color: "primary",
        });
      } else {
        store
          .dispatch(
            FETCH_PRODUCT_LIST,
            "key=" + encodeURIComponent(this.newProInfo.part_number)
          )
          .then((data) => {
            let pro = data.product.map((item) => {
              let p = Object.assign({}, item);
              p.quantity = 0;
              p.sales_price = 0;
              return p;
            });
            this.linkedProduct.push(pro[0]);
            this.dlgConflict = false;
            this.closeEditPro();
          });
      }
    },
    removeLinked(pro) {
      let proIndex = this.linkedProduct.indexOf(pro);
      if (proIndex > -1) {
        this.linkedProduct.splice(proIndex, 1);
      }
    },
    submitLease() {
      store.commit(SET_ERROR, { msg: "订单创建成功，请继续完善续租信息", color: "primary" });
      let newDate = new Date(this.orderItem.endDate)
      newDate.setDate(newDate.getDate() + 1)
      this.orderItem.lease_start = this.$options.filters['formatDate'](newDate)
      this.setEndDate()
    },
    showRiskTip() {
      this.dialogRiskTip = true
    },
    submitOrder(type) {
      let orderInfo = this.orderItem;
      orderInfo.product_list = this.linkedProduct;
      this.orderType === 'rent' ? orderInfo.type = 1 : orderInfo.type = 0
      if(this.parentId) orderInfo.parentId = this.parentId
      let name = ''
      let isEmpty = orderInfo.product_list.some((item) => {
        name = item.name
        return item.vendor === '' || !item.vendor
      })

      if (isEmpty) {
        store.commit(SET_ERROR, { msg: '产品：' + name + '，【产品供应商】不能为空', color: 'primary'});
      }else {
        orderInfo.files = this.editedItem.files;
        orderInfo.remarks = this.editedItem.remarks;
        orderInfo.product_list.forEach((item) => {
          if (this.orderType === 'rent') {
            delete item.sales_price
          }else {
            delete item.rent_price
          }
          item.product_name = item.name;
          item.product_vendor = item.vendor;
        });
        orderInfo.invoice_info = this.defaultInvoice;
        orderInfo.delivery_info = this.defaultDelivery;
        orderInfo.expiry_date = orderInfo.endDate
        store.dispatch(PUBLISH_ORDER_PURCHASE, orderInfo)
          .then((data) => {
            if(type==='lease') {
              store.commit(SET_ERROR, { msg: "订单创建成功，请继续创建续租订单", color: "primary" });
              this.parentId = data._id
              let newDate = new Date(this.orderItem.endDate)
              newDate.setDate(newDate.getDate() + 1)
              this.orderItem.lease_start = this.$options.filters['formatDate'](newDate)
              this.setEndDate()
            }else {
              store.commit(SET_ERROR, { msg: "订单创建成功！", color: "primary" });
              this.$router.push("/dealer/workbench/order/list");
            }
          })
          .catch((error) => {
            store.commit(SET_ERROR, { msg: error.response.data.message });
          });
      }
    },
  },
};
</script>

<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
