<template>
  <v-container>
    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-toolbar-title>处理任务</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";
export default {
  data() {
    return {}
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  created() {
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
  watch: {},
  methods: {
    doAction(action, item = {}) {
      switch (action) {
        case 'test': {
          console.log(item)
          break
        }
        default: {

          break
        }
      }
    }
  },
}
</script>
