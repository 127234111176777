var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.processList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.processCount,
      "loading": _vm.processLoading,
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "loading-text": "数据读取中... 请稍后",
      "item-key": "_id",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("流程列表")]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer'), _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "depressed": "",
            "color": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('create_item', '', '创建新流程');
            }
          }
        }, [_c('v-icon', [_vm._v("add")]), _vm._v(" 创建流程 ")], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          class: item.isEnable ? '' : 'text-decoration-line-through'
        }, [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: "item.service_catalog",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.service_catalog ? _c('span', [_vm._v(_vm._s(item.service_catalog.name))]) : _vm._e()];
      }
    }, {
      key: "item.isRecovery",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(item.isRecovery ? '是' : '否'))])];
      }
    }, {
      key: "item.isOrder",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(item.isOrder ? '是' : '否'))])];
      }
    }, {
      key: "item.tasks_relation",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.tasks_relation ? _c('span', [_vm._v(_vm._s(_vm._f("stringModule")(item.tasks_relation)))]) : _vm._e()];
      }
    }, {
      key: "item.SLA_limit",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s(item.SLA_limit === 0 ? '不限' : item.SLA_limit))])];
      }
    }, {
      key: "item.act",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('edit_item', item, '编辑');
            }
          }
        }, [_vm._v("edit")]), !item.isEnable ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_item', item, true);
            }
          }
        }, [_vm._v("check_circle_outline")]) : _vm._e(), item.isEnable ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_item', item, false);
            }
          }
        }, [_vm._v("block")]) : _vm._e()];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("获取数据")])];
      },
      proxy: true
    }])
  }), _vm.dlgEdit ? _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "700px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "流程名称",
      "hint": "请输入任务名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.serviceList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "disabled": this.editedIndex !== -1,
      "label": "请选择流程所属服务"
    },
    on: {
      "change": function change($event) {
        return _vm.preCreateGroup(_vm.editedItem.service_catalog);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(data.item.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.group))])], 1)];
      }
    }], null, false, 1887009870),
    model: {
      value: _vm.editedItem.service_catalog,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "service_catalog", $$v);
      },
      expression: "editedItem.service_catalog"
    }
  })], 1), _vm.isRepair ? _c('v-col', {
    staticClass: "mt-n10",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "申请人确认维修",
      "value": "applicant"
    },
    model: {
      value: _vm.editedItem.repair_confirm,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "repair_confirm", $$v);
      },
      expression: "editedItem.repair_confirm"
    }
  })], 1) : _vm._e(), _vm.isRepair ? _c('v-col', {
    staticClass: "mt-n10",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "IT确认维修",
      "value": "admin"
    },
    model: {
      value: _vm.editedItem.repair_confirm,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "repair_confirm", $$v);
      },
      expression: "editedItem.repair_confirm"
    }
  })], 1) : _vm._e(), !_vm.isRepair ? _c('v-col', {
    staticClass: "mt-n10",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "\u66F4\u6362\u6D41\u7A0B: ".concat(_vm.editedItem.isRecovery ? '是' : '否')
    },
    model: {
      value: _vm.editedItem.isRecovery,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "isRecovery", $$v);
      },
      expression: "editedItem.isRecovery"
    }
  })], 1) : _vm._e(), !_vm.isRepair ? _c('v-col', {
    staticClass: "mt-n10",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "\u91C7\u8D2D\u6D41\u7A0B: ".concat(_vm.editedItem.isOrder ? '是' : '否')
    },
    model: {
      value: _vm.editedItem.isOrder,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "isOrder", $$v);
      },
      expression: "editedItem.isOrder"
    }
  })], 1) : _vm._e(), !_vm.isRepair ? _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-combobox', {
    attrs: {
      "label": "服务类型",
      "hint": "新建工单时供开单人选择的服务类型，多个逗号分割",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.process_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "process_type", $$v);
      },
      expression: "editedItem.process_type"
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.getDeptList(_vm.departments[0]),
      "rules": [_vm.rules.required, _vm.rules.selected],
      "item-text": "name",
      "item-value": "value",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择受理部门"
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_employee_list');
      }
    },
    model: {
      value: _vm.editedItem.department,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "department", $$v);
      },
      expression: "editedItem.department"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    ref: "selectAccount",
    attrs: {
      "items": _vm.accountList,
      "disabled": !_vm.selectAccount,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "multiple": "",
      "deletable-chips": "",
      "clearable": "",
      "label": "受理人员 空代表部门内人员都可受理",
      "hint": "请选择任务受理人员"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }], null, false, 59584339),
    model: {
      value: _vm.editedItem.account,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "account", $$v);
      },
      expression: "editedItem.account"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "suffix": "小时",
      "type": "number",
      "label": "受理环节SLA时效",
      "hint": "输入该流程在受理环节的处理时效，0代表不限制",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.accept_SLA_limit,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "accept_SLA_limit", _vm._n($$v));
      },
      expression: "editedItem.accept_SLA_limit"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "suffix": "小时",
      "type": "number",
      "label": "流程整体SLA时效",
      "hint": "输入该流程整体处理时效，0代表不限制",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.SLA_limit,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "SLA_limit", _vm._n($$v));
      },
      expression: "editedItem.SLA_limit"
    }
  })], 1), !_vm.isRepair ? _c('v-col', {
    staticClass: "mt-n12",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "\u65B0\u5EFA\u5DE5\u5355\u5FC5\u987B\u9009\u62E9\u7528\u6237: ".concat(_vm.editedItem.require_account ? '是' : '否')
    },
    model: {
      value: _vm.editedItem.require_account,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "require_account", $$v);
      },
      expression: "editedItem.require_account"
    }
  })], 1) : _vm._e(), !_vm.isRepair ? _c('v-col', {
    staticClass: "mt-n12",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "\u65B0\u5EFA\u5DE5\u5355\u5FC5\u987B\u9009\u62E9\u8BBE\u5907: ".concat(_vm.editedItem.require_asset ? '是' : '否')
    },
    model: {
      value: _vm.editedItem.require_asset,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "require_asset", $$v);
      },
      expression: "editedItem.require_asset"
    }
  })], 1) : _vm._e(), !_vm.isRepair ? _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.taskRelation,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择任务执行逻辑"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.text))])], 1)];
      }
    }], null, false, 821515329),
    model: {
      value: _vm.editedItem.tasks_relation,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "tasks_relation", $$v);
      },
      expression: "editedItem.tasks_relation"
    }
  })], 1) : _vm._e(), !_vm.isRepair ? _c('v-col', {
    staticClass: "mt-n10",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-subheader', [_vm._v("未关联任务清单")]), _c('v-virtual-scroll', {
    attrs: {
      "items": _vm.tasksList,
      "item-height": 50,
      "height": "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var index = _ref8.index,
            item = _ref8.item;
        return [_c('v-list-item', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(item.name)
          }
        }), item.action_roles === 'applicant' ? _c('v-list-item-subtitle', [_vm._v("申请人执行")]) : item.action_roles === 'enterprise' ? _c('v-list-item-subtitle', [_vm._v("申请人团队执行")]) : item.action_roles === 'service' ? _c('v-list-item-subtitle', [_vm._v("指定服务团队执行")]) : item.action_roles === 'assign' ? _c('v-list-item-subtitle', [_vm._v("申请人指定人员")]) : _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(item.department.name) + " "), _vm._l(item.account, function (account) {
          return _c('span', {
            key: account._id,
            attrs: {
              "account": account._id,
              "divider": "- "
            }
          }, [_vm._v(_vm._s(account.personal.name ? account.personal.name : '*未设置姓名'))]);
        })], 2)], 1), _c('v-list-item-action', [_c('v-btn', {
          attrs: {
            "depressed": "",
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('add_task', index);
            }
          }
        }, [_vm._v("关联任务"), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v("keyboard_arrow_right")])], 1)], 1)], 1), _c('v-divider')];
      }
    }], null, false, 1608167740)
  })], 1) : _vm._e(), !_vm.isRepair ? _c('v-col', {
    staticClass: "mt-n10",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-subheader', [_vm._v("已关联任务清单")]), _c('draggable', _vm._b({
    attrs: {
      "list": _vm.editedItem.tasks,
      "handle": ".drag"
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.editedItem.tasks, function (element, index) {
    return _c('v-list-item', {
      key: element.name
    }, [_c('v-list-item-icon', [_c('v-icon', {
      staticClass: "mt-2 drag",
      attrs: {
        "small": ""
      }
    }, [_vm._v("drag_indicator")])], 1), _c('v-list-item-content', [element.name ? _c('v-list-item-title', {
      domProps: {
        "innerHTML": _vm._s(element.name)
      }
    }) : _vm._e(), element.action_roles === 'applicant' ? _c('v-list-item-subtitle', [_vm._v("申请人执行")]) : element.action_roles === 'enterprise' ? _c('v-list-item-subtitle', [_vm._v("申请人团队执行")]) : element.action_roles === 'service' ? _c('v-list-item-subtitle', [_vm._v("指定服务团队执行")]) : element.action_roles === 'assign' ? _c('v-list-item-subtitle', [_vm._v("申请人指定人员")]) : element.department ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(element.department.name) + " "), _vm._l(element.account, function (account) {
      return _c('span', {
        key: account._id,
        attrs: {
          "account": account._id,
          "divider": "- "
        }
      }, [_vm._v(_vm._s(account.personal.name ? account.personal.name : '*未设置姓名'))]);
    })], 2) : _vm._e()], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "depressed": "",
        "small": "",
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.doAction('remove_task', index);
        }
      }
    }, [_c('v-icon', [_vm._v("remove")])], 1)], 1)], 1);
  }), 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }