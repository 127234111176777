var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("处理任务")]), _c('v-spacer')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }