<template>
  <div class="fluid">
    <v-data-table
      :headers="headerTable"
      :items="stateList"
      :options.sync="optionsTable"
      :server-items-length="stateCount"
      :loading="stateLoading"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      loading-text="数据读取中... 请稍后"
      item-key="name"
      transition
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>设备状态列表</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" outlined fab x-small @click="doAction('create_item', '', '新增设备状态')">
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <span :class="item.isEnable ? '' : 'text-decoration-line-through'">{{item.name}}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="doAction('edit_item', item, '编辑设备状态')" v-if="item.isEdit">edit</v-icon>
        <v-icon small @click="doAction('active_item', item, true)" v-if="!item.isEnable && item.isEdit">check_circle_outline</v-icon>
        <v-icon small @click="doAction('active_item', item, false)" v-if="item.isEnable && item.isEdit">block</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataList()">Reset</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dlgEdit" persistent max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[rules.required]"
                  type="text"
                  label="状态名称"
                  hint="请输入设备状态名称"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_ASSETSTATE_LIST,
  PUBLISH_ASSETSTATE,
  EDIT_ASSETSTATE,
  ACTIV_ASSETSTATE,
} from "@/store/actions.type";
export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedIndex: -1,
      editedItem: {
        _id: '',
        name: '',
      },
      defaultItem: {
        _id: '',
        name: '',
      },
      query: {
        key: '',
      },
      defaultQuery: {
        key: '',
      },
      headerTable: [
        {text: '名称', value: 'name'},
        {text: '操作', value: 'actions', align: 'end', sortable: false}
      ],
      optionsTable: {},
    };
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "stateLoading",
      "stateList",
      "stateCount"]),
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  mounted() {},
  methods: {
    getDataList(query = {}) {
      // 获取分页信息
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (query.key) urlQuery += '&key=' + query.key
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_ASSETSTATE_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    doAction(action, item, customitem) {
      switch (action) {
        case 'search_key': {
          if (this.query.key !== '') {
            this.getDataList({key: this.query.key})
          } else {
            this.getDataList()
          }
          break
        }
        case 'create_item': case 'edit_item': {
          this.editedIndex = this.stateList.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
        case 'active_item': {
          if (confirm(`确定要 ${customitem ? '启用' : '禁用'} ${item.name}？`)) {
            store.dispatch(ACTIV_ASSETSTATE, {_id: item._id, reply: customitem})
              .then(() => {
                store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
                this.getDataList()
                this.close()
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          }
          break
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    close () {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    submit() {
      let strInsert = {}
      strInsert.name = this.editedItem.name
      // console.log('insert data =>'+ JSON.stringify(strInsert))
      // return
      if (this.editedIndex !== -1) {
        strInsert._id = this.editedItem._id
        store.dispatch(EDIT_ASSETSTATE, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_ASSETSTATE, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },
  }
}
</script>
