<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>门店管理</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-title>
        门店管理
      </v-card-title>
      <v-card-subtitle>
        门店及驻场团队可以在设计流程时进行分派，门店设置完成后可以在人员管理和企业管理中分派服务门店。
      </v-card-subtitle>
      <v-row class="pa-4" justify="space-between">
        <v-col cols="5">
          <div v-if="branchLoading" class="article-preview">读取分支机构信息...</div>
          <v-list v-else dense class="mx-n4">
            <v-list-item-group v-model="activeBranch" color="success">
              <draggable v-model="orderList" v-bind="dragOptions">
                <v-list-item v-for="(item, i) in branchList" :key="i" @click="changeBranchList()">
                  <v-list-item-icon>
                    <v-icon style="cursor: move" class="mt-2" small>drag_indicator</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{item.name}} <v-icon small class="ml-2 mt-n1">{{item.isHidden ? 'visibility_off' : 'visibility'}}</v-icon>
                    </v-list-item-title>
                    <v-list-item-subtitle v-text="item.address">
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-if="item.typedef === 1">storefront</v-icon>
                    <v-icon v-if="item.typedef === 2">people_outline</v-icon>
                    <v-icon v-if="item.typedef === 3">corporate_fare</v-icon>
                    <v-icon v-if="item.typedef === 4">work_outline</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </draggable>
            </v-list-item-group>
          </v-list>
          <v-btn block outlined color="secondary" class="mt-2 ma-2" @click="doAction('create_store')">
            <v-icon left>add</v-icon>增加门店
          </v-btn>
        </v-col>
        <v-divider vertical class="ml-1"></v-divider>
        <v-col>
          <v-scroll-y-transition mode="out-in">
            <div v-if="activeBranch === undefined" class="title font-weight-light" style="align-self: center;">选择 或 增加一个门店</div>
            <v-card v-else-if="!Object.keys(editedItem).length" :key="activeBranch" outlined class="mx-auto" max-width="374">
              <v-img
                height="250"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                :src="branchList[activeBranch].profileImage ? ossURL +'/'+ branchList[activeBranch].profileImage : '/static/error/empty_state.svg'"
              ><v-card-title>{{branchList[activeBranch].name}}</v-card-title></v-img>
              <v-card-subtitle class="pb-0">{{branchList[activeBranch].isHidden ? '前台隐藏' : '前台可见'}}</v-card-subtitle>
              <v-card-text class="text--primary">
                <div>{{branchList[activeBranch].city}}</div>
                <div>{{branchList[activeBranch].address}}</div>
              </v-card-text>
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon>integration_instructions</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>{{branchList[activeBranch].center_code || '无服务中心代码'}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon>assignment_ind</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle v-if="branchList[activeBranch].managers">
                  <widgets-employee-dialogs :account="branchList[activeBranch].managers._id">{{branchList[activeBranch].managers.personal.name || '*未设置姓名'}}</widgets-employee-dialogs>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>未指定负责人</v-list-item-subtitle>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon>contact_phone</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>{{branchList[activeBranch].contactName}} - {{branchList[activeBranch].contactPhone}}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-if="branchList[activeBranch].support">
                <v-list-item-icon>
                  <v-icon>support</v-icon>
                </v-list-item-icon>
                <v-list-item-content v-if="branchList[activeBranch].support.length">
                  <v-chip-group
                    v-model="branchList[activeBranch].support"
                    column
                    multiple
                  >
                    <v-chip
                      v-for="vendor in branchList[activeBranch].support"
                      :key="vendor"
                      :value="vendor"
                      :ripple="false"
                      filter label disabled small
                    >
                      {{vendorList.find(v => v._id === vendor).name}}
                    </v-chip>
                  </v-chip-group>

                </v-list-item-content>
                <v-list-item-subtitle v-else>无</v-list-item-subtitle>
              </v-list-item>
              <v-list-item dense>
                <v-list-item-icon>
                  <v-icon>comment_bank</v-icon>
                </v-list-item-icon>
                <v-list-item-subtitle>{{branchList[activeBranch].remarks || '无备注信息'}}</v-list-item-subtitle>
              </v-list-item>
              <v-divider class="mx-4"></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeEdit()">关闭</v-btn>
                <v-btn color="secondary" text @click="doAction('edit_store', branchList[activeBranch])">编辑</v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-else outlined class="mx-auto">
              <v-toolbar dense flat>
                <v-toolbar-title>
                  {{editedItem._id ? '编辑分支机构' : '新建分支机构' }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form v-model="valid" @submit.prevent="submit">
                  <v-row>
                    <v-col cols="6">
                      <input
                        id="files"
                        type="file"
                        name="file"
                        ref="uploadInput"
                        accept="image/*"
                        :multiple="false"
                        @change="detectFiles($event)"/>
                      <v-progress-linear color="secondary" v-if="Upload.uploading" :value="Upload.progressUpload"></v-progress-linear>
                      <v-hover v-slot:default="{ hover }">
                        <v-img
                          :src="editedItem.profileImage ? ossURL +'/'+ editedItem.profileImage : '/static/error/empty_state.svg'"
                          class="white--text align-end"
                          dark
                          contain
                          aspect-ratio="1.6">
                          <v-expand-transition>
                            <div
                              v-if="hover"
                              class="d-flex transition-fast-in-fast-out  darken-2 v-card--reveal display-3 white--text"
                              style="height: 100%; cursor: pointer"
                              @click="doAction('upload_profile')"
                            >
                              <v-icon x-large color="primary">add_a_photo</v-icon>
                            </div>
                          </v-expand-transition>
                        </v-img>
                      </v-hover>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        type="text"
                        v-model="editedItem.name"
                        :rules="[rules.required]"
                        label="名称"
                        hint="请输入名称"
                        prepend-inner-icon="add_business"
                        outlined
                        dense
                      ></v-text-field>

                      <v-autocomplete
                        autocomplete="off"
                        v-model="editedItem.typedef"
                        :items="inputTypedef"
                        :rules="[rules.selected]"
                        label="类型"
                        hint="请选择分支机构类型"
                        prepend-inner-icon="category"
                        item-text="name"
                        item-value="code"
                        outlined
                        dense
                      ></v-autocomplete>

                      <v-text-field
                        type="text"
                        v-model="editedItem.center_code"
                        label="服务中心代码"
                        hint="与其它系统对接时该分支机构代码"
                        prepend-inner-icon="integration_instructions"
                        outlined
                        dense
                      ></v-text-field>

                      <v-switch v-model="editedItem.isHidden" inset :label="`前台隐藏该分支机构: ${editedItem.isHidden ? '是' : '否'}`"></v-switch>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        type="text"
                        v-model="editedItem.contactName"
                        :rules="[rules.required]"
                        label="联系人姓名"
                        hint="请输入联系人姓名"
                        prepend-inner-icon="folder_shared"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        type="text"
                        :rules="[rules.required]"
                        v-model="editedItem.contactPhone"
                        label="联系人电话"
                        hint="请输入联系人电话"
                        prepend-inner-icon="contact_phone"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="editedItem.managers"
                        :loading="accountLoading"
                        :items="accountList"
                        :search-input.sync="searchAccount"
                        item-text="personal.name"
                        item-value="_id"
                        autocomplete="off"
                        outlined
                        hide-no-data
                        dense
                        clearable
                        label="负责人"
                        hint="请查找并选择分支机构负责人"
                        prepend-inner-icon="how_to_reg"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        type="text"
                        v-model="editedItem.address"
                        :rules="[rules.required]"
                        :error-messages="check.chkAddressMessage"
                        :clearable="check.chkAddressMessage !== ''"
                        label="联系地址"
                        hint="请输入分支机构地址"
                        prepend-inner-icon="room"
                        outlined
                        dense
                      >
                        <template v-slot:append>
                          <v-fade-transition leave-absolute>
                            <v-progress-circular
                              v-if="check.chkAddressLoading"
                              width="2"
                              size="24"
                              color="secondary"
                              indeterminate
                            ></v-progress-circular>
                          </v-fade-transition>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <h2 class="subtitle-2 mt-2">服务范围</h2>
                      <v-chip-group v-model="editedItem.support" column multiple>
                        <v-chip v-for="vendor in vendorList" :key="vendor._id" :value="vendor._id" filter label outlined>{{vendor.name}}</v-chip>
                      </v-chip-group>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="editedItem.remarks"
                        label="备注"
                        hint="请输入分支机构备注信息"
                        prepend-inner-icon="comment_bank"
                        rows="2"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text v-if="editedItem._id" @click="closeEdit(activeBranch)">取消</v-btn>
                <v-btn text v-else @click="closeEdit()">取消</v-btn>
                <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
              </v-card-actions>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import Util from '@/common/util';
import _ from "lodash";
import axios from "axios";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_BRANCH_LIST,
  ORDER_BRANCH_LIST,
  FETCH_ACCOUNT_LIST,
  CHECK_ADDR,
  FETCH_UPLOAD,
  PUBLISH_BRANCH,
  EDIT_BRANCH,
  FETCH_VENDOR_LIST,
} from "@/store/actions.type";
import draggable from "vuedraggable";
import store from "@/store";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      activeBranch: undefined,
      editedItem: {},
      defaultItem: {
        name: '',
        typedef: 1,
        isHidden: false,
        managers: '',
        contactName: '',
        contactPhone: '',
        address: '',
        addressInfo: {},
        remarks: '',
        profileImage:'',
        center_code: '',
        support: [],
      },
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      searchAccount: null,
      valid: true,
      check: {
        chkAddressMessage: '',
        chkAddressLoading: false,
      },
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_VENDOR_LIST)
    ]).then(() => {
      next();
    });
  },
  created() {
  },
  mounted() {
    this.getBranchList();
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "uploadSign",
      "branchLoading",
      "vendorList",
      "branchList",
      'accountLoading',
      'accountList',]),
    dragOptions() {
      return {
        animation: 200,
        group: "branch",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    inputTypedef () {
      return Util.categories('deliver')
    },
    orderList: {
      get() {
        return this.$store.state.branch.branchList;
      },
      set(value) {
        this.activeBranch = undefined;
        this.$store.dispatch(ORDER_BRANCH_LIST, {'branch': value});
      }
    },
  },
  watch: {
    searchAccount (val) {
      val && val !== this.editedItem.managers && this.searchEmployee(val)
    },
    'editedItem.address'() {
      this.checkAddress();
    },
  },
  methods: {
    goBack() {
      this.closeEdit()
      this.$router.push({path: '/dealer/system'});
    },
    getBranchList() {
      this.$store.dispatch(FETCH_BRANCH_LIST);
    },
    changeBranchList() {
      this.closeEdit()
    },
    closeEdit (active = undefined) {
      this.activeBranch = active;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {})
      })
    },
    searchEmployee (key) {
      this.$store.dispatch(FETCH_ACCOUNT_LIST, '&key='+ key)
    },

    doAction(action, item) {
      switch (action) {
        case 'upload_profile': {
          this.$store.dispatch(FETCH_UPLOAD)
            .then(this.$refs.uploadInput.click())
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
          break;
        }
        case 'edit_store': {
          this.editedItem = Object.assign({}, item)
          if (item.managers && item.managers.personal) this.searchEmployee(item.managers.personal.name)
          break;
        }
        case 'create_store': {
          this.activeBranch = 'new'
          this.editedItem = Object.assign({}, this.defaultItem)
          break;
        }
      }
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.currentEmployer.ownerId._id +'/branch/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.editedItem.profileImage = this.currentEmployer.ownerId._id +'/branch/'+ this.uploadSign.key;
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    checkAddress: _.debounce(function () {
      if (this.editedItem && this.editedItem.address) {
        this.check.chkAddressLoading = true;
        this.$store.dispatch(CHECK_ADDR, this.editedItem.address)
          .then((data) => {
            if (data.status !== 0 || data.result.reliability < 7) {
              this.check.chkAddressMessage = '地址信息错误，请输入正确的地址信息';
            } else {
              this.editedItem.addressInfo = data.result;
              this.check.chkAddressMessage = '';
            }
            this.check.chkAddressLoading = false;
          })
          .catch((err) => {
            this.check.chkAddressMessage = err.data.message;
            this.check.chkAddressLoading = false;
          })
      }
    }, 500),
    submit() {
      if (this.activeBranch === 'new') {
        this.$store.dispatch(PUBLISH_BRANCH, this.editedItem)
          .then(() => {
            this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'})
            this.closeEdit()
            this.getBranchList()
            this.activeBranch = 0
          })
          .catch((error) => {
            this.$store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        let active = this.activeBranch
        if (!this.editedItem.managers) this.editedItem.managers = ''
        if (typeof this.editedItem.managers === "object") {
          this.editedItem.managers = this.editedItem.managers._id
        }

        if (!this.editedItem.profileImage) this.editedItem.profileImage = ''
        if (!this.editedItem.remarks) this.editedItem.remarks = ''
        this.$store.dispatch(EDIT_BRANCH, this.editedItem)
          .then(() => {
            this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'})
            this.closeEdit()
            this.getBranchList()
            this.activeBranch = active
          })
          .catch((error) => {
            this.$store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },

  }
}
</script>
<style scoped>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
</style>
