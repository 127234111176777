var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "secondary white--text headline"
  }, [_vm._v(" 后台管理首页 ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }