<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>服务提供</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-title>
        服务目录
      </v-card-title>
      <v-card-subtitle>
        服务目录是经销商为企业提供的所有服务介绍，服务目录最多为2级，企业用户可以查看服务介绍，并在经销商授权后使用该服务。本平台中所有流程也必须属于某一类服务，企业用户是否可以使用流程基于是否已经授权使用该服务。
      </v-card-subtitle>
      <v-row class="pa-4" justify="space-between">
        <v-col cols="5">
          <div v-if="catalogLoading" class="article-preview">读取服务目录信息...</div>
          <v-list v-else dense class="mx-n4">
            <v-list-item-group v-model="activeList">
              <draggable v-model="orderList" v-bind="dragOptions">
                <v-list-group v-for="(list, index) in catalogList" :key="list._id" color="secondary">
                  <template v-slot:activator :value="list._id">
                    <v-list-item-icon>
                      <v-icon style="cursor: move" class="mt-2" small>drag_indicator</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="list.name"></v-list-item-title>
                      <v-list-item-subtitle>{{list.children.length}}个服务</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <draggable :list="list.children" v-bind="dragOptions" @change="changeChildren(list.children)">
                    <v-list-item v-for="(item, i) in list.children" :key="item._id" link class="ml-12" :value="index+','+i">
                      <v-list-item-icon>
                        <v-icon style="cursor: move" small>drag_indicator</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{item.name}} <v-icon small class="ml-2 mt-n1">{{item.isHidden ? 'visibility_off' : 'visibility'}}</v-icon>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                </v-list-group>
              </draggable>
            </v-list-item-group>
          </v-list>
          <v-btn block outlined color="secondary" class="mt-2 ma-2" @click="doAction('create_catalog', 'parent')">
            <v-icon left>add</v-icon>增加服务分类
          </v-btn>
        </v-col>
        <v-divider vertical class="ml-1"></v-divider>
        <v-col>
          <v-scroll-y-transition mode="out-in">
            <div v-if="activeList === undefined && !Object.keys(editedItem).length" class="title font-weight-light" style="align-self: center;">
              选择 或 增加服务
            </div>
            <v-card v-else-if="Object.keys(editedItem).length" outlined class="mx-auto">
              <v-card-title>
                {{editedItem._id ? '编辑服务' : '新建服务' }}{{editedItem.parentId ? '' : '分类'}}
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" @submit.prevent="submit">
                  <v-row>
                    <v-col cols="4" v-if="editedItem.parentId">
                      <v-hover v-slot:default="{ hover }">
                        <v-avatar tile size="164">
                          <v-icon size="164">{{editedItem.profileIcon ? editedItem.profileIcon : 'add_circle_outline'}}</v-icon>
                          <v-expand-transition>
                            <div
                              v-if="hover"
                              class="d-flex transition-fast-in-fast-out  darken-2 v-card--reveal display-3 white--text"
                              style="height: 100%; cursor: pointer"
                              @click="doAction('change_icon')"
                            >
                              <v-icon x-large color="primary">edit</v-icon>
                            </div>
                          </v-expand-transition>
                        </v-avatar>
                      </v-hover>
                    </v-col>
                    <v-col cols="8">
                      <v-autocomplete
                        v-if="editedItem.parentId"
                        v-model="editedItem.parentId"
                        :items="catalogList.filter(item => !item.parentId)"
                        :rules="[rules.selected]"
                        item-text="name"
                        item-value="_id"
                        autocomplete="off"
                        outlined
                        dense
                        label="上级服务"
                        hint="请选择该服务的上级服务"
                        prepend-inner-icon="drive_folder_upload"
                      ></v-autocomplete>
                      <v-text-field
                        type="text"
                        v-model="editedItem.name"
                        :rules="[rules.required]"
                        label="名称"
                        hint="请输入名称"
                        prepend-inner-icon="post_add"
                        outlined
                        dense
                      ></v-text-field>
                      <v-autocomplete
                        v-if="!editedItem.parentId"
                        autocomplete="off"
                        v-model="editedItem.service_type"
                        :items="serviceType"
                        :rules="[rules.selected]"
                        label="服务类型"
                        hint="请选择服务类型"
                        prepend-inner-icon="category"
                        item-text="name"
                        item-value="code"
                        outlined
                        dense
                      ></v-autocomplete>
                      <v-autocomplete
                        v-if="editedItem.parentId"
                        v-model="editedItem.managers"
                        :loading="accountLoading"
                        :items="accountList"
                        :search-input.sync="searchAccount"
                        item-text="personal.name"
                        item-value="_id"
                        autocomplete="off"
                        hide-no-data
                        outlined
                        dense
                        clearable
                        label="负责人"
                        hint="请查找并选择服务负责人"
                        prepend-inner-icon="how_to_reg"
                      ></v-autocomplete>
                      <v-autocomplete
                        v-model="editedItem.material"
                        :items="materialList"
                        item-text="title"
                        item-value="_id"
                        autocomplete="off"
                        outlined
                        dense
                        label="关联素材"
                        hint="请选择该服务关联的素材详细介绍"
                        prepend-inner-icon="layers"
                      >
                        <template v-slot:item="data">
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.title"></v-list-item-title>
                            <v-list-item-subtitle>{{data.item.word_count}}字 {{data.item.updateTime | fromDate}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                      <v-switch v-if="editedItem.parentId" class="mt-n2" v-model="editedItem.isHidden" inset :label="`前台隐藏该服务: ${editedItem.isHidden ? '是' : '否'}`"></v-switch>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-if="editedItem.parentId"
                        v-model="editedItem.remarks"
                        label="备注"
                        hint="请输入服务备注信息"
                        prepend-inner-icon="comment_bank"
                        rows="2"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text v-if="editedItem._id" @click="closeEdit()">取消</v-btn>
                <v-btn text v-else @click="closeEdit()">取消</v-btn>
                <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-else outlined class="mx-auto" max-width="400">
              <v-card-title>
                <v-icon large left v-if="activeItem.parentId">
                  {{activeItem.profileIcon ? activeItem.profileIcon : 'volunteer_activism'}}
                </v-icon>
                <span class="title font-weight-light">{{activeItem.name}}</span>
              </v-card-title>
              <v-card-text v-if="activeItem.parentId" class=" font-weight-bold">
                <pre>{{activeItem.remarks || '无概述信息'}}</pre>
              </v-card-text>
              <v-list v-else dense>
                <v-list-item v-for="(item, index) in activeItem.children" :key="item._id" link @click="changeCatalogList(item, index)">
                  <v-list-item-icon>
                    <v-icon>{{item.profileIcon ? item.profileIcon : 'volunteer_activism'}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{item.name}} <v-icon small class="ml-2 mt-n1">{{item.isHidden ? 'visibility_off' : 'visibility'}}</v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>keyboard_arrow_right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-card-actions v-if="activeItem.parentId">
                <v-list-item class="grow">
                  <v-list-item-content>
                    <v-list-item-title v-if="activeItem.managers">
                      <widgets-employee-dialogs :account="activeItem.managers._id" :avatar="activeItem.managers.personal.profile">{{activeItem.managers.personal.name || '*未设置姓名'}}</widgets-employee-dialogs></v-list-item-title>
                    <v-list-item-title v-else>未指定负责人</v-list-item-title>
                  </v-list-item-content>
                  <v-row align="center" justify="end">
                    <v-icon class="mr-1" v-if="activeItem.parentId">{{activeItem.isHidden ? 'visibility_off' : 'visibility'}}</v-icon>
                    <span class="subheading mr-2" v-if="activeItem.parentId">{{activeItem.isHidden ? '前台隐藏' : '前台可见'}}</span>
                  </v-row>
                </v-list-item>
              </v-card-actions>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn v-if="!activeItem.parentId"  color="secondary" class="mt-2 ma-2" @click="doAction('create_catalog', 'service')">
                  <v-icon left>add</v-icon>增加服务
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="closeEdit()">关闭</v-btn>
                <v-btn text @click="doAction('delete_catalog', activeItem)">删除</v-btn>
                <v-btn color="secondary" text @click="doAction('edit_catalog', activeItem)">编辑</v-btn>
              </v-card-actions>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="iconDialog" scrollable fullscreen hide-overlay transition="dialog-bottom-transition">
      <widgets-icon-card @Select="doAction('select_icon', arguments)" @Close="iconDialog = false"></widgets-icon-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_CATALOG_LIST,
  ORDER_CATALOG_LIST,
  ORDER_CATALOG_CHILDREN,
  FETCH_ACCOUNT_LIST,
  FETCH_MATERIAL_LIST,
  PUBLISH_CATALOG,
  EDIT_CATALOG,
  DELETE_CATALOG
} from "@/store/actions.type";
import draggable from "vuedraggable";
import Util from "@/common/util";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      activeList: undefined,
      activeItem: {},
      editedItem: {},
      defaultItem: {
        name: '',
        isHidden: false,
        managers: '',
        remarks: '',
        profileIcon:'',
        parentId:'',
        material: '',
      },
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      searchAccount: null,
      valid: true,
      iconDialog: false
    }
  },
  created() {
  },
  mounted() {
    this.getCatalogList();
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "catalogLoading",
      "catalogList",
      "materialList",
      'accountLoading',
      'accountList',]),
    dragOptions() {
      return {
        animation: 200,
        group: "catalog",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    orderList: {
      get() {
        return this.$store.state.catalog.catalogList;
      },
      set(value) {
        this.$store.dispatch(ORDER_CATALOG_LIST, {'catalog': value});
        this.getCatalogList(0)
      }
    },
    serviceType () {
      return Util.categories('serviceType')
    },
  },
  watch: {
    searchAccount (val) {
      val && val !== this.editedItem.managers && this.searchEmployee(val)
    },
    activeList(activeValue) {
      this.editedItem = Object.assign({}, {})
      if (activeValue === undefined) {
        this.activeItem = Object.assign({}, {})
      } else {
        if (Number.isInteger(activeValue)) {
          this.activeItem = Object.assign({}, this.catalogList[activeValue])
        } else {
          let arrValue = activeValue.split(',')
          this.activeItem = Object.assign({}, this.catalogList[arrValue[0]].children[arrValue[1]])
        }
      }
    }
  },
  methods: {
    goBack() {
      this.closeEdit()
      this.$router.push({path: '/dealer/system'});
    },
    getCatalogList(active = undefined) {
      this.$store.dispatch(FETCH_MATERIAL_LIST)
      this.$store.dispatch(FETCH_CATALOG_LIST)
        .then(() => this.activeList = active)
    },
    changeCatalogList(item, index = 0) {
      this.activeList = this.activeList +','+ index
      this.editedItem = Object.assign({}, {})
      this.activeItem = Object.assign({}, item)

    },
    closeEdit (active = undefined) {
      this.activeItem = Object.assign({}, {})
      this.editedItem = Object.assign({}, {})
      this.activeList = active
    },
    searchEmployee (key) {
      this.$store.dispatch(FETCH_ACCOUNT_LIST, '&key='+ key)
    },
    changeChildren (list) {
      this.$store.dispatch(ORDER_CATALOG_CHILDREN, {'catalog': list});
    },
    doAction(action, item) {
      switch (action) {
        case 'edit_catalog': {
          this.editedItem = Object.assign({}, item)
          if (item.managers && item.managers.personal) this.searchEmployee(item.managers.personal.name)
          break;
        }
        case 'create_catalog': {
          if (item === 'parent') {
            this.editedItem = Object.assign({}, this.defaultItem)
          } else {
            this.editedItem = Object.assign({}, this.defaultItem)
            if (this.activeItem && this.activeItem._id && !this.activeItem.parentId) this.editedItem.parentId = this.activeItem._id
          }

          break;
        }
        case 'change_icon': {
          this.iconDialog = true
          break;
        }
        case 'select_icon': {
          this.editedItem.profileIcon = item[0]
          this.iconDialog = false
          break;
        }
        case 'delete_catalog': {
          if (item.children && item.children.length) return this.$store.commit(SET_ERROR, {msg: '删除失败：请删除下级内容后再次尝试删除该信息'});
          if (confirm('确定要删除 '+ item.name +'服务？')) {
            this.$store.dispatch(DELETE_CATALOG, item._id)
              .then(() => {
                this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'})
                if (item.parentId) {
                  this.getCatalogList(this.catalogList.findIndex(data => data._id === item.parentId))
                } else {
                  this.getCatalogList(0)
                }
              })
              .catch((error) => {
                this.$store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          }
        }
      }
    },
    submit() {
      if (!this.editedItem._id) {
        this.$store.dispatch(PUBLISH_CATALOG, this.editedItem)
          .then(({data}) => {
            this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'})
            this.closeEdit()
            if (data.parentId) {
              this.getCatalogList(this.catalogList.findIndex(item => item._id === data.parentId))
            } else {
              this.getCatalogList(0)
            }
          })
          .catch((error) => {
            this.$store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        let openItem = this.activeList || 0
        if (!this.editedItem.managers) this.editedItem.managers = ''
        if (typeof this.editedItem.managers === "object") {
          this.editedItem.managers = this.editedItem.managers._id
        }

        if (!this.editedItem.profileIcon) this.editedItem.profileIcon = ''
        if (!this.editedItem.remarks) this.editedItem.remarks = ''
        this.$store.dispatch(EDIT_CATALOG, this.editedItem)
          .then(() => {
            this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'})
            this.closeEdit()
            this.getCatalogList(openItem)
          })
          .catch((error) => {
            this.$store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },

  }
}
</script>
<style scoped>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
  pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
</style>
