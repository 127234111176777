var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-form', {
    model: {
      value: _vm.validOffer,
      callback: function callback($$v) {
        _vm.validOffer = $$v;
      },
      expression: "validOffer"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "secondary font-weight-regular"
  }, [_c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/dealer/workbench/rent/list');
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _vm._v(" 新建报价 ")], 1), _c('v-card-text', [_c('v-subheader', [_vm._v("基本信息")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "报价名称、说明",
      "hint": "新建报价单命名规则：客户名称加日期",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "prepend-icon": "content_paste"
    },
    model: {
      value: _vm.offerItem.title,
      callback: function callback($$v) {
        _vm.$set(_vm.offerItem, "title", $$v);
      },
      expression: "offerItem.title"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "客户",
      "autocomplete": "off",
      "items": _vm.enterpriseList,
      "item-text": "name",
      "item-value": "_id",
      "rules": [_vm.rules.required],
      "hide-no-data": "",
      "loading": _vm.isEnterpriseLoading,
      "search-input": _vm.searchEnterprise,
      "outlined": "",
      "dense": "",
      "prepend-icon": "business",
      "append-outer-icon": "add"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchEnterprise = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchEnterprise = $event;
      },
      "click:append-outer": _vm.openCreateEnterprise
    },
    model: {
      value: _vm.offerItem.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.offerItem, "enterprise", $$v);
      },
      expression: "offerItem.enterprise"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": Array.from({
        length: 28
      }, function (v, k) {
        return k + 1;
      }),
      "label": "客户付款日",
      "rules": [_vm.rules.selected],
      "outlined": "",
      "dense": "",
      "prepend-icon": "payment"
    },
    model: {
      value: _vm.offerItem.payment_day,
      callback: function callback($$v) {
        _vm.$set(_vm.offerItem, "payment_day", $$v);
      },
      expression: "offerItem.payment_day"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.rentType,
      "label": "租赁方式",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "published_with_changes"
    },
    model: {
      value: _vm.offerItem.rent_type,
      callback: function callback($$v) {
        _vm.$set(_vm.offerItem, "rent_type", $$v);
      },
      expression: "offerItem.rent_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "预计起租日期",
            "prepend-icon": "date_range",
            "readonly": "",
            "rules": [_vm.rules.required],
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.offerItem.lease_start,
            callback: function callback($$v) {
              _vm.$set(_vm.offerItem, "lease_start", $$v);
            },
            expression: "offerItem.lease_start"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.startMenu,
      callback: function callback($$v) {
        _vm.startMenu = $$v;
      },
      expression: "startMenu"
    }
  }, [_c('v-date-picker', {
    on: {
      "input": function input($event) {
        _vm.startMenu = false;
      }
    },
    model: {
      value: _vm.offerItem.lease_start,
      callback: function callback($$v) {
        _vm.$set(_vm.offerItem, "lease_start", $$v);
      },
      expression: "offerItem.lease_start"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "租期（年）",
      "outlined": "",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "dense": "",
      "items": _vm.leaseTerm
    },
    model: {
      value: _vm.offerItem.lease_term,
      callback: function callback($$v) {
        _vm.$set(_vm.offerItem, "lease_term", $$v);
      },
      expression: "offerItem.lease_term"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.paymentCycle,
      "label": "支付周期",
      "rules": [_vm.rules.selected],
      "outlined": "",
      "item-text": "name",
      "item-value": "code",
      "dense": ""
    },
    model: {
      value: _vm.offerItem.payment_cycle,
      callback: function callback($$v) {
        _vm.$set(_vm.offerItem, "payment_cycle", $$v);
      },
      expression: "offerItem.payment_cycle"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "预计客户账期（天）",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.offerItem.payment_period,
      callback: function callback($$v) {
        _vm.$set(_vm.offerItem, "payment_period", $$v);
      },
      expression: "offerItem.payment_period"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "search-input": _vm.searchSales,
      "label": "分配销售",
      "item-text": "personal.name",
      "rules": [_vm.rules.required],
      "item-value": "_id",
      "items": _vm.salesList,
      "outlined": "",
      "dense": "",
      "prepend-icon": "person_outline",
      "disabled": _vm.disSelectSales
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchSales = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchSales = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : "未设置名称"))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.offerItem.creator,
      callback: function callback($$v) {
        _vm.$set(_vm.offerItem, "creator", $$v);
      },
      expression: "offerItem.creator"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "search-input": _vm.searchAudit,
      "label": "选择核价助理",
      "item-text": "personal.name",
      "item-value": "_id",
      "rules": [_vm.rules.required],
      "items": _vm.auditList,
      "outlined": "",
      "dense": "",
      "prepend-icon": "support_agent"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAudit = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAudit = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : "未设置名称"))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.offerItem.reviewer,
      callback: function callback($$v) {
        _vm.$set(_vm.offerItem, "reviewer", $$v);
      },
      expression: "offerItem.reviewer"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "headers": _vm.linkedHeaders,
      "items": _vm.linkedProduct,
      "item-key": "index",
      "hide-default-footer": "",
      "no-data-text": "请关联或新增产品",
      "items-per-page": 100
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-subheader', [_vm._v("报价产品")]), !_vm.isTemplate ? _c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": "primary",
            "dark": "",
            "small": ""
          },
          on: {
            "click": _vm.openProductDlg
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("link")]), _vm._v(" 关联产品 ")], 1) : _vm._e(), !_vm.isTemplate ? _c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": "primary",
            "dark": "",
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editProInfo('');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("add")]), _vm._v(" 新增产品 ")], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', {
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.editProInfo(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("edit")]), _c('span', [_vm._v(_vm._s(item.product_code || item.name))])], 1)];
      }
    }, {
      key: "item.isWarranty",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-switch', {
          attrs: {
            "label": item.isWarranty ? '是' : '否',
            "color": "secondary"
          },
          model: {
            value: item.isWarranty,
            callback: function callback($$v) {
              _vm.$set(item, "isWarranty", $$v);
            },
            expression: "item.isWarranty"
          }
        })];
      }
    }, {
      key: "item.quantity",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.quantity) + " ")];
      }
    }, {
      key: "item.official",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.official_price).toFixed(2)))])];
      }
    }, {
      key: "item.purchase",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.purchase_price).toFixed(2)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref8) {
              var on = _ref8.on,
                  attrs = _ref8.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "secondary",
                  "dark": "",
                  "small": "",
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.removeLinked(item);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("remove")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("移除")])])];
      }
    }])
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "width": "30%",
      "loading": _vm.loading,
      "disabled": !_vm.validOffer || !_vm.linkedProduct.length,
      "large": ""
    },
    on: {
      "click": _vm.submitOffer
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-calculator")]), _vm._v(" 保存 ")], 1), _c('v-spacer')], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "700"
    },
    model: {
      value: _vm.proInfoDlg,
      callback: function callback($$v) {
        _vm.proInfoDlg = $$v;
      },
      expression: "proInfoDlg"
    }
  }, [_c('widgets-product-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        _vm.proInfoDlg = false;
      }
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.newDialog,
      callback: function callback($$v) {
        _vm.newDialog = $$v;
      },
      expression: "newDialog"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validNewPro,
      callback: function callback($$v) {
        _vm.validNewPro = $$v;
      },
      expression: "validNewPro"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._v("新增产品")]), _c('v-spacer')], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "mb-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "产品名称",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.newProInfo.name,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "name", $$v);
      },
      expression: "newProInfo.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "error-messages": _vm.errPartNo,
      "type": "text",
      "label": "产品型号",
      "loading": _vm.isCheckProLoading,
      "hint": "请输入产品型号 如：MGXC2CH/A",
      "outlined": "",
      "dense": ""
    },
    on: {
      "input": _vm.checkItem
    },
    model: {
      value: _vm.newProInfo.part_number,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "part_number", $$v);
      },
      expression: "newProInfo.part_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.vendorList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "return-object": "",
      "outlined": "",
      "dense": "",
      "label": "产品供应商",
      "hint": "请选择产品供应商"
    },
    model: {
      value: _vm.newProInfo.product_vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "product_vendor", $$v);
      },
      expression: "newProInfo.product_vendor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.typeList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "return-object": "",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "产品类型",
      "hint": "请选择产品类型"
    },
    model: {
      value: _vm.newProInfo.product_type,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "product_type", $$v);
      },
      expression: "newProInfo.product_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "数量",
      "rules": [_vm.rules.required],
      "type": "number",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.newProInfo.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "quantity", $$v);
      },
      expression: "newProInfo.quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "官网价格",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "prefix": "¥"
    },
    model: {
      value: _vm.newProInfo.official_price,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "official_price", $$v);
      },
      expression: "newProInfo.official_price"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "采购价",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "prefix": "¥"
    },
    model: {
      value: _vm.newProInfo.purchase_price,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "purchase_price", $$v);
      },
      expression: "newProInfo.purchase_price"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "1年余值比例",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.newProInfo.SV._1_year_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo.SV, "_1_year_ratio", $$v);
      },
      expression: "newProInfo.SV._1_year_ratio"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "2年余值比例",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.newProInfo.SV._2_year_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo.SV, "_2_year_ratio", $$v);
      },
      expression: "newProInfo.SV._2_year_ratio"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "3年余值比例",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.newProInfo.SV._3_year_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo.SV, "_3_year_ratio", $$v);
      },
      expression: "newProInfo.SV._3_year_ratio"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "justify": "center"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "color": "secondary"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "body-2"
        }, [_vm._v(_vm._s(_vm.newProInfo.isWarranty ? "是否延保：是" : "是否延保：否"))]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref9) {
              var on = _ref9.on,
                  attrs = _ref9.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "ml-6",
                attrs: {
                  "color": "primary",
                  "dark": "",
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" info ")])];
            }
          }])
        }, [_c('span', {
          staticClass: "caption"
        }, [_vm._v("请联系MDAAS产品负责人获取延保单价")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.newProInfo.isWarranty,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo, "isWarranty", $$v);
      },
      expression: "newProInfo.isWarranty"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "2年延保成本单价",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "prefix": "¥",
      "hide-details": ""
    },
    model: {
      value: _vm.newProInfo.warranty._2_year_inside,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo.warranty, "_2_year_inside", $$v);
      },
      expression: "newProInfo.warranty._2_year_inside"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "3年延保成本单价",
      "rules": [_vm.rules.required],
      "outlined": "",
      "dense": "",
      "prefix": "¥",
      "hide-details": ""
    },
    model: {
      value: _vm.newProInfo.warranty._3_year_inside,
      callback: function callback($$v) {
        _vm.$set(_vm.newProInfo.warranty, "_3_year_inside", $$v);
      },
      expression: "newProInfo.warranty._3_year_inside"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEditPro
    }
  }, [_vm._v(" 取消 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1",
      "text": "",
      "disabled": !_vm.validNewPro
    },
    on: {
      "click": _vm.saveEditPro
    }
  }, [_vm._v(" 保存 ")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgEnterprise,
      callback: function callback($$v) {
        _vm.dlgEnterprise = $$v;
      },
      expression: "dlgEnterprise"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitEmployee.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validEnterprise,
      callback: function callback($$v) {
        _vm.validEnterprise = $$v;
      },
      expression: "validEnterprise"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("创建新客户")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.dlgEnterprise = false;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "rules": [_vm.rules.required],
      "label": "客户名称",
      "hint": "请输入客户的名称，该名称为全局唯一命名"
    },
    model: {
      value: _vm.newEnterprise.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.newEnterprise.editedItem, "name", $$v);
      },
      expression: "newEnterprise.editedItem.name"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "rules": [_vm.rules.required],
      "label": "客户简称",
      "hint": "请输入客户的简称，该简称来自S1系统。"
    },
    model: {
      value: _vm.newEnterprise.editedItem.name_code,
      callback: function callback($$v) {
        _vm.$set(_vm.newEnterprise.editedItem, "name_code", $$v);
      },
      expression: "newEnterprise.editedItem.name_code"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "rules": [_vm.rules.required],
      "label": "联系人",
      "hint": "请输入客户的联系人，联系人不公开仅用于经销商与客户联系使用"
    },
    model: {
      value: _vm.newEnterprise.editedItem.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.newEnterprise.editedItem, "contact_name", $$v);
      },
      expression: "newEnterprise.editedItem.contact_name"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "rules": [_vm.rules.required],
      "label": "联系电话",
      "hint": "请输入企业的联系电话，联系电话不公开仅用于经销商与客户联系使用"
    },
    model: {
      value: _vm.newEnterprise.editedItem.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.newEnterprise.editedItem, "contact_phone", $$v);
      },
      expression: "newEnterprise.editedItem.contact_phone"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "error-messages": _vm.newEnterprise.editedItem.msgAddress,
      "clearable": _vm.newEnterprise.editedItem.msgAddress !== '',
      "outlined": "",
      "dense": "",
      "rules": [_vm.rules.required],
      "label": "详细地址",
      "hint": "请输入完整的地址，地址必须按正规格式输入"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-fade-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.newEnterprise.editedItem.chkAddress ? _c('v-progress-circular', {
          attrs: {
            "width": "2",
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.newEnterprise.editedItem.address,
      callback: function callback($$v) {
        _vm.$set(_vm.newEnterprise.editedItem, "address", $$v);
      },
      expression: "newEnterprise.editedItem.address"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dlgEnterprise = false;
      }
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.validEnterprise
    },
    on: {
      "click": _vm.submitCreateEnterprise
    }
  }, [_vm._v("创建")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "800px"
    },
    model: {
      value: _vm.productDialog,
      callback: function callback($$v) {
        _vm.productDialog = $$v;
      },
      expression: "productDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', [_vm._v("关联产品")]), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "label": "搜索产品",
      "outlined": "",
      "dense": "",
      "prepend-icon": "search",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchProduct.apply(null, arguments);
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-divider'), _c('v-card-text', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "headers": _vm.productHeaders,
      "items": _vm.productList,
      "options": _vm.optionsItem,
      "server-items-length": _vm.productCount,
      "item-key": "product_id",
      "show-select": "",
      "footer-props": {
        'items-per-page-options': [5, 10, 15]
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          staticClass: "text-truncate",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "nowrap": "true",
            "width": "220"
          },
          on: {
            "click": function click($event) {
              return _vm.openProInfo(item.product_id);
            }
          }
        }, [_vm._v(" " + _vm._s(item.product_code || item.name) + " ")])];
      }
    }, {
      key: "item.official",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.official_price).toFixed(2)))])];
      }
    }, {
      key: "item.purchase",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('span', [_vm._v("¥" + _vm._s(Number(item.purchase_price).toFixed(2)))])];
      }
    }]),
    model: {
      value: _vm.chooseProduct,
      callback: function callback($$v) {
        _vm.chooseProduct = $$v;
      },
      expression: "chooseProduct"
    }
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeChoose
    }
  }, [_vm._v(" 关闭 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1",
      "text": "",
      "disabled": !_vm.chooseProduct.length
    },
    on: {
      "click": _vm.linkChoose
    }
  }, [_vm._v(" 确定 ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }