<template>
  <v-container>
    <v-form v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-toolbar class="secondary" dark flat>
          <v-btn icon @click="goBack()">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title v-if="process_info">{{enterprise.name}}：{{currentVendor && currentVendor.name}}{{ process_info.service_catalog.name }}工单</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="submit('')" :disabled="!valid">创建</v-btn>
        </v-toolbar>
        <v-card-text v-if="process_info">
          <v-row>
            <v-col cols="12">
              <v-tabs>
                <v-tab>关联设备</v-tab>
                <v-tab>附件</v-tab>
                <v-tab>备注</v-tab>
                <v-tab-item v-if="enterprise && enterprise._id && vendorList && vendorList.length">
                  <case-create-select-asset ref="select_asset" :selectItem="editedItem.link_asset" :enterprise="enterprise._id" :vendor="vendorList.map((vendor) => vendor._id).join()" @RepairVendor="setVendor"></case-create-select-asset>
                </v-tab-item>
                <v-tab-item v-if="enterprise && enterprise._id">
                  <case-create-upload-files ref="upload_files" :enterprise="enterprise._id"></case-create-upload-files>
                </v-tab-item>
                <v-tab-item>
                  <case-create-edit-remarks ref="edit_remarks"></case-create-edit-remarks>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
          <v-row v-if="process_info && process_info.service_catalog.name === Global.REPAIR_CATALOG_TEXT">
            <v-col cols="6">
              <v-subheader>申报信息</v-subheader>
              <v-divider class="mb-4"></v-divider>
              <v-autocomplete
                v-if="currentVendor && currentVendor.name &&  !currentVendor.name.toLowerCase().includes('apple')"
                autocomplete="off"
                v-model="editedItem.out_req_type"
                :items="repairType"
                :rules="[rules.required]"
                label="服务类型"
                placeholder="维修服务类型"
                item-text="name"
                item-value="code"
                outlined
                dense
              ></v-autocomplete>
              <v-autocomplete
                v-if="editedItem.out_req_type === '三包服务'"
                autocomplete="off"
                v-model="editedItem.return_policy"
                :items="repairPolicy"
                :rules="[rules.required]"
                label="三包服务"
                placeholder="退换货策略"
                item-text="name"
                item-value="code"
                outlined
                dense
              ></v-autocomplete>
              <v-text-field
                v-model="editedItem.returns.contact_phone"
                :rules="[rules.required]"
                type="text"
                label="联系电话"
                placeholder="请输入联系电话号码"
                outlined
                dense
              >
              </v-text-field>
              <v-autocomplete
                v-if="recoveryType"
                autocomplete="off"
                v-model="editedItem.recovery.channel"
                :items="recoveryType"
                :rules="[rules.required]"
                label="送修方式"
                placeholder="请选择设备送修方式"
                item-text="name"
                item-value="code"
                outlined
                dense
              ></v-autocomplete>
              <v-autocomplete
                v-if="branchList && currentVendor && currentVendor._id"
                v-model="editedItem.service_branch"
                :items="branchList.filter(branch => branch.support.includes(currentVendor._id.toString()))"
                :rules="[rules.required]"
                return-object
                item-text="name"
                item-value="_id"
                autocomplete="off"
                outlined
                dense
                clearable
                label="服务门店"
                placeholder="请选择一家服务门店"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.name ? data.item.name : '未设置名称'}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.city}} - {{data.item.address}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <case-create-select-account v-if="enterprise && enterprise._id" :enterprise="enterprise" @Selected="reloadAccount"></case-create-select-account>
              <v-textarea
                v-model="editedItem.detail_fault"
                :rules="[rules.required]"
                label="故障描述"
                placeholder="请输入设备故障描述：如发生时间、原因、现象等，详细的故障描述有助于加速问题解决的过程。"
                rows="5"
                outlined
                dense
              ></v-textarea>
              <v-subheader>寄回信息</v-subheader>
              <v-divider class="mb-4"></v-divider>
              <v-autocomplete
                autocomplete="off"
                v-model="editedItem.returns.channel"
                :items="returnType"
                :rules="[rules.required]"
                label="寄回方式"
                placeholder="服务完成或被取消后将按此方式退回设备"
                item-text="name"
                item-value="code"
                outlined
                dense
              ></v-autocomplete>
              <v-text-field
                v-if="editedItem.returns.channel === 'express'"
                v-model="editedItem.returns.contact_name"
                :rules="[rules.required]"
                :append-icon="editedItem.account ? 'content_copy' : ''"
                @click:append="copyAccount"
                type="text"
                label="联系人"
                placeholder="请输入联系人姓名"
                outlined
                dense
              >
              </v-text-field>
              <v-text-field
                v-if="editedItem.returns.channel === 'express'"
                v-model="editedItem.returns.contact_phone"
                :rules="[rules.required]"
                type="text"
                label="联系电话"
                placeholder="请输入联系电话号码"
                outlined
                dense
              >
              </v-text-field>
              <v-text-field
                v-if="editedItem.returns.channel === 'express'"
                v-model="editedItem.returns.express_address"
                :rules="[rules.required]"
                type="text"
                label="寄回地址"
                placeholder="请输入地址信息"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-subheader>门店信息</v-subheader>
              <v-divider class="mb-4"></v-divider>
              <case-create-label-branch class="mt-2" v-if="editedItem.service_branch" :branch="editedItem.service_branch"></case-create-label-branch>
              <v-subheader>用户信息</v-subheader>
              <v-divider class="mb-4"></v-divider>
              <case-create-label-account v-if="editedItem.account" :account="editedItem.account" @Update="reloadAccount"></case-create-label-account>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <v-textarea
                v-model="editedItem.detail_fault"
                :rules="[rules.required]"
                label="故障描述"
                placeholder="请输入设备故障描述：如发生时间、原因、现象等，详细的故障描述有助于加速问题解决的过程。"
                rows="5"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="submit('')" :disabled="!valid">提交</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog v-model="dlgSubmit" persistent scrollable max-width="500px">
      <v-card>
        <v-card-title class="headline">
          确认提交新的服务请求
          <v-spacer></v-spacer>
          <v-btn icon @click="closeSubmit"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                服务请求提交后将通知经销商进行受理，处理过程和完成将会通过短信、邮件方式进行通知。如果关联了多台设备，系统将按设备创建多个服务请求。
              </v-col>
            </v-row>
            <v-row v-if="createResult.length">
              <v-col cols="12">
                <v-alert outlined type="success" text>
                  <v-row align="center" no-gutters>
                    <v-col cols="12">
                      成功创建：{{createResult.length}} 个服务请求；服务请求编号为：
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="grow">
                      <p class="font-weight-black" v-for="code in createResult" :key="code">{{ code }}</p>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="!createResult.length">
          <v-btn text @click="closeSubmit">取消</v-btn>
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="$refs.select_asset && $refs.select_asset.selectedAsset.length > 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="secondary" :loading="!submitEnd" v-bind="attrs" v-on="on" @click="submit('each')"><v-icon left>file_copy</v-icon>提交</v-btn>
            </template>
            <span>按所选设备创建多个服务请求</span>
          </v-tooltip>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="secondary" :loading="!submitEnd" v-bind="attrs" v-on="on" @click="submit('all')"><v-icon left>note_add</v-icon>提交</v-btn>
            </template>
            <span>创建新的请求</span>
          </v-tooltip>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn text @click="goBack">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import store from "@/store";
import {
  FETCH_BRANCH_LIST,
  FETCH_ENTERPRISE, FETCH_VENDOR_LIST,
  INIT_CASE, PUBLISH_CASE
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {SET_ERROR} from "@/store/mutations.type";
import Util from "@/common/util";

export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      query: {
        enterprise: '',
        service: '',
        asset: ''
      },
      defaultQuery: {
        enterprise: '',
        service: '',
        asset: '',
      },
      editedItem: {
        recovery: {
          channel: '',
          express_address: ''
        },
        returns: {
          channel: '',
          express_address: '',
          contact_name: '',
          contact_phone: ''
        },
        service_branch: '',
        account: null,
        link_asset:[],
        files: [],
        remarks: [],
        detail_fault: '',
        endSend: false,
        out_req_type: '',
        return_policy: ''
      },
      dlgSubmit: false,
      createResult: [],
      submitEnd: true,
      currentVendor: {},
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_BRANCH_LIST, 'typedef=1&isHidden=false'),
    ]).then(() => {
      next();
    });
  },
  created() {

  },
  mounted() {
    if (Object.keys(this.$route.query).length > 0) this.query = Object.assign(this.query, this.$route.query)
    this.initiateCase()
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "enterprise",
      "process_info",
      "branchList",
      "vendorList",
    ]),
    recoveryType () {
      return Util.categories('recoveryType')
    },
    returnType () {
      return Util.categories('returnType')
    },
    repairType () {
      return Util.categories('repairType')
    },
    repairPolicy () {
      return Util.categories('repairPolicy')
    },
  },
  watch: {},
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    initiateCase() {
      if (!Object.keys(this.$route.query).length) {
        store.commit(SET_ERROR, {msg: '参数错误'})
        this.goBack()
      } else {
        Promise.all([
          store.dispatch(INIT_CASE, {form: 'repair', query: 'enterprise='+this.$route.query.enterprise+'&service='+ this.$route.query.service}),
          store.dispatch(FETCH_ENTERPRISE, this.$route.query.enterprise),
          store.dispatch(FETCH_VENDOR_LIST, 'limit=-1&isEnable=true&key=Apple,HUAWEI,'+ encodeURIComponent('华为')),
          this.queryAsset()
        ]).catch((err) => {
          this.goBack()
          console.log('init case error =>'+ err)
        })
      }
    },
    reloadAccount(data) {
      this.editedItem.account = data
    },
    queryAsset(){
      return new Promise(() => {
        if (this.query.asset) this.editedItem.link_asset = this.query.asset.split(',')
      })
    },
    closeSubmit() {
      this.dlgSubmit = false
    },
    copyAccount() {
      this.editedItem.returns.contact_name = this.editedItem.account.personal.name
      this.editedItem.returns.contact_phone = this.editedItem.account.phone
      this.editedItem.returns.express_address = this.editedItem.account.personal.address
    },
    submit(byType) {
      let account = '', service_branch = '', detail_fault = '', out_req_type = '', return_policy = '';
      let selectedAsset = [], uploadFiles = [], editedRemarks = [];
      let recovery = {}, returns = {};
      if (this.process_info.service_catalog.name === this.Global.REPAIR_CATALOG_TEXT) {
        account = this.editedItem.account._id;
        service_branch = this.editedItem.service_branch._id;
        recovery =  {
          channel: this.editedItem.recovery.channel
        };
        returns = {
          channel: this.editedItem.returns.channel,
          express_address: this.editedItem.returns.express_address,
          contact_name: this.editedItem.returns.contact_name,
          contact_phone: this.editedItem.returns.contact_phone
        };
        if (this.currentVendor) {
          let vendorName = this.currentVendor.name
          if (!vendorName.includes('Apple') && !vendorName.includes('APPLE') && !vendorName.includes('苹果')) {
            out_req_type = this.editedItem.out_req_type
            return_policy = this.editedItem.return_policy
          }
        }
      } else {
        account = this.currentUser._id
      }
      detail_fault = this.editedItem.detail_fault;
      if (this.$refs.select_asset) selectedAsset = this.$refs.select_asset.selectedAsset.map(item => item._id)
      if (this.$refs.upload_files) uploadFiles = this.$refs.upload_files.filesList
      if (this.$refs.edit_remarks) editedRemarks = this.$refs.edit_remarks.remarks
      if (selectedAsset && selectedAsset.length) {
        if (!byType) {
          this.dlgSubmit = true
        } else {
          let strInsert = {
            byType: byType,
            enterprise: this.$route.query.enterprise,
            service_catalog: this.process_info.service_catalog._id,
            process: this.process_info._id,
            account,
            recovery,
            returns,
            service_branch,
            detail_fault,
            link_asset: selectedAsset,
            files: uploadFiles,
            remarks: editedRemarks,
            out_req_type, return_policy
          }
          store.dispatch(PUBLISH_CASE, {form: 'repair', data: strInsert})
            .then(({caseList}) => {
              store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
              this.createResult = caseList.map(item => item.case_number)
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
        }
      } else {
        store.commit(SET_ERROR, {msg: '请选择或增加一台需要维修的设备'});
      }
    },
    setVendor(data) {
      if (data) {
        this.currentVendor = this.vendorList.find(item => item._id === data)
      } else {
        this.currentVendor = {}
      }
    }
  }
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
