var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "fluid"
  }, [_c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "mx-4 my-2",
    attrs: {
      "depressed": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.createItem();
      }
    }
  }, [_vm._v("创建报价模版")])], 1), _c('v-row', {
    staticClass: "fill-height overflow-y-auto"
  }, [_vm._l(_vm.itemList, function (item) {
    return _c('v-col', {
      key: item._id,
      attrs: {
        "lg": "3",
        "md": "3",
        "sm": "4",
        "cols": "12"
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var hover = _ref.hover;
          return [_c('v-card', {
            attrs: {
              "outlined": "",
              "hover": "",
              "ripple": false
            }
          }, [_c('v-img', {
            staticClass: "white--text align-end repeating-gradient",
            attrs: {
              "src": item.cover_image ? _vm.ossURL + '/' + item.cover_image : '/static/error/empty_state.svg',
              "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
              "height": "200px"
            }
          }, [_c('v-card-title', {
            domProps: {
              "textContent": _vm._s(item.title)
            }
          }), _c('v-app-bar', {
            attrs: {
              "absolute": "",
              "dark": hover,
              "color": "transparent",
              "flat": "",
              "dense": ""
            }
          }, [item.isEnable ? _c('v-icon', {
            attrs: {
              "small": "",
              "color": "green"
            }
          }, [_vm._v("info")]) : _c('v-icon', {
            attrs: {
              "small": "",
              "color": "error"
            }
          }, [_vm._v("info")]), _c('v-spacer'), _c('v-btn', {
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.editItem(item);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("edit")])], 1), !item.isEnable ? _c('v-btn', {
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.setEnable(item._id, true);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("check_circle_outline")])], 1) : _c('v-btn', {
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.setEnable(item._id, false);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("block")])], 1), !item.isTop ? _c('v-btn', {
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.setTop(item._id, true);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("vertical_align_top")])], 1) : _c('v-btn', {
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.setTop(item._id, false);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "small": ""
            }
          }, [_vm._v("vertical_align_bottom")])], 1)], 1)], 1)], 1)];
        }
      }], null, true)
    })], 1);
  }), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [this.query.page * this.query.limit < _vm.rentTemplateCount ? _c('v-progress-circular', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.infiniteScrolling,
        options: {
          threshold: [1.0]
        }
      },
      expression: "{\n          handler: infiniteScrolling,\n          options: {\n            threshold: [1.0],\n          },\n        }"
    }],
    attrs: {
      "indeterminate": "",
      "color": "secondary",
      "width": "2"
    }
  }) : _c('span', {
    staticClass: "title grey--text font-weight-light"
  }, [_vm._v("已展示" + _vm._s(_vm.rentTemplateCount) + "个报价模版，没有更多数据了。")])], 1)], 2), _c('v-fab-transition', [_c('v-btn', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }, {
      name: "show",
      rawName: "v-show",
      value: _vm.fab,
      expression: "fab"
    }],
    staticClass: "transition-swing",
    staticStyle: {
      "z-index": "6"
    },
    attrs: {
      "color": "secondary",
      "fab": "",
      "dark": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "large": ""
    },
    on: {
      "click": _vm.toTop
    }
  }, [_c('v-icon', [_vm._v("keyboard_arrow_up")])], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "700px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitItem.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeItem
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-img', {
    attrs: {
      "src": _vm.editedItem.cover_image ? _vm.ossURL + '/' + _vm.editedItem.cover_image : '/static/error/empty_state.svg',
      "dark": "",
      "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
      "height": "200px"
    }
  }, [_c('v-row', {
    staticClass: "lightbox white--text pa-2 fill-height",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v(" " + _vm._s(_vm.editedItem.title) + " "), _c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.uploadImage();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add_photo_alternate")]), _vm._v("上传素材封面")], 1)], 1)])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "报价名称、说明",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editedItem.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "title", $$v);
      },
      expression: "editedItem.title"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.rentParameterList,
      "item-text": "name",
      "item-value": "_id",
      "label": "默认参数",
      "rules": [_vm.rules.selected],
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editedItem.rent_parameter,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "rent_parameter", $$v);
      },
      expression: "editedItem.rent_parameter"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.productList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "选择产品",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [[_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "input-value": data.attrs.inputValue
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        })], 1)]];
      }
    }]),
    model: {
      value: _vm.editedItem.product_list,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "product_list", $$v);
      },
      expression: "editedItem.product_list"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeItem
    }
  }, [_vm._v("关闭")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submitItem
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }