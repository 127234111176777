<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>知识目录</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-title>
        知识目录
      </v-card-title>
      <v-card-subtitle>
        知识目录是经销商为企业提供的支持服务和知识库的服务目录，目录最多为2级，企业用户可以按目录查阅知识和发出咨询请求。知识目录一级需要配置审核部门和编辑部门，审核部门成员将可以对该目录内的知识进行审核发布，编辑部门可以编写知识和回复该服务目录的咨询请求。
      </v-card-subtitle>
      <v-row class="pa-4" justify="space-between">
        <v-col cols="5">
          <div v-if="KBCatalogLoading" class="article-preview">读取目录信息...</div>
          <v-list v-else dense class="mx-n4">
            <v-list-item-group v-model="activeList">
              <draggable v-model="orderList" v-bind="dragOptions">
                <v-list-group v-for="(list, index) in KBCatalogList" :key="list._id" color="secondary">
                  <template v-slot:activator :value="list._id">
                    <v-list-item-icon>
                      <v-icon style="cursor: move" class="mt-2" small>drag_indicator</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="list.name"></v-list-item-title>
                      <v-list-item-subtitle>{{list.children.length}}个目录</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <draggable :list="list.children" v-bind="dragOptions" @change="changeChildren(list.children)">
                    <v-list-item v-for="(item, i) in list.children" :key="item._id" link class="ml-12" :value="index+','+i">
                      <v-list-item-icon>
                        <v-icon style="cursor: move" small>drag_indicator</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{item.name}} <v-icon small class="ml-2 mt-n1">{{item.isHidden ? 'visibility_off' : 'visibility'}}</v-icon>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                </v-list-group>
              </draggable>
            </v-list-item-group>
          </v-list>
          <v-btn block outlined color="secondary" class="mt-2 ma-2" @click="doAction('create_catalog', 'parent')">
            <v-icon left>add</v-icon>增加知识分组
          </v-btn>
        </v-col>
        <v-divider vertical class="ml-1"></v-divider>
        <v-col>
          <v-scroll-y-transition mode="out-in">
            <div v-if="activeList === undefined && !Object.keys(editedItem).length" class="title font-weight-light" style="align-self: center;">
              选择 或 增加目录
            </div>
            <v-card v-else-if="Object.keys(editedItem).length" outlined class="mx-auto">
              <v-card-title>
                {{editedItem._id ? '编辑知识' : '新建知识' }}{{editedItem.parentId ? '目录' : '分组'}}
              </v-card-title>
              <v-card-text>
                <v-form v-model="valid" @submit.prevent="submit">
                  <v-row>
                    <v-col cols="6" v-if="!editedItem.parentId">
                      <input
                        id="files"
                        type="file"
                        name="file"
                        ref="uploadInput"
                        accept="image/*"
                        :multiple="false"
                        @change="detectFiles($event)"/>
                      <v-progress-linear color="secondary" v-if="Upload.uploading" :value="Upload.progressUpload"></v-progress-linear>
                      <v-hover v-slot:default="{ hover }">
                        <v-img
                          :src="editedItem.profileImage ? ossURL +'/'+ editedItem.profileImage : '/static/error/empty_state.svg'"
                          class="white--text align-end"
                          dark
                          contain
                          aspect-ratio="1.6">
                          <v-expand-transition>
                            <div
                              v-if="hover"
                              class="d-flex transition-fast-in-fast-out  darken-2 v-card--reveal display-3 white--text"
                              style="height: 100%; cursor: pointer"
                              @click="doAction('upload_profile')"
                            >
                              <v-icon x-large color="primary">add_a_photo</v-icon>
                            </div>
                          </v-expand-transition>
                        </v-img>
                      </v-hover>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-if="editedItem.parentId"
                        v-model="editedItem.parentId"
                        :items="KBCatalogList.filter(item => !item.parentId)"
                        :rules="[rules.selected]"
                        item-text="name"
                        item-value="_id"
                        autocomplete="off"
                        outlined
                        dense
                        label="上级服务"
                        hint="请选择该服务的上级服务"
                        prepend-inner-icon="drive_folder_upload"
                      ></v-autocomplete>
                      <v-text-field
                        type="text"
                        v-model="editedItem.name"
                        :rules="[rules.required]"
                        label="名称"
                        hint="请输入名称"
                        prepend-inner-icon="post_add"
                        outlined
                        dense
                      ></v-text-field>
                      <v-autocomplete
                        v-if="!editedItem.parentId"
                        v-model="editedItem.editor"
                        :items="getDepartmentList(departments[0])"
                        :rules="[rules.required, rules.selected]"
                        @change="doAction('set_managers')"
                        return-object
                        item-text="name"
                        item-value="value"
                        autocomplete="off"
                        outlined
                        dense
                        prepend-inner-icon="drive_file_rename_outline"
                        label="请选择编辑部门"
                      ></v-autocomplete>
                      <v-alert
                        v-if="editedItem.editor"
                        dense
                        icon="check_circle_outline"
                        type="success"
                      >
                        审核人：
                        <widgets-employee-dialogs v-for="manager in editedItem.managers" :key="manager._id" :account="manager._id" divider="- ">
                          {{manager.personal.name ? manager.personal.name : '*未设置姓名'}}
                        </widgets-employee-dialogs>
                      </v-alert>
                      <v-switch class="mt-1" v-model="editedItem.isHidden" :label="`前台隐藏该服务: ${editedItem.isHidden ? '是' : '否'}`"></v-switch>
                      <v-switch class="mt-4" v-model="editedItem.isCreateCase" :label="`前台可以开单咨询: ${editedItem.isCreateCase ? '是' : '否'}`"></v-switch>
                    </v-col>
                    <v-col cols="6">
                      </v-col>
                    <v-col cols="12" v-if="editedItem.parentId">
                      <v-textarea
                        v-model="editedItem.remarks"
                        label="备注"
                        hint="请输入知识目录说明介绍"
                        prepend-inner-icon="comment_bank"
                        rows="3"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text v-if="editedItem._id" @click="closeEdit()">取消</v-btn>
                <v-btn text v-else @click="closeEdit()">取消</v-btn>
                <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-else outlined class="mx-auto" max-width="400">
              <v-img
                v-if="!activeItem.parentId"
                height="250"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                :src="activeItem.profileImage ? ossURL +'/'+ activeItem.profileImage : '/static/error/empty_state.svg'"
              >
                <v-card-title>{{activeItem.name}}</v-card-title>
              </v-img>
              <v-card-text class="text--primary" v-if="!activeItem.parentId">
                <div>部门：{{activeItem.editor.name}}</div>
                <div>审核人：
                  <widgets-employee-dialogs v-for="manager in activeItem.managers" :key="manager._id" :account="manager._id" divider="- ">
                    {{manager.personal.name ? manager.personal.name : '*未设置姓名'}}
                  </widgets-employee-dialogs></div>
              </v-card-text>
              <v-card-title v-if="activeItem.parentId">{{activeItem.name}}</v-card-title>
              <v-card-text v-if="activeItem.parentId" class=" font-weight-bold">
                <pre>{{activeItem.remarks || '无概述信息'}}</pre>
              </v-card-text>
              <v-list v-else dense>
                <v-list-item v-for="(item, index) in activeItem.children" :key="item._id" link @click="changeCatalogList(item, index)">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{item.name}} <v-icon small class="ml-2 mt-n1">{{item.isHidden ? 'visibility_off' : 'visibility'}}</v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>keyboard_arrow_right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn v-if="!activeItem.parentId"  color="secondary" class="mt-2 ma-2" @click="doAction('create_catalog', 'service')">
                  <v-icon left>add</v-icon>增加目录
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="closeEdit()">关闭</v-btn>
                <v-btn text @click="doAction('delete_catalog', activeItem)">删除</v-btn>
                <v-btn color="secondary" text @click="doAction('edit_catalog', activeItem)">编辑</v-btn>
              </v-card-actions>
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_KNOWLEDGE_CATALOG_LIST,
  ORDER_KNOWLEDGE_CATALOG_LIST,
  ORDER_KNOWLEDGE_CATALOG_CHILDREN,
  PUBLISH_KNOWLEDGE_CATALOG,
  EDIT_KNOWLEDGE_CATALOG,
  FETCH_DEPT_LIST,
  FETCH_EMPLOYEE, FETCH_UPLOAD,
  DELETE_KNOWLEDGE_CATALOG
} from "@/store/actions.type";
import draggable from "vuedraggable";
import axios from "axios";
import store from "@/store";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      activeList: undefined,
      activeItem: {
        editor: {},
        managers: [],
      },
      editedItem: {},
      defaultItem: {
        name: '',
        isHidden: false,
        isCreateCase: true,
        managers: [],
        editor: '',
        remarks: '',
        profileIcon:'',
        profileImage:'',
        parentId:'',
      },
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      valid: true,
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_DEPT_LIST),
    ]).then(() => {
      next();
    });
  },
  created() {
  },
  mounted() {
    this.getCatalogList();
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "KBCatalogLoading",
      "KBCatalogList",
      "uploadSign",
      "departments"]),
    dragOptions() {
      return {
        animation: 200,
        group: "catalog",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    orderList: {
      get() {
        return this.$store.state.knowledge_catalog.KBCatalogList;
      },
      set(value) {
        this.$store.dispatch(ORDER_KNOWLEDGE_CATALOG_LIST, {'catalog': value});
        this.getCatalogList(0)
      }
    },
  },
  watch: {
    activeList(activeValue) {
      this.editedItem = Object.assign({}, {})
      if (activeValue === undefined) {
        this.activeItem = Object.assign({}, {})
      } else {
        if (Number.isInteger(activeValue)) {
          let managers = this.KBCatalogList[activeValue].editor.managers
          this.activeItem = Object.assign({}, this.KBCatalogList[activeValue])
          if (managers && managers.length) {
            Promise.all(managers.map(item => this.$store.dispatch(FETCH_EMPLOYEE, item)
            )).then((data) => {
              this.activeItem.managers = data
              this.$forceUpdate()
            })
          }
        } else {
          let arrValue = activeValue.split(',')
          this.activeItem = Object.assign({}, this.KBCatalogList[arrValue[0]].children[arrValue[1]])
        }
      }
    }
  },
  methods: {
    goBack() {
      this.closeEdit()
      this.$router.push({path: '/dealer/system'});
    },
    getCatalogList(active = undefined) {
      this.$store.dispatch(FETCH_KNOWLEDGE_CATALOG_LIST)
        .then(() => this.activeList = active)
    },
    changeCatalogList(item, index = 0) {
      this.activeList = this.activeList + ',' + index
      this.editedItem = Object.assign({}, {})
      this.activeItem = Object.assign({}, item)

    },
    closeEdit(active = undefined) {
      this.activeItem = Object.assign({}, {})
      this.editedItem = Object.assign({}, {})
      this.activeList = active
    },
    changeChildren(list) {
      this.$store.dispatch(ORDER_KNOWLEDGE_CATALOG_CHILDREN, {'catalog': list});
    },
    doAction(action, item) {
      switch (action) {
        case 'set_managers': {
          this.editedItem.managers = []
          if (this.editedItem.editor && this.editedItem.editor.managers && this.editedItem.editor.managers.length) {
            let managers = this.editedItem.editor.managers
            Promise.all(managers.map(item => this.$store.dispatch(FETCH_EMPLOYEE, item)
            )).then((data) => {
              this.editedItem.managers = data
              this.$forceUpdate()
            })
          }
          break
        }
        case 'upload_profile': {
          this.$store.dispatch(FETCH_UPLOAD)
            .then(this.$refs.uploadInput.click())
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
          break;
        }
        case 'edit_catalog': {
          this.editedItem = Object.assign({}, item)
          if (this.editedItem.editor && typeof this.editedItem.editor === "object") {
            this.editedItem.editor = this.editedItem.editor._id
          }
          break;
        }
        case 'create_catalog': {
          if (item === 'parent') {
            this.editedItem = Object.assign({}, this.defaultItem)
          } else {
            this.editedItem = Object.assign({}, this.defaultItem)
            if (this.activeItem && this.activeItem._id && !this.activeItem.parentId) this.editedItem.parentId = this.activeItem._id
          }

          break;
        }
        case 'change_icon': {
          this.iconDialog = true
          break;
        }
        case 'select_icon': {
          this.editedItem.profileIcon = item[0]
          this.iconDialog = false
          break;
        }
        case 'delete_catalog': {
          if (item.children && item.children.length) return store.commit(SET_ERROR, {msg: '删除失败：请删除下级内容后再次尝试删除该信息'});
          if (confirm('确定要删除 '+ item.name +'目录？')) {
            store.dispatch(DELETE_KNOWLEDGE_CATALOG, item._id)
              .then(({data}) => {
                store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'})
                if (data.parentId) {
                  this.getCatalogList(this.KBCatalogList.findIndex(item => item._id === data.parentId))
                } else {
                  this.getCatalogList(0)
                }
                // if (item.parentId) {
                //   this.getCatalogList(this.catalogList.findIndex(data => data._id === item.parentId))
                // } else {
                //   this.getCatalogList(0)
                // }
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          }
        }
      }
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.currentEmployer.ownerId._id +'/knowledge/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.editedItem.profileImage = this.currentEmployer.ownerId._id +'/knowledge/'+ this.uploadSign.key;
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    submit() {
      if (!this.editedItem._id) {
        let strInsert = {}
        strInsert.name = this.editedItem.name
        strInsert.isHidden = this.editedItem.isHidden;
        strInsert.isCreateCase = this.editedItem.isCreateCase;
        strInsert.profileImage = this.editedItem.profileImage;
        strInsert.remarks = this.editedItem.remarks;
        if (this.editedItem.parentId) strInsert.parentId = this.editedItem.parentId;
        if (this.editedItem.editor) {
          if (typeof this.editedItem.editor === "object") {
            strInsert.editor = this.editedItem.editor.value
          } else {
            strInsert.editor = this.editedItem.editor
          }
        }
        this.$store.dispatch(PUBLISH_KNOWLEDGE_CATALOG, strInsert)
          .then(({data}) => {
            this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'})
            this.closeEdit()
            if (data.parentId) {
              this.getCatalogList(this.KBCatalogList.findIndex(item => item._id === data.parentId))
            } else {
              this.getCatalogList(0)
            }
          })
          .catch((error) => {
            this.$store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        let openItem = this.activeList || 0

        let strInsert = {}
        strInsert._id = this.editedItem._id
        strInsert.name = this.editedItem.name
        strInsert.isHidden = this.editedItem.isHidden;
        strInsert.isCreateCase = this.editedItem.isCreateCase;
        strInsert.profileImage = this.editedItem.profileImage;
        if (this.editedItem.remarks) strInsert.remarks = this.editedItem.remarks;
        if (this.editedItem.parentId) {
          if (typeof this.editedItem.parentId === "object") {
            strInsert.parentId = this.editedItem.parentId._id
          } else {
            strInsert.parentId = this.editedItem.parentId
          }
        }
        if (this.editedItem.editor) {
          if (typeof this.editedItem.editor === "object") {
            strInsert.editor = this.editedItem.editor.value
          } else {
            strInsert.editor = this.editedItem.editor
          }
        }

        this.$store.dispatch(EDIT_KNOWLEDGE_CATALOG, strInsert)
          .then(() => {
            this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'})
            this.closeEdit()
            this.getCatalogList(openItem)
          })
          .catch((error) => {
            this.$store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
    getDepartmentList(objDept, upLevelName = '') {
      let arrResult = [];
      arrResult.push({ name: upLevelName + objDept.name, value: objDept._id, managers: objDept.managers })
      if (objDept.children && objDept.children.length) {
        objDept.children.forEach(item => {
          arrResult.push(this.getDepartmentList(item, upLevelName + objDept.name +' >>'))
        })
      }
      return arrResult.flat()
    },
    setManagers(objDept = {}) {
      let arrManagers = []
      if (objDept.managers && objDept.managers.length) {
        objDept.managers.forEach(item => {
          arrManagers.push(this.$store.dispatch(FETCH_EMPLOYEE, item))
        })
      }
      return arrManagers
    },
  }
}
</script>
<style scoped>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
  pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
</style>
