<template>
  <v-container>
    hi~
  </v-container>
</template>
<script>

export default {
  data() {
    return {
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([

    ]).then(() => {
      next();
    });
  },
  created() {
    this.getList()
  },
  mounted() {},
  computed: {
  },
  watch: {
  },
  methods: {
  },
}
</script>
<style scoped>
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
