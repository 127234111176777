var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.riskEconomyList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.riskEconomyCount,
      "loading": _vm.riskEconomyLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "fixed-header": "",
      "height": "600",
      "item-key": "_id",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("经济数据列表")]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-text-field', {
          attrs: {
            "label": "关键字搜索（按回车）",
            "prepend-inner-icon": "search",
            "background-color": _vm.$vuetify.theme.dark ? '' : 'grey lighten-4',
            "solo": "",
            "flat": "",
            "dense": "",
            "hide-details": "",
            "clearable": ""
          },
          on: {
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.searchItem.apply(null, arguments);
            },
            "click:clear": _vm.clearSearchItem
          },
          model: {
            value: _vm.query.key,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "key", $$v);
            },
            expression: "query.key"
          }
        }), _c('v-spacer'), _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "ma-2",
                attrs: {
                  "depressed": "",
                  "color": "secondary"
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("add")]), _vm._v(" 创建经济数据 ")], 1)];
            }
          }])
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.editItem();
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("add_box")])], 1), _c('v-list-item-title', [_vm._v("通过表单增加单个经济数据")])], 1), _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.importItem();
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("library_add")])], 1), _c('v-list-item-title', [_vm._v("通过文件批量增加经济数据")])], 1)], 1)], 1), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Asset_Manage') ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "loading": !_vm.exportEnd
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.exportItem.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("save_alt")])], 1)];
            }
          }], null, false, 3898156763)
        }, [_c('span', [_vm._v("导出数据")])]) : _vm._e(), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.openFilter.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.city",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.type ? '国内三线' : item.city))])];
      }
    }, {
      key: "item.GDPGrowthRate",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(parseFloat(item.GDPGrowthRate * 100).toFixed(2)) + "%")])];
      }
    }, {
      key: "item.landDependence",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(parseFloat(item.landDependence * 100).toFixed(2)) + "%")])];
      }
    }, {
      key: "item.budgetRevenueGrowthRate",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(parseFloat(item.budgetRevenueGrowthRate * 100).toFixed(2)) + "%")])];
      }
    }, {
      key: "item.debtBudgetRatio",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(parseFloat(item.debtBudgetRatio * 100).toFixed(2)) + "%")])];
      }
    }, {
      key: "item.selfSufficiencyRate",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(parseFloat(item.selfSufficiencyRate * 100).toFixed(2)) + "%")])];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(item);
            }
          }
        }, [_vm._v("edit")]), _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_vm._v("delete")])], 1)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("刷新")])];
      },
      proxy: true
    }])
  }), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("年度")]), _c('v-autocomplete', {
    staticClass: "mt-2",
    attrs: {
      "items": _vm.yearList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "年度",
      "hint": "请选择年度"
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.year,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "year", $$v);
      },
      expression: "query.year"
    }
  }), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("区域")]), _c('v-autocomplete', {
    staticClass: "mt-2",
    attrs: {
      "items": _vm.cityList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "区域",
      "hint": "请选择区域",
      "chips": "",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.city,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "city", $$v);
      },
      expression: "query.city"
    }
  })], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "650px",
      "scrollable": ""
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.yearList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "年度",
      "hint": "请选择年度"
    },
    model: {
      value: _vm.editedItem.year,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "year", $$v);
      },
      expression: "editedItem.year"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.cityList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "地区",
      "hint": "请选择地区"
    },
    model: {
      value: _vm.editedItem.city,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "city", $$v);
      },
      expression: "editedItem.city"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "GDP总值",
      "hint": "请输入GDP总值",
      "prefix": "¥",
      "suffix": "亿元",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.GDPTotal,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "GDPTotal", $$v);
      },
      expression: "editedItem.GDPTotal"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "GDP增长率",
      "suffix": "%",
      "hint": "请输入GDP增长率",
      "outlined": ""
    },
    on: {
      "change": function change($event) {
        return _vm.checkItem();
      }
    },
    model: {
      value: _vm.editedItem.GDPGrowthRate,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "GDPGrowthRate", $$v);
      },
      expression: "editedItem.GDPGrowthRate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "当地人均GDP",
      "hint": "请输入当地人均GDP",
      "prefix": "¥",
      "suffix": "元",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.GDPPerCapita,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "GDPPerCapita", $$v);
      },
      expression: "editedItem.GDPPerCapita"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "土地依赖",
      "suffix": "%",
      "hint": "请输入土地依赖",
      "outlined": ""
    },
    on: {
      "change": function change($event) {
        return _vm.checkItem();
      }
    },
    model: {
      value: _vm.editedItem.landDependence,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "landDependence", $$v);
      },
      expression: "editedItem.landDependence"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "一般预算收入",
      "hint": "请输入一般预算收入",
      "prefix": "¥",
      "suffix": "亿元",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.budgetRevenue,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "budgetRevenue", $$v);
      },
      expression: "editedItem.budgetRevenue"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "一般预算收入增长率",
      "suffix": "%",
      "hint": "请输入一般预算收入增长率",
      "outlined": ""
    },
    on: {
      "change": function change($event) {
        return _vm.checkItem();
      }
    },
    model: {
      value: _vm.editedItem.budgetRevenueGrowthRate,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "budgetRevenueGrowthRate", $$v);
      },
      expression: "editedItem.budgetRevenueGrowthRate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "负债率",
      "suffix": "%",
      "hint": "请输入地方政府债务余额/一般公共预算收入",
      "outlined": ""
    },
    on: {
      "change": function change($event) {
        return _vm.checkItem();
      }
    },
    model: {
      value: _vm.editedItem.debtBudgetRatio,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "debtBudgetRatio", $$v);
      },
      expression: "editedItem.debtBudgetRatio"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "自给率",
      "suffix": "%",
      "hint": "请输入财政自给率",
      "outlined": ""
    },
    on: {
      "change": function change($event) {
        return _vm.checkItem();
      }
    },
    model: {
      value: _vm.editedItem.selfSufficiencyRate,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "selfSufficiencyRate", $$v);
      },
      expression: "editedItem.selfSufficiencyRate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "舆情分值",
      "hint": "请输入舆情分值",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.publicOpinion,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "publicOpinion", $$v);
      },
      expression: "editedItem.publicOpinion"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "备注",
      "rows": "2",
      "hint": "请输入备注",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "remarks", $$v);
      },
      expression: "editedItem.remarks"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "700"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'import' ? _c('v-card', [_c('v-card-title', [_vm._v(" 批量导入 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeItem
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载设备模版，填写产品信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.downloadTemplate();
      }
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importFile($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.uploadImportFile();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1), _vm.importEnd ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _c('ul', _vm._l(_vm.importFailed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功导入：" + _vm._s(_vm.importSuccess) + " 条记录 ")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeItem
    }
  }, [_vm._v("关闭")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }