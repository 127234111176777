<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>租赁管理</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-title>
        提示
      </v-card-title>
      <v-card-subtitle>租赁管理中可以维护报价单默认参数、租赁专属产品、报价单模版等信息。</v-card-subtitle>
      <v-tabs v-model="activeTab" color="secondary" grow icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route">
          {{ tab.name }}
          <v-icon>{{tab.icon}}</v-icon>
        </v-tab>
        <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route" :transition="false" :reverse-transition="false">
          <router-view></router-view>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      activeTab: '/dealer/system/rent/parameter',
      tabs: [
        { id: 1, name: '默认参数', route: '/dealer/system/rent/parameter', help: '销售人员计算租赁报价时相关参数默认值。', icon: 'calculate' },
        { id: 2, name: '报价模版', route: '/dealer/system/rent/template', help: '供销售选择已配置完成的租赁报价单。', icon: 'fact_check' },
        { id: 3, name: '行业字典', route: '/dealer/system/rent/industry', help: '供租赁产品和管理员维护行业信息。', icon: 'settings_applications' },
      ],
    }
  },
  methods: {
    goBack() {
      this.$router.push({ path: '/dealer/system' })
    }
  }

}
</script>
