<template>
  <v-container>
    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-toolbar-title>知识库</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="searchKey()"
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索知识标题或标签关键字..."
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
      </v-toolbar>
      <v-alert dense type="info" class="ma-2" dismissible>
        知识库是编辑和发布知识的模块。企业端可以通过检索查询相关知识，同时可以按知识目录提交咨询请求。知识目录的维护在系统设置中完成。
      </v-alert>
      <v-row v-if="showAllKB">
        <v-col cols="12">
          <v-scroll-y-transition mode="out-in">
            <v-data-table
              :headers="headersAll"
              :items="knowledgeList"
              :options.sync="optionsItem"
              :server-items-length="knowledgeCount"
              :loading="knowledgeLoading"
              loading-text="数据读取中... 请稍后"
              :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
              item-key="_id"
              return-object="false"
              transition
            >
              <template v-slot:item.title="{ item }">
                <td class="text-truncate" width="250px">
                  <widgets-knowledge-dialogs :getId="item._id" :key="item._id" @Close="reloadPage()" @Update="getList(query)">
                    <v-icon v-if="item.isTop" small>vertical_align_top</v-icon>
                    <v-icon v-if="item.status === 0" small>block</v-icon>
                    <v-icon v-else small>check_circle_outline</v-icon>
                    {{item.title}}
                  </widgets-knowledge-dialogs>
                </td>
              </template>
              <template v-slot:item.editor="{ item }">
                <td nowrap="true" width="80px" v-if="item.editor"><widgets-employee-dialogs :account="item.editor._id" class="v-list-item--link">{{item.editor.personal ? item.editor.personal.name : '*未设置姓名'}}</widgets-employee-dialogs></td>
              </template>
              <template v-slot:item.isVideo="{ item }">
                <td nowrap="true" width="180px">{{item.isVideo ? '视频' : '图文'}}</td>
              </template>
              <template v-slot:item.catalog="{ item }">
                <td nowrap="true" width="180px">{{item.catalog.name}}</td>
              </template>
              <template v-slot:item.updateTime="{ item }">
                <td nowrap="true" width="80px">{{item.updateTime | formatDate}}</td>
              </template>
            </v-data-table>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
      <v-row v-else justify="space-between">
        <v-col cols="3">
          <v-treeview
            :active.sync="activeCatalog"
            :items="KBCatalogList"
            :loading-icon="KBCatalogLoading ? 'hourglass_top' : ''"
            color="secondary"
            class="text-button"
            :open.sync="deptOpenTree"
            return-object
            activatable
            open-on-click
            transition
            shaped
            hoverable
            dense
          >
            <template v-slot:append="{ item }">
              {{  countKnowledge(item) }}
            </template>
          </v-treeview>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col>
          <v-scroll-y-transition mode="out-in">
            <div v-if="!activeCatalog.length" class="title text--lighten-1 font-weight-light" style="align-self: center;">
              请选择知识目录
            </div>
            <v-data-table
              v-else
              :headers="headers"
              :items="knowledgeList"
              :options.sync="optionsItem"
              :server-items-length="knowledgeCount"
              :loading="knowledgeLoading"
              loading-text="数据读取中... 请稍后"
              :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
              item-key="_id"
              return-object="false"
              transition
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title v-if="activeCatalog.length"><v-icon class="mr-4">description</v-icon>知识列表</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>

                  <v-menu offset-y v-if="currentUser.hasAdmin.includes('dealer') || checkCreatePower(activeCatalog, currentUser)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" color="secondary" depressed>
                        <v-icon left>add</v-icon>
                        创建新知识
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="createKnowledge(activeCatalog, 'text')">
                        <v-list-item-icon>
                          <v-icon>description</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>增加图文知识</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="createKnowledge(activeCatalog, 'video')">
                        <v-list-item-icon>
                          <v-icon>smart_display</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>增加视频百科</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <template v-slot:item.title="{ item }">
                <td class="text-truncate" :style="headerScale ? '' : 'max-width: 125px;'">
                  <widgets-knowledge-dialogs :getId="item._id" :key="item._id" @Close="reloadPage()" @Update="getList(query)">
                    <v-icon v-if="item.isTop" small>vertical_align_top</v-icon>
                    <v-icon v-if="item.status === 0" small>block</v-icon>
                    <v-icon v-else small>check_circle_outline</v-icon>
                    {{item.title}}
                  </widgets-knowledge-dialogs>
                </td>
              </template>
              <template v-slot:header.scale>
                <v-icon size="18" class="mx-2" @click="headerScale = !headerScale">{{headerScale ? 'keyboard_backspace' : 'keyboard_tab'}}</v-icon>
              </template>
              <template v-slot:item.editor="{ item }">
                <td nowrap="true" v-if="item.editor"><widgets-employee-dialogs :account="item.editor._id" class="v-list-item--link">{{item.editor.personal ? item.editor.personal.name : '*未设置姓名'}}</widgets-employee-dialogs></td>
              </template>
              <template v-slot:item.isVideo="{ item }">
                <td nowrap="true">{{item.isVideo ? '视频' : '图文'}}</td>
              </template>
              <template v-slot:item.updateTime="{ item }">
                <td nowrap="true">{{item.updateTime | formatDate}}</td>
              </template>
              <template v-slot:item.actions="{ item }">
                <td nowrap="true">
                  <v-menu bottom left v-if="currentUser.hasAdmin.includes('dealer') || checkAuditPower(activeCatalog, currentUser)">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="editItem(item._id, item.isVideo)">
                        <v-list-item-icon><v-icon>edit_note</v-icon></v-list-item-icon>
                        <v-list-item-title>编辑</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="deleteItem(item)">
                        <v-list-item-icon><v-icon>delete_outline</v-icon></v-list-item-icon>
                        <v-list-item-title>删除</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="!item.isTop" @click="setTop(item, true)">
                        <v-list-item-icon><v-icon>vertical_align_top</v-icon></v-list-item-icon>
                        <v-list-item-title>置顶</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else @click="setTop(item, false)">
                        <v-list-item-icon><v-icon>vertical_align_bottom</v-icon></v-list-item-icon>
                        <v-list-item-title>取消置顶</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="item.status === 0" @click="setAuth(item, 1)">
                        <v-list-item-icon><v-icon>check_circle_outline</v-icon></v-list-item-icon>
                        <v-list-item-title>发布</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-else @click="setAuth(item, 0)">
                        <v-list-item-icon><v-icon>block</v-icon></v-list-item-icon>
                        <v-list-item-title>下架</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </template>
            </v-data-table>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import store from "@/store";
import {
  FETCH_KNOWLEDGE_CATALOG_LIST,
  FETCH_KNOWLEDGE_LIST,
  AUTH_KNOWLEDGE,
  TOP_KNOWLEDGE, DELETE_KNOWLEDGE,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  data: () => ({
    query: {
      key: '',
      catalog: '',
      isTop: null,
      isUseful: null
    },
    defaultQuery: {
      key: '',
      catalog: '',
      isTop: null,
      isUseful: null
    },
    activeCatalog: [],
    headersAll: [
      { text: '标题', value: 'title', width: '120px' },
      { text: '内容格式', value: 'isVideo', width: '120px' },
      { text: '查看次数', value: 'views_count', width: '120px' },
      { text: '有用次数', value: 'useful_account.length', width: '120px' },
      { text: '所属目录', value: 'catalog', width: '120px' },
      { text: '编辑', value: 'editor', width: '120px' },
      { text: '编辑时间', value: 'updateTime', width: '120px' },
    ],
    headers: [
      { text: '标题', value: 'title', width: '175px' },
      {text: '', value: 'scale', active: true, sortable: false, width: '20px', class: 'px-0'},
      { text: '格式', value: 'isVideo', width: '75px' },
      { text: '查看', value: 'views_count', width: '75px' },
      { text: '有用', value: 'useful_account.length', width: '75px' },
      { text: '编辑', value: 'editor', width: '95px' },
      { text: '时间', value: 'updateTime', width: '95px' },
      { text: '操作', value: 'actions', sortable: false },
    ],
    headerScale: false,
    optionsItem: {},
    showAllKB: false,
    deptOpenTree: [],
  }),
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_KNOWLEDGE_CATALOG_LIST),
    ]).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "KBCatalogLoading",
      "knowledgeList",
      "knowledgeCount",
      "knowledgeLoading",
      "KBCatalogList",]),
  },
  mounted() {
    if (Object.keys(this.$route.query).length > 0) this.query = Object.assign(this.query, this.$route.query)
    this.getList()
  },
  watch: {
    activeCatalog: 'getList',
    optionsItem: {
      handler() {
        this.getList(this.query)
      },
      deep: true,
    },
  },
  created() {
    this.getTreeNode()
  },
  methods: {
    getTreeNode() {
      if (this.$route.query.pId) this.activeCatalog = [this.$route.query.pId]
      if (this.$route.query.open) this.deptOpenTree = this.$route.query.open.split(',')
    },
    searchKey() {
      if (this.query.key) {
        this.showAllKB = true
        this.getList()
      } else {
        this.showAllKB = false
      }
    },
    countKnowledge(obj, countKey = 'kb_count') {
      if (obj.parentId) {
        return obj[countKey];
      } else {
        return sumChildren(obj);
      }
    },
    checkAuditPower(catalogId = [], account = {}) {
      let power = false
      let account_id = account._id
      if (catalogId[0].parentId) {
        let editor = catalogId[0].parentId ? catalogId[0].parentId.editor : {}
        let catalog_managers = editor.managers ? editor.managers : []
        if (catalog_managers.includes(account_id)) power = true
      }
      return power
    },
    checkCreatePower(catalogId = [], account = {}) {
      let power = false
      let account_depts = account.employer.department.map(item => item._id)
      if (catalogId[0].parentId) {
        let catalog_dept = catalogId[0].parentId.editor._id
        if (account_depts.includes(catalog_dept)) power = true
      }
      return power
    },
    getList() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
      }
      if (this.activeCatalog && this.activeCatalog.length) this.query.catalog = this.activeCatalog[0]._id
      let strQuery = '';
      if (this.query.key) strQuery += '&key='+ this.query.key
      if (this.query.catalog) strQuery += '&catalog='+ this.query.catalog
      if (this.query.isTop !== null && this.query.isTop !== undefined) strQuery += '&isTop='+ this.query.isTop
      if (this.query.isUseful !== null && this.query.isUseful !== undefined) strQuery += '&isUseful='+ this.isUseful.isTop
      if (this.query.page) strQuery += '&page='+ this.query.page
      if (this.query.limit) strQuery += '&limit='+ this.query.limit
      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_KNOWLEDGE_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + strQuery)
      }
    },
    createKnowledge(catalog, isVideo = '') {
      if (!this.activeCatalog || !this.activeCatalog.length) {
        store.commit(SET_ERROR, {msg: '请选择分类后再创建新知识。'})
        return
      }
      if (!this.activeCatalog[0]._id) {
        store.commit(SET_ERROR, {msg: '请选择分类后再创建新知识。'})
        return
      }
      if (!this.activeCatalog[0].parentId) {
        store.commit(SET_ERROR, {msg: '请选择分组后再创建新知识。'})
        return
      }
      let pId = ''
      let open = ''
      pId = this.activeCatalog[0]._id
      open = this.deptOpenTree.map(item => item._id).join(',')
      if (isVideo === 'video') {
        this.$router.push({path:'/dealer/knowledge/edit/video/0?group='+ catalog[0].parentId._id + '&catalog='+ catalog[0]._id +'&pId=' + pId + '&open=' + open })
      } else {
        this.$router.push({path:'/dealer/knowledge/edit/0?group='+ catalog[0].parentId._id + '&catalog='+ catalog[0]._id +'&pId=' + pId + '&open=' + open })
      }

    },
    reloadPage() {
      this.$router.go(0)
    },
    editItem(id, isVideo = false) {
      if (isVideo) {
        this.$router.push({path:'/dealer/knowledge/edit/video/' + id})
      } else {
        this.$router.push({path:'/dealer/knowledge/edit/' + id})
      }
    },
    setTop(objItem, reply) {
      store.dispatch(TOP_KNOWLEDGE, { _id: objItem._id, reply })
        .then(() => {
          store.commit(SET_ERROR, {msg: '操作成功', color: 'primary'})
          this.getList(this.query)
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err})
        });
    },
    setAuth(objItem, reply) {
      store.dispatch(AUTH_KNOWLEDGE, { _id: objItem._id, reply })
        .then(() => {
          store.commit(SET_ERROR, {msg: '操作成功', color: 'primary'})
          this.getList(this.query)
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err})
        });
    },
    deleteItem(item) {
      if (confirm('确定要删除 '+ item.title +'？删除后该知识将不能被检索和查看。')) {
        if (confirm('已经引用该知识的内容将不受影响。如首页、咨询等内容。')) {
          store.dispatch(DELETE_KNOWLEDGE, item)
            .then(() => {
              store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'})
              this.reloadPage()
            })
            .catch((err) => {
              store.commit(SET_ERROR, {msg: err})
            });
        }
      }
    }
  }
}
function sumChildren(obj, key = 'kb_count') {
  let count = obj[key]
  if (obj.children) {
    for (let item of obj.children) {
      count += sumChildren(item)
    }
  }
  return count
}
</script>
<style scoped>
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
