<template>
  <v-container>
    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-btn icon @click="$router.push('/dealer/workbench')">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>租赁核价模块</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="doAction('search_item')"
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索报价编号、名称..."
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
      </v-toolbar>
      <v-sheet color="grey lighten-3">
        <v-card flat color="transparent">
          <v-card-title class="font-weight-regular subtitle-1">
            创建新报价
          </v-card-title>
          <v-card-text>
            <v-row class="px-6">
              <v-col cols="2">
                <v-hover v-slot="{ hover }">
                  <v-card
                    height="100"
                    flat
                    class="overflow-hidden"
                    @click="$router.push('/dealer/workbench/rent/create')"
                  >
                    <v-card-text
                      align="center"
                      justify="center"
                      class="fill-height"
                    >
                      <v-icon :color="hover ? 'secondary' : 'grey'" class="mt-5"
                        >add</v-icon
                      >
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-col>
              <v-col cols="2" v-for="item in topList" :key="item._id">
                <v-hover v-slot="{ hover }">
                  <v-card height="100" outlined class="overflow-hidden" hover @click="$router.push('/dealer/workbench/rent/create?templateId='+item._id+'&type=template')">
                    <v-img :src="item.cover_image ? ossURL + '/' + item.cover_image : '/static/error/empty_state.svg'" class="align-end" height="100" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                      <v-card-title
                        class="pa-2 caption white--text"
                        v-text="item.title"
                      ></v-card-title>
                    </v-img>
                    <v-fade-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal body-2 white--text"
                        style="height: 100%; cursor: pointer"
                      >
                          <v-icon color="secondary">add</v-icon>
                      </div>
                    </v-fade-transition>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header
                  color="grey lighten-3"
                  hide-actions
                  v-slot="{ open }"
                >
                  <span class="caption secondary--text">{{
                    open ? "收起" : "显示全部"
                  }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="grey lighten-3" class="pa-0">
                  <v-row>
                    <v-col cols="2" v-for="item in templateList" :key="item._id">
                      <v-hover v-slot="{ hover }">
                        <v-card height="100" outlined class="overflow-hidden" hover @click="$router.push('/dealer/workbench/rent/create?templateId='+item._id+'&type=template')">
                          <v-img :src="item.cover_image ? ossURL + '/' + item.cover_image : '/static/error/empty_state.svg'" class="align-end" height="100" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                            <v-card-title
                              class="pa-2 caption white--text"
                              v-text="item.title"
                            ></v-card-title>
                          </v-img>
                          <v-fade-transition>
                            <div
                              v-if="hover"
                              class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal body-2 white--text"
                              style="height: 100%; cursor: pointer"
                            >
                                <v-icon color="secondary">add</v-icon>
                            </div>
                          </v-fade-transition>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-sheet>
      <v-card flat>
        <v-card-text>
          <v-data-table
            :options.sync="optionsItem"
            :headers="headers"
            :items="rentQuoteList"
            :server-items-length="rentQuoteCount"
            :footer-props="{'items-per-page-options': [5, 10, 15]}"
            class="elevation-0 mt-4"
          >
            <template v-slot:top>
              <v-toolbar flat class="d-print-none">
                <v-card-title class="font-weight-regular pl-0 subtitle-1"
                  >我的报价</v-card-title
                >
                <v-spacer></v-spacer>
                <!-- <v-btn
                  class="ma-2"
                  outlined
                  fab
                  x-small
                  @click="filterdrawer = true"
                >
                  <v-icon>filter_list</v-icon>
                </v-btn> -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon @click="filterdrawer = true">
                      <v-icon small>filter_list</v-icon>
                    </v-btn>
                  </template>
                  <span>过滤数据</span>
                </v-tooltip>
              </v-toolbar>
            </template>
            <template v-slot:item.id="{ item }">
              <!-- <widgets-offer-dialogs :getId="item._id" :key="item._id" v-on:Update="updateList"> -->
                <div @click="checkOfferInfo(item._id)" class="v-list-item--link">
                  <v-badge color="secondary" dot v-if="item.status == 2">
                    <span>{{ item.offer_number }}</span>
                  </v-badge>
                <span v-else>{{ item.offer_number }}</span>
                </div>
              <!-- </widgets-offer-dialogs> -->
            </template>
            <template v-slot:item.name="{ item }">
              <td
                nowrap="true"
                width="200"
                class="text-truncate"
              >
                {{ item.title }}
              </td>
            </template>
            <template v-slot:item.enterprise="{ item }">
              <td
                nowrap="true"
                width="200"
                class="v-list-item--link text-truncate"
                @click="openEnterInfo(item.enterprise.id)"
              >
                {{ item.enterprise.name }}
              </td>
            </template>
            <template v-slot:item.status="{ item }">
              <!-- <v-chip label small v-if="item.status == '0'">已保存</v-chip>
              <v-chip label small color="primary" v-else-if="item.status == '1'">已审核</v-chip>
              <v-chip label small color="secondary" v-else-if="item.status == '2'">已更新</v-chip>
              <v-chip label small v-else-if="item.status == '3'">已过期</v-chip> -->
              <v-chip
                label
                small
                v-if="item.expiry_date <= new Date().toISOString()"
                >已过期</v-chip
              >
              <v-chip label small v-else-if="item.status == '0'">已保存</v-chip>
              <v-chip label small color="primary" v-else-if="item.status == '1'"
                >已审核</v-chip
              >
              <v-chip
                label
                small
                color="secondary"
                v-else-if="item.status == '2'"
                >已更新</v-chip
              >
            </template>
            <template v-slot:item.createTime="{ item }">
              <td nowrap="true">{{ item.createTime | formatTime }}</td>
            </template>
            <template v-slot:item.updateTime="{ item }">
              <td nowrap="true">{{ item.updateTime | formatTime }}</td>
            </template>
            <template v-slot:item.expiry_date="{ item }">
              <td nowrap="true">{{ item.expiry_date | formatTime }}</td>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                  >
                    <v-icon small>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    v-if="
                      (item.status == '0' || item.status == '2') &&
                      item.expiry_date > new Date().toISOString() &&
                      (pLevel >= 1 || currentUser.hasAdmin.includes('dealer'))
                    "
                    @click="confirmApprove(item)"
                  >
                    <v-list-item-icon>
                      <v-icon small>check</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> 审核 </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="copyOffer(item._id, item.template)">
                    <v-list-item-icon>
                      <v-icon small>content_copy</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> 复制 </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="pLevel >= 1 || currentUser.hasAdmin.includes('dealer')" @click="confirmDelete(item)">
                    <v-list-item-icon>
                      <v-icon small>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> 删除 </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>

    <v-navigation-drawer
      v-model="filterdrawer"
      app
      temporary
      right
      height="100%"
      style="z-index: 999"
    >
      <v-card class="mx-auto" flat>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="headline py-2">筛选</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-card-text>
          <v-sheet min-height="500">
            <div class="mt-2">
              <!-- <v-text-field

                label="名称"
                item-text="name"
                item-value="code"
                chips
                clearable
                outlined
                dense
              ></v-text-field> -->
              <v-autocomplete
                v-model="query.enterprise"
                label="客户"
                autocomplete="off"
                :items="enterpriseList"
                item-text="name"
                item-value="_id"
                hide-no-data
                :loading="isEnterpriseLoading"
                :search-input.sync="searchEnterprise"
                outlined
                dense
              ></v-autocomplete>
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="doAction('clear_filter')">清空</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="doAction('set_filter')"
            >确认</v-btn
          >
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="changedrawer"
      app
      temporary
      right
      width="500"
      height="100%"
      style="z-index: 999"
    >
      <v-card class="mx-auto" flat>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="headline py-2"
              >非凡量子询价</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-card-text>
          <v-sheet min-height="500">
            <v-sheet color="primary" class="pa-2">
              <span style="color: #fff">调整参数</span>
            </v-sheet>
            <div class="mt-6">
              <v-text-field
                label="计算器有效期"
                item-text="name"
                item-value="code"
                readonly
                dense
                outlined
                value="2022-04-15"
              ></v-text-field>
              <v-text-field
                label="年利率（资金成本）"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="7.5"
                suffix="%"
              ></v-text-field>
              <v-text-field
                label="风险控制系数"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="2.5"
                suffix="%"
              ></v-text-field>
              <v-text-field
                label="还租次数（年）"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="4"
              ></v-text-field>
              <v-text-field
                label="还款总期数"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="12"
              ></v-text-field>
              <v-text-field
                label="租赁方式"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="经营性租赁"
              ></v-text-field>
              <v-text-field
                label="净租金成本"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="421.23"
              ></v-text-field>
              <v-text-field
                label="不含账期费租金"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="452.82"
              ></v-text-field>
              <v-text-field
                label="账期费用（元/期）"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="2.98"
              ></v-text-field>
              <v-text-field
                label="租赁报价（元/期）"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="455.80"
              ></v-text-field>
              <v-text-field
                label="期末余值"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="200"
              ></v-text-field>
              <v-text-field
                label="期末余值折现"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="164.79"
              ></v-text-field>
              <v-text-field
                label="IRR"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="14.79"
              ></v-text-field>
              <v-text-field
                label="经营性租赁月均价"
                item-text="name"
                item-value="code"
                clearable
                outlined
                dense
                value="151.93"
              ></v-text-field>
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
      <template v-slot:append>
        <v-divider></v-divider>
        <div class="pa-2">
          <v-btn block outlined color="primary" @click="changedrawer = false"
            >提交</v-btn
          >
        </div>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="newSingle" width="800" persistent>
      <v-card>
        <v-card-title class="headline font-weight-regular"
          >新增VNC</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col lg="6" md="6" sm="12">
              <v-text-field
                label="名称"
                color="primary"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col lg="6" md="6" sm="12">
              <v-text-field
                label="IP地址"
                color="primary"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col lg="6" md="6" sm="12">
              <v-text-field
                label="端口号"
                color="primary"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col lg="6" md="6" sm="12"> </v-col>
            <v-col lg="6" md="6" sm="12">
              <v-select
                label="所属学校"
                :items="schoolItems"
                placeholder="选择学校"
                color="primary"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col lg="6" md="6" sm="12">
              <v-select
                label="所属教室"
                :items="classItems"
                placeholder="选择教室"
                color="primary"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="newSingle = false">取消</v-btn>
          <v-btn color="primary" text @click="newSingle = false">提交</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="enterInfoDlg" scrollable max-width="600px" persistent>
      <widgets-enterprise-dialogs
        :id="activeId"
        :key="activeId"
        v-on:Close="closeEnterInfo"
      ></widgets-enterprise-dialogs>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
  FETCH_RENT_QUOTE_LIST,
  FETCH_ENTERPRISE_LIST,
  APPROVE_RENT_QUOTE,
  DELETE_RENT_QUOTE,
  FETCH_RENT_TEMPLATE_LIST,
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
export default {
  data() {
    return {
      headers: [
        { text: "报价编号", value: "id" },
        { text: "名称", value: "name" },
        { text: "客户", value: "enterprise" },
        { text: "创建时间", value: "createTime" },
        { text: "更新时间", value: "updateTime" },
        { text: "过期时间", value: "expiry_date" },
        { text: "状态", value: "status" },
        { text: "操作", value: "actions" },
      ],
      desserts: [
        {
          id: "202020222991",
          name: "非凡量子询价",
          enterprise: "非凡量子（北京）科技有限公司",
          creatTime: "2021-01-01",
          updateTime: "2021-01-03",
          expire: "2021-02-01",
          status: "0",
        },
        {
          id: "202020222992",
          name: "非凡量子询价",
          enterprise: "非凡量子（北京）科技有限公司",
          creatTime: "2021-01-01",
          updateTime: "2021-01-03",
          expire: "2021-02-01",
          status: "1",
        },
        {
          id: "202020222993",
          name: "非凡量子询价",
          enterprise: "非凡量子（北京）科技有限公司",
          creatTime: "2021-01-01",
          updateTime: "2021-01-03",
          expire: "2021-02-01",
          status: "2",
        },
        {
          id: "202020222994",
          name: "非凡量子询价",
          enterprise: "非凡量子（北京）科技有限公司",
          creatTime: "2021-01-01",
          updateTime: "2021-01-03",
          expire: "2021-02-01",
          status: "3",
        },
      ],
      filterdrawer: false,
      changedrawer: false,
      newSingle: false,
      classItems: ["IOS开发2001班", "IOS开发2002班"],
      schoolItems: ["麦肯思维", "麦肯教育", "思维教育"],
      newMult: false,
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      query: {
        key: "",
        enterprise: "",
        status: [],
      },
      defaultQuery: {
        key: "",
        enterprise: "",
        status: [],
      },
      optionsItem: {},
      enterpriseList: [],
      isEnterpriseLoading: false,
      searchEnterprise: null,
      enterInfoDlg: false,
      activeId: "",
      pLevel: 0,
      temQuery: {},
      topList: [],
      templateList: [],
    };
  },
  computed: {
    ...mapGetters(["currentUser", "rentQuoteList", "rentQuoteCount"]),
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
    searchEnterprise(val) {
      if (this.countDown) {
        clearTimeout(this.countDown);
      }
      this.countDown = setTimeout(() => {
        if (this.isEnterpriseLoading) return;
        this.isEnterpriseLoading = true;
        store
          .dispatch(FETCH_ENTERPRISE_LIST, "&key=" + val)
          .then((data) => {
            this.enterpriseList = data.enterprise;
            this.isEnterpriseLoading = false;
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
            this.isEnterpriseLoading = false;
          });
      }, 500);
    },
  },
  created() {
    this.getTemplateList(true)
    this.getTemplateList()
    this.getList();
    this.checkPermission();
  },
  methods: {
    checkOfferInfo(id) {
      this.$router.push("/dealer/workbench/rent/offer?offerId="+id);
    },
    getTemplateList(isTop) {
      let url = ''
      if (isTop) {
        url = 'isTop=true&isEnable=true&page=1&limit=5'
        store.dispatch(FETCH_RENT_TEMPLATE_LIST, url).then((data) => {
          this.topList = data.template;
        });
      }else {
        url = 'isEnable=true&page=1&limit=100'
        store.dispatch(FETCH_RENT_TEMPLATE_LIST, url).then((data) => {
          this.templateList = data.template;
        });
      }

    },
    getList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.status && !Array.isArray(this.query.status)) this.query.status = this.query.status.split(',')
      }

      let urlQuery = "";
      if (query.key) urlQuery += "&key=" + query.key;
      if (query.enterprise) urlQuery += "&enterprise=" + query.enterprise;
      if (this.query.status.length) urlQuery += '&status='+ query.status

      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_RENT_QUOTE_LIST, {
          query:
            "page=" +
            page +
            "&limit=" +
            itemsPerPage +
            "&sort=" +
            sort +
            urlQuery,
        })
      }
    },
    checkPermission() {
      let p = this.currentUser.permission;
      if (p.includes("Lease_Admin")) {
        this.pLevel = 3;
      } else if (p.includes("Lease_Product")) {
        this.pLevel = 2;
      } else if (p.includes("Lease_Audit")) {
        this.pLevel = 1;
      }
    },
    doAction(action) {
      switch (action) {
        case "search_item": {
          this.getList(this.query);
          break;
        }
        case "set_filter": {
          this.$router.push({ query: {} });
          this.getList(this.query);
          this.filterDrawer = !this.filterDrawer;
          break;
        }
        case "clear_filter": {
          this.query = Object.assign({}, this.defaultQuery);
          this.$router.push({ query: {} });
          this.getList();
          this.filterDrawer = !this.filterDrawer;
          break;
        }
      }
    },
    openEnterInfo(id) {
      this.activeId = id;
      this.enterInfoDlg = true;
    },
    closeEnterInfo() {
      this.enterInfoDlg = false;
    },
    confirmApprove(item) {
      if (confirm("确定要审核通过 " + item.title + " 此报价吗？")) {
        store
          .dispatch(APPROVE_RENT_QUOTE, item._id)
          .then(() => {
            this.getList();
            store.commit(SET_ERROR, { msg: "审核成功", color: "primary" });
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
          });
      }
    },
    confirmDelete(item) {
      if (confirm("确定要删除 " + item.title + " 此报价吗？")) {
        store
          .dispatch(DELETE_RENT_QUOTE, item._id)
          .then(() => {
            this.getList();
            store.commit(SET_ERROR, { msg: "删除成功", color: "primary" });
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
          });
      }
    },
    copyOffer(id, template) {
      let url = '?offerId='+id
      if (template) {
        url+='&templateId='+template+'&type=copyTemplate'
      }else {
        url+='&type=copy'
      }
      this.$router.push(
        "/dealer/workbench/rent/create"+url
      );
    },
    updateList() {
      this.getList(this.query)
    }
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}
.v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0 0 0 0 !important;
}
.addIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
</style>
