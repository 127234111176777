<template>
  <div class="fluid">
    <v-data-table
      :headers="headerTable"
      :items="taskList"
      :options.sync="optionsTable"
      :server-items-length="taskCount"
      :loading="taskLoading"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      loading-text="数据读取中... 请稍后"
      item-key="name"
      transition
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>任务列表</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" depressed color="secondary" @click="doAction('create_item', '', '创建新任务')">
            <v-icon>add</v-icon> 创建任务
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <span :class="item.isEnable ? '' : 'text-decoration-line-through'">{{item.name}}</span>
        <v-icon v-if="item.isExpress" small class="ma-2">local_shipping</v-icon>
        <v-icon v-if="item.isWarehouse" small class="ma-2">store</v-icon>
      </template>
      <template v-slot:item.department="{ item }">
        <span v-if="item.action_roles === 'applicant'">申请人</span>
        <span v-else-if="item.action_roles === 'enterprise'">申请人团队</span>
        <span v-else-if="item.action_roles === 'service'">服务团队</span>
        <span v-else-if="item.action_roles === 'assign'">申请人指定</span>
        <span v-else>{{ item.department ? item.department.name : '' }}</span>
      </template>
      <template v-slot:item.account="{ item }">
        <widgets-employee-dialogs v-for="account in item.account" :key="account._id" :account="account._id" divider="- ">
          {{account.personal.name ? account.personal.name : '*未设置姓名'}}
        </widgets-employee-dialogs>
      </template>
      <template v-slot:item.SLA_limit="{ item }">
        <span>{{item.SLA_limit === 0 ? '不限' : item.SLA_limit}}</span>
      </template>
      <template v-slot:item.act="{ item }">
        <v-icon small class="mr-2" @click="doAction('edit_item', item, '编辑任务')">edit</v-icon>
        <v-icon small @click="doAction('active_item', item, true)" v-if="!item.isEnable">check_circle_outline</v-icon>
        <v-icon small @click="doAction('active_item', item, false)" v-if="item.isEnable">block</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataList()">获取数据</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dlgEdit" v-if="dlgEdit" scrollable persistent max-width="750px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" >
                <v-alert dense type="info" class="ma-2" dismissible>
                  <span v-if="editedItem.isExpress">快递任务系统将自动创建承运公司和运单号字段。</span>
                  <span v-else-if="editedItem.isWarehouse">入库任务选择的字段为旧设备可编辑内容。</span>
                  <span v-else>一般任务系统常用任务类型。</span>
                </v-alert>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[rules.required]"
                  type="text"
                  label="任务名称"
                  hint="请输入任务名称"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-model="editedItem.taskType"
                  :items="[ { name:'一般任务', code: 'general' }, { name: '快递任务',code: 'express' }, { name: '入库任务', code: 'warehouse'} ]"
                  :rules="[rules.selected]"
                  @change="doAction('set_task_type')"
                  item-text="name"
                  item-value="code"
                  autocomplete="off"
                  label="任务类型"
                  hint="请选择任务类型"
                  outlined
                  dense
                ></v-autocomplete>
<!--                <v-switch-->
<!--                  v-model="editedItem.isExpress"-->
<!--                  hide-details-->
<!--                  label="快递任务"-->
<!--                  class="mt-1"-->
<!--                ></v-switch>-->
              </v-col>
              <v-col cols="12" class="mt-n6">
                <v-textarea
                  outlined
                  auto-grow
                  rows="3"
                  v-model="editedItem.remarks"
                  label="任务提示"
                  value=""
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="mt-n6">
                <v-autocomplete
                  v-model="editedItem.department"
                  :items="getDeptList(departments[0])"
                  :rules="[rules.required, rules.selected]"
                  @change="doAction('set_employee_list')"
                  item-text="name"
                  item-value="value"
                  autocomplete="off"
                  outlined
                  dense
                  label="请选择执行部门"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="mt-n6">
                <v-autocomplete
                  v-model="editedItem.account"
                  :items="accountList"
                  :disabled="!selectAccount"
                  ref="selectAccount"
                  item-text="personal.name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  chips
                  small-chips
                  multiple
                  deletable-chips
                  clearable
                  label="执行人员 空代表部门内人员都可执行"
                  hint="请选择任务执行人员"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                      <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" class="mt-n6">
                <v-text-field
                  v-model.number="editedItem.SLA_limit"
                  suffix="小时"
                  type="number"
                  label="SLA时效"
                  hint="输入该任务在处理时SLA限时，0代表不限制"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="mt-n6 d-flex flex-nowrap">
                可执行动作：
<!--                <v-checkbox-->
<!--                  v-model="editedItem.actions"-->
<!--                  multiple-->
<!--                  hide-details-->
<!--                  label="驳回"-->
<!--                  value="reject"-->
<!--                  class="mt-n1 mx-4"-->
<!--                ></v-checkbox>-->
                <v-checkbox
                  v-model="editedItem.actions"
                  multiple
                  hide-details
                  label="取消"
                  value="cancel"
                  class="mt-n1 mx-4"
                ></v-checkbox>
                <v-checkbox
                  v-model="editedItem.actions"
                  multiple
                  disabled
                  hide-details
                  label="保存"
                  value="save"
                  class="mt-n1 mx-4"
                ></v-checkbox>
                <v-checkbox
                  v-model="editedItem.actions"
                  multiple
                  hide-details
                  label="协助"
                  value="assist"
                  class="mt-n1 mx-4"
                ></v-checkbox>
<!--                <v-checkbox-->
<!--                  v-model="editedItem.actions"-->
<!--                  multiple-->
<!--                  hide-details-->
<!--                  label="挂起"-->
<!--                  value="hangup"-->
<!--                  class="mt-n1 mx-4"-->
<!--                ></v-checkbox>-->
                <v-checkbox
                  v-model="editedItem.actions"
                  multiple
                  disabled
                  hide-details
                  label="完成"
                  value="complete"
                  class="mt-n1 mx-4"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" class="mt-n2 d-flex flex-nowrap">
                可操作内容：
                <v-checkbox
                  v-model="editedItem.powers"
                  multiple
                  hide-details
                  label="上传附件"
                  value="upload_files"
                  class="mt-n1 mx-4"
                ></v-checkbox>
                <v-checkbox
                  v-model="editedItem.powers"
                  multiple
                  hide-details
                  label="增加备注"
                  value="create_remarks"
                  class="mt-n1 mx-4"
                ></v-checkbox>
                <v-checkbox
                  v-model="editedItem.powers"
                  multiple
                  hide-details
                  label="关联资产"
                  value="relate_asset"
                  class="mt-n1 mx-4"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-if="editedItem.actions.includes('complete')"
                  v-model="editedItem.asset_complete"
                  :items="stateList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  label="该任务完成后设备状态"
                  hint="当任务完成后设备状态"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="mt-n4" v-if="!editedItem.isExpress">
                需编辑资产：资产字段和任务字段是该任务处理时，需要编辑的内容。
                <v-chip-group v-model="editedItem.asset_fields" column multiple>
                  <v-chip
                    v-for="(f, i) in fields_asset"
                    :key="'asset_field_'+i"
                    :value="f.field"
                    filter outlined>{{ f.name }}</v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="6" class="mt-n4" v-if="!editedItem.isExpress">
                <v-subheader>可选择任务字段</v-subheader>
                  <v-virtual-scroll :items="fields_task" :item-height="50" height="175">
                    <template v-slot:default="{ index, item }">
                      <v-list-item dense>
                        <v-list-item-content>
                          <v-list-item-title v-html="item.name"></v-list-item-title>
                          <v-list-item-subtitle v-if="item.format.type === 'text'">文本输入</v-list-item-subtitle>
                          <v-list-item-subtitle v-if="item.format.type === 'selects'">单项选择</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn depressed small @click="doAction('add_fields_task', index)">选择字段<v-icon right>keyboard_arrow_right</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider></v-divider>
                    </template>
                  </v-virtual-scroll>
              </v-col>
              <v-col cols="6" class="mt-n4" v-if="!editedItem.isExpress">
                <v-subheader>已选择任务字段</v-subheader>
                <v-list dense>
                  <draggable :list="editedItem.fields" v-bind="dragOptions" handle=".drag">
                    <v-list-item v-for="(element, index) in editedItem.fields" :key="element.name">
                      <v-list-item-icon>
                        <v-icon class="mt-2 drag" small>drag_indicator</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-if="element.name" v-html="element.name"></v-list-item-title>
                        <v-list-item-subtitle v-if="element.model === 'asset'">资产字段</v-list-item-subtitle>
                        <v-list-item-subtitle v-else>任务字段</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn depressed small icon v-if="element.model === 'asset'" @click="doAction('remove_fields_asset', index)"><v-icon>remove</v-icon></v-btn>
                        <v-btn depressed small icon v-else @click="doAction('remove_fields_task', index)"><v-icon>remove</v-icon></v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </draggable>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <widgets-custom-field v-if="!editedItem.isExpress" module="Task" :ownerId="currentEmployer.ownerId._id" ownerType="Dealer" v-on:Complete="doAction('update_field')"></widgets-custom-field>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import store from "@/store";
import {SET_ERROR} from "@/store/mutations.type";
import {
  FETCH_TASK_LIST,
  PUBLISH_TASK,
  EDIT_TASK,
  ACTIV_TASK,
  FETCH_DEPT_LIST,
  FETCH_ACCOUNT_LIST,
  FETCH_ASSETSTATE_LIST,
  FETCH_FIELDS
} from "@/store/actions.type";
import Util from "@/common/util";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedIndex: -1,
      selectAccount: false,
      editedItem: {
        _id: '',
        name: '',
        actions: ['save','complete'],
        powers: ['upload_files', 'create_remarks', 'relate_asset'],
        SLA_limit: 0,
        department: null,
        account: [],
        asset_state: null,
        fields: [],
        asset_fields: [],
        remarks: '',
        isExpress: false,
        taskType: 'general',
        isWarehouse: false,
      },
      defaultItem: {
        _id: '',
        name: '',
        actions: ['save','complete'],
        powers: ['upload_files', 'create_remarks', 'relate_asset'],
        SLA_limit: 0,
        department: null,
        account: [],
        asset_state: null,
        fields: [],
        asset_fields: [],
        remarks: '',
        isExpress: false,
        taskType: 'general',
        isWarehouse: false,
      },
      query: {
        key: '',
      },
      defaultQuery: {
        key: '',
      },
      headerTable: [
        {text: '名称', value: 'name'},
        {text: '执行部门', value: 'department' },
        {text: '执行人', value: 'account' },
        {text: 'SLA 单位：小时', value: 'SLA_limit' },
        {text: '操作', value: 'act', align: 'end', sortable: false}
      ],
      optionsTable: {},
      fields_task: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_DEPT_LIST),
      store.dispatch(FETCH_ASSETSTATE_LIST, 'isEnable=true'),
    ]).then(() => {
      next();
    });
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "taskLoading",
      "taskList",
      "taskCount",
      "departments",
      "accountList",
      "stateList",
      "customFields"]),
    dragOptions() {
      return {
        animation: 200,
        group: "tasks",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    fields_asset () {
      return Util.categories('assetFields')
    }
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  mounted() {},
  methods: {
    getDataList(query = {}) {
      // 获取分页信息
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (query.key) urlQuery += '&key=' + query.key
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_TASK_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    doAction(action, item, customitem) {
      switch (action) {
        case 'search_key': {
          if (this.query.key !== '') {
            this.getDataList({key: this.query.key})
          } else {
            this.getDataList()
          }
          break
        }
        case 'create_item': {
          this.getFieldList()
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
        case 'edit_item': {
          this.getFieldList()
          this.editedIndex = this.taskList.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.editedItem.taskType = 'general'
          if (this.editedItem.isExpress) this.editedItem.taskType = 'express'
          if (this.editedItem.isWarehouse) this.editedItem.taskType = 'warehouse'
          if (this.editedItem.action_roles === 'applicant') {
            this.editedItem.department = 'applicant'
          } else if (this.editedItem.action_roles === 'enterprise') {
            this.editedItem.department = 'enterprise'
          } else if (this.editedItem.action_roles === 'service') {
            this.editedItem.department = 'service'
          } else if (this.editedItem.action_roles === 'assign') {
            this.editedItem.department = 'assign'
          } else {
            this.selectAccount = true
            this.editedItem.action_roles = 'department'
            this.editedItem.department = this.editedItem.department && this.editedItem.department._id
            // console.log(this.editedItem.department)
            if (this.editedItem.department) {
              store.dispatch(FETCH_ACCOUNT_LIST, '&limit=100&department='+ this.editedItem.department)
                .then(() => {
                  this.editedItem.account = this.editedItem.account.map(o => o._id)
                })
            } else {
              this.editedItem.account = []
            }

          }
          store.dispatch(FETCH_FIELDS, this.currentEmployer.ownerId._id+'/Task')
            .then((data) => {
              this.fields_task = data.filter(f => !this.editedItem.fields.includes(f._id))
              this.editedItem.fields = this.editedItem.fields.map(i => data.find(o => o._id === i))
            })
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
        case 'active_item': {
          if (confirm(`确定要 ${customitem ? '启用' : '禁用'} ${item.name}？`)) {
            store.dispatch(ACTIV_TASK, {_id: item._id, reply: customitem})
              .then(() => {
                store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
                this.getDataList()
                this.close()
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          }
          break
        }
        case 'set_employee_list': {
          this.editedItem.account = []
          this.$refs.selectAccount.internalSearch = null;
          if (this.editedItem.department) {
            if (this.editedItem.department !== 'applicant' &&
              this.editedItem.department !== 'enterprise' &&
              this.editedItem.department !== 'service' &&
              this.editedItem.department !== 'assign') {
              store.dispatch(FETCH_ACCOUNT_LIST, '&limit=100&department='+ this.editedItem.department)
              this.selectAccount = true
            } else {
              this.editedItem.account = null
              this.$refs.selectAccount.internalSearch = null;
              this.selectAccount = false
            }
          }
          break
        }
        case 'update_field': {
          this.getFieldList('Task');
          break
        }
        case 'add_fields_task': {
          this.editedItem.fields.push(this.fields_task[item])
          this.fields_task.splice(item, 1)
          break
        }
        case 'remove_fields_task': {
          this.fields_task.push(this.editedItem.fields[item])
          this.editedItem.fields.splice(item, 1)
          break
        }
        case 'add_fields_asset': {
          this.editedItem.fields.push(this.fields_asset[item])
          this.fields_asset.splice(item, 1)
          break
        }
        case 'remove_fields_asset': {
          this.fields_asset.push(this.editedItem.fields[item])
          this.editedItem.fields.splice(item, 1)
          break
        }
        case 'set_task_type': {
          this.editedItem.isExpress = false
          this.editedItem.isWarehouse = false
          if (this.editedItem.taskType === 'express') this.editedItem.isExpress = true
          if (this.editedItem.taskType === 'warehouse') this.editedItem.isWarehouse = true
          break
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    close () {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.fields_task.length = 0
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.account.length = 0
        this.editedItem.fields.length = 0
        this.editedItem.asset_fields.length = 0
        this.editedIndex = -1
      })
    },
    submit() {
      let strInsert = {}
      strInsert.name = this.editedItem.name
      if (this.editedItem.department === 'applicant') {
        strInsert.action_roles = 'applicant'
        strInsert.department = ''
        strInsert.account = ''
      } else if (this.editedItem.department === 'enterprise') {
        strInsert.action_roles = 'enterprise'
        strInsert.department = ''
        strInsert.account = ''
      } else if (this.editedItem.department === 'service') {
        strInsert.action_roles = 'service'
        strInsert.department = ''
        strInsert.account = ''
      } else if (this.editedItem.department === 'assign') {
        strInsert.action_roles = 'assign'
        strInsert.department = ''
        strInsert.account = ''
      } else {
        strInsert.action_roles = 'department'
        strInsert.department = this.editedItem.department
        strInsert.account = this.editedItem.account
      }
      if (this.editedItem.account && this.editedItem.account.length) strInsert.action_roles = 'account'
      // 以上代码判断执行团队，如果为判断内则 部门和账号为空。
      strInsert.actions = this.editedItem.actions
      strInsert.powers = this.editedItem.powers
      strInsert.SLA_limit = this.editedItem.SLA_limit || 0
      strInsert.asset_state = this.editedItem.asset_state
      // strInsert.asset_reject = this.editedItem.asset_reject
      // strInsert.asset_cancel = this.editedItem.asset_cancel
      // strInsert.asset_save = this.editedItem.asset_save
      // strInsert.asset_hangup = this.editedItem.asset_hangup
      strInsert.asset_complete = this.editedItem.asset_complete
      strInsert.fields = this.editedItem.fields.map(o => o._id)
      strInsert.asset_fields = this.editedItem.asset_fields
      strInsert.remarks = this.editedItem.remarks
      if (this.editedItem.taskType === 'express') strInsert.isExpress = true
      if (this.editedItem.taskType === 'warehouse') strInsert.isWarehouse = true
      // console.log('strInsert =>'+ JSON.stringify(strInsert))
      if (this.editedIndex !== -1) {
        strInsert._id = this.editedItem._id
        store.dispatch(EDIT_TASK, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_TASK, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },
    getDeptList(obj) {
      let arrObj = [];
      arrObj.push({name: obj.name, value: obj._id})
      if (obj.children) {
        for (let item_1 of obj.children) {
          arrObj.push({name: obj.name +'>>'+ item_1.name, value: item_1._id})
          if (item_1.children) {
            for (let item_2 of item_1.children) {
              arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name, value: item_2._id})
              if (item_2.children) {
                for (let item_3 of item_2.children) {
                  arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name +'>>'+ item_3.name, value: item_3._id})
                }
              }
            }
          }
        }
      }
      arrObj.push({name: '申请人', value: 'applicant'})
      arrObj.push({name: '申请人所在团队', value: 'enterprise'})
      // arrObj.push({name: '申请人指定人员', value: 'assign'})
      arrObj.push({name: '受理环节指定的服务团队', value: 'service'})
      return arrObj;
    },
    getFieldList(mod = 'Task') {
      store.dispatch(FETCH_FIELDS, this.currentEmployer.ownerId._id+'/'+mod)
        .then((data) => {
          this.fields_task = [...data]
        })
    },
  }
}
</script>
