<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>资产配置</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-title>
        提示
      </v-card-title>
      <v-card-subtitle>在使用设备管理模块前需要在这里进行基础配置。包括库房、供应商（品牌厂商）、产品分类、产品、设备状态的配置，只有这些配置完成后，设备管理才可以正常使用。</v-card-subtitle>
      <v-tabs v-model="activeTab" color="secondary" grow icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route">
          {{ tab.name }}
          <v-icon>{{tab.icon}}</v-icon>
        </v-tab>
        <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route" :transition="false" :reverse-transition="false">
          <router-view></router-view>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>
<script>
// 分类：style 产品：devices
export default {
  data() {
    return {
      activeTab: '/dealer/system/asset/warehouse',
      tabs: [
        { id: 1, name: '库房管理', route: '/dealer/system/asset/warehouse', help: '库房是所属经销商的库房', icon: 'home_work' },
        { id: 2, name: '设备状态', route: '/dealer/system/asset/state', help: '设备状态', icon: 'rotate_right' }
      ],
    }
  },
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    goBack() {
      this.$router.push({path: '/dealer/system'});
    },
  },
  directives: {}
}
</script>
