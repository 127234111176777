<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>企业员工</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="doAction('search_employee')"
          dark
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索人员姓名、电话或者电子邮件地址"
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
      </v-toolbar>
      <v-alert dense type="info" class="ma-2" dismissible>
        企业员工为经销商提供维护所属企业的分组和人员资料管理。<span class="font-weight-bold">通过右侧筛选按钮选择一家企业后可以创建和导入员工。</span>
      </v-alert>
      <v-data-table
        v-model="selectedEmployee"
        :headers="headerEmployee"
        :items="employeeList"
        :options.sync="employee_options"
        :server-items-length="employeeCount"
        :loading="employeeLoading"
        :item-class="row_class"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
        item-key="_id"
        show-select
        transition
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title v-if="ownEnterpriseList.length">
              <v-icon class="mr-2">groups</v-icon>
              {{ query.enterprise ? ownEnterpriseList.find(item => item._id === query.enterprise).name : '全部人员列表'}}
            </v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-autocomplete
              v-if="query.enterprise"
              v-model="query.roles"
              :items="flatGroupRoles(groupList)"
              item-text="name"
              item-value="_id"
              autocomplete="off"
              solo
              dense
              hide-details
              label="请选择角色"
              append-outer-icon="add"
              @click:append-outer="doAction('create_role', query.enterprise, '创建角色')"
              @change="doAction('change_role')"
            >
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.name}}{{!data.item._id ? '' : ' - '+ data.item.count +'人'}}</v-list-item-title>
                    <v-list-item-subtitle v-if="data.item.type === 9">企业系统管理员拥有全部权限且不能修改</v-list-item-subtitle>
                    <v-list-item-subtitle v-else-if="data.item.type === 1">{{data.item.service.map(item => item.name)}}</v-list-item-subtitle>
                    <v-list-item-subtitle v-else-if="data.item.type === 0">{{data.item.service.map(item => item.name)}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action @click.stop>
                    <v-btn icon v-if="data.item._id && data.item.type !== 9" @click="doAction('edit_role', data.item, '修改角色')"><v-icon small >edit</v-icon></v-btn>
                  </v-list-item-action>
                </template>
              </template>
            </v-autocomplete>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" class="mx-2" depressed :disabled="!selectedEmployee.length">
                  批量执行
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="doAction('block_employee', query.enterprise, '注销员工')">
                  <v-list-item-icon>
                    <v-icon>remove_circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>注销员工-可恢复</v-list-item-title>
                </v-list-item>
                <v-list-item @click="doAction('delete_employee', query.enterprise, '删除员工')">
                  <v-list-item-icon>
                    <v-icon>delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>删除员工-不可恢复</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="secondary" depressed :disabled="!query.enterprise">
                  <v-icon>add</v-icon>
                  新建员工
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="doAction('create_employee', query.enterprise, '新增员工')">
                  <v-list-item-icon>
                    <v-icon>add_box</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>通过表单增加单个员工</v-list-item-title>
                </v-list-item>
                <v-list-item @click="doAction('import_employee', query.enterprise, '导入员工')">
                  <v-list-item-icon>
                    <v-icon>library_add</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>通过文件增加多个员工</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('filter_enterprise')">
                  <v-icon small>filter_list</v-icon>
                </v-btn>
              </template>
              <span>过滤数据</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item.personal.name="{ item }">
          <widgets-employee-dialogs :account="item._id" v-on:UpdateEmployee="getEmployeeList()">{{item.personal && item.personal.name || '*未设置姓名'}}</widgets-employee-dialogs>
        </template>
        <template v-slot:item.employer.ownerId="{ item }">
          <span @click="doAction('open_enterprise', item.employer.ownerId)" style="cursor: pointer;">{{item.employer.ownerId.name}}</span>
        </template>
        <template v-slot:item.employer.subordinate="{ item }">
          {{item.employer.subordinate || '-'}}
        </template>
        <template v-slot:item.employer.roles="{ item }">
          {{item.employer.roles.map(item => item.name)}}
        </template>
      </v-data-table>
    </v-card>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">选择企业</h2>
        <v-autocomplete
          autocomplete="off"
          v-model="query.enterprise"
          :items="ownEnterpriseList"
          @change="doAction('set_filter')"
          label="企业"
          hint="请选择一家企业"
          item-text="name"
          item-value="_id"
          outlined
          dense
        ></v-autocomplete>
        <h2 class="subtitle-2 mt-2">员工状态</h2>
        <v-chip-group v-model="query.isBlock" column multiple @change="doAction('set_filter')">
          <v-chip :value="false" filter label outlined>正常</v-chip>
          <v-chip :value="true" filter label outlined>已注销</v-chip>
        </v-chip-group>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDrawer = !filterDrawer">隐藏</v-btn>
          <v-btn color="secondary" text @click="doAction('clear_filter')">清空</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
      <widgets-enterprise-dialogs :id="activeId" :key="activeId" v-on:Close="doAction('close_info')"></widgets-enterprise-dialogs>
    </v-dialog>
    <v-dialog v-model="dlgEdit" persistent scrollable max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="dlgTitle === '创建角色' || dlgTitle === '修改角色'">
            <v-text-field
              v-model="roles.editedItem.name"
              :rules="[rules.required]"
              type="text"
              label="角色名称"
              hint="请输入角色名称"
              outlined
              dense
            ></v-text-field>
            <v-autocomplete
              v-model="roles.editedItem.service"
              :items="uniqueService(serviceList)"
              item-text="name"
              item-value="_id"
              autocomplete="off"
              label="请选择角色所含服务"
              outlined
              dense
              multiple
              chips
              small-chips
              deletable-chips
            ></v-autocomplete>
          </v-card-text>
          <v-card-text v-if="dlgTitle === '新增员工'">
            <v-container>
              <v-row align="start" justify="center">
                <v-col cols="2" class="text-center">
                  <input
                    id="files"
                    type="file"
                    name="file"
                    ref="uploadInput"
                    accept="image/*"
                    :multiple="false"
                    @change="detectFiles($event)"/>
                  <v-hover v-slot:default="{ hover }">
                    <v-avatar>
                      <v-img
                        :src="employee.editedItem.profile ? ossURL +'/'+ employee.editedItem.profile : '/static/grey_silhouette.png'"
                        alt="设置头像"
                        aspect-ratio="1">
                        <v-progress-circular
                          color="secondary"
                          rotate="360"
                          size="48"
                          width="5"
                          v-if="Upload.uploading"
                          :value="Upload.progressUpload">
                        </v-progress-circular>
                      </v-img>
                      <div v-if="hover" class="show_upload" style="height: 100%; cursor: pointer">
                        <v-icon @click="doAction('upload_avatar', employee.editedItem)" >add_a_photo</v-icon>
                      </div>
                    </v-avatar>
                  </v-hover>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.name"
                    :rules="[rules.required]"
                    type="text"
                    label="员工姓名"
                    hint="员工姓名为用户的真实姓名"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">https</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.username"
                    :rules="[rules.required, rules.email]"
                    :error-messages="employee.editedItem.msgName"
                    :clearable="employee.editedItem.msgName !== ''"
                    type="text"
                    label="电子邮件"
                    hint="电子邮件将作为登陆名称使用"
                    outlined
                    dense
                    required
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="employee.editedItem.chkName"
                          :width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-scale-transition>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">vpn_key</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.password"
                    :rules="[rules.required]"
                    :type="employee.editedItem.showPass ? 'text' : 'password'"
                    :append-icon="employee.editedItem.showPass ? 'visibility_off' : 'visibility'"
                    label="账号密码"
                    hint="请牢记此密码，密码将作为登陆凭证"
                    outlined
                    dense
                    required
                    @click:append="employee.editedItem.showPass = !employee.editedItem.showPass"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">stay_current_portrait</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.phone"
                    :rules="[rules.required, rules.phonenum]"
                    :error-messages="employee.editedItem.msgPhone"
                    :clearable="employee.editedItem.msgPhone !== ''"
                    type="number"
                    @mousewheel.native.prevent @DOMMouseScroll.native.prevent
                    label="手机号码"
                    hint="手机号码可作为登陆名称使用"
                    outlined
                    color="secondary"
                    dense
                    required
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="employee.editedItem.chkPhone"
                          :width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-scale-transition>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6" v-if="query.enterprise && ownEnterpriseList.find(e => e._id === query.enterprise).branch">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">account_tree</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="employee.editedItem.sub_corp"
                    :items="ownEnterpriseList.find(e => e._id === query.enterprise).branch"
                    autocomplete="off"
                    outlined
                    dense
                    label="请选择员工所属分公司"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">badge</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.subordinate"
                    type="text"
                    label="所属部门"
                    hint="请填写员工所属部门"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">room</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.address"
                    :rules="[rules.required]"
                    :error-messages="employee.editedItem.msgAddress"
                    :clearable="employee.editedItem.msgAddress !== ''"
                    type="text"
                    label="收件地址"
                    hint="收件地址为接收设备的目的地"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <v-fade-transition leave-absolute>
                        <v-progress-circular
                          v-if="employee.editedItem.chkAddress"
                          width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-fade-transition>
                    </template>
                    <template v-slot:append-outer>
                      <v-btn text @click="doAction('copy_address', currentEmployer)">复制公司地址</v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">supervisor_account</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="employee.editedItem.roles"
                    :items="flatInputRoles(groupList)"
                    :rules="[rules.selected]"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择所属角色"
                    multiple
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-action>
                          <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">integration_instructions</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.employeeNo"
                    type="text"
                    label="员工编号"
                    hint="员工编号为企业内部的员工编号"
                    outlined
                    color="secondary"
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6" v-for="(item, i) in customFields" :key="i">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">list</v-icon>
                </v-col>
                <v-col cols="10" v-if="item.format.type === 'text'">
                  <v-text-field
                    v-if="item.format.mask"
                    v-model="item.value"
                    v-mask="item.format.mask"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense>
                  </v-text-field>
                  <v-text-field
                    v-else
                    v-model="item.value"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="10" v-if="item.format.type === 'selects'">
                  <v-autocomplete
                    autocomplete="off"
                    v-model="item.value"
                    :items="item.format.items"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="dlgTitle === '导入员工'">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <p>1. 下载人员模版，填写成员信息.</p>
                  <v-btn text @click="doAction('download_template')">下载模版<v-icon>file_download</v-icon></v-btn>
                </v-col>
                <v-col cols="6">
                  <input
                    id="import_file"
                    type="file"
                    name="import_file"
                    ref="uploadImport"
                    accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :multiple="false"
                    @change="importFiles($event)"/>
                  <p>2. 上传填好的文件.</p>
                  <v-btn text @click="doAction('upload_employee')"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
                </v-col>
              </v-row>
              <v-row v-if="importEnd">
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-alert
                    outlined
                    type="error"
                    text
                  >
                    导入失败：<ul><li v-for="(fail,index) in importFailed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
                  </v-alert>
                  <v-alert
                    outlined
                    type="success"
                    text
                  >
                    成功导入：{{importSuccess}} 条记录
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="dlgTitle === '注销员工'">
            <v-alert type="info">注销用户：禁止账号登陆及平台相关操作，但保留账号相关信息，可随时恢复账号。</v-alert>
          </v-card-text>
          <v-card-text v-if="dlgTitle === '删除员工'">
            <v-alert type="error">删除用户：删除账号及用户资料，该操作无法恢复。</v-alert>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <widgets-custom-field module="Account" :ownerId="query.enterprise" ownerType="Enterprise" v-if="dlgTitle === '新增员工'" v-on:Complete="doAction('update_field')"></widgets-custom-field>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid" v-if="dlgTitle !== '导入员工'">提交</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import _ from 'lodash'
import axios from "axios";
import XLSX from 'xlsx'
import ApiService from "@/common/http";
import { mapGetters } from "vuex";
import moment from "moment";
import store from "@/store";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_EMPLOYEE_LIST,
  FETCH_GROUP_LIST,
  FETCH_SERVICE_LIST,
  CREATE_ROLE,
  EDIT_ROLE,
  FETCH_FIELDS,
  FETCH_UPLOAD,
  CHECK_USERNAME,
  CHECK_USERPHONE,
  CHECK_ADDR,
  CREATE_EMPLOYEE, FETCH_ENTERPRISE,
  BLOCK_EMPLOYEE, REMOVE_EMPLOYEE
} from "@/store/actions.type";
export default {
  data() {
    return {
      filterDrawer: false,
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      query: {
        key: '',
        enterprise: '',
        isBlock: [],
        roles: '',
      },
      defaultQuery: {
        key: '',
        enterprise: '',
        isBlock: [],
        roles: '',
      },
      headerEmployee: [
        { text: '姓名', value: 'personal.name', width: "125px" },
        { text: '电子邮件', value: 'username', width: "125px" },
        { text: '手机号码', value: 'phone', width: "155px" },
        { text: '企业', value: 'employer.ownerId', width: "255px" },
        { text: '所属部门', value: 'employer.subordinate', width: "155px" },
        { text: '角色', value: 'employer.roles', width: "255px" },
        { text: '员工编号', value: 'employer.employeeNo', width: "155px" },
        { text: '省份', value: 'personal.province', width: "155px" },
        { text: '城市', value: 'personal.city', width: "155px" },
      ],
      employee_options: {},
      dlgInfo: false,
      activeId: null,
      roles: {
        editedIndex: -1,
        editedItem: {
          name: '',
          service: [],
        },
        defaultItem: {
          name: '',
          service: [],
        }
      },
      employee: {
        editedItem: {
          _id: '',
          username: '',
          password: '',
          phone: '',
          name: '',
          profile: '',
          employeeNo: '',
          subordinate: '',
          sub_corp: '',
          address: '',
          roles: [],
          chkName: false,
          msgName: '',
          chkPhone: false,
          msgPhone: '',
          chkAddress: false,
          msgAddress: '',
          showPass: false,
        },
        defaultItem: {
          _id: '',
          username: '',
          password: '',
          phone: '',
          name: '',
          profile: '',
          employeeNo: '',
          subordinate: '',
          sub_corp: '',
          roles: [],
          address: '',
          chkName: false,
          msgName: '',
          chkPhone: false,
          msgPhone: '',
          chkAddress: false,
          msgAddress: '',
          showPass: false,
        },
      },
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      importExcelHeader: ['姓名', '电子邮件', '手机号码', '地址', '角色', '部门', '员工编号', '所属分公司'],
      importExcelNotice: [['多个角色用,(英文逗号）分割']],
      importSuccess: 0,
      importFailed: [],
      importEnd: false,
      selectedEmployee:[],
    };
  },
  created() {
    this.getEmployeeList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "employeeLoading",
      "employeeList",
      "employeeCount",
      "ownEnterpriseList",
      "groupList",
      "serviceList",
      "customFields",
      "enterprise",
      "uploadSign"]),
  },
  watch: {
    employee_options: {
      handler() {
        this.getEmployeeList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
    'employee.editedItem.username'() {
      this.checkName();
    },
    'employee.editedItem.phone'() {
      this.checkPhone();
    },
    'employee.editedItem.address'() {
      this.checkAddress();
    },
  },
  mounted() {},
  methods: {
    getEmployeeList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.employee_options;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (query.key) urlQuery += '&key='+ query.key
      if (query.enterprise) {
        urlQuery += '&enterprise='+ query.enterprise
        this.getEnterpriseInfo(query.enterprise)
      }
      if (query.roles) urlQuery += '&roles='+ query.roles
      if (query.isBlock && query.isBlock.length && query.isBlock.length <= 1) urlQuery += '&isBlock='+ query.isBlock
      if (Object.keys(this.employee_options).length > 0) {
        store.dispatch(FETCH_EMPLOYEE_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    getEnterpriseInfo(eid) {
      store.dispatch(FETCH_ENTERPRISE, eid)
      store.dispatch(FETCH_GROUP_LIST, 'owner='+ eid)
      store.dispatch(FETCH_SERVICE_LIST, eid)
    },
    getFieldList(eid, mod = 'Account') {
      this.$store.dispatch(FETCH_FIELDS, eid+'/'+mod)
        .then(() => {
          addModelName(this.customFields)
        })
    },
    doAction(action, item = {}, customitem) {
      switch (action) {
        case 'search_employee': {
          if (this.query.key !== '') {
            this.getEmployeeList({key: this.query.key})
          } else {
            this.getEmployeeList()
          }
          break
        }
        case 'open_enterprise': {
          this.activeId = item._id
          this.dlgInfo = true
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          break
        }
        case 'filter_enterprise': {
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'set_filter': {
          this.$router.push({ query: {} });
          if (this.query.enterprise) {
            this.getEnterpriseInfo(this.query.enterprise)
          }
          this.getEmployeeList(this.query)
          // this.filterDrawer = !this.filterDrawer
          break
        }
        case 'clear_filter': {
          this.query = Object.assign({}, this.defaultQuery)
          this.$router.push({ query: {} });
          this.getEmployeeList()
          // this.filterDrawer = !this.filterDrawer
          break
        }
        case 'change_role': {
          this.getEmployeeList(this.query)
          break
        }
        case 'edit_role': {
          this.roles.editedIndex = item._id
          this.roles.editedItem = Object.assign({}, item)
          this.roles.editedItem.service = this.roles.editedItem.service.map(item => item._id)
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
        case 'update_field': {
          this.getFieldList(this.query.enterprise, 'Account')
          break;
        }
        case 'create_employee': {
          if (this.query.enterprise) {
            this.getFieldList(this.query.enterprise, 'Account')
            this.dlgTitle = customitem
            this.dlgEdit = true
          } else {
            store.commit(SET_ERROR, {msg: '请选择一家企业后创建人员。'})
          }
          break
        }
        case 'upload_avatar': {
          store.dispatch(FETCH_UPLOAD)
            .then(this.$refs.uploadInput.click())
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
          break;
        }
        case 'copy_address': {
          if (this.enterprise.address) {
            this.employee.editedItem.address = this.enterprise.address
          } else {
            this.employee.editedItem.address = item.ownerId.address
          }
          break
        }
        case 'upload_employee': {
          this.$refs.uploadImport.click()
          break;
        }
        case 'download_template': {
          this.$store.dispatch(FETCH_FIELDS, this.query.enterprise+ '/Account')
            .then(() => {
              this.customFields.forEach(item => {
                if (!this.importExcelHeader.includes(item.name)) this.importExcelHeader.push(item.name)
              })

              let ws = XLSX.utils.aoa_to_sheet(this.importExcelNotice)
              XLSX.utils.sheet_add_json(ws, [
                {'姓名': '张力-测试数据可删除此行', '电子邮件': 'li.zhang@youcomany.com', '手机号码': 13800138000, '地址': '北京市海淀区东北旺西路8号院尚东数字山谷B区2号楼一层105室', '角色': this.groupList[0].roles[0].name, '部门': '开发部','员工编号': 'A001'}
              ], {header: this.importExcelHeader, origin: 'A2'})
              const merge = [
                {s: {r:0, c:0}, e: {r:0, c:this.importExcelHeader.length - 1}}
              ]
              ws['!merges'] = merge
              ws.A1.s = {alignment:{ wrapText: true }};
              let wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, ws, 'Import_Employee')
              XLSX.writeFile(wb, this.ownEnterpriseList.find(item => item._id === this.query.enterprise).name +'_人员模版.xlsx')
            })
          break;
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    close () {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.$nextTick(() => {
        this.selectedEmployee = []
        this.roles.editedIndex = -1
        this.roles.editedItem = Object.assign({}, this.roles.defaultItem)
        this.employee.editedItem = Object.assign({}, this.employee.defaultItem)

      })
    },
    submit() {
      switch (this.dlgTitle) {
        case '创建角色': case '修改角色': {
          let strInsert = {}
          strInsert.name = this.roles.editedItem.name
          strInsert.service = this.roles.editedItem.service
          strInsert.ownerId = this.query.enterprise
          strInsert.ownerType = 'Enterprise'
          strInsert.group = this.groupList[0]._id

          if (this.roles.editedIndex !== -1) {
            strInsert._id = this.roles.editedIndex

            store.dispatch(EDIT_ROLE, strInsert)
              .then(()=> {
                store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
                this.getEnterpriseInfo(this.query.enterprise)
                this.getEmployeeList(this.query)
                this.close()
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          } else {
            store.dispatch(CREATE_ROLE, strInsert)
              .then(()=> {
                store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
                this.getEnterpriseInfo(this.query.enterprise)
                this.close()
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          }
          break
        }
        case '新增员工': {
          let strInsert = {}
          strInsert['personal'] = {}
          strInsert['employer'] = {}
          strInsert['customFields'] = []
          strInsert.username = this.employee.editedItem.username
          strInsert.password = this.employee.editedItem.password
          strInsert.phone = this.employee.editedItem.phone
          strInsert.ownerType = 'Enterprise'
          strInsert.ownerId = this.query.enterprise
          strInsert['employer'].roles = this.employee.editedItem.roles
          if (this.customFields.length) {
            this.customFields.forEach(item => {
              strInsert['customFields'].push({ _id: item._id, value: item.value })
            })
          }
          if (this.employee.editedItem.name) strInsert['personal'].name = this.employee.editedItem.name
          if (this.employee.editedItem.profile) strInsert['personal'].profile = this.employee.editedItem.profile
          if (this.employee.editedItem.address) strInsert['personal'].address = this.employee.editedItem.address
          if (this.employee.editedItem.employeeNo) strInsert['employer'].employeeNo = this.employee.editedItem.employeeNo;
          if (this.employee.editedItem.sub_corp) strInsert['employer'].sub_corp = this.employee.editedItem.sub_corp;
          if (this.employee.editedItem.subordinate) strInsert['employer'].subordinate = this.employee.editedItem.subordinate;
          if (this.employee.editedItem.addressInfo) {
            strInsert.addressInfo = this.employee.editedItem.addressInfo
          } else {
            this.$store.commit(SET_ERROR, {msg: '请等待地址验证中...', color: 'secondary', timeout: 500});
            return
          }

          this.$store.dispatch(CREATE_EMPLOYEE, strInsert)
            .then(() => {
              this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
              this.getEnterpriseInfo(this.query.enterprise)
              this.getEmployeeList(this.query)
              this.close()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
        case '注销员工': {
          store.dispatch(BLOCK_EMPLOYEE, {ids: this.selectedEmployee.map(o => o._id)})
            .then(() => {
              store.commit(SET_ERROR, {msg: '修改完成', color: 'primary'});
              this.getEmployeeList(this.query)
              this.close()
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
        case '删除员工': {
          store.dispatch(REMOVE_EMPLOYEE, {ids: this.selectedEmployee.map(o => o._id)})
            .then(() => {
              store.commit(SET_ERROR, {msg: '修改完成', color: 'primary'});
              this.getEmployeeList(this.query)
              this.close()
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
      }
    },
    importFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importEmployee(fileList[x]);
      })
    },
    importEmployee(file) {
      let param = new FormData();
      param.append('file', file);
      param.append('ownerType', 'Enterprise');
      param.append('ownerId', this.query.enterprise);
      ApiService.post('/employee/import', param)
        .then((data) => {
          this.importSuccess = data.data.Success
          this.importFailed = data.data.Failed
          this.$refs.uploadImport.value = '';
          this.importEnd = true
        })
        .catch((err) => {
          this.$refs.uploadImport.value = '';
          this.importSuccess = 0
          this.importFailed = []
          this.importEnd = true
          let arrErr = [];
          if (err.response.data) {
            if (err.response.data.code === 11000) {
              arrErr = err.response.data.writeErrors.map(obj => { return obj.op.personal.name });
            }
          }
          if (arrErr.length > 0) {
            this.$store.commit(SET_ERROR, {msg: '请检查以下员工数据：'+ arrErr});
          } else {
            this.importFailed = [{line: 2, error: err.response.data.message}]
          }
        });
      this.getEnterpriseInfo(this.query.enterprise)
      this.getEmployeeList(this.query)
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.query.enterprise +'/avatar/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.employee.editedItem.profile = this.query.enterprise +'/avatar/'+ this.uploadSign.key;
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    checkName: _.debounce(function () {
      if (this.employee.editedItem.username) {
        this.employee.editedItem.chkName = true;
        this.$store.dispatch(CHECK_USERNAME, this.employee.editedItem.username)
          .then(() => {
            this.employee.editedItem.msgName = '电子邮件已被注册，请更换其它地址';
            this.employee.editedItem.chkName = false;
          })
          .catch(() => {
            this.employee.editedItem.msgName = '';
            this.employee.editedItem.chkName = false;
          })
      }
    }, 1000),
    checkPhone: _.debounce(function () {
      if (this.employee.editedItem.phone) {
        this.employee.editedItem.chkPhone = true;
        this.$store.dispatch(CHECK_USERPHONE, this.employee.editedItem.phone)
          .then(() => {
            this.employee.editedItem.msgPhone = '手机号码已被注册，请更换其它号码';
            this.employee.editedItem.chkPhone = false;
          })
          .catch(() => {
            this.employee.editedItem.msgPhone = '';
            this.employee.editedItem.chkPhone = false;
          })
      }
    }, 1000),
    checkAddress: _.debounce(function () {
      if (this.employee.editedItem.address) {
        this.employee.editedItem.chkAddress = true;
        this.$store.dispatch(CHECK_ADDR, this.employee.editedItem.address)
          .then((data) => {
            if (data.status !== 0 || data.result.reliability < 7) {
              this.employee.editedItem.msgAddress = '地址信息错误，请输入正确的地址信息';
            } else {
              this.employee.editedItem.addressInfo = data.result;
              this.employee.editedItem.msgAddress = '';
            }
            this.employee.editedItem.chkAddress = false;
          })
          .catch((err) => {
            this.employee.editedItem.msgAddress = err.data.message;
            this.employee.editedItem.chkAddress = false;
          })
      }
    }, 500),
    flatInputRoles(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.roles.forEach((rolesItem) => {
          arrRoles.push({ _id: rolesItem._id, name: rolesItem.name, group: groupItem.name })
        })
      })
      return arrRoles
    },
    flatGroupRoles(arrGroup) {
      let arrRoles = []
      arrRoles.push({ _id: '', name: '全部', group: ''})
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.roles.forEach((rolesItem) => {
          arrRoles.push({ _id: rolesItem._id, name: rolesItem.name, count: rolesItem.employeeCount, group: groupItem.name, type: rolesItem.type, service: rolesItem.service })
        })
      })
      return arrRoles
    },
    uniqueService(arrList) {
      let arrService = []
      if (arrList.length) {
        arrList = arrList.filter(item => moment().isBefore(item.end_time))
        arrList.forEach(service => {
          service.catalog.forEach(catalog => {
            if (!arrService.find(findItem => findItem._id === catalog._id)) {
              arrService.push({_id: catalog._id, name: catalog.name, end_time: service.end_time, icon: catalog.profileIcon})
            }
          })
        })
      }
      return arrService
    },
    row_class(item) {
      if (item.isBlock) {
        return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
      }
    }
  },
  directives: {}
};
function addModelName(arr) {
  arr.forEach(obj => {
    obj.value = ''
  })
}
</script>
<style scoped>
  .show_upload {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
</style>

