var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.typeList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.typeCount,
      "loading": _vm.typeLoading,
      "item-class": _vm.itemRowBackground,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "fixed-header": "",
      "height": "600",
      "item-key": "name",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("产品类别列表")]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('create_item', '', '新增产品类别');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("add")]), _vm._v(" 新建类别 ")], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.noCount",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.noCount ? '是' : '否') + " ")];
      }
    }, {
      key: "item.noSN",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.noSN ? '是' : '否') + " ")];
      }
    }, {
      key: "item.customFields",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.customFields.map(function (field) {
          return field.name;
        })) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('edit_item', item, '编辑产品类别');
            }
          }
        }, [_vm._v("edit")]), !item.isEnable ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_item', item, true);
            }
          }
        }, [_vm._v("check_circle_outline")]) : _vm._e(), item.isEnable ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_item', item, false);
            }
          }
        }, [_vm._v("block")]) : _vm._e()];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("Reset")])];
      },
      proxy: true
    }])
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "类别名称",
      "hint": "请输入产品类别名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" 不参与设备统计 "), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mx-2",
                attrs: {
                  "color": "",
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" help_outline ")])];
            }
          }])
        }, [_c('span', [_vm._v("设备统计数据将统计全部产品类别，不需要加入统计数据的如配件等，请打开此项目。")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.editedItem.noCount,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "noCount", $$v);
      },
      expression: "editedItem.noCount"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" 不需要序列号 "), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mx-2",
                attrs: {
                  "color": "",
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" help_outline ")])];
            }
          }])
        }, [_c('span', [_vm._v("创建设备资料时需要产品的序列号，不需要序列号时，请打开此项目。")])])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.editedItem.noSN,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "noSN", $$v);
      },
      expression: "editedItem.noSN"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("固定字段")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm._v("产品名称、部件代码、型号、供应商、成本、保修期、图片、备注")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("自定义字段")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, _vm._l(_vm.editedItem.customFields, function (item, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "mx-2",
      attrs: {
        "small": "",
        "close": ""
      },
      on: {
        "click:close": function clickClose($event) {
          return _vm.deleteField(index);
        }
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('widgets-product-field', {
    on: {
      "Complete": _vm.addField
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }