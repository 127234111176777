var render = function () {
  var _vm$curRiskEnterprise;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("授信列表")]), _c('v-divider', {
    staticClass: "mx-4",
    attrs: {
      "inset": "",
      "vertical": ""
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "关键字搜索（按回车）",
      "prepend-inner-icon": "search",
      "background-color": _vm.$vuetify.theme.dark ? '' : 'grey lighten-4',
      "solo": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchItem.apply(null, arguments);
      },
      "click:clear": _vm.clearSearchItem
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  }), _c('v-spacer'), _c('v-menu', {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "ma-2",
          attrs: {
            "depressed": "",
            "color": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('create_item');
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("add")]), _vm._v(" 风控计算 ")], 1)];
      }
    }])
  }), _c('v-divider', {
    staticClass: "mx-4",
    attrs: {
      "inset": "",
      "vertical": ""
    }
  }), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.openFilter.apply(null, arguments);
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("filter_list")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("过滤数据")])])], 1), _c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', [_vm._v("集团授信")]), _c('v-tab', [_vm._v("企业授信")]), _c('v-tab-item', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerTableEntGroup,
      "items": _vm.riskEntGroupList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.riskEntGroupCount,
      "loading": _vm.riskEntGroupLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "fixed-header": "",
      "height": "600",
      "item-key": "_id",
      "transition": "",
      "single-expand": true,
      "show-expand": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      },
      "item-expanded": _vm.onRowExpanded
    },
    scopedSlots: _vm._u([{
      key: "expanded-item",
      fn: function fn(_ref3) {
        var headers = _ref3.headers,
            item = _ref3.item;
        return [_c('td', {
          staticStyle: {
            "padding": "16px"
          },
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-data-table', {
          attrs: {
            "headers": _vm.headerTable1,
            "items": _vm.riskEnterpriseList1,
            "options": _vm.optionsTable1,
            "server-items-length": _vm.riskEnterpriseCount1,
            "loading": _vm.riskEnterpriseLoading1,
            "loading-text": "数据读取中... 请稍后",
            "footer-props": {
              'items-per-page-options': [5, 10, 15, 30, 50, 100]
            },
            "fixed-header": "",
            "item-key": "_id",
            "transition": ""
          },
          on: {
            "update:options": function updateOptions($event) {
              _vm.optionsTable1 = $event;
            }
          },
          scopedSlots: _vm._u([{
            key: "item.model.businessType",
            fn: function fn(_ref4) {
              var _vm$businessTypeList$;

              var item = _ref4.item;
              return [_c('td', {
                attrs: {
                  "nowrap": "true"
                }
              }, [_vm._v(_vm._s((_vm$businessTypeList$ = _vm.businessTypeList.find(function (o) {
                var _item$model;

                return o.code === ((_item$model = item.model) === null || _item$model === void 0 ? void 0 : _item$model.businessType);
              })) === null || _vm$businessTypeList$ === void 0 ? void 0 : _vm$businessTypeList$.name))])];
            }
          }, {
            key: "item.updateTime",
            fn: function fn(_ref5) {
              var item = _ref5.item;
              return [_c('td', {
                attrs: {
                  "nowrap": "true"
                }
              }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
            }
          }, {
            key: "item.actions",
            fn: function fn(_ref6) {
              var item = _ref6.item;
              return [_c('v-menu', {
                attrs: {
                  "offset-y": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref7) {
                    var on = _ref7.on,
                        attrs = _ref7.attrs;
                    return [_c('v-btn', _vm._g(_vm._b({
                      attrs: {
                        "color": "primary",
                        "dark": "",
                        "icon": "",
                        "small": ""
                      }
                    }, 'v-btn', attrs, false), on), [_c('v-icon', {
                      attrs: {
                        "small": ""
                      }
                    }, [_vm._v("more_vert")])], 1)];
                  }
                }], null, true)
              }, [_c('v-list', {
                attrs: {
                  "dense": ""
                }
              }, [_c('v-list-item', {
                staticClass: "v-list-item--ink",
                on: {
                  "click": function click($event) {
                    return _vm.doAction('detail', item);
                  }
                }
              }, [_c('v-list-item-icon', [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("edit_note")])], 1), _c('v-list-item-title', [_vm._v("计算详情")])], 1), _c('v-list-item', {
                staticClass: "v-list-item--ink",
                on: {
                  "click": function click($event) {
                    return _vm.doAction('refresh', item);
                  }
                }
              }, [_c('v-list-item-icon', [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("autorenew")])], 1), _c('v-list-item-title', [_vm._v("刷新额度")])], 1), _c('v-list-item', {
                staticClass: "v-list-item--ink",
                on: {
                  "click": function click($event) {
                    return _vm.doAction('adjust', item);
                  }
                }
              }, [_c('v-list-item-icon', [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("adjust")])], 1), _c('v-list-item-title', [_vm._v("调整额度")])], 1), _c('v-list-item', {
                staticClass: "v-list-item--ink",
                on: {
                  "click": function click($event) {
                    return _vm.doAction('logs', item);
                  }
                }
              }, [_c('v-list-item-icon', [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("history")])], 1), _c('v-list-item-title', [_vm._v("变更记录")])], 1)], 1)], 1)];
            }
          }, {
            key: "no-data",
            fn: function fn() {
              return [_c('v-btn', {
                attrs: {
                  "color": "primary",
                  "depressed": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.getDataList();
                  }
                }
              }, [_vm._v("刷新")])];
            },
            proxy: true
          }], null, true)
        })], 1)];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref10) {
              var on = _ref10.on,
                  attrs = _ref10.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "small": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.doAction('adjustGroup', item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("adjust")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("调整集团额度")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref11) {
              var on = _ref11.on,
                  attrs = _ref11.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "small": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.doAction('showLog', item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v("history")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("变更记录")])])], 1)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("刷新")])];
      },
      proxy: true
    }])
  })], 1), _c('v-tab-item', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.riskEnterpriseList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.riskEnterpriseCount,
      "loading": _vm.riskEnterpriseLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "fixed-header": "",
      "height": "600",
      "item-key": "_id",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.model.businessType",
      fn: function fn(_ref12) {
        var _vm$businessTypeList$2;

        var item = _ref12.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s((_vm$businessTypeList$2 = _vm.businessTypeList.find(function (o) {
          return o.code === item.model.businessType;
        })) === null || _vm$businessTypeList$2 === void 0 ? void 0 : _vm$businessTypeList$2.name))])];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref15) {
              var on = _ref15.on,
                  attrs = _ref15.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "primary",
                  "dark": "",
                  "icon": "",
                  "small": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("more_vert")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          staticClass: "v-list-item--ink",
          on: {
            "click": function click($event) {
              return _vm.doAction('detail', item);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("edit_note")])], 1), _c('v-list-item-title', [_vm._v("计算详情")])], 1), _c('v-list-item', {
          staticClass: "v-list-item--ink",
          on: {
            "click": function click($event) {
              return _vm.doAction('refresh', item);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("autorenew")])], 1), _c('v-list-item-title', [_vm._v("刷新额度")])], 1), _c('v-list-item', {
          staticClass: "v-list-item--ink",
          on: {
            "click": function click($event) {
              return _vm.doAction('adjust', item);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("adjust")])], 1), _c('v-list-item-title', [_vm._v("调整额度")])], 1), _c('v-list-item', {
          staticClass: "v-list-item--ink",
          on: {
            "click": function click($event) {
              return _vm.doAction('logs', item);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("history")])], 1), _c('v-list-item-title', [_vm._v("变更记录")])], 1), _c('v-list-item', {
          staticClass: "v-list-item--ink",
          on: {
            "click": function click($event) {
              return _vm.doAction('syncKingdee', item);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("sync_alt")])], 1), _c('v-list-item-title', [_vm._v("同步金蝶")])], 1)], 1)], 1)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("刷新")])];
      },
      proxy: true
    }])
  }), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("风控模型")]), _c('v-autocomplete', {
    staticClass: "mt-2",
    attrs: {
      "items": _vm.riskModelList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "风控模型",
      "hint": "请选择风控模型",
      "chips": "",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.modelId,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "modelId", $$v);
      },
      expression: "query.modelId"
    }
  })], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.dlgTitle) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.dlgTitle === '风控计算' ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.enterpriseList,
      "loading": _vm.isEnterpriseLoading,
      "search-input": _vm.searchEnterprise,
      "rules": [_vm.rules.required, _vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择企业"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchEnterprise = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchEnterprise = $event;
      }
    },
    model: {
      value: _vm.editedItem.enterpriseId,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "enterpriseId", $$v);
      },
      expression: "editedItem.enterpriseId"
    }
  }) : _vm._e(), _vm.dlgTitle === '风控计算' ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.riskModelList,
      "rules": [_vm.rules.required, _vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择风控模型"
    },
    model: {
      value: _vm.editedItem.modelId,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "modelId", $$v);
      },
      expression: "editedItem.modelId"
    }
  }) : _vm._e(), _vm.dlgTitle === '风控计算' ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.riskEnterpriseTypeList,
      "rules": [_vm.rules.required, _vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择企业授信类型"
    },
    model: {
      value: _vm.editedItem.enterpriseTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "enterpriseTypeId", $$v);
      },
      expression: "editedItem.enterpriseTypeId"
    }
  }) : _vm._e(), _vm.dlgTitle === '调整授信额度' || _vm.dlgTitle === '调整集团授信额度' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.selected],
      "type": "number",
      "label": "授信额度（元）",
      "hint": "请输入授信额度",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.creditLine,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "creditLine", $$v);
      },
      expression: "editedItem.creditLine"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.dlgTitle === '风控计算' ? '计算' : '确定'))])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgLog,
      callback: function callback($$v) {
        _vm.dlgLog = $$v;
      },
      expression: "dlgLog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 变更记录 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeLog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('risk-action-logs', {
    key: _vm.dateKey,
    attrs: {
      "id": _vm.entGroupId
    }
  })], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgKingdee,
      callback: function callback($$v) {
        _vm.dlgKingdee = $$v;
      },
      expression: "dlgKingdee"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 同步金蝶信用额度 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeKingdee
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('div', [_vm._v(" 企业名称：" + _vm._s((_vm$curRiskEnterprise = _vm.curRiskEnterprise.enterprise) === null || _vm$curRiskEnterprise === void 0 ? void 0 : _vm$curRiskEnterprise.name) + " ")]), _c('div', [_vm._v(" 授信总额度：" + _vm._s(_vm.curRiskEnterprise.totalCreditLine) + " ")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.headerTableKingdee,
      "items": _vm.kingdeeCustomerList,
      "options": _vm.optionsTableKingdee,
      "server-items-length": _vm.kingdeeCustomerCount,
      "loading": _vm.kingdeeCustomerLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "hide-default-footer": true,
      "fixed-header": "",
      "item-key": "_id",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTableKingdee = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.creditLine",
      fn: function fn(props) {
        return [_c('v-edit-dialog', {
          attrs: {
            "return-value": props.item.creditLine
          },
          on: {
            "update:returnValue": function updateReturnValue($event) {
              return _vm.$set(props.item, "creditLine", $event);
            },
            "update:return-value": function updateReturnValue($event) {
              return _vm.$set(props.item, "creditLine", $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "input",
            fn: function fn() {
              return [_c('v-text-field', {
                attrs: {
                  "type": "number",
                  "label": "授信额度",
                  "single-line": ""
                },
                model: {
                  value: props.item.creditLine,
                  callback: function callback($$v) {
                    _vm.$set(props.item, "creditLine", $$v);
                  },
                  expression: "props.item.creditLine"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm._v(" " + _vm._s(props.item.creditLine)), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("edit")])], 1)];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }])
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeKingdee
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": _vm.submitKingdee
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }