var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-toolbar', {
    attrs: {
      "color": "secondary",
      "dark": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("系统设置")]), _c('v-spacer')], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 该模块为经销商端系统配置及运维集中管理的区域，仅向经销商端具有相应权限的员工开发使用。 ")]), _c('v-row', {
    staticClass: "fill-height overflow-y-auto"
  }, [_vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Catalog') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/catalog.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 服务提供 ")]), _c('v-card-text', [_vm._v("经销商提供的服务目录配置")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/dealer/system/catalog'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Workflow') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/workflow.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 流程配置 ")]), _c('v-card-text', [_vm._v("标准服务流程配置与管理")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('div', {
    staticClass: "text-truncate"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/workflow/process"
    }
  }, [_vm._v("流程管理")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/workflow/task"
    }
  }, [_vm._v("任务管理")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "link": "",
      "to": "/dealer/system/workflow/process"
    }
  }, [_c('v-icon', [_vm._v("arrow_forward")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Organize') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/organize.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 组织权限 ")]), _c('v-card-text', [_vm._v("经销商公司内部人员及组织及权限管理")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('div', {
    staticClass: "text-truncate"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/organize/department"
    }
  }, [_vm._v("组织架构")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/organize/roles"
    }
  }, [_vm._v("角色权限")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "link": "",
      "to": "/dealer/system/organize/department"
    }
  }, [_c('v-icon', [_vm._v("arrow_forward")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Branch') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/store.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 门店管理 ")]), _c('v-card-text', [_vm._v("经销商所属门店及驻场团队管理。")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/dealer/system/branch'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Asset') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/asset.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 资产配置 ")]), _c('v-card-text', [_vm._v("产品、库房及供应商基础配置")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('div', {
    staticClass: "text-truncate"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/asset/warehouse"
    }
  }, [_vm._v("仓库管理")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/asset/state"
    }
  }, [_vm._v("资产状态")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "link": "",
      "to": "/dealer/system/asset/warehouse"
    }
  }, [_c('v-icon', [_vm._v("arrow_forward")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Product') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/product.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 产品管理 ")]), _c('v-card-text', [_vm._v("ESP平台中各类产品信息的维护。")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('div', {
    staticClass: "text-truncate"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/product/type"
    }
  }, [_vm._v("产品类别")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/product/vendor"
    }
  }, [_vm._v("品牌厂商")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/product/list"
    }
  }, [_vm._v("产品列表")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "link": "",
      "to": "/dealer/system/product/list"
    }
  }, [_c('v-icon', [_vm._v("arrow_forward")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_RiskAdmin') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/risk.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 风控管理 ")]), _c('v-card-text', [_vm._v("对企业授信使用的风控模型进行维护和管理。")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('div', {
    staticClass: "text-truncate"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/risk/enterprise"
    }
  }, [_vm._v("授信管理")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/risk/model"
    }
  }, [_vm._v("模型")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/risk/citylevel"
    }
  }, [_vm._v("区域")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/risk/economy"
    }
  }, [_vm._v("经济数据")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/risk/enterprisetype"
    }
  }, [_vm._v("授信类型")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "link": "",
      "to": "/dealer/system/risk/enterprise"
    }
  }, [_c('v-icon', [_vm._v("arrow_forward")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Knowledge') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/knowledge.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 知识配置 ")]), _c('v-card-text', [_vm._v("供企业端发起咨询和检索知识的分组及目录管理。")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/dealer/system/knowledge'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Fields') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/fields.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 自定义字段 ")]), _c('v-card-text', [_vm._v("经销商可以维护自身和所服务企业的自定义字段信息。")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/dealer/system/fields'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Lease_Product') || _vm.currentUser.permission.includes('Lease_Admin') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/rent.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 租赁管理 ")]), _c('v-card-text', [_vm._v("对租赁的产品、默认参数等设置。")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('div', {
    staticClass: "text-truncate"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/rent/parameter"
    }
  }, [_vm._v("默认参数")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/rent/template"
    }
  }, [_vm._v("报价模版")]), _c('v-btn', {
    attrs: {
      "text": "",
      "link": "",
      "to": "/dealer/system/rent/industry"
    }
  }, [_vm._v("行业字典")])], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "link": "",
      "to": "/dealer/system/rent/parameter"
    }
  }, [_c('v-icon', [_vm._v("arrow_forward")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('System_Logs') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/logs.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 系统日志 ")]), _c('v-card-text', [_vm._v("系统操作日志")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/dealer/system/logs'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/bug.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 问题反馈 ")]), _c('v-card-text', [_vm._v("系统BUG和建议反馈")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/dealer/system/bugs'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/release.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 发行说明 ")]), _c('v-card-text', [_vm._v("系统版本说明")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/dealer/system/release'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-img', {
    staticClass: "ma-2",
    attrs: {
      "src": "/static/system/programming.svg",
      "height": "100px",
      "contain": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card-title', [_vm._v(" 测试工具 ")]), _c('v-card-text', [_vm._v("各种管理员使用小工具集合，不断补充...")])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/dealer/system/tools'
        });
      }
    }
  }, [_c('v-spacer'), _c('v-icon', {
    staticClass: "subheading font-weight-light"
  }, [_vm._v("arrow_forward")])], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }