var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "secondary white--text headline"
  }, [_vm._v(" 经销商管理 "), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "dark": "",
      "flat": "",
      "hide-details": "",
      "label": "搜索经销商名称",
      "prepend-inner-icon": "search",
      "solo-inverted": "",
      "dense": ""
    },
    on: {
      "keyup": function keyup($event) {
        _vm.dealer_list;
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-btn', {
    attrs: {
      "depressed": "",
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/admin/dealer/edit/0'
        });
      }
    }
  }, [_c('v-icon', [_vm._v("add")]), _vm._v(" 注册经销商")], 1)], 1), _c('v-tabs', {
    attrs: {
      "background-color": "white",
      "color": "secondary",
      "right": ""
    }
  }, [_c('v-tab', {
    on: {
      "click": function click($event) {
        _vm.citys = '';
      }
    }
  }, [_vm._v("全部")]), _vm._l(_vm.dealer_city, function (city, i) {
    return _c('v-tab', {
      key: i,
      on: {
        "click": function click($event) {
          _vm.citys = city;
        }
      }
    }, [_vm._v(_vm._s(city || '未知'))]);
  }), _vm._l(_vm.dealer_city.length + 1, function (n) {
    return _c('v-tab-item', {
      key: n
    }, [_c('v-container', {
      attrs: {
        "fluid": ""
      }
    }, [_c('v-row', _vm._l(_vm.dealer_list.filter(function (n) {
      return n.city === _vm.citys || _vm.citys === '';
    }), function (item, j) {
      return _c('v-col', {
        key: j,
        attrs: {
          "cols": "12",
          "md": "4"
        }
      }, [_c('v-skeleton-loader', {
        attrs: {
          "loading": _vm.dealerLoading,
          "type": "card"
        }
      }, [_c('v-card', {
        staticClass: "mx-auto",
        attrs: {
          "max-width": "360",
          "outlined": ""
        }
      }, [_c('v-img', {
        staticClass: "white--text align-end",
        attrs: {
          "src": item.profileImage ? _vm.ossURL + '/' + item.profileImage : _vm.ossURL + '/noimage.jpg',
          "aspect-ratio": 16 / 9,
          "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
          "dark": ""
        }
      }, [_c('v-card-title', {
        staticClass: "text-right justify-end"
      }, [_c('v-icon', {
        on: {
          "click": function click($event) {
            return _vm.action('set_bookmark', item);
          }
        }
      }, [_vm._v(_vm._s(item.isDefault ? 'bookmark' : 'bookmark_border'))])], 1), _c('v-card-title', {
        staticClass: "text-center justify-center"
      }, [_c('v-img', {
        attrs: {
          "src": item.logoImage ? _vm.ossURL + '/' + item.logoImage : _vm.ossURL + '/noimage.jpg',
          "aspect-ratio": "1",
          "contain": "",
          "max-width": "55"
        }
      })], 1), _c('v-card-title', {
        staticClass: "text-center justify-center"
      }, [_c('div', {
        staticClass: "hideText"
      }, [_vm._v(_vm._s(item.sub_title || '未设置标语'))])])], 1), _c('v-card-title', [_c('div', {
        staticClass: "hideText"
      }, [_vm._v(_vm._s(item.name))])]), _c('v-card-subtitle', [_c('v-icon', {
        staticClass: "mr-1",
        attrs: {
          "color": "primary",
          "small": ""
        }
      }, [_vm._v("people")]), _c('span', {
        staticClass: "subheading mr-2"
      }, [_vm._v(_vm._s(item.employeeCount || 0))])], 1), _c('v-card-text', [_c('div'), _c('div', {
        staticClass: "hideText"
      }, [_vm._v(_vm._s(item.address || '地址未设置'))])]), _c('v-divider'), _c('v-card-actions', [item.isBlock ? _c('v-chip', {
        staticClass: "ma-2",
        attrs: {
          "small": "",
          "outlined": "",
          "label": ""
        }
      }, [_vm._v("暂停运营")]) : item.status === 1 ? _c('v-chip', {
        staticClass: "ma-2",
        attrs: {
          "small": "",
          "outlined": "",
          "label": ""
        }
      }, [_vm._v("待审核")]) : _c('v-chip', {
        staticClass: "ma-2",
        attrs: {
          "small": "",
          "label": ""
        }
      }, [_vm._v("正常运营")]), _c('v-spacer'), item.isBlock ? _c('v-btn', {
        attrs: {
          "text": ""
        },
        on: {
          "click": function click($event) {
            return _vm.action('recover', item);
          }
        }
      }, [_vm._v("恢复运营")]) : item.status === 1 ? _c('v-btn', {
        attrs: {
          "text": ""
        },
        on: {
          "click": function click($event) {
            return _vm.action('auth', item);
          }
        }
      }, [_vm._v("审核")]) : _c('v-btn', {
        attrs: {
          "text": ""
        },
        on: {
          "click": function click($event) {
            return _vm.action('block', item);
          }
        }
      }, [_vm._v("暂停运营")]), _c('v-btn', {
        attrs: {
          "color": "secondary",
          "text": ""
        },
        on: {
          "click": function click($event) {
            return _vm.$router.push({
              path: '/admin/dealer/edit/' + item._id
            });
          }
        }
      }, [_vm._v("编辑")]), _c('v-btn', {
        attrs: {
          "text": ""
        },
        on: {
          "click": function click($event) {
            return _vm.action('delete', item);
          }
        }
      }, [_vm._v("删除")])], 1)], 1)], 1)], 1);
    }), 1)], 1)], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }