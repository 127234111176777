var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "overflow-hidden",
    attrs: {
      "color": "grey lighten-3",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "no-gutters": "",
      "height": "100%"
    }
  }, [_c('v-col', {
    staticClass: "pa-12",
    attrs: {
      "lg": "10",
      "md": "12",
      "sm": "12",
      "cols": "12",
      "elevation": "6"
    }
  }, [_c('v-sheet', {
    staticClass: "overflow-y-auto fill-height justify-center",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "elevation": "6",
      "height": "100%",
      "width": "100%"
    }
  }, [_c('element-app-bar'), _c('draggable', _vm._b({
    attrs: {
      "handle": ".handle"
    },
    model: {
      value: _vm.orderList,
      callback: function callback($$v) {
        _vm.orderList = $$v;
      },
      expression: "orderList"
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.page_content, function (item, i) {
    return _c('v-row', {
      key: i,
      staticStyle: {
        "position": "relative"
      },
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-icon', {
      staticClass: "handle",
      staticStyle: {
        "cursor": "move",
        "position": "absolute",
        "top": "50%",
        "z-index": "6"
      },
      attrs: {
        "small": "",
        "color": "grey lighten-2"
      }
    }, [_vm._v("drag_indicator")]), _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [item.type === 'text' ? _c('element-text', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e(), item.type === 'carousel' ? _c('element-carousel', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e(), item.type === 'service' ? _c('element-service', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e(), item.type === 'material' ? _c('element-material', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e(), item.type === 'image' ? _c('element-image', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e(), item.type === 'horizontal' ? _c('element-horizontal', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e(), item.type === 'onerowcard' ? _c('element-onerowcard', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e(), item.type === 'twocolcard' ? _c('element-twocolcard', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e(), item.type === 'threecolcard' ? _c('element-threecolcard', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e(), item.type === 'threecard' ? _c('element-threecard', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e(), item.type === 'twocard' ? _c('element-twocard', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e(), item.type === 'fourcard' ? _c('element-fourcard', {
      key: i,
      attrs: {
        "index": i,
        "id": _vm.pageId,
        "element": item
      },
      on: {
        "DeleteItem": function DeleteItem($event) {
          return _vm.getPageInfo('body');
        },
        "UpdateItem": function UpdateItem($event) {
          return _vm.getPageInfo('body');
        }
      }
    }) : _vm._e()], 1)], 1);
  }), 1), _c('element-app-footer')], 1)], 1), _c('v-col', {
    attrs: {
      "lg": "2",
      "md": "12",
      "sm": "12"
    }
  }, [_c('v-navigation-drawer', {
    staticClass: "mt-14",
    attrs: {
      "permanent": "",
      "clipped": "",
      "fixed": "",
      "right": "",
      "width": "235px"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-subheader', [_vm._v("插入")]), _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addComponent('text');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("text_fields")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("文本")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addComponent('carousel');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("view_carousel")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("轮播")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addComponent('service');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("volunteer_activism")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("服务")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addComponent('horizontal');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("horizontal_rule")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("横线")])], 1)], 1), _c('v-subheader', [_vm._v("卡片")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12",
      "cols": "6"
    }
  }, [_c('v-card', {
    staticClass: "ml-2",
    attrs: {
      "hover": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addComponent('onerowcard');
      }
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/thumbnail/card_one_row.svg",
      "alt": "单个单行卡片",
      "contain": ""
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12",
      "cols": "6"
    },
    on: {
      "click": function click($event) {
        return _vm.addComponent('twocolcard');
      }
    }
  }, [_c('v-card', {
    staticClass: "mr-2",
    attrs: {
      "hover": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/thumbnail/card_two_col.svg",
      "alt": "两列两个卡片",
      "contain": ""
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12",
      "cols": "6"
    },
    on: {
      "click": function click($event) {
        return _vm.addComponent('threecolcard');
      }
    }
  }, [_c('v-card', {
    staticClass: "ml-2",
    attrs: {
      "hover": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/thumbnail/card_three.svg",
      "alt": "两列三个卡片",
      "contain": ""
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12",
      "cols": "6"
    },
    on: {
      "click": function click($event) {
        return _vm.addComponent('threecard');
      }
    }
  }, [_c('v-card', {
    staticClass: "mr-2",
    attrs: {
      "hover": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/thumbnail/card_three_col.svg",
      "alt": "三列三个卡片",
      "contain": ""
    }
  })], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12",
      "cols": "6"
    },
    on: {
      "click": function click($event) {
        return _vm.addComponent('twocard');
      }
    }
  }, [_c('v-card', {
    staticClass: "ml-2",
    attrs: {
      "hover": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/thumbnail/card_two_row.svg",
      "alt": "两个单行卡片",
      "contain": ""
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12",
      "cols": "6"
    },
    on: {
      "click": function click($event) {
        return _vm.addComponent('fourcard');
      }
    }
  }, [_c('v-card', {
    staticClass: "mr-2",
    attrs: {
      "hover": "",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/thumbnail/card_four.svg",
      "alt": "四个单行卡片",
      "contain": ""
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }