var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("问题咨询服务")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "flat": "",
      "dense": "",
      "hide-details": "",
      "label": "搜索服务编号、工单编号、咨询主题和内容...",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.doAction('search_item');
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 工作台是集中处理日常工作服务请求、待办事项的区域，在工作台中可以查找、编辑和完成服务请求。 ")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.computedHeaders,
      "items": _vm.caseList,
      "options": _vm.optionsItem,
      "server-items-length": _vm.caseCount,
      "loading": _vm.caseLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "item-key": "_id",
      "return-object": "false",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_vm.dealer_enterprise.length ? _c('v-toolbar-title', [_c('v-icon', {
          staticClass: "mr-4"
        }, [_vm._v("assignment")]), _vm._v(_vm._s(_vm.query.enterprise ? _vm.dealer_enterprise.find(function (item) {
          return item._id === _vm.query.enterprise;
        }).name : '全部企业咨询'))], 1) : _vm._e(), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer'), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Advisory_Edit') ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "ma-2",
                attrs: {
                  "outlined": "",
                  "fab": "",
                  "x-small": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('select_catalog', _vm.query.enterprise, '选择企业和咨询类别');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("add")])], 1)];
            }
          }], null, false, 2271455830)
        }, [_c('span', [_vm._v("通过表单增加请求")])]) : _vm._e(), _c('widgets-custom-header', {
          attrs: {
            "defaultHeader": _vm.defaultHeader,
            "headerList": _vm.headerItem,
            "version": 0,
            "tableName": "consultHeader"
          },
          on: {
            "Cancel": function Cancel($event) {
              return _vm.doAction('cancel_header');
            },
            "Default": function Default($event) {
              return _vm.doAction('default_header');
            },
            "Save": function Save($event) {
              return _vm.doAction('set_header');
            }
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "item.case_number",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.isEdit && !item.isSelf ? _c('v-badge', {
          attrs: {
            "color": "secondary",
            "dot": ""
          }
        }, [_c('widgets-case-consult', {
          key: item._id,
          attrs: {
            "getId": item._id
          },
          on: {
            "Close": _vm.closeCase
          }
        }, [_vm._v(" " + _vm._s(item.case_number) + " ")])], 1) : _c('widgets-case-consult', {
          key: item._id,
          attrs: {
            "getId": item._id
          },
          on: {
            "Close": _vm.closeCase
          }
        }, [_vm._v(" " + _vm._s(item.case_number) + " "), item.isSelf && item.status < 2 ? _c('v-icon', {
          attrs: {
            "right": "",
            "small": ""
          }
        }, [_vm._v("hourglass_top")]) : _vm._e()], 1)], 1)];
      }
    }, {
      key: "item.link_case_number",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "175px"
          }
        }, [_vm._v(_vm._s(item.link_case_number))])];
      }
    }, {
      key: "item.group",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.group ? _c('td', {
          attrs: {
            "nowrap": "true",
            "width": "125px"
          }
        }, [_vm._v(_vm._s(item.group.name))]) : _vm._e()];
      }
    }, {
      key: "item.catalog",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "75px"
          }
        }, [_vm._v(_vm._s(item.catalog ? item.catalog.name : ''))])];
      }
    }, {
      key: "item.subject",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          staticClass: "text-truncate",
          attrs: {
            "nowrap": "true",
            "width": "175px"
          }
        }, [_vm._v(_vm._s(item.subject))])];
      }
    }, {
      key: "item.enterprise",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.enterprise ? _c('td', {
          staticClass: "v-list-item--link",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.openEnterprise(item.enterprise);
            }
          }
        }, [_vm._v(_vm._s(item.enterprise.name))]) : _vm._e()];
      }
    }, {
      key: "item.creator",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.creator ? _c('td', {
          attrs: {
            "nowrap": "true",
            "width": "80px"
          }
        }, [_c('widgets-employee-dialogs', {
          staticClass: "v-list-item--link",
          attrs: {
            "account": item.creator._id
          }
        }, [_vm._v(_vm._s(item.creator.personal ? item.creator.personal.name : '*未设置姓名'))])], 1) : _c('td', {
          attrs: {
            "nowrap": "true",
            "width": "80px"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.editor",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.editor ? _c('td', {
          attrs: {
            "nowrap": "true",
            "width": "80px"
          }
        }, [_c('widgets-employee-dialogs', {
          staticClass: "v-list-item--link",
          attrs: {
            "account": item.editor._id
          }
        }, [_vm._v(_vm._s(item.editor.personal ? item.editor.personal.name : '*未设置姓名'))])], 1) : _c('td', {
          attrs: {
            "nowrap": "true",
            "width": "80px"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "75px"
          }
        }, [item.status === 0 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "secondary"
          }
        }, [_vm._v("待受理")]) : item.status === 1 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "primary"
          }
        }, [_vm._v("处理中")]) : _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已解决")])], 1)];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "175px"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }]),
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgCatalog,
      callback: function callback($$v) {
        _vm.dlgCatalog = $$v;
      },
      expression: "dlgCatalog"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('catalog');
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.dealer_enterprise,
      "rules": [_vm.rules.selected],
      "label": "企业",
      "hint": "请选择一家企业",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedCase.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.editedCase, "enterprise", $$v);
      },
      expression: "editedCase.enterprise"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.groupList,
      "rules": [_vm.rules.selected],
      "label": "支持分类",
      "hint": "请选择一个支持分类",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_catalog_list');
      }
    },
    model: {
      value: _vm.editedCase.group,
      callback: function callback($$v) {
        _vm.$set(_vm.editedCase, "group", $$v);
      },
      expression: "editedCase.group"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.catalogList,
      "label": "支持目录",
      "hint": "请选择一个支持目录",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedCase.catalog,
      callback: function callback($$v) {
        _vm.$set(_vm.editedCase, "catalog", $$v);
      },
      expression: "editedCase.catalog"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dlgCatalog = false;
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": function click($event) {
        return _vm.submit('catalog');
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'enterprise' ? _c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.closeEnterprise();
      }
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }