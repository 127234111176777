var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-dialog', {
    attrs: {
      "value": !_vm.report.sumCount || !_vm.caseStatue.data || !_vm.report.caseCountYear,
      "hide-overlay": "",
      "persistent": "",
      "width": "300"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-card-text', [_vm._v(" 数据加载中... "), _c('v-progress-linear', {
    staticClass: "mb-0",
    attrs: {
      "indeterminate": "",
      "color": "white"
    }
  })], 1)], 1)], 1), _vm.report.sumCount ? _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('report-card-count', {
    attrs: {
      "icon": "business",
      "updateTime": _vm.report.sumCount.createTime,
      "count": _vm.report.sumCount.enterprise,
      "label": "入驻企业总数",
      "path": "/dealer/enterprise"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('report-card-count', {
    attrs: {
      "icon": "devices",
      "updateTime": _vm.report.sumCount.createTime,
      "count": _vm.report.sumCount.asset,
      "label": "已录入设备总数",
      "path": "/dealer/asset/list"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('report-card-count', {
    attrs: {
      "icon": "person",
      "updateTime": _vm.report.sumCount.createTime,
      "count": _vm.report.sumCount.order,
      "label": "企业订单总数",
      "path": "/dealer/workbench/order/list"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('report-card-count', {
    attrs: {
      "icon": "assignment",
      "updateTime": _vm.report.sumCount.createTime,
      "count": _vm.report.sumCount.case,
      "label": "处理请求总数",
      "path": "/dealer/workbench"
    }
  })], 1)], 1) : _vm._e(), _vm.report ? _c('v-row', [_vm.report.enterpriseHistory && _vm.report.enterpriseHistory.data ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('report-card-history', {
    attrs: {
      "updateTime": _vm.report.enterpriseHistory.createTime,
      "labels": _vm.report.enterpriseHistory.data.map(function (item) {
        return item.date;
      }),
      "value": _vm.report.enterpriseHistory.data.map(function (item) {
        return item.count;
      }),
      "title": "新增企业"
    }
  })], 1) : _vm._e(), _vm.report.assetHistory && _vm.report.assetHistory.data ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('report-card-history', {
    attrs: {
      "updateTime": _vm.report.assetHistory.createTime,
      "labels": _vm.report.assetHistory.data.map(function (item) {
        return item.date;
      }),
      "value": _vm.report.assetHistory.data.map(function (item) {
        return item.count;
      }),
      "title": "新增设备"
    }
  })], 1) : _vm._e(), _vm.report.orderHistory && _vm.report.orderHistory.data ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('report-card-history', {
    attrs: {
      "updateTime": _vm.report.orderHistory.createTime,
      "labels": _vm.report.orderHistory.data.map(function (item) {
        return item.date;
      }),
      "value": _vm.report.orderHistory.data.map(function (item) {
        return item.count;
      }),
      "title": "企业订单"
    }
  })], 1) : _vm._e(), _vm.report.caseHistory && _vm.report.caseHistory.data ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('report-card-history', {
    attrs: {
      "updateTime": _vm.report.caseHistory.createTime,
      "labels": _vm.report.caseHistory.data.map(function (item) {
        return item.date;
      }),
      "value": _vm.report.caseHistory.data.map(function (item) {
        return item.count;
      }),
      "title": "服务请求"
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-iterator', {
    attrs: {
      "items": _vm.caseStatue.data,
      "item-key": "name",
      "items-per-page": 4,
      "hide-default-footer": "",
      "no-data-text": "统计数据分析中..."
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c('v-row', _vm._l(props.items, function (item) {
          return _c('v-col', {
            key: item.name,
            attrs: {
              "cols": "12",
              "sm": "6",
              "md": "4",
              "lg": "4"
            }
          }, [_c('v-card', {
            attrs: {
              "outlined": ""
            }
          }, [_c('v-card-title', {
            staticClass: "title font-weight-light"
          }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('v-divider'), _c('v-list', {
            attrs: {
              "dense": ""
            }
          }, [_c('v-list-item', {
            attrs: {
              "link": ""
            },
            on: {
              "click": function click($event) {
                return _vm.gotoPath(item.code, 'pending');
              }
            }
          }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("处理中:")])], 1), _c('v-spacer'), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.pending) + " ")])], 1), _c('v-icon', {
            staticClass: "subheading font-weight-light"
          }, [_vm._v("arrow_forward")])], 1), _c('v-list-item', {
            attrs: {
              "link": item.code === 'service'
            },
            on: {
              "click": function click($event) {
                return _vm.gotoPath(item.code, 'warning');
              }
            }
          }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("即将超期:")])], 1), _c('v-spacer'), _c('v-list-item-content', [item.code === 'service' ? _c('v-list-item-title', [_c('span', {
            staticClass: "orange--text"
          }, [_vm._v(_vm._s(item.warning))])]) : _vm._e()], 1), item.code === 'service' ? _c('v-icon', {
            staticClass: "subheading font-weight-light"
          }, [_vm._v("arrow_forward")]) : _vm._e()], 1), _c('v-list-item', {
            attrs: {
              "link": item.code === 'service'
            },
            on: {
              "click": function click($event) {
                return _vm.gotoPath(item.code, 'overdue');
              }
            }
          }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("超期处理:")])], 1), _c('v-spacer'), _c('v-list-item-content', [item.code === 'service' ? _c('v-list-item-title', [_c('span', {
            staticClass: "secondary--text"
          }, [_vm._v(_vm._s(item.overdue))])]) : _vm._e()], 1), item.code === 'service' ? _c('v-icon', {
            staticClass: "subheading font-weight-light"
          }, [_vm._v("arrow_forward")]) : _vm._e()], 1), _c('v-list-item', {
            attrs: {
              "link": ""
            },
            on: {
              "click": function click($event) {
                return _vm.gotoPath(item.code, 'complete');
              }
            }
          }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("已完成:")])], 1), _c('v-spacer'), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.complete))])], 1), _c('v-icon', {
            staticClass: "subheading font-weight-light"
          }, [_vm._v("arrow_forward")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
            staticClass: "py-1"
          }, [_c('v-icon', {
            staticClass: "mr-2",
            attrs: {
              "small": ""
            }
          }, [_vm._v("schedule")]), _c('span', {
            staticClass: "caption font-weight-light"
          }, [_vm._v("更新时间：" + _vm._s(_vm._f("fromDate")(_vm.caseStatue.createTime)))])], 1)], 1)], 1);
        }), 1)];
      }
    }])
  })], 1)], 1), _vm.report ? _c('v-row', [_vm.report.caseCountYear && _vm.report.caseCountYear.data ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title font-weight-light"
  }, [_vm._v(" 服务请求" + _vm._s(_vm.report.caseCountYear.year) + "年度热点图 "), _c('v-spacer'), _c('v-select', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.BusinessType,
      "label": "请选择业务类型",
      "item-text": "name",
      "item-value": "code",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.updateHeatMap
    },
    model: {
      value: _vm.catalogCountYear,
      callback: function callback($$v) {
        _vm.catalogCountYear = $$v;
      },
      expression: "catalogCountYear"
    }
  })], 1), _c('v-divider'), _c('report-heat-map', {
    attrs: {
      "width": "100%",
      "height": "300px",
      "data": _vm.report.caseCountYear.data,
      "year": _vm.report.caseCountYear.year,
      "max": _vm.report.caseCountYear.maxValue,
      "updateTime": _vm.report.caseCountYear.createTime
    }
  }), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-1"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("schedule")]), _c('span', {
    staticClass: "caption font-weight-light"
  }, [_vm._v("更新时间：" + _vm._s(_vm._f("fromDate")(_vm.report.caseCountYear.createTime)))])], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }