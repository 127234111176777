<template>
  <v-container>
    <v-form v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-toolbar class="secondary" dark flat>
          <v-btn icon @click="goBack()">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title v-if="process_info">{{enterprise.name}}：{{ process_info.service_catalog.name }}工单</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="submit('')" :disabled="!valid">创建</v-btn>
        </v-toolbar>
        <v-card-text v-if="process_info">
          <v-row>
            <v-col cols="6" v-if="process_info.process_type.length">
              <v-autocomplete
                v-model="editedItem.process_type"
                :items="process_info.process_type"
                :rules="[rules.selected]"
                autocomplete="off"
                outlined
                dense
                label="请选择服务类型"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.out_number"
                type="text"
                label="外部单号"
                hint="企业第三方系统工单编号"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="process_info.require_account">
            <v-col cols="6" class="my-n4">
              <v-autocomplete
                v-model="editedItem.account"
                :rules="[rules.required]"
                :items="employeeResult"
                :loading="loadingEmployee"
                :search-input.sync="searchEmployee"
                :filter="filterEmployee"
                return-object
                autocomplete="off"
                outlined
                dense
                item-text="personal.name"
                item-value="_id"
                label="选择用户"
                placeholder="输入员工姓名、电话或手机号码进行查找"
                append-outer-icon="add"
                @click:append-outer="createEmployee"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6" class="my-n4">
              <v-expand-transition>
                <v-list v-if="editedItem.account" :class="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" dense>
                  <v-list-item>
                    <v-list-item-avatar v-if="editedItem.account.personal">
                      <v-img
                        :src="editedItem.account.personal.profile ? ossURL +'/'+ editedItem.account.personal.profile : '/static/grey_silhouette.png'"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>姓名</v-list-item-title>
                      <v-list-item-subtitle>{{editedItem.account.personal.name}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar v-if="editedItem.account.personal">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>电话</v-list-item-title>
                      <v-list-item-subtitle>{{editedItem.account.phone}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar v-if="editedItem.account.personal">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>电子邮件</v-list-item-title>
                      <v-list-item-subtitle>{{editedItem.account.username}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar v-if="editedItem.account.personal">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>员工号码</v-list-item-title>
                      <v-list-item-subtitle>{{editedItem.account.employer.employeeNo}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-avatar v-if="editedItem.account.personal">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>地址</v-list-item-title>
                      <v-list-item-subtitle>{{editedItem.account.personal.address}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <widgets-employee-dialogs :account="editedItem.account._id" v-on:UpdateEmployee="reloadAccount">
                        <v-btn icon>
                          <v-icon>read_more</v-icon>
                        </v-btn>
                      </widgets-employee-dialogs>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-expand-transition>
            </v-col>
            <v-col cols="12"><v-divider></v-divider></v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-tabs>
                <v-tab>关联设备</v-tab>
                <v-tab v-if="process_info.isRecovery">更换新设备</v-tab>
                <v-tab>附件</v-tab>
                <v-tab>备注</v-tab>
                <v-tab-item>
                  <v-card-text>
                    <v-data-table
                      :headers="headerAsset"
                      :items="editedItem.link_asset"
                      :server-items-length="editedItem.link_asset.length"
                      :footer-props="{'items-per-page-options': [5, 10, 15]}"
                      item-key="_id"
                      return-object="false"
                      dense
                    >
                      <template v-slot:item.name="{ item }">
                        <td nowrap="true">
                          <widgets-asset-dialogs :getId="item._id">
                            <div>
                              <v-tooltip bottom v-if="item.isLock">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" small>lock</v-icon>
                                </template>
                                <span>设备锁定中</span>
                              </v-tooltip>
                              {{item.name}}
                              <v-tooltip bottom v-if="item.ownerType === 'Enterprise'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" small>corporate_fare</v-icon>
                                </template>
                                <span>企业自有设备</span>
                              </v-tooltip>
                              <v-tooltip bottom v-else-if="item.ownerType === 'Account'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" small>person</v-icon>
                                </template>
                                <span>个人自有设备</span>
                              </v-tooltip>
                            </div>
                          </widgets-asset-dialogs>
                        </td>
                      </template>
                      <template v-slot:item.serial_number="{ item }">
                        <td nowrap="true" v-if="item.noSN">无序列号</td>
                        <td nowrap="true" v-else>{{item.serial_number}}</td>
                      </template>
                      <template v-slot:item.product="{ item }">
                        <td nowrap="true" v-if="item.product" @click="openProduct(item.product)" class="v-list-item--link">{{item.product.name}} - {{item.product.part_number}}</td>
                      </template>
                      <template v-slot:item.state="{ item }">
                        <td nowrap="true">{{item.state.name}}</td>
                      </template>
                      <template v-slot:item.branch="{ item }">
                        <td nowrap="true">{{item.branch}}</td>
                      </template>
                      <template v-slot:item.asset_state="{ item }">
                        <td nowrap="true">{{item.asset_state.map(state => assetState.find(o => o.code === state).name)}}</td>
                      </template>
                      <template v-slot:item.label="{ item }">
                        <td nowrap="true">{{item.label}}</td>
                      </template>
                      <template v-slot:item.account="{ item }">
                        <td nowrap="true">{{item.account && item.account.personal ? item.account.personal.name : ''}}</td>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon small @click="doAction('unlink_asset', item)">remove</v-icon>
                      </template>
                    </v-data-table>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="2">
                        <v-btn block text @click="doAction('create_asset')"><v-icon left>add</v-icon>创建新的设备</v-btn>
                      </v-col>
                      <v-col cols="2">
                        <v-btn block text color="secondary" @click="doAction('link_asset')"><v-icon left>link</v-icon>关联现有设备</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item v-if="process_info.isRecovery">
                  <v-card-text>
                    <v-data-table
                      :headers="headerAsset"
                      :items="editedItem.link_asset_new"
                      :server-items-length="editedItem.link_asset_new.length"
                      :footer-props="{'items-per-page-options': [5, 10, 15]}"
                      item-key="_id"
                      return-object="false"
                      dense
                    >
                      <template v-slot:item.name="{ item }">
                        <td nowrap="true">
                          <widgets-asset-dialogs :getId="item._id">
                            <div>
                              <v-tooltip bottom v-if="item.isLock">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" small>lock</v-icon>
                                </template>
                                <span>设备锁定中</span>
                              </v-tooltip>
                              {{item.name}}
                              <v-tooltip bottom v-if="item.ownerType === 'Enterprise'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" small>corporate_fare</v-icon>
                                </template>
                                <span>企业自有设备</span>
                              </v-tooltip>
                              <v-tooltip bottom v-else-if="item.ownerType === 'Account'">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" small>person</v-icon>
                                </template>
                                <span>个人自有设备</span>
                              </v-tooltip>
                            </div>
                          </widgets-asset-dialogs>
                        </td>
                      </template>
                      <template v-slot:item.serial_number="{ item }">
                        <td nowrap="true" v-if="item.noSN">无序列号</td>
                        <td nowrap="true" v-else>{{item.serial_number}}</td>
                      </template>
                      <template v-slot:item.product="{ item }">
                        <td nowrap="true" v-if="item.product" @click="openProduct(item.product)" class="v-list-item--link">{{item.product.name}} - {{item.product.part_number}}</td>
                      </template>
                      <template v-slot:item.state="{ item }">
                        <td nowrap="true">{{item.state.name}}</td>
                      </template>
                      <template v-slot:item.label="{ item }">
                        <td nowrap="true">{{item.label}}</td>
                      </template>
                      <template v-slot:item.branch="{ item }">
                        <td nowrap="true">{{item.branch}}</td>
                      </template>
                      <template v-slot:item.asset_state="{ item }">
                        <td nowrap="true">{{item.asset_state.map(state => assetState.find(o => o.code === state).name)}}</td>
                      </template>
                      <template v-slot:item.account="{ item }">
                        <td nowrap="true">{{item.account ? item.account.personal.name : ''}}</td>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-icon small @click="doAction('unlink_asset_new', item)">remove</v-icon>
                      </template>
                    </v-data-table>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col cols="2">
                        <v-btn block text color="secondary" @click="doAction('link_asset_new')"><v-icon left>link</v-icon>关联现有设备</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-card-text>
                    <v-list dense>
                      <template v-for="(file, i) in editedItem.files">
                        <v-list-item :key="file.href">
                          <v-list-item-avatar>
                            <v-icon>text_snippet</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="file.name"></v-list-item-title>
                            <!--                          <v-list-item-subtitle>{{file.creator}}{{file.createTime}}</v-list-item-subtitle>-->
                          </v-list-item-content>
                          <v-list-item-action class="d-inline">
                            <v-btn icon><v-icon @click="doAction('remove_file', '', i)">delete</v-icon></v-btn>
                            <v-btn icon><v-icon @click="doAction('download_file', '', file.href)">get_app</v-icon></v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="i+'_files_divider'" inset></v-divider>
                      </template>
                      <v-list-item>
                        <v-list-item-content>
                          <v-progress-linear color="secondary" v-if="Upload.uploading" :value="Upload.progressUpload"></v-progress-linear>
                          <input type="file" ref="uploadFile" :multiple="false" @change="detectFiles($event)" />
                          <v-list-item-title align="end"><v-btn text @click="doAction('upload_file', '', -1)"><v-icon left>attach_file</v-icon>上传附件</v-btn></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item>
                  <v-card-text>
                    <v-list dense>
                      <template v-for="(item, index) in editedItem.remarks">
                        <v-list-item :key="index+'_remarks_title'" three-line>
                          <widgets-employee-dialogs :account="item.account">
                          <v-list-item-avatar>
                            <v-img :src="item.avatar ? ossURL +'/'+ item.avatar : '/static/grey_silhouette.png'"></v-img>
                          </v-list-item-avatar>
                          </widgets-employee-dialogs>
                          <v-list-item-content>
                            <v-list-item-title>{{item.title}} {{ item.createTime | fromDate }} {{item.private ? ' - 仅个人可见' : ''}} {{item.public ? ' - 客户可见' : ''}}</v-list-item-title>
                            <v-list-item-subtitle>{{item.context}}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon>
                              <v-icon @click="removeRemark(item)">delete</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="index+'_remarks_divider'" inset></v-divider>
                      </template>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title align="end"><v-btn text @click="createRemarks()"><v-icon left>rate_review</v-icon>增加备注</v-btn></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="submit('')" :disabled="!valid">提交</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog v-model="dlgAsset" persistent width="750px">
      <widgets-select-asset ref="select_asset" :enterprise="$route.query.enterprise" key="linkAsset" :selectItem="editedItem.link_asset" v-on:Close="closeAsset" v-on:Selected="setAssetList"></widgets-select-asset>
    </v-dialog>
    <v-dialog v-model="dlgAssetInHouse" persistent width="750px">
      <widgets-select-asset ref="select_asset_new" :enterprise="$route.query.enterprise" key="linkAssetInHouse" :isInWarehouse="true" :maxLimit="editedItem.link_asset.length" :selectItem="editedItem.link_asset_new" v-on:Close="closeAssetInHouse" v-on:Selected="setAssetInHouseList"></widgets-select-asset>
    </v-dialog>
    <v-dialog v-model="dlgProduct" persistent scrollable width="600px">
      <widgets-product-dialogs :id="activeProduct" :key="activeProduct" v-on:Close="closeProduct()"></widgets-product-dialogs>
    </v-dialog>
    <v-dialog v-model="dlgRemarks" persistent width="550px">
      <v-card>
        <v-card-title>
          增加备注
          <v-spacer></v-spacer>
          <v-btn icon @click="closeRemarks"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-switch v-model="editedRemark.private" :label="`仅个人可见: ${editedRemark.private ? '是' : '否'}`" @change="changeRemarkPublic()"></v-switch>
            </v-col>
            <v-col cols="6">
              <v-switch v-model="editedRemark.public" :label="`客户可见: ${editedRemark.public ? '是' : '否'}`" @change="changeRemarkPrivate()"></v-switch>
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                auto-grow
                rows="3"
                v-model="editedRemark.context"
                label="备注内容"
                value=""
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeRemarks()">取消</v-btn>
          <v-btn text color="secondary" :disabled="!editedRemark.context" @click="addRemarks(editedRemark)">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgSubmit" persistent scrollable width="500px">
      <v-card>
        <v-card-title class="headline">
          确认提交新的服务请求
          <v-spacer></v-spacer>
          <v-btn icon @click="closeSubmit"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                服务请求提交后将通知经销商进行受理，处理过程和完成将会通过短信、邮件方式进行通知。
              </v-col>
            </v-row>
            <v-row v-if="createResult.length">
              <v-col cols="12">
                  <v-alert outlined type="success" text>
                    <v-row align="center" no-gutters>
                      <v-col cols="12">
                        成功创建：{{createResult.length}} 个服务请求；服务请求编号为：
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="grow">
                        <p class="font-weight-black" v-for="code in createResult" :key="code">{{ code }}</p>
                      </v-col>
                    </v-row>
                  </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="!createResult.length">
          <v-btn text @click="closeSubmit">取消</v-btn>
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="editedItem.link_asset.length > 1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="secondary" :loading="!submitEnd" v-bind="attrs" v-on="on" @click="submit('each')"><v-icon left>file_copy</v-icon>批量提交</v-btn>
            </template>
            <span>按所选设备提交多个服务请求</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text color="secondary" :loading="!submitEnd" v-bind="attrs" v-on="on" @click="submit('all')"><v-icon left>note_add</v-icon>提交</v-btn>
            </template>
            <span>为所选设备提交新的服务请求</span>
          </v-tooltip>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn text @click="goBack">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgEmployee" persistent scrollable width="600px">
      <v-form v-model="validEmployee" @submit.prevent="submitEmployee">
        <v-card>
          <v-card-title>
            <span class="headline">创建员工</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeEmployee"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row align="start" justify="center">
                <v-col cols="2" class="text-center">
                  <input
                    id="filesAvatar"
                    type="file"
                    name="filesAvatar"
                    ref="uploadAvatar"
                    accept="image/*"
                    :multiple="false"
                    @change="detectAvatar($event)"/>
                  <v-hover v-slot:default="{ hover }">
                    <v-avatar>
                      <v-img
                        :src="employee.editedItem.profile ? ossURL +'/'+ employee.editedItem.profile : '/static/grey_silhouette.png'"
                        alt="设置头像"
                        aspect-ratio="1">
                        <v-progress-circular
                          color="secondary"
                          rotate="360"
                          size="48"
                          width="5"
                          v-if="employee.uploading"
                          :value="employee.progressUpload">
                        </v-progress-circular>
                      </v-img>
                      <div v-if="hover" class="show_upload" style="height: 100%; cursor: pointer">
                        <v-icon @click="doAction('upload_avatar', employee.editedItem)" >add_a_photo</v-icon>
                      </div>
                    </v-avatar>
                  </v-hover>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.name"
                    :rules="[rules.required]"
                    type="text"
                    label="员工姓名"
                    hint="员工姓名为用户的真实姓名"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">https</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.username"
                    :rules="[rules.required, rules.email]"
                    :error-messages="employee.editedItem.msgName"
                    :clearable="employee.editedItem.msgName !== ''"
                    type="text"
                    label="电子邮件"
                    hint="电子邮件将作为登陆名称使用"
                    outlined
                    dense
                    required
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="employee.editedItem.chkName"
                          :width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-scale-transition>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">vpn_key</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.password"
                    :rules="[rules.required]"
                    :type="employee.editedItem.showPass ? 'text' : 'password'"
                    :append-icon="employee.editedItem.showPass ? 'visibility_off' : 'visibility'"
                    label="账号密码"
                    hint="请牢记此密码，密码将作为登陆凭证"
                    outlined
                    dense
                    required
                    @click:append="employee.editedItem.showPass = !employee.editedItem.showPass"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">stay_current_portrait</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.phone"
                    :rules="[rules.required, rules.phonenum]"
                    :error-messages="employee.editedItem.msgPhone"
                    :clearable="employee.editedItem.msgPhone !== ''"
                    type="number"
                    label="手机号码"
                    hint="手机号码可作为登陆名称使用"
                    outlined
                    color="secondary"
                    dense
                    required
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="employee.editedItem.chkPhone"
                          :width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-scale-transition>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6" v-if="enterprise.branch">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">account_tree</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="employee.editedItem.sub_corp"
                    :items="enterprise.branch"
                    autocomplete="off"
                    outlined
                    dense
                    label="请选择员工所属分公司"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">supervisor_account</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="employee.editedItem.roles"
                    :items="flatGroupRoles(groupList)"
                    :rules="[rules.selected]"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择所属角色"
                    multiple
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-action>
                          <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">integration_instructions</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.employeeNo"
                    type="text"
                    label="员工编号"
                    hint="员工编号为企业内部的员工编号"
                    outlined
                    color="secondary"
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">room</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="employee.editedItem.address"
                    :rules="[rules.required]"
                    :error-messages="employee.editedItem.msgAddress"
                    :clearable="employee.editedItem.msgAddress !== ''"
                    type="text"
                    label="收件地址"
                    hint="收件地址为接收设备的目的地"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <v-fade-transition leave-absolute>
                        <v-progress-circular
                          v-if="employee.editedItem.chkAddress"
                          width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-fade-transition>
                    </template>
                    <template v-slot:append-outer>
                      <v-btn text @click="doAction('copy_address', enterprise)">复制公司地址</v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6" v-for="(item, i) in this.employee.editedItem.customFields" :key="i+'_field'">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">list</v-icon>
                </v-col>
                <v-col cols="10" v-if="item.format.type === 'text'">
                  <v-text-field
                    v-if="item.format.mask"
                    v-model="item.value"
                    v-mask="item.format.mask"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense>
                  </v-text-field>
                  <v-text-field
                    v-else
                    v-model="item.value"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="10" v-if="item.format.type === 'selects'">
                  <v-autocomplete
                    autocomplete="off"
                    v-model="item.value"
                    :items="item.format.items"
                    :label="item.name"
                    :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <widgets-custom-field module="Account" :ownerId="query.enterprise" ownerType="Enterprise" v-on:Complete="doAction('update_field')"></widgets-custom-field>
            <v-spacer></v-spacer>
            <v-btn text @click="closeEmployee">取消</v-btn>
            <v-btn color="secondary" text @click="submitEmployee" :disabled="!validEmployee">创建</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dlgCreateAsset" persistent scrollable width="600px">
      <v-form v-model="validAsset" @submit.prevent="createAsset">
      <v-card>
        <v-card-title>
          新增设备
          <v-spacer></v-spacer>
          <v-btn icon @click="closeCreateAsset"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row align="center" justify="center" v-if="currentUser">
              <v-col cols="2" class="text-right" v-if="currentUser.hasAdmin.includes('enterprise') || currentUser.employer.ownerType === 'Dealer'">
                设备所属
              </v-col>
              <v-col cols="10" v-if="currentUser.hasAdmin.includes('enterprise') || currentUser.employer.ownerType === 'Dealer'">
                <v-autocomplete
                  v-model="assetForm.editedItem.ownerType"
                  :items="assetForm.ownerType"
                  item-text="name"
                  item-value="code"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  label="设备所属"
                  placeholder="设备归属人"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" class="text-right" v-if="currentUser.hasAdmin.includes('enterprise') || currentUser.employer.ownerType === 'Dealer'">
                设备用户
              </v-col>
              <v-col cols="10" v-if="currentUser.hasAdmin.includes('enterprise') || currentUser.employer.ownerType === 'Dealer'">
                <v-autocomplete
                  v-model="assetForm.editedItem.account"
                  :rules="[rules.required]"
                  :items="employeeList"
                  return-object
                  autocomplete="off"
                  outlined
                  dense
                  item-text="personal.name"
                  item-value="_id"
                  label="选择用户"
                  hide-details
                  placeholder="输入员工姓名、电话或手机号码进行查找"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                      <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="2" class="text-right">
                品牌厂商
              </v-col>
              <v-col cols="10">
                <v-autocomplete
                  v-model="assetForm.editedItem.vendor"
                  :items="vendorList"
                  @change="doAction('set_product_list')"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  label="产品供应商"
                  placeholder="请选择产品供应商"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" class="text-right">
                品牌类型
              </v-col>
              <v-col cols="10">
                <v-autocomplete
                  v-model="assetForm.editedItem.product_type"
                  :items="typeList"
                  @change="doAction('set_product_list')"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  label="产品类型"
                  placeholder="请选择产品类型"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2" class="text-right">
                产品
              </v-col>
              <v-col cols="10">
                <v-autocomplete
                  v-model="assetForm.editedItem.product"
                  :items="productList"
                  :rules="[rules.required, rules.selected]"
                  @change="doAction('set_warranty_time')"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  hide-details
                  label="关联产品"
                  placeholder="请选择设备关联的产品"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.name}} - {{data.item.part_number}}</v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.it_info">{{data.item.it_info.ram}}G - {{data.item.it_info.network}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="2" class="text-right">
                购买日期
              </v-col>
              <v-col cols="10">
                <v-menu
                  v-model="assetForm.menu_purchase"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="assetForm.editedItem.purchase_time"
                      :rules="[rules.required]"
                      label="购买日期"
                      placeholder="产品实际购买日期"
                      readonly
                      v-bind="attrs"
                      hide-details
                      outlined
                      dense
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="assetForm.editedItem.purchase_time" @input="doAction('set_warranty_deadline')" no-title scrollable></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2" class="text-right">
                质保期
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model.number="assetForm.editedItem.warranty_time"
                  :rules="[rules.required]"
                  @change="doAction('set_warranty_deadline')"
                  suffix="月"
                  type="number"
                  label="厂商质保期"
                  placeholder="厂商质保期在产品管理中可以设置默认值"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="text-right">
                质保到期
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="assetForm.editedItem.warranty_deadline"
                  :rules="[rules.required]"
                  type="date"
                  label="质保到期"
                  outlined
                  dense
                  disabled
                  hide-details
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="text-right">
                序列号
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="assetForm.editedItem.serial_number"
                  :rules="[rules.required]"
                  type="text"
                  label="序列号"
                  placeholder="产品唯一标示SN号码"
                  outlined
                  dense
                  hide-details
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeCreateAsset">取消</v-btn>
          <v-btn color="secondary" text @click="createAsset" :disabled="!validAsset">创建</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import _ from 'lodash'
import axios from "axios";
import {mapGetters} from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  CHECK_ADDR,
  FETCH_ENTERPRISE,
  FETCH_GROUP_LIST,
  INIT_CASE,
  PUBLISH_CASE,
  FETCH_UPLOAD,
  FETCH_URL,
  FETCH_ASSET,
  FETCH_EMPLOYEE_LIST,
  FETCH_FIELDS,
  CHECK_USERNAME,
  CHECK_USERPHONE,
  CREATE_EMPLOYEE,
  FETCH_ASSETSTATE_LIST, FETCH_PRODUCTTYPE_LIST, FETCH_VENDOR_LIST, FETCH_PRODUCT_LIST, PUBLISH_ASSET,
} from "@/store/actions.type";
import store from "@/store";
import moment from "moment";
import Util from "@/common/util";
export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      query: {
        enterprise: '',
        service: ''
      },
      defaultQuery: {
        enterprise: '',
        service: ''
      },
      editedItem: {
        account: null,
        user: {
          branch: '',
          roles: '',
          name: '',
          code: '',
          phone: '',
          tel: '',
          email: '',
          address:''
        },
        link_asset:[],
        link_asset_new: [],
        files: [],
        remarks: [],
        chkAddress: false,
        msgAddress: '',
      },
      editedRemark: {
        title: '',
        context: '',
        private: false,
        public: true
      },
      defaultRemark: {
        title: '',
        context: '',
        private: false,
        public: true
      },
      dlgAsset: false,
      headerAsset: [
        { text: '名称', value: 'name' },
        { text: '序列号', value: 'serial_number' },
        { text: '标签', value: 'label' },
        { text: '状态', value: 'state', width: "100px" },
        { text: '分支机构', width: "100px", value: 'branch' },
        { text: '用户', value: 'account', width: "100px" },
        { text: '产品', value: 'product' },
        { text: '设备情况', value: 'asset_state', width: "100px" },
        { text: '取消关联', value: 'actions', width: "85px", sortable: false },
      ],
      dlgAssetInHouse: false,
      activeProduct: null,
      dlgProduct: false,
      dlgRemarks: false,
      Upload: {
        fileIndex: -1,
        uploading: false,
        progressUpload: 0,
      },
      dlgSubmit: false,
      loadingEmployee: false,
      searchEmployee: null,
      entriesEmployee: [],
      inputTimer: null,
      dlgEmployee: false,
      validEmployee: true,
      dlgCreateAsset: false,
      validAsset: true,
      assetForm: {
        menu_purchase: false,
        ownerType: [{name: '企业所有', code: 'Enterprise'}, {name: '个人所有', code: 'Account'}],
        editedItem: {
          _id: '',
          ownerType: '',
          serial_number: '',
          vendor: '',
          product_type: '',
          product: '',
          warranty_time: '',
          purchase_time: '',
          warranty_deadline: '',
          state: '',
          account: '',
        },
        defaultItem: {
          _id: '',
          ownerType: '',
          serial_number: '',
          vendor: '',
          product_type: '',
          product: '',
          warranty_time: '',
          purchase_time: '',
          warranty_deadline: '',
          state: '',
          account: '',
        },
        uploading: false,
        progressUpload: 0,
      },
      employee: {
        editedItem: {
          _id: '',
          username: '',
          password: '',
          phone: '',
          name: '',
          profile: '',
          employeeNo: '',
          address: '',
          roles: [],
          sub_corp: '',
          chkName: false,
          msgName: '',
          chkPhone: false,
          msgPhone: '',
          chkAddress: false,
          msgAddress: '',
          showPass: false,
          customFields: []
        },
        defaultItem: {
          _id: '',
          username: '',
          password: '',
          phone: '',
          name: '',
          profile: '',
          employeeNo: '',
          roles: [],
          sub_corp: '',
          address: '',
          chkName: false,
          msgName: '',
          chkPhone: false,
          msgPhone: '',
          chkAddress: false,
          msgAddress: '',
          showPass: false,
          customFields: []
        },
        uploading: false,
        progressUpload: 0,
      },
      submitEnd: true,
      createResult:[],
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_ASSETSTATE_LIST, 'isEnable=true'),
      store.dispatch(FETCH_PRODUCTTYPE_LIST, 'isEnable=true'),
      store.dispatch(FETCH_VENDOR_LIST, 'limit=-1&isEnable=true'),
      store.dispatch(FETCH_PRODUCT_LIST, 'isEnable=true&limit=200'),
    ]).then(() => {
      next();
    });
  },
  created() {
  },
  mounted() {
    if (Object.keys(this.$route.query).length > 0) this.query = Object.assign(this.query, this.$route.query)
    this.initiateCase()
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentEmployer",
      "enterprise",
      "process_info",
      "groupList",
      "uploadSign",
      "vendorList",
      "typeList",
      "productList",
      "stateList",
      "employeeList",
    ]),
    employeeResult() {
      return this.entriesEmployee.map(user => {
        return Object.assign({}, user)
      })
    },
    assetState () {
      return Util.categories('assetState')
    },
  },
  watch: {
    searchEmployee(val) {
      if (this.inputTimer) {
        clearTimeout(this.inputTimer)
      }
      this.inputTimer = setTimeout(() => {
        if (this.loadingEmployee) return
        this.loadingEmployee = true
        store.dispatch(FETCH_EMPLOYEE_LIST, 'enterprise='+ this.query.enterprise +'&key='+ val)
          .then((data) =>{
            if (data) {
              const { employees } = data
              this.entriesEmployee = employees
            }
          })
          .catch(err => {
            store.commit(SET_ERROR, {msg: err})
          })
          .finally(() => (this.loadingEmployee = false))
      }, 500)
    },
    dlgEmployee (val) {
      val || this.closeEmployee()
    },
    'employee.editedItem.username'() {
      this.checkName();
    },
    'employee.editedItem.phone'() {
      this.checkPhone();
    },
    'employee.editedItem.address'() {
      this.checkAddress();
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    initiateCase() {
      if (!Object.keys(this.$route.query).length) {
        store.commit(SET_ERROR, {msg: '参数错误'})
        this.goBack()
      } else {
        store.dispatch(INIT_CASE, {form: 'service', query: 'enterprise='+this.$route.query.enterprise+'&service='+ this.$route.query.service})
          .catch(() => {
            this.goBack()
          })
        store.dispatch(FETCH_ENTERPRISE, this.$route.query.enterprise)
          .then(() => {
            this.loadingEmployee = true
            store.dispatch(FETCH_EMPLOYEE_LIST, 'enterprise='+ this.query.enterprise)
              .then((user) =>{
                if (user) {
                  const { employees } = user
                  this.entriesEmployee = employees
                }
              })
              .catch(err => {
                store.commit(SET_ERROR, {msg: err})
              })
              .finally(() => (this.loadingEmployee = false))
          })
        store.dispatch(FETCH_GROUP_LIST, 'owner='+ this.$route.query.enterprise)
        if (this.query.asset) {
          let asset_item = this.query.asset.split(',');
          asset_item.map(async (item) => {
            let info = await store.dispatch(FETCH_ASSET, item)
            this.editedItem.link_asset.push(info)
          })
        }
        if (this.currentUser.employer.ownerType === 'Dealer') {
          this.assetForm.ownerType.push({name: '经销商管理', code: 'Dealer'})
        }
      }
    },
    doAction(action, item = {}, additional) {
      switch (action) {
        case 'copy_address': {
          this.employee.editedItem.address = item.address
          break
        }
        case 'create_asset': {
          store.dispatch(FETCH_EMPLOYEE_LIST, '&limit=1000&enterprise='+ this.query.enterprise)
          this.dlgCreateAsset = true
          break
        }
        case 'link_asset': {
          if (this.$refs.select_asset) this.$refs.select_asset.getAssetList()
          this.dlgAsset = true
          break
        }
        case 'link_asset_new': {
          if (this.$refs.select_asset_new) this.$refs.select_asset_new.getAssetList()
          if (this.editedItem.link_asset.length < 1) {
            store.commit(SET_ERROR, {msg: '请先选择需要更换的设备。'})
          } else {
            this.dlgAssetInHouse = true
          }
          break
        }
        case 'unlink_asset': {
          this.editedItem.link_asset.splice(this.editedItem.link_asset.indexOf(item), 1)
          break
        }
        case 'unlink_asset_new': {
          this.editedItem.link_asset_new.splice(this.editedItem.link_asset_new.indexOf(item), 1)
          break
        }
        case 'upload_file': {
          this.Upload.fileIndex = additional
          store.dispatch(FETCH_UPLOAD, 'private')
            .then(this.$refs.uploadFile.click())
            .catch(err => {
              store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'upload_avatar': {
          store.dispatch(FETCH_UPLOAD)
            .then(this.$refs.uploadAvatar.click())
            .catch(err => {
              store.commit(SET_ERROR, {msg: err});
            });
          break;
        }
        case 'update_field': {
          this.getFieldList(this.query.enterprise, 'Account')
          break;
        }
        case 'download_file': {
          store.dispatch(FETCH_URL, additional)
            .then((fileurl) => {
              window.location.href = fileurl;
            })
            .catch(err => {
              store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'remove_file': {
          this.editedItem.files.splice(additional, 1)
          break
        }
        case 'set_product_list': {
          let strQuery = 'isEnable=true&limit=100'
          if (this.assetForm.editedItem.vendor) strQuery += '&vendor='+ this.assetForm.editedItem.vendor
          if (this.assetForm.editedItem.product_type) strQuery += '&type='+ this.assetForm.editedItem.product_type
          store.dispatch(FETCH_PRODUCT_LIST, strQuery)
          break
        }
        case 'set_warranty_time': {
          if (this.assetForm.editedItem.product) {
            let objProduct = this.productList.find(o => o._id === this.assetForm.editedItem.product)
            this.assetForm.editedItem.vendor = objProduct.vendor._id
            this.assetForm.editedItem.product_type = objProduct.product_type._id
            if (objProduct.guarantee) {
              this.assetForm.editedItem.warranty_time = objProduct.guarantee
            } else {
              this.assetForm.editedItem.warranty_time = 0
            }
            if (this.assetForm.editedItem.purchase_time) this.doAction('set_warranty_deadline')
          }
          break
        }
        case 'set_warranty_deadline': {
          this.assetForm.menu_purchase = false
          if (this.assetForm.editedItem.warranty_time && this.assetForm.editedItem.purchase_time) {
            let purchase_time = moment(this.assetForm.editedItem.purchase_time)
            purchase_time = purchase_time.format('YYYY-MM-DD')
            let warranty_deadline = moment(purchase_time).add(this.assetForm.editedItem.warranty_time, 'M')
            warranty_deadline = moment(warranty_deadline).subtract(1, 'd')
            warranty_deadline = moment(warranty_deadline).format('YYYY-MM-DD')
            this.assetForm.editedItem.warranty_deadline = warranty_deadline
            this.$forceUpdate()
          }
          break
        }
        default: {

          break
        }
      }
    },
    flatGroupRoles(arrGroup) {
      let arrRoles = []
      // arrRoles.push({ _id: '', name: '全部', group: ''})
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.roles.forEach((rolesItem) => {
          arrRoles.push({ _id: rolesItem._id, name: rolesItem.name, count: rolesItem.employeeCount, group: groupItem.name, type: rolesItem.type, service: rolesItem.service })
        })
      })
      return arrRoles
    },
    createEmployee() {
      this.getFieldList(this.query.enterprise, 'Account')
      this.dlgEmployee = true
    },
    getFieldList(eid, mod = 'Account') {
      store.dispatch(FETCH_FIELDS, eid+'/'+mod)
        .then((data) => {
          if (data && data.length) {
            data.forEach(item => {
              this.employee.editedItem.customFields.push(Object.assign(item, {value: ''}))
            })
          }
        })
    },
    checkName: _.debounce(function () {
      if (this.employee.editedItem.username) {
        this.employee.editedItem.chkName = true;
        this.$store.dispatch(CHECK_USERNAME, this.employee.editedItem.username)
          .then(() => {
            this.employee.editedItem.msgName = '电子邮件已被注册，请更换其它地址';
            this.employee.editedItem.chkName = false;
          })
          .catch(() => {
            this.employee.editedItem.msgName = '';
            this.employee.editedItem.chkName = false;
          })
      }
    }, 1000),
    checkPhone: _.debounce(function () {
      if (this.employee.editedItem.phone) {
        this.employee.editedItem.chkPhone = true;
        this.$store.dispatch(CHECK_USERPHONE, this.employee.editedItem.phone)
          .then(() => {
            this.employee.editedItem.msgPhone = '手机号码已被注册，请更换其它号码';
            this.employee.editedItem.chkPhone = false;
          })
          .catch(() => {
            this.employee.editedItem.msgPhone = '';
            this.employee.editedItem.chkPhone = false;
          })
      }
    }, 1000),
    checkAddress: _.debounce(function () {
      if (this.employee.editedItem.address) {
        this.employee.editedItem.chkAddress = true;
        this.$store.dispatch(CHECK_ADDR, this.employee.editedItem.address)
          .then((data) => {
            if (data.status !== 0 || data.result.reliability < 7) {
              this.employee.editedItem.msgAddress = '地址信息错误，请输入正确的地址信息';
            } else {
              this.employee.editedItem.addressInfo = data.result;
              this.employee.editedItem.msgAddress = '';
            }
            this.employee.editedItem.chkAddress = false;
          })
          .catch((err) => {
            this.employee.editedItem.msgAddress = err.data.message;
            this.employee.editedItem.chkAddress = false;
          })
      }
    }, 500),
    submitEmployee() {
      let strInsert = {}
      strInsert['personal'] = {}
      strInsert['employer'] = {}
      strInsert['customFields'] = []
      strInsert.username = this.employee.editedItem.username
      strInsert.password = this.employee.editedItem.password
      strInsert.phone = this.employee.editedItem.phone
      strInsert.ownerType = 'Enterprise'
      strInsert.ownerId = this.query.enterprise
      strInsert['employer'].roles = this.employee.editedItem.roles
      if (this.employee.editedItem.customFields.length) {
        this.employee.editedItem.customFields.forEach(item => {
          strInsert['customFields'].push({ _id: item._id, value: item.value })
        })
      }
      if (this.employee.editedItem.name) strInsert['personal'].name = this.employee.editedItem.name
      if (this.employee.editedItem.profile) strInsert['personal'].profile = this.employee.editedItem.profile
      if (this.employee.editedItem.address) strInsert['personal'].address = this.employee.editedItem.address
      if (this.employee.editedItem.employeeNo) strInsert['employer'].employeeNo = this.employee.editedItem.employeeNo;
      if (this.employee.editedItem.sub_corp) strInsert['employer'].sub_corp = this.employee.editedItem.sub_corp;
      if (this.employee.editedItem.addressInfo) {
        strInsert.addressInfo = this.employee.editedItem.addressInfo
      } else {
        this.$store.commit(SET_ERROR, {msg: '请等待地址验证中...', color: 'secondary', timeout: 500});
        return
      }
      store.dispatch(CREATE_EMPLOYEE, strInsert)
        .then(async (data) => {
          store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
          this.entriesEmployee.push(data)
          this.editedItem.account = data
          this.closeEmployee()
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    closeEmployee() {
      this.dlgEmployee = false
      this.$nextTick(() => {
        this.employee.editedItem = Object.assign({}, this.employee.defaultItem)
        this.employee.editedItem.roles.length = 0
        this.employee.editedItem.customFields.length = 0
      })
    },
    createAsset() {
      let strInsert = {}
      strInsert.name = this.assetForm.editedItem.serial_number
      strInsert.serial_number = this.assetForm.editedItem.serial_number
      strInsert.vendor = this.assetForm.editedItem.vendor
      strInsert.productType = this.assetForm.editedItem.product_type
      strInsert.product = this.assetForm.editedItem.product
      strInsert.purchase_time = this.assetForm.editedItem.purchase_time
      strInsert.warranty_time = this.assetForm.editedItem.warranty_time
      strInsert.warranty_deadline = this.assetForm.editedItem.warranty_deadline
      strInsert.enterprise = this.query.enterprise
      strInsert.ownerType = this.assetForm.editedItem.ownerType
      if (this.stateList && this.stateList.length) {
        let usedState = this.stateList.find(item => item.name === '在用')
        if (!usedState) return store.commit(SET_ERROR, {msg: '请在系统设置中配置资产在用状态。'})
        strInsert.state = usedState._id
      } else {
        return store.commit(SET_ERROR, {msg: '请在系统设置中配置资产在用状态。'})
      }
      if (this.currentUser.hasAdmin.includes('enterprise') || this.currentUser.employer.ownerType === 'Dealer') {
        strInsert.account = this.assetForm.editedItem.account._id
        strInsert.user = {
          name: this.assetForm.editedItem.account.personal.name || '',
          code: this.assetForm.editedItem.account.employer.employeeNo || '',
          phone: this.assetForm.editedItem.account.phone,
          email: this.assetForm.editedItem.account.username,
          city: this.assetForm.editedItem.account.personal.city || '',
        }
      } else {
        strInsert.account = this.currentUser._id
        strInsert.user = {
          name: this.currentUser.personal.name || '',
          code: this.currentUser.employer.employeeNo || '',
          phone: this.currentUser.phone,
          email: this.currentUser.username,
          city: this.currentUser.personal.city || '',
        }
      }
      store.dispatch(PUBLISH_ASSET, strInsert)
        .then((data) => {
          store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
          this.editedItem.link_asset.push(data.data)
          this.dlgCreateAsset = false
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    closeCreateAsset() {
      this.dlgCreateAsset = false
      this.$nextTick(() => {
        store.dispatch(FETCH_PRODUCT_LIST, 'isEnable=true&limit=0')
        this.assetForm.editedItem = Object.assign({}, this.assetForm.defaultItem)
      })
    },
    reloadAccount(data) {
      let indexObj = this.entriesEmployee.findIndex(item => item._id === this.editedItem.account._id)
      if (indexObj > -1) {
        Object.assign(this.entriesEmployee[indexObj], data)
        this.editedItem.account = data
      }
    },
    closeAsset() {
      this.dlgAsset = false
    },
    setAssetList(data) {
      this.editedItem.link_asset = [...data.filter(item => !item.isLock)]
      if (data.close) {
        this.dlgAsset = false
      }
      // this.dlgAsset = false
    },
    closeAssetInHouse() {
      this.dlgAssetInHouse = false
    },
    setAssetInHouseList(data) {
      this.editedItem.link_asset_new = [...data.filter(item => !item.isLock)]
      if (data.close) {
        this.dlgAssetInHouse = false
      }
    },
    openProduct(item) {
      this.activeProduct = item._id
      this.dlgProduct = true
    },
    closeProduct() {
      this.dlgProduct = false
    },
    createRemarks() {
      this.dlgRemarks = true
    },
    addRemarks(objRemarks) {
      objRemarks['account'] = this.currentUser._id
      objRemarks['avatar'] = this.currentUser.personal.profile || ''
      objRemarks['createTime'] = new Date()
      this.editedItem.remarks.push(Object.assign({}, objRemarks))
      this.closeRemarks()
    },
    removeRemark(item) {
      this.editedItem.remarks.splice(this.editedItem.remarks.indexOf(item), 2)
    },
    closeRemarks() {
      this.dlgRemarks = false
      this.$nextTick(() => {
        this.editedRemark = Object.assign({}, this.defaultRemark);
      });
    },
    detectAvatar(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.uploadAvatar(fileList[x]);
      })
    },
    detectFiles (e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async uploadAvatar(file) {
      this.fileName = file.name;
      this.employee.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', this.query.enterprise +'/avatar/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.employee.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.employee.uploading = false;
          this.employee.progressUpload = 0;
          this.$refs.uploadAvatar.value = '';
          this.employee.editedItem.profile = this.query.enterprise +'/avatar/'+ this.uploadSign.key;
        })
        .catch((error) => {
          this.employee.uploading = false;
          this.employee.progressUpload = 0;
          this.$refs.uploadAvatar.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
    async upload(file) {
      let fileFullName = file.name,
        fileSuffix = fileFullName.substring(fileFullName.lastIndexOf('.') + 1);
      this.Upload.uploading = true
      let param = new FormData()
      param.append('name', fileFullName);
      param.append('key', 'case/'+ this.uploadSign.key +'/'+ fileFullName);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, fileFullName);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };
      await axios.post(this.uploadSign.host, param, config)
        .then(async () => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          this.$refs.uploadFile.value = '';

          if (this.Upload.fileIndex > -1) {
            await this.editedItem.files.splice(this.Upload.fileIndex, 1, {
              suffix: fileSuffix,
              name: fileFullName,
              href: param.get('key'),
            })
          } else {
            await this.editedItem.files.push({
              suffix: fileSuffix,
              name: fileFullName,
              href: param.get('key'),
            })
          }
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          this.$refs.uploadInput.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
    closeSubmit() {
      this.dlgSubmit = false
    },
    submit(byType = '') {
      if (this.process_info.isRecovery && this.editedItem.link_asset_new.length !== 0 && (this.editedItem.link_asset.length !== this.editedItem.link_asset_new.length)) {
        store.commit(SET_ERROR, {msg: '请保持选择的关联设备和更换新设备数量一致。'})
        return
      }
      if (!byType) {
        this.dlgSubmit = true
      } else {
        let strCase = {}
        strCase['user'] = {}
        strCase.byType = byType
        strCase.enterprise = this.$route.query.enterprise
        strCase.out_number = this.editedItem.out_number
        strCase.service_catalog = this.process_info.service_catalog._id
        strCase.process = this.process_info._id
        strCase.process_type = this.editedItem.process_type
        strCase.create_address = this.editedItem.account.personal.address
        strCase.account = this.editedItem.account._id
        strCase.link_asset = this.editedItem.link_asset.map(item => item._id)
        strCase.link_asset_new = this.editedItem.link_asset_new.map(item => item._id)
        strCase.files = this.editedItem.files
        strCase.remarks = this.editedItem.remarks

        store.dispatch(PUBLISH_CASE, {form: 'service', data: strCase})
          .then(({caseList}) => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.createResult = caseList.map(item => item.case_number)
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
    changeRemarkPrivate() {
      if (this.editedRemark.public) this.editedRemark.private = false;
    },
    changeRemarkPublic() {
      if (this.editedRemark.private) this.editedRemark.public = false;
    },
    filterEmployee(item, search) {
      return (
        item.username.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        || item.phone.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
        || (item.personal && item.personal.name && item.personal.name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1)
      )
    },
  },
};
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
