<template>
  <v-row no-gutters>
    <v-col cols="12" class="mb-n2">
      <v-carousel
        height="350"
        hide-delimiter-background
        hide-delimiters
        transition="fade-transition"
        :show-arrows="false"
      >
        <v-carousel-item v-if="inviteOwner.logoImage" :src="inviteOwner.profileImage && ossURL +'/'+ inviteOwner.profileImage" gradient="to bottom, rgba(0,0,0,0) 75%, rgba(255,255,255,1)">
          <v-card-title class="text-center justify-center">
            <v-img
              :src="ossURL +'/'+ inviteOwner.logoImage"
              transition="scale-transition" origin="center center"
              aspect-ratio="1" contain max-width="150">
            </v-img>
          </v-card-title>
          <v-card-title class="text-center justify-center text-h4">
            {{ inviteOwner.subTitle }}
          </v-card-title>
        </v-carousel-item>
        <v-carousel-item v-else>
          <v-card-title class="text-center justify-center mt-10">
            <v-img
              src="/static/logo2x.svg"
              transition="fade-transition" origin="center center"
              max-width="150"
              lass="my-3"
              contain
              height="150">
            </v-img>
          </v-card-title>
        </v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col cols="12" class="mt-n16">
      <v-card max-width="550" class="mx-auto" outlined>
        <v-card-title v-if="inviteOwner.name">{{inviteOwner.name}}员工注册</v-card-title>
        <v-card-title v-else>{{$route.query.owner === 'Dealer' ? '经销商' : '企业'}}员工注册</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="register" v-model="valid" @submit.prevent="submit">
            <v-text-field
              v-model="register.inviteCode"
              :rules="[rules.required]"
              :error-messages="register.msgCode"
              :clearable="register.msgCode !== ''"
              :disabled="inviteOwner.hasOwnProperty('name')"
              v-mask="'XXXXXXX'"
              type="text"
              label="邀请码"
              hint="账号注册必须先要获得邀请码，请联系企业管理员获取。"
              outlined
              color="secondary"
              dense
              required
            >
              <template v-slot:append>
                <v-scale-transition leave-absolute>
                  <v-progress-circular
                    v-if="register.chkCode"
                    :width="2"
                    size="24"
                    color="secondary"
                    indeterminate
                  ></v-progress-circular>
                </v-scale-transition>
              </template>
            </v-text-field>
            <v-text-field
              v-model="register.name"
              type="text"
              :rules="[rules.required]"
              label="真实姓名"
              hint="真实姓名用于系统显示，不作为登陆凭证。"
              outlined
              color="secondary"
              dense
              required
            >
            </v-text-field>
            <v-text-field
              v-model="register.username"
              :rules="[rules.required, rules.email]"
              :error-messages="register.msgName"
              :clearable="register.msgName !== ''"
              type="text"
              label="电子邮件"
              hint="电子邮件将作为登陆名称和消息通知使用"
              outlined
              color="secondary"
              dense
              required
            >
              <template v-slot:append>
                <v-scale-transition leave-absolute>
                  <v-progress-circular
                    v-if="register.chkName"
                    :width="2"
                    size="24"
                    color="secondary"
                    indeterminate
                  ></v-progress-circular>
                </v-scale-transition>
              </template>
            </v-text-field>
            <v-text-field
              v-model="register.password"
              :rules="[rules.required]"
              :type="showPass ? 'text' : 'password'"
              :append-icon="showPass ? 'visibility_off' : 'visibility'"
              label="账号密码"
              hint="请牢记此密码，密码将作为登陆凭证"
              outlined
              color="secondary"
              dense
              required
              @click:append="showPass = !showPass"
            >
            </v-text-field>
            <v-text-field
              v-if="$route.query.owner === 'Enterprise'"
              v-model="register.employeeNo"
              type="text"
              label="员工编号"
              hint="员工编号为企业内部的员工编号"
              outlined
              color="secondary"
              dense>
            </v-text-field>
            <v-text-field
              v-model="register.phone"
              :rules="[rules.sensms]"
              type="number"
              label="手机号码"
              hint="电话号码将用于登陆平台和消息通知使用"
              :error-messages="register.msgPhone"
              outlined
              color="secondary"
              dense
            >
              <template v-slot:append>
                <v-btn @click="sendSMS" :disabled="!smsShow" text style="top: -3px" offset-y>{{smsText}}</v-btn>
              </template>
            </v-text-field>
            <v-text-field
              v-model="register.smsCode"
              :rules="[rules.required]"
              :error-messages="register.msgSMS"
              :clearable="register.msgSMS !== ''"
              v-mask="'####'"
              label="短信接收到的验证码"
              hint="短信验证码为4位数字"
              outlined
              color="secondary"
              dense
              required
            >
              <template v-slot:append>
                <v-scale-transition leave-absolute>
                  <v-progress-circular
                    v-if="register.chkSMS"
                    :width="2"
                    size="24"
                    color="secondary"
                    indeterminate
                  ></v-progress-circular>
                </v-scale-transition>
              </template>
            </v-text-field>
            <v-row align="center" justify="center" class="mt-n4" v-for="(item, i) in inviteOwner.customFields" :key="i">
              <v-col cols="12" v-if="item.format.type === 'text'">
                <v-text-field
                  v-if="item.format.mask"
                  v-model="item.value"
                  v-mask="item.format.mask"
                  :label="item.name"
                  :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                  outlined
                  dense>
                </v-text-field>
                <v-text-field
                  v-else
                  v-model="item.value"
                  :label="item.name"
                  :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
              <v-col cols="12" v-if="item.format.type === 'selects'">
                <v-autocomplete
                  autocomplete="off"
                  v-model="item.value"
                  :items="item.format.items"
                  :label="item.name"
                  :rules="item.format.rules ? item.format.rules.map(item => rules[item]) : []"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text color="secondary" @click="$router.push({path:'/login'})">已有账号？立即登陆</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="resetForm"
          >清空</v-btn>
          <v-btn
            text
            color="secondary"
            :disabled="!valid"
            @click="submit"
          >注册</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import _ from 'lodash'
import { SET_ERROR } from "@/store/mutations.type";
import {
  SEND_SMS_CODE,
  CHECK_USERNAME,
  CHECK_ENTERPRISE_CODE,
  CHECK_DEALER_CODE,
  CHECK_SMS_CODE,
  CREATE_ACCOUNT } from "@/store/actions.type";
import store from "@/store";
export default {
  data () {
    const defaultRegister = Object.freeze({
      ownerType: '',
      inviteCode: '',
      name: '',
      username: '',
      password: '',
      phone: '',
      employeeNo: '',
      smsCode: '',
      chkName: false,
      chkSMS: false,
      chkCode: false,
      msgName: '',
      msgPhone: '',
      msgSMS: '',
      msgCode: '',
    });
    return {
      valid: true,
      showPass: false,
      register: Object.assign({}, defaultRegister),
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        sensms: value => {
          const pattern = /^1[3456789]\d{9}$/

          if (pattern.test(value)) {
            this.smsShow = true;
            return true;
          } else {
            this.smsShow = false;
            return '请输入正确的电话号码';
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      defaultRegister,
      inviteOwner: {},
      smsTimer: null,
      smsText: '发送验证码',
      smsShow: false,
    }
  },
  watch: {
    'register.username'() {
      this.checkName();
    },
    'register.inviteCode'() {
      this.checkCode();
    },
    'register.smsCode'() {
      this.checkSMS();
    }
  },
  computed: {},
  mounted() {},
  methods: {
    async submit () {
      this.register.ownerType = this.$route.query.owner
      this.register.customFields = []
      if (this.inviteOwner.customFields && this.inviteOwner.customFields.length) {
        this.inviteOwner.customFields.forEach(item => {
          this.register.customFields.push({ _id: item._id, value: item.value })
        })
      }
      this.$store.dispatch(CREATE_ACCOUNT, this.register)
        .then(() => {
          store.commit(SET_ERROR, {msg: '注册成功，请直接登陆。', color: 'success'})
          this.resetForm();
          this.$router.push( this.redirect || '/')
        })
        .catch(({ response }) => {
          store.commit(SET_ERROR, {msg: response.data.message})
        })
    },
    checkName: _.debounce(function () {
      if (this.register.username) {
        this.register.chkName = true;
        this.$store.dispatch(CHECK_USERNAME, this.register.username)
          .then(() => {
            this.register.msgName = '账号已被注册，请更换其它名称';
            this.register.chkName = false;
          })
          .catch(() => {
            this.register.msgName = '';
            this.register.chkName = false;
          })
      }
    }, 1000),
    checkCode: _.debounce(function() {
      if (this.register.inviteCode) {
        this.register.chkCode = true;
        this.$store.dispatch(this.$route.query.owner === 'Dealer' ? CHECK_DEALER_CODE : CHECK_ENTERPRISE_CODE, this.register.inviteCode)
          .then(({ data }) => {
            this.$forceUpdate()
            this.register.msgCode = ''
            this.inviteOwner = data
            if (data.customFields && data.customFields.length) this.inviteOwner.customFields = data.customFields.map(item => ({...item, value: ''}))
            this.register.chkCode = false
          })
          .catch((err) => {
            this.register.msgCode = err.message
            this.inviteOwner = Object.assign({}, {})
            this.register.chkCode = false
          })
      }
    }, 500),
    checkSMS: _.debounce(function () {
      if (this.register.smsCode && this.register.phone) {
        this.register.chkSMS = true;
        this.$store.dispatch(CHECK_SMS_CODE, {phone: this.register.phone, code: this.register.smsCode, type: 'register'})
          .then(() => {
            this.register.msgSMS = '';
            this.register.chkSMS = false;
          })
          .catch((err) => {
            this.register.msgSMS = err.data.message;
            this.register.chkSMS = false;
          })

      }
    },500),
    sendSMS () {
      const TIME_COUNT = 60;
      if (this.register.phone.length === 11) {
        if (!this.smsTimer) {
          this.smsText = TIME_COUNT;
          this.smsShow = false;
          this.smsTimer = setInterval(() => {
            if (this.smsText > 0 && this.smsText <= TIME_COUNT) {
              this.smsText--;
            } else {
              clearInterval(this.smsTimer);
              this.smsText = '发送验证码';
              this.smsTimer = null;
              this.smsShow = true;
            }
          }, 1000);

          this.$store.dispatch(SEND_SMS_CODE, { phone: this.register.phone, type: "register" })
            .then(() => {
              this.register.msgPhone = '';
            }).catch((err) => {
              this.smsText = '发送验证码';
              this.smsTimer = null;
              this.smsShow = true;
              this.register.msgPhone = err.message;
            })
        }
      }
    },
    resetForm () {
      this.register = Object.assign({}, this.defaultRegister);
      this.inviteOwner = Object.assign({}, {});
      this.$refs.register.reset();
    },
  },
}
</script>
