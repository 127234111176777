var render = function () {
  var _vm$editedItem$enterp, _vm$editedItem$enterp2, _vm$editedItem$enterp3, _vm$editedItem$enterp4, _vm$editedItem$establ, _vm$editedItem$regist;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "flat": "",
      "dark": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.title_page()))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": !_vm.valid,
      "icon": ""
    },
    on: {
      "click": _vm.submit
    }
  }, [_c('v-icon', [_vm._v("save")])], 1)], 1), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('v-subheader', [_vm._v("授信详情")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 企业名称 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$editedItem$enterp = _vm.editedItem.enterprise) === null || _vm$editedItem$enterp === void 0 ? void 0 : _vm$editedItem$enterp.name) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 企业授信类型 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$editedItem$enterp2 = _vm.editedItem.enterpriseType) === null || _vm$editedItem$enterp2 === void 0 ? void 0 : _vm$editedItem$enterp2.name) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 所在地 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.city) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 客户当前授信额度 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.creditLine) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 总评分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.totalScore) + "分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 计算时间 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatTime")(_vm.editedItem.updateTime)) + " ")])], 1), _c('v-subheader', [_vm._v("区域层面 " + _vm._s(_vm.editedItem.areaScore) + "分")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 地区生产总值（GDP） ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.GDPTotal1) + "（亿元） ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 国内三线（70城）平均地区生产总值 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.GDPTotal2) + "（亿元） ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 生产总值得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.GDPTotalScore) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 地区生产总值增长率 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(parseFloat(_vm.editedItem.GDPGrowthRate1 * 100).toFixed(2)) + "% ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 国内GDP增长率 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(parseFloat(_vm.editedItem.GDPGrowthRate2 * 100).toFixed(2)) + "% ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" GDP增长率得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.GDPGrowthRateScore) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 当地人均GDP ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.GDPPerCapita1) + "（元） ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 全国人均GDP ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.GDPPerCapita2) + "（元） ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 人均GDP得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.GDPPerCapitaScore) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 土地依赖 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(parseFloat(_vm.editedItem.landDependenceRatio1 * 100).toFixed(2)) + "% ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 参照土地依赖 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(parseFloat(_vm.editedItem.landDependenceRatio2 * 100).toFixed(2)) + "% ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 土地依赖得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.landDependenceScore) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 政府债务负担 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(parseFloat(_vm.editedItem.debtBudgetRatio1 * 100).toFixed(2)) + "% ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 参照政府债务负担 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(parseFloat(_vm.editedItem.debtBudgetRatio2 * 100).toFixed(2)) + "% ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 政府债务负担得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.debtBudgetScore) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 一般预算收入 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.budgetRevenue1) + "（亿元） ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 国内三线（70城）平均一般预算收入 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.budgetRevenue2) + "（亿元） ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 一般预算收入得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.budgetRevenueScore) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 一般预算收入增长率 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(parseFloat(_vm.editedItem.budgetRevenueGrowthRate1 * 100).toFixed(2)) + "% ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 国内一般预算收入增长率 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(parseFloat(_vm.editedItem.budgetRevenueGrowthRate2 * 100).toFixed(2)) + "% ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 一般预算收入增长率得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.budgetRevenueGrowthRateScore) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 财政自给率 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(parseFloat(_vm.editedItem.selfSufficiencyRate1 * 100).toFixed(2)) + "% ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 国内财政自给率 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(parseFloat(_vm.editedItem.selfSufficiencyRate2 * 100).toFixed(2)) + "% ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 财政自给率得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.selfSufficiencyRateScore) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 区域级别 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.cityLevel ? _vm.editedItem.cityLevel.name : '-') + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 区域级别得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.cityLevelScore) + "分 ")])], 1), _c('v-subheader', [_vm._v("企业层面 " + _vm._s(_vm.editedItem.enterpriseScore) + "分")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 行业得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.industry ? _vm.editedItem.industry.name : '-') + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.industry ? _vm.editedItem.industry.score : '-') + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 企业规模得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$editedItem$enterp3 = _vm.editedItem.enterpriseScale) === null || _vm$editedItem$enterp3 === void 0 ? void 0 : _vm$editedItem$enterp3.name) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$editedItem$enterp4 = _vm.editedItem.enterpriseScale) === null || _vm$editedItem$enterp4 === void 0 ? void 0 : _vm$editedItem$enterp4.score) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 企业成立时长得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.estYear) + "年 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$editedItem$establ = _vm.editedItem.establishYear) === null || _vm$editedItem$establ === void 0 ? void 0 : _vm$editedItem$establ.score) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 注册资金得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.registerCapi) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$editedItem$regist = _vm.editedItem.registerCapital) === null || _vm$editedItem$regist === void 0 ? void 0 : _vm$editedItem$regist.score) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 实缴资金得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.paidCapi) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.paidCapital ? _vm.editedItem.paidCapital.score : '-') + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 人力健康参数得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 人员规模（参保人数）当前年度 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.socialInsuranceCurrent) + "人 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 人员规模（参保人数）历史年度 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.socialInsuranceHitory) + "人 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.humanHealthScore) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 经营异常&司法舆情 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, _vm._l(_vm.editedItem.exception, function (item, i) {
    return _c('v-row', {
      key: '_exception_' + i
    }, [_c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(_vm.excepTypeList.find(function (n) {
      return n.code === item.excepType;
    }) ? _vm.excepTypeList.find(function (n) {
      return n.code === item.excepType;
    }).name : '') + " ")]), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(item.score) + " ")])], 1);
  }), 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 企业附加信息 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 企业管理层（法人/实控人/负责人/财务/监事） ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.additionDataManagerScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "additionDataManagerScore", $$v);
      },
      expression: "editedItem.additionDataManagerScore"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 核心职工人数/总职工人数 ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.additionDataEmployeeNumScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "additionDataEmployeeNumScore", $$v);
      },
      expression: "editedItem.additionDataEmployeeNumScore"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 企业上下游合作方（上下游各5家） ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.additionDataPartnerScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "additionDataPartnerScore", $$v);
      },
      expression: "editedItem.additionDataPartnerScore"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 流量曝光度 ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.additionDataTrafficScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "additionDataTrafficScore", $$v);
      },
      expression: "editedItem.additionDataTrafficScore"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 财务实力 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 总资产 （" + _vm._s(_vm.editedItem.totalAssets) + "） ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.totalAssetsScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "totalAssetsScore", $$v);
      },
      expression: "editedItem.totalAssetsScore"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 货币资金 ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.moneyFundsScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "moneyFundsScore", $$v);
      },
      expression: "editedItem.moneyFundsScore"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 净资产 ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.netAssetsScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "netAssetsScore", $$v);
      },
      expression: "editedItem.netAssetsScore"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 营业总收入（" + _vm._s(_vm.editedItem.totalIncome) + "） ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.totalIncomeScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "totalIncomeScore", $$v);
      },
      expression: "editedItem.totalIncomeScore"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 净利润（" + _vm._s(_vm.editedItem.netProfit) + "） ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.netProfitScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "netProfitScore", $$v);
      },
      expression: "editedItem.netProfitScore"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 资产负债率 ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.debtRatioScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "debtRatioScore", $$v);
      },
      expression: "editedItem.debtRatioScore"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 流动比率 ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.currentRatioScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "currentRatioScore", $$v);
      },
      expression: "editedItem.currentRatioScore"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 现金流量利息保障倍数 ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.cashInterestRatioScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "cashInterestRatioScore", $$v);
      },
      expression: "editedItem.cashInterestRatioScore"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 经营性收入/营业总收入 ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.incomeRatioScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "incomeRatioScore", $$v);
      },
      expression: "editedItem.incomeRatioScore"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 存货周转天数 ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.inventoryDayScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "inventoryDayScore", $$v);
      },
      expression: "editedItem.inventoryDayScore"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(" 应收账款周转天数 ")]), _c('v-col', {
    attrs: {
      "cols": "1"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "得分",
      "hint": "请输入得分",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.accountDayScore,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "accountDayScore", $$v);
      },
      expression: "editedItem.accountDayScore"
    }
  })], 1)], 1)], 1)], 1), _c('v-subheader', [_vm._v("我司层面 " + _vm._s(_vm.editedItem.companyScore) + "分")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 与我司合作时长得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.coopYear) + "年 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.cooperationYear && _vm.editedItem.cooperationYear.score) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 付款条件（账期）得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.paymentPrd == null ? '-' : _vm.editedItem.paymentPrd) + "天 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.paymentPeriod && _vm.editedItem.paymentPeriod.score) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 历史交易金额得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.historyAmt == null ? '-' : _vm.editedItem.historyAmt) + "元 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.historyAmount && _vm.editedItem.historyAmount.score) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 最长逾期时间得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.maxOverdDay == null ? '-' : _vm.editedItem.maxOverdDay) + "天 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.maxOverdueDay && _vm.editedItem.maxOverdueDay.score) + "分 ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 逾期金额/交易金额得分 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.overdueAmtRatio == null ? '-' : (_vm.editedItem.overdueAmtRatio * 100).toFixed(2)) + "% ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.overdueAmountRatio && _vm.editedItem.overdueAmountRatio.score) + "分 ")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")]), _c('v-spacer')], 1)], 1), _c('v-tabs', {
    ref: "logs",
    staticClass: "mt-4"
  }, [_c('v-tab', [_vm._v("变更记录")]), _c('v-tab', [_vm._v("超额记录")]), _c('v-tab-item', [_vm.logList && _vm.logList.length ? _c('v-timeline', {
    attrs: {
      "dense": "",
      "clipped": "",
      "align-top": ""
    }
  }, _vm._l(_vm.logList, function (item, n) {
    return _c('v-timeline-item', {
      key: n,
      attrs: {
        "small": "",
        "fill-dot": "",
        "icon": item.action === 'Create' ? 'add' : item.action === 'Batch' ? 'layers' : 'create'
      }
    }, [_c('v-row', {
      staticClass: "mt-n4"
    }, [_c('v-col', {
      attrs: {
        "cols": "5"
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))]), _vm._v(" " + _vm._s(_vm._f("stringModule")(item.action)) + " "), _c('div', {
      staticClass: "caption"
    }, [_c('widgets-employee-dialogs', {
      staticClass: "v-list-item--link",
      attrs: {
        "account": item.operator._id
      }
    }, [_vm._v(_vm._s(item.operator.personal.name ? item.operator.personal.name : '*未设置姓名')), _c('v-icon', {
      staticClass: "mx-2"
    }, [_vm._v("read_more")])], 1)], 1)]), item.record.length ? _c('v-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "7"
      }
    }, _vm._l(item.record, function (change, j) {
      return _c('div', {
        key: j,
        staticClass: "caption text-left"
      }, [item.action === 'Batch' ? _c('span', [_vm._v(_vm._s(change.text) + ": " + _vm._s(change.value))]) : _c('span', [_vm._v(_vm._s(change))])]);
    }), 0) : _vm._e()], 1)], 1);
  }), 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }