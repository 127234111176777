var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.title_page()))]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-expansion-panels', {
    attrs: {
      "multiple": "",
      "accordion": "",
      "flat": ""
    },
    model: {
      value: _vm.openPanel,
      callback: function callback($$v) {
        _vm.openPanel = $$v;
      },
      expression: "openPanel"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("基本信息")]), _c('v-expansion-panel-content', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "设备名称*",
      "hint": "设备名称是该设备的标示，如开发电脑，新员工笔记本等",
      "prepend-icon": "bookmark",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    staticClass: "ml-4",
    attrs: {
      "items": _vm.stateList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "label": "设备状态*",
      "hint": "设备状态用来记录当前设备的状态，可在系统后台维护状态类型。",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.state,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "state", $$v);
      },
      expression: "editedItem.state"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.vendorList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "prepend-icon": "devices",
      "label": "产品供应商*",
      "hint": "请选择产品供应商"
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_product_list');
      }
    },
    model: {
      value: _vm.editedItem.vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "vendor", $$v);
      },
      expression: "editedItem.vendor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    staticClass: "mx-4",
    attrs: {
      "items": _vm.typeList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "产品类型",
      "hint": "请选择产品类型"
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_product_list');
      }
    },
    model: {
      value: _vm.editedItem.productType,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "productType", $$v);
      },
      expression: "editedItem.productType"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.editedItem.product ? _vm.productList : _vm.productList.filter(function (item) {
        return item.selected.includes('default');
      }),
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "关联产品*",
      "hint": "请选择设备关联的产品"
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_warranty_time');
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name) + " - " + _vm._s(data.item.part_number))]), data.item.it_info ? _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.it_info.ram) + "G - " + _vm._s(data.item.it_info.network))]) : _vm._e()], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.product,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "product", $$v);
      },
      expression: "editedItem.product"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [!_vm.noSN ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "prepend-icon": "style",
      "label": "设备序列号*",
      "hint": "设备序列号是设备的唯一标示",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.serial_number,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "serial_number", $$v);
      },
      expression: "editedItem.serial_number"
    }
  }) : _c('v-text-field', {
    attrs: {
      "type": "text",
      "prepend-icon": "style",
      "label": "设备序列号",
      "value": "所选产品不需要序列号",
      "outlined": "",
      "dense": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-combobox', {
    staticClass: "mx-4",
    attrs: {
      "label": "设备标签",
      "hint": "使用回车键可以输入多个标签。如内部设备代码、编码等信息",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.label,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "label", $$v);
      },
      expression: "editedItem.label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "外部编号",
      "hint": "如企业资产编号等",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.out_number,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "out_number", $$v);
      },
      expression: "editedItem.out_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "prepend-icon": "shopping_cart",
            "label": "购买日期",
            "readonly": "",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.editedItem.purchase_time,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "purchase_time", $$v);
            },
            expression: "editedItem.purchase_time"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.purchase_time,
      callback: function callback($$v) {
        _vm.purchase_time = $$v;
      },
      expression: "purchase_time"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        return _vm.doAction('set_warranty_deadline');
      }
    },
    model: {
      value: _vm.editedItem.purchase_time,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "purchase_time", $$v);
      },
      expression: "editedItem.purchase_time"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "suffix": "月",
      "type": "number",
      "label": "厂商质保期",
      "hint": "厂商质保期在产品管理中可以设置默认值",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_warranty_deadline');
      }
    },
    model: {
      value: _vm.editedItem.warranty_time,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "warranty_time", _vm._n($$v));
      },
      expression: "editedItem.warranty_time"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "date",
      "label": "质保到期",
      "hint": "质保到期时间",
      "outlined": "",
      "dense": "",
      "disabled": ""
    },
    model: {
      value: _vm.editedItem.warranty_deadline,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "warranty_deadline", $$v);
      },
      expression: "editedItem.warranty_deadline"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.warehouseList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "prepend-icon": "home_work",
      "label": "仓库",
      "hint": "请选择设备所在仓库"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name) + " - " + _vm._s(data.item.center_code))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.address))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.warehouse,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "warehouse", $$v);
      },
      expression: "editedItem.warehouse"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "ml-4",
          attrs: {
            "label": "入库日期",
            "readonly": "",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.editedItem.warehouse_time,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "warehouse_time", $$v);
            },
            expression: "editedItem.warehouse_time"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.warehouse_time,
      callback: function callback($$v) {
        _vm.warehouse_time = $$v;
      },
      expression: "warehouse_time"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.warehouse_time = false;
      }
    },
    model: {
      value: _vm.editedItem.warehouse_time,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "warehouse_time", $$v);
      },
      expression: "editedItem.warehouse_time"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "loading": _vm.enterpriseLoading,
      "items": _vm.enterpriseList,
      "search-input": _vm.searchEnterprise,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "hide-no-data": "",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "所属企业",
      "hint": "输入企业名称进行检索",
      "prepend-icon": "corporate_fare"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchEnterprise = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchEnterprise = $event;
      },
      "change": function change($event) {
        return _vm.doAction('set_employee_list');
      }
    },
    model: {
      value: _vm.editedItem.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "enterprise", $$v);
      },
      expression: "editedItem.enterprise"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    staticClass: "mx-4",
    attrs: {
      "items": _vm.branchList,
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "下属企业",
      "hint": "请选择设备所属下属企业"
    },
    model: {
      value: _vm.editedItem.branch,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "branch", $$v);
      },
      expression: "editedItem.branch"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.employeeList,
      "loading": _vm.employeeLoading,
      "search-input": _vm.searchEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "使用人员",
      "hint": "请选择设备使用人员"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchEmployee = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchEmployee = $event;
      },
      "change": function change($event) {
        return _vm.doAction('set_asset_user');
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.personal.name || '未设置名称') + " ")];
      }
    }, {
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.account,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "account", $$v);
      },
      expression: "editedItem.account"
    }
  })], 1)], 1)], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("详细信息")]), _c('v-expansion-panel-content', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "设备来源",
      "hint": "设备获得方式，如采购、回收等",
      "prepend-icon": "device_unknown",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.asset_from,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "asset_from", $$v);
      },
      expression: "editedItem.asset_from"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    staticClass: "ml-4",
    attrs: {
      "autocomplete": "off",
      "items": _vm.assetState,
      "multiple": "",
      "label": "设备情况",
      "hint": "请选择设备使用情况",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.asset_state,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "asset_state", $$v);
      },
      expression: "editedItem.asset_state"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.guaranteedType,
      "label": "延保类型",
      "hint": "请选择延保类型",
      "item-text": "name",
      "item-value": "code",
      "prepend-icon": "construction",
      "outlined": "",
      "dense": "",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": ""
    },
    model: {
      value: _vm.editedItem.guaranteed_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "guaranteed_type", $$v);
      },
      expression: "editedItem.guaranteed_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
            attrs = _ref3.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "延保开始时间",
            "prepend-icon": "event",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "click:clear": function clickClear($event) {
              return _vm.doAction('clearGuaranteed');
            }
          },
          model: {
            value: _vm.editedItem.guaranteed_time,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "guaranteed_time", $$v);
            },
            expression: "editedItem.guaranteed_time"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.guaranteed_time,
      callback: function callback($$v) {
        _vm.guaranteed_time = $$v;
      },
      expression: "guaranteed_time"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        return _vm.doAction('set_guaranteed_deadline');
      }
    },
    model: {
      value: _vm.editedItem.guaranteed_time,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "guaranteed_time", $$v);
      },
      expression: "editedItem.guaranteed_time"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "suffix": "月",
      "type": "number",
      "label": "延保期限",
      "hint": "延保期限单位月",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_guaranteed_deadline');
      }
    },
    model: {
      value: _vm.editedItem.guaranteed_limit,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "guaranteed_limit", _vm._n($$v));
      },
      expression: "editedItem.guaranteed_limit"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "date",
      "label": "延保到期",
      "hint": "延保到期时间",
      "outlined": "",
      "dense": "",
      "disabled": ""
    },
    model: {
      value: _vm.editedItem.guaranteed_deadline,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "guaranteed_deadline", $$v);
      },
      expression: "editedItem.guaranteed_deadline"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "备注",
      "hint": "请输入设备备注信息",
      "prepend-icon": "comment_bank",
      "rows": "4",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "remarks", $$v);
      },
      expression: "editedItem.remarks"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-list', {
    attrs: {
      "subheader": "",
      "dense": ""
    }
  }, [_c('v-subheader', [_vm._v("附件清单")]), _vm._l(_vm.editedItem.files, function (file, i) {
    return _c('v-list-item', {
      key: file.href
    }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("text_snippet")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(file.name)
      }
    })], 1), _c('v-list-item-action', {
      staticClass: "d-inline"
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.doAction('remove_file', '', i);
        }
      }
    }, [_vm._v("delete")])], 1), _c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.doAction('download_file', '', file.href);
        }
      }
    }, [_vm._v("get_app")])], 1)], 1)], 1);
  }), _c('v-list-item', [_c('v-list-item-content', [_vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('input', {
    ref: "uploadFile",
    attrs: {
      "type": "file",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-list-item-title', {
    staticClass: "text-center"
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('upload_file', '', -1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("cloud_upload")]), _vm._v("上传附件")], 1)], 1)], 1)], 1)], 2)], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "my-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        _vm.showITInfo = !_vm.showITInfo;
      }
    }
  }, [_vm._v(_vm._s(_vm.showITInfo ? '隐藏' : '编辑') + "IT属性")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        _vm.showRentInfo = !_vm.showRentInfo;
      }
    }
  }, [_vm._v(_vm._s(_vm.showRentInfo ? '隐藏' : '编辑') + "租赁属性")])], 1)], 1), _vm.showITInfo ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "ml-8",
    attrs: {
      "type": "string",
      "label": "Apple ID",
      "hint": "请输入登录Apple的用户名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.apple_id,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "apple_id", $$v);
      },
      expression: "editedItem.apple_id"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "type": "string",
      "label": "Apple Password",
      "hint": "请输入对应Apple ID的用户名的密码",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.apple_password,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "apple_password", $$v);
      },
      expression: "editedItem.apple_password"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": [{
        name: '是',
        code: true
      }, {
        name: '否',
        code: false
      }],
      "label": "是否ABM",
      "hint": "请选择设备是否ABM",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.isABM,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "isABM", $$v);
      },
      expression: "editedItem.isABM"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": [{
        name: '是',
        code: true
      }, {
        name: '否',
        code: false
      }],
      "label": "SIM卡实名",
      "hint": "请选择SIM卡是否已经实名认证",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.sim_verified,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "sim_verified", $$v);
      },
      expression: "editedItem.sim_verified"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "ml-8",
    attrs: {
      "type": "string",
      "label": "SIM卡号",
      "hint": "请输入设备的SIM卡号",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.sim_code,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "sim_code", $$v);
      },
      expression: "editedItem.sim_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "type": "string",
      "label": "MAC地址",
      "hint": "请输入设备的MAC地址",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.mac_address,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "mac_address", $$v);
      },
      expression: "editedItem.mac_address"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "string",
      "label": "IMEI编号",
      "hint": "请输入设备的IMEI编号",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.imei_number,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "imei_number", $$v);
      },
      expression: "editedItem.imei_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "ml-8",
    attrs: {
      "type": "string",
      "label": "ICCID串号",
      "hint": "请输入设备的ICCID串号",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.iccid_code,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "iccid_code", $$v);
      },
      expression: "editedItem.iccid_code"
    }
  })], 1)], 1) : _vm._e(), _vm.showRentInfo ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    staticClass: "ml-8",
    attrs: {
      "autocomplete": "off",
      "items": _vm.rentType,
      "label": "租赁方式",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    model: {
      value: _vm.editedItem.rent_info.rent_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info, "rent_type", $$v);
      },
      expression: "editedItem.rent_info.rent_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    staticClass: "mx-4",
    attrs: {
      "autocomplete": "off",
      "items": Array.from({
        length: 28
      }, function (v, k) {
        return k + 1;
      }),
      "label": "客户付款日",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rent_info.payment_day,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info, "payment_day", $$v);
      },
      expression: "editedItem.rent_info.payment_day"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "账期（天）",
      "outlined": "",
      "dense": "",
      "type": "number"
    },
    model: {
      value: _vm.editedItem.rent_info.payment_period,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info, "payment_period", $$v);
      },
      expression: "editedItem.rent_info.payment_period"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
            attrs = _ref4.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "ml-8",
          attrs: {
            "label": "起租日期",
            "readonly": "",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.editedItem.rent_info.lease_start,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem.rent_info, "lease_start", $$v);
            },
            expression: "editedItem.rent_info.lease_start"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 1557543867),
    model: {
      value: _vm.editedItem.showLeaseStartMenu,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "showLeaseStartMenu", $$v);
      },
      expression: "editedItem.showLeaseStartMenu"
    }
  }, [_c('v-date-picker', {
    on: {
      "input": function input($event) {
        _vm.editedItem.showLeaseStartMenu = false;
      },
      "change": _vm.setLeaseEndDate
    },
    model: {
      value: _vm.editedItem.rent_info.lease_start,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info, "lease_start", $$v);
      },
      expression: "editedItem.rent_info.lease_start"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    staticClass: "mx-4",
    attrs: {
      "autocomplete": "off",
      "items": _vm.leaseTerm,
      "item-text": "name",
      "item-value": "code",
      "label": "租期（年）",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.setLeaseEndDate
    },
    model: {
      value: _vm.editedItem.rent_info.lease_term,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info, "lease_term", $$v);
      },
      expression: "editedItem.rent_info.lease_term"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.paymentCycle,
      "item-text": "name",
      "item-value": "code",
      "label": "支付周期",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": _vm.setLeaseEndDate
    },
    model: {
      value: _vm.editedItem.rent_info.payment_cycle,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info, "payment_cycle", $$v);
      },
      expression: "editedItem.rent_info.payment_cycle"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "ml-8",
    attrs: {
      "label": "期数",
      "outlined": "",
      "dense": "",
      "disabled": ""
    },
    model: {
      value: _vm.editedItem.rent_info.total_period,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info, "total_period", $$v);
      },
      expression: "editedItem.rent_info.total_period"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "label": "到期时间",
      "outlined": "",
      "dense": "",
      "disabled": ""
    },
    model: {
      value: _vm.editedItem.rent_info.expiry_date,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info, "expiry_date", $$v);
      },
      expression: "editedItem.rent_info.expiry_date"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "ml-8",
    attrs: {
      "label": "投资方名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rent_info.stakeholders.investor,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info.stakeholders, "investor", $$v);
      },
      expression: "editedItem.rent_info.stakeholders.investor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "label": "代理商名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rent_info.stakeholders.agent,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info.stakeholders, "agent", $$v);
      },
      expression: "editedItem.rent_info.stakeholders.agent"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "回收商名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rent_info.stakeholders.recycler,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info.stakeholders, "recycler", $$v);
      },
      expression: "editedItem.rent_info.stakeholders.recycler"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    staticClass: "ml-8",
    attrs: {
      "label": "签约方名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.rent_info.stakeholders.party,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.rent_info.stakeholders, "party", $$v);
      },
      expression: "editedItem.rent_info.stakeholders.party"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v("自定义信息")]), _c('v-expansion-panel-content', _vm._l(_vm.customFields, function (item, i) {
    return _c('v-row', {
      key: i,
      attrs: {
        "no-gutters": ""
      }
    }, [item.format.type === 'text' ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [item.format.mask ? _c('v-text-field', {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: item.format.mask,
        expression: "item.format.mask"
      }],
      attrs: {
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "label": item.format.rules.includes('required') ? item.name + '*' : item.name,
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _c('v-text-field', {
      attrs: {
        "label": item.format.rules.includes('required') ? item.name + '*' : item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.format.type === 'selects' ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": item.format.items,
        "label": item.format.rules.includes('selected') ? item.name + '*' : item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e()], 1);
  }), 1)], 1)], 1)], 1), _c('v-card-actions', [_vm.editedItem.enterprise ? _c('widgets-custom-field', {
    attrs: {
      "module": "Asset",
      "ownerId": _vm.editedItem.enterprise,
      "ownerType": "Enterprise"
    },
    on: {
      "Complete": function Complete($event) {
        return _vm.doAction('update_field');
      }
    }
  }) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }