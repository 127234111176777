var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "secondary",
      "dark": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("客户管理")]), _c('v-spacer')], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 经销商全部人员均可以浏览企业列表，但编辑和审核需要企业管理权限，可以联系平台负责人获取权限。 ")]), _c('v-tabs', {
    attrs: {
      "color": "secondary",
      "grow": "",
      "icons-and-text": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tabs-slider'), _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab', {
      key: tab.id,
      attrs: {
        "to": tab.route
      }
    }, [_vm._v(" " + _vm._s(tab.name) + " "), _c('v-icon', [_vm._v(_vm._s(tab.icon))])], 1);
  }), _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab-item', {
      key: tab.id,
      attrs: {
        "value": tab.route,
        "transition": false,
        "reverse-transition": false
      }
    }, [tab.route === _vm.$route.fullPath && tab.route === _vm.activeTab ? _c('router-view') : _vm._e()], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }