var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "flat": "",
      "dark": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("资产配置")]), _c('v-spacer')], 1), _c('v-card-title', [_vm._v(" 提示 ")]), _c('v-card-subtitle', [_vm._v("在使用设备管理模块前需要在这里进行基础配置。包括库房、供应商（品牌厂商）、产品分类、产品、设备状态的配置，只有这些配置完成后，设备管理才可以正常使用。")]), _c('v-tabs', {
    attrs: {
      "color": "secondary",
      "grow": "",
      "icons-and-text": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tabs-slider'), _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab', {
      key: tab.id,
      attrs: {
        "to": tab.route
      }
    }, [_vm._v(" " + _vm._s(tab.name) + " "), _c('v-icon', [_vm._v(_vm._s(tab.icon))])], 1);
  }), _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab-item', {
      key: tab.id,
      attrs: {
        "value": tab.route,
        "transition": false,
        "reverse-transition": false
      }
    }, [_c('router-view')], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }