<template>
  <div class="fluid">
    <v-data-table
      :headers="headerTable"
      :items="riskEnterpriseTypeList"
      :options.sync="optionsTable"
      :server-items-length="riskEnterpriseTypeCount"
      :loading="riskEnterpriseTypeLoading"
      loading-text="数据读取中... 请稍后"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      fixed-header
      height="600"
      item-key="_id"
      transition
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>授信类型列表</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" depressed color="secondary" @click="doAction('create_item', '', '新增授信类型')">
            <v-icon>add</v-icon> 创建授信类型
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.updateTime="{ item }">
        <td nowrap="true">{{item.updateTime | formatTime}}</td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="doAction('edit_item', item, '编辑授信类型')">edit</v-icon>
        <v-icon small @click="doAction('delete_item', item, false)">delete</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" depressed @click="getDataList()">刷新</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dlgEdit" persistent max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[rules.required]"
                  type="text"
                  label="授信类型名称"
                  hint="请输入授信类型名称"
                  outlined
                  dense
                ></v-text-field>
                <v-text-field
                    v-model="editedItem.creditLineLimit"
                    :rules="[rules.required]"
                    type="number"
                    label="授信额度峰值（元）"
                    hint="授信额度峰值"
                    outlined
                    dense
                  ></v-text-field>
                <v-textarea
                  v-model="editedItem.remarks"
                  outlined
                  auto-grow
                  rows="3"
                  label="备注"
                  value=""
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {
  PUBLISH_RISK_ENTERPRISETYPE,
  EDIT_RISK_ENTERPRISETYPE,
  FETCH_RISK_ENTERPRISETYPE_LIST,
  DELETE_RISK_ENTERPRISETYPE,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
        negative: val => ( val >= 0) || '不能小于0'
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedItem: {
        _id: '',
        name: '',
        creditLineLimit: '',
        remarks: '',
      },
      defaultItem: {
        _id: '',
        name: '',
        creditLineLimit: '',
        remarks: '',
      },
      query: {
        key: '',
      },
      defaultQuery: {
        key: '',
      },
      headerTable: [
        {text: '授信类型名称', value: 'name'},
        {text: '授信额度峰值（元）', value: 'creditLineLimit'},
        {text: '备注', value: 'remarks'},
        {text: '更新时间', width: '125px', value: 'updateTime'},
        {text: '操作', width: '80px', value: 'actions', align: 'end', sortable: false}
      ],
      optionsTable: {},
      activeType: 'import',
      importFailed: [],
      importEnd: false,
      exportEnd: true,
    }
  },
  async created() {
    await this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentEmployer",
      "riskEnterpriseTypeLoading",
      "riskEnterpriseTypeList",
      "riskEnterpriseTypeCount"]),
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  methods: {
    searchItem() {
      this.optionsTable.page = 1
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    clearSearchItem() {
      this.optionsTable.page = 1
      this.query.key = ''
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    async getDataList() {
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + encodeURIComponent(this.query.key)
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_RISK_ENTERPRISETYPE_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    doAction(action, item, customitem) {
      switch (action) {
        case 'create_item':
        case 'edit_item': {
          this.editedIndex = this.riskEnterpriseTypeList.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
        case 'delete_item': {
          if (confirm(`确定要 删除 ${item.name}？`)) {
            store.dispatch(DELETE_RISK_ENTERPRISETYPE, {_id: item._id,})
              .then(() => {
                store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'});
                this.getDataList()
                this.close()
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          }
          break
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    async submit() {
      let strInsert = {}

      strInsert.name = this.editedItem.name;
      strInsert.creditLineLimit = parseFloat(this.editedItem.creditLineLimit);
      strInsert.remarks = this.editedItem.remarks;

      if (this.editedItem._id) {
        strInsert._id = this.editedItem._id
        store.dispatch(EDIT_RISK_ENTERPRISETYPE, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.query.key = ''
            this.query.filterItem = []
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_RISK_ENTERPRISETYPE, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.query.key = ''
            this.query.filterItem = []
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },
    close () {
      this.dlgTitle = '';
      this.dlgEdit = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
  }
}
</script>
