var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "secondary",
      "dark": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("企业员工")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "dark": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "label": "搜索人员姓名、电话或者电子邮件地址",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.doAction('search_employee');
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 企业员工为经销商提供维护所属企业的分组和人员资料管理。"), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("通过右侧筛选按钮选择一家企业后可以创建和导入员工。")])]), _c('v-data-table', {
    attrs: {
      "headers": _vm.headerEmployee,
      "items": _vm.employeeList,
      "options": _vm.employee_options,
      "server-items-length": _vm.employeeCount,
      "loading": _vm.employeeLoading,
      "item-class": _vm.row_class,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "item-key": "_id",
      "show-select": "",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.employee_options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_vm.ownEnterpriseList.length ? _c('v-toolbar-title', [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v("groups")]), _vm._v(" " + _vm._s(_vm.query.enterprise ? _vm.ownEnterpriseList.find(function (item) {
          return item._id === _vm.query.enterprise;
        }).name : '全部人员列表') + " ")], 1) : _vm._e(), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _vm.query.enterprise ? _c('v-autocomplete', {
          attrs: {
            "items": _vm.flatGroupRoles(_vm.groupList),
            "item-text": "name",
            "item-value": "_id",
            "autocomplete": "off",
            "solo": "",
            "dense": "",
            "hide-details": "",
            "label": "请选择角色",
            "append-outer-icon": "add"
          },
          on: {
            "click:append-outer": function clickAppendOuter($event) {
              return _vm.doAction('create_role', _vm.query.enterprise, '创建角色');
            },
            "change": function change($event) {
              return _vm.doAction('change_role');
            }
          },
          scopedSlots: _vm._u([{
            key: "item",
            fn: function fn(data) {
              return [_typeof(data.item) !== 'object' ? [_c('v-list-item-content', {
                domProps: {
                  "textContent": _vm._s(data.item)
                }
              })] : [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name) + _vm._s(!data.item._id ? '' : ' - ' + data.item.count + '人'))]), data.item.type === 9 ? _c('v-list-item-subtitle', [_vm._v("企业系统管理员拥有全部权限且不能修改")]) : data.item.type === 1 ? _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.service.map(function (item) {
                return item.name;
              })))]) : data.item.type === 0 ? _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.service.map(function (item) {
                return item.name;
              })))]) : _vm._e()], 1), _c('v-list-item-action', {
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                  }
                }
              }, [data.item._id && data.item.type !== 9 ? _c('v-btn', {
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.doAction('edit_role', data.item, '修改角色');
                  }
                }
              }, [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("edit")])], 1) : _vm._e()], 1)]];
            }
          }], null, false, 2518196409),
          model: {
            value: _vm.query.roles,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "roles", $$v);
            },
            expression: "query.roles"
          }
        }) : _vm._e(), _c('v-spacer'), _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mx-2",
                attrs: {
                  "depressed": "",
                  "disabled": !_vm.selectedEmployee.length
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" 批量执行 ")])];
            }
          }])
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.doAction('block_employee', _vm.query.enterprise, '注销员工');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("remove_circle")])], 1), _c('v-list-item-title', [_vm._v("注销员工-可恢复")])], 1), _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.doAction('delete_employee', _vm.query.enterprise, '删除员工');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("delete")])], 1), _c('v-list-item-title', [_vm._v("删除员工-不可恢复")])], 1)], 1)], 1), _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "secondary",
                  "depressed": "",
                  "disabled": !_vm.query.enterprise
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("add")]), _vm._v(" 新建员工 ")], 1)];
            }
          }])
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.doAction('create_employee', _vm.query.enterprise, '新增员工');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("add_box")])], 1), _c('v-list-item-title', [_vm._v("通过表单增加单个员工")])], 1), _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.doAction('import_employee', _vm.query.enterprise, '导入员工');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("library_add")])], 1), _c('v-list-item-title', [_vm._v("通过文件增加多个员工")])], 1)], 1)], 1), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('filter_enterprise');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.personal.name",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('widgets-employee-dialogs', {
          attrs: {
            "account": item._id
          },
          on: {
            "UpdateEmployee": function UpdateEmployee($event) {
              return _vm.getEmployeeList();
            }
          }
        }, [_vm._v(_vm._s(item.personal && item.personal.name || '*未设置姓名'))])];
      }
    }, {
      key: "item.employer.ownerId",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', {
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('open_enterprise', item.employer.ownerId);
            }
          }
        }, [_vm._v(_vm._s(item.employer.ownerId.name))])];
      }
    }, {
      key: "item.employer.subordinate",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item.employer.subordinate || '-') + " ")];
      }
    }, {
      key: "item.employer.roles",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(item.employer.roles.map(function (item) {
          return item.name;
        })) + " ")];
      }
    }]),
    model: {
      value: _vm.selectedEmployee,
      callback: function callback($$v) {
        _vm.selectedEmployee = $$v;
      },
      expression: "selectedEmployee"
    }
  })], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.filterDrawer = !_vm.filterDrawer;
            }
          }
        }, [_vm._v("隐藏")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('clear_filter');
            }
          }
        }, [_vm._v("清空")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("选择企业")]), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.ownEnterpriseList,
      "label": "企业",
      "hint": "请选择一家企业",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "enterprise", $$v);
      },
      expression: "query.enterprise"
    }
  }), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("员工状态")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.isBlock,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isBlock", $$v);
      },
      expression: "query.isBlock"
    }
  }, [_c('v-chip', {
    attrs: {
      "value": false,
      "filter": "",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("正常")]), _c('v-chip', {
    attrs: {
      "value": true,
      "filter": "",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("已注销")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.doAction('close_info');
      }
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _vm.dlgTitle === '创建角色' || _vm.dlgTitle === '修改角色' ? _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "角色名称",
      "hint": "请输入角色名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.roles.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.roles.editedItem, "name", $$v);
      },
      expression: "roles.editedItem.name"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.uniqueService(_vm.serviceList),
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "label": "请选择角色所含服务",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": ""
    },
    model: {
      value: _vm.roles.editedItem.service,
      callback: function callback($$v) {
        _vm.$set(_vm.roles.editedItem, "service", $$v);
      },
      expression: "roles.editedItem.service"
    }
  })], 1) : _vm._e(), _vm.dlgTitle === '新增员工' ? _c('v-card-text', [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "start",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var hover = _ref8.hover;
        return [_c('v-avatar', [_c('v-img', {
          attrs: {
            "src": _vm.employee.editedItem.profile ? _vm.ossURL + '/' + _vm.employee.editedItem.profile : '/static/grey_silhouette.png',
            "alt": "设置头像",
            "aspect-ratio": "1"
          }
        }, [_vm.Upload.uploading ? _c('v-progress-circular', {
          attrs: {
            "color": "secondary",
            "rotate": "360",
            "size": "48",
            "width": "5",
            "value": _vm.Upload.progressUpload
          }
        }) : _vm._e()], 1), hover ? _c('div', {
          staticClass: "show_upload",
          staticStyle: {
            "height": "100%",
            "cursor": "pointer"
          }
        }, [_c('v-icon', {
          on: {
            "click": function click($event) {
              return _vm.doAction('upload_avatar', _vm.employee.editedItem);
            }
          }
        }, [_vm._v("add_a_photo")])], 1) : _vm._e()], 1)];
      }
    }], null, false, 3117978003)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "员工姓名",
      "hint": "员工姓名为用户的真实姓名",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.employee.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "name", $$v);
      },
      expression: "employee.editedItem.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("https")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "error-messages": _vm.employee.editedItem.msgName,
      "clearable": _vm.employee.editedItem.msgName !== '',
      "type": "text",
      "label": "电子邮件",
      "hint": "电子邮件将作为登陆名称使用",
      "outlined": "",
      "dense": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkName ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 3805399357),
    model: {
      value: _vm.employee.editedItem.username,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "username", $$v);
      },
      expression: "employee.editedItem.username"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("vpn_key")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": _vm.employee.editedItem.showPass ? 'text' : 'password',
      "append-icon": _vm.employee.editedItem.showPass ? 'visibility_off' : 'visibility',
      "label": "账号密码",
      "hint": "请牢记此密码，密码将作为登陆凭证",
      "outlined": "",
      "dense": "",
      "required": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.employee.editedItem.showPass = !_vm.employee.editedItem.showPass;
      }
    },
    model: {
      value: _vm.employee.editedItem.password,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "password", $$v);
      },
      expression: "employee.editedItem.password"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("stay_current_portrait")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "error-messages": _vm.employee.editedItem.msgPhone,
      "clearable": _vm.employee.editedItem.msgPhone !== '',
      "type": "number",
      "label": "手机号码",
      "hint": "手机号码可作为登陆名称使用",
      "outlined": "",
      "color": "secondary",
      "dense": "",
      "required": ""
    },
    nativeOn: {
      "mousewheel": function mousewheel($event) {
        $event.preventDefault();
      },
      "DOMMouseScroll": function DOMMouseScroll($event) {
        $event.preventDefault();
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkPhone ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 1554159462),
    model: {
      value: _vm.employee.editedItem.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "phone", $$v);
      },
      expression: "employee.editedItem.phone"
    }
  })], 1)], 1), _vm.query.enterprise && _vm.ownEnterpriseList.find(function (e) {
    return e._id === _vm.query.enterprise;
  }).branch ? _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("account_tree")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.ownEnterpriseList.find(function (e) {
        return e._id === _vm.query.enterprise;
      }).branch,
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择员工所属分公司"
    },
    model: {
      value: _vm.employee.editedItem.sub_corp,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "sub_corp", $$v);
      },
      expression: "employee.editedItem.sub_corp"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("badge")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "所属部门",
      "hint": "请填写员工所属部门",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.employee.editedItem.subordinate,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "subordinate", $$v);
      },
      expression: "employee.editedItem.subordinate"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("room")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "error-messages": _vm.employee.editedItem.msgAddress,
      "clearable": _vm.employee.editedItem.msgAddress !== '',
      "type": "text",
      "label": "收件地址",
      "hint": "收件地址为接收设备的目的地",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-fade-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkAddress ? _c('v-progress-circular', {
          attrs: {
            "width": "2",
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "append-outer",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('copy_address', _vm.currentEmployer);
            }
          }
        }, [_vm._v("复制公司地址")])];
      },
      proxy: true
    }], null, false, 1530028620),
    model: {
      value: _vm.employee.editedItem.address,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "address", $$v);
      },
      expression: "employee.editedItem.address"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("supervisor_account")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.flatInputRoles(_vm.groupList),
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择所属角色",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_typeof(data.item) !== 'object' ? [_c('v-list-item-content', {
          domProps: {
            "textContent": _vm._s(data.item)
          }
        })] : [_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "input-value": data.attrs.inputValue
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        })], 1)]];
      }
    }], null, false, 3296285918),
    model: {
      value: _vm.employee.editedItem.roles,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "roles", $$v);
      },
      expression: "employee.editedItem.roles"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("integration_instructions")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工编号",
      "hint": "员工编号为企业内部的员工编号",
      "outlined": "",
      "color": "secondary",
      "dense": ""
    },
    model: {
      value: _vm.employee.editedItem.employeeNo,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "employeeNo", $$v);
      },
      expression: "employee.editedItem.employeeNo"
    }
  })], 1)], 1), _vm._l(_vm.customFields, function (item, i) {
    return _c('v-row', {
      key: i,
      staticClass: "mt-n6",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-center",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-icon', {
      staticClass: "mt-n6",
      attrs: {
        "color": "primary lighten-4"
      }
    }, [_vm._v("list")])], 1), item.format.type === 'text' ? _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [item.format.mask ? _c('v-text-field', {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: item.format.mask,
        expression: "item.format.mask"
      }],
      attrs: {
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _c('v-text-field', {
      attrs: {
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.format.type === 'selects' ? _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": item.format.items,
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e()], 1);
  })], 2)], 1) : _vm._e(), _vm.dlgTitle === '导入员工' ? _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载人员模版，填写成员信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('download_template');
      }
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importFiles($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('upload_employee');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1), _vm.importEnd ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _c('ul', _vm._l(_vm.importFailed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功导入：" + _vm._s(_vm.importSuccess) + " 条记录 ")])], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.dlgTitle === '注销员工' ? _c('v-card-text', [_c('v-alert', {
    attrs: {
      "type": "info"
    }
  }, [_vm._v("注销用户：禁止账号登陆及平台相关操作，但保留账号相关信息，可随时恢复账号。")])], 1) : _vm._e(), _vm.dlgTitle === '删除员工' ? _c('v-card-text', [_c('v-alert', {
    attrs: {
      "type": "error"
    }
  }, [_vm._v("删除用户：删除账号及用户资料，该操作无法恢复。")])], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_vm.dlgTitle === '新增员工' ? _c('widgets-custom-field', {
    attrs: {
      "module": "Account",
      "ownerId": _vm.query.enterprise,
      "ownerType": "Enterprise"
    },
    on: {
      "Complete": function Complete($event) {
        return _vm.doAction('update_field');
      }
    }
  }) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _vm.dlgTitle !== '导入员工' ? _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")]) : _vm._e()], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }