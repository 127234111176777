var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _vm.process_info ? _c('v-toolbar-title', [_vm._v(_vm._s(_vm.enterprise.name) + "：" + _vm._s(_vm.process_info.service_catalog.name) + "工单")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": function click($event) {
        return _vm.submit('');
      }
    }
  }, [_vm._v("创建")])], 1), _vm.process_info ? _c('v-card-text', [_c('v-row', [_vm.process_info.process_type.length ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.process_info.process_type,
      "rules": [_vm.rules.selected],
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择服务类型"
    },
    model: {
      value: _vm.editedItem.process_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "process_type", $$v);
      },
      expression: "editedItem.process_type"
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "外部单号",
      "hint": "企业第三方系统工单编号",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.out_number,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "out_number", $$v);
      },
      expression: "editedItem.out_number"
    }
  })], 1)], 1), _vm.process_info.require_account ? _c('v-row', [_c('v-col', {
    staticClass: "my-n4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "rules": [_vm.rules.required],
      "items": _vm.employeeResult,
      "loading": _vm.loadingEmployee,
      "search-input": _vm.searchEmployee,
      "filter": _vm.filterEmployee,
      "return-object": "",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "item-text": "personal.name",
      "item-value": "_id",
      "label": "选择用户",
      "placeholder": "输入员工姓名、电话或手机号码进行查找",
      "append-outer-icon": "add"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchEmployee = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchEmployee = $event;
      },
      "click:append-outer": _vm.createEmployee
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }], null, false, 59584339),
    model: {
      value: _vm.editedItem.account,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "account", $$v);
      },
      expression: "editedItem.account"
    }
  })], 1), _c('v-col', {
    staticClass: "my-n4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-expand-transition', [_vm.editedItem.account ? _c('v-list', {
    class: _vm.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3',
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', [_vm.editedItem.account.personal ? _c('v-list-item-avatar', [_c('v-img', {
    attrs: {
      "src": _vm.editedItem.account.personal.profile ? _vm.ossURL + '/' + _vm.editedItem.account.personal.profile : '/static/grey_silhouette.png'
    }
  })], 1) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("姓名")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.editedItem.account.personal.name))])], 1)], 1), _c('v-list-item', [_vm.editedItem.account.personal ? _c('v-list-item-avatar') : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("电话")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.editedItem.account.phone))])], 1)], 1), _c('v-list-item', [_vm.editedItem.account.personal ? _c('v-list-item-avatar') : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("电子邮件")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.editedItem.account.username))])], 1)], 1), _c('v-list-item', [_vm.editedItem.account.personal ? _c('v-list-item-avatar') : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("员工号码")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.editedItem.account.employer.employeeNo))])], 1)], 1), _c('v-list-item', [_vm.editedItem.account.personal ? _c('v-list-item-avatar') : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("地址")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.editedItem.account.personal.address))])], 1), _c('v-list-item-action', [_c('widgets-employee-dialogs', {
    attrs: {
      "account": _vm.editedItem.account._id
    },
    on: {
      "UpdateEmployee": _vm.reloadAccount
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("read_more")])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', [_c('v-tab', [_vm._v("关联设备")]), _vm.process_info.isRecovery ? _c('v-tab', [_vm._v("更换新设备")]) : _vm._e(), _c('v-tab', [_vm._v("附件")]), _c('v-tab', [_vm._v("备注")]), _c('v-tab-item', [_c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerAsset,
      "items": _vm.editedItem.link_asset,
      "server-items-length": _vm.editedItem.link_asset.length,
      "footer-props": {
        'items-per-page-options': [5, 10, 15]
      },
      "item-key": "_id",
      "return-object": "false",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-asset-dialogs', {
          attrs: {
            "getId": item._id
          }
        }, [_c('div', [item.isLock ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("lock")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("设备锁定中")])]) : _vm._e(), _vm._v(" " + _vm._s(item.name) + " "), item.ownerType === 'Enterprise' ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("corporate_fare")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("企业自有设备")])]) : item.ownerType === 'Account' ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("person")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("个人自有设备")])]) : _vm._e()], 1)])], 1)];
      }
    }, {
      key: "item.serial_number",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.noSN ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("无序列号")]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.serial_number))])];
      }
    }, {
      key: "item.product",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.product ? _c('td', {
          staticClass: "v-list-item--link",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.openProduct(item.product);
            }
          }
        }, [_vm._v(_vm._s(item.product.name) + " - " + _vm._s(item.product.part_number))]) : _vm._e()];
      }
    }, {
      key: "item.state",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.state.name))])];
      }
    }, {
      key: "item.branch",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.branch))])];
      }
    }, {
      key: "item.asset_state",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.asset_state.map(function (state) {
          return _vm.assetState.find(function (o) {
            return o.code === state;
          }).name;
        })))])];
      }
    }, {
      key: "item.label",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.label))])];
      }
    }, {
      key: "item.account",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.account && item.account.personal ? item.account.personal.name : ''))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('unlink_asset', item);
            }
          }
        }, [_vm._v("remove")])];
      }
    }], null, false, 3689606491)
  }), _c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_asset');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("创建新的设备")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('link_asset');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("link")]), _vm._v("关联现有设备")], 1)], 1)], 1)], 1)], 1), _vm.process_info.isRecovery ? _c('v-tab-item', [_c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerAsset,
      "items": _vm.editedItem.link_asset_new,
      "server-items-length": _vm.editedItem.link_asset_new.length,
      "footer-props": {
        'items-per-page-options': [5, 10, 15]
      },
      "item-key": "_id",
      "return-object": "false",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-asset-dialogs', {
          attrs: {
            "getId": item._id
          }
        }, [_c('div', [item.isLock ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref14) {
              var on = _ref14.on,
                  attrs = _ref14.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("lock")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("设备锁定中")])]) : _vm._e(), _vm._v(" " + _vm._s(item.name) + " "), item.ownerType === 'Enterprise' ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref15) {
              var on = _ref15.on,
                  attrs = _ref15.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("corporate_fare")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("企业自有设备")])]) : item.ownerType === 'Account' ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref16) {
              var on = _ref16.on,
                  attrs = _ref16.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "small": ""
                }
              }, 'v-icon', attrs, false), on), [_vm._v("person")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("个人自有设备")])]) : _vm._e()], 1)])], 1)];
      }
    }, {
      key: "item.serial_number",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [item.noSN ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("无序列号")]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.serial_number))])];
      }
    }, {
      key: "item.product",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [item.product ? _c('td', {
          staticClass: "v-list-item--link",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.openProduct(item.product);
            }
          }
        }, [_vm._v(_vm._s(item.product.name) + " - " + _vm._s(item.product.part_number))]) : _vm._e()];
      }
    }, {
      key: "item.state",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.state.name))])];
      }
    }, {
      key: "item.label",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.label))])];
      }
    }, {
      key: "item.branch",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.branch))])];
      }
    }, {
      key: "item.asset_state",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.asset_state.map(function (state) {
          return _vm.assetState.find(function (o) {
            return o.code === state;
          }).name;
        })))])];
      }
    }, {
      key: "item.account",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.account ? item.account.personal.name : ''))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref24) {
        var item = _ref24.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('unlink_asset_new', item);
            }
          }
        }, [_vm._v("remove")])];
      }
    }], null, false, 2200305364)
  }), _c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('link_asset_new');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("link")]), _vm._v("关联现有设备")], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('v-tab-item', [_c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.editedItem.files, function (file, i) {
    return [_c('v-list-item', {
      key: file.href
    }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("text_snippet")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(file.name)
      }
    })], 1), _c('v-list-item-action', {
      staticClass: "d-inline"
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.doAction('remove_file', '', i);
        }
      }
    }, [_vm._v("delete")])], 1), _c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.doAction('download_file', '', file.href);
        }
      }
    }, [_vm._v("get_app")])], 1)], 1)], 1), _c('v-divider', {
      key: i + '_files_divider',
      attrs: {
        "inset": ""
      }
    })];
  }), _c('v-list-item', [_c('v-list-item-content', [_vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('input', {
    ref: "uploadFile",
    attrs: {
      "type": "file",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-list-item-title', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('upload_file', '', -1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("attach_file")]), _vm._v("上传附件")], 1)], 1)], 1)], 1)], 2)], 1)], 1), _c('v-tab-item', [_c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.editedItem.remarks, function (item, index) {
    return [_c('v-list-item', {
      key: index + '_remarks_title',
      attrs: {
        "three-line": ""
      }
    }, [_c('widgets-employee-dialogs', {
      attrs: {
        "account": item.account
      }
    }, [_c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": item.avatar ? _vm.ossURL + '/' + item.avatar : '/static/grey_silhouette.png'
      }
    })], 1)], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.title) + " " + _vm._s(_vm._f("fromDate")(item.createTime)) + " " + _vm._s(item.private ? ' - 仅个人可见' : '') + " " + _vm._s(item.public ? ' - 客户可见' : ''))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(item.context))])], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.removeRemark(item);
        }
      }
    }, [_vm._v("delete")])], 1)], 1)], 1), _c('v-divider', {
      key: index + '_remarks_divider',
      attrs: {
        "inset": ""
      }
    })];
  }), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.createRemarks();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("rate_review")]), _vm._v("增加备注")], 1)], 1)], 1)], 1)], 2)], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": function click($event) {
        return _vm.submit('');
      }
    }
  }, [_vm._v("提交")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "750px"
    },
    model: {
      value: _vm.dlgAsset,
      callback: function callback($$v) {
        _vm.dlgAsset = $$v;
      },
      expression: "dlgAsset"
    }
  }, [_c('widgets-select-asset', {
    key: "linkAsset",
    ref: "select_asset",
    attrs: {
      "enterprise": _vm.$route.query.enterprise,
      "selectItem": _vm.editedItem.link_asset
    },
    on: {
      "Close": _vm.closeAsset,
      "Selected": _vm.setAssetList
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "750px"
    },
    model: {
      value: _vm.dlgAssetInHouse,
      callback: function callback($$v) {
        _vm.dlgAssetInHouse = $$v;
      },
      expression: "dlgAssetInHouse"
    }
  }, [_c('widgets-select-asset', {
    key: "linkAssetInHouse",
    ref: "select_asset_new",
    attrs: {
      "enterprise": _vm.$route.query.enterprise,
      "isInWarehouse": true,
      "maxLimit": _vm.editedItem.link_asset.length,
      "selectItem": _vm.editedItem.link_asset_new
    },
    on: {
      "Close": _vm.closeAssetInHouse,
      "Selected": _vm.setAssetInHouseList
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.dlgProduct,
      callback: function callback($$v) {
        _vm.dlgProduct = $$v;
      },
      expression: "dlgProduct"
    }
  }, [_c('widgets-product-dialogs', {
    key: _vm.activeProduct,
    attrs: {
      "id": _vm.activeProduct
    },
    on: {
      "Close": function Close($event) {
        return _vm.closeProduct();
      }
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "550px"
    },
    model: {
      value: _vm.dlgRemarks,
      callback: function callback($$v) {
        _vm.dlgRemarks = $$v;
      },
      expression: "dlgRemarks"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 增加备注 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeRemarks
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "\u4EC5\u4E2A\u4EBA\u53EF\u89C1: ".concat(_vm.editedRemark.private ? '是' : '否')
    },
    on: {
      "change": function change($event) {
        return _vm.changeRemarkPublic();
      }
    },
    model: {
      value: _vm.editedRemark.private,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRemark, "private", $$v);
      },
      expression: "editedRemark.private"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-switch', {
    attrs: {
      "label": "\u5BA2\u6237\u53EF\u89C1: ".concat(_vm.editedRemark.public ? '是' : '否')
    },
    on: {
      "change": function change($event) {
        return _vm.changeRemarkPrivate();
      }
    },
    model: {
      value: _vm.editedRemark.public,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRemark, "public", $$v);
      },
      expression: "editedRemark.public"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "备注内容",
      "value": ""
    },
    model: {
      value: _vm.editedRemark.context,
      callback: function callback($$v) {
        _vm.$set(_vm.editedRemark, "context", $$v);
      },
      expression: "editedRemark.context"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeRemarks();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.editedRemark.context
    },
    on: {
      "click": function click($event) {
        return _vm.addRemarks(_vm.editedRemark);
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "500px"
    },
    model: {
      value: _vm.dlgSubmit,
      callback: function callback($$v) {
        _vm.dlgSubmit = $$v;
      },
      expression: "dlgSubmit"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" 确认提交新的服务请求 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeSubmit
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 服务请求提交后将通知经销商进行受理，处理过程和完成将会通过短信、邮件方式进行通知。 ")])], 1), _vm.createResult.length ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.createResult.length) + " 个服务请求；服务请求编号为： ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "grow"
  }, _vm._l(_vm.createResult, function (code) {
    return _c('p', {
      key: code,
      staticClass: "font-weight-black"
    }, [_vm._v(_vm._s(code))]);
  }), 0)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-divider'), !_vm.createResult.length ? _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeSubmit
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _vm.editedItem.link_asset.length > 1 ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref25) {
        var on = _ref25.on,
            attrs = _ref25.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": "",
            "color": "secondary",
            "loading": !_vm.submitEnd
          },
          on: {
            "click": function click($event) {
              return _vm.submit('each');
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("file_copy")]), _vm._v("批量提交")], 1)];
      }
    }], null, false, 3416092080)
  }, [_c('span', [_vm._v("按所选设备提交多个服务请求")])]) : _vm._e(), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref26) {
        var on = _ref26.on,
            attrs = _ref26.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": "",
            "color": "secondary",
            "loading": !_vm.submitEnd
          },
          on: {
            "click": function click($event) {
              return _vm.submit('all');
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("note_add")]), _vm._v("提交")], 1)];
      }
    }], null, false, 3919191770)
  }, [_c('span', [_vm._v("为所选设备提交新的服务请求")])])], 1) : _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v("关闭")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.dlgEmployee,
      callback: function callback($$v) {
        _vm.dlgEmployee = $$v;
      },
      expression: "dlgEmployee"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitEmployee.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validEmployee,
      callback: function callback($$v) {
        _vm.validEmployee = $$v;
      },
      expression: "validEmployee"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("创建员工")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEmployee
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-container', [_c('v-row', {
    attrs: {
      "align": "start",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('input', {
    ref: "uploadAvatar",
    attrs: {
      "id": "filesAvatar",
      "type": "file",
      "name": "filesAvatar",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectAvatar($event);
      }
    }
  }), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref27) {
        var hover = _ref27.hover;
        return [_c('v-avatar', [_c('v-img', {
          attrs: {
            "src": _vm.employee.editedItem.profile ? _vm.ossURL + '/' + _vm.employee.editedItem.profile : '/static/grey_silhouette.png',
            "alt": "设置头像",
            "aspect-ratio": "1"
          }
        }, [_vm.employee.uploading ? _c('v-progress-circular', {
          attrs: {
            "color": "secondary",
            "rotate": "360",
            "size": "48",
            "width": "5",
            "value": _vm.employee.progressUpload
          }
        }) : _vm._e()], 1), hover ? _c('div', {
          staticClass: "show_upload",
          staticStyle: {
            "height": "100%",
            "cursor": "pointer"
          }
        }, [_c('v-icon', {
          on: {
            "click": function click($event) {
              return _vm.doAction('upload_avatar', _vm.employee.editedItem);
            }
          }
        }, [_vm._v("add_a_photo")])], 1) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "员工姓名",
      "hint": "员工姓名为用户的真实姓名",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.employee.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "name", $$v);
      },
      expression: "employee.editedItem.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("https")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "error-messages": _vm.employee.editedItem.msgName,
      "clearable": _vm.employee.editedItem.msgName !== '',
      "type": "text",
      "label": "电子邮件",
      "hint": "电子邮件将作为登陆名称使用",
      "outlined": "",
      "dense": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkName ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.employee.editedItem.username,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "username", $$v);
      },
      expression: "employee.editedItem.username"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("vpn_key")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": _vm.employee.editedItem.showPass ? 'text' : 'password',
      "append-icon": _vm.employee.editedItem.showPass ? 'visibility_off' : 'visibility',
      "label": "账号密码",
      "hint": "请牢记此密码，密码将作为登陆凭证",
      "outlined": "",
      "dense": "",
      "required": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.employee.editedItem.showPass = !_vm.employee.editedItem.showPass;
      }
    },
    model: {
      value: _vm.employee.editedItem.password,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "password", $$v);
      },
      expression: "employee.editedItem.password"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("stay_current_portrait")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "error-messages": _vm.employee.editedItem.msgPhone,
      "clearable": _vm.employee.editedItem.msgPhone !== '',
      "type": "number",
      "label": "手机号码",
      "hint": "手机号码可作为登陆名称使用",
      "outlined": "",
      "color": "secondary",
      "dense": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkPhone ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.employee.editedItem.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "phone", $$v);
      },
      expression: "employee.editedItem.phone"
    }
  })], 1)], 1), _vm.enterprise.branch ? _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("account_tree")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.enterprise.branch,
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择员工所属分公司"
    },
    model: {
      value: _vm.employee.editedItem.sub_corp,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "sub_corp", $$v);
      },
      expression: "employee.editedItem.sub_corp"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("supervisor_account")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.flatGroupRoles(_vm.groupList),
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择所属角色",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_typeof(data.item) !== 'object' ? [_c('v-list-item-content', {
          domProps: {
            "textContent": _vm._s(data.item)
          }
        })] : [_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "input-value": data.attrs.inputValue
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        })], 1)]];
      }
    }]),
    model: {
      value: _vm.employee.editedItem.roles,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "roles", $$v);
      },
      expression: "employee.editedItem.roles"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("integration_instructions")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工编号",
      "hint": "员工编号为企业内部的员工编号",
      "outlined": "",
      "color": "secondary",
      "dense": ""
    },
    model: {
      value: _vm.employee.editedItem.employeeNo,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "employeeNo", $$v);
      },
      expression: "employee.editedItem.employeeNo"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-n6",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    staticClass: "mt-n6",
    attrs: {
      "color": "primary lighten-4"
    }
  }, [_vm._v("room")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "error-messages": _vm.employee.editedItem.msgAddress,
      "clearable": _vm.employee.editedItem.msgAddress !== '',
      "type": "text",
      "label": "收件地址",
      "hint": "收件地址为接收设备的目的地",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-fade-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkAddress ? _c('v-progress-circular', {
          attrs: {
            "width": "2",
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "append-outer",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('copy_address', _vm.enterprise);
            }
          }
        }, [_vm._v("复制公司地址")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.employee.editedItem.address,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "address", $$v);
      },
      expression: "employee.editedItem.address"
    }
  })], 1)], 1), _vm._l(this.employee.editedItem.customFields, function (item, i) {
    return _c('v-row', {
      key: i + '_field',
      staticClass: "mt-n6",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-center",
      attrs: {
        "cols": "2"
      }
    }, [_c('v-icon', {
      staticClass: "mt-n6",
      attrs: {
        "color": "primary lighten-4"
      }
    }, [_vm._v("list")])], 1), item.format.type === 'text' ? _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [item.format.mask ? _c('v-text-field', {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: item.format.mask,
        expression: "item.format.mask"
      }],
      attrs: {
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _c('v-text-field', {
      attrs: {
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.format.type === 'selects' ? _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": item.format.items,
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e()], 1);
  })], 2)], 1), _c('v-divider'), _c('v-card-actions', [_c('widgets-custom-field', {
    attrs: {
      "module": "Account",
      "ownerId": _vm.query.enterprise,
      "ownerType": "Enterprise"
    },
    on: {
      "Complete": function Complete($event) {
        return _vm.doAction('update_field');
      }
    }
  }), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEmployee
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.validEmployee
    },
    on: {
      "click": _vm.submitEmployee
    }
  }, [_vm._v("创建")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.dlgCreateAsset,
      callback: function callback($$v) {
        _vm.dlgCreateAsset = $$v;
      },
      expression: "dlgCreateAsset"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.createAsset.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validAsset,
      callback: function callback($$v) {
        _vm.validAsset = $$v;
      },
      expression: "validAsset"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 新增设备 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeCreateAsset
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-container', [_vm.currentUser ? _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.currentUser.hasAdmin.includes('enterprise') || _vm.currentUser.employer.ownerType === 'Dealer' ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 设备所属 ")]) : _vm._e(), _vm.currentUser.hasAdmin.includes('enterprise') || _vm.currentUser.employer.ownerType === 'Dealer' ? _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.assetForm.ownerType,
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "设备所属",
      "placeholder": "设备归属人"
    },
    model: {
      value: _vm.assetForm.editedItem.ownerType,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "ownerType", $$v);
      },
      expression: "assetForm.editedItem.ownerType"
    }
  })], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('enterprise') || _vm.currentUser.employer.ownerType === 'Dealer' ? _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 设备用户 ")]) : _vm._e(), _vm.currentUser.hasAdmin.includes('enterprise') || _vm.currentUser.employer.ownerType === 'Dealer' ? _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "rules": [_vm.rules.required],
      "items": _vm.employeeList,
      "return-object": "",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "item-text": "personal.name",
      "item-value": "_id",
      "label": "选择用户",
      "hide-details": "",
      "placeholder": "输入员工姓名、电话或手机号码进行查找"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }], null, false, 59584339),
    model: {
      value: _vm.assetForm.editedItem.account,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "account", $$v);
      },
      expression: "assetForm.editedItem.account"
    }
  })], 1) : _vm._e(), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 品牌厂商 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.vendorList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "产品供应商",
      "placeholder": "请选择产品供应商"
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_product_list');
      }
    },
    model: {
      value: _vm.assetForm.editedItem.vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "vendor", $$v);
      },
      expression: "assetForm.editedItem.vendor"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 品牌类型 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.typeList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "产品类型",
      "placeholder": "请选择产品类型"
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_product_list');
      }
    },
    model: {
      value: _vm.assetForm.editedItem.product_type,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "product_type", $$v);
      },
      expression: "assetForm.editedItem.product_type"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 产品 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.productList,
      "rules": [_vm.rules.required, _vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "关联产品",
      "placeholder": "请选择设备关联的产品"
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_warranty_time');
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name) + " - " + _vm._s(data.item.part_number))]), data.item.it_info ? _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.it_info.ram) + "G - " + _vm._s(data.item.it_info.network))]) : _vm._e()], 1)];
      }
    }], null, false, 3405991014),
    model: {
      value: _vm.assetForm.editedItem.product,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "product", $$v);
      },
      expression: "assetForm.editedItem.product"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 购买日期 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref28) {
        var on = _ref28.on,
            attrs = _ref28.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "rules": [_vm.rules.required],
            "label": "购买日期",
            "placeholder": "产品实际购买日期",
            "readonly": "",
            "hide-details": "",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.assetForm.editedItem.purchase_time,
            callback: function callback($$v) {
              _vm.$set(_vm.assetForm.editedItem, "purchase_time", $$v);
            },
            expression: "assetForm.editedItem.purchase_time"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 4259973377),
    model: {
      value: _vm.assetForm.menu_purchase,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm, "menu_purchase", $$v);
      },
      expression: "assetForm.menu_purchase"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        return _vm.doAction('set_warranty_deadline');
      }
    },
    model: {
      value: _vm.assetForm.editedItem.purchase_time,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "purchase_time", $$v);
      },
      expression: "assetForm.editedItem.purchase_time"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 质保期 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "suffix": "月",
      "type": "number",
      "label": "厂商质保期",
      "placeholder": "厂商质保期在产品管理中可以设置默认值",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_warranty_deadline');
      }
    },
    model: {
      value: _vm.assetForm.editedItem.warranty_time,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "warranty_time", _vm._n($$v));
      },
      expression: "assetForm.editedItem.warranty_time"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 质保到期 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "date",
      "label": "质保到期",
      "outlined": "",
      "dense": "",
      "disabled": "",
      "hide-details": ""
    },
    model: {
      value: _vm.assetForm.editedItem.warranty_deadline,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "warranty_deadline", $$v);
      },
      expression: "assetForm.editedItem.warranty_deadline"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 序列号 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "序列号",
      "placeholder": "产品唯一标示SN号码",
      "outlined": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.assetForm.editedItem.serial_number,
      callback: function callback($$v) {
        _vm.$set(_vm.assetForm.editedItem, "serial_number", $$v);
      },
      expression: "assetForm.editedItem.serial_number"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeCreateAsset
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.validAsset
    },
    on: {
      "click": _vm.createAsset
    }
  }, [_vm._v("创建")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }