<template>
  <v-container>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>系统设置</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-alert dense type="info" class="ma-2" dismissible>
      该模块为经销商端系统配置及运维集中管理的区域，仅向经销商端具有相应权限的员工开发使用。
    </v-alert>
    <v-row class="fill-height overflow-y-auto">
      <!--服务提供-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Catalog')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/catalog.svg" height="100px" contain class="ma-2"></v-img>
<!--            <v-img src="/static/system/catalog.png" max-height="250px" contain></v-img>-->
            </v-col>
            <v-col cols="8">
              <v-card-title>
                服务提供
              </v-card-title>
              <v-card-text>经销商提供的服务目录配置</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/dealer/system/catalog'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>
      <!--流程管理-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Workflow')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/workflow.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                流程配置
              </v-card-title>
              <v-card-text>标准服务流程配置与管理</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense>
            <div class="text-truncate">
              <v-btn text link to="/dealer/system/workflow/process">流程管理</v-btn>
              <v-btn text link to="/dealer/system/workflow/task">任务管理</v-btn>
            </div>
            <v-spacer></v-spacer>
              <v-btn icon link to="/dealer/system/workflow/process"><v-icon>arrow_forward</v-icon></v-btn>
          </v-list-item>
        </v-card>
      </v-col>
      <!--组织权限-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Organize')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/organize.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                组织权限
              </v-card-title>
              <v-card-text>经销商公司内部人员及组织及权限管理</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense>
            <div class="text-truncate">
              <v-btn text link to="/dealer/system/organize/department">组织架构</v-btn>
              <v-btn text link to="/dealer/system/organize/roles">角色权限</v-btn>
            </div>
            <v-spacer></v-spacer>
              <v-btn icon link to="/dealer/system/organize/department"><v-icon>arrow_forward</v-icon></v-btn>
          </v-list-item>
        </v-card>
      </v-col>
      <!--门店管理-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Branch')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/store.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                门店管理
              </v-card-title>
              <v-card-text>经销商所属门店及驻场团队管理。</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/dealer/system/branch'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>
      <!--资产配置-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Asset')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/asset.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                资产配置
              </v-card-title>
              <v-card-text>产品、库房及供应商基础配置</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense>
            <div class="text-truncate">
              <v-btn text link to="/dealer/system/asset/warehouse">仓库管理</v-btn>
              <v-btn text link to="/dealer/system/asset/state">资产状态</v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon link to="/dealer/system/asset/warehouse"><v-icon>arrow_forward</v-icon></v-btn>
          </v-list-item>
        </v-card>
      </v-col>
      <!--产品管理-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Product')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/product.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                产品管理
              </v-card-title>
              <v-card-text>ESP平台中各类产品信息的维护。</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense>
            <div class="text-truncate">
              <v-btn text link to="/dealer/system/product/type">产品类别</v-btn>
              <v-btn text link to="/dealer/system/product/vendor">品牌厂商</v-btn>
              <v-btn text link to="/dealer/system/product/list">产品列表</v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon link to="/dealer/system/product/list"><v-icon>arrow_forward</v-icon></v-btn>
          </v-list-item>
        </v-card>
      </v-col>
      <!--风控管理-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_RiskAdmin')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/risk.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                风控管理
              </v-card-title>
              <v-card-text>对企业授信使用的风控模型进行维护和管理。</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense>
            <div class="text-truncate">
              <v-btn text link to="/dealer/system/risk/enterprise">授信管理</v-btn>
              <v-btn text link to="/dealer/system/risk/model">模型</v-btn>
              <v-btn text link to="/dealer/system/risk/citylevel">区域</v-btn>
              <v-btn text link to="/dealer/system/risk/economy">经济数据</v-btn>
              <v-btn text link to="/dealer/system/risk/enterprisetype">授信类型</v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon link to="/dealer/system/risk/enterprise"><v-icon>arrow_forward</v-icon></v-btn>
          </v-list-item>
        </v-card>
      </v-col>
      <!--知识配置-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Knowledge')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/knowledge.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                知识配置
              </v-card-title>
              <v-card-text>供企业端发起咨询和检索知识的分组及目录管理。</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/dealer/system/knowledge'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>
      <!--自定义字段-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Fields')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/fields.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                自定义字段
              </v-card-title>
              <v-card-text>经销商可以维护自身和所服务企业的自定义字段信息。</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/dealer/system/fields'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>
      <!--租赁管理-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Lease_Product') || currentUser.permission.includes('Lease_Admin')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/rent.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                租赁管理
              </v-card-title>
              <v-card-text>对租赁的产品、默认参数等设置。</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense>
            <div class="text-truncate">
              <v-btn text link to="/dealer/system/rent/parameter">默认参数</v-btn>
              <v-btn text link to="/dealer/system/rent/template">报价模版</v-btn>
              <v-btn text link to="/dealer/system/rent/industry">行业字典</v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon link to="/dealer/system/rent/parameter"><v-icon>arrow_forward</v-icon></v-btn>
          </v-list-item>
        </v-card>
      </v-col>
      <!--系统日志-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('System_Logs')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/logs.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                系统日志
              </v-card-title>
              <v-card-text>系统操作日志</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/dealer/system/logs'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>
      <!--问题反馈-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/bug.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                问题反馈
              </v-card-title>
              <v-card-text>系统BUG和建议反馈</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/dealer/system/bugs'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>
      <!--发行说明-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/release.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                发行说明
              </v-card-title>
              <v-card-text>系统版本说明</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/dealer/system/release'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>
      <!--管理员工具-->
      <v-col cols="6" v-if="currentUser.hasAdmin.includes('dealer')">
        <v-card outlined>
          <v-row align="center">
            <v-col cols="4">
              <v-img src="/static/system/programming.svg" height="100px" contain class="ma-2"></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-title>
                测试工具
              </v-card-title>
              <v-card-text>各种管理员使用小工具集合，不断补充...</v-card-text>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-list-item dense @click="$router.push({path:'/dealer/system/tools'})">
            <v-spacer></v-spacer>
            <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  created() {
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {},
  mounted() {},
  methods: {}
}
</script>
