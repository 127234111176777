var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "flat": "",
      "dark": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("知识目录")]), _c('v-spacer')], 1), _c('v-card-title', [_vm._v(" 知识目录 ")]), _c('v-card-subtitle', [_vm._v(" 知识目录是经销商为企业提供的支持服务和知识库的服务目录，目录最多为2级，企业用户可以按目录查阅知识和发出咨询请求。知识目录一级需要配置审核部门和编辑部门，审核部门成员将可以对该目录内的知识进行审核发布，编辑部门可以编写知识和回复该服务目录的咨询请求。 ")]), _c('v-row', {
    staticClass: "pa-4",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_vm.KBCatalogLoading ? _c('div', {
    staticClass: "article-preview"
  }, [_vm._v("读取目录信息...")]) : _c('v-list', {
    staticClass: "mx-n4",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    model: {
      value: _vm.activeList,
      callback: function callback($$v) {
        _vm.activeList = $$v;
      },
      expression: "activeList"
    }
  }, [_c('draggable', _vm._b({
    model: {
      value: _vm.orderList,
      callback: function callback($$v) {
        _vm.orderList = $$v;
      },
      expression: "orderList"
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.KBCatalogList, function (list, index) {
    return _c('v-list-group', {
      key: list._id,
      attrs: {
        "color": "secondary"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn() {
          return [_c('v-list-item-icon', [_c('v-icon', {
            staticClass: "mt-2",
            staticStyle: {
              "cursor": "move"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("drag_indicator")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
            domProps: {
              "textContent": _vm._s(list.name)
            }
          }), _c('v-list-item-subtitle', [_vm._v(_vm._s(list.children.length) + "个目录")])], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('draggable', _vm._b({
      attrs: {
        "list": list.children
      },
      on: {
        "change": function change($event) {
          return _vm.changeChildren(list.children);
        }
      }
    }, 'draggable', _vm.dragOptions, false), _vm._l(list.children, function (item, i) {
      return _c('v-list-item', {
        key: item._id,
        staticClass: "ml-12",
        attrs: {
          "link": "",
          "value": index + ',' + i
        }
      }, [_c('v-list-item-icon', [_c('v-icon', {
        staticStyle: {
          "cursor": "move"
        },
        attrs: {
          "small": ""
        }
      }, [_vm._v("drag_indicator")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.name) + " "), _c('v-icon', {
        staticClass: "ml-2 mt-n1",
        attrs: {
          "small": ""
        }
      }, [_vm._v(_vm._s(item.isHidden ? 'visibility_off' : 'visibility'))])], 1)], 1)], 1);
    }), 1)], 1);
  }), 1)], 1)], 1), _c('v-btn', {
    staticClass: "mt-2 ma-2",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_catalog', 'parent');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("增加知识分组 ")], 1)], 1), _c('v-divider', {
    staticClass: "ml-1",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', [_c('v-scroll-y-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_vm.activeList === undefined && !Object.keys(_vm.editedItem).length ? _c('div', {
    staticClass: "title font-weight-light",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" 选择 或 增加目录 ")]) : Object.keys(_vm.editedItem).length ? _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.editedItem._id ? '编辑知识' : '新建知识') + _vm._s(_vm.editedItem.parentId ? '目录' : '分组') + " ")]), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [!_vm.editedItem.parentId ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-img', {
          staticClass: "white--text align-end",
          attrs: {
            "src": _vm.editedItem.profileImage ? _vm.ossURL + '/' + _vm.editedItem.profileImage : '/static/error/empty_state.svg',
            "dark": "",
            "contain": "",
            "aspect-ratio": "1.6"
          }
        }, [_c('v-expand-transition', [hover ? _c('div', {
          staticClass: "d-flex transition-fast-in-fast-out  darken-2 v-card--reveal display-3 white--text",
          staticStyle: {
            "height": "100%",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('upload_profile');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "primary"
          }
        }, [_vm._v("add_a_photo")])], 1) : _vm._e()])], 1)];
      }
    }], null, false, 2036338178)
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.editedItem.parentId ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.KBCatalogList.filter(function (item) {
        return !item.parentId;
      }),
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "上级服务",
      "hint": "请选择该服务的上级服务",
      "prepend-inner-icon": "drive_folder_upload"
    },
    model: {
      value: _vm.editedItem.parentId,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "parentId", $$v);
      },
      expression: "editedItem.parentId"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "type": "text",
      "rules": [_vm.rules.required],
      "label": "名称",
      "hint": "请输入名称",
      "prepend-inner-icon": "post_add",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  }), !_vm.editedItem.parentId ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.getDepartmentList(_vm.departments[0]),
      "rules": [_vm.rules.required, _vm.rules.selected],
      "return-object": "",
      "item-text": "name",
      "item-value": "value",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "prepend-inner-icon": "drive_file_rename_outline",
      "label": "请选择编辑部门"
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_managers');
      }
    },
    model: {
      value: _vm.editedItem.editor,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "editor", $$v);
      },
      expression: "editedItem.editor"
    }
  }) : _vm._e(), _vm.editedItem.editor ? _c('v-alert', {
    attrs: {
      "dense": "",
      "icon": "check_circle_outline",
      "type": "success"
    }
  }, [_vm._v(" 审核人： "), _vm._l(_vm.editedItem.managers, function (manager) {
    return _c('widgets-employee-dialogs', {
      key: manager._id,
      attrs: {
        "account": manager._id,
        "divider": "- "
      }
    }, [_vm._v(" " + _vm._s(manager.personal.name ? manager.personal.name : '*未设置姓名') + " ")]);
  })], 2) : _vm._e(), _c('v-switch', {
    staticClass: "mt-1",
    attrs: {
      "label": "\u524D\u53F0\u9690\u85CF\u8BE5\u670D\u52A1: ".concat(_vm.editedItem.isHidden ? '是' : '否')
    },
    model: {
      value: _vm.editedItem.isHidden,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "isHidden", $$v);
      },
      expression: "editedItem.isHidden"
    }
  }), _c('v-switch', {
    staticClass: "mt-4",
    attrs: {
      "label": "\u524D\u53F0\u53EF\u4EE5\u5F00\u5355\u54A8\u8BE2: ".concat(_vm.editedItem.isCreateCase ? '是' : '否')
    },
    model: {
      value: _vm.editedItem.isCreateCase,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "isCreateCase", $$v);
      },
      expression: "editedItem.isCreateCase"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }), _vm.editedItem.parentId ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "备注",
      "hint": "请输入知识目录说明介绍",
      "prepend-inner-icon": "comment_bank",
      "rows": "3",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "remarks", $$v);
      },
      expression: "editedItem.remarks"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm.editedItem._id ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEdit();
      }
    }
  }, [_vm._v("取消")]) : _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEdit();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1) : _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "outlined": "",
      "max-width": "400"
    }
  }, [!_vm.activeItem.parentId ? _c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "height": "250",
      "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
      "src": _vm.activeItem.profileImage ? _vm.ossURL + '/' + _vm.activeItem.profileImage : '/static/error/empty_state.svg'
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.activeItem.name))])], 1) : _vm._e(), !_vm.activeItem.parentId ? _c('v-card-text', {
    staticClass: "text--primary"
  }, [_c('div', [_vm._v("部门：" + _vm._s(_vm.activeItem.editor.name))]), _c('div', [_vm._v("审核人： "), _vm._l(_vm.activeItem.managers, function (manager) {
    return _c('widgets-employee-dialogs', {
      key: manager._id,
      attrs: {
        "account": manager._id,
        "divider": "- "
      }
    }, [_vm._v(" " + _vm._s(manager.personal.name ? manager.personal.name : '*未设置姓名') + " ")]);
  })], 2)]) : _vm._e(), _vm.activeItem.parentId ? _c('v-card-title', [_vm._v(_vm._s(_vm.activeItem.name))]) : _vm._e(), _vm.activeItem.parentId ? _c('v-card-text', {
    staticClass: " font-weight-bold"
  }, [_c('pre', [_vm._v(_vm._s(_vm.activeItem.remarks || '无概述信息'))])]) : _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.activeItem.children, function (item, index) {
    return _c('v-list-item', {
      key: item._id,
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.changeCatalogList(item, index);
        }
      }
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.name) + " "), _c('v-icon', {
      staticClass: "ml-2 mt-n1",
      attrs: {
        "small": ""
      }
    }, [_vm._v(_vm._s(item.isHidden ? 'visibility_off' : 'visibility'))])], 1)], 1), _c('v-list-item-icon', [_c('v-icon', [_vm._v("keyboard_arrow_right")])], 1)], 1);
  }), 1), _c('v-divider'), _c('v-card-actions', [!_vm.activeItem.parentId ? _c('v-btn', {
    staticClass: "mt-2 ma-2",
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_catalog', 'service');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("增加目录 ")], 1) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEdit();
      }
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('delete_catalog', _vm.activeItem);
      }
    }
  }, [_vm._v("删除")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('edit_catalog', _vm.activeItem);
      }
    }
  }, [_vm._v("编辑")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }