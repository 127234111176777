var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.entGroupList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.entGroupCount,
      "loading": _vm.entGroupLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "fixed-header": "",
      "height": "600",
      "item-key": "_id",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("集团列表")]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-text-field', {
          attrs: {
            "label": "关键字搜索（按回车）",
            "prepend-inner-icon": "search",
            "background-color": _vm.$vuetify.theme.dark ? '' : 'grey lighten-4',
            "solo": "",
            "flat": "",
            "dense": "",
            "hide-details": "",
            "clearable": ""
          },
          on: {
            "keydown": function keydown($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
              return _vm.searchItem.apply(null, arguments);
            },
            "click:clear": _vm.clearSearchItem
          },
          model: {
            value: _vm.query.key,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "key", $$v);
            },
            expression: "query.key"
          }
        }), _c('v-spacer'), _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "ma-2",
                attrs: {
                  "depressed": "",
                  "color": "secondary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.editItem();
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("add")]), _vm._v(" 创建集团 ")], 1)];
            }
          }])
        }), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.openFilter.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.isInLitigation",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.isInLitigation ? '已涉诉' : '未涉诉'))])];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(item);
            }
          }
        }, [_vm._v("edit")]), _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_vm._v("delete")])], 1)];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("刷新")])];
      },
      proxy: true
    }])
  }), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("是否涉诉")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.query.isInLitigation,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isInLitigation", $$v);
      },
      expression: "query.isInLitigation"
    }
  }, [_c('v-chip', {
    attrs: {
      "value": false,
      "filter": "",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("未涉诉")]), _c('v-chip', {
    attrs: {
      "value": true,
      "filter": "",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("已涉诉")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "集团名称",
      "hint": "请输入集团的名称，该名称为全局唯一命名",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "开票天数",
      "hint": "请输入开票天数",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.invoiceDays,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "invoiceDays", $$v);
      },
      expression: "editedItem.invoiceDays"
    }
  }), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.editedItem.isInLitigation,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "isInLitigation", $$v);
      },
      expression: "editedItem.isInLitigation"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "未涉诉",
      "value": false
    }
  }), _c('v-radio', {
    attrs: {
      "label": "已涉诉",
      "value": true
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm.$route.params.id !== '0' ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v("取消")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }