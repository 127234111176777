var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-dialog', {
    attrs: {
      "value": _vm.assetServiceLoading || _vm.assetRepairLoading || _vm.consultingServiceLoading || _vm.rentOfferLoading || _vm.orderPurchaseLoading,
      "hide-overlay": "",
      "persistent": "",
      "width": "300"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-card-text', [_vm._v(" 数据加载中... "), _c('v-progress-linear', {
    staticClass: "mb-0",
    attrs: {
      "indeterminate": "",
      "color": "white"
    }
  })], 1)], 1)], 1), _c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("工作台")]), _c('v-spacer')], 1)], 1), _c('v-row', {
    staticClass: "pa-4"
  }, [_c('draggable', _vm._b({
    staticClass: "row",
    attrs: {
      "disabled": _vm.$vuetify.breakpoint.mobile
    },
    model: {
      value: _vm.cardOrder,
      callback: function callback($$v) {
        _vm.cardOrder = $$v;
      },
      expression: "cardOrder"
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.cardOrder, function (layouts, i) {
    return _c('v-col', {
      key: layouts.index,
      attrs: {
        "md": "6",
        "sm": "12"
      }
    }, [_c('draggable', _vm._b({
      attrs: {
        "disabled": _vm.$vuetify.breakpoint.mobile,
        "list": layouts.items,
        "group": {
          name: 'card'
        },
        "move": _vm.changeCard
      },
      on: {
        "end": _vm.endCard
      }
    }, 'draggable', _vm.dragOptions, false), _vm._l(layouts.items.filter(function (l) {
      return l.code !== 'delivery';
    }), function (card, j) {
      return _c('v-col', {
        key: card.code,
        attrs: {
          "cols": "12"
        }
      }, [_c('v-card', {
        attrs: {
          "outlined": "",
          "loading": card.code === 'maintain' ? _vm.assetRepairLoading : card.code === 'service' ? _vm.assetServiceLoading : card.code === 'advisory' ? _vm.consultingServiceLoading : card.code === 'rentoffer' ? _vm.rentOfferLoading : card.code === 'purchase' ? _vm.purchaseProductLoading : _vm.orderPurchaseLoading
        }
      }, [_c('v-row', {
        attrs: {
          "no-gutters": "",
          "align": "start"
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "2"
        }
      }, [!_vm.$vuetify.breakpoint.mobile ? _c('v-icon', {
        staticStyle: {
          "cursor": "move"
        },
        attrs: {
          "small": ""
        }
      }, [_vm._v("drag_indicator")]) : _vm._e()], 1), _c('v-col', {
        attrs: {
          "cols": "8"
        }
      }, [card.close ? _c('span', {
        staticClass: "subtitle-2"
      }, [_vm._v(_vm._s(card.name))]) : _vm._e()]), _c('v-col', {
        staticClass: "text-right",
        attrs: {
          "cols": "2"
        }
      }, [_c('v-btn', {
        attrs: {
          "icon": "",
          "x-small": ""
        },
        on: {
          "click": function click($event) {
            return _vm.foldCard([i, j]);
          }
        }
      }, [_c('v-icon', {
        attrs: {
          "small": ""
        }
      }, [_vm._v(_vm._s(card.close ? 'open_in_full' : 'close_fullscreen'))])], 1)], 1)], 1), !card.close ? _c('div', [_c('v-row', {
        attrs: {
          "align": "center",
          "justify": "space-between"
        }
      }, [_c('v-col', {
        attrs: {
          "cols": "12",
          "md": "6"
        }
      }, [_c('v-card-title', [_vm._v(_vm._s(card.name))]), _c('v-card-text', [_vm._v(_vm._s(card.text))])], 1), !_vm.$vuetify.breakpoint.mobile ? _c('v-col', {
        attrs: {
          "cols": "12",
          "md": "6"
        }
      }, [_c('v-img', {
        staticClass: "ma-2",
        attrs: {
          "src": card.image,
          "max-width": "450px",
          "height": "135px",
          "contain": ""
        }
      })], 1) : _vm._e()], 1), _vm._l(card.data.data, function (list) {
        return [_c('v-divider', {
          key: list._id + '_hr'
        }), _c('v-list-item', {
          key: list._id,
          attrs: {
            "link": ""
          },
          on: {
            "click": function click($event) {
              _vm.$router.push({
                path: card.path + '?' + _vm.converPath(card.code) + list._id + '&enterprise=' + _vm.query.enterprise
              });
            }
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(list.name))])], 1), _c('v-spacer'), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(list.count))])], 1), _c('v-icon', {
          staticClass: "subheading font-weight-light"
        }, [_vm._v("arrow_forward")])], 1)];
      }), _c('v-divider'), _c('v-list-item', [_c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "small": ""
        }
      }, [_vm._v("schedule")]), _c('span', {
        staticClass: "caption font-weight-light"
      }, [_vm._v("更新：" + _vm._s(_vm._f("fromDate")(card.data.createTime)))]), card.group ? _c('v-icon', {
        staticClass: "ml-2",
        attrs: {
          "small": ""
        },
        on: {
          "click": function click($event) {
            return _vm.syncData(card.group, [i, j]);
          }
        }
      }, [_vm._v("sync")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
        attrs: {
          "text": "",
          "plain": ""
        },
        on: {
          "click": function click($event) {
            return _vm.$router.push({
              path: card.path + '?enterprise=' + _vm.query.enterprise
            });
          }
        }
      }, [_vm._v("查看全部(" + _vm._s(card.data.count) + ")"), _c('v-icon', {
        attrs: {
          "right": ""
        }
      }, [_vm._v("keyboard_arrow_right")])], 1)], 1)], 2) : _vm._e()], 1)], 1);
    }), 1)], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }