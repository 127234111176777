var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "secondary",
      "dark": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("素材管理")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "dark": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "label": "搜索素材名称和内容",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.doAction('search_material');
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  }), _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.materialCount) + "个素材")]), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_item');
      }
    }
  }, [_c('v-icon', [_vm._v("add")])], 1)], 1)], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.materialList.length ? '该内容的维护需要素材管理权限，如需权限可到平台系统管理者处申请。' : '没有找到任何素材内容') + " ")]), _vm.materialList.length ? _c('v-row', {
    staticClass: "fill-height overflow-y-auto"
  }, [_vm._l(_vm.materialList, function (material) {
    return _c('v-col', {
      key: material._id,
      attrs: {
        "lg": "4",
        "md": "4",
        "sm": "6",
        "cols": "12"
      }
    }, [_c('v-card', {
      staticClass: "fill-height",
      attrs: {
        "hover": "",
        "outlined": "",
        "ripple": false
      },
      on: {
        "click": function click($event) {
          return _vm.doAction('open_item', material);
        }
      }
    }, [_c('v-img', {
      attrs: {
        "src": material.profile_image ? _vm.ossURL + '/' + material.profile_image + '?x-oss-process=image/auto-orient,1/resize,m_lfit,w_400/quality,q_90' : '/static/error/empty_state.svg',
        "height": "75"
      }
    }), _c('v-card-title', {
      domProps: {
        "textContent": _vm._s(material.title)
      }
    }), _c('v-card-subtitle', [_vm._v(_vm._s(_vm._f("formatDate")(material.updateTime)) + " "), _c('widgets-employee-dialogs', {
      key: material.editor._id,
      attrs: {
        "account": material.editor._id
      }
    }, [_vm._v(_vm._s(material.editor.personal.name ? material.editor.personal.name : '*未设置姓名'))]), _vm._v(" " + _vm._s(material.word_count) + "字")], 1)], 1)], 1);
  }), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [this.query.page * this.query.limit < _vm.materialCount ? _c('v-progress-circular', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.infiniteScrolling,
        options: {
          threshold: [1.0]
        }
      },
      expression: "{\n        handler: infiniteScrolling,\n        options: {\n            threshold: [1.0]\n        }\n      }"
    }],
    attrs: {
      "indeterminate": "",
      "color": "secondary",
      "width": "2"
    }
  }) : _c('span', {
    staticClass: "title font-weight-light"
  }, [_vm._v("已展示" + _vm._s(_vm.materialCount) + "个素材内容，没有更多数据了。")])], 1)], 2) : _c('v-row', [_c('span', {
    staticClass: "bg"
  })]), _c('v-fab-transition', [_c('v-btn', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }, {
      name: "show",
      rawName: "v-show",
      value: _vm.fab,
      expression: "fab"
    }],
    staticClass: "transition-swing",
    staticStyle: {
      "z-index": "6"
    },
    attrs: {
      "color": "secondary",
      "fab": "",
      "dark": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "large": ""
    },
    on: {
      "click": _vm.toTop
    }
  }, [_c('v-icon', [_vm._v("keyboard_arrow_up")])], 1)], 1), _c('v-navigation-drawer', {
    staticStyle: {
      "z-index": "999"
    },
    attrs: {
      "app": "",
      "temporary": "",
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('clear_filter');
            }
          }
        }, [_vm._v("清空")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('set_filter');
            }
          }
        }, [_vm._v("确认")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("是否置顶")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.query.isTop,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isTop", $$v);
      },
      expression: "query.isTop"
    }
  }, [_c('v-chip', {
    attrs: {
      "value": false,
      "filter": "",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("未置顶")]), _c('v-chip', {
    attrs: {
      "value": true,
      "filter": "",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("已置顶")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "width": "80%"
    },
    model: {
      value: _vm.dlgItem,
      callback: function callback($$v) {
        _vm.dlgItem = $$v;
      },
      expression: "dlgItem"
    }
  }, [_c('widgets-material-dialogs', {
    key: _vm.ItemId,
    attrs: {
      "id": _vm.ItemId
    },
    on: {
      "Close": function Close($event) {
        return _vm.doAction('close_item');
      },
      "Delete": function Delete($event) {
        return _vm.doAction('delete_item');
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }