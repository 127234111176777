<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{title_page()}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :disabled="!valid" icon @click="preview">
          <v-icon>preview</v-icon>
        </v-btn>
        <v-btn :disabled="!editItemData._id" icon @click="deleteItem">
          <v-icon>delete</v-icon>
        </v-btn>
        <v-btn :disabled="!valid" icon @click="submit">
          <v-icon>save</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form v-model="valid" @submit.prevent="submit">
        <v-row>
          <v-col cols="12">
            <v-progress-linear color="secondary" v-if="Upload.uploading" :value="Upload.progressUpload"></v-progress-linear>
            <input id="files" type="file" name="file" ref="uploadInput" accept="image/*" :multiple="false" @change="detectFiles($event)" />
            <v-img
              :src="editItemData.profile_image ? ossURL +'/'+ editItemData.profile_image : '/static/error/empty_state.svg'"
              dark
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="375px"
              :aspect-ratio="32/9">
              <v-row align="end" class="lightbox white--text pa-2 fill-height">
                <v-col>
                  <div class="subheading">{{ editItemData.publish_date }} {{ editItemData.author }}</div>
                  <div class="text-h6">{{ editItemData.title }} <v-btn text @click="uploadProfile(editItemData)" class="float-right"><v-icon left>add_photo_alternate</v-icon>上传素材封面</v-btn></div>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="editItemData.author"
              type="text"
              label="作者"
              hint="请输入素材作者或发布方"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="dataTimeMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editItemData.publish_date"
                  label="发布日期"
                  hint="请选择素材的发布日期"
                  readonly
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="editItemData.publish_date" @input="dataTimeMenu = false" no-title scrollable></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" class="mt-n6">
            <v-text-field
              v-model="editItemData.title"
              :rules="[rules.required]"
              type="text"
              label="标题"
              hint="请输入素材的标题"
              outlined
              dense
            ></v-text-field>
            <editor v-model="editItemData.context" :init="editorInit"></editor>
          </v-col>
        </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <!--<v-subheader>预览</v-subheader>-->
        <!--<v-btn-toggle dense group>-->
          <!--<v-btn icon @click="preview('phone')">-->
            <!--<v-icon>phone_iphone</v-icon>-->
          <!--</v-btn>-->
          <!--<v-btn icon @click="preview('desktop')">-->
            <!--<v-icon>laptop_mac</v-icon>-->
          <!--</v-btn>-->
        <!--</v-btn-toggle>-->
        <v-spacer></v-spacer>
        <!--<v-btn text @click="doAction('clear_filter')">清空</v-btn>-->
        <!--<v-btn color="secondary" @click="submit" :disabled="!valid">保存</v-btn>-->
        <v-speed-dial v-if="valid" v-model="btnFab" large fixed bottom right direction="top" open-on-hover transition="slide-y-reverse-transition" style="z-index: 5;">
          <template v-slot:activator>
            <v-btn v-model="btnFab" color="secondary" dark fab>
              <v-icon v-if="btnFab">close</v-icon>
              <v-icon v-else>save</v-icon>
            </v-btn>
          </template>
          <v-btn fab dark small color="secondary" @click="submit">
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn v-if="editItemData._id" fab dark small color="primary" @click="deleteItem">
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn fab dark small color="primary" @click="preview">
            <v-icon>preview</v-icon>
          </v-btn>
        </v-speed-dial>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
// eslint-disable-next-line
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/link'
import 'tinymce/plugins/media'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/preview'
import moment from "moment";
import Util from '@/common/util';
import axios from "axios";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_UPLOAD,
  FETCH_MATERIAL,
  PUBLISH_MATERIAL,
  EDIT_MATERIAL,
  DELETE_MATERIAL
} from "@/store/actions.type";

let _this = {};
export default {
  components: {
    Editor
  },
  data: () => ({
    title_page: function () {
      return (this.$route.params.id !== '1' && this.$route.params.id !== '0') ? '编辑素材内容' : '新建素材内容';
    },
    btnFab: false,
    editItemData: {},
    dataTimeMenu: false,
    rules: {
      required: value => (value === 0 || !!value) || '请输入内容.',
      phonenum: value => {
        if (value) {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        } else {
          return true;
        }
      },
      selected: value => {
        if (!value || value.length < 1) {
          return '请至少选择一个选项。'
        } else {
          return true;
        }
      },
      email: value => {
        if (value) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        } else {
          return true;
        }
      },
    },
    valid: true,
    Upload: {
      uploading: false,
      progressUpload: 0,
    },
    editorInit: {
      language_url: '/tinymce/zh_CN.js',
      language: 'zh_CN',
      skin_url: '/tinymce/skins/ui/MDass',
      content_css: '/tinymce/skins/content/MDass/content.css',
      height: 550,
      branding: false,
      menubar: false,
      statusbar: false,
      content_style:
        "@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap'); body,h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif; }",
      font_formats:
        "Roboto=roboto;",
      quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable | removeformat',
      quickbars_insert_toolbar: 'image hr',
      plugins: 'lists image imagetools wordcount link media quickbars hr preview',
      toolbar: 'bold italic underline strikethrough | fontselect fontsizeselect formatselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | ' +
      'bullist numlist outdent indent | link unlink image media | removeformat undo redo',
      images_upload_handler: function (blobInfo, success, failure) {
        let file = blobInfo.blob();
        if (file) {
          _this.$store.dispatch(FETCH_UPLOAD)
            .then(() => {
              _this.fileName = file.name;
              let param = new FormData();
              param.append('name', file.name);
              param.append('key', 'material/'+ file.name);
              param.append('policy', _this.uploadSign.policy);
              param.append('OSSAccessKeyId', _this.uploadSign.OSSAccessKeyId);
              param.append('success_action_status', 200);
              param.append('signature', _this.uploadSign.signature);
              param.append('file', file, file.name);

              let config = {
                headers: {'Content-Type': 'multipart/form-data'},
              };
              axios.post(_this.uploadSign.host, param, config)
                .then(() => {
                  success(_this.ossURL+'/material/'+ file.name)
                })
                .catch((error) => {
                  failure(error);
                });
            })
            .catch(err => {
              failure(err);
            });
        }
      },
      powerpaste_word_import: 'clean',
      powerpaste_html_import: 'clean',
    },
  }),
  watch: {},

  computed: {
    ...mapGetters(["currentUser", "material", "uploadSign"]),
    materialType () {
      return Util.categories('materialType')
    },
  },
  beforeCreate(){
    _this = this;
  },
  created () {
    if (this.$route.params.id !== '0') this.getMaterial(this.$route.params.id)
  },
  methods: {
    goBack() {
      if (this.valid) {
        if (confirm('是否要保存修改内容？')) {
          this.submit('/dealer/material')
        } else {
          this.editItemData = Object.assign({}, {});
          this.$router.push({path: '/dealer/material'});
        }
      } else {
        this.editItemData = Object.assign({}, {});
        this.$router.push({path: '/dealer/material'});
      }
    },
    preview() {
      tinymce.activeEditor.execCommand('mcePreview');
    },
    deleteItem() {
      if (confirm('确定要删除 '+ this.editItemData.title +'？删除后该素材将不能被使用。')) {
        if (confirm('已经引用该素材的内容将不受影响。如首页、知识库、咨询等内容。')) {
          this.$store.dispatch(DELETE_MATERIAL, this.editItemData)
            .then(() => {
              this.$store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'})
              this.$router.push({path: '/dealer/material'});
            })
            .catch((err) => {
              this.$store.commit(SET_ERROR, {msg: err})
            });
        }
      }
    },
    getMaterial(id) {
      this.$store.dispatch(FETCH_MATERIAL, id)
        .then(data => {
          this.editItemData = Object.assign({}, data)
          if (data.publish_date) this.editItemData.publish_date = moment(data.publish_date).format('YYYY-MM-DD');
        });
    },
    uploadProfile() {
      this.$store.dispatch(FETCH_UPLOAD)
        .then(this.$refs.uploadInput.click())
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    detectFiles (e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', 'material/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          this.$refs.uploadInput.value = '';
          this.editItemData.profile_image = 'material/'+ this.uploadSign.key;
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload= 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    submit(goto = '') {
      let wordcount = tinymce.activeEditor.plugins.wordcount
      this.editItemData.word_count = wordcount.body.getCharacterCountWithoutSpaces()

      if (this.editItemData._id) {
        if (typeof this.editItemData.publisher === "object") {
          this.editItemData.publisher = this.editItemData.publisher._id
        }
        this.$store.dispatch(EDIT_MATERIAL, this.editItemData)
          .then(({data}) => {
            this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getMaterial(data._id);
          })
          .catch((err) => {
            this.$store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      } else {
        this.$store.dispatch(PUBLISH_MATERIAL, this.editItemData)
          .then(({data}) => {
            this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getMaterial(data._id);
            this.$router.push({path: '/dealer/material/edit/' + data._id});
          })
          .catch((err) => {
            this.$store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      }
      if (goto) {
        this.editItemData = Object.assign({}, {});
        this.$router.push({path: goto});
      }
    },
    getTypeText(code) {
      return this.materialType.find(item => item.code === code)
    },
  }
}
</script>
<style>
  .v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0,0,0,0);
  }
  .scroll {
    overflow-y: scroll
  }
</style>
