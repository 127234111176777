var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "mb-n2",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-carousel', {
    attrs: {
      "height": "350",
      "hide-delimiter-background": "",
      "hide-delimiters": "",
      "transition": "fade-transition",
      "show-arrows": false
    }
  }, [_vm.inviteOwner.logoImage ? _c('v-carousel-item', {
    attrs: {
      "src": _vm.inviteOwner.profileImage && _vm.ossURL + '/' + _vm.inviteOwner.profileImage,
      "gradient": "to bottom, rgba(0,0,0,0) 75%, rgba(255,255,255,1)"
    }
  }, [_c('v-card-title', {
    staticClass: "text-center justify-center"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.ossURL + '/' + _vm.inviteOwner.logoImage,
      "transition": "scale-transition",
      "origin": "center center",
      "aspect-ratio": "1",
      "contain": "",
      "max-width": "150"
    }
  })], 1), _c('v-card-title', {
    staticClass: "text-center justify-center text-h4"
  }, [_vm._v(" " + _vm._s(_vm.inviteOwner.subTitle) + " ")])], 1) : _c('v-carousel-item', [_c('v-card-title', {
    staticClass: "text-center justify-center mt-10"
  }, [_c('v-img', {
    attrs: {
      "src": "/static/logo2x.svg",
      "transition": "fade-transition",
      "origin": "center center",
      "max-width": "150",
      "lass": "my-3",
      "contain": "",
      "height": "150"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "mt-n16",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "550",
      "outlined": ""
    }
  }, [_vm.inviteOwner.name ? _c('v-card-title', [_vm._v(_vm._s(_vm.inviteOwner.name) + "员工注册")]) : _c('v-card-title', [_vm._v(_vm._s(_vm.$route.query.owner === 'Dealer' ? '经销商' : '企业') + "员工注册")]), _c('v-divider'), _c('v-card-text', [_c('v-form', {
    ref: "register",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: 'XXXXXXX',
      expression: "'XXXXXXX'"
    }],
    attrs: {
      "rules": [_vm.rules.required],
      "error-messages": _vm.register.msgCode,
      "clearable": _vm.register.msgCode !== '',
      "disabled": _vm.inviteOwner.hasOwnProperty('name'),
      "type": "text",
      "label": "邀请码",
      "hint": "账号注册必须先要获得邀请码，请联系企业管理员获取。",
      "outlined": "",
      "color": "secondary",
      "dense": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.register.chkCode ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.register.inviteCode,
      callback: function callback($$v) {
        _vm.$set(_vm.register, "inviteCode", $$v);
      },
      expression: "register.inviteCode"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "rules": [_vm.rules.required],
      "label": "真实姓名",
      "hint": "真实姓名用于系统显示，不作为登陆凭证。",
      "outlined": "",
      "color": "secondary",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.register.name,
      callback: function callback($$v) {
        _vm.$set(_vm.register, "name", $$v);
      },
      expression: "register.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "error-messages": _vm.register.msgName,
      "clearable": _vm.register.msgName !== '',
      "type": "text",
      "label": "电子邮件",
      "hint": "电子邮件将作为登陆名称和消息通知使用",
      "outlined": "",
      "color": "secondary",
      "dense": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.register.chkName ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.register.username,
      callback: function callback($$v) {
        _vm.$set(_vm.register, "username", $$v);
      },
      expression: "register.username"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": _vm.showPass ? 'text' : 'password',
      "append-icon": _vm.showPass ? 'visibility_off' : 'visibility',
      "label": "账号密码",
      "hint": "请牢记此密码，密码将作为登陆凭证",
      "outlined": "",
      "color": "secondary",
      "dense": "",
      "required": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPass = !_vm.showPass;
      }
    },
    model: {
      value: _vm.register.password,
      callback: function callback($$v) {
        _vm.$set(_vm.register, "password", $$v);
      },
      expression: "register.password"
    }
  }), _vm.$route.query.owner === 'Enterprise' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工编号",
      "hint": "员工编号为企业内部的员工编号",
      "outlined": "",
      "color": "secondary",
      "dense": ""
    },
    model: {
      value: _vm.register.employeeNo,
      callback: function callback($$v) {
        _vm.$set(_vm.register, "employeeNo", $$v);
      },
      expression: "register.employeeNo"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.sensms],
      "type": "number",
      "label": "手机号码",
      "hint": "电话号码将用于登陆平台和消息通知使用",
      "error-messages": _vm.register.msgPhone,
      "outlined": "",
      "color": "secondary",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-btn', {
          staticStyle: {
            "top": "-3px"
          },
          attrs: {
            "disabled": !_vm.smsShow,
            "text": "",
            "offset-y": ""
          },
          on: {
            "click": _vm.sendSMS
          }
        }, [_vm._v(_vm._s(_vm.smsText))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.register.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.register, "phone", $$v);
      },
      expression: "register.phone"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '####',
      expression: "'####'"
    }],
    attrs: {
      "rules": [_vm.rules.required],
      "error-messages": _vm.register.msgSMS,
      "clearable": _vm.register.msgSMS !== '',
      "label": "短信接收到的验证码",
      "hint": "短信验证码为4位数字",
      "outlined": "",
      "color": "secondary",
      "dense": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.register.chkSMS ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.register.smsCode,
      callback: function callback($$v) {
        _vm.$set(_vm.register, "smsCode", $$v);
      },
      expression: "register.smsCode"
    }
  }), _vm._l(_vm.inviteOwner.customFields, function (item, i) {
    return _c('v-row', {
      key: i,
      staticClass: "mt-n4",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [item.format.type === 'text' ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [item.format.mask ? _c('v-text-field', {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: item.format.mask,
        expression: "item.format.mask"
      }],
      attrs: {
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _c('v-text-field', {
      attrs: {
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.format.type === 'selects' ? _c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": item.format.items,
        "label": item.name,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e()], 1);
  })], 2)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/login'
        });
      }
    }
  }, [_vm._v("已有账号？立即登陆")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.resetForm
    }
  }, [_vm._v("清空")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("注册")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }