<template>
  <div class="fluid">
    <v-data-table
      :headers="headerTable"
      :items="rentParameterList"
      :options.sync="optionsTable"
      :server-items-length="rentParameterCount"
      :loading="rentParameterLoading"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      loading-text="数据读取中... 请稍后"
      item-key="name"
      transition
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>报价参数组</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" depressed @click="createItem()" color="secondary">创建参数组</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <span :class="item.isDefault ? 'font-weight-black v-list-item--link' : 'v-list-item--link'" @click="editItem(item)">{{item.name}}</span>
      </template>
      <template v-slot:item.isDefault="{ item }">
        {{item.isDefault ? '是' : '否'}}
      </template>
      <template v-slot:item.createTime="{ item }">
        {{item.createTime | formatTime}}
      </template>
      <template v-slot:item.updateTime="{ item }">
        {{item.updateTime | formatTime}}
      </template>
      <template v-slot:item.editor="{ item }">
        <widgets-employee-dialogs :account="item.editor._id" class="v-list-item--link">{{item.editor.personal ? item.editor.personal.name : '*未设置姓名'}}</widgets-employee-dialogs>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="setDefault(item, true)" v-if="!item.isDefault">bookmark_border</v-icon>
        <v-icon small @click="setDefault(item, false)" v-else>bookmark</v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dlgEdit" persistent scrollable max-width="700px">
      <v-form v-model="valid" @submit.prevent="submitItem">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeItem"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[rules.required]"
                  type="text"
                  label="组名称"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.period"
                  :rules="[rules.required]"
                  type="text"
                  label="报价有效期"
                  outlined
                  dense
                  hide-details
                  suffix="天"
                ></v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="editedItem.CV.init"
                  :rules="[rules.required]"
                  type="number"
                  label="默认风控系数"
                  suffix="%"
                  :hint="parseFloat((editedItem.CV.init/100).toFixed(4)).toString()"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="editedItem.CV.period"
                  :rules="[rules.required]"
                  type="number"
                  label="风险账期"
                  suffix="天"
                  hide-details
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="editedItem.CPA"
                  :rules="[rules.required]"
                  type="number"
                  label="客户资金盈利能力"
                  suffix="%"
                  :hint="parseFloat((editedItem.CV.init/100).toFixed(4)).toString()"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="editedItem.OC"
                  :rules="[rules.required]"
                  type="number"
                  label="自有资金"
                  suffix="%"
                  :hint="parseFloat((editedItem.OC/100).toFixed(4)).toString()"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.ROS.rents"
                  :rules="[rules.required]"
                  type="number"
                  label="租赁利润率"
                  suffix="%"
                  :hint="parseFloat((editedItem.ROS.rents/100).toFixed(4)).toString()"
                  persistent-hint
                  class="mt-n4"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.ROS.sales"
                  :rules="[rules.required]"
                  type="number"
                  label="销售利润率"
                  suffix="%"
                  :hint="parseFloat((editedItem.ROS.sales/100).toFixed(4)).toString()"
                  persistent-hint
                  class="mt-n4"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="mt-n6"><v-divider></v-divider></v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="editedItem.APR.base"
                  :rules="[rules.required]"
                  type="number"
                  label="基础利率"
                  suffix="%"
                  :hint="parseFloat((editedItem.APR.base/100).toFixed(4)).toString()"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="editedItem.APR.increase_2_year"
                  :rules="[rules.required]"
                  type="number"
                  label="2年基础利率增加"
                  suffix="%"
                  :hint="parseFloat((editedItem.APR.increase_2_year/100).toFixed(4)).toString()"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="editedItem.APR.increase_3_year"
                  :rules="[rules.required]"
                  type="number"
                  label="3年基础利率增加"
                  suffix="%"
                  :hint="parseFloat((editedItem.APR.increase_3_year/100).toFixed(4)).toString()"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="editedItem.APR.increase_4_year"
                  :rules="[rules.required]"
                  type="number"
                  label="4年基础利率增加"
                  suffix="%"
                  :hint="parseFloat((editedItem.APR.increase_4_year/100).toFixed(4)).toString()"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :value="editedItem.APR.base"
                  :rules="[rules.required]"
                  type="number"
                  label="一年利率"
                  suffix="%"
                  :hint="parseFloat((editedItem.APR.base/100).toFixed(4)).toString()"
                  disabled
                  class="mt-n4"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :value="parseFloat(editedItem.APR.base) + parseFloat(editedItem.APR.increase_2_year)"
                  :rules="[rules.required]"
                  type="number"
                  label="二年利率"
                  suffix="%"
                  :hint="parseFloat(((parseFloat(editedItem.APR.base) + parseFloat(editedItem.APR.increase_2_year))/100).toFixed(4)).toString()"
                  disabled
                  class="mt-n4"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :value="parseFloat(editedItem.APR.base) + parseFloat(editedItem.APR.increase_3_year)"
                  :rules="[rules.required]"
                  type="number"
                  label="三年利率"
                  suffix="%"
                  :hint="parseFloat(((parseFloat(editedItem.APR.base) + parseFloat(editedItem.APR.increase_3_year))/100).toFixed(4)).toString()"
                  disabled
                  class="mt-n4"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  :value="parseFloat(editedItem.APR.base) + parseFloat(editedItem.APR.increase_4_year)"
                  :rules="[rules.required]"
                  type="number"
                  label="四年利率"
                  suffix="%"
                  :hint="parseFloat(((parseFloat(editedItem.APR.base) + parseFloat(editedItem.APR.increase_4_year))/100).toFixed(4)).toString()"
                  disabled
                  class="mt-n4"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="editedItem.APR.annual"
                  :rules="[rules.required]"
                  type="number"
                  label="帐期年利率"
                  suffix="%"
                  :hint="parseFloat((editedItem.APR.annual/100).toFixed(4)).toString()"
                  class="mt-n4"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="9"></v-col>
              <v-col cols="12" class="mt-n6"><v-divider></v-divider></v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.ETP._1_year_12"
                  :rules="[rules.required]"
                  type="number"
                  label="1年12期"
                  suffix="%"
                  :hint="parseFloat((editedItem.ETP._1_year_12/100).toFixed(4)).toString()"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.ETP._2_year_24"
                  :rules="[rules.required]"
                  type="number"
                  label="2年24期"
                  suffix="%"
                  :hint="parseFloat((editedItem.ETP._2_year_24/100).toFixed(4)).toString()"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.ETP._3_year_36"
                  :rules="[rules.required]"
                  type="number"
                  label="3年36期"
                  suffix="%"
                  :hint="parseFloat((editedItem.ETP._3_year_36/100).toFixed(4)).toString()"
                  persistent-hint
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.ETP._1_year_4"
                  :rules="[rules.required]"
                  type="number"
                  label="1年4期"
                  suffix="%"
                  :hint="parseFloat((editedItem.ETP._1_year_4/100).toFixed(4)).toString()"
                  persistent-hint
                  class="mt-n4"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.ETP._2_year_8"
                  :rules="[rules.required]"
                  type="number"
                  label="2年8期"
                  suffix="%"
                  :hint="parseFloat((editedItem.ETP._2_year_8/100).toFixed(4)).toString()"
                  persistent-hint
                  class="mt-n4"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedItem.ETP._3_year_12"
                  :rules="[rules.required]"
                  type="number"
                  label="3年12期"
                  suffix="%"
                  :hint="parseFloat((editedItem.ETP._3_year_12/100).toFixed(4)).toString()"
                  persistent-hint
                  class="mt-n4"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12"><v-divider></v-divider></v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.comments"
                  label="报价单备注信息"
                  auto-grow
                  outlined
                  rows="3"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.statement"
                  label="报价单声明信息"
                  auto-grow
                  outlined
                  class="mt-n4"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeItem">取消</v-btn>
            <v-btn color="secondary" text @click="submitItem" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {SET_ERROR} from "@/store/mutations.type";
import {
  DEFAULT_RENT_PARAMETER,
  EDIT_RENT_PARAMETER,
  FETCH_RENT_PARAMETER_LIST,
  PUBLISH_RENT_PARAMETER
} from "@/store/actions.type";

export default {
  data() {
    return {
      query: {
        key: '',
      },
      defaultQuery: {
        key: '',
      },
      headerTable: [
        {text: '名称', value: 'name'},
        {text: '是否默认', value: 'isDefault' },
        {text: '有效期（天）', value: 'period' },
        {text: '创建时间', value: 'createTime' },
        {text: '更新时间', value: 'updateTime' },
        {text: '最后编辑', value: 'editor' },
        {text: '操作', value: 'action', sortable: false}
      ],
      optionsTable: {},
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedItem: {
        _id: null,
        name: null,
        isDefault: false,
        period: 30,
        APR: {
          annual: 0.0000,
          base: 0.0000,
          increase_2_year: 0.0000,
          increase_3_year: 0.0000,
          increase_4_year: 0.0000,
        },
        CV: {
          init: 0.0000,
          period: 0,
        },
        ROS: {
          sales: 0.0000,
          rents: 0.0000,
        },
        CPA: 0.0000,
        OC: 0.0000,
        ETP: {
          _1_year_12: 0.0000,
          _2_year_24: 0.0000,
          _3_year_36: 0.0000,
          _1_year_4: 0.0000,
          _2_year_8: 0.0000,
          _3_year_12: 0.0000
        },
        comments : null,
        statement : null,
      },
      defaultItem: {
        _id: null,
        name: null,
        isDefault: false,
        period: 30,
        APR: {
          annual: 0.0000,
          base: 0.0000,
          increase_2_year: 0.0000,
          increase_3_year: 0.0000,
          increase_4_year: 0.0000,
        },
        CV: {
          init: 0.0000,
          period: 0,
        },
        ROS: {
          sales: 0.0000,
          rents: 0.0000,
        },
        CPA: 0.0000,
        OC: 0.0000,
        ETP: {
          _1_year_12: 0.0000,
          _2_year_24: 0.0000,
          _3_year_36: 0.0000,
          _1_year_4: 0.0000,
          _2_year_8: 0.0000,
          _3_year_12: 0.0000
        },
        comments : null,
        statement : null,
      },
    };
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "rentParameterLoading",
      "rentParameterList",
      "rentParameterCount",
    ]),
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.closeItem()
    },
  },
  methods: {
    getDataList(query = {}) {
      // 获取分页信息
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (query.key) urlQuery += '&key=' + query.key
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_RENT_PARAMETER_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    createItem() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dlgTitle = '创建报价参数组'
      this.dlgEdit = true
    },
    editItem(item) {
      this.editedItem = JSON.parse(JSON.stringify(Object.assign(this.editedItem, item)))
      this.editedItem.APR.base = parseFloat((this.editedItem.APR.base*100).toFixed(4))
      this.editedItem.APR.annual = parseFloat((this.editedItem.APR.annual*100).toFixed(4))
      this.editedItem.APR.increase_2_year = parseFloat((this.editedItem.APR.increase_2_year*100).toFixed(4))
      this.editedItem.APR.increase_3_year = parseFloat((this.editedItem.APR.increase_3_year*100).toFixed(4))
      this.editedItem.APR.increase_4_year = parseFloat((this.editedItem.APR.increase_4_year*100).toFixed(4))
      this.editedItem.CV.init = parseFloat((this.editedItem.CV.init*100).toFixed(4))
      this.editedItem.ROS.sales = parseFloat((this.editedItem.ROS.sales*100).toFixed(4))
      this.editedItem.ROS.rents = parseFloat((this.editedItem.ROS.rents*100).toFixed(4))
      this.editedItem.CPA = parseFloat((this.editedItem.CPA*100).toFixed(4))
      this.editedItem.OC = parseFloat((this.editedItem.OC*100).toFixed(4))
      this.editedItem.ETP._1_year_12 = parseFloat((this.editedItem.ETP._1_year_12*100).toFixed(4))
      this.editedItem.ETP._2_year_24 = parseFloat((this.editedItem.ETP._2_year_24*100).toFixed(4))
      this.editedItem.ETP._3_year_36 = parseFloat((this.editedItem.ETP._3_year_36*100).toFixed(4))
      this.editedItem.ETP._1_year_4 = parseFloat((this.editedItem.ETP._1_year_4*100).toFixed(4))
      this.editedItem.ETP._2_year_8 = parseFloat((this.editedItem.ETP._2_year_8*100).toFixed(4))
      this.editedItem.ETP._3_year_12 = parseFloat((this.editedItem.ETP._3_year_12*100).toFixed(4))

      this.dlgTitle = '编辑报价参数组'
      this.dlgEdit = true
    },
    setDefault(item, value) {
      if (confirm(`确定要将 ${item.name} 报价参数组 ${value ? '设置默认' : '取消默认'} ？`)) {
        store.dispatch(DEFAULT_RENT_PARAMETER, {_id: item._id, reply: value})
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.closeItem()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
    closeItem() {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    submitItem() {
      let strInsert = {}
      strInsert.APR = {}
      strInsert.CV = {}
      strInsert.ROS = {}
      strInsert.ETP = {}

      strInsert.name = this.editedItem.name
      strInsert.period = this.editedItem.period
      strInsert.APR.base = parseFloat((this.editedItem.APR.base/100).toFixed(4))
      strInsert.APR.annual = parseFloat((this.editedItem.APR.annual/100).toFixed(4))
      strInsert.APR.increase_2_year = parseFloat((this.editedItem.APR.increase_2_year/100).toFixed(4))
      strInsert.APR.increase_3_year = parseFloat((this.editedItem.APR.increase_3_year/100).toFixed(4))
      strInsert.APR.increase_4_year = parseFloat((this.editedItem.APR.increase_4_year/100).toFixed(4))
      strInsert.CV.init = parseFloat((this.editedItem.CV.init/100).toFixed(4))
      strInsert.CV.period = this.editedItem.CV.period
      strInsert.ROS.sales = parseFloat((this.editedItem.ROS.sales/100).toFixed(4))
      strInsert.ROS.rents = parseFloat((this.editedItem.ROS.rents/100).toFixed(4))
      strInsert.CPA = parseFloat((this.editedItem.CPA/100).toFixed(4))
      strInsert.OC = parseFloat((this.editedItem.OC/100).toFixed(4))
      strInsert.ETP._1_year_12 = parseFloat((this.editedItem.ETP._1_year_12/100).toFixed(4))
      strInsert.ETP._2_year_24 = parseFloat((this.editedItem.ETP._2_year_24/100).toFixed(4))
      strInsert.ETP._3_year_36 = parseFloat((this.editedItem.ETP._3_year_36/100).toFixed(4))
      strInsert.ETP._1_year_4 = parseFloat((this.editedItem.ETP._1_year_4/100).toFixed(4))
      strInsert.ETP._2_year_8 = parseFloat((this.editedItem.ETP._2_year_8/100).toFixed(4))
      strInsert.ETP._3_year_12 = parseFloat((this.editedItem.ETP._3_year_12/100).toFixed(4))

      if (this.editedItem._id) {
        // edited
        strInsert.comments = this.editedItem.comments
        strInsert.statement = this.editedItem.statement
        strInsert._id = this.editedItem._id

        store.dispatch(EDIT_RENT_PARAMETER, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.closeItem()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })

      } else {
        // create
        if (this.editedItem.comments) strInsert.comments = this.editedItem.comments
        if (this.editedItem.statement) strInsert.statement = this.editedItem.statement

        store.dispatch(PUBLISH_RENT_PARAMETER, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getDataList()
            this.closeItem()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
    searchItem() {
      if (this.query.key !== '') {
        this.getDataList({key: this.query.key})
      } else {
        this.getDataList()
      }
    }
  },
}
</script>

