<template>
  <div class="fluid">
    <v-data-table
      :headers="headerTable"
      :items="riskEconomyList"
      :options.sync="optionsTable"
      :server-items-length="riskEconomyCount"
      :loading="riskEconomyLoading"
      loading-text="数据读取中... 请稍后"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      fixed-header
      height="600"
      item-key="_id"
      transition
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>经济数据列表</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="query.key"
            label="关键字搜索（按回车）"
            prepend-inner-icon="search"
            :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
            solo
            flat
            dense
            hide-details
            clearable
            @keydown.enter="searchItem"
            @click:clear="clearSearchItem"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="ma-2" depressed color="secondary">
                <v-icon>add</v-icon> 创建经济数据
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item @click="editItem()">
                <v-list-item-icon>
                  <v-icon>add_box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>通过表单增加单个经济数据</v-list-item-title>
              </v-list-item>
              <v-list-item @click="importItem()">
                <v-list-item-icon>
                  <v-icon>library_add</v-icon>
                </v-list-item-icon>
                <v-list-item-title>通过文件批量增加经济数据</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-tooltip bottom v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Asset_Manage')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click.stop="exportItem" :loading="!exportEnd">
                <v-icon small>save_alt</v-icon>
              </v-btn>
            </template>
            <span>导出数据</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click.stop="openFilter">
                <v-icon small>filter_list</v-icon>
              </v-btn>
            </template>
            <span>过滤数据</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item.city="{ item }">
        <td nowrap="true">{{item.type ? '国内三线' : item.city}}</td>
      </template>
      <template v-slot:item.GDPGrowthRate="{ item }">
        <td nowrap="true">{{parseFloat(item.GDPGrowthRate*100).toFixed(2)}}%</td>
      </template>
      <template v-slot:item.landDependence="{ item }">
        <td nowrap="true">{{parseFloat(item.landDependence*100).toFixed(2)}}%</td>
      </template>
      <template v-slot:item.budgetRevenueGrowthRate="{ item }">
        <td nowrap="true">{{parseFloat(item.budgetRevenueGrowthRate*100).toFixed(2)}}%</td>
      </template>
      <template v-slot:item.debtBudgetRatio="{ item }">
        <td nowrap="true">{{parseFloat(item.debtBudgetRatio*100).toFixed(2)}}%</td>
      </template>
      <template v-slot:item.selfSufficiencyRate="{ item }">
        <td nowrap="true">{{parseFloat(item.selfSufficiencyRate*100).toFixed(2)}}%</td>
      </template>
      <template v-slot:item.updateTime="{ item }">
        <td nowrap="true">{{item.updateTime | formatTime}}</td>
      </template>
      <template v-slot:item.actions="{ item }">
        <td nowrap="true">
          <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon small @click="deleteItem(item)">delete</v-icon>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" depressed @click="getDataList()">刷新</v-btn>
      </template>
    </v-data-table>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">年度</h2>
        <v-autocomplete
          class="mt-2"
          v-model="query.year"
          :items="yearList"
          @change="setFilter"
          item-text="name"
          item-value="_id"
          autocomplete="off"
          outlined
          dense
          label="年度"
          hint="请选择年度"
        >
        </v-autocomplete>
        <h2 class="subtitle-2 mt-2">区域</h2>
        <v-autocomplete
          class="mt-2"
          v-model="query.city"
          :items="cityList"
          @change="setFilter"
          item-text="name"
          item-value="_id"
          autocomplete="off"
          outlined
          dense
          label="区域"
          hint="请选择区域"
          chips
          deletable-chips
          multiple
          small-chips
        >
        </v-autocomplete>
      </v-card-text>
    </v-navigation-drawer>
    <v-dialog v-model="dlgEdit"  persistent max-width="650px" scrollable>
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-autocomplete
                  v-model="editedItem.year"
                  :items="yearList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  label="年度"
                  hint="请选择年度"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="editedItem.city"
                  :items="cityList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  label="地区"
                  hint="请选择地区"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.GDPTotal"
                  type="number"
                  label="GDP总值"
                  hint="请输入GDP总值"
                  prefix="¥"
                  suffix="亿元"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.GDPGrowthRate"
                  :rules="[rules.required]"
                  @change="checkItem()"
                  type="text"
                  label="GDP增长率"
                  suffix="%"
                  hint="请输入GDP增长率"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.GDPPerCapita"
                  type="text"
                  label="当地人均GDP"
                  hint="请输入当地人均GDP"
                  prefix="¥"
                  suffix="元"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.landDependence"
                  :rules="[rules.required]"
                  @change="checkItem()"
                  type="text"
                  label="土地依赖"
                  suffix="%"
                  hint="请输入土地依赖"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.budgetRevenue"
                  type="text"
                  label="一般预算收入"
                  hint="请输入一般预算收入"
                  prefix="¥"
                  suffix="亿元"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.budgetRevenueGrowthRate"
                  :rules="[rules.required]"
                  @change="checkItem()"
                  type="text"
                  label="一般预算收入增长率"
                  suffix="%"
                  hint="请输入一般预算收入增长率"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.debtBudgetRatio"
                  :rules="[rules.required]"
                  @change="checkItem()"
                  type="text"
                  label="负债率"
                  suffix="%"
                  hint="请输入地方政府债务余额/一般公共预算收入"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.selfSufficiencyRate"
                  :rules="[rules.required]"
                  @change="checkItem()"
                  type="text"
                  label="自给率"
                  suffix="%"
                  hint="请输入财政自给率"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.publicOpinion"
                  type="text"
                  label="舆情分值"
                  hint="请输入舆情分值"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.remarks"
                  label="备注"
                  rows="2"
                  hint="请输入备注"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dlgInfo" persistent scrollable width="700">
      <v-card v-if="activeType === 'import'">
        <v-card-title>
          批量导入
          <v-spacer></v-spacer>
          <v-btn icon @click="closeItem"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <p>1. 下载设备模版，填写产品信息.</p>
                <v-btn text @click="downloadTemplate()">下载模版<v-icon>file_download</v-icon></v-btn>
              </v-col>
              <v-col cols="6">
                <input
                  id="import_file"
                  type="file"
                  name="import_file"
                  ref="uploadImport"
                  accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :multiple="false"
                  @change="importFile($event)"/>
                <p>2. 上传填好的文件.</p>
                <v-btn text @click="uploadImportFile()"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="importEnd">
              <v-col cols="12">
                <v-divider></v-divider>
                <v-alert
                  outlined
                  type="error"
                  text
                >
                  导入失败：<ul><li v-for="(fail,index) in importFailed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
                </v-alert>
                <v-alert
                  outlined
                  type="success"
                  text
                >
                  成功导入：{{importSuccess}} 条记录
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeItem">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {
  PUBLISH_RISK_ECONOMY,
  EDIT_RISK_ECONOMY,
  FETCH_RISK_ECONOMY,
  FETCH_RISK_ECONOMY_LIST,
  DELETE_RISK_ECONOMY,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import Util from "@/common/util";
import {SET_ERROR} from "@/store/mutations.type";
import ApiService from "@/common/http";

export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
        negative: val => ( val >= 0) || '不能小于0'
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedItem: {
        _id: '',
        year: null,
        city: '',
        GDPTotal: null,
        GDPGrowthRate: null,
        GDPPerCapita: null,
        landDependence: null,
        budgetRevenue: null,
        budgetRevenueGrowthRate: null,
        debtBudgetRatio: null,
        selfSufficiencyRate: null,
        publicOpinion: null,
      },
      defaultItem: {
        _id: '',
        year: null,
        city: '',
        GDPTotal: null,
        GDPGrowthRate: null,
        GDPPerCapita: null,
        landDependence: null,
        budgetRevenue: null,
        budgetRevenueGrowthRate: null,
        debtBudgetRatio: null,
        selfSufficiencyRate: null,
        publicOpinion: null,
      },
      filterDrawer: false,
      query: {
        key: '',
        year: null,
        city: [],
      },
      defaultQuery: {
        key: '',
        year: null,
        city: [],
      },
      headerTable: [
        {text: '年度', width: '125px', value: 'year'},
        {text: '区域', width: '125px', value: 'city'},
        {text: 'GDP总值（亿元）', width: '155px', value: 'GDPTotal'},
        {text: 'GDP增长率', width: '125px', value: 'GDPGrowthRate'},
        {text: '人均GDP（元）', width: '155px', value: 'GDPPerCapita'},
        {text: '土地依赖', width: '125px', value: 'landDependence'},
        {text: '一般预算收入（亿元）', width: '175px', value: 'budgetRevenue'},
        {text: '一般预算收入增长率', width: '175px', value: 'budgetRevenueGrowthRate'},
        {text: '负债率', width: '125px', value: 'debtBudgetRatio'},
        {text: '自给率', width: '125px', value: 'selfSufficiencyRate'},
        {text: '舆情分值', width: '125px', value: 'publicOpinion'},
        {text: '更新时间', width: '125px', value: 'updateTime'},
        {text: '操作', width: '85px', value: 'actions', sortable: false}
      ],
      optionsTable: {},
      dlgInfo: false,
      activeType: 'import',
      importExcelNotice: [['请使用此模版批量导入风控经济数据，*为必填项目。']],
      importSuccess: 0,
      importFailed: [],
      importEnd: false,
      exportEnd: true,
    }
  },
  async created() {
    await this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentEmployer",
      "riskEconomyLoading",
      "riskEconomyList",
      "riskEconomyCount"]),
    yearList() {
      const start = 2023;
      const currentYear = new Date().getFullYear();

      const years = [];

      for (let year = start; year <= currentYear; year++) {
        years.push(year);
      }

      return years;
    },
    cityList () {
      return Util.categories('cityList')
    },
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  methods: {
    openFilter() {
      this.filterDrawer = !this.filterDrawer
    },
    setFilter() {
      this.$router.push({ query: {} });
      this.getDataList(this.query)
    },
    searchItem() {
      this.optionsTable.page = 1
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    clearSearchItem() {
      this.optionsTable.page = 1
      this.query.key = ''
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    async getDataList() {
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + encodeURIComponent(this.query.key)
      if (this.query.year) urlQuery += '&year=' + this.query.year
      if (this.query.city.length) urlQuery += '&city='+ encodeURIComponent(this.query.city)
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_RISK_ECONOMY_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    async deleteItem(item) {
      if (confirm(`确定要 删除 ${item.city}？`)) {
        this.$store.dispatch(DELETE_RISK_ECONOMY, item)
          .then(() => {
            this.getDataList()
          })
          .catch((err) => {
            this.$store.commit(SET_ERROR, { msg: err.response.data.message });
          });
      }
    },
    closeItem() {
      this.dlgInfo = false
    },
    editItem(item) {
      if (item) {
        store.dispatch(FETCH_RISK_ECONOMY, item._id)
          .then((data) => {
            this.editedItem = Object.assign({}, data);

            if (this.editedItem.type === 1) {
              this.editedItem.city = '国内三线';
            }
            this.editedItem.GDPGrowthRate = parseFloat(data.GDPGrowthRate*100).toFixed(2);
            this.editedItem.landDependence = parseFloat(data.landDependence*100).toFixed(2);
            this.editedItem.budgetRevenueGrowthRate = parseFloat(data.budgetRevenueGrowthRate*100).toFixed(2);
            this.editedItem.debtBudgetRatio = parseFloat(data.debtBudgetRatio*100).toFixed(2);
            this.editedItem.selfSufficiencyRate = parseFloat(data.selfSufficiencyRate*100).toFixed(2);
          })
      } else {
        this.editedItem = Object.assign({}, this.defaultItem)
      }
      this.dlgTitle = item ? '编辑经济数据' : '新增经济数据'
      this.dlgEdit = true
    },
    async detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      await Promise.all(Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      }))
    },
    downloadTemplate() {
      this.exportEnd = false

      ApiService.download('/risk/economy/export?onlyheader=yes', {
        responseType: 'blob'
      })
        .then(resp => {
          let fileName = '经济数据导入模版.xlsx';
          let contentDisposition = resp.headers['content-disposition'];
          if (contentDisposition) {
            fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
          }
          let blob = new Blob([resp.data], { type: 'application/octet-stream' });
          if (window.navigator.msSaveOrOpenBlob) { //支持IE
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement('a');
            link.style.display = "none";
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            this.exportEnd = true
            //释放内存
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
        })
        .catch(err => { // 请求失败处理
          this.exportEnd = true
          store.commit(SET_ERROR, { msg: err });
        });
    },
    uploadImportFile() {
      this.$refs.uploadImport.click()
    },
    async submit() {
      let strInsert = {}

      strInsert.year = this.editedItem.year;
      strInsert.city = this.editedItem.city
      if (strInsert.city === '国内三线') {
        strInsert.type = 1;
        strInsert.city = '';
      }
      strInsert.GDPTotal = parseFloat(this.editedItem.GDPTotal);
      strInsert.GDPGrowthRate = parseFloat((this.editedItem.GDPGrowthRate/100).toFixed(4));
      strInsert.GDPPerCapita = parseFloat(this.editedItem.GDPPerCapita);
      strInsert.landDependence = parseFloat((this.editedItem.landDependence/100).toFixed(4));
      strInsert.budgetRevenue = parseFloat(this.editedItem.budgetRevenue);
      strInsert.budgetRevenueGrowthRate = parseFloat((this.editedItem.budgetRevenueGrowthRate/100).toFixed(4));
      strInsert.debtBudgetRatio = parseFloat((this.editedItem.debtBudgetRatio/100).toFixed(4));
      strInsert.selfSufficiencyRate = parseFloat((this.editedItem.selfSufficiencyRate/100).toFixed(4));
      strInsert.publicOpinion = parseFloat(this.editedItem.publicOpinion);
      strInsert.remarks = this.editedItem.remarks;

      if (this.editedItem._id) {
        strInsert._id = this.editedItem._id
        store.dispatch(EDIT_RISK_ECONOMY, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.query.key = ''
            this.query.filterItem = []
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_RISK_ECONOMY, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.query.key = ''
            this.query.filterItem = []
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },
    close () {
      this.dlgTitle = '';
      this.dlgEdit = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    importItem() {
      this.activeType = 'import'
      this.dlgInfo = true
    },
    importFile(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importRiskEconomy(fileList[x]);
      })
    },
    importRiskEconomy(file) {
      let param = new FormData();
      param.append('file', file);
      ApiService.post('/risk/economy/import', param)
        .then((data) => {
          store.dispatch(FETCH_RISK_ECONOMY_LIST)
          this.importSuccess = data.data.Success
          this.importFailed = data.data.Failed
          this.$refs.uploadImport.value = '';
          this.importEnd = true
        })
        .catch((err) => {
          this.$refs.uploadImport.value = '';
          this.importSuccess = 0
          this.importFailed = []
          this.importEnd = true
          let arrErr = [];
          if (err.response.data) {
            if (err.response.data.code === 11000) {
              arrErr = err.response.data.writeErrors.map(obj => { return obj.op.personal.name });
            }
          }
          if (arrErr.length > 0) {
            this.$store.commit(SET_ERROR, {msg: '请检查以下经济数据：'+ arrErr});
          } else {
            this.importFailed = [{line: 2, error: err.response.data.message}]
          }
        });
    },
    exportItem(){
      if (this.exportEnd) {
        this.exportFile(this.query)
      }
    },
    exportFile() {
      this.exportEnd = false
      const {sortBy, sortDesc} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + encodeURIComponent(this.query.key)
      if (this.query.year) urlQuery += '&year=' + this.query.year
      if (this.query.city.length) urlQuery += '&city='+ encodeURIComponent(this.query.city)

      if (Object.keys(this.optionsTable).length > 0) {
        ApiService.download('/risk/economy/export?sort=' + sort + urlQuery, {
          responseType:'blob'
        })
          .then(resp => {
            let fileName = '经济数据列表.xlsx';
            let contentDisposition = resp.headers['content-disposition'];
            if (contentDisposition) {
              fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
            }
            let blob = new Blob([resp.data], {type: 'application/octet-stream'});
            if (window.navigator.msSaveOrOpenBlob) { //支持IE
              navigator.msSaveBlob(blob, fileName);
            } else {
              let link = document.createElement('a');
              link.style.display = "none";
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              this.exportEnd = true
              //释放内存
              window.URL.revokeObjectURL(link.href);
              document.body.removeChild(link);
            }
          })
          .catch(err => { // 请求失败处理
            this.exportEnd = true
            store.commit(SET_ERROR, {msg: err});
          });
      }
    },
  }
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>

