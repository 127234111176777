<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>流程配置</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-title>
        提示
      </v-card-title>
      <v-card-subtitle>流程指对应服务目录提供的服务流程，每个企业成员基于服务协议中的服务目录，请求不同的服务流程。服务流程包括若干个任务，任务指具体需要更新的字段或者操作，一个服务请求在处理中时系统将自动基于任务创建待办事项。</v-card-subtitle>
      <v-tabs v-model="activeTab" color="secondary" grow icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route">
          {{ tab.name }}
          <v-icon>{{tab.icon}}</v-icon>
        </v-tab>
        <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route" :transition="false" :reverse-transition="false">
          <router-view></router-view>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      activeTab: '/dealer/system/workflow/process',
      tabs: [
        { id: 1, name: '服务流程', route: '/dealer/system/workflow/process', help: '流程管理中可以为不同企业创建专属的服务流程', icon: 'low_priority' },
        { id: 2, name: '任务管理', route: '/dealer/system/workflow/task', help: '任务是流程处理时需要完成的事宜，可以组合成不同的流程。', icon: 'rule' },
      ],
    }
  },
  methods: {
    goBack() {
      this.$router.push({ path: '/dealer/system' })
    }
  }

}
</script>
