<template>
  <div class="fluid">
    <v-data-table
      :headers="headerTable"
      :items="warehouseList"
      :options.sync="optionsTable"
      :server-items-length="warehouseCount"
      :loading="warehouseLoading"
      loading-text="数据读取中... 请稍后"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      :item-class="itemRowBackground"
      item-key="_id"
      transition
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>仓库列表</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!--<v-text-field-->
            <!--v-model="query.key"-->
            <!--@keydown.enter="doAction('search_key')"-->
            <!--class="mx-4"-->
            <!--flat-->
            <!--dense-->
            <!--background-color="grey lighten-4"-->
            <!--hide-details-->
            <!--label="关键字搜索"-->
            <!--prepend-inner-icon="search"-->
            <!--solo-->
          <!--&gt;</v-text-field>-->
          <v-btn class="ma-2" outlined fab x-small @click="doAction('create_warehouse', '', '新增仓库')">
            <v-icon>add</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <span :class="item.isEnable ? '' : 'text-decoration-line-through'">{{item.name}}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="doAction('edit_warehouse', item, '编辑仓库')">edit</v-icon>
        <v-icon small @click="doAction('active_warehouse', item, true)" v-if="!item.isEnable">check_circle_outline</v-icon>
        <v-icon small @click="doAction('active_warehouse', item, false)" v-if="item.isEnable">block</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataList()">Reset</v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dlgEdit" persistent max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="editedItem.name"
              :rules="[rules.required]"
              type="text"
              label="仓库名称"
              hint="请输入仓库名称"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="editedItem.center_code"
              type="text"
              label="仓库代码"
              hint="与其它系统集成时识别代码"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="editedItem.contactName"
              type="text"
              label="联系人"
              hint="请输入仓库联系人姓名"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="editedItem.contactPhone"
              type="text"
              label="联系电话"
              hint="请输入仓库联系电话"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="editedItem.contactEmail"
              :rules="[rules.email]"
              type="text"
              label="联系邮件"
              hint="请输入仓库联系邮件"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              v-model="editedItem.address"
              :rules="[rules.required]"
              :error-messages="editedItem.msgAddress"
              :clearable="editedItem.msgAddress !== ''"
              type="text"
              label="仓库地址"
              hint="仓库实际地址"
              outlined
              dense
            >
              <template v-slot:append>
                <v-fade-transition leave-absolute>
                  <v-progress-circular
                    v-if="editedItem.chkAddress"
                    width="2"
                    size="24"
                    color="secondary"
                    indeterminate
                  ></v-progress-circular>
                </v-fade-transition>
              </template>
            </v-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapGetters } from "vuex";
import store from "@/store";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_WAREHOUSE_LIST,
  PUBLISH_WAREHOUSE,
  ACTIV_WAREHOUSE,
  EDIT_WAREHOUSE,
  CHECK_ADDR
} from "@/store/actions.type";
export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedIndex: -1,
      editedItem: {
        _id: '',
        name: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        address: '',
        chkAddress: false,
        msgAddress: '',
        center_code: '',
      },
      defaultItem: {
        _id: '',
        name: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        address: '',
        chkAddress: false,
        msgAddress: '',
        center_code: ''
      },
      query: {
        key: '',
      },
      defaultQuery: {
        key: '',
      },
      headerTable: [
        {text: '名称', value: 'name'},
        {text: '仓库代码', value: 'center_code'},
        {text: '联系人', value: 'contactName'},
        {text: '联系电话', value: 'contactPhone'},
        {text: '联系邮件', value: 'contactEmail'},
        {text: '地址', value: 'address'},
        {text: '操作', value: 'actions', align: 'end', sortable: false}
      ],
      optionsTable: {},
    };
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "warehouseLoading",
      "warehouseList",
      "warehouseCount"]),
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
    'editedItem.address'() {
      this.checkAddress();
    },
  },
  mounted() {},
  methods: {
    getDataList(query = {}) {
      // 获取分页信息
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (query.key) urlQuery += '&key=' + query.key
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_WAREHOUSE_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    doAction(action, item, customitem) {
      switch (action) {
        case 'search_key': {
          if (this.query.key !== '') {
            this.getDataList({key: this.query.key})
          } else {
            this.getDataList()
          }
          break
        }
        case 'create_warehouse': case 'edit_warehouse': {
          this.editedIndex = this.warehouseList.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
        case 'active_warehouse': {
          if (confirm(`确定要 ${customitem ? '启用' : '禁用'} ${item.name}？`)) {
            this.$store.dispatch(ACTIV_WAREHOUSE, {_id: item._id, reply: customitem})
              .then(() => {
                this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
                this.getDataList()
                this.close()
              })
              .catch((error) => {
                this.$store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          }
          break
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    close () {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    checkAddress: _.debounce(function () {
      if (this.editedItem.address) {
        this.editedItem.chkAddress = true;
        this.$store.dispatch(CHECK_ADDR, this.editedItem.address)
          .then((data) => {
            if (data.status !== 0 || data.result.reliability < 7) {
              this.editedItem.msgAddress = '地址信息错误，请输入正确的地址信息';
              this.$forceUpdate()
            } else {
              this.editedItem.addressInfo = data.result;
              this.editedItem.msgAddress = '';
              this.$forceUpdate()
            }
            this.editedItem.chkAddress = false;
          })
          .catch((err) => {
            this.editedItem.msgAddress = err.message;
            this.editedItem.chkAddress = false;
            this.$forceUpdate()
          })
      }
    }, 1000),
    submit() {
      let strInsert = {}
      strInsert.name = this.editedItem.name
      strInsert.contactName = this.editedItem.contactName
      strInsert.contactPhone = this.editedItem.contactPhone
      strInsert.contactEmail = this.editedItem.contactEmail
      strInsert.center_code = this.editedItem.center_code
      if (this.editedItem.address) strInsert.address = this.editedItem.address
      if (this.editedItem.addressInfo) {
        strInsert.addressInfo = this.editedItem.addressInfo
      } else {
        // setTimeout(strInsert.addressInfo = this.editedItem.addressInfo, 500)
        this.$store.commit(SET_ERROR, {msg: '请等待地址验证中...', color: 'primary', timeout: 500});
        return
      }
      if (this.editedIndex !== -1) {
        strInsert._id = this.editedItem._id
        this.$store.dispatch(EDIT_WAREHOUSE, strInsert)
          .then(() => {
            this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            this.$store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        this.$store.dispatch(PUBLISH_WAREHOUSE, strInsert)
          .then(() => {
            this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            this.$store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },
    itemRowBackground: function (item) {
      return item.isEnable ? 'style-1' : 'style-2'
    }
  }
}
</script>
<style scoped>
  .style-1 {
    background-color: rgb(215,215,44)
  }
  .style-2 {
    background-color: rgb(114,114,67)
  }
</style>
