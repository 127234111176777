var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "flat": "",
      "dark": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("门店管理")]), _c('v-spacer')], 1), _c('v-card-title', [_vm._v(" 门店管理 ")]), _c('v-card-subtitle', [_vm._v(" 门店及驻场团队可以在设计流程时进行分派，门店设置完成后可以在人员管理和企业管理中分派服务门店。 ")]), _c('v-row', {
    staticClass: "pa-4",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_vm.branchLoading ? _c('div', {
    staticClass: "article-preview"
  }, [_vm._v("读取分支机构信息...")]) : _c('v-list', {
    staticClass: "mx-n4",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "color": "success"
    },
    model: {
      value: _vm.activeBranch,
      callback: function callback($$v) {
        _vm.activeBranch = $$v;
      },
      expression: "activeBranch"
    }
  }, [_c('draggable', _vm._b({
    model: {
      value: _vm.orderList,
      callback: function callback($$v) {
        _vm.orderList = $$v;
      },
      expression: "orderList"
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.branchList, function (item, i) {
    return _c('v-list-item', {
      key: i,
      on: {
        "click": function click($event) {
          return _vm.changeBranchList();
        }
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      staticClass: "mt-2",
      staticStyle: {
        "cursor": "move"
      },
      attrs: {
        "small": ""
      }
    }, [_vm._v("drag_indicator")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.name) + " "), _c('v-icon', {
      staticClass: "ml-2 mt-n1",
      attrs: {
        "small": ""
      }
    }, [_vm._v(_vm._s(item.isHidden ? 'visibility_off' : 'visibility'))])], 1), _c('v-list-item-subtitle', {
      domProps: {
        "textContent": _vm._s(item.address)
      }
    })], 1), _c('v-list-item-action', [item.typedef === 1 ? _c('v-icon', [_vm._v("storefront")]) : _vm._e(), item.typedef === 2 ? _c('v-icon', [_vm._v("people_outline")]) : _vm._e(), item.typedef === 3 ? _c('v-icon', [_vm._v("corporate_fare")]) : _vm._e(), item.typedef === 4 ? _c('v-icon', [_vm._v("work_outline")]) : _vm._e()], 1)], 1);
  }), 1)], 1)], 1), _c('v-btn', {
    staticClass: "mt-2 ma-2",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_store');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("增加门店 ")], 1)], 1), _c('v-divider', {
    staticClass: "ml-1",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', [_c('v-scroll-y-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_vm.activeBranch === undefined ? _c('div', {
    staticClass: "title font-weight-light",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v("选择 或 增加一个门店")]) : !Object.keys(_vm.editedItem).length ? _c('v-card', {
    key: _vm.activeBranch,
    staticClass: "mx-auto",
    attrs: {
      "outlined": "",
      "max-width": "374"
    }
  }, [_c('v-img', {
    staticClass: "white--text align-end",
    attrs: {
      "height": "250",
      "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
      "src": _vm.branchList[_vm.activeBranch].profileImage ? _vm.ossURL + '/' + _vm.branchList[_vm.activeBranch].profileImage : '/static/error/empty_state.svg'
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.branchList[_vm.activeBranch].name))])], 1), _c('v-card-subtitle', {
    staticClass: "pb-0"
  }, [_vm._v(_vm._s(_vm.branchList[_vm.activeBranch].isHidden ? '前台隐藏' : '前台可见'))]), _c('v-card-text', {
    staticClass: "text--primary"
  }, [_c('div', [_vm._v(_vm._s(_vm.branchList[_vm.activeBranch].city))]), _c('div', [_vm._v(_vm._s(_vm.branchList[_vm.activeBranch].address))])]), _c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("integration_instructions")])], 1), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.branchList[_vm.activeBranch].center_code || '无服务中心代码'))])], 1), _c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("assignment_ind")])], 1), _vm.branchList[_vm.activeBranch].managers ? _c('v-list-item-subtitle', [_c('widgets-employee-dialogs', {
    attrs: {
      "account": _vm.branchList[_vm.activeBranch].managers._id
    }
  }, [_vm._v(_vm._s(_vm.branchList[_vm.activeBranch].managers.personal.name || '*未设置姓名'))])], 1) : _c('v-list-item-subtitle', [_vm._v("未指定负责人")])], 1), _c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("contact_phone")])], 1), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.branchList[_vm.activeBranch].contactName) + " - " + _vm._s(_vm.branchList[_vm.activeBranch].contactPhone))])], 1), _vm.branchList[_vm.activeBranch].support ? _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("support")])], 1), _vm.branchList[_vm.activeBranch].support.length ? _c('v-list-item-content', [_c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.branchList[_vm.activeBranch].support,
      callback: function callback($$v) {
        _vm.$set(_vm.branchList[_vm.activeBranch], "support", $$v);
      },
      expression: "branchList[activeBranch].support"
    }
  }, _vm._l(_vm.branchList[_vm.activeBranch].support, function (vendor) {
    return _c('v-chip', {
      key: vendor,
      attrs: {
        "value": vendor,
        "ripple": false,
        "filter": "",
        "label": "",
        "disabled": "",
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.vendorList.find(function (v) {
      return v._id === vendor;
    }).name) + " ")]);
  }), 1)], 1) : _c('v-list-item-subtitle', [_vm._v("无")])], 1) : _vm._e(), _c('v-list-item', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("comment_bank")])], 1), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.branchList[_vm.activeBranch].remarks || '无备注信息'))])], 1), _c('v-divider', {
    staticClass: "mx-4"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEdit();
      }
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('edit_store', _vm.branchList[_vm.activeBranch]);
      }
    }
  }, [_vm._v("编辑")])], 1)], 1) : _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-toolbar', {
    attrs: {
      "dense": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v(" " + _vm._s(_vm.editedItem._id ? '编辑分支机构' : '新建分支机构') + " ")]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-img', {
          staticClass: "white--text align-end",
          attrs: {
            "src": _vm.editedItem.profileImage ? _vm.ossURL + '/' + _vm.editedItem.profileImage : '/static/error/empty_state.svg',
            "dark": "",
            "contain": "",
            "aspect-ratio": "1.6"
          }
        }, [_c('v-expand-transition', [hover ? _c('div', {
          staticClass: "d-flex transition-fast-in-fast-out  darken-2 v-card--reveal display-3 white--text",
          staticStyle: {
            "height": "100%",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('upload_profile');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "primary"
          }
        }, [_vm._v("add_a_photo")])], 1) : _vm._e()])], 1)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "rules": [_vm.rules.required],
      "label": "名称",
      "hint": "请输入名称",
      "prepend-inner-icon": "add_business",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.inputTypedef,
      "rules": [_vm.rules.selected],
      "label": "类型",
      "hint": "请选择分支机构类型",
      "prepend-inner-icon": "category",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.typedef,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "typedef", $$v);
      },
      expression: "editedItem.typedef"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "服务中心代码",
      "hint": "与其它系统对接时该分支机构代码",
      "prepend-inner-icon": "integration_instructions",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.center_code,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "center_code", $$v);
      },
      expression: "editedItem.center_code"
    }
  }), _c('v-switch', {
    attrs: {
      "inset": "",
      "label": "\u524D\u53F0\u9690\u85CF\u8BE5\u5206\u652F\u673A\u6784: ".concat(_vm.editedItem.isHidden ? '是' : '否')
    },
    model: {
      value: _vm.editedItem.isHidden,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "isHidden", $$v);
      },
      expression: "editedItem.isHidden"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "rules": [_vm.rules.required],
      "label": "联系人姓名",
      "hint": "请输入联系人姓名",
      "prepend-inner-icon": "folder_shared",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.contactName,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "contactName", $$v);
      },
      expression: "editedItem.contactName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "rules": [_vm.rules.required],
      "label": "联系人电话",
      "hint": "请输入联系人电话",
      "prepend-inner-icon": "contact_phone",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.contactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "contactPhone", $$v);
      },
      expression: "editedItem.contactPhone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "loading": _vm.accountLoading,
      "items": _vm.accountList,
      "search-input": _vm.searchAccount,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "hide-no-data": "",
      "dense": "",
      "clearable": "",
      "label": "负责人",
      "hint": "请查找并选择分支机构负责人",
      "prepend-inner-icon": "how_to_reg"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      }
    },
    model: {
      value: _vm.editedItem.managers,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "managers", $$v);
      },
      expression: "editedItem.managers"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "rules": [_vm.rules.required],
      "error-messages": _vm.check.chkAddressMessage,
      "clearable": _vm.check.chkAddressMessage !== '',
      "label": "联系地址",
      "hint": "请输入分支机构地址",
      "prepend-inner-icon": "room",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-fade-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.check.chkAddressLoading ? _c('v-progress-circular', {
          attrs: {
            "width": "2",
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.editedItem.address,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "address", $$v);
      },
      expression: "editedItem.address"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("服务范围")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.editedItem.support,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "support", $$v);
      },
      expression: "editedItem.support"
    }
  }, _vm._l(_vm.vendorList, function (vendor) {
    return _c('v-chip', {
      key: vendor._id,
      attrs: {
        "value": vendor._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(vendor.name))]);
  }), 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "备注",
      "hint": "请输入分支机构备注信息",
      "prepend-inner-icon": "comment_bank",
      "rows": "2",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "remarks", $$v);
      },
      expression: "editedItem.remarks"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm.editedItem._id ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEdit(_vm.activeBranch);
      }
    }
  }, [_vm._v("取消")]) : _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEdit();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }