var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('span', {
    class: _vm.$vuetify.theme.dark ? '' : 'bg'
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticStyle: {
      "height": "300px"
    },
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/logo2x.svg",
      "transition": "scale-transition",
      "origin": "center center",
      "max-width": "150",
      "lass": "my-3",
      "contain": "",
      "height": "150"
    }
  })], 1), _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "550",
      "outlined": ""
    }
  }, [_c('v-tabs', {
    attrs: {
      "fixed-tabs": ""
    },
    model: {
      value: _vm.login.tabLogin,
      callback: function callback($$v) {
        _vm.$set(_vm.login, "tabLogin", $$v);
      },
      expression: "login.tabLogin"
    }
  }, [_c('v-tabs-slider'), _c('v-tab', {
    attrs: {
      "href": "#emailTap"
    }
  }, [_vm._v("电子邮件密码登陆")]), _c('v-tab', {
    attrs: {
      "href": "#phoneTap"
    }
  }, [_vm._v("手机验证码登陆")])], 1), _c('v-tabs-items', {
    model: {
      value: _vm.login.tabLogin,
      callback: function callback($$v) {
        _vm.$set(_vm.login, "tabLogin", $$v);
      },
      expression: "login.tabLogin"
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "emailTap"
    }
  }, [_c('v-card-text', [_c('v-form', {
    ref: "frmMail",
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('v-text-field', {
    attrs: {
      "autofocus": "",
      "disabled": !_vm.login.editEmail,
      "type": "email",
      "label": "注册时填写的电子邮件地址",
      "outlined": "",
      "color": "secondary",
      "error-messages": _vm.login.errMail,
      "rules": [_vm.rules.required, _vm.rules.email]
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.checkMail.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.login.mailLoading ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "indeterminate": ""
          }
        }) : _c('v-icon', {
          on: {
            "click": _vm.checkMail
          }
        }, [_vm._v("navigate_next")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.login.txtEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.login, "txtEmail", $$v);
      },
      expression: "login.txtEmail"
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "slot": "append",
      "leave-absolute": ""
    },
    slot: "append"
  }, [_vm.login.mailLoading ? _c('v-progress-circular', {
    attrs: {
      "size": "24",
      "indeterminate": ""
    }
  }) : _vm._e()], 1)], 1)], 1), _vm.login.editPass ? _c('v-form', {
    ref: "frmPass",
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "password",
      "error-messages": _vm.login.errPass,
      "label": "账号密码",
      "outlined": "",
      "color": "secondary",
      "autofocus": _vm.login.editPass,
      "rules": [_vm.rules.required]
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.checkLogin.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.login.passLoading ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "indeterminate": ""
          }
        }) : _c('v-icon', {
          on: {
            "click": _vm.checkLogin
          }
        }, [_vm._v("input")])], 1)];
      },
      proxy: true
    }], null, false, 1867044607),
    model: {
      value: _vm.login.txtPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.login, "txtPassword", $$v);
      },
      expression: "login.txtPassword"
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "slot": "append",
      "leave-absolute": ""
    },
    slot: "append"
  }, [_vm.login.passLoading ? _c('v-progress-circular', {
    attrs: {
      "size": "24",
      "indeterminate": ""
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-tab-item', {
    attrs: {
      "value": "phoneTap"
    }
  }, [_c('v-card-text', [_c('v-form', {
    ref: "frmPhone",
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '###########',
      expression: "'###########'"
    }],
    attrs: {
      "autofocus": "",
      "disabled": !_vm.login.editPhone,
      "label": "账号关联的电话号码",
      "outlined": "",
      "color": "secondary",
      "error-messages": _vm.login.errPhone,
      "rules": [_vm.rules.required, _vm.rules.phone]
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.checkPhone.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.login.phoneLoading ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "indeterminate": ""
          }
        }) : _c('v-icon', {
          on: {
            "click": _vm.checkPhone
          }
        }, [_vm._v("navigate_next")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.login.txtPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.login, "txtPhone", $$v);
      },
      expression: "login.txtPhone"
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "slot": "append",
      "leave-absolute": ""
    },
    slot: "append"
  }, [_vm.login.phoneLoading ? _c('v-progress-circular', {
    attrs: {
      "size": "24",
      "indeterminate": ""
    }
  }) : _vm._e()], 1)], 1)], 1), _vm.login.editCode ? _c('v-form', {
    ref: "frmCode",
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '####',
      expression: "'####'"
    }],
    attrs: {
      "label": "4位数字的短信验证码",
      "outlined": "",
      "autofocus": _vm.login.editCode,
      "error-messages": _vm.login.errCode,
      "color": "secondary",
      "rules": [_vm.rules.required, _vm.rules.smscode]
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.checkLogin.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.login.codeLoading ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "indeterminate": ""
          }
        }) : _c('v-icon', {
          on: {
            "click": _vm.checkLogin
          }
        }, [_vm._v("input")])], 1)];
      },
      proxy: true
    }], null, false, 2323839139),
    model: {
      value: _vm.login.txtSMSCode,
      callback: function callback($$v) {
        _vm.$set(_vm.login, "txtSMSCode", $$v);
      },
      expression: "login.txtSMSCode"
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "slot": "append",
      "leave-absolute": ""
    },
    slot: "append"
  }, [_vm.login.codeLoading ? _c('v-progress-circular', {
    attrs: {
      "size": "24",
      "indeterminate": ""
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-menu', {
    attrs: {
      "transition": "slide-y-transition",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": "",
            "color": "secondary",
            "dark": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 没有账号？立即注册 ")])];
      }
    }])
  }, [_c('v-list', [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/register',
          query: {
            owner: 'Dealer'
          }
        });
      }
    }
  }, [_c('v-list-item-title', [_vm._v("我是经销商员工（提供服务）")])], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          path: '/register',
          query: {
            owner: 'Enterprise'
          }
        });
      }
    }
  }, [_c('v-list-item-title', [_vm._v("我是企业员工（使用服务）")])], 1)], 1)], 1), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.clear
    }
  }, [_vm._v("清空")])], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "thirdLogin mx-auto mt-2"
  }, [_c('span', [_vm._v("其他登录方式：")]), _c('ul', {
    staticClass: "logoList"
  }, [_c('li', [_c('v-img', {
    attrs: {
      "src": "/static/feishu.png",
      "width": "24"
    },
    on: {
      "click": _vm.feiShuLogin
    }
  })], 1)])])])], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "550"
    },
    model: {
      value: _vm.dlgFeiShu,
      callback: function callback($$v) {
        _vm.dlgFeiShu = $$v;
      },
      expression: "dlgFeiShu"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 快速安全登录 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('widgets-feishu-qrcode', {
    attrs: {
      "appId": _vm.feishuAppId,
      "redirectUri": _vm.redirectUri
    }
  })], 1), _c('v-card-subtitle', [_c('div', {
    staticClass: "text-center text--primary"
  }, [_vm._v("使用手机飞书扫码登录")])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }