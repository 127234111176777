var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "secondary",
      "dark": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("企业管理")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "dark": "",
      "flat": "",
      "dense": "",
      "hide-details": "",
      "label": "搜索企业名称",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.doAction('search_enterprise');
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  }), _vm._v(" " + _vm._s(_vm.enterpriseCount) + "家企业 "), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.doAction('filter_enterprise');
      }
    }
  }, [_c('v-icon', [_vm._v("filter_list")])], 1), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_enterprise');
      }
    }
  }, [_c('v-icon', [_vm._v("add")])], 1)], 1)], 1), _vm.enterpriseList.length ? _c('v-row', {
    staticClass: "fill-height overflow-y-auto"
  }, [_vm._l(_vm.enterpriseList, function (enterprise, index) {
    return _c('v-col', {
      key: enterprise._id,
      attrs: {
        "lg": "4",
        "md": "4",
        "sm": "6",
        "cols": "12"
      }
    }, [_c('v-card', {
      attrs: {
        "outlined": "",
        "hover": "",
        "ripple": false,
        "color": enterprise.isBlock ? 'red lighten-4' : ''
      }
    }, [_c('v-card-title', [_c('span', {
      staticClass: "d-inline-block text-truncate"
    }, [_vm._v(_vm._s(enterprise.name))])]), _c('v-card-text', [_c('v-list', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("contact_phone")])], 1), _c('v-list-item-subtitle', [_vm._v(_vm._s(enterprise.contact_name) + " - " + _vm._s(enterprise.contact_phone))])], 1), _c('v-list-item', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item-icon', [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("room")])], 1), _c('v-list-item-subtitle', [_vm._v(_vm._s(enterprise.city) + " - " + _vm._s(enterprise.address))])], 1)], 1)], 1), _c('v-divider', {
      staticClass: "mx-4"
    }), _c('v-card-actions', [_c('v-spacer'), enterprise.inventory_warning && enterprise.inventory_warning.length ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.openInventory(index);
        }
      }
    }, [enterprise.inventory_warning.find(function (item) {
      return item.minimum >= item.count;
    }) ? _c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v("battery_charging_full")]) : _c('v-icon', [_vm._v("battery_full")])], 1) : _vm._e(), enterprise.service_catalog && enterprise.service_catalog.length ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.openService(index);
        }
      }
    }, [enterprise.service_catalog.find(function (item) {
      return _vm.diffDays(item.end_time) > 0;
    }) ? _c('v-icon', [_vm._v("handshake")]) : enterprise.service_catalog.find(function (item) {
      return _vm.diffDays(item.end_time) > -168;
    }) ? _c('v-icon', {
      attrs: {
        "color": "red"
      }
    }, [_vm._v("handshake")]) : enterprise.service_catalog.find(function (item) {
      return _vm.diffDays(item.end_time) > -720;
    }) ? _c('v-icon', {
      attrs: {
        "color": "orange"
      }
    }, [_vm._v("handshake")]) : _c('v-icon', [_vm._v("handshake")])], 1) : _vm._e(), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.doAction('open_enterprise', enterprise);
        }
      }
    }, [_c('v-icon', [_vm._v("read_more")])], 1)], 1), _c('v-expand-transition', [_vm.cardRisk[index] ? _c('v-card', {
      staticClass: "transition-fast-in-fast-out v-card--reveal overflow-y-auto",
      staticStyle: {
        "height": "100%"
      }
    }, [_c('v-card-title', [_vm._v("授信额度"), _c('v-spacer'), _c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.closeRisk(index);
        }
      }
    }, [_vm._v("close")])], 1), _c('v-card-text', [_c('v-list', {
      attrs: {
        "subheader": "",
        "dense": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("paid")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [index === 1 ? _c('span', [_vm._v("25,000.00")]) : _c('span', [_vm._v("5,200,000.00")])]), _c('v-list-item-subtitle', [_vm._v(" 授信额度 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.openCreateRisk(enterprise);
        }
      }
    }, [_c('v-icon', [_vm._v("add")])], 1)], 1)], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("production_quantity_limits")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [index === 1 ? _c('span', {
      staticClass: "red--text"
    }, [_vm._v("36,125.00")]) : _c('span', [_vm._v("1,259,910.00")])]), _c('v-list-item-subtitle', [_vm._v(" 当期应收 ")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-row', [_c('v-col', [_c('p', [_vm._v("M1")]), _c('p', [_vm._v("1,000,000.00")])]), _c('v-col', [_c('p', [_vm._v("M2")]), _c('p', [_vm._v("250,000.00")])]), _c('v-col', [_c('p', [_vm._v("M3以上")]), _c('p', [_vm._v("9,910.00")])])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1), _c('v-expand-transition', [_vm.cardService[index] ? _c('v-card', {
      staticClass: "transition-fast-in-fast-out v-card--reveal overflow-y-auto",
      staticStyle: {
        "height": "100%"
      }
    }, [_c('v-card-title', [_vm._v("服务协议"), _c('v-spacer'), _c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.closeService(index);
        }
      }
    }, [_vm._v("close")])], 1), _c('v-card-text', [_c('v-list', {
      attrs: {
        "subheader": "",
        "dense": ""
      }
    }, _vm._l(enterprise.service_catalog, function (service, i) {
      return _c('v-list-item', {
        key: 'service_' + i
      }, [_c('v-list-item-icon', [_vm.diffDays(service.end_time) > 0 ? _c('v-icon', [_vm._v("remove_circle")]) : _vm.diffDays(service.end_time) > -168 ? _c('v-icon', {
        attrs: {
          "color": "red"
        }
      }, [_vm._v("error")]) : _vm.diffDays(service.end_time) > -720 ? _c('v-icon', {
        attrs: {
          "color": "orange"
        }
      }, [_vm._v("circle_notifications")]) : _c('v-icon', [_vm._v("check_circle")])], 1), _c('v-list-item-content', [_c('v-list-item-title', _vm._l(service.catalog, function (catalog) {
        return _c('span', {
          key: catalog._id
        }, [_vm._v(" " + _vm._s(catalog.name) + " ")]);
      }), 0), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm._f("formatDate")(service.end_time)) + " - " + _vm._s(_vm._f("fromDate")(service.end_time)) + " ")])], 1)], 1);
    }), 1)], 1)], 1) : _vm._e()], 1), _c('v-expand-transition', [_vm.cardInventory[index] ? _c('v-card', {
      staticClass: "transition-fast-in-fast-out v-card--reveal overflow-y-auto",
      staticStyle: {
        "height": "100%"
      }
    }, [_c('v-card-title', [_vm._v("库存设备"), _c('v-spacer'), _c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.closeInventory(index);
        }
      }
    }, [_vm._v("close")])], 1), _c('v-card-text', [_c('v-list', {
      attrs: {
        "subheader": "",
        "dense": ""
      }
    }, _vm._l(enterprise.inventory_warning, function (inventory, i) {
      return _c('v-list-item', {
        key: 'inventory_' + i
      }, [_c('v-list-item-icon', [inventory.minimum < inventory.count ? _c('v-icon', [_vm._v("check_circle")]) : _c('v-icon', {
        attrs: {
          "color": "error"
        }
      }, [_vm._v("error")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(inventory.product.name))]), _c('v-list-item-subtitle', [_c('span', {
        class: inventory.minimum >= inventory.count ? 'error--text' : ''
      }, [_vm._v("库存：" + _vm._s(inventory.count))]), _vm._v(" - 预警：" + _vm._s(inventory.minimum) + " ")])], 1)], 1);
    }), 1)], 1)], 1) : _vm._e()], 1)], 1)], 1);
  }), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [this.query.page * this.query.limit < _vm.enterpriseCount ? _c('v-progress-circular', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.infiniteScrolling,
        options: {
          threshold: [1.0]
        }
      },
      expression: "{\n          handler: infiniteScrolling,\n          options: {\n              threshold: [1.0]\n          }\n        }"
    }],
    attrs: {
      "indeterminate": "",
      "color": "secondary",
      "width": "2"
    }
  }) : _c('span', {
    staticClass: "title font-weight-light"
  }, [_vm._v("已展示" + _vm._s(_vm.enterpriseCount) + "家企业信息，没有更多数据了。")])], 1)], 2) : _c('v-row', [_c('span', {
    staticClass: "bg"
  })]), _c('v-fab-transition', [_c('v-btn', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }, {
      name: "show",
      rawName: "v-show",
      value: _vm.fab,
      expression: "fab"
    }],
    staticClass: "transition-swing",
    staticStyle: {
      "z-index": "6"
    },
    attrs: {
      "color": "secondary",
      "fab": "",
      "dark": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "large": ""
    },
    on: {
      "click": _vm.toTop
    }
  }, [_c('v-icon', [_vm._v("keyboard_arrow_up")])], 1)], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('clear_filter');
            }
          }
        }, [_vm._v("清空")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('set_filter');
            }
          }
        }, [_vm._v("确认")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("隶属集团")]), _c('v-autocomplete', {
    staticClass: "mt-2",
    attrs: {
      "items": _vm.entGroupList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "隶属集团",
      "hint": "请选择隶属集团",
      "chips": "",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": ""
    },
    model: {
      value: _vm.query.ent_group,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "ent_group", $$v);
      },
      expression: "query.ent_group"
    }
  }), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("企业类型")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.query.corp_type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "corp_type", $$v);
      },
      expression: "query.corp_type"
    }
  }, _vm._l(_vm.corpTypedef, function (typef) {
    return _c('v-chip', {
      key: typef.code,
      attrs: {
        "value": typef.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(typef.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("企业状态")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.query.isBlock,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isBlock", $$v);
      },
      expression: "query.isBlock"
    }
  }, [_c('v-chip', {
    attrs: {
      "value": false,
      "filter": "",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("正常运营")]), _c('v-chip', {
    attrs: {
      "value": true,
      "filter": "",
      "label": "",
      "outlined": ""
    }
  }, [_vm._v("暂停运营")])], 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("包含服务")]), _vm.catalogList.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.query.service_catalog,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "service_catalog", $$v);
      },
      expression: "query.service_catalog"
    }
  }, _vm._l(_vm.flatGroupCatalog(_vm.catalogList).filter(function (c) {
    return c['group'];
  }), function (catalog) {
    return _c('v-chip', {
      key: catalog._id,
      attrs: {
        "value": catalog._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(catalog.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("服务到期")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    },
    model: {
      value: _vm.query.end_time,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "end_time", $$v);
      },
      expression: "query.end_time"
    }
  }, _vm._l(_vm.expiryDate, function (datetiem) {
    return _c('v-chip', {
      key: datetiem.code,
      attrs: {
        "value": datetiem.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(datetiem.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("信用额度")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.creditType, function (item) {
    return _c('v-chip', {
      key: item.code,
      attrs: {
        "value": item.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("应收预警")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    }
  }, _vm._l(_vm.receivableWarning, function (item) {
    return _c('v-chip', {
      key: item.code,
      attrs: {
        "value": item.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.doAction('close_info');
      }
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.dialogRiskTip,
      callback: function callback($$v) {
        _vm.dialogRiskTip = $$v;
      },
      expression: "dialogRiskTip"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 grey lighten-2"
  }, [_vm._v("信用审批"), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function click($event) {
        return _vm.closeCreateRisk();
      }
    }
  }, [_vm._v("close")])], 1), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 您正在为企业「" + _vm._s(_vm.riskName) + "」 进行授信审批申请，基于企业已存资料，还需要补充以下内容： ")]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.businessTypeList,
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "outlined": "",
      "label": "业务类型",
      "hint": "请选择业务类型"
    },
    model: {
      value: _vm.risk.editedItem.businessType,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "businessType", $$v);
      },
      expression: "risk.editedItem.businessType"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "500强企业"
    },
    model: {
      value: _vm.risk.editedItem.isG500,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "isG500", $$v);
      },
      expression: "risk.editedItem.isG500"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.cusTypeList,
      "rules": [_vm.rules.required],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "outlined": "",
      "label": "公司性质",
      "hint": "请选择公司性质"
    },
    model: {
      value: _vm.risk.editedItem.cusType,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "cusType", $$v);
      },
      expression: "risk.editedItem.cusType"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "客户联系人",
      "outlined": ""
    },
    model: {
      value: _vm.risk.editedItem.cusContact,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "cusContact", $$v);
      },
      expression: "risk.editedItem.cusContact"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "职员人数",
      "outlined": ""
    },
    model: {
      value: _vm.risk.editedItem.peopleNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "peopleNumber", $$v);
      },
      expression: "risk.editedItem.peopleNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.payConditionList,
      "rules": [_vm.rules.required],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "outlined": "",
      "label": "付款条件",
      "hint": "请选择付款条件"
    },
    model: {
      value: _vm.risk.editedItem.payCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "payCondition", $$v);
      },
      expression: "risk.editedItem.payCondition"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "原账期天数",
      "outlined": ""
    },
    model: {
      value: _vm.risk.editedItem.oldDays,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "oldDays", $$v);
      },
      expression: "risk.editedItem.oldDays"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "原额度",
      "outlined": ""
    },
    model: {
      value: _vm.risk.editedItem.oldQuota,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "oldQuota", $$v);
      },
      expression: "risk.editedItem.oldQuota"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.risk.editedItem.payCondition == 1 ? [] : [_vm.rules.required],
      "disabled": _vm.risk.editedItem.payCondition == 1,
      "type": "number",
      "label": "申请账期",
      "outlined": ""
    },
    model: {
      value: _vm.risk.editedItem.days,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "days", $$v);
      },
      expression: "risk.editedItem.days"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.risk.editedItem.payCondition == 1 ? [] : [_vm.rules.required],
      "disabled": _vm.risk.editedItem.payCondition == 1,
      "type": "number",
      "label": "申请额度",
      "outlined": ""
    },
    model: {
      value: _vm.risk.editedItem.quota,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "quota", $$v);
      },
      expression: "risk.editedItem.quota"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "最终客户",
      "outlined": ""
    },
    model: {
      value: _vm.risk.editedItem.finishCus,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "finishCus", $$v);
      },
      expression: "risk.editedItem.finishCus"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "主要竞争对手",
      "value": ""
    },
    model: {
      value: _vm.risk.editedItem.enemy,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "enemy", $$v);
      },
      expression: "risk.editedItem.enemy"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "客户情况",
      "value": ""
    },
    model: {
      value: _vm.risk.editedItem.cusDesc,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "cusDesc", $$v);
      },
      expression: "risk.editedItem.cusDesc"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "业务机会描述",
      "value": ""
    },
    model: {
      value: _vm.risk.editedItem.serviceDesc,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "serviceDesc", $$v);
      },
      expression: "risk.editedItem.serviceDesc"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "关联公司",
      "value": ""
    },
    model: {
      value: _vm.risk.editedItem.relaCus,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "relaCus", $$v);
      },
      expression: "risk.editedItem.relaCus"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "rules": [_vm.rules.selected],
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "label": "抄送商务人员",
      "hint": "请查找并选择抄送商务人员",
      "multiple": ""
    },
    model: {
      value: _vm.risk.editedItem.cc,
      callback: function callback($$v) {
        _vm.$set(_vm.risk.editedItem, "cc", $$v);
      },
      expression: "risk.editedItem.cc"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "depressed": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("done")]), _vm._v("授信审批")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }