<template>
  <v-container>
    <v-dialog
      :value="assetStateLoading ||
      partsStateLoading ||
      updateTimeLoading ||
      guaranteedLoading ||
      warrantyLoading ||
      expressLoading ||
      rentalLoading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          数据加载中...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-toolbar-title>设备管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text dark @click="$router.push({path:'/dealer/asset/list'})">
          查看所有设备
          <v-icon right>arrow_forward</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-row class="pa-4">
      <draggable :disabled="$vuetify.breakpoint.mobile" v-model="cardOrder" class="row" v-bind="dragOptions">
        <v-col md="6" sm="12" v-for="(layouts, i) in cardOrder" :key="layouts.index">
          <draggable :disabled="$vuetify.breakpoint.mobile" :list="layouts.items" :group="{ name: 'card' }" v-bind="dragOptions" :move="changeCard" @end="endCard">
            <v-col cols="12" v-for="(card, j) in layouts.items" :key="card.code">
              <v-card outlined :loading="
              card.code === 'asset_status' ? assetStateLoading
              : card.code === 'parts_status' ? partsStateLoading
              : card.code === 'asset_update' ? updateTimeLoading
              : card.code === 'warranty_deadline' ? warrantyLoading
              : card.code === 'express_state' ? expressLoading
              : card.code === 'rental_deadline' ? rentalLoading
              : guaranteedLoading">
                <v-row no-gutters align="start">
                  <v-col cols="2"><v-icon v-if="!$vuetify.breakpoint.mobile" small style="cursor: move">drag_indicator</v-icon></v-col>
                  <v-col cols="8"><span class="subtitle-2" v-if="card.close">{{ card.name }}</span></v-col>
                  <v-col cols="2" class="text-right"><v-btn icon x-small @click="foldCard([i,j])"><v-icon small>{{card.close ? 'open_in_full' : 'close_fullscreen'}}</v-icon></v-btn></v-col>
                </v-row>
                <div v-if="!card.close">
                  <v-row align="center" justify="space-between">
                    <v-col cols="12" md="6">
                      <v-card-title>{{ card.name }}</v-card-title>
                      <v-card-text>{{ card.text }}</v-card-text>
                    </v-col>
                    <v-col cols="12" md="6" v-if="!$vuetify.breakpoint.mobile">
                      <v-img :src="card.image" max-width="450px" height="135px" contain class="ma-2"></v-img>
                    </v-col>
                  </v-row>
                  <template v-for="list in card.data.data">
                    <v-divider :key="list.code +'_hr'"></v-divider>
                    <v-list-item link :key="list.code" @click="$router.push({ path: card.path +'&'+ converPath(card.code) + list.code +'&enterprise='+ query.enterprise})">
                      <v-list-item-content>
                        <v-list-item-title>{{ list.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                      <v-list-item-content>
                        <v-list-item-title>{{ list.count }}</v-list-item-title>
                      </v-list-item-content>
                      <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
                    </v-list-item>
                  </template>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-icon class="mr-2" small>schedule</v-icon>
                    <span class="caption font-weight-light">更新：{{card.data.createTime | fromDate}}</span>
                    <v-icon v-if="card.group" class="ml-2" small @click="syncData(card.group, [i,j])">sync</v-icon>
                    <v-spacer></v-spacer>
                    <v-btn v-if="card.alllink" text plain @click="$router.push({ path: card.path +'&enterprise='+ query.enterprise})">查看全部({{card.data.count}})<v-icon right>keyboard_arrow_right</v-icon></v-btn>
                  </v-list-item>
                </div>
              </v-card>
            </v-col>
          </draggable>
        </v-col>
      </draggable>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
  FETCH_PRODUCTTYPE_LIST,
  FETCH_REPORT_ASSET_COUNT
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      assetStateLoading: true,
      partsStateLoading: true,
      updateTimeLoading: true,
      guaranteedLoading: true,
      warrantyLoading: true,
      expressLoading: true,
      rentalLoading: true,
      query: {
        enterprise: '',
      },
      cardOrder: [
        { index: 'left', items: []},
        { index: 'right', items: []},
      ],
      maxRow: 3,
    }
  },
  created() {
    this.getList()
  },
  mounted() {},
  computed: {
    ...mapGetters([
      "currentUser",
      "typeList"
    ]),
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  methods: {
    async getList() {
      let urlQuery = ''
      await store.dispatch(FETCH_PRODUCTTYPE_LIST, 'noCount=false')
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.enterprise) urlQuery += '&enterprise='+ this.query.enterprise
      }

      let localCard = localStorage.getItem('esp_asset_29')
      if (localCard) this.cardOrder = JSON.parse(localCard)

      await store.dispatch(FETCH_REPORT_ASSET_COUNT, '?groupname=asset_status'+ urlQuery)
        .then((data) => {
          let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
          let objCard = {
            code: 'asset_status',
            name: '设备状态',
            text: '具备序列号的设备状态',
            data,
            image: '/static/asset/asset_status.svg',
            image_width: 450,
            path: '/dealer/asset/list?productType='+ this.typeList.map(t => t._id),
            group: 'asset_status',
            list: 'assetStateList',
            alllink: true
          }
          let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'asset_status')
          let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'asset_status')
          if (leftIndex > -1) {
            Object.assign(this.cardOrder[0].items[leftIndex], objCard)
          } else if (rightIndex > -1){
            Object.assign(this.cardOrder[1].items[rightIndex], objCard)
          } else {
            this.cardOrder[numLayout].items.push(objCard)
          }
          this.assetStateLoading = false
        })

      await store.dispatch(FETCH_REPORT_ASSET_COUNT, '?groupname=parts_status'+ urlQuery)
        .then((data) => {
          let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
          let objCard = {
            code: 'parts_status',
            name: '配件状态',
            text: '不具备序列号的配件信息',
            data,
            image: '/static/workflow/service.svg',
            image_width: 450,
            path: '/dealer/asset/list?productType='+ this.typeList.map(t => t._id) +'&typein=1',
            group: 'parts_status',
            list: 'partsStateList',
            alllink: true
          }
          let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'parts_status')
          let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'parts_status')
          if (leftIndex > -1) {
            Object.assign(this.cardOrder[0].items[leftIndex], objCard)
          } else if (rightIndex > -1){
            Object.assign(this.cardOrder[1].items[rightIndex], objCard)
          } else {
            this.cardOrder[numLayout].items.push(objCard)
          }
          this.partsStateLoading = false
        })

      await store.dispatch(FETCH_REPORT_ASSET_COUNT, '?groupname=asset_update'+ urlQuery)
        .then((data) => {
          let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
          let objCard = {
            code: 'asset_update',
            name: '设备变更',
            text: '近期设备发生过信息变更的统计',
            data,
            image: '/static/workflow/maintain.svg',
            image_width: 450,
            path: '/dealer/asset/list?',
            group: 'asset_update',
            list: 'updateTimeList',
            alllink: false
          }
          let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'asset_update')
          let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'asset_update')
          if (leftIndex > -1) {
            Object.assign(this.cardOrder[0].items[leftIndex], objCard)
          } else if (rightIndex > -1){
            Object.assign(this.cardOrder[1].items[rightIndex], objCard)
          } else {
            this.cardOrder[numLayout].items.push(objCard)
          }
          this.updateTimeLoading = false
        })
      await store.dispatch(FETCH_REPORT_ASSET_COUNT, '?groupname=warranty_deadline'+ urlQuery)
        .then((data) => {
          let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
          let objCard = {
            code: 'warranty_deadline',
            name: '质保到期',
            text: '由设备厂商提供的原厂保修计划',
            data,
            image: '/static/asset/deadline.svg',
            image_width: 450,
            path: '/dealer/asset/list?',
            group: 'warranty_deadline',
            list: 'warrantyList'
          }
          let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'warranty_deadline')
          let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'warranty_deadline')
          if (leftIndex > -1) {
            Object.assign(this.cardOrder[0].items[leftIndex], objCard)
          } else if (rightIndex > -1){
            Object.assign(this.cardOrder[1].items[rightIndex], objCard)
          } else {
            this.cardOrder[numLayout].items.push(objCard)
          }
          this.warrantyLoading = false
        })

      await store.dispatch(FETCH_REPORT_ASSET_COUNT, '?groupname=guaranteed_deadline'+ urlQuery)
        .then((data) => {
          let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
          let objCard = {
            code: 'guaranteed_deadline',
            name: '延保到期',
            text: '由经销商或厂商提供的延长保修计划',
            data,
            image: '/static/asset/deadline.svg',
            image_width: 450,
            path: '/dealer/asset/list?',
            group: 'guaranteed_deadline',
            list: 'guaranteedList'
          }
          let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'guaranteed_deadline')
          let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'guaranteed_deadline')
          if (leftIndex > -1) {
            Object.assign(this.cardOrder[0].items[leftIndex], objCard)
          } else if (rightIndex > -1){
            Object.assign(this.cardOrder[1].items[rightIndex], objCard)
          } else {
            this.cardOrder[numLayout].items.push(objCard)
          }
          this.guaranteedLoading = false
        })

      await store.dispatch(FETCH_REPORT_ASSET_COUNT, '?groupname=express_state'+ urlQuery)
        .then((data) => {
          let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
          let objCard = {
            code: 'express_state',
            name: '快递状态',
            text: '由经销商寄送出去的设备快递状态，不含单独或特殊渠道寄送设备。',
            data,
            image: '/static/asset/express.svg',
            image_width: 450,
            path: '/dealer/asset/list?',
            group: 'express_state',
            list: 'expressList',
            alllink: false
          }
          let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'express_state')
          let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'express_state')
          if (leftIndex > -1) {
            Object.assign(this.cardOrder[0].items[leftIndex], objCard)
          } else if (rightIndex > -1){
            Object.assign(this.cardOrder[1].items[rightIndex], objCard)
          } else {
            this.cardOrder[numLayout].items.push(objCard)
          }
          this.expressLoading = false
        })

      await store.dispatch(FETCH_REPORT_ASSET_COUNT, '?groupname=rental_deadline'+ urlQuery)
        .then((data) => {
          let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
          let objCard = {
            code: 'rental_deadline',
            name: '租赁到期',
            text: '租赁设备到期时间汇总统计',
            data,
            image: '/static/asset/deadline.svg',
            image_width: 450,
            path: '/dealer/asset/list?owner=rental',
            group: 'rental_deadline',
            list: 'rentalList',
            alllink: false
          }
          let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'rental_deadline')
          let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'rental_deadline')
          if (leftIndex > -1) {
            Object.assign(this.cardOrder[0].items[leftIndex], objCard)
          } else if (rightIndex > -1){
            Object.assign(this.cardOrder[1].items[rightIndex], objCard)
          } else {
            this.cardOrder[numLayout].items.push(objCard)
          }
          this.rentalLoading = false
        })

    },
    syncData(group = 'asset_status', listIndex) {
      let urlQuery = ''
      urlQuery = '&source=database'
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.enterprise) urlQuery += '&enterprise=' + this.query.enterprise
      }

      if (group === 'asset_status') this.assetStateLoading = true
      if (group === 'parts_status') this.partsStateLoading = true
      if (group === 'asset_update') this.updateTimeLoading = true
      if (group === 'guaranteed_deadline') this.guaranteedLoading = true
      if (group === 'warranty_deadline') this.warrantyLoading = true
      if (group === 'express_state') this.expressLoading = true
      if (group === 'rental_deadline') this.rentalLoading = true
      store.dispatch(FETCH_REPORT_ASSET_COUNT, '?groupname=' + group + urlQuery)
        .then((data) => {
          store.commit(SET_ERROR, {msg: '数据更新成功。', color: 'primary'});
          this.cardOrder[listIndex[0]].items[listIndex[1]].data = data
          this.assetStateLoading = false
          this.partsStateLoading = false
          this.updateTimeLoading = false
          this.guaranteedLoading = false
          this.warrantyLoading = false
          this.expressLoading = false
          this.rentalLoading = false
        })
    },
    changeCard: function(evt){
      const lastItem = this.cardOrder.find(element => element.items.length === 1)
      const moveItem = evt.draggedContext.element
      if (lastItem && lastItem.items.find(item => item.code === moveItem.code)) {
        return false
      } else {
        return
      }
    },
    endCard() {
      let objLocal = [
        {
          index: 'left',
          items: this.cardOrder[0].items
        },
        {
          index: 'right',
          items: this.cardOrder[1].items
        }
      ]
      localStorage.setItem('esp_asset_29', JSON.stringify(objLocal))
    },
    converPath(code) {
      if (code === 'asset_status' || code === 'parts_status') return 'state='
      if (code === 'asset_update') return 'updateTime='
      if (code === 'warranty_deadline') return 'warranty_deadline='
      if (code === 'guaranteed_deadline') return 'guaranteed_deadline='
      if (code === 'express_state') return 'express_state='
      if (code === 'rental_deadline') return 'rental_deadline='
    },
    foldCard(listIndex) {
      this.cardOrder[listIndex[0]].items[listIndex[1]].close = !this.cardOrder[listIndex[0]].items[listIndex[1]].close;
      this.endCard();
      this.$forceUpdate();
    },
  }
}

</script>
