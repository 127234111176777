<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>素材管理</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="doAction('search_material')"
          dark
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索素材名称和内容"
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
        <span class="subtitle-1">{{materialCount}}个素材</span>
        <!--<v-btn icon @click.stop="doAction('filter_material')">-->
          <!--<v-icon>filter_list</v-icon>-->
        <!--</v-btn>-->
        <v-btn icon @click="doAction('create_item')">
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>
    <v-alert dense type="info" class="ma-2" dismissible>
      {{materialList.length ? '该内容的维护需要素材管理权限，如需权限可到平台系统管理者处申请。' : '没有找到任何素材内容'}}
    </v-alert>
    <v-row class="fill-height overflow-y-auto" v-if="materialList.length">
      <v-col lg="4" md="4" sm="6" cols="12" v-for="material in materialList" :key="material._id">
        <v-card hover outlined class="fill-height" :ripple="false" @click="doAction('open_item', material)">
          <v-img
            :src="material.profile_image ? ossURL +'/'+ material.profile_image +'?x-oss-process=image/auto-orient,1/resize,m_lfit,w_400/quality,q_90' : '/static/error/empty_state.svg'"
            height="75"
          ></v-img>
          <v-card-title v-text="material.title"></v-card-title>
          <v-card-subtitle>{{material.updateTime | formatDate}} <widgets-employee-dialogs :key="material.editor._id" :account="material.editor._id">{{material.editor.personal.name ? material.editor.personal.name : '*未设置姓名'}}</widgets-employee-dialogs> {{material.word_count}}字</v-card-subtitle>
        </v-card>

      </v-col>
      <v-col cols="12" class="text-center">
        <v-progress-circular
          v-if="this.query.page * this.query.limit < materialCount"
          indeterminate
          color="secondary"
          width="2"
          v-intersect="{
          handler: infiniteScrolling,
          options: {
              threshold: [1.0]
          }
        }"
        ></v-progress-circular>
        <span v-else class="title font-weight-light">已展示{{materialCount}}个素材内容，没有更多数据了。</span>
      </v-col>
    </v-row>
    <v-row v-else>
      <span class="bg"></span>
    </v-row>
    <v-fab-transition>
      <v-btn
        class="transition-swing"
        v-scroll="onScroll"
        v-show="fab"
        color="secondary"
        fab
        dark
        fixed
        bottom
        right
        large
        @click="toTop"
        style="z-index: 6"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-navigation-drawer v-model="filterDrawer" app temporary bottom right style="z-index: 999;">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <!--<h2 class="subtitle-2 mt-2">素材类型</h2>-->
        <!--<v-chip-group v-model="query.material_type" column multiple>-->
          <!--<v-chip v-for="typef in materialType" :key="typef.code" :value="typef.code" filter label outlined>{{typef.name}}</v-chip>-->
        <!--</v-chip-group>-->
        <h2 class="subtitle-2 mt-2">是否置顶</h2>
        <v-chip-group v-model="query.isTop" column multiple>
          <v-chip :value="false" filter label outlined>未置顶</v-chip>
          <v-chip :value="true" filter label outlined>已置顶</v-chip>
        </v-chip-group>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doAction('clear_filter')">清空</v-btn>
          <v-btn color="secondary" text @click="doAction('set_filter')">确认</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="dlgItem" scrollable width="80%">
      <widgets-material-dialogs :id="ItemId" :key="ItemId" v-on:Close="doAction('close_item')" v-on:Delete="doAction('delete_item')"></widgets-material-dialogs>
    </v-dialog>
  </v-container>
</template>
<script>
import Util from '@/common/util';
import { mapGetters } from "vuex";
import store from "@/store";
import {
  FETCH_MATERIAL_LIST,
} from "@/store/actions.type";
export default {
  data: () => ({
    materialList: [],
    filterDrawer: null,
    fab: false,
    dlgItem: false,
    ItemId: null,
    query: {
      key: '',
      material_type: [],
      isTop: [],
      page: 1,
      limit: 10,
    },
    defaultQuery: {
      key: '',
      material_type: [],
      isTop: [],
      page: 1,
      limit: 10,
    },
  }),
  computed: {
    ...mapGetters(["currentUser", "materialLoading", "materialCount"]),
    materialType () {
      return Util.categories('materialType')
    },
  },
  mounted() {
    if (Object.keys(this.$route.query).length > 0) this.query = Object.assign(this.query, this.$route.query)
    this.getList()
  },
  created() {},
  methods: {
    getList() {
      let strQuery = '';
      if (this.query.key) strQuery += '&key='+ this.query.key
      if (this.query.isTop.length && this.query.isTop.length <= 1) strQuery += '&isTop='+ this.query.isTop
      if (this.query.material_type.length) strQuery += '&material_type='+ this.query.material_type
      if (this.query.page) strQuery += '&page='+ this.query.page
      if (this.query.limit) strQuery += '&limit='+ this.query.limit
      store.dispatch(FETCH_MATERIAL_LIST, strQuery)
        .then((data) => {
          this.materialList.push(...data.material)
        })
    },
    doAction(action, item = {}) {
      switch (action) {
        case 'create_item': {
          this.$router.push({path:'/dealer/material/edit/0'})
          break
        }
        case 'open_item': {
          this.ItemId = item._id
          this.dlgItem = true
          break
        }
        case 'close_item': {
          this.dlgItem = false
          break
        }
        case 'delete_item': {
          this.dlgItem = false
          const index = this.materialList.findIndex(item => item._id === this.ItemId)
          this.materialList.splice(index, 1)
          break
        }
        case 'search_material': {
          this.query.page = 1;
          this.query.limit = 20;
          this.materialList = [];
          this.getList();
          break
        }
        case 'filter_material': {
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'set_filter': {
          this.query.page = 1;
          this.query.limit = 20;
          this.materialList = [];
          this.getList();
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'clear_filter': {
          this.query = Object.assign({}, this.defaultQuery)
          this.materialList = []
          this.getList()
          this.filterDrawer = !this.filterDrawer
          break
        }
      }
    },
    infiniteScrolling() {
      this.query.page++;
      this.getList();
    },
    onScroll () {
      if (typeof window === 'undefined') return
      const top = (
        window.pageYOffset ||
        document.documentElement.offsetTop ||
        0
      )
      this.fab = top > 300
    },
    toTop () {
      if (this.$route.hash) {
        this.$router.push({ hash: '' })
      }
      this.$vuetify.goTo(0)
    },
  }
};
</script>
<style>
  .hideText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0;
    background: url( '/static/empty_data.png') no-repeat center center;
    background-size: contain;
    transform: scale(0.15);
  }
</style>
