<template>
  <v-container fluid>
    <component :is="identity"/>
  </v-container>
</template>

<script>
import admin from './admin/dashboard'
import dealer from './dealer/dashboard'
import enterprise from './enterprise/dashboard'
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  components: { admin, dealer, enterprise },
  data: () => ({

  }),
  computed: {
    ...mapGetters(['currentUser', 'currentEmployer']),

    identity: function() {
      let strRole = '';
      if (this.currentUser.hasAdmin.includes('admin')) {
        strRole = 'admin'
      } else if (this.currentEmployer.ownerType === 'Dealer') {
        strRole = 'dealer'
      } else {
        strRole = 'enterprise'
      }
      return strRole;
    }
  },
}
</script>
