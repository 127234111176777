var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    attrs: {
      "id": "404"
    }
  }, [_c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "align-center": "",
      "justify-center": "",
      "row": ""
    }
  }, [_c('div', {
    staticClass: "mr-3 hidden-sm-and-down"
  }, [_c('img', {
    attrs: {
      "src": "/static/error/500.svg",
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "text-md-center"
  }, [_c('h1', {
    staticClass: "text--secondary"
  }, [_vm._v("500")]), _c('h2', {
    staticClass: "my-3 headline "
  }, [_vm._v("对不起，服务器出现错误.")]), _c('div', [_c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": _vm.goHome
    }
  }, [_vm._v("首页")])], 1)])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }