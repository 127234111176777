var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fluid"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.taskList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.taskCount,
      "loading": _vm.taskLoading,
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "loading-text": "数据读取中... 请稍后",
      "item-key": "name",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("任务列表")]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer'), _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "depressed": "",
            "color": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('create_item', '', '创建新任务');
            }
          }
        }, [_c('v-icon', [_vm._v("add")]), _vm._v(" 创建任务 ")], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          class: item.isEnable ? '' : 'text-decoration-line-through'
        }, [_vm._v(_vm._s(item.name))]), item.isExpress ? _c('v-icon', {
          staticClass: "ma-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("local_shipping")]) : _vm._e(), item.isWarehouse ? _c('v-icon', {
          staticClass: "ma-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("store")]) : _vm._e()];
      }
    }, {
      key: "item.department",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.action_roles === 'applicant' ? _c('span', [_vm._v("申请人")]) : item.action_roles === 'enterprise' ? _c('span', [_vm._v("申请人团队")]) : item.action_roles === 'service' ? _c('span', [_vm._v("服务团队")]) : item.action_roles === 'assign' ? _c('span', [_vm._v("申请人指定")]) : _c('span', [_vm._v(_vm._s(item.department ? item.department.name : ''))])];
      }
    }, {
      key: "item.account",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return _vm._l(item.account, function (account) {
          return _c('widgets-employee-dialogs', {
            key: account._id,
            attrs: {
              "account": account._id,
              "divider": "- "
            }
          }, [_vm._v(" " + _vm._s(account.personal.name ? account.personal.name : '*未设置姓名') + " ")]);
        });
      }
    }, {
      key: "item.SLA_limit",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(item.SLA_limit === 0 ? '不限' : item.SLA_limit))])];
      }
    }, {
      key: "item.act",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('edit_item', item, '编辑任务');
            }
          }
        }, [_vm._v("edit")]), !item.isEnable ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_item', item, true);
            }
          }
        }, [_vm._v("check_circle_outline")]) : _vm._e(), item.isEnable ? _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('active_item', item, false);
            }
          }
        }, [_vm._v("block")]) : _vm._e()];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("获取数据")])];
      },
      proxy: true
    }])
  }), _vm.dlgEdit ? _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "750px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm.editedItem.isExpress ? _c('span', [_vm._v("快递任务系统将自动创建承运公司和运单号字段。")]) : _vm.editedItem.isWarehouse ? _c('span', [_vm._v("入库任务选择的字段为旧设备可编辑内容。")]) : _c('span', [_vm._v("一般任务系统常用任务类型。")])])], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "任务名称",
      "hint": "请输入任务名称",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": [{
        name: '一般任务',
        code: 'general'
      }, {
        name: '快递任务',
        code: 'express'
      }, {
        name: '入库任务',
        code: 'warehouse'
      }],
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "label": "任务类型",
      "hint": "请选择任务类型",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_task_type');
      }
    },
    model: {
      value: _vm.editedItem.taskType,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "taskType", $$v);
      },
      expression: "editedItem.taskType"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "任务提示",
      "value": ""
    },
    model: {
      value: _vm.editedItem.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "remarks", $$v);
      },
      expression: "editedItem.remarks"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.getDeptList(_vm.departments[0]),
      "rules": [_vm.rules.required, _vm.rules.selected],
      "item-text": "name",
      "item-value": "value",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "请选择执行部门"
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_employee_list');
      }
    },
    model: {
      value: _vm.editedItem.department,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "department", $$v);
      },
      expression: "editedItem.department"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    ref: "selectAccount",
    attrs: {
      "items": _vm.accountList,
      "disabled": !_vm.selectAccount,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "multiple": "",
      "deletable-chips": "",
      "clearable": "",
      "label": "执行人员 空代表部门内人员都可执行",
      "hint": "请选择任务执行人员"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }], null, false, 59584339),
    model: {
      value: _vm.editedItem.account,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "account", $$v);
      },
      expression: "editedItem.account"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "suffix": "小时",
      "type": "number",
      "label": "SLA时效",
      "hint": "输入该任务在处理时SLA限时，0代表不限制",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.SLA_limit,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "SLA_limit", _vm._n($$v));
      },
      expression: "editedItem.SLA_limit"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6 d-flex flex-nowrap",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 可执行动作： "), _c('v-checkbox', {
    staticClass: "mt-n1 mx-4",
    attrs: {
      "multiple": "",
      "hide-details": "",
      "label": "取消",
      "value": "cancel"
    },
    model: {
      value: _vm.editedItem.actions,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "actions", $$v);
      },
      expression: "editedItem.actions"
    }
  }), _c('v-checkbox', {
    staticClass: "mt-n1 mx-4",
    attrs: {
      "multiple": "",
      "disabled": "",
      "hide-details": "",
      "label": "保存",
      "value": "save"
    },
    model: {
      value: _vm.editedItem.actions,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "actions", $$v);
      },
      expression: "editedItem.actions"
    }
  }), _c('v-checkbox', {
    staticClass: "mt-n1 mx-4",
    attrs: {
      "multiple": "",
      "hide-details": "",
      "label": "协助",
      "value": "assist"
    },
    model: {
      value: _vm.editedItem.actions,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "actions", $$v);
      },
      expression: "editedItem.actions"
    }
  }), _c('v-checkbox', {
    staticClass: "mt-n1 mx-4",
    attrs: {
      "multiple": "",
      "disabled": "",
      "hide-details": "",
      "label": "完成",
      "value": "complete"
    },
    model: {
      value: _vm.editedItem.actions,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "actions", $$v);
      },
      expression: "editedItem.actions"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n2 d-flex flex-nowrap",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 可操作内容： "), _c('v-checkbox', {
    staticClass: "mt-n1 mx-4",
    attrs: {
      "multiple": "",
      "hide-details": "",
      "label": "上传附件",
      "value": "upload_files"
    },
    model: {
      value: _vm.editedItem.powers,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "powers", $$v);
      },
      expression: "editedItem.powers"
    }
  }), _c('v-checkbox', {
    staticClass: "mt-n1 mx-4",
    attrs: {
      "multiple": "",
      "hide-details": "",
      "label": "增加备注",
      "value": "create_remarks"
    },
    model: {
      value: _vm.editedItem.powers,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "powers", $$v);
      },
      expression: "editedItem.powers"
    }
  }), _c('v-checkbox', {
    staticClass: "mt-n1 mx-4",
    attrs: {
      "multiple": "",
      "hide-details": "",
      "label": "关联资产",
      "value": "relate_asset"
    },
    model: {
      value: _vm.editedItem.powers,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "powers", $$v);
      },
      expression: "editedItem.powers"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.editedItem.actions.includes('complete') ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.stateList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "label": "该任务完成后设备状态",
      "hint": "当任务完成后设备状态",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.asset_complete,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "asset_complete", $$v);
      },
      expression: "editedItem.asset_complete"
    }
  }) : _vm._e()], 1), !_vm.editedItem.isExpress ? _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 需编辑资产：资产字段和任务字段是该任务处理时，需要编辑的内容。 "), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.editedItem.asset_fields,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "asset_fields", $$v);
      },
      expression: "editedItem.asset_fields"
    }
  }, _vm._l(_vm.fields_asset, function (f, i) {
    return _c('v-chip', {
      key: 'asset_field_' + i,
      attrs: {
        "value": f.field,
        "filter": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(f.name))]);
  }), 1)], 1) : _vm._e(), !_vm.editedItem.isExpress ? _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-subheader', [_vm._v("可选择任务字段")]), _c('v-virtual-scroll', {
    attrs: {
      "items": _vm.fields_task,
      "item-height": 50,
      "height": "175"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var index = _ref6.index,
            item = _ref6.item;
        return [_c('v-list-item', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(item.name)
          }
        }), item.format.type === 'text' ? _c('v-list-item-subtitle', [_vm._v("文本输入")]) : _vm._e(), item.format.type === 'selects' ? _c('v-list-item-subtitle', [_vm._v("单项选择")]) : _vm._e()], 1), _c('v-list-item-action', [_c('v-btn', {
          attrs: {
            "depressed": "",
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('add_fields_task', index);
            }
          }
        }, [_vm._v("选择字段"), _c('v-icon', {
          attrs: {
            "right": ""
          }
        }, [_vm._v("keyboard_arrow_right")])], 1)], 1)], 1), _c('v-divider')];
      }
    }], null, false, 1964768014)
  })], 1) : _vm._e(), !_vm.editedItem.isExpress ? _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-subheader', [_vm._v("已选择任务字段")]), _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('draggable', _vm._b({
    attrs: {
      "list": _vm.editedItem.fields,
      "handle": ".drag"
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.editedItem.fields, function (element, index) {
    return _c('v-list-item', {
      key: element.name
    }, [_c('v-list-item-icon', [_c('v-icon', {
      staticClass: "mt-2 drag",
      attrs: {
        "small": ""
      }
    }, [_vm._v("drag_indicator")])], 1), _c('v-list-item-content', [element.name ? _c('v-list-item-title', {
      domProps: {
        "innerHTML": _vm._s(element.name)
      }
    }) : _vm._e(), element.model === 'asset' ? _c('v-list-item-subtitle', [_vm._v("资产字段")]) : _c('v-list-item-subtitle', [_vm._v("任务字段")])], 1), _c('v-list-item-action', [element.model === 'asset' ? _c('v-btn', {
      attrs: {
        "depressed": "",
        "small": "",
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.doAction('remove_fields_asset', index);
        }
      }
    }, [_c('v-icon', [_vm._v("remove")])], 1) : _c('v-btn', {
      attrs: {
        "depressed": "",
        "small": "",
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.doAction('remove_fields_task', index);
        }
      }
    }, [_c('v-icon', [_vm._v("remove")])], 1)], 1)], 1);
  }), 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-divider'), _c('v-card-actions', [!_vm.editedItem.isExpress ? _c('widgets-custom-field', {
    attrs: {
      "module": "Task",
      "ownerId": _vm.currentEmployer.ownerId._id,
      "ownerType": "Dealer"
    },
    on: {
      "Complete": function Complete($event) {
        return _vm.doAction('update_field');
      }
    }
  }) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }