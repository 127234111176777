var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/dealer/workbench');
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("租赁核价模块")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "flat": "",
      "dense": "",
      "hide-details": "",
      "label": "搜索报价编号、名称...",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.doAction('search_item');
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-sheet', {
    attrs: {
      "color": "grey lighten-3"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "color": "transparent"
    }
  }, [_c('v-card-title', {
    staticClass: "font-weight-regular subtitle-1"
  }, [_vm._v(" 创建新报价 ")]), _c('v-card-text', [_c('v-row', {
    staticClass: "px-6"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-card', {
          staticClass: "overflow-hidden",
          attrs: {
            "height": "100",
            "flat": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push('/dealer/workbench/rent/create');
            }
          }
        }, [_c('v-card-text', {
          staticClass: "fill-height",
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-icon', {
          staticClass: "mt-5",
          attrs: {
            "color": hover ? 'secondary' : 'grey'
          }
        }, [_vm._v("add")])], 1)], 1)];
      }
    }])
  })], 1), _vm._l(_vm.topList, function (item) {
    return _c('v-col', {
      key: item._id,
      attrs: {
        "cols": "2"
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var hover = _ref2.hover;
          return [_c('v-card', {
            staticClass: "overflow-hidden",
            attrs: {
              "height": "100",
              "outlined": "",
              "hover": ""
            },
            on: {
              "click": function click($event) {
                return _vm.$router.push('/dealer/workbench/rent/create?templateId=' + item._id + '&type=template');
              }
            }
          }, [_c('v-img', {
            staticClass: "align-end",
            attrs: {
              "src": item.cover_image ? _vm.ossURL + '/' + item.cover_image : '/static/error/empty_state.svg',
              "height": "100",
              "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            }
          }, [_c('v-card-title', {
            staticClass: "pa-2 caption white--text",
            domProps: {
              "textContent": _vm._s(item.title)
            }
          })], 1), _c('v-fade-transition', [hover ? _c('div', {
            staticClass: "d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal body-2 white--text",
            staticStyle: {
              "height": "100%",
              "cursor": "pointer"
            }
          }, [_c('v-icon', {
            attrs: {
              "color": "secondary"
            }
          }, [_vm._v("add")])], 1) : _vm._e()])], 1)];
        }
      }], null, true)
    })], 1);
  })], 2), _c('v-expansion-panels', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    attrs: {
      "color": "grey lighten-3",
      "hide-actions": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var open = _ref3.open;
        return [_c('span', {
          staticClass: "caption secondary--text"
        }, [_vm._v(_vm._s(open ? "收起" : "显示全部"))])];
      }
    }])
  }), _c('v-expansion-panel-content', {
    staticClass: "pa-0",
    attrs: {
      "color": "grey lighten-3"
    }
  }, [_c('v-row', _vm._l(_vm.templateList, function (item) {
    return _c('v-col', {
      key: item._id,
      attrs: {
        "cols": "2"
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref4) {
          var hover = _ref4.hover;
          return [_c('v-card', {
            staticClass: "overflow-hidden",
            attrs: {
              "height": "100",
              "outlined": "",
              "hover": ""
            },
            on: {
              "click": function click($event) {
                return _vm.$router.push('/dealer/workbench/rent/create?templateId=' + item._id + '&type=template');
              }
            }
          }, [_c('v-img', {
            staticClass: "align-end",
            attrs: {
              "src": item.cover_image ? _vm.ossURL + '/' + item.cover_image : '/static/error/empty_state.svg',
              "height": "100",
              "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            }
          }, [_c('v-card-title', {
            staticClass: "pa-2 caption white--text",
            domProps: {
              "textContent": _vm._s(item.title)
            }
          })], 1), _c('v-fade-transition', [hover ? _c('div', {
            staticClass: "d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal body-2 white--text",
            staticStyle: {
              "height": "100%",
              "cursor": "pointer"
            }
          }, [_c('v-icon', {
            attrs: {
              "color": "secondary"
            }
          }, [_vm._v("add")])], 1) : _vm._e()])], 1)];
        }
      }], null, true)
    })], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "options": _vm.optionsItem,
      "headers": _vm.headers,
      "items": _vm.rentQuoteList,
      "server-items-length": _vm.rentQuoteCount,
      "footer-props": {
        'items-per-page-options': [5, 10, 15]
      }
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          staticClass: "d-print-none",
          attrs: {
            "flat": ""
          }
        }, [_c('v-card-title', {
          staticClass: "font-weight-regular pl-0 subtitle-1"
        }, [_vm._v("我的报价")]), _c('v-spacer'), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    _vm.filterdrawer = true;
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.id",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              return _vm.checkOfferInfo(item._id);
            }
          }
        }, [item.status == 2 ? _c('v-badge', {
          attrs: {
            "color": "secondary",
            "dot": ""
          }
        }, [_c('span', [_vm._v(_vm._s(item.offer_number))])]) : _c('span', [_vm._v(_vm._s(item.offer_number))])], 1)];
      }
    }, {
      key: "item.name",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          staticClass: "text-truncate",
          attrs: {
            "nowrap": "true",
            "width": "200"
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")])];
      }
    }, {
      key: "item.enterprise",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          staticClass: "v-list-item--link text-truncate",
          attrs: {
            "nowrap": "true",
            "width": "200"
          },
          on: {
            "click": function click($event) {
              return _vm.openEnterInfo(item.enterprise.id);
            }
          }
        }, [_vm._v(" " + _vm._s(item.enterprise.name) + " ")])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.expiry_date <= new Date().toISOString() ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已过期")]) : item.status == '0' ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已保存")]) : item.status == '1' ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "primary"
          }
        }, [_vm._v("已审核")]) : item.status == '2' ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "secondary"
          }
        }, [_vm._v("已更新")]) : _vm._e()];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.expiry_date",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.expiry_date)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref14) {
              var on = _ref14.on,
                  attrs = _ref14.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "primary",
                  "dark": "",
                  "icon": "",
                  "small": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("more_vert")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [(item.status == '0' || item.status == '2') && item.expiry_date > new Date().toISOString() && (_vm.pLevel >= 1 || _vm.currentUser.hasAdmin.includes('dealer')) ? _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.confirmApprove(item);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("check")])], 1), _c('v-list-item-title', [_vm._v(" 审核 ")])], 1) : _vm._e(), _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.copyOffer(item._id, item.template);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("content_copy")])], 1), _c('v-list-item-title', [_vm._v(" 复制 ")])], 1), _vm.pLevel >= 1 || _vm.currentUser.hasAdmin.includes('dealer') ? _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.confirmDelete(item);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("delete")])], 1), _c('v-list-item-title', [_vm._v(" 删除 ")])], 1) : _vm._e()], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1), _c('v-navigation-drawer', {
    staticStyle: {
      "z-index": "999"
    },
    attrs: {
      "app": "",
      "temporary": "",
      "right": "",
      "height": "100%"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('clear_filter');
            }
          }
        }, [_vm._v("清空")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('set_filter');
            }
          }
        }, [_vm._v("确认")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterdrawer,
      callback: function callback($$v) {
        _vm.filterdrawer = $$v;
      },
      expression: "filterdrawer"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "flat": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "headline py-2"
  }, [_vm._v("筛选")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-sheet', {
    attrs: {
      "min-height": "500"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('v-autocomplete', {
    attrs: {
      "label": "客户",
      "autocomplete": "off",
      "items": _vm.enterpriseList,
      "item-text": "name",
      "item-value": "_id",
      "hide-no-data": "",
      "loading": _vm.isEnterpriseLoading,
      "search-input": _vm.searchEnterprise,
      "outlined": "",
      "dense": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchEnterprise = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchEnterprise = $event;
      }
    },
    model: {
      value: _vm.query.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "enterprise", $$v);
      },
      expression: "query.enterprise"
    }
  })], 1)])], 1)], 1)], 1), _c('v-navigation-drawer', {
    staticStyle: {
      "z-index": "999"
    },
    attrs: {
      "app": "",
      "temporary": "",
      "right": "",
      "width": "500",
      "height": "100%"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('div', {
          staticClass: "pa-2"
        }, [_c('v-btn', {
          attrs: {
            "block": "",
            "outlined": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.changedrawer = false;
            }
          }
        }, [_vm._v("提交")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.changedrawer,
      callback: function callback($$v) {
        _vm.changedrawer = $$v;
      },
      expression: "changedrawer"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "flat": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "headline py-2"
  }, [_vm._v("非凡量子询价")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-sheet', {
    attrs: {
      "min-height": "500"
    }
  }, [_c('v-sheet', {
    staticClass: "pa-2",
    attrs: {
      "color": "primary"
    }
  }, [_c('span', {
    staticStyle: {
      "color": "#fff"
    }
  }, [_vm._v("调整参数")])]), _c('div', {
    staticClass: "mt-6"
  }, [_c('v-text-field', {
    attrs: {
      "label": "计算器有效期",
      "item-text": "name",
      "item-value": "code",
      "readonly": "",
      "dense": "",
      "outlined": "",
      "value": "2022-04-15"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "年利率（资金成本）",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "7.5",
      "suffix": "%"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "风险控制系数",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "2.5",
      "suffix": "%"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "还租次数（年）",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "4"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "还款总期数",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "12"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "租赁方式",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "经营性租赁"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "净租金成本",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "421.23"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "不含账期费租金",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "452.82"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "账期费用（元/期）",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "2.98"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "租赁报价（元/期）",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "455.80"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "期末余值",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "200"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "期末余值折现",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "164.79"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "IRR",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "14.79"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "经营性租赁月均价",
      "item-text": "name",
      "item-value": "code",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "value": "151.93"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "width": "800",
      "persistent": ""
    },
    model: {
      value: _vm.newSingle,
      callback: function callback($$v) {
        _vm.newSingle = $$v;
      },
      expression: "newSingle"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline font-weight-regular"
  }, [_vm._v("新增VNC")]), _c('v-divider'), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "名称",
      "color": "primary",
      "outlined": "",
      "dense": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "IP地址",
      "color": "primary",
      "outlined": "",
      "dense": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "端口号",
      "color": "primary",
      "outlined": "",
      "dense": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }), _c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "所属学校",
      "items": _vm.schoolItems,
      "placeholder": "选择学校",
      "color": "primary",
      "outlined": "",
      "dense": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "所属教室",
      "items": _vm.classItems,
      "placeholder": "选择教室",
      "color": "primary",
      "outlined": "",
      "dense": ""
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.newSingle = false;
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.newSingle = false;
      }
    }
  }, [_vm._v("提交")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "600px",
      "persistent": ""
    },
    model: {
      value: _vm.enterInfoDlg,
      callback: function callback($$v) {
        _vm.enterInfoDlg = $$v;
      },
      expression: "enterInfoDlg"
    }
  }, [_c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": _vm.closeEnterInfo
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }