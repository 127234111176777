<template>
  <div class="fluid">
    <v-data-table
      :headers="headerTable"
      :items="vendorList"
      :options.sync="optionsTable"
      :server-items-length="vendorCount"
      :loading="vendorLoading"
      loading-text="数据读取中... 请稍后"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
      :item-class="itemRowBackground"
      fixed-header
      height="600"
      item-key="name"
      transition
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>品牌厂商列表</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="query.key"
            label="关键字搜索（按回车）"
            prepend-inner-icon="search"
            :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
            solo
            flat
            dense
            hide-details
            clearable
            @keydown.enter="searchItem"
            @click:clear="clearSearchItem"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="secondary" depressed @click="doAction('create_vendor', '', '新增品牌厂商')">
            <v-icon>add</v-icon>
            新建品牌厂商
          </v-btn>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click.stop="openFilter">
                <v-icon small>filter_list</v-icon>
              </v-btn>
            </template>
            <span>过滤数据</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:header.scale>
        <v-icon size="18" class="mx-2" @click="headerScale = !headerScale">{{headerScale ? 'keyboard_backspace' : 'keyboard_tab'}}</v-icon>
      </template>
      <template v-slot:item.address="{ item }">
        <td class="text-truncate" :style="headerScale ? '' : 'max-width: 125px;'">{{ item.address }}</td>
      </template>
      <template v-slot:item.contactName="{ item }">
        <td>{{ item.contactName }} {{item.contactPhone}} {{item.contactEmail}}</td>
      </template>
      <template v-slot:item.purchaser="{ item }">
        <widgets-employee-dialogs v-for="account in item.purchaser" :key="account._id" :account="account._id" divider="- ">
          {{account.personal.name ? account.personal.name : '*未设置姓名'}}
        </widgets-employee-dialogs>
      </template>
      <template v-slot:item.department="{ item }">
        <span>{{ item.department ? item.department.name : '' }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="doAction('edit_vendor', item, '编辑品牌厂商')">edit</v-icon>
        <v-icon small @click="doAction('active_vendor', item, true)" v-if="!item.isEnable">check_circle_outline</v-icon>
        <v-icon small @click="doAction('active_vendor', item, false)" v-if="item.isEnable">block</v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataList()">重新获取数据</v-btn>
      </template>
    </v-data-table>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">是否启用</h2>
        <v-chip-group v-model="query.isEnable" column mandatory @change="setFilter">
          <v-chip v-for="enable in ProductEnable" :key="enable.code" :value="enable.code" filter label outlined>{{enable.name}}</v-chip>
        </v-chip-group>
      </v-card-text>
    </v-navigation-drawer>
    <v-dialog v-model="dlgEdit" persistent scrollable max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>{{dlgTitle}}</v-card-title>
          <v-card-text style="height: 500px;">
            <v-container>
              <v-text-field
                v-model="editedItem.name"
                :rules="[rules.required]"
                type="text"
                label="品牌厂商名称"
                hint="请输入品牌厂商名称"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="editedItem.contactName"
                type="text"
                label="联系人"
                hint="请输入品牌厂商联系人姓名"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="editedItem.contactPhone"
                type="text"
                label="联系电话"
                hint="请输入品牌厂商联系电话"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="editedItem.contactEmail"
                :rules="[rules.email]"
                type="text"
                label="联系邮件"
                hint="请输入品牌厂商联系邮件"
                outlined
                dense
              ></v-text-field>
              <v-text-field
                v-model="editedItem.address"
                :error-messages="editedItem.msgAddress"
                :clearable="editedItem.msgAddress !== ''"
                type="text"
                label="品牌厂商地址"
                hint="品牌厂商实际地址"
                outlined
                dense
              >
                <template v-slot:append>
                  <v-fade-transition leave-absolute>
                    <v-progress-circular
                      v-if="editedItem.chkAddress"
                      width="2"
                      size="24"
                      color="secondary"
                      indeterminate
                    ></v-progress-circular>
                  </v-fade-transition>
                </template>
              </v-text-field>
              <v-autocomplete
                v-model="editedItem.department"
                :items="getDeptList(departments[0])"
                @change="doAction('set_employee_list')"
                item-text="name"
                item-value="value"
                autocomplete="off"
                outlined
                dense
                clearable
                label="请选择外采负责部门"
              ></v-autocomplete>
              <v-autocomplete
                v-model="editedItem.purchaser"
                :items="accountList"
                :disabled="!selectPurchaser"
                ref="selectPurchaser"
                item-text="personal.name"
                item-value="_id"
                autocomplete="off"
                outlined
                dense
                chips
                small-chips
                multiple
                deletable-chips
                clearable
                label="采购人员 空代表部门内人员都可处理外采请求"
                hint="请选择外采人员"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.username}} - {{data.item.phone}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-alert type="info">
                <h1 class="text-h6">厂商标示</h1>
                <div>将在【维修服务报告】中展示，<strong class="text--secondary">请勿使用非正式的图片！</strong>默认显示经销商LOGO。</div>
                <div>建议采用透明或白色背景，黑色前景的图片。</div>
                <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
                <v-row>
                  <v-col cols="6">
                    <input
                      id="files"
                      type="file"
                      name="file"
                      ref="uploadInput"
                      accept="image/*"
                      :multiple="false"
                      @change="detectFiles($event)"/>
                    <v-card color="white" flat :loading="Upload.uploading ? 'secondary' : ''" class="d-flex justify-center">
                      <v-img class="gray-img" :src="ossURL + (editedItem.imgLogo ? '/'+ editedItem.imgLogo : '/default/logo2x.png')" aspect-ratio="1" max-width="120px"  contain></v-img>
                    </v-card>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center"><v-btn text @click="uploadLogo"><v-icon left>upload</v-icon>上传</v-btn></v-col>
                </v-row>
              </v-alert>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapGetters } from "vuex";
import store from "@/store";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_VENDOR_LIST,
  PUBLISH_VENDOR,
  ACTIV_VENDOR,
  EDIT_VENDOR,
  CHECK_ADDR, FETCH_UPLOAD, FETCH_ACCOUNT_LIST, FETCH_DEPT_LIST,
} from "@/store/actions.type";
import axios from "axios";
import Util from "@/common/util";
export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      selectPurchaser: false,
      editedIndex: -1,
      editedItem: {
        _id: '',
        name: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        address: '',
        chkAddress: false,
        msgAddress: '',
        imgLogo: '',
        department: null,
        purchaser: [],
      },
      defaultItem: {
        _id: '',
        name: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        address: '',
        chkAddress: false,
        msgAddress: '',
        imgLogo: 'default/logo2x.png',
        department: null,
        purchaser: [],
      },
      filterDrawer: false,
      query: {
        key: '',
        isEnable: true,
      },
      defaultQuery: {
        key: '',
        isEnable: true,
      },
      headerScale: false,
      headerTable: [
        {text: '名称', width: '125px', value: 'name'},
        {text: '联系人', width: '125px', value: 'contactName'},
        {text: '地址', width: '125px', value: 'address'},
        {text: '', value: 'scale', active: true, sortable: false, width: '20px', class: 'px-0'},
        {text: '外采部门', width: '155px', value: 'department'},
        {text: '外采人员', width: '225px', value: 'purchaser'},
        {text: '操作', width: '85px', value: 'actions', align: 'end', sortable: false}
      ],
      optionsTable: {},
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_DEPT_LIST),
    ]).then(() => {
      next();
    });
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "vendorLoading",
      "vendorList",
      "departments",
      "uploadSign",
      "vendorCount",
      "accountList"]),
    ProductEnable () {
      return Util.categories('ProductEnable')
    }
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
    'editedItem.address'() {
      this.checkAddress();
    },
  },
  mounted() {},
  methods: {
    searchItem() {
      this.optionsTable.page = 1
      this.getDataList()
    },
    clearSearchItem() {
      this.optionsTable.page = 1
      this.query.key = ''
      this.getDataList()
    },
    openFilter() {
      this.filterDrawer = !this.filterDrawer
    },
    setFilter() {
      this.$router.push({ query: {} });
      this.getDataList(this.query)
    },
    getDataList() {
      // 获取分页信息
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + encodeURIComponent(this.query.key)
      if (this.query.isEnable !== '' && this.query.isEnable !== undefined) urlQuery += '&isEnable='+ this.query.isEnable
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_VENDOR_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    doAction(action, item, customitem) {
      switch (action) {
        case 'search_key': {
          if (this.query.key !== '') {
            this.getDataList({key: this.query.key})
          } else {
            this.getDataList()
          }
          break
        }
        case 'create_vendor': case 'edit_vendor': {
          this.editedIndex = this.vendorList.indexOf(item)
          this.editedItem = Object.assign({}, item)
          this.dlgTitle = customitem
          if (action === 'create_vendor' && this.currentEmployer.ownerId.address) {
            this.editedItem.address = this.currentEmployer.ownerId.address
            this.checkAddress()
          }
          if (action === 'edit_vendor') {
            this.selectPurchaser = true
            if (this.editedItem.department) {
              this.editedItem.department = this.editedItem.department._id
              store.dispatch(FETCH_ACCOUNT_LIST, '&limit=100&department='+ this.editedItem.department)
                .then(() => {
                  if (this.editedItem.purchaser) this.editedItem.purchaser = this.editedItem.purchaser.map(o => o._id)
                })
            } else {
              this.editedItem.department = null
            }
          }
          this.dlgEdit = true
          break
        }
        case 'active_vendor': {
          if (confirm(`确定要 ${customitem ? '启用' : '禁用'} ${item.name}？`)) {
            store.dispatch(ACTIV_VENDOR, {_id: item._id, reply: customitem})
              .then(() => {
                store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
                this.getDataList()
                this.close()
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          }
          break
        }
        case 'set_employee_list': {
          this.editedItem.purchaser = []
          this.$refs.selectPurchaser.internalSearch = null;
          if (this.editedItem.department) {
            store.dispatch(FETCH_ACCOUNT_LIST, '&limit=100&department='+ this.editedItem.department)
            this.selectPurchaser = true
          }
          break
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    close () {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    checkAddress: _.debounce(function () {
      if (this.editedItem.address) {
        this.editedItem.chkAddress = true;
        store.dispatch(CHECK_ADDR, this.editedItem.address)
          .then((data) => {
            if (data.status !== 0 || data.result.reliability < 7) {
              this.editedItem.msgAddress = '地址信息错误，请输入正确的地址信息';
              this.$forceUpdate()
            } else {
              this.editedItem.addressInfo = data.result;
              this.editedItem.msgAddress = '';
              this.$forceUpdate()
            }
            this.editedItem.chkAddress = false;
          })
          .catch((err) => {
            this.editedItem.msgAddress = err.message;
            this.editedItem.chkAddress = false;
            this.$forceUpdate()
          })
      } else {
        this.editedItem.msgAddress = '';
        this.editedItem.chkAddress = false;
        this.$forceUpdate()
      }
    }, 1000),
    submit() {
      let strInsert = {}
      strInsert.name = this.editedItem.name
      strInsert.contactName = this.editedItem.contactName
      strInsert.contactPhone = this.editedItem.contactPhone
      strInsert.contactEmail = this.editedItem.contactEmail
      strInsert.imgLogo = this.editedItem.imgLogo
      strInsert.department = this.editedItem.department
      strInsert.purchaser = this.editedItem.purchaser
      if (this.editedItem.address) strInsert.address = this.editedItem.address
      if (this.editedItem.addressInfo) {
        strInsert.addressInfo = this.editedItem.addressInfo
      }
      if (this.editedIndex !== -1) {
        strInsert._id = this.editedItem._id
        store.dispatch(EDIT_VENDOR, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_VENDOR, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }

    },
    itemRowBackground: function (item) {
      if (!item.isEnable) return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
    },
    uploadLogo() {
      store.dispatch(FETCH_UPLOAD)
        .then(this.$refs.uploadInput.click())
        .catch(err => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', 'vendor/'+ this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.editedItem.imgLogo = 'vendor/'+ this.uploadSign.key;
        })
        .catch((error) => {
          // console.log('url => '+ JSON.stringify(error))
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
    getDeptList(obj) {
      let arrObj = [];
      arrObj.push({name: obj.name, value: obj._id})
      if (obj.children) {
        for (let item_1 of obj.children) {
          arrObj.push({name: obj.name +'>>'+ item_1.name, value: item_1._id})
          if (item_1.children) {
            for (let item_2 of item_1.children) {
              arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name, value: item_2._id})
              if (item_2.children) {
                for (let item_3 of item_2.children) {
                  arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name +'>>'+ item_3.name, value: item_3._id})
                }
              }
            }
          }
        }
      }
      return arrObj;
    },
  }
}
</script>
<style scoped>
  .gray-img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
</style>
