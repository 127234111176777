<template>
  <v-sheet class="overflow-hidden" color="grey lighten-3" height="100%">
    <v-row no-gutters height="100%" class="fill-height">
      <v-col lg="10" md="12" sm="12" cols="12" class="pa-12" elevation=6>
        <v-sheet elevation=6 class="overflow-y-auto fill-height justify-center" style="position:relative" height="100%" width="100%">
          <!--头部元素-->
          <element-app-bar></element-app-bar>
          <!--正文元素-->
          <!--ID: {{pageId}} - Content: {{page_content}}-->
          <draggable v-model="orderList" v-bind="dragOptions" handle=".handle">
            <v-row no-gutters v-for="(item, i) in page_content" :key="i" style="position: relative">
              <!--<div class="float-left mb-n6" style="align-self:center;"><v-icon class="handle" small style="cursor: move"  color="grey lighten-2">drag_indicator</v-icon></div>-->
              <v-icon class="handle" small style="cursor: move; position: absolute; top: 50%; z-index: 6"  color="grey lighten-2">drag_indicator</v-icon>
              <v-col cols="12">
                <element-text v-if="item.type === 'text'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-text>
                <element-carousel v-if="item.type === 'carousel'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-carousel>
                <element-service v-if="item.type === 'service'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-service>
                <element-material v-if="item.type === 'material'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-material>
                <element-image v-if="item.type === 'image'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-image>
                <element-horizontal v-if="item.type === 'horizontal'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-horizontal>
                <element-onerowcard v-if="item.type === 'onerowcard'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-onerowcard>
                <element-twocolcard v-if="item.type === 'twocolcard'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-twocolcard>
                <element-threecolcard v-if="item.type === 'threecolcard'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-threecolcard>
                <element-threecard v-if="item.type === 'threecard'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-threecard>
                <element-twocard v-if="item.type === 'twocard'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-twocard>
                <element-fourcard v-if="item.type === 'fourcard'" :key="i" :index="i" :id="pageId" :element="item" v-on:DeleteItem="getPageInfo('body')" v-on:UpdateItem="getPageInfo('body')"></element-fourcard>
              </v-col>
            </v-row>
          </draggable>
          <!--底部元素-->
          <element-app-footer></element-app-footer>
        </v-sheet>
      </v-col>
      <v-col lg="2" md="12" sm="12">
          <v-navigation-drawer permanent clipped fixed right width="235px" class="mt-14">
            <v-list dense>
              <v-subheader>插入</v-subheader>
              <v-list-item link @click="addComponent('text')">
                <v-list-item-icon>
                  <v-icon>text_fields</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>文本</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link @click="addComponent('carousel')">
                <v-list-item-icon>
                  <v-icon>view_carousel</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>轮播</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link @click="addComponent('service')">
                <v-list-item-icon>
                  <v-icon>volunteer_activism</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>服务</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!--<v-list-item link @click="addComponent('material')">-->
                <!--<v-list-item-icon>-->
                  <!--<v-icon>article</v-icon>-->
                <!--</v-list-item-icon>-->
                <!--<v-list-item-content>-->
                  <!--<v-list-item-title>素材</v-list-item-title>-->
                <!--</v-list-item-content>-->
              <!--</v-list-item>-->
              <v-list-item link @click="addComponent('horizontal')">
                <v-list-item-icon>
                  <v-icon>horizontal_rule</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>横线</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-subheader>卡片</v-subheader>
            </v-list>
            <v-row>
              <v-col lg="6" md="6" sm="12" cols="6">
                <v-card hover outlined class="ml-2" @click="addComponent('onerowcard')">
                  <v-img src="/static/thumbnail/card_one_row.svg" alt="单个单行卡片" contain></v-img>
                </v-card>
              </v-col>
              <v-col lg="6" md="6" sm="12" cols="6" @click="addComponent('twocolcard')">
                <v-card hover outlined class="mr-2">
                  <v-img src="/static/thumbnail/card_two_col.svg" alt="两列两个卡片" contain></v-img>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6" md="6" sm="12" cols="6" @click="addComponent('threecolcard')">
                <v-card hover outlined class="ml-2">
                  <v-img src="/static/thumbnail/card_three.svg" alt="两列三个卡片" contain></v-img>
                </v-card>
              </v-col>
              <v-col lg="6" md="6" sm="12" cols="6" @click="addComponent('threecard')">
                <v-card hover outlined class="mr-2">
                  <v-img src="/static/thumbnail/card_three_col.svg" alt="三列三个卡片" contain></v-img>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col lg="6" md="6" sm="12" cols="6" @click="addComponent('twocard')">
                <v-card hover outlined class="ml-2">
                  <v-img src="/static/thumbnail/card_two_row.svg" alt="两个单行卡片" contain></v-img>
                </v-card>
              </v-col>
              <v-col lg="6" md="6" sm="12" cols="6" @click="addComponent('fourcard')">
                <v-card hover outlined class="mr-2">
                  <v-img src="/static/thumbnail/card_four.svg" alt="四个单行卡片" contain></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-navigation-drawer>
      </v-col>
    </v-row>
  </v-sheet>

</template>

<script>
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_PAGE,
  EDIT_PAGE,
} from "@/store/actions.type";
import draggable from "vuedraggable";
import {mapGetters} from "vuex";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      pageId: '',
      page_content: [],
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
    dragOptions() {
      return {
        animation: 200,
        group: "content",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    orderList: {
      get() {
        return this.page_content;
      },
      set(value) {
        this.$store.dispatch(EDIT_PAGE, { _id: this.pageId, config: value })
          .then(() => {
            this.getPageInfo('body')
          })
          .catch((err) => {
            this.$store.commit(SET_ERROR, {msg: err.response.data.message});
          });
      }
    },
  },
  watch: {},
  created() {
    this.getPageInfo('body')
  },
  methods: {
    getPageInfo(component='body') {
      this.$store.dispatch(FETCH_PAGE, '?name=home&component='+ component +'&owner='+ this.currentUser.employer.ownerId._id)
        .then(data => {
          this.pageId = data._id
          this.page_content = data.config
        });
    },
    addComponent(comType) {
      this.page_content.push({type: comType})
      this.$store.dispatch(EDIT_PAGE, { _id: this.pageId, config: this.page_content })
        .then(() => {
          this.getPageInfo('body')
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, {msg: err.response.data.message});
        });
      // switch (comType) {
      //   case 'text': {
      //     this.page_content.push({
      //       type: comType,
      //     })
      //     break
      //   }
      // }
    }
  },
}
</script>
