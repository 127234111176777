<template>
  <v-container>

    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>自定义字段列表</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="searchItems()"
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索字段名称..."
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
      </v-toolbar>
      <v-alert dense type="info" class="ma-2" dismissible>
        自定义字段包含经销商和企业两部分。
        <span class="font-weight-bold">删除的字段仅在删除之后不可用，但此前已经使用的数据仍然可以查看。</span>
      </v-alert>

      <v-data-table
        :headers="headerItem"
        :items="fieldList"
        :options.sync="optionsItem"
        :server-items-length="fieldCount"
        :loading="fieldLoading"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
        item-key="_id"
        return-object="false"
        transition
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <v-icon class="mr-4">fact_check</v-icon>
              <span>字段列表</span>
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click.stop="filterDrawer = !filterDrawer">
                  <v-icon small>filter_list</v-icon>
                </v-btn>
              </template>
              <span>过滤数据</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item.name="{ item }">
          <td nowrap="true" @click="openItem(item)" class="v-list-item--link text-truncate">{{item.name}}</td>
        </template>
        <template v-slot:item.ownerType="{ item }">
          <td nowrap="true">{{item.ownerType === 'Dealer' ? '经销商' : '企业'}}</td>
        </template>
        <template v-slot:item.ownerId="{ item }">
          <td nowrap="true" v-if="item.ownerType === 'Enterprise'" @click="openEnterprise(item.ownerId._id)" class="v-list-item--link">{{item.ownerId.name}}</td>
          <td nowrap="true" v-else>{{item.ownerId.name}}</td>
        </template>
        <template v-slot:item.module="{ item }">
          <td nowrap="true">{{item.module | stringModule}}</td>
        </template>
        <template v-slot:item.createTime="{ item }">
          <td nowrap="true">{{item.createTime | formatTime}}</td>
        </template>
      </v-data-table>
    </v-card>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list dense>
        <v-subheader class="mt-n1 font-weight-bold">筛选</v-subheader>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>雇主类型</v-list-item-title>
            <v-list-item-subtitle>
              <v-chip-group v-model="query.type" column @change="searchItems">
                <v-chip filter outlined :value="'All'">全部</v-chip>
                <v-chip filter outlined :value="'Dealer'">经销商</v-chip>
                <v-chip filter outlined :value="'Enterprise'">企业</v-chip>
              </v-chip-group>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="query.type === 'Enterprise'">
          <v-list-item-content>
            <v-list-item-title>企业名称</v-list-item-title>
            <v-list-item-subtitle>
              <v-autocomplete
                autocomplete="off"
                v-model="query.enterprise"
                :items="fieldEnterpriseList"
                @change="searchItems"
                item-text="name"
                item-value="_id"
                outlined
                dense
                clearable
              ></v-autocomplete>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>功能模块</v-list-item-title>
            <v-list-item-subtitle>
              <v-chip-group v-model="query.module" multiple column @change="searchItems">
                <v-chip filter outlined :value="'Account'">人员</v-chip>
                <v-chip filter outlined :value="'Asset'">资产</v-chip>
                <v-chip filter outlined :value="'Product'">产品</v-chip>
                <v-chip filter outlined :value="'Task'">任务</v-chip>
              </v-chip-group>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDrawer = !filterDrawer">隐藏</v-btn>
          <v-btn color="secondary" text @click="clearSearch()">清空</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="dlgInfo" persistent scrollable width="600px">
      <widgets-enterprise-dialogs v-if="activeType === 'enterprise'" :id="activeId" :key="activeId" @Close="closeInfo"></widgets-enterprise-dialogs>
      <v-card v-if="activeType === 'field'">
        <v-form v-model="valid" @submit.prevent="submit">
          <v-card-title class="headline">
            编辑{{activeObject.module | stringModule}}字段
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="activeObject.name"
                  :rules="[rules.required]"
                  type="text"
                  label="字段名称"
                  hint="字段名称提示用户输入内容"
                  outlined
                  dense>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  autocomplete="off"
                  v-model="activeObject.format.type"
                  :items="inputType"
                  :rules="[rules.selected]"
                  label="输入形式"
                  item-text="name"
                  item-value="code"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="activeObject.format.type === 'text'" class="mt-n6">
              <v-col cols="12">
                <v-text-field
                  v-model="activeObject.format.mask"
                  type="text"
                  label="内容格式"
                  hint="限定内容，为空即不限制任何输入"
                  append-outer-icon="help_outline"
                  @click:append-outer="helpMask()"
                  outlined
                  dense>
                </v-text-field>
                <v-btn text x-small @click="setMask('datatime')" class="mt-n6">
                  日期时间 2016-12-12 20:32
                </v-btn>
                <v-btn text x-small @click="setMask('time')" class="mt-n6">
                  时间 05:23:12
                </v-btn>
                <v-btn text x-small @click="setMask('telnumber')" class="mt-n6">
                  国际电话 +086-01062011122
                </v-btn>
                <v-btn text x-small @click="setMask('compose')" class="mt-n6">
                  字母数字组合 CN001
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="activeObject.format.type === 'selects'">
              <v-col cols="12" v-for="(item, i) in activeObject.format.items" :key="i">
                <v-text-field
                  v-model="activeObject.format.items[i]"
                  type="text"
                  label="内容"
                  hint="下拉菜单选项内容"
                  outlined
                  dense
                  class="mt-n6"
                  append-outer-icon="remove"
                  @click:append-outer="removeSelectItem(item)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="text-center mt-n6">
                <v-btn text @click="addSelectItem()">
                  <v-icon>add</v-icon>
                  增加下拉菜单内容
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  autocomplete="off"
                  v-model="activeObject.format.rules"
                  :items="inputRules"
                  label="输入验证"
                  hint="请选择用户输入该字段时验证方式"
                  item-text="name"
                  item-value="code"
                  outlined
                  dense
                  multiple
                  chips
                  small-chips
                  deletable-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="primary" text @click="dlgDelete = !dlgDelete">删除</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeInfo">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">确定</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">确认要删除该项目？</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDelete">取消</v-btn>
          <v-btn color="secondary" text @click="removeField">确认删除</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {EDIT_FIELD, FETCH_FIELD_LIST, REMOVE_FIELD} from "@/store/actions.type";
import Util from "@/common/util";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  data() {
    return {
      query: {
        key: '',
        type: 'All',
        enterprise: '',
        module: []
      },
      defaultQuery: {
        key: '',
        type: 'All',
        enterprise: '',
        module: []
      },
      headerItem: [
        { text: '名称', value: 'name', width: "125px" },
        { text: '雇主类型', value: 'ownerType', width: "75px" },
        { text: '雇主名称', value: 'ownerId', width: "135px" },
        { text: '所属模块', value: 'module', width: "75px" },
        { text: '创建时间', value: 'createTime', width: "125px" },
      ],
      optionsItem: {},
      filterDrawer: false,
      dlgInfo: false,
      activeType: null,
      activeId: '',
      activeObject: {},
      valid: true,
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      inputType: [
        { name: "文本", code: 'text' },
        { name: "下拉菜单", code: 'selects' },
      ],
      dlgDelete: false,
    }
  },
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "fieldList",
      "fieldLoading",
      "fieldCount",
      "fieldEnterpriseList",
    ]),
    inputRules () {
      return Util.categories('inputRules')
    }
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList()
      },
      deep: true,
    },
  },
  methods: {
    goBack() {
      this.$router.push({path: '/dealer/system'})
    },
    searchItems() {
      this.getList();
    },
    clearSearch() {
      this.query = Object.assign({}, this.defaultQuery)
      this.$router.push({ query: {} });
      this.getList()
    },
    getList() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) this.query = Object.assign(this.query, this.$route.query);

      const { key, type, enterprise, module } = this.query;
      let urlQuery = '';
      if (key) urlQuery += '&key='+ key
      if (type && type !== 'All') urlQuery += '&type='+ type
      if (enterprise) urlQuery += '&enterprise='+ enterprise
      if (module && module.length) urlQuery += '&module='+ module
      // console.log('query =>'+ JSON.stringify(urlQuery))
      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_FIELD_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    openEnterprise(enterpriseId) {
      this.activeType = 'enterprise'
      this.activeId = enterpriseId
      this.dlgInfo = true
    },
    closeInfo() {
      this.dlgDelete = false
      this.dlgInfo = false
      this.getList()
    },
    closeDelete() {
      this.dlgDelete = false
    },
    openItem(objField) {
      this.activeType = 'field'
      this.activeObject = objField
      this.dlgInfo = true
    },
    submit() {
      let strCreate = {}
      strCreate['format'] = {}
      strCreate['_id'] = this.activeObject._id
      strCreate['name'] = this.activeObject.name
      strCreate['format'] = this.activeObject.format

      store.dispatch(EDIT_FIELD, strCreate)
        .then(()=> {
          store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
          this.closeInfo()
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    removeField() {
      let strCreate = {}
      strCreate['_id'] = this.activeObject._id

      store.dispatch(REMOVE_FIELD, {data: strCreate})
        .then(()=> {
          store.commit(SET_ERROR, {msg: '删除成功', color: 'primary'});
          this.closeInfo()
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    helpMask() {
      window.open('https://v-mask-demo.netlify.app/');
    },
    setMask(format) {
      if (format === 'datatime') this.activeObject.format.mask = '####-##-## ##:##'
      if (format === 'time') this.activeObject.format.mask = '##:##:##'
      if (format === 'telnumber') this.activeObject.format.mask = '+(###)-###########'
      if (format === 'compose') this.activeObject.format.mask = 'AA###'
    },
    addSelectItem() {
      this.activeObject.format.items.push('');
    },
    removeSelectItem(item) {
      const index = this.activeObject.format.items.indexOf(item)
      this.activeObject.format.items.splice(index, 1)
    }
  },
}
</script>
