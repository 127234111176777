<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>{{title_page()}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn :disabled="!valid" icon @click="submit">
          <v-icon>save</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form @submit.prevent="submit">
        <v-subheader>授信详情</v-subheader>
        <v-row>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            企业名称
          </v-col>
          <v-col cols="2">
            {{ editedItem.enterprise?.name }}
          </v-col>
          <v-col cols="2">
            企业授信类型
          </v-col>
          <v-col cols="2">
            {{ editedItem.enterpriseType?.name }}
          </v-col>
          <v-col cols="2">
            所在地
          </v-col>
          <v-col cols="2">
            {{ editedItem.city }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            客户当前授信额度
          </v-col>
          <v-col cols="2">
            {{ editedItem.creditLine }}
          </v-col>
          <v-col cols="2">
            总评分
          </v-col>
          <v-col cols="2">
            {{ editedItem.totalScore }}分
          </v-col>
          <v-col cols="2">
            计算时间
          </v-col>
          <v-col cols="2">
            {{ editedItem.updateTime | formatTime }}
          </v-col>
        </v-row>

        <v-subheader>区域层面 {{ editedItem.areaScore }}分</v-subheader>
        <v-row>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            地区生产总值（GDP）
          </v-col>
          <v-col cols="2">
            {{ editedItem.GDPTotal1 }}（亿元）
          </v-col>
          <v-col cols="2">
            国内三线（70城）平均地区生产总值
          </v-col>
          <v-col cols="2">
            {{ editedItem.GDPTotal2 }}（亿元）
          </v-col>
          <v-col cols="2">
            生产总值得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.GDPTotalScore }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            地区生产总值增长率
          </v-col>
          <v-col cols="2">
            {{ parseFloat(editedItem.GDPGrowthRate1 * 100).toFixed(2) }}%
          </v-col>
          <v-col cols="2">
            国内GDP增长率
          </v-col>
          <v-col cols="2">
            {{ parseFloat(editedItem.GDPGrowthRate2 * 100).toFixed(2) }}%
          </v-col>
          <v-col cols="2">
            GDP增长率得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.GDPGrowthRateScore }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            当地人均GDP
          </v-col>
          <v-col cols="2">
            {{ editedItem.GDPPerCapita1 }}（元）
          </v-col>
          <v-col cols="2">
            全国人均GDP
          </v-col>
          <v-col cols="2">
            {{ editedItem.GDPPerCapita2 }}（元）
          </v-col>
          <v-col cols="2">
            人均GDP得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.GDPPerCapitaScore }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            土地依赖
          </v-col>
          <v-col cols="2">
            {{ parseFloat(editedItem.landDependenceRatio1 * 100).toFixed(2) }}%
          </v-col>
          <v-col cols="2">
            参照土地依赖
          </v-col>
          <v-col cols="2">
            {{ parseFloat(editedItem.landDependenceRatio2 * 100).toFixed(2) }}%
          </v-col>
          <v-col cols="2">
            土地依赖得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.landDependenceScore }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            政府债务负担
          </v-col>
          <v-col cols="2">
            {{ parseFloat(editedItem.debtBudgetRatio1 * 100).toFixed(2) }}%
          </v-col>
          <v-col cols="2">
            参照政府债务负担
          </v-col>
          <v-col cols="2">
            {{ parseFloat(editedItem.debtBudgetRatio2 * 100).toFixed(2) }}%
          </v-col>
          <v-col cols="2">
            政府债务负担得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.debtBudgetScore }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            一般预算收入
          </v-col>
          <v-col cols="2">
            {{ editedItem.budgetRevenue1 }}（亿元）
          </v-col>
          <v-col cols="2">
            国内三线（70城）平均一般预算收入
          </v-col>
          <v-col cols="2">
            {{ editedItem.budgetRevenue2 }}（亿元）
          </v-col>
          <v-col cols="2">
            一般预算收入得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.budgetRevenueScore }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            一般预算收入增长率
          </v-col>
          <v-col cols="2">
            {{ parseFloat(editedItem.budgetRevenueGrowthRate1 * 100).toFixed(2) }}%
          </v-col>
          <v-col cols="2">
            国内一般预算收入增长率
          </v-col>
          <v-col cols="2">
            {{ parseFloat(editedItem.budgetRevenueGrowthRate2 * 100).toFixed(2) }}%
          </v-col>
          <v-col cols="2">
            一般预算收入增长率得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.budgetRevenueGrowthRateScore }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            财政自给率
          </v-col>
          <v-col cols="2">
            {{ parseFloat(editedItem.selfSufficiencyRate1 * 100).toFixed(2) }}%
          </v-col>
          <v-col cols="2">
            国内财政自给率
          </v-col>
          <v-col cols="2">
            {{ parseFloat(editedItem.selfSufficiencyRate2 * 100).toFixed(2) }}%
          </v-col>
          <v-col cols="2">
            财政自给率得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.selfSufficiencyRateScore }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            区域级别
          </v-col>
          <v-col cols="2">
            {{ editedItem.cityLevel ? editedItem.cityLevel.name : '-' }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            区域级别得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.cityLevelScore }}分
          </v-col>
        </v-row>
        <v-subheader>企业层面 {{ editedItem.enterpriseScore }}分</v-subheader>
        <v-row>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            行业得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.industry ? editedItem.industry.name : '-' }}
          </v-col>
          <v-col cols="2">
            {{ editedItem.industry ? editedItem.industry.score : '-' }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            企业规模得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.enterpriseScale?.name }}
          </v-col>
          <v-col cols="2">
            {{ editedItem.enterpriseScale?.score }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            企业成立时长得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.estYear }}年
          </v-col>
          <v-col cols="2">
            {{ editedItem.establishYear?.score }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            注册资金得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.registerCapi }}
          </v-col>
          <v-col cols="2">
            {{ editedItem.registerCapital?.score }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            实缴资金得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.paidCapi }}
          </v-col>
          <v-col cols="2">
            {{ editedItem.paidCapital ? editedItem.paidCapital.score : '-' }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            人力健康参数得分
          </v-col>
          <v-col cols="2">
            人员规模（参保人数）当前年度
          </v-col>
          <v-col cols="2">
            {{ editedItem.socialInsuranceCurrent }}人
          </v-col>
          <v-col cols="2">
            人员规模（参保人数）历史年度
          </v-col>
          <v-col cols="2">
            {{ editedItem.socialInsuranceHitory }}人
          </v-col>
          <v-col cols="2">
            {{ editedItem.humanHealthScore }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            经营异常&司法舆情
          </v-col>
          <v-col cols="10">
            <v-row v-for="(item, i) in editedItem.exception" :key="'_exception_'+ i">
              <v-col cols="2">
                {{ excepTypeList.find(n => n.code === item.excepType) ? excepTypeList.find(n => n.code === item.excepType).name : '' }}
              </v-col>
              <v-col cols="2">
                {{ item.score }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            企业附加信息
          </v-col>
          <v-col cols="10">
            <v-row>
              <v-col cols="3">
                企业管理层（法人/实控人/负责人/财务/监事）
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.additionDataManagerScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                核心职工人数/总职工人数
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.additionDataEmployeeNumScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                企业上下游合作方（上下游各5家）
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.additionDataPartnerScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                流量曝光度
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.additionDataTrafficScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            财务实力
          </v-col>
          <v-col cols="10">
            <v-row>
              <v-col cols="3">
                总资产 （{{ editedItem.totalAssets }}）
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.totalAssetsScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                货币资金
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.moneyFundsScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                净资产
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.netAssetsScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                营业总收入（{{ editedItem.totalIncome }}）
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.totalIncomeScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                净利润（{{ editedItem.netProfit }}）
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.netProfitScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                资产负债率
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.debtRatioScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                流动比率
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.currentRatioScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                现金流量利息保障倍数
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.cashInterestRatioScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                经营性收入/营业总收入
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.incomeRatioScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                存货周转天数
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.inventoryDayScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                应收账款周转天数
              </v-col>
              <v-col cols="1">
                <v-text-field
                  v-model="editedItem.accountDayScore"
                  type="text"
                  label="得分"
                  hint="请输入得分"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-subheader>我司层面 {{ editedItem.companyScore }}分</v-subheader>
        <v-row>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            与我司合作时长得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.coopYear }}年
          </v-col>
          <v-col cols="2">
            {{ editedItem.cooperationYear && editedItem.cooperationYear.score }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            付款条件（账期）得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.paymentPrd == null ? '-' : editedItem.paymentPrd }}天
          </v-col>
          <v-col cols="2">
            {{ editedItem.paymentPeriod && editedItem.paymentPeriod.score }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            历史交易金额得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.historyAmt == null ? '-' : editedItem.historyAmt }}元
          </v-col>
          <v-col cols="2">
            {{ editedItem.historyAmount && editedItem.historyAmount.score }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            最长逾期时间得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.maxOverdDay == null ? '-' : editedItem.maxOverdDay }}天
          </v-col>
          <v-col cols="2">
            {{ editedItem.maxOverdueDay && editedItem.maxOverdueDay.score }}分
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            逾期金额/交易金额得分
          </v-col>
          <v-col cols="2">
            {{ editedItem.overdueAmtRatio == null ? '-' : (editedItem.overdueAmtRatio * 100).toFixed(2) }}%
          </v-col>
          <v-col cols="2">
            {{ editedItem.overdueAmountRatio && editedItem.overdueAmountRatio.score }}分
          </v-col>
        </v-row>
      </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!--<v-btn text @click="doAction('clear_filter')">清空</v-btn>-->
        <v-btn color="secondary" @click="submit" :disabled="!valid">保存</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-tabs class="mt-4" ref="logs">
      <v-tab>变更记录</v-tab>
      <v-tab>超额记录</v-tab>
      <v-tab-item>
        <v-timeline dense clipped align-top v-if="logList && logList.length">
          <v-timeline-item
            v-for="(item, n) in logList"
            :key="n"
            small
            fill-dot
            :icon="item.action === 'Create' ? 'add' : item.action === 'Batch' ? 'layers' : 'create'"
          >
            <v-row class="mt-n4">
              <v-col cols="5">
                <strong>{{ item.createTime | formatTime }}</strong> {{item.action | stringModule}}
                <div class="caption"><widgets-employee-dialogs :account="item.operator._id" class="v-list-item--link">{{item.operator.personal.name ? item.operator.personal.name : '*未设置姓名'}}<v-icon class="mx-2">read_more</v-icon></widgets-employee-dialogs></div>
              </v-col>
              <v-col cols="7" class="text-right" v-if="item.record.length">
                <div class="caption text-left" v-for="(change, j) in item.record" :key="j">
                  <span v-if="item.action === 'Batch'">{{change.text}}: {{change.value}}</span>
                  <span v-else>{{change}}</span>
                </div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<script>
import store from "@/store";
import Util from '@/common/util';
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  EDIT_RISK_ENTERPRISE,
  FETCH_RISK_ENTERPRISE,
  RE_CAL_CREDITLINE,
  FETCH_LOGS_LIST,
} from "@/store/actions.type";
export default {
  data: () => ({
    title_page: function () {
      return (this.$route.params.id !== '1' && this.$route.params.id !== '0') ? '风控详情' : '风控计算';
    },
    btnFab: false,
    editedItem: {},
    dataTimeMenu: false,
    rules: {
      required: value => (value === 0 || !!value) || '请输入内容.',
      phonenum: value => {
        if (value) {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        } else {
          return true;
        }
      },
      selected: value => {
        if (!value || value.length < 1) {
          return '请至少选择一个选项。'
        } else {
          return true;
        }
      },
      email: value => {
        if (value) {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        } else {
          return true;
        }
      },
    },
    valid: true,
    logList: [],
  }),
  watch: {},

  computed: {
    ...mapGetters(["currentUser"]),
    businessTypeList () {
      return Util.categories('RiskBusinessType')
    },
    excepTypeList() {
      return Util.categories('ExcepType')
    },
  },
  async created () {
    if (this.$route.params.id !== '0') {
      this.getRiskEnterprise(this.$route.params.id);
      this.getLogList(this.$route.params.id);

      if (this.$route.query.showlog === '1') {
        // 添加延迟以解决元素可能没有渲染完或位置不正确的问题
        setTimeout(() => {
          this.scrollTo();
        }, 150);
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push({path: `/dealer/system/risk/enterprise`});
    },
    getRiskEnterprise(id) {
      this.$store.dispatch(FETCH_RISK_ENTERPRISE, id)
        .then(data => {
          this.editedItem = Object.assign({}, data);
        });
    },
    async getLogList(id) {
      store.dispatch(FETCH_LOGS_LIST, '&limit=100&key=' + id)
        .then((logs) => {
          this.logList = logs.logs;
        })
    },
    submit(goto = '') {
      let strInsert = {}

      strInsert.additionDataManagerScore = this.editedItem.additionDataManagerScore;
      strInsert.additionDataEmployeeNumScore = this.editedItem.additionDataEmployeeNumScore;
      strInsert.additionDataPartnerScore = this.editedItem.additionDataPartnerScore;
      strInsert.additionDataTrafficScore = this.editedItem.additionDataTrafficScore;

      strInsert.totalAssetsScore = this.editedItem.totalAssetsScore;
      strInsert.moneyFundsScore  = this.editedItem.moneyFundsScore ;
      strInsert.netAssetsScore = this.editedItem.netAssetsScore;
      strInsert.totalIncomeScore = this.editedItem.totalIncomeScore;
      strInsert.netProfitScore = this.editedItem.netProfitScore;
      strInsert.debtRatioScore = this.editedItem.debtRatioScore;
      strInsert.currentRatioScore = this.editedItem.currentRatioScore;
      strInsert.cashInterestRatio = this.editedItem.cashInterestRatio;
      strInsert.incomeRatioScore = this.editedItem.incomeRatioScore;
      strInsert.inventoryDayScore = this.editedItem.inventoryDayScore;
      strInsert.accountDayScore  = this.editedItem.accountDayScore ;

      strInsert._id = this.editedItem._id
      store.dispatch(EDIT_RISK_ENTERPRISE, strInsert)
        .then(() => {
          store.dispatch(RE_CAL_CREDITLINE, {
            riskEnterpriseId: this.editedItem._id,
          })
            .then(() => {
              store.commit(SET_ERROR, { msg: '修改成功', color: 'primary' });
              this.$router.push({ path: `/dealer/system/risk/enterprise` });
            }).catch((error) => {
              console.error('error', error)
              store.commit(SET_ERROR, { msg: error.response.data.message });
            });
        })
        .catch((error) => {
          store.commit(SET_ERROR, { msg: error.response.data.message });
        })

      if (goto) {
        this.$router.push({path: goto});
      }
    },
    doAction(action, item, index) {
      switch (action) {
        case 'add_industry': {
          this.editedItem.industry.push({
            name: '',
            score: null,
          });
          break
        }
        case 'remove_industry': {
          this.editedItem.industry.splice(index, 1);
          break
        }
      }
    },
    scrollTo() {
      const element = this.$refs.logs.$el;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  }
}
</script>
