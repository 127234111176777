var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "flat": "",
      "dark": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("服务提供")]), _c('v-spacer')], 1), _c('v-card-title', [_vm._v(" 服务目录 ")]), _c('v-card-subtitle', [_vm._v(" 服务目录是经销商为企业提供的所有服务介绍，服务目录最多为2级，企业用户可以查看服务介绍，并在经销商授权后使用该服务。本平台中所有流程也必须属于某一类服务，企业用户是否可以使用流程基于是否已经授权使用该服务。 ")]), _c('v-row', {
    staticClass: "pa-4",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_vm.catalogLoading ? _c('div', {
    staticClass: "article-preview"
  }, [_vm._v("读取服务目录信息...")]) : _c('v-list', {
    staticClass: "mx-n4",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    model: {
      value: _vm.activeList,
      callback: function callback($$v) {
        _vm.activeList = $$v;
      },
      expression: "activeList"
    }
  }, [_c('draggable', _vm._b({
    model: {
      value: _vm.orderList,
      callback: function callback($$v) {
        _vm.orderList = $$v;
      },
      expression: "orderList"
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.catalogList, function (list, index) {
    return _c('v-list-group', {
      key: list._id,
      attrs: {
        "color": "secondary"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn() {
          return [_c('v-list-item-icon', [_c('v-icon', {
            staticClass: "mt-2",
            staticStyle: {
              "cursor": "move"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("drag_indicator")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
            domProps: {
              "textContent": _vm._s(list.name)
            }
          }), _c('v-list-item-subtitle', [_vm._v(_vm._s(list.children.length) + "个服务")])], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('draggable', _vm._b({
      attrs: {
        "list": list.children
      },
      on: {
        "change": function change($event) {
          return _vm.changeChildren(list.children);
        }
      }
    }, 'draggable', _vm.dragOptions, false), _vm._l(list.children, function (item, i) {
      return _c('v-list-item', {
        key: item._id,
        staticClass: "ml-12",
        attrs: {
          "link": "",
          "value": index + ',' + i
        }
      }, [_c('v-list-item-icon', [_c('v-icon', {
        staticStyle: {
          "cursor": "move"
        },
        attrs: {
          "small": ""
        }
      }, [_vm._v("drag_indicator")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.name) + " "), _c('v-icon', {
        staticClass: "ml-2 mt-n1",
        attrs: {
          "small": ""
        }
      }, [_vm._v(_vm._s(item.isHidden ? 'visibility_off' : 'visibility'))])], 1)], 1)], 1);
    }), 1)], 1);
  }), 1)], 1)], 1), _c('v-btn', {
    staticClass: "mt-2 ma-2",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_catalog', 'parent');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("增加服务分类 ")], 1)], 1), _c('v-divider', {
    staticClass: "ml-1",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', [_c('v-scroll-y-transition', {
    attrs: {
      "mode": "out-in"
    }
  }, [_vm.activeList === undefined && !Object.keys(_vm.editedItem).length ? _c('div', {
    staticClass: "title font-weight-light",
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" 选择 或 增加服务 ")]) : Object.keys(_vm.editedItem).length ? _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.editedItem._id ? '编辑服务' : '新建服务') + _vm._s(_vm.editedItem.parentId ? '' : '分类') + " ")]), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_vm.editedItem.parentId ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-avatar', {
          attrs: {
            "tile": "",
            "size": "164"
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "164"
          }
        }, [_vm._v(_vm._s(_vm.editedItem.profileIcon ? _vm.editedItem.profileIcon : 'add_circle_outline'))]), _c('v-expand-transition', [hover ? _c('div', {
          staticClass: "d-flex transition-fast-in-fast-out  darken-2 v-card--reveal display-3 white--text",
          staticStyle: {
            "height": "100%",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('change_icon');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": "",
            "color": "primary"
          }
        }, [_vm._v("edit")])], 1) : _vm._e()])], 1)];
      }
    }], null, false, 1076943003)
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_vm.editedItem.parentId ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.catalogList.filter(function (item) {
        return !item.parentId;
      }),
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "上级服务",
      "hint": "请选择该服务的上级服务",
      "prepend-inner-icon": "drive_folder_upload"
    },
    model: {
      value: _vm.editedItem.parentId,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "parentId", $$v);
      },
      expression: "editedItem.parentId"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "type": "text",
      "rules": [_vm.rules.required],
      "label": "名称",
      "hint": "请输入名称",
      "prepend-inner-icon": "post_add",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  }), !_vm.editedItem.parentId ? _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.serviceType,
      "rules": [_vm.rules.selected],
      "label": "服务类型",
      "hint": "请选择服务类型",
      "prepend-inner-icon": "category",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.service_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "service_type", $$v);
      },
      expression: "editedItem.service_type"
    }
  }) : _vm._e(), _vm.editedItem.parentId ? _c('v-autocomplete', {
    attrs: {
      "loading": _vm.accountLoading,
      "items": _vm.accountList,
      "search-input": _vm.searchAccount,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "hide-no-data": "",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "负责人",
      "hint": "请查找并选择服务负责人",
      "prepend-inner-icon": "how_to_reg"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      }
    },
    model: {
      value: _vm.editedItem.managers,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "managers", $$v);
      },
      expression: "editedItem.managers"
    }
  }) : _vm._e(), _c('v-autocomplete', {
    attrs: {
      "items": _vm.materialList,
      "item-text": "title",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "关联素材",
      "hint": "请选择该服务关联的素材详细介绍",
      "prepend-inner-icon": "layers"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.title)
          }
        }), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.word_count) + "字 " + _vm._s(_vm._f("fromDate")(data.item.updateTime)))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.material,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "material", $$v);
      },
      expression: "editedItem.material"
    }
  }), _vm.editedItem.parentId ? _c('v-switch', {
    staticClass: "mt-n2",
    attrs: {
      "inset": "",
      "label": "\u524D\u53F0\u9690\u85CF\u8BE5\u670D\u52A1: ".concat(_vm.editedItem.isHidden ? '是' : '否')
    },
    model: {
      value: _vm.editedItem.isHidden,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "isHidden", $$v);
      },
      expression: "editedItem.isHidden"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.editedItem.parentId ? _c('v-textarea', {
    attrs: {
      "label": "备注",
      "hint": "请输入服务备注信息",
      "prepend-inner-icon": "comment_bank",
      "rows": "2",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "remarks", $$v);
      },
      expression: "editedItem.remarks"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm.editedItem._id ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEdit();
      }
    }
  }, [_vm._v("取消")]) : _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEdit();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1) : _c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "outlined": "",
      "max-width": "400"
    }
  }, [_c('v-card-title', [_vm.activeItem.parentId ? _c('v-icon', {
    attrs: {
      "large": "",
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.activeItem.profileIcon ? _vm.activeItem.profileIcon : 'volunteer_activism') + " ")]) : _vm._e(), _c('span', {
    staticClass: "title font-weight-light"
  }, [_vm._v(_vm._s(_vm.activeItem.name))])], 1), _vm.activeItem.parentId ? _c('v-card-text', {
    staticClass: " font-weight-bold"
  }, [_c('pre', [_vm._v(_vm._s(_vm.activeItem.remarks || '无概述信息'))])]) : _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.activeItem.children, function (item, index) {
    return _c('v-list-item', {
      key: item._id,
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.changeCatalogList(item, index);
        }
      }
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v(_vm._s(item.profileIcon ? item.profileIcon : 'volunteer_activism'))])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.name) + " "), _c('v-icon', {
      staticClass: "ml-2 mt-n1",
      attrs: {
        "small": ""
      }
    }, [_vm._v(_vm._s(item.isHidden ? 'visibility_off' : 'visibility'))])], 1)], 1), _c('v-list-item-icon', [_c('v-icon', [_vm._v("keyboard_arrow_right")])], 1)], 1);
  }), 1), _vm.activeItem.parentId ? _c('v-card-actions', [_c('v-list-item', {
    staticClass: "grow"
  }, [_c('v-list-item-content', [_vm.activeItem.managers ? _c('v-list-item-title', [_c('widgets-employee-dialogs', {
    attrs: {
      "account": _vm.activeItem.managers._id,
      "avatar": _vm.activeItem.managers.personal.profile
    }
  }, [_vm._v(_vm._s(_vm.activeItem.managers.personal.name || '*未设置姓名'))])], 1) : _c('v-list-item-title', [_vm._v("未指定负责人")])], 1), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "end"
    }
  }, [_vm.activeItem.parentId ? _c('v-icon', {
    staticClass: "mr-1"
  }, [_vm._v(_vm._s(_vm.activeItem.isHidden ? 'visibility_off' : 'visibility'))]) : _vm._e(), _vm.activeItem.parentId ? _c('span', {
    staticClass: "subheading mr-2"
  }, [_vm._v(_vm._s(_vm.activeItem.isHidden ? '前台隐藏' : '前台可见'))]) : _vm._e()], 1)], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [!_vm.activeItem.parentId ? _c('v-btn', {
    staticClass: "mt-2 ma-2",
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('create_catalog', 'service');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("增加服务 ")], 1) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEdit();
      }
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('delete_catalog', _vm.activeItem);
      }
    }
  }, [_vm._v("删除")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('edit_catalog', _vm.activeItem);
      }
    }
  }, [_vm._v("编辑")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.iconDialog,
      callback: function callback($$v) {
        _vm.iconDialog = $$v;
      },
      expression: "iconDialog"
    }
  }, [_c('widgets-icon-card', {
    on: {
      "Select": function Select($event) {
        return _vm.doAction('select_icon', arguments);
      },
      "Close": function Close($event) {
        _vm.iconDialog = false;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }