var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.enterprise.name) + " - 咨询工单")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": function click($event) {
        return _vm.submit('');
      }
    }
  }, [_vm._v("创建")])], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.groupList,
      "rules": [_vm.rules.selected],
      "label": "支持分类",
      "hint": "请选择一个支持分类",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.setCatalogList(_vm.editedItem.group);
      }
    },
    model: {
      value: _vm.editedItem.group,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "group", $$v);
      },
      expression: "editedItem.group"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.catalogList,
      "label": "支持目录",
      "hint": "请选择一个支持目录",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.catalog,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "catalog", $$v);
      },
      expression: "editedItem.catalog"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "标题",
      "hint": "请输入咨询的主题",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "subject", $$v);
      },
      expression: "editedItem.subject"
    }
  }), _c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "label": "详细内容",
      "hint": "请输入咨询的详细内容"
    },
    model: {
      value: _vm.editedItem.context,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "context", $$v);
      },
      expression: "editedItem.context"
    }
  }), _c('case-create-upload-files', {
    ref: "upload_files",
    attrs: {
      "enterprise": _vm.enterprise._id
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": function click($event) {
        return _vm.submit('');
      }
    }
  }, [_vm._v("提交")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "500px"
    },
    model: {
      value: _vm.dlgSubmit,
      callback: function callback($$v) {
        _vm.dlgSubmit = $$v;
      },
      expression: "dlgSubmit"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" 确认提交新的咨询请求 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.dlgSubmit = false;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 咨询请求提交后将通知经销商进行受理。 ")])], 1), _vm.createResult.length ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.createResult.length) + " 个咨询请求；咨询请求编号为： ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "grow"
  }, _vm._l(_vm.createResult, function (code) {
    return _c('p', {
      key: code,
      staticClass: "font-weight-black"
    }, [_vm._v(_vm._s(code))]);
  }), 0)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-divider'), !_vm.createResult.length ? _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dlgSubmit = false;
      }
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "loading": !_vm.submitEnd
    },
    on: {
      "click": function click($event) {
        return _vm.submit('all');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("note_add")]), _vm._v("提交")], 1)], 1) : _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v("关闭")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }