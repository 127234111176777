<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>操作日志</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="doAction('search_logs')"
          dark
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索操作对象ID"
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
      </v-toolbar>
      <v-alert dense type="info" class="ma-2" dismissible>
        操作日志为系统用户登录系统后所有的增加、删除、修改操作，不包括浏览和查询操作。
      </v-alert>
      <v-data-table
        :headers="headerLogs"
        :items="logsList"
        :options.sync="logs_options"
        :server-items-length="logsCount"
        :loading="logsLoading"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10,15, 30, 50]}"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        show-expand
        item-key="_id"
        transition
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title><v-icon class="mr-2">schedule</v-icon>操作记录</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" outlined fab x-small @click.stop="doAction('filter_logs')">
              <v-icon>filter_list</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.operator="{ item }">
          <widgets-employee-dialogs :account="item.operator._id" v-on:UpdateEmployee="getDataList(query)">{{item.operator.personal.name ? item.operator.personal.name : '*未设置姓名'}}</widgets-employee-dialogs>
        </template>
        <template v-slot:item.createTime="{ item }">
          {{item.createTime | fromDate}}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <ul v-if="item.record.length">
              <li v-for="(item,index) in diffRecord(item.record[0].before, item.record[0].after)" :key="index">{{item}}</li>
            </ul>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-navigation-drawer v-model="filterDrawer" app temporary bottom right style="z-index: 999;">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">动作类型</h2>
        <v-chip-group v-model="query.action" column multiple>
          <v-chip v-for="action in actionList" :key="action.code" :value="action.code" filter label outlined>{{action.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">对象类型</h2>
        <v-chip-group v-model="query.objType" column multiple>
          <v-chip v-for="objType in objTypeList" :key="objType.code" :value="objType.code" filter label outlined>{{objType.name}}</v-chip>
        </v-chip-group>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doAction('clear_filter')">清空</v-btn>
          <v-btn color="secondary" text @click="doAction('set_filter')">确认</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import Util from '@/common/util';
import { mapGetters } from "vuex";
import store from "@/store";
import {
  FETCH_LOGS_LIST,
} from "@/store/actions.type";
export default {
  data() {
    return {
      filterDrawer: null,
      expanded: [],
      singleExpand: false,
      query: {
        key: '',
        action: [],
        objType: [],
        start_time: '',
        end_time: '',
      },
      defaultQuery: {
        key: '',
        action: [],
        objType: [],
        start_time: '',
        end_time: '',
      },
      headerLogs: [
        { text: '操作人', value: 'operator' },
        { text: '操作类型', value: 'action' },
        { text: '操作对象', value: 'objType' },
        { text: '操作时间', value: 'createTime' },
        { text: '对象编号', value: 'objId' },
        { text: '', value: 'data-table-expand' },
      ],
      logs_options: {},
    };
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "logsLoading",
      "logsList",
      "logsCount"]),
    actionList () {
      return Util.categories('action')
    },
    objTypeList () {
      return Util.categories('objType')
    }
  },
  watch: {
    logs_options: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.push({path: '/dealer/system'});
    },
    getDataList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.logs_options;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (query.key) urlQuery += '&key='+ query.key
      if (query.start_time) urlQuery += '&start_time='+ query.start_time
      if (query.end_time) urlQuery += '&end_time='+ query.end_time
      if (this.query.action.length) urlQuery += '&action='+ this.query.action
      if (this.query.objType.length) urlQuery += '&objType='+ this.query.objType
      if (Object.keys(this.logs_options).length > 0) {
        store.dispatch(FETCH_LOGS_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    doAction(action, item = {}, customitem) {
      console.log(item)
      switch (action) {
        case 'search_logs': {
          if (this.query.key !== '') {
            this.getDataList({key: this.query.key})
          } else {
            this.getDataList()
          }
          break
        }
        case 'filter_logs': {
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'set_filter': {
          this.getDataList(this.query)
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'clear_filter': {
          this.query = Object.assign({}, this.defaultQuery)
          this.getDataList()
          this.filterDrawer = !this.filterDrawer
          break
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
    diffRecord (obj1, obj2) {
      let diffKeys = []
      if (obj1 && obj2) {
        diffKeys = Object.keys(obj1).filter(k => obj1[k] !== obj2[k])
      }
      let diffObj = []
      diffKeys.forEach(key => {
        diffObj.push({
          [key+'原值']: obj1[key],
          '修改为': obj2[key],
        })
      })
      return diffObj
      // if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
      //   return obj1;
      // }
      //
      // let diffs = {}, key = '';
      //
      // let arraysMatch = function (arr1, arr2) {
      //   if (arr1.length !== arr2.length) return false;
      //   for (let i = 0; i < arr1.length; i++) {
      //     if (arr1[i] !== arr2[i]) return false;
      //   }
      //   return true;
      // };
      //
      // let compare = function (item1, item2, key) {
      //   let type1 = Object.prototype.toString.call(item1);
      //   let type2 = Object.prototype.toString.call(item2);
      //
      //   if (type2 === '[object Undefined]') {
      //     diffs[key] = null;
      //     return;
      //   }
      //   if (type1 !== type2) {
      //     diffs[key] = item2;
      //     return;
      //   }
      //   if (type1 === '[object Object]') {
      //     let objDiff = this.diffRecord(item1, item2);
      //     if (Object.keys(objDiff).length > 0) {
      //       diffs[key] = objDiff;
      //     }
      //     return;
      //   }
      //   if (type1 === '[object Array]') {
      //     if (!arraysMatch(item1, item2)) {
      //       diffs[key] = item2;
      //     }
      //     return;
      //   }
      //   if (type1 === '[object Function]') {
      //     if (item1.toString() !== item2.toString()) {
      //       diffs[key] = item2;
      //     }
      //   } else {
      //     if (item1 !== item2 ) {
      //       diffs[key] = item2;
      //     }
      //   }
      // }
      // for (key in obj1) {
      //   if (obj1.hasOwnProperty(key)) {
      //     compare(obj1[key], obj2[key], key);
      //   }
      // }
      // for (key in obj2) {
      //   if (obj2.hasOwnProperty(key)) {
      //     if (!obj1[key] && obj1[key] !== obj2[key] ) {
      //       diffs[key] = obj2[key];
      //     }
      //   }
      // }
      // return diffs;
    },
  },
  directives: {}
};
</script>
