var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "secondary",
      "dark": "",
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v("企业报表")]), _c('v-spacer'), _c('v-autocomplete', {
    staticClass: "mx-4",
    attrs: {
      "autocomplete": "off",
      "items": _vm.monthlyEnterpriseList,
      "label": "企业",
      "hint": "请选择一家企业",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "flat": "",
      "dense": "",
      "dark": "",
      "hide-details": "",
      "clearable": "",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('search_enterprise');
      }
    },
    model: {
      value: _vm.query.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "enterprise", $$v);
      },
      expression: "query.enterprise"
    }
  })], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 企业报表是为企业提供月度统计信息的报告。只有经过经销商人员编辑后且发布的报告才会在企业端呈现。 "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("月度报告每个月第一天0点系统自动生成")])]), _c('v-data-table', {
    attrs: {
      "headers": _vm.listHeader,
      "items": _vm.monthlyList,
      "options": _vm.list_options,
      "server-items-length": _vm.monthlyCount,
      "loading": _vm.monthlyLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "item-key": "_id",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.list_options = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_vm.monthlyEnterpriseList.length ? _c('v-toolbar-title', [_c('v-icon', {
          staticClass: "mr-2"
        }, [_vm._v("analytics")]), _vm._v(_vm._s(_vm.query.enterprise ? _vm.monthlyEnterpriseList.find(function (item) {
          return item._id === _vm.query.enterprise;
        }).name : '全部企业报告'))], 1) : _vm._e(), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "item.year",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "25px"
          }
        }, [_c('widgets-monthly-report', {
          attrs: {
            "getId": item._id
          },
          on: {
            "Close": _vm.closeItem
          }
        }, [_vm._v(" " + _vm._s(item.month ? item.year : item.year - 1) + " ")])], 1)];
      }
    }, {
      key: "item.month",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "25px"
          }
        }, [_c('widgets-monthly-report', {
          attrs: {
            "getId": item._id
          },
          on: {
            "Close": _vm.closeItem
          }
        }, [_vm._v(" " + _vm._s(item.month ? item.month : 12) + " ")])], 1)];
      }
    }, {
      key: "item.enterprise",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.enterprise ? _c('td', {
          staticClass: "v-list-item--link",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('open_enterprise', item.enterprise);
            }
          }
        }, [_vm._v(_vm._s(item.enterprise.name))]) : _vm._e()];
      }
    }, {
      key: "item.service",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.service === 'service' ? '设备管理' : '设备维修'))])];
      }
    }, {
      key: "item.isRelease",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.isRelease ? '是' : '否'))])];
      }
    }, {
      key: "item.isRead",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.isRead ? '是' : '否'))])];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true",
            "width": "50px"
          }
        }, [_c('widgets-monthly-report', {
          attrs: {
            "getId": item._id
          },
          on: {
            "Close": _vm.closeItem
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("edit")])], 1)], 1)];
      }
    }])
  })], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'enterprise' ? _c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.doAction('close_info');
      }
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }