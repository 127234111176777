var render = function () {
  var _vm$editItemData$ent_;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "secondary",
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.title_page()))]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-img', {
    staticClass: "white--text align-end text-right",
    attrs: {
      "src": _vm.editItemData.profile_image ? _vm.ossURL + '/' + _vm.editItemData.profile_image : '/static/error/empty_state.svg',
      "dark": "",
      "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
      "height": "375px",
      "aspect-ratio": 24 / 9
    }
  }, [_c('v-card-title', {
    staticClass: "text-center justify-center"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.editItemData.logo_image ? _vm.ossURL + '/' + _vm.editItemData.logo_image : '/static/error/empty_state.svg',
      "contain": "",
      "max-width": "200",
      "max-height": "155"
    }
  })], 1), _c('v-card-title', {
    staticClass: "text-center justify-center"
  }, [_vm._v(" " + _vm._s(_vm.editItemData.sub_title) + " ")]), _c('v-card-title', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('upload_logo', _vm.editItemData);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add_photo_alternate")]), _vm._v("上传LOGO")], 1), _c('v-divider', {
    attrs: {
      "vertical": "",
      "inset": ""
    }
  }), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('upload_profile', _vm.editItemData);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add_photo_alternate")]), _vm._v("上传背景图")], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "5"
    }
  }, [_vm.editItemData ? _c('v-expansion-panels', [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var open = _ref.open;
        return [_c('v-row', {
          attrs: {
            "no-gutters": ""
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("管理人员")]), _c('v-col', {
          attrs: {
            "cols": "8"
          }
        }, [_c('v-fade-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [open ? _c('span', {
          key: "0"
        }, [_vm._v("单击右侧按钮移出管理权限")]) : _c('span', {
          key: "1"
        }, [_vm._v(_vm._s(_vm.adminList.length) + "人")])])], 1)], 1)];
      }
    }], null, false, 2669804546)
  }), _c('v-expansion-panel-content', [_c('v-list', {
    attrs: {
      "dense": "",
      "two-line": ""
    }
  }, [_vm._l(_vm.adminList, function (item, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("assignment_ind")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.personal.name) + " - " + _vm._s(item.username))]), _c('v-list-item-subtitle', [_vm._v("状态：" + _vm._s(item.isBlock ? '停用' : item.status === 1 ? '正常' : '停用') + " | 手机号码：" + _vm._s(item.phone))])], 1), _c('v-list-item-icon', [_c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.editItem('remove_admin', _vm.editItemData, item);
        }
      }
    }, [_vm._v("remove_circle")])], 1)], 1);
  }), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('add_admin', _vm.editItemData, '创建管理员');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("创建管理人员 ")], 1)], 1)], 1)], 1)], 2)], 1)], 1)], 1) : _vm._e(), _vm.editItemData ? _c('v-list', {
    attrs: {
      "subheader": "",
      "dense": "",
      "two-line": ""
    }
  }, [_c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('to_employer', _vm.editItemData, '查看员工');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("groups")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("员工总数：" + _vm._s(_vm.editItemData.employee_count))]), _c('v-list-item-subtitle', [_vm._v("查看所有员工")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("launch")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('to_asset', _vm.editItemData, '查看设备');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("devices")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("设备总数：" + _vm._s(_vm.editItemData.asset_count))]), _c('v-list-item-subtitle', [_vm._v("查看所有设备")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("launch")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.editItemData ? _c('v-expansion-panels', [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_vm.editItemData.inventory_warning && _vm.editItemData.inventory_warning.find(function (item) {
          return item.minimum >= item.count;
        }) ? _c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v("error")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 3411737043)
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("库存预警")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  })], 1)], 1)], 1), _c('v-expansion-panel-content', [_c('v-list', {
    attrs: {
      "subheader": "",
      "dense": "",
      "two-line": ""
    }
  }, [_vm._l(_vm.editItemData.inventory_warning, function (item, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-icon', [item.minimum < item.count ? _c('v-icon', [_vm._v("check_circle")]) : _c('v-icon', {
      attrs: {
        "color": "error"
      }
    }, [_vm._v("error")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.product.name))]), _c('v-list-item-subtitle', [_c('span', {
      class: item.minimum >= item.count ? 'error--text' : ''
    }, [_vm._v("库存：" + _vm._s(item.count))]), _vm._v(" - 预警：" + _vm._s(item.minimum) + " ")])], 1), _c('v-list-item-icon', [_c('v-icon', {
      staticClass: "mx-2",
      on: {
        "click": function click($event) {
          return _vm.editItem('edit_warning', _vm.editItemData, item);
        }
      }
    }, [_vm._v("create")]), _c('v-icon', {
      on: {
        "click": function click($event) {
          return _vm.editItem('remove_warning', _vm.editItemData, item);
        }
      }
    }, [_vm._v("remove_circle")])], 1)], 1);
  }), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('add_inventory', _vm.editItemData, '创建库存预警');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add")]), _vm._v("增加预警配置 ")], 1)], 1)], 1)], 1)], 2)], 1)], 1)], 1) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "7"
    }
  }, [_vm.editItemData ? _c('v-expansion-panels', [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("企业信息")]), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.editItemData.name))])])], 1)], 1)], 1), _c('v-expansion-panel-content', [_c('v-list', {
    attrs: {
      "subheader": "",
      "dense": "",
      "two-line": ""
    }
  }, [_c('v-subheader', [_vm._v("企业信息")]), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('enterprise_name', _vm.editItemData, '企业名称');
      }
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.name))]), _vm.editItemData.creater ? _c('v-list-item-subtitle', [_vm._v("状态：" + _vm._s(_vm.editItemData.isBlock ? '暂停运营' : _vm.editItemData.status === 1 ? '正常' : '待审批') + " | "), _c('widgets-employee-dialogs', {
    attrs: {
      "account": _vm.editItemData.creater._id
    }
  }, [_vm._v(_vm._s(_vm.editItemData.creater.personal.name ? _vm.editItemData.creater.personal.name : '*未设置姓名'))]), _vm._v("创建于 " + _vm._s(_vm._f("formatTime")(_vm.editItemData.createTime)))], 1) : _vm._e()], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("pin")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    attrs: {
      "id": "copyId"
    }
  }, [_vm._v(_vm._s(_vm.editItemData._id))]), _c('v-list-item-subtitle', [_vm._v("企业编码是企业在ESP系统唯一标识，不能修改。")])], 1), _c('v-list-item-action', [_c('widgets-copy-btn', {
    attrs: {
      "target": _vm.copyID
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("vpn_key")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    attrs: {
      "id": "copyTarget"
    }
  }, [_vm._v(_vm._s(_vm.editItemData.invite_code))]), _c('v-list-item-subtitle', [_vm._v("企业邀请码作为企业员工注册时填写依据，不能修改。")])], 1), _c('v-list-item-action', [_c('widgets-copy-btn', {
    attrs: {
      "target": _vm.copyCode
    }
  })], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('ent_group', _vm.editItemData, '隶属集团');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("corporate_fare")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("隶属集团")]), _c('v-list-item-subtitle', [_vm._v(_vm._s((_vm$editItemData$ent_ = _vm.editItemData.ent_group) === null || _vm$editItemData$ent_ === void 0 ? void 0 : _vm$editItemData$ent_.name))])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('corp_type', _vm.editItemData, '企业类型');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("category")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.corp_type && _vm.getTypeText(_vm.editItemData.corp_type).name) + " ")]), _c('v-list-item-subtitle', [_vm._v("信用代码：" + _vm._s(_vm.editItemData.license_key))])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('industry', _vm.editItemData, '所属行业');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("engineering")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.industry && _vm.editItemData.industry.name) + " ")]), _c('v-list-item-subtitle', [_vm._v("企业所属行业")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('name_code', _vm.editItemData, '企业简称');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("closed_caption")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.name_code))]), _c('v-list-item-subtitle', [_vm._v("企业代码，用于第三方系统如ERP、CRM、S1等客户标识。")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('sub_title', _vm.editItemData, '企业理念');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("flag")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.sub_title))]), _c('v-list-item-subtitle', [_vm._v("企业理念：企业宣传口号，文化理念")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('contact_name', _vm.editItemData, '联系方式');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("contact_phone")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("联系人：" + _vm._s(_vm.editItemData.contact_name))]), _c('v-list-item-subtitle', [_vm._v("联系电话：" + _vm._s(_vm.editItemData.contact_phone) + " 公开电话：" + _vm._s(_vm.editItemData.public_phone))])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('address', _vm.editItemData, '企业地址');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("room")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.province) + " - " + _vm._s(_vm.editItemData.city))]), _c('v-list-item-subtitle', [_vm._v("详细地址：" + _vm._s(_vm.editItemData.address))])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('branch', _vm.editItemData, '下属企业');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("account_tree")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.editItemData.branch))]), _c('v-list-item-subtitle', [_vm._v("下属企业，便于企业区分不同分支机构的设备和流程请求")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('notice_email', _vm.editItemData, '通知方式');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("notifications")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("接收通知：" + _vm._s(_vm.editItemData.notice_email) + " - " + _vm._s(_vm.editItemData.notice_phone))]), _c('v-list-item-subtitle', [_vm._v("企业端接收服务状态变更通知的终端，清空不再接收通知")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('account_managers', _vm.editItemData, '客户经理');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("engineering")])], 1), _c('v-list-item-content', [_vm.editItemData.account_managers && _vm.editItemData.account_managers.length ? _c('v-list-item-title', _vm._l(_vm.editItemData.account_managers, function (managers) {
    return _c('widgets-employee-dialogs', {
      key: managers._id,
      attrs: {
        "account": managers._id,
        "divider": "- "
      }
    }, [_vm._v(" " + _vm._s(managers.personal.name ? managers.personal.name : '*未设置姓名') + " ")]);
  }), 1) : _c('v-list-item-title', [_vm._v(" 无 ")]), _c('v-list-item-subtitle', [_vm._v("客户负责人。可以维护和修改客户资料")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('account_order', _vm.editItemData, '订单处理人');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("manage_accounts")])], 1), _c('v-list-item-content', [_vm.editItemData.account_order ? _c('v-list-item-title', [_c('widgets-employee-dialogs', {
    attrs: {
      "account": _vm.editItemData.account_order._id
    }
  }, [_vm._v(" " + _vm._s(_vm.editItemData.account_order.personal.name ? _vm.editItemData.account_order.personal.name : '*未设置姓名') + " ")])], 1) : _c('v-list-item-title', [_vm._v(" 无 ")]), _c('v-list-item-subtitle', [_vm._v("订单负责人。可以查看并处理客户请求的采购或租赁订单。")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('account_sales', _vm.editItemData, '订单销售人');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("hail")])], 1), _c('v-list-item-content', [_vm.editItemData.account_sales ? _c('v-list-item-title', [_c('widgets-employee-dialogs', {
    attrs: {
      "account": _vm.editItemData.account_sales._id
    }
  }, [_vm._v(" " + _vm._s(_vm.editItemData.account_sales.personal.name ? _vm.editItemData.account_sales.personal.name : '*未设置姓名') + " ")])], 1) : _c('v-list-item-title', [_vm._v(" 无 ")]), _c('v-list-item-subtitle', [_vm._v("订单销售人。可以查看客户请求的采购或租赁订单。")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('additional', _vm.editItemData, '附加信息');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("bookmark_add")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("ABM：" + _vm._s(_vm.editItemData.additional ? _vm.editItemData.additional.abm : '-') + " | ACE: " + _vm._s(_vm.editItemData.additional ? _vm.editItemData.additional.ace : '-') + " | ESC Apple ID: " + _vm._s(_vm.editItemData.additional ? _vm.editItemData.additional.esc : '-') + " ")]), _c('v-list-item-subtitle', [_vm._v("企业在日常服务中会用到的一些附加信息")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-divider'), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('notice_email', _vm.editItemData, '服务限制');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("vertical_align_top")])], 1), _c('v-list-item-content', [_vm.editItemData.limit ? _c('v-list-item-title', [_vm._v("最大员工数：" + _vm._s(_vm.editItemData.limit.employees) + " - 最大设备数量：" + _vm._s(_vm.editItemData.limit.assets))]) : _vm._e(), _c('v-list-item-subtitle', [_vm._v("权限调整后仅对新业务产生影响，已经录入的数据和业务不受影响。")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("how_to_reg")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("员工注册：" + _vm._s(_vm.editItemData.self_register ? '允许' : '禁止'))]), _c('v-list-item-subtitle', [_vm._v("企业员工可以通过邀请码在ESP平台进行自助注册。")])], 1), _c('v-list-item-action', [_c('v-switch', {
    on: {
      "change": function change($event) {
        return _vm.editItem('self_register', _vm.editItemData);
      }
    },
    model: {
      value: _vm.editItemData.self_register,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "self_register", $$v);
      },
      expression: "editItemData.self_register"
    }
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("devices")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("设备管理：" + _vm._s(_vm.editItemData.self_asset ? '允许' : '禁止'))]), _c('v-list-item-subtitle', [_vm._v("企业具备资产管理权限，可自行修改设备信息。")])], 1), _c('v-list-item-action', [_c('v-switch', {
    on: {
      "change": function change($event) {
        return _vm.editItem('self_asset', _vm.editItemData);
      }
    },
    model: {
      value: _vm.editItemData.self_asset,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "self_asset", $$v);
      },
      expression: "editItemData.self_asset"
    }
  })], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('mail_suffix', _vm.editItemData, '邮箱后缀');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("email")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("邮箱后缀："), _vm._l(_vm.editItemData.mail_suffix, function (label, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "mx-2",
      attrs: {
        "label": "",
        "small": ""
      }
    }, [_vm._v(_vm._s(label))]);
  })], 2), _c('v-list-item-subtitle', [_vm._v("提交维修服务时需要通过此后缀的邮箱进行验证码验证。")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('receipt_info', _vm.editItemData, '发票抬头');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("receipt_long")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("发票抬头：")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.editItemData.invoice_info ? _vm.editItemData.invoice_info.title + ' ' + _vm.editItemData.invoice_info.license_code : '无'))])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-divider'), _c('v-list-item', {
    on: {
      "click": function click($event) {
        return _vm.editItem('api_callback', _vm.editItemData, '集成接口');
      }
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("api")])], 1), _c('v-list-item-content', [_vm.editItemData.api ? _c('v-list-item-title') : _vm._e(), _c('v-list-item-subtitle', [_vm._v("与第三方系统进行集成配置接口及接口回调信息。")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', [_vm._v("edit")])], 1)], 1)], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("hub")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v("对接公贝：" + _vm._s(_vm.editItemData.allow_gongbei ? '允许' : '禁止'))]), _c('v-list-item-subtitle', [_vm._v("允许该企业在公贝系统创建和查阅ESP工单。")])], 1), _c('v-list-item-action', [_c('v-switch', {
    on: {
      "change": function change($event) {
        return _vm.editItem('allow_gongbei', _vm.editItemData);
      }
    },
    model: {
      value: _vm.editItemData.allow_gongbei,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "allow_gongbei", $$v);
      },
      expression: "editItemData.allow_gongbei"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.service.headers,
      "items": _vm.serviceList,
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "sort-by": "end_time",
      "sort-desc": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_vm._v("服务协议")]), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer'), _c('v-btn', {
          staticClass: "mb-2",
          attrs: {
            "color": "secondary",
            "depressed": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem('create_service', _vm.editItemData);
            }
          }
        }, [_vm._v("新增服务")])], 1)];
      },
      proxy: true
    }, {
      key: "item.actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem('edit_service', _vm.editItemData, item);
            }
          }
        }, [_vm._v("edit")]), _c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem('delete_service', _vm.editItemData, item);
            }
          }
        }, [_vm._v("delete")])];
      }
    }, {
      key: "item.catalog",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.catalog.map(function (i) {
          return i.name;
        })) + " ")];
      }
    }, {
      key: "item.branch",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.branch.map(function (i) {
          return i.name;
        })) + " ")];
      }
    }, {
      key: "item.start_time",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.start_time)) + " ")];
      }
    }, {
      key: "item.end_time",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm.diffDays(item.end_time) > 0 ? _c('span', {
          staticClass: "text-decoration-line-through"
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.end_time)) + " - "), _c('span', {
          staticClass: "overline"
        }, [_vm._v(_vm._s(_vm._f("fromDate")(item.end_time)))])]) : _vm.diffDays(item.end_time) > -24 ? _c('span', {
          staticClass: "red--text"
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.end_time)) + " - "), _c('span', {
          staticClass: "overline"
        }, [_vm._v(_vm._s(_vm._f("fromDate")(item.end_time)))])]) : _vm.diffDays(item.end_time) > -168 ? _c('span', {
          staticClass: "orange--text"
        }, [_vm._v(_vm._s(_vm._f("formatDate")(item.end_time)) + " - "), _c('span', {
          staticClass: "overline"
        }, [_vm._v(_vm._s(_vm._f("fromDate")(item.end_time)))])]) : _c('span', [_vm._v(_vm._s(_vm._f("formatDate")(item.end_time)) + " - "), _c('span', {
          staticClass: "overline"
        }, [_vm._v(_vm._s(_vm._f("fromDate")(item.end_time)))])])];
      }
    }])
  })], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": _vm.dlgTitle === '新增年度财报' || _vm.dlgTitle === '修改年度财报',
      "max-width": _vm.dlgTitle === '新增年度财报' || _vm.dlgTitle === '修改年度财报' ? '750px' : '600px'
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-card-text', [_vm.dlgTitle === '附加信息' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "ABM",
      "hint": "请输入企业的ABM信息",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.additional.abm,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.additional, "abm", $$v);
      },
      expression: "editItemData.additional.abm"
    }
  }) : _vm._e(), _vm.dlgTitle === '附加信息' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "ACE",
      "hint": "请输入企业的ACE信息",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.additional.ace,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.additional, "ace", $$v);
      },
      expression: "editItemData.additional.ace"
    }
  }) : _vm._e(), _vm.dlgTitle === '附加信息' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Apple ID",
      "hint": "请输入企业的ESC Apple ID",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.additional.esc,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.additional, "esc", $$v);
      },
      expression: "editItemData.additional.esc"
    }
  }) : _vm._e(), _vm.dlgTitle === '邮箱后缀' ? _c('v-combobox', {
    attrs: {
      "label": "邮箱后缀",
      "hint": "请输入邮箱@后域名，不包括@符号，如 hi@mdaas.com，输入mdaas.com 使用回车键可以输入多个地址。",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.mail_suffix,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "mail_suffix", $$v);
      },
      expression: "editItemData.mail_suffix"
    }
  }) : _vm._e(), _vm.dlgTitle === '企业名称' || _vm.dlgTitle === '创建新企业' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "企业名称",
      "hint": "请输入企业的名称，该名称为全局唯一命名",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "name", $$v);
      },
      expression: "editItemData.name"
    }
  }) : _vm._e(), _vm.dlgTitle === '隶属集团' || _vm.dlgTitle === '创建新企业' ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.entGroupList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "label": "隶属集团",
      "hint": "请选择隶属集团"
    },
    model: {
      value: _vm.editItemData.ent_group,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "ent_group", $$v);
      },
      expression: "editItemData.ent_group"
    }
  }) : _vm._e(), _vm.dlgTitle === '所属行业' ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.rentIndustryList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "行业列表",
      "hint": "请选择企业所属行业"
    },
    model: {
      value: _vm.editItemData.industry,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "industry", $$v);
      },
      expression: "editItemData.industry"
    }
  }) : _vm._e(), _vm.dlgTitle === '企业简称' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "企业简称",
      "hint": "请输入企业的简称，该简称来自S1系统。",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.name_code,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "name_code", $$v);
      },
      expression: "editItemData.name_code"
    }
  }) : _vm._e(), _vm.dlgTitle === '企业理念' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "企业理念",
      "hint": "请输入企业一句话介绍，如理念、愿景等信息。",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.sub_title,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "sub_title", $$v);
      },
      expression: "editItemData.sub_title"
    }
  }) : _vm._e(), _vm.dlgTitle === '企业类型' || _vm.dlgTitle === '创建新企业' ? _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.inputTypedef,
      "rules": [_vm.rules.selected],
      "label": "企业类型",
      "hint": "请选择企业类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.corp_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "corp_type", $$v);
      },
      expression: "editItemData.corp_type"
    }
  }) : _vm._e(), _vm.dlgTitle === '企业类型' || _vm.dlgTitle === '创建新企业' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "信用代码",
      "hint": "由工商统一颁发的营业执照号码",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.license_key,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "license_key", $$v);
      },
      expression: "editItemData.license_key"
    }
  }) : _vm._e(), _vm.dlgTitle === '联系方式' || _vm.dlgTitle === '创建新企业' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "企业联系人",
      "hint": "请输入企业的联系人，联系人不公开仅用于经销商与企业联系使用",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "contact_name", $$v);
      },
      expression: "editItemData.contact_name"
    }
  }) : _vm._e(), _vm.dlgTitle === '联系方式' || _vm.dlgTitle === '创建新企业' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "联系电话",
      "hint": "请输入企业的联系电话，联系电话不公开仅用于经销商与企业联系使用",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "contact_phone", $$v);
      },
      expression: "editItemData.contact_phone"
    }
  }) : _vm._e(), _vm.dlgTitle === '联系方式' || _vm.dlgTitle === '创建新企业' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "客服电话",
      "hint": "公开的联系电话，可为空。",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.public_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "public_phone", $$v);
      },
      expression: "editItemData.public_phone"
    }
  }) : _vm._e(), _vm.dlgTitle === '集成接口' ? _c('v-alert', {
    attrs: {
      "type": "success",
      "icon": "swap_horiz"
    }
  }, [_vm._v(" 回调地址：https://cb.mdaas.com/" + _vm._s(_vm.editItemData.invite_code) + " ")]) : _vm._e(), _vm.dlgTitle === '集成接口' ? _c('v-select', {
    attrs: {
      "items": _vm.system3RD,
      "item-text": "name",
      "item-value": "code",
      "label": "第三方系统",
      "hint": "请选择第三方系统",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.apiType,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "apiType", $$v);
      },
      expression: "editItemData.apiType"
    }
  }) : _vm._e(), _vm.dlgTitle === '集成接口' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "第三方系统ID",
      "hint": "通过此ID标识与安全码生成动态令牌",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.accountId,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "accountId", $$v);
      },
      expression: "editItemData.accountId"
    }
  }) : _vm._e(), _vm.dlgTitle === '集成接口' ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "第三方系统安全码",
      "hint": "通过此安全码与ID标识生成动态令牌",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.secureId,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "secureId", $$v);
      },
      expression: "editItemData.secureId"
    }
  }) : _vm._e(), _vm.dlgTitle === '企业地址' || _vm.dlgTitle === '创建新企业' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "error-messages": _vm.check.chkAddressMessage,
      "clearable": _vm.check.chkAddressMessage !== '',
      "type": "text",
      "label": "企业地址",
      "hint": "请输入完整的企业地址，地址必须按正规格式输入",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-fade-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.check.chkAddressLoading ? _c('v-progress-circular', {
          attrs: {
            "width": "2",
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 2214016406),
    model: {
      value: _vm.editItemData.address,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "address", $$v);
      },
      expression: "editItemData.address"
    }
  }) : _vm._e(), _vm.dlgTitle === '下属企业' ? _c('v-combobox', {
    attrs: {
      "label": "下属企业",
      "hint": "使用回车键可以输入多个企业",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.branch,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "branch", $$v);
      },
      expression: "editItemData.branch"
    }
  }) : _vm._e(), _vm.dlgTitle === '通知方式' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.email],
      "type": "text",
      "label": "电子邮件",
      "hint": "接收通知的电子邮件信箱",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.notice_email,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "notice_email", $$v);
      },
      expression: "editItemData.notice_email"
    }
  }) : _vm._e(), _vm.dlgTitle === '通知方式' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.phonenum],
      "type": "text",
      "label": "手机号码",
      "hint": "接收短信通知的手机号码",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.notice_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "notice_phone", $$v);
      },
      expression: "editItemData.notice_phone"
    }
  }) : _vm._e(), _vm.dlgTitle === '服务限制' ? _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "员工上限",
      "hint": "该企业最大允许员工数量",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.limit.employees,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.limit, "employees", $$v);
      },
      expression: "editItemData.limit.employees"
    }
  }) : _vm._e(), _vm.dlgTitle === '服务限制' ? _c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "设备上限",
      "hint": "该企业最大允许设备数量",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.limit.assets,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.limit, "assets", $$v);
      },
      expression: "editItemData.limit.assets"
    }
  }) : _vm._e(), _vm.dlgTitle === '调整授信额度' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "hint": _vm._f("formatMoneyUnitFloat")(_vm.editItemData.credit_line_adjust),
      "label": "授信额度",
      "type": "number",
      "persistent-hint": "",
      "outlined": "",
      "dense": "",
      "min": "0",
      "step": "0.01",
      "prefix": "¥",
      "suffix": "元"
    },
    model: {
      value: _vm.editItemData.credit_line_adjust,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "credit_line_adjust", $$v);
      },
      expression: "editItemData.credit_line_adjust"
    }
  }) : _vm._e(), _vm.dlgTitle === '客户经理' ? _c('v-autocomplete', {
    attrs: {
      "loading": _vm.accountLoading,
      "items": _vm.accountList,
      "search-input": _vm.searchAccount,
      "return-object": "",
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "label": "客户经理",
      "hint": "请查找并选择企业客户经理",
      "multiple": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchAccount = $event;
      }
    },
    model: {
      value: _vm.editItemData.account_managers,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "account_managers", $$v);
      },
      expression: "editItemData.account_managers"
    }
  }) : _vm._e(), _vm.dlgTitle === '订单处理人' ? _c('v-autocomplete', {
    attrs: {
      "loading": _vm.accountLoading,
      "items": _vm.accountList,
      "search-input": _vm.searchOrderAccount,
      "return-object": "",
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "订单处理人",
      "hint": "请查找并选择企业订单处理人"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchOrderAccount = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchOrderAccount = $event;
      }
    },
    model: {
      value: _vm.editItemData.account_order,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "account_order", $$v);
      },
      expression: "editItemData.account_order"
    }
  }) : _vm._e(), _vm.dlgTitle === '订单销售人' ? _c('v-autocomplete', {
    attrs: {
      "loading": _vm.accountLoading,
      "items": _vm.accountList,
      "search-input": _vm.searchSalesAccount,
      "return-object": "",
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "订单销售人",
      "hint": "请查找并选择企业销售人员"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchSalesAccount = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchSalesAccount = $event;
      }
    },
    model: {
      value: _vm.editItemData.account_sales,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "account_sales", $$v);
      },
      expression: "editItemData.account_sales"
    }
  }) : _vm._e(), _vm.dlgTitle === '创建管理员' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "error-messages": _vm.employee.editedItem.msgName,
      "clearable": _vm.employee.editedItem.msgName !== '',
      "type": "text",
      "label": "账号名称",
      "hint": "账号名称必须为电子邮件，将作为登陆名称使用",
      "outlined": "",
      "color": "secondary",
      "dense": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkName ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 3805399357),
    model: {
      value: _vm.employee.editedItem.username,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "username", $$v);
      },
      expression: "employee.editedItem.username"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "error-messages": _vm.employee.editedItem.msgPhone,
      "clearable": _vm.employee.editedItem.msgPhone !== '',
      "type": "number",
      "label": "手机号码",
      "hint": "手机号码可作为登陆名称使用",
      "outlined": "",
      "color": "secondary",
      "dense": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkPhone ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }], null, false, 1554159462),
    model: {
      value: _vm.employee.editedItem.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "phone", $$v);
      },
      expression: "employee.editedItem.phone"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": _vm.employee.editedItem.showPass ? 'text' : 'password',
      "append-icon": _vm.employee.editedItem.showPass ? 'visibility_off' : 'visibility',
      "label": "账号密码",
      "hint": "请牢记此密码，密码将作为登陆凭证",
      "outlined": "",
      "color": "secondary",
      "dense": "",
      "required": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.employee.editedItem.showPass = !_vm.employee.editedItem.showPass;
      }
    },
    model: {
      value: _vm.employee.editedItem.password,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "password", $$v);
      },
      expression: "employee.editedItem.password"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工姓名",
      "hint": "员工姓名为用户的真实姓名",
      "outlined": "",
      "color": "secondary",
      "dense": ""
    },
    model: {
      value: _vm.employee.editedItem.realname,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "realname", $$v);
      },
      expression: "employee.editedItem.realname"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "员工编号",
      "hint": "员工编号为企业内部的员工编号",
      "outlined": "",
      "color": "secondary",
      "dense": ""
    },
    model: {
      value: _vm.employee.editedItem.employeeNo,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "employeeNo", $$v);
      },
      expression: "employee.editedItem.employeeNo"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "error-messages": _vm.employee.editedItem.msgAddress,
      "clearable": _vm.employee.editedItem.msgAddress !== '',
      "type": "text",
      "label": "收件地址",
      "hint": "收件地址为接收设备的目的地",
      "outlined": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-fade-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.employee.editedItem.chkAddress ? _c('v-progress-circular', {
          attrs: {
            "width": "2",
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "append-outer",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editItem('copy_address', _vm.editItemData);
            }
          }
        }, [_vm._v("复制公司地址")])];
      },
      proxy: true
    }], null, false, 253989877),
    model: {
      value: _vm.employee.editedItem.address,
      callback: function callback($$v) {
        _vm.$set(_vm.employee.editedItem, "address", $$v);
      },
      expression: "employee.editedItem.address"
    }
  })], 1)], 1) : _vm._e(), _vm.dlgTitle === '创建库存预警' || _vm.dlgTitle === '编辑预警配置' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.vendorList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "产品供应商",
      "hint": "请选择产品供应商"
    },
    on: {
      "change": function change($event) {
        return _vm.editItem('set_product_list', _vm.editItemData);
      }
    },
    model: {
      value: _vm.inventory.editedItem.vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.inventory.editedItem, "vendor", $$v);
      },
      expression: "inventory.editedItem.vendor"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.typeList,
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "产品类型",
      "hint": "请选择产品类型"
    },
    on: {
      "change": function change($event) {
        return _vm.editItem('set_product_list', _vm.editItemData);
      }
    },
    model: {
      value: _vm.inventory.editedItem.productType,
      callback: function callback($$v) {
        _vm.$set(_vm.inventory.editedItem, "productType", $$v);
      },
      expression: "inventory.editedItem.productType"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    staticClass: "my-n4",
    attrs: {
      "items": _vm.productList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "关联产品",
      "hint": "请选择设备关联的产品"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name) + " - " + _vm._s(data.item.part_number))]), data.item.it_info ? _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.it_info.ram) + "G - " + _vm._s(data.item.it_info.network))]) : _vm._e()], 1)];
      }
    }], null, false, 3405991014),
    model: {
      value: _vm.inventory.editedItem.product,
      callback: function callback($$v) {
        _vm.$set(_vm.inventory.editedItem, "product", $$v);
      },
      expression: "inventory.editedItem.product"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "my-n4",
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "最低库存值",
      "hint": "请输入库存最低值",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.inventory.editedItem.minimum,
      callback: function callback($$v) {
        _vm.$set(_vm.inventory.editedItem, "minimum", $$v);
      },
      expression: "inventory.editedItem.minimum"
    }
  })], 1)], 1) : _vm._e(), _vm.dlgTitle === '新增服务协议' || _vm.dlgTitle === '修改服务协议' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.flatGroupCatalog(_vm.catalogList),
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "label": "请选择所含服务内容",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "multiple": ""
    },
    on: {
      "change": _vm.set_process_list
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_typeof(data.item) !== 'object' ? [_c('v-list-item-content', {
          domProps: {
            "textContent": _vm._s(data.item)
          }
        })] : [_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "input-value": data.attrs.inputValue
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        })], 1)]];
      }
    }], null, false, 3296285918),
    model: {
      value: _vm.service.editedItem.catalog,
      callback: function callback($$v) {
        _vm.$set(_vm.service.editedItem, "catalog", $$v);
      },
      expression: "service.editedItem.catalog"
    }
  })], 1), _vm._l(_vm.service.editedItem.process, function (element, index) {
    return _c('v-col', {
      key: index,
      staticClass: "mt-n4",
      attrs: {
        "cols": "6"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "items": _vm.flatGroupCatalog(_vm.catalogList).find(function (o) {
          return o._id === element.id;
        }).process,
        "rules": [_vm.rules.selected],
        "item-text": "name",
        "item-value": "_id",
        "autocomplete": "off",
        "outlined": "",
        "dense": "",
        "label": _vm.flatGroupCatalog(_vm.catalogList).find(function (o) {
          return o._id === element.id;
        }).name + '流程'
      },
      model: {
        value: element.value,
        callback: function callback($$v) {
          _vm.$set(element, "value", $$v);
        },
        expression: "element.value"
      }
    })], 1);
  }), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.branchList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "label": "服务提供分支机构",
      "hint": "请查找并选择提供服务的分支机构",
      "multiple": ""
    },
    model: {
      value: _vm.service.editedItem.branch,
      callback: function callback($$v) {
        _vm.$set(_vm.service.editedItem, "branch", $$v);
      },
      expression: "service.editedItem.branch"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref7) {
        var on = _ref7.on,
            attrs = _ref7.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "协议开始日期",
            "rules": [_vm.rules.required],
            "readonly": "",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.service.editedItem.start_time,
            callback: function callback($$v) {
              _vm.$set(_vm.service.editedItem, "start_time", $$v);
            },
            expression: "service.editedItem.start_time"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 971384272),
    model: {
      value: _vm.service.startMenu,
      callback: function callback($$v) {
        _vm.$set(_vm.service, "startMenu", $$v);
      },
      expression: "service.startMenu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.service.startMenu = false;
      }
    },
    model: {
      value: _vm.service.editedItem.start_time,
      callback: function callback($$v) {
        _vm.$set(_vm.service.editedItem, "start_time", $$v);
      },
      expression: "service.editedItem.start_time"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref8) {
        var on = _ref8.on,
            attrs = _ref8.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "rules": [_vm.rules.required],
            "label": "协议结束日期",
            "readonly": "",
            "outlined": "",
            "dense": ""
          },
          model: {
            value: _vm.service.editedItem.end_time,
            callback: function callback($$v) {
              _vm.$set(_vm.service.editedItem, "end_time", $$v);
            },
            expression: "service.editedItem.end_time"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 2223496408),
    model: {
      value: _vm.service.endMenu,
      callback: function callback($$v) {
        _vm.$set(_vm.service, "endMenu", $$v);
      },
      expression: "service.endMenu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.service.endMenu = false;
      }
    },
    model: {
      value: _vm.service.editedItem.end_time,
      callback: function callback($$v) {
        _vm.$set(_vm.service.editedItem, "end_time", $$v);
      },
      expression: "service.editedItem.end_time"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "12"
    }
  }, [_vm.service.editedItem.sign_file ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('dl_service_file', _vm.editItemData, _vm.service.editedItem);
      }
    }
  }, [_vm._v(_vm._s(_vm.service.editedItem.sign_file.substring(_vm.service.editedItem.sign_file.lastIndexOf("/") + 1)))]) : _vm._e(), _vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('input', {
    ref: "uploadFile",
    attrs: {
      "type": "file",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('upload_service_file', _vm.editItemData, _vm.service.editedItem);
      }
    }
  }, [_vm._v("上传服务协议")])], 1)], 2) : _vm._e(), _vm.dlgTitle === '新增年度财报' || _vm.dlgTitle === '修改年度财报' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "label": "年度",
      "outlined": "",
      "dense": "",
      "min": "2000",
      "max": "2100",
      "step": "1",
      "type": "number",
      "suffix": "年"
    },
    model: {
      value: _vm.earnings.editedItem.years,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "years", $$v);
      },
      expression: "earnings.editedItem.years"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "hint": parseFloat((_vm.earnings.editedItem.scale_credit_current_assets / 100).toFixed(4)).toString(),
      "rules": [_vm.rules.required],
      "label": "流动信用比率",
      "type": "number",
      "persistent-hint": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.earnings.editedItem.scale_credit_current_assets,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "scale_credit_current_assets", $$v);
      },
      expression: "earnings.editedItem.scale_credit_current_assets"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "hint": parseFloat((_vm.earnings.editedItem.scale_credit_equity_owner / 100).toFixed(4)).toString(),
      "rules": [_vm.rules.required],
      "label": "权益信用比率",
      "type": "number",
      "persistent-hint": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.earnings.editedItem.scale_credit_equity_owner,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "scale_credit_equity_owner", $$v);
      },
      expression: "earnings.editedItem.scale_credit_equity_owner"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "hint": _vm._f("formatMoneyUnitFloat")(_vm.earnings.editedItem.inventories),
      "label": "存货",
      "type": "number",
      "persistent-hint": "",
      "outlined": "",
      "dense": "",
      "min": "0",
      "step": "0.01",
      "prefix": "¥",
      "suffix": "元"
    },
    model: {
      value: _vm.earnings.editedItem.inventories,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "inventories", $$v);
      },
      expression: "earnings.editedItem.inventories"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "hint": _vm._f("formatMoneyUnitFloat")(_vm.earnings.editedItem.current_assets),
      "label": "流动资产",
      "type": "number",
      "persistent-hint": "",
      "outlined": "",
      "dense": "",
      "min": "0",
      "step": "0.01",
      "prefix": "¥",
      "suffix": "元"
    },
    model: {
      value: _vm.earnings.editedItem.current_assets,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "current_assets", $$v);
      },
      expression: "earnings.editedItem.current_assets"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "hint": _vm._f("formatMoneyUnitFloat")(_vm.earnings.editedItem.current_liabilities),
      "label": "流动负债",
      "type": "number",
      "persistent-hint": "",
      "outlined": "",
      "dense": "",
      "min": "0",
      "step": "0.01",
      "prefix": "¥",
      "suffix": "元"
    },
    model: {
      value: _vm.earnings.editedItem.current_liabilities,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "current_liabilities", $$v);
      },
      expression: "earnings.editedItem.current_liabilities"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "hint": _vm._f("formatMoneyUnitFloat")(_vm.earnings.editedItem.total_liabilities),
      "label": "负债总额",
      "type": "number",
      "persistent-hint": "",
      "outlined": "",
      "dense": "",
      "min": "0",
      "step": "0.01",
      "prefix": "¥",
      "suffix": "元"
    },
    model: {
      value: _vm.earnings.editedItem.total_liabilities,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "total_liabilities", $$v);
      },
      expression: "earnings.editedItem.total_liabilities"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "hint": _vm._f("formatMoneyUnitFloat")(_vm.earnings.editedItem.equity_owner),
      "label": "所有者权益合计",
      "type": "number",
      "persistent-hint": "",
      "outlined": "",
      "dense": "",
      "min": "0",
      "step": "0.01",
      "prefix": "¥",
      "suffix": "元"
    },
    model: {
      value: _vm.earnings.editedItem.equity_owner,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "equity_owner", $$v);
      },
      expression: "earnings.editedItem.equity_owner"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "hint": _vm._f("formatMoneyUnitFloat")(_vm.earnings.editedItem.order_amount),
      "label": "订货额度",
      "type": "number",
      "persistent-hint": "",
      "outlined": "",
      "dense": "",
      "min": "0",
      "step": "0.01",
      "prefix": "¥",
      "suffix": "元"
    },
    model: {
      value: _vm.earnings.editedItem.order_amount,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "order_amount", $$v);
      },
      expression: "earnings.editedItem.order_amount"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "label": "标准信用帐期",
      "outlined": "",
      "dense": "",
      "step": "1",
      "type": "number",
      "suffix": "天"
    },
    model: {
      value: _vm.earnings.editedItem.period_credit,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "period_credit", $$v);
      },
      expression: "earnings.editedItem.period_credit"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.negative],
      "label": "实际回款周期",
      "outlined": "",
      "dense": "",
      "step": "1",
      "type": "number",
      "suffix": "天"
    },
    model: {
      value: _vm.earnings.editedItem.period_payment,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "period_payment", $$v);
      },
      expression: "earnings.editedItem.period_payment"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', {
    attrs: {
      "hint": parseFloat((_vm.earnings.editedItem.scale_risk_payment / 100).toFixed(4)).toString(),
      "rules": [_vm.rules.required],
      "label": "回款风险评价",
      "type": "number",
      "persistent-hint": "",
      "outlined": "",
      "dense": "",
      "suffix": "%"
    },
    model: {
      value: _vm.earnings.editedItem.scale_risk_payment,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings.editedItem, "scale_risk_payment", $$v);
      },
      expression: "earnings.editedItem.scale_risk_payment"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "1"
    }
  }, [_vm._v("资产")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(_vm._s(_vm._f("formatMoneyUnitFloat")(_vm.valOperatingAssets)) + "元")]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("评估值")]), _c('v-col', {
    staticClass: "d-flex font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.valEvaluation) + " "), _c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        _vm.earnings.evalDrawer = !_vm.earnings.evalDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("read_more")])], 1)], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("评估区")]), _vm.earnings.editedItem.industry ? _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(_vm._s(_vm.valEvaluationExpe.scale) + "% "), _c('v-btn', {
    attrs: {
      "icon": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        _vm.earnings.evalDrawer = !_vm.earnings.evalDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("read_more")])], 1)], 1) : _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-btn', {
    staticClass: "mt-n2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.editItem('industry', _vm.editItemData, '所属行业');
      }
    }
  }, [_vm._v("选择行业")])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "outlined": "",
      "text": "",
      "color": "primary"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("运营资产信用")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    class: _vm.creditMinValue === _vm.creditOpsAsset ? 'secondary--text' : ''
  }, [_vm._v(_vm._s(_vm._f("formatMoneyUnitFloat")(_vm.creditOpsAsset)) + "元")])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("流动资产信用")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    class: _vm.creditMinValue === _vm.creditCurAsset ? 'secondary--text' : ''
  }, [_vm._v(_vm._s(_vm._f("formatMoneyUnitFloat")(_vm.creditCurAsset)) + "元")])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("权益信用")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    class: _vm.creditMinValue === _vm.creditEquity ? 'secondary--text' : ''
  }, [_vm._v(_vm._s(_vm._f("formatMoneyUnitFloat")(_vm.creditEquity)) + "元")])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("销售信用")]), _c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    class: _vm.creditMinValue === _vm.creditSales ? 'secondary--text' : ''
  }, [_vm._v(_vm._s(_vm._f("formatMoneyUnitFloat")(_vm.creditSales)) + "元")])])], 1)], 1)], 1)], 1) : _vm._e(), _vm.dlgTitle === '发票抬头' ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-subheader', {
    staticClass: "pl-0"
  }, [_vm._v("发票信息")]), _c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.editItemData.invoice_info.invoice_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.invoice_info, "invoice_type", $$v);
      },
      expression: "editItemData.invoice_info.invoice_type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "增值税普通发票",
      "value": 0
    }
  }), _c('v-radio', {
    attrs: {
      "label": "增值税专用发票",
      "value": 1
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "抬头名称",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItemData.invoice_info.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.invoice_info, "title", $$v);
      },
      expression: "editItemData.invoice_info.title"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "统一社会信用代码",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItemData.invoice_info.license_code,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.invoice_info, "license_code", $$v);
      },
      expression: "editItemData.invoice_info.license_code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "开户行",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItemData.invoice_info.bank_name,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.invoice_info, "bank_name", $$v);
      },
      expression: "editItemData.invoice_info.bank_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "银行账号",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItemData.invoice_info.bank_account,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.invoice_info, "bank_account", $$v);
      },
      expression: "editItemData.invoice_info.bank_account"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "企业地址",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItemData.invoice_info.license_address,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.invoice_info, "license_address", $$v);
      },
      expression: "editItemData.invoice_info.license_address"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "企业电话",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItemData.invoice_info.license_telephone,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.invoice_info, "license_telephone", $$v);
      },
      expression: "editItemData.invoice_info.license_telephone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-subheader', {
    staticClass: "pl-0"
  }, [_vm._v("邮寄信息")]), _c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "收件人姓名",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItemData.invoice_info.delivery_name,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.invoice_info, "delivery_name", $$v);
      },
      expression: "editItemData.invoice_info.delivery_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "收件人电话",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItemData.invoice_info.delivery_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.invoice_info, "delivery_phone", $$v);
      },
      expression: "editItemData.invoice_info.delivery_phone"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "label": "收件地址",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItemData.invoice_info.delivery_address,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData.invoice_info, "delivery_address", $$v);
      },
      expression: "editItemData.invoice_info.delivery_address"
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm.$route.params.id !== '0' ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_vm._v("取消")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1), _c('v-navigation-drawer', {
    staticStyle: {
      "z-index": "999"
    },
    attrs: {
      "app": "",
      "bottom": "",
      "right": "",
      "hide-overlay": "",
      "width": "316"
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.earnings.evalDrawer = !_vm.earnings.evalDrawer;
            }
          }
        }, [_vm._v("关闭")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.earnings.evalDrawer,
      callback: function callback($$v) {
        _vm.$set(_vm.earnings, "evalDrawer", $$v);
      },
      expression: "earnings.evalDrawer"
    }
  }, [_c('v-card-title', [_vm._v(" 运营资产等级 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.earnings.evalDrawer = !_vm.earnings.evalDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _vm.earnings.editedItem.industry ? _c('v-card-text', [_c('v-simple-table', {
    attrs: {
      "dense": "",
      "fixed-header": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn() {
        return [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-left"
        }, [_vm._v("评估区")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("评估值")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("评估比率")])])]), _c('tbody', [_c('tr', [_c('td', {
          attrs: {
            "bgcolor": "#EF9A9A"
          }
        }, [_vm._v("1")]), _c('td', [_vm._v("小于 " + _vm._s(_vm.earnings.editedItem.industry.evaluation[0].start))]), _c('td', [_vm._v(_vm._s(_vm.earnings.editedItem.industry.eval_init_scale) + "%")])]), _vm._l(_vm.earnings.editedItem.industry.evaluation, function (scale, i) {
          return _c('tr', {
            key: scale._id
          }, [i <= 1 ? _c('td', {
            attrs: {
              "bgcolor": "#EF9A9A"
            }
          }, [_vm._v(_vm._s(i + 2))]) : i <= 4 ? _c('td', {
            attrs: {
              "bgcolor": "#FFCC80"
            }
          }, [_vm._v(_vm._s(i + 2))]) : i <= 7 ? _c('td', {
            attrs: {
              "bgcolor": "#E6EE9C"
            }
          }, [_vm._v(_vm._s(i + 2))]) : _c('td', {
            attrs: {
              "bgcolor": "#C5E1A5"
            }
          }, [_vm._v(_vm._s(i + 2))]), _c('td', [_c('span', {
            class: scale === _vm.valEvaluationExpe ? 'secondary--text' : ''
          }, [_vm._v(_vm._s(scale.start) + " 至 " + _vm._s(scale.end))])]), _c('td', [_c('span', {
            class: scale === _vm.valEvaluationExpe ? 'secondary--text' : ''
          }, [_vm._v(_vm._s(scale.scale) + "%")])])]);
        })], 2)];
      },
      proxy: true
    }], null, false, 270365678)
  })], 1) : _c('v-card-text', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.earnings.showIndustry = false;

        _vm.editItem('industry', _vm.editItemData, '所属行业');
      }
    }
  }, [_vm._v("设置企业所属行业后查看运营资产等级")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }