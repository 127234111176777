<template>
  <v-container>
    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>测试工具</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-alert dense type="info" class="ma-2" dismissible>
        仅供开发和管理员使用的系统工具，可能会对系统造成不可逆的损坏，请谨慎使用...
      </v-alert>
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-card outlined>
              <v-card-title>条形码生成器</v-card-title>
              <v-card-text>
                <v-textarea
                  v-model="editBarcode"
                  @change="createSvg"
                  outlined
                  label="请输入需要转换的条形码字符串"
                  placeholder="输入单个或者多个字符串，可以从EXCEL直接粘贴多行字符串"
                ></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="secondary" @click="createBarcode">生成</v-btn>

                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed color="secondary" v-bind="attrs" v-on="on">增加批量修改字段</v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item v-for="(item, index) in arrBarcodeOptions" :key="index" link @click="createBarcode(item)">
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

              </v-card-actions>
              <v-card-text v-if="arrBarcode && arrBarcode.length">
                <v-row>
                  <v-col cols="6" v-for="(code, index) in arrBarcode" :key="index +'_code'">
                    <v-sheet color="white" elevation="1">
                      format: {{formatBarcode}} | code: {{code}}
                      <svg class="barcode"
                           :jsbarcode-format="formatBarcode"
                           :jsbarcode-value="code"
                           jsbarcode-textmargin="0"
                           jsbarcode-fontoptions="bold">
                      </svg>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-divider></v-divider>Global：{{ Global }}
            <v-divider></v-divider>Online：{{ users.length }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"><v-divider></v-divider></v-col>
          <v-col cols="8">
            <v-chip-group column>
              <v-chip
                v-for="account in users"
                :key="account.userID"
                class="ma-2"
                :color="account.connected ? 'secondary' : 'primary'"
                text-color="white"
              >
                <v-avatar left>
                  <v-icon v-if="account.connected">account_circle</v-icon>
                  <v-icon v-else>offline_bolt</v-icon>
                </v-avatar>
                {{ account.username }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row>

        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
import ApiService from "@/common/http";
const JsBarcode = require('jsbarcode')
import socket from '@/common/socket';

export default {
  data() {
    return {
      editBarcode:'',
      arrBarcode: [],
      formatBarcode: '',
      arrBarcodeOptions: [
        { text: 'code128', format: '' },
        { text: 'ean-13', format: 'ean13' },
        { text: 'ean-8', format: 'ean8' },
        { text: 'ean-5', format: 'ean5' },
        { text: 'ean-2', format: 'ean2' },
        { text: 'upc', format: 'upc' },
        { text: 'code39', format: 'code39' },
        { text: 'itf-14', format: 'itf14' },
        { text: 'msi', format: 'msi' },
        { text: 'pharmacode', format: 'pharmacode' },
      ],
      users: [],
      importResult: null,
      importFailed: [],
      importEnd: true,
    }
  },
  created() {
    socket.on("connect", () => {
      this.users.forEach((user) => {
        if (user.self) {
          user.connected = true;
        }
      });
    });
    socket.on("disconnect", () => {
      this.users.forEach((user) => {
        if (user.self) {
          user.connected = false;
        }
      });
    });

    const initReactiveProperties = (user) => {
      user.hasNewMessages = false;
    };

    socket.on("users", (users) => {
      users.forEach((user) => {
        user.messages.forEach((message) => {
          message.fromSelf = message.from === socket.userID;
        });
        for (let i = 0; i < this.users.length; i++) {
          const existingUser = this.users[i];
          if (existingUser.userID === user.userID) {
            existingUser.connected = user.connected;
            existingUser.messages = user.messages;
            return;
          }
        }
        user.self = user.userID === socket.userID;
        initReactiveProperties(user);
        this.users.push(user);
      });
      // put the current user first, and sort by username
      this.users.sort((a, b) => {
        if (a.self) return -1;
        if (b.self) return 1;
        if (a.username < b.username) return -1;
        return a.username > b.username ? 1 : 0;
      });
    });

    socket.on("user connected", (user) => {
      for (let i = 0; i < this.users.length; i++) {
        const existingUser = this.users[i];
        if (existingUser.userID === user.userID) {
          existingUser.connected = true;
          return;
        }
      }
      initReactiveProperties(user);
      this.users.push(user);
    });

    socket.on("user disconnected", (id) => {
      for (let i = 0; i < this.users.length; i++) {
        const user = this.users[i];
        if (user.userID === id) {
          user.connected = false;
          break;
        }
      }
    });

    socket.on("private message", ({ content, from, to }) => {
      for (let i = 0; i < this.users.length; i++) {
        const user = this.users[i];
        const fromSelf = socket.userID === from;
        if (user.userID === (fromSelf ? to : from)) {
          user.messages.push({
            content,
            fromSelf,
          });
          if (user !== this.selectedUser) {
            user.hasNewMessages = true;
          }
          break;
        }
      }
    });
  },
  destroyed() {
    socket.off("connect");
    socket.off("disconnect");
    socket.off("users");
    socket.off("user connected");
    socket.off("user disconnected");
    socket.off("private message");
  },
  methods: {
    goBack() {
      this.$router.push({path: '/dealer/system'});
    },
    createSvg() {
      let reSplit = /\s*\n|,\s*/
      this.arrBarcode = this.editBarcode.split(reSplit)
      this.$forceUpdate
    },
    createBarcode(opt = {}) {
      console.log('selected opt =>'+ JSON.stringify(opt))
      this.formatBarcode = opt.format
      // for (const [index, code] of this.arrBarcode.entries()) {
      //   let eleId = '#'+ index +'_code'
      //   if (document.getElementById("1_code")) {
      //     console.log('1_code is ok')
      //   } else {
      //     console.log('1_code not')
      //   }
      //   JsBarcode(eleId, code);
      //   console.log(index +'=> ' + code)
      // }
      JsBarcode(".barcode").init();
    },
    sendName() {
      console.log('send =>'+ this.chat.name)
    },
    uploadFile() {
      this.$refs.uploadImport.click()
    },
    importFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    upload(file) {
      this.importEnd = false
      let param = new FormData();
      param.append('file', file);
      ApiService.post('/tools/import', param, {responseType:'blob'})
        .then((resp) => {
          let fileName = '堆堆堆堆堆堆.xlsx';
          let contentDisposition = resp.headers['content-disposition'];
          if (contentDisposition) {
            fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
          }
          let blob = new Blob([resp.data], {type: 'application/octet-stream'});
          if (window.navigator.msSaveOrOpenBlob) { //支持IE
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement('a');
            link.style.display = "none";
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            this.downloading = false
            //释放内存
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
          this.$refs.uploadImport.value = '';
          this.importEnd = true
        })
        .catch((err) => {
          this.$refs.uploadImport.value = '';
          this.importResult = { updateSum: 0, insertSum: 0 }
          this.importFailed = []
          this.importEnd = true
          this.importFailed = [{line: 2, error: err.response.data.message}]
        });
    },
  }
}
</script>
<style scoped>
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
