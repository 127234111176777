var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("产品管理")]), _c('v-spacer')], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" ESP平台所有产品都将在此维护，包括产品属性、成本信息等内容。产品型号为产品的唯一值，不能重复录入。 ")]), _c('v-tabs', {
    attrs: {
      "color": "secondary",
      "grow": "",
      "icons-and-text": ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c('v-tabs-slider'), _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab', {
      key: tab.id,
      attrs: {
        "to": tab.route
      }
    }, [_vm._v(" " + _vm._s(tab.name) + " "), _c('v-icon', [_vm._v(_vm._s(tab.icon))])], 1);
  }), _vm._l(_vm.tabs, function (tab) {
    return _c('v-tab-item', {
      key: tab.id,
      attrs: {
        "value": tab.route,
        "transition": false,
        "reverse-transition": false
      }
    }, [_c('router-view')], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }