var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.title_page()))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": !_vm.valid,
      "icon": ""
    },
    on: {
      "click": _vm.preview
    }
  }, [_c('v-icon', [_vm._v("preview")])], 1), _c('v-btn', {
    attrs: {
      "disabled": !_vm.editItemData._id,
      "icon": ""
    },
    on: {
      "click": _vm.deleteItem
    }
  }, [_c('v-icon', [_vm._v("delete")])], 1), _c('v-btn', {
    attrs: {
      "disabled": !_vm.valid,
      "icon": ""
    },
    on: {
      "click": _vm.submit
    }
  }, [_c('v-icon', [_vm._v("save")])], 1)], 1), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.Upload.uploading ? _c('v-progress-linear', {
    attrs: {
      "color": "secondary",
      "value": _vm.Upload.progressUpload
    }
  }) : _vm._e(), _c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-img', {
    attrs: {
      "src": _vm.editItemData.profile_image ? _vm.ossURL + '/' + _vm.editItemData.profile_image : '/static/error/empty_state.svg',
      "dark": "",
      "gradient": "to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
      "height": "375px",
      "aspect-ratio": 32 / 9
    }
  }, [_c('v-row', {
    staticClass: "lightbox white--text pa-2 fill-height",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "subheading"
  }, [_vm._v(_vm._s(_vm.editItemData.publish_date) + " " + _vm._s(_vm.editItemData.author))]), _c('div', {
    staticClass: "text-h3"
  }, [_vm._v(_vm._s(_vm.editItemData.title) + " "), _c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.uploadProfile(_vm.editItemData);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("add_photo_alternate")]), _vm._v("上传知识封面")], 1)], 1)])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.KBCatalog,
      "rules": [_vm.rules.selected],
      "autocomplete": "off",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "label": "请选择知识所属目录"
    },
    model: {
      value: _vm.editItemData.catalog,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "catalog", $$v);
      },
      expression: "editItemData.catalog"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-combobox', {
    attrs: {
      "label": "知识标签",
      "hint": "使用回车键可以输入多个标签。标签将作为用户检索内容之一。",
      "deletable-chips": "",
      "multiple": "",
      "small-chips": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.label,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "label", $$v);
      },
      expression: "editItemData.label"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm.catalogList && _vm.catalogList.length ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.flatGroupCatalog(_vm.catalogList),
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "label": "请选择支持服务内容",
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "multiple": "",
      "clearable": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_typeof(data.item) !== 'object' ? [_c('v-list-item-content', {
          domProps: {
            "textContent": _vm._s(data.item)
          }
        })] : [_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "input-value": data.attrs.inputValue
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        })], 1)]];
      }
    }], null, false, 3296285918),
    model: {
      value: _vm.editItemData.service,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "service", $$v);
      },
      expression: "editItemData.service"
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "发布日期",
            "hint": "请选择知识的发布日期",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          model: {
            value: _vm.editItemData.publish_date,
            callback: function callback($$v) {
              _vm.$set(_vm.editItemData, "publish_date", $$v);
            },
            expression: "editItemData.publish_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.dataTimeMenu,
      callback: function callback($$v) {
        _vm.dataTimeMenu = $$v;
      },
      expression: "dataTimeMenu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.dataTimeMenu = false;
      }
    },
    model: {
      value: _vm.editItemData.publish_date,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "publish_date", $$v);
      },
      expression: "editItemData.publish_date"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "作者",
      "hint": "请输入知识作者或发布方",
      "outlined": "",
      "clearable": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.author,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "author", $$v);
      },
      expression: "editItemData.author"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "标题",
      "hint": "请输入知识的标题",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editItemData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "title", $$v);
      },
      expression: "editItemData.title"
    }
  }), _c('editor', {
    attrs: {
      "init": _vm.editorInit
    },
    model: {
      value: _vm.editItemData.context,
      callback: function callback($$v) {
        _vm.$set(_vm.editItemData, "context", $$v);
      },
      expression: "editItemData.context"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _vm.valid ? _c('v-speed-dial', {
    staticStyle: {
      "z-index": "5"
    },
    attrs: {
      "large": "",
      "fixed": "",
      "bottom": "",
      "right": "",
      "direction": "top",
      "open-on-hover": "",
      "transition": "slide-y-reverse-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "color": "secondary",
            "dark": "",
            "fab": ""
          },
          model: {
            value: _vm.btnFab,
            callback: function callback($$v) {
              _vm.btnFab = $$v;
            },
            expression: "btnFab"
          }
        }, [_vm.btnFab ? _c('v-icon', [_vm._v("close")]) : _c('v-icon', [_vm._v("save")])], 1)];
      },
      proxy: true
    }], null, false, 3883048176),
    model: {
      value: _vm.btnFab,
      callback: function callback($$v) {
        _vm.btnFab = $$v;
      },
      expression: "btnFab"
    }
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "dark": "",
      "small": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_c('v-icon', [_vm._v("save")])], 1), _vm.editItemData._id ? _c('v-btn', {
    attrs: {
      "fab": "",
      "dark": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": _vm.deleteItem
    }
  }, [_c('v-icon', [_vm._v("delete")])], 1) : _vm._e(), _c('v-btn', {
    attrs: {
      "fab": "",
      "dark": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": _vm.preview
    }
  }, [_c('v-icon', [_vm._v("preview")])], 1)], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }