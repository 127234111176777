<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>客户管理</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-alert dense type="info" class="ma-2" dismissible>
        经销商全部人员均可以浏览企业列表，但编辑和审核需要企业管理权限，可以联系平台负责人获取权限。
      </v-alert>
      <v-tabs v-model="activeTab" color="secondary" grow icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route">
          {{ tab.name }}
          <v-icon>{{tab.icon}}</v-icon>
        </v-tab>
        <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route" :transition="false" :reverse-transition="false">
          <router-view v-if="tab.route === $route.fullPath && tab.route === activeTab"></router-view>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>
<script>

export default {
  data() {
    return {
      activeTab: '/dealer/enterprise/enterprise',
      tabs: [
        { id: 1, name: '企业管理', route: '/dealer/enterprise/enterprise', help: '企业管理', icon: 'location_city' },
        { id: 2, name: '集团管理', route: '/dealer/enterprise/group', help: '集团管理', icon: 'corporate_fare' },
      ],
    }
  },
  created() {},
  computed: {},
  watch: {},
}
</script>
