<template>
  <v-container>
    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>风控管理</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-alert dense type="info" class="ma-2" dismissible>
        风控管理模块是为风控管理人员提供企业授信模型维护的模块，当这些模块正常维护后，前台用户才可以对企业进行信用额度查询和使用等操作。
      </v-alert>
      <v-tabs v-model="activeTab" color="secondary" grow icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route">
          {{ tab.name }}
          <v-icon>{{tab.icon}}</v-icon>
        </v-tab>
        <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route" :transition="false" :reverse-transition="false">
          <router-view v-if="tab.route === $route.fullPath && tab.route === activeTab"></router-view>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>
<script>

export default {
  data() {
    return {
      activeTab: '/dealer/system/risk/model',
      tabs: [
        { id: 1, name: '授信管理', route: '/dealer/system/risk/enterprise', help: '授信管理', icon: 'credit_score' },
        { id: 2, name: '模型管理', route: '/dealer/system/risk/model', help: '模型管理', icon: 'calculate' },
        { id: 3, name: '区域管理', route: '/dealer/system/risk/citylevel', help: '区域管理', icon: 'public' },
        { id: 4, name: '经济数据', route: '/dealer/system/risk/economy', help: '经济数据管理', icon: 'paid' },
        { id: 5, name: '授信类型', route: '/dealer/system/risk/enterprisetype', help: '经济数据管理', icon: 'class' },
      ],
    }
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    goBack() {
      this.$router.push({path: '/dealer/system'});
    },
  }
}
</script>
