<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>组织权限</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="txtSearch"
          @keyup="doAction('search_employee')"
          dark
          class="mx-4"
          flat
          hide-details
          clearable
          label="搜索人员姓名、电话或者电子邮件地址"
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
      </v-toolbar>
      <v-card-title v-if="!txtSearch">
        组织权限
      </v-card-title>
      <v-card-subtitle v-if="!txtSearch">组织权限可以维护经销商内部员工组织架构和角色权限。组织架构最多可以建立三级，平台的数据访问是基于组织架构模型，同一部门内可以浏览相同的数据，上级领导可以浏览所有下属的数据。
        门店设置为
        角色权限设定每个角色所能使用的功能，且一个人员可以设置多个角色。</v-card-subtitle>
      <v-tabs v-model="activeTab" color="secondary" grow icons-and-text v-if="!txtSearch">
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route">
          {{ tab.name }}
          <!--<v-tooltip bottom>-->
          <!--<template v-slot:activator="{ on, attrs }">-->
          <!--<v-icon class="ml-2" color="primary lighten-4" small v-bind="attrs" v-on="on">help_outline</v-icon>-->
          <!--</template>-->
          <!--{{tab.help}}-->
          <!--</v-tooltip>-->
          <v-icon>{{tab.icon}}</v-icon>
        </v-tab>
        <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route">
          <router-view></router-view>
        </v-tab-item>
      </v-tabs>
      <v-data-table
        v-if="txtSearch"
        :headers="headerEmployee"
        :items="accountList"
        :options.sync="employee_options"
        :server-items-length="accountCount"
        :loading="accountLoading"
        :item-class="row_class"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
        item-key="_id"
        dense
        transition
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title><v-icon class="mr-2">groups</v-icon>人员列表</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" outlined fab x-small @click="doAction('create_employee')">
              <v-icon>person_add</v-icon>
            </v-btn>
            <v-btn class="ma-2" outlined fab x-small @click="doAction('import_employee')">
              <v-icon>group_add</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.personal.name="{ item }">
          <widgets-employee-dialogs :account="item._id" v-on:UpdateEmployee="getEmployee({key: txtSearch})">{{item.personal.name || '*未设置姓名'}}</widgets-employee-dialogs>
        </template>
        <template v-slot:item.employer.department="{ item }">
          {{item.employer.department.map(item => item.name)}}
        </template>
        <template v-slot:item.employer.roles="{ item }">
          {{item.employer.roles.map(item => item.name)}}
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogEmployee" scrollable persistent width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card style="height: 550px;">
          <v-card-title>
            {{dialogTitle}}
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text v-if="dialogTitle === '新增人员'">
            <v-container>
              <v-row align="start" justify="center">
                <v-col cols="2" class="text-center">
                  <input
                    id="files"
                    type="file"
                    name="file"
                    ref="uploadInput"
                    accept="image/*"
                    :multiple="false"
                    @change="detectFiles($event)"/>
                  <v-hover v-slot:default="{ hover }">
                    <v-avatar>

                      <v-img
                        :src="editedEmployee.profile ? ossURL +'/'+ editedEmployee.profile : '/static/grey_silhouette.png'"
                        alt="设置照片"
                        aspect-ratio="1">
                        <v-progress-circular
                          color="secondary"
                          rotate="360"
                          size="48"
                          width="5"
                          v-if="Upload.uploading"
                          :value="Upload.progressUpload">
                        </v-progress-circular>
                      </v-img>
                      <div v-if="hover" class="show_upload" style="height: 100%; cursor: pointer">
                        <v-icon @click="doAction('upload_avatar', editedEmployee)" >add_a_photo</v-icon>
                      </div>
                    </v-avatar>
                  </v-hover>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="editedEmployee.name"
                    type="text"
                    label="员工姓名"
                    hint="员工姓名为用户的真实姓名"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">https</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="editedEmployee.username"
                    :rules="[rules.required, rules.email]"
                    :error-messages="editedEmployee.msgName"
                    :clearable="editedEmployee.msgName !== ''"
                    type="text"
                    label="电子邮件"
                    hint="电子邮件将作为登陆名称使用"
                    outlined
                    dense
                    required
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="editedEmployee.chkName"
                          :width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-scale-transition>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">vpn_key</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="editedEmployee.password"
                    :rules="[rules.required]"
                    :type="editedEmployee.showPass ? 'text' : 'password'"
                    :append-icon="editedEmployee.showPass ? 'visibility_off' : 'visibility'"
                    label="账号密码"
                    hint="请牢记此密码，密码将作为登陆凭证"
                    outlined
                    dense
                    required
                    @click:append="editedEmployee.showPass = !editedEmployee.showPass"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">stay_current_portrait</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="editedEmployee.phone"
                    :rules="[rules.phonenum]"
                    :error-messages="editedEmployee.msgPhone"
                    :clearable="editedEmployee.msgPhone !== ''"
                    type="number"
                    label="手机号码"
                    hint="电话号码将用于恢复密码和登陆凭证"
                    outlined
                    dense
                  >
                    <template v-slot:append>
                      <v-scale-transition leave-absolute>
                        <v-progress-circular
                          v-if="editedEmployee.chkPhone"
                          :width="2"
                          size="24"
                          color="secondary"
                          indeterminate
                        ></v-progress-circular>
                      </v-scale-transition>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">account_tree</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="editedEmployee.department"
                    :items="flatDeptList(departments[0])"
                    :rules="[rules.selected]"
                    item-text="name"
                    item-value="value"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择所属部门"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">perm_identity</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-autocomplete
                    v-model="editedEmployee.roles"
                    :items="flatGroupRoles(groupList)"
                    :rules="[rules.selected]"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择所属角色"
                    multiple
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-action>
                          <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">assignment_ind</v-icon>
                </v-col>
                <v-col cols="10">
                  <v-text-field
                    v-model="editedEmployee.employeeNo"
                    type="text"
                    label="员工编号"
                    hint="员工编号为企业内部的员工编号"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center" class="mt-n6" v-for="(item, i) in customFields" :key="i">
                <v-col cols="2" class="text-center">
                  <v-icon class="mt-n6" color="primary lighten-4">list</v-icon>
                </v-col>
                <v-col cols="10" v-if="item.format.type === 'text'">
                  <v-text-field
                    v-if="item.format.mask"
                    v-model="item.value"
                    v-mask="item.format.mask"
                    :label="item.name"
                    outlined
                    dense>
                  </v-text-field>
                  <v-text-field
                    v-else
                    v-model="item.value"
                    :label="item.name"
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="10" v-if="item.format.type === 'selects'">
                  <v-autocomplete
                    autocomplete="off"
                    v-model="item.value"
                    :items="item.format.items"
                    :label="item.name"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-if="dialogTitle === '导入人员'">
            <v-container>
              <!--<v-row>-->
                <!--<v-col cols="12" class="text-center">-->
                  <!--<v-btn-toggle v-model="toggle_import" mandatory dense>-->
                    <!--<v-btn>批量导入人员</v-btn>-->
                    <!--<v-btn>批量修改人员</v-btn>-->
                  <!--</v-btn-toggle>-->
                <!--</v-col>-->
              <!--</v-row>-->
              <v-row>
                <v-col cols="6">
                  <p>1. 下载人员模版，填写成员信息.</p>
                  <v-btn text @click="doAction('download_template')">下载模版<v-icon>file_download</v-icon></v-btn>
                </v-col>
                <v-col cols="6">
                  <input
                    id="import_file"
                    type="file"
                    name="import_file"
                    ref="uploadImport"
                    accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :multiple="false"
                    @change="importFiles($event)"/>
                  <p>2. 上传填好的文件.</p>
                  <v-btn text @click="doAction('upload_employee')"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
                </v-col>
              </v-row>
              <!--<v-row v-if="toggle_import === 1">-->
                <!--<v-col cols="12">-->
                  <!--<v-alert-->
                    <!--outlined-->
                    <!--type="error"-->
                    <!--text-->
                  <!--&gt;-->
                    <!--测试阶段 - 暂未开放功能-->
                  <!--</v-alert>-->
                <!--</v-col>-->
              <!--</v-row>-->
              <v-row v-if="importEnd">
                <v-col cols="12">
                  <v-divider></v-divider>
                  <v-alert
                    outlined
                    type="error"
                    text
                  >
                    导入失败：<ul><li v-for="(fail,index) in importFailed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
                  </v-alert>
                  <v-alert
                    outlined
                    type="success"
                    text
                  >
                    成功导入：{{importSuccess}} 条记录
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <widgets-custom-field module="Account" v-if="dialogTitle === '新增人员'" v-on:Complete="doAction('update_field')"></widgets-custom-field>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDialog">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid" v-if="dialogTitle !== '导入人员'">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import _ from 'lodash'
import ApiService from "@/common/http";
import axios from "axios";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import XLSX from 'xlsx'
import {
  FETCH_ACCOUNT_LIST,
  FETCH_FIELDS,
  FETCH_GROUP_LIST,
  FETCH_DEPT_LIST,
  CREATE_EMPLOYEE,
  CHECK_USERNAME,
  CHECK_USERPHONE,
  FETCH_UPLOAD,
  FETCH_BRANCH_LIST,
} from "@/store/actions.type";
export default {
  data() {
    return {
      activeTab: '/dealer/system/organize/department',
      tabs: [{
        id: 1, name: '组织架构', route: '/dealer/system/organize/department', help: '组织架构调整将影响用户查阅和管理数据权限的范围，不影响用户使用功能权限。', icon: 'account_tree'
      }, {
        id: 2, name: '角色权限', route: '/dealer/system/organize/roles', help: '角色权限调整将影响用户使用平台功能的范围，不影响用户查阅和管理数据范围。', icon: 'supervisor_account'
      }],
      txtSearch: '',
      headerEmployee: [
        { text: '姓名', value: 'personal.name', width: "75px" },
        { text: '电子邮件', value: 'username', width: "135px" },
        { text: '手机号码', value: 'phone', width: "105px" },
        { text: '部门', value: 'employer.department', width: "135px" },
        { text: '角色', value: 'employer.roles', width: "135px" },
        { text: '员工编号', value: 'employer.employeeNo', width: "75px" },
        { text: '操作', value: 'actions', width: "75px", sortable: false }
      ],
      employee_options: {},
      dialogEmployee: false,
      dialogTitle: '',
      valid: true,
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      editedEmployee: {
        _id: '',
        username: '',
        password: '',
        phone: '',
        name: '',
        profile: '',
        department: [],
        employeeNo: '',
        roles: [],
        chkName: false,
        msgName: '',
        chkPhone: false,
        msgPhone: '',
        showPass: false,
        selDeptActive:[],
      },
      defaultEmployee: {
        _id: '',
        username: '',
        password: '',
        phone: '',
        name: '',
        profile: '',
        department: [],
        employeeNo: '',
        roles: [],
        chkName: false,
        msgName: '',
        chkPhone: false,
        msgPhone: '',
        showPass: false,
        selDeptActive:[],
      },
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      toggle_import: undefined,
      importExcelHeader: ['姓名', '电子邮件', '手机号码', '部门', '角色', '员工编号'],
      importExcelNotice: [['部门上下级用-符号分割，角色不用填写分组名称']],
      importSuccess: 0,
      importFailed: [],
      importEnd: false
    }
  },
  created() {},
  computed: {
    ...mapGetters([
      'departments',
      'customFields',
      'groupList',
      'uploadSign',
      'accountLoading',
      'accountList',
      'accountCount',
      'currentEmployer',
      'branchList']),
  },
  watch: {
    employee_options: {
      handler () {
        this.getEmployee()
      },
      deep: true,
    },
    dialogEmployee (val) {
      val || this.closeDialog()
    },
    'editedEmployee.username'() {
      this.checkName();
    },
    'editedEmployee.phone'() {
      this.checkPhone();
    },
  },
  mounted() {
    this.$store.dispatch(FETCH_DEPT_LIST)
  },
  methods: {
    goBack() {
      this.$router.push({path: '/dealer/system'});
    },
    doAction(action) {
      switch (action) {
        case 'search_employee': {
          if (this.txtSearch !== '') this.getEmployee({key: this.txtSearch})
          break
        }
        case 'create_employee': {
          this.dialogTitle = '新增人员';
          this.getFieldList('Account');
          this.getRolesList();
          this.getBranchList();
          this.dialogEmployee = true;
          break;
        }
        case 'import_employee': {
          this.dialogTitle = '导入人员';
          this.dialogEmployee = true;
          break;
        }
        case 'update_field': {
          this.getFieldList('Account');
          break;
        }
        case 'upload_avatar': {
          this.$store.dispatch(FETCH_UPLOAD)
            .then(this.$refs.uploadInput.click())
            .catch(err => {
              this.$store.commit(SET_ERROR, {msg: err});
            });
          break;
        }
        case 'upload_employee': {
          this.$refs.uploadImport.click()
          break;
        }
        case 'download_template': {
          this.$store.dispatch(FETCH_FIELDS, 'Account')
            .then(() => {
              this.customFields.forEach(item => {
                if (!this.importExcelHeader.includes(item.name)) this.importExcelHeader.push(item.name)
              })

              let ws = XLSX.utils.aoa_to_sheet(this.importExcelNotice)
              XLSX.utils.sheet_add_json(ws, [
                {姓名: '张力（样例数据）', 电子邮件: 'test@test.com', 手机号码: 13800138000, 部门: '客户服务中心-热线', 角色: this.currentEmployer.roles[0].name, 员工编号: 'A001'}
              ], {header: this.importExcelHeader, origin: 'A2'})
              const merge = [
                {s: {r:0, c:0}, e: {r:0, c:this.importExcelHeader.length - 1}}
              ]
              ws['!merges'] = merge
              ws.A1.s = {alignment:{ wrapText: true }};
              let wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, ws, 'Import_Employee')
              XLSX.writeFile(wb, this.currentEmployer.ownerId.name +'_人员模版.xlsx')
            })
          break;
        }
      }
    },
    getEmployee(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.employee_options;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      const { key, ownertype, ownerid, department, roles } = query;
      let urlQuery = '';
      if (ownertype && ownertype.abbr) urlQuery += '&ownertype='+ ownertype.abbr;
      if (department && department !== -1) urlQuery += '&department='+ department;
      if (roles && roles._id) urlQuery += '&roles='+ roles._id;
      if (ownerid) urlQuery += '&ownerid='+ ownerid;
      if (key) urlQuery += '&key='+ key;
      if (Object.keys(this.employee_options).length > 0) {
        this.$store.dispatch(FETCH_ACCOUNT_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    getDeptList() {
      this.$store.dispatch(FETCH_DEPT_LIST)
    },
    getFieldList(mod = 'Account') {
      this.$store.dispatch(FETCH_FIELDS, this.currentEmployer.ownerId._id+'/'+mod)
        .then(() => {
          addModelName(this.customFields)
        })
    },
    getRolesList() {
      this.$store.dispatch(FETCH_GROUP_LIST)
    },
    getBranchList() {
      this.$store.dispatch(FETCH_BRANCH_LIST)
    },
    submit() {
      switch (this.dialogTitle) {
        case '新增人员': {
          let strInsert = {}
          strInsert['personal'] = {}
          strInsert['employer'] = {}
          strInsert['customFields'] = []
          strInsert.username = this.editedEmployee.username
          strInsert.password = this.editedEmployee.password
          strInsert.phone = this.editedEmployee.phone
          strInsert['employer'].department = this.editedEmployee.department
          strInsert['employer'].roles = this.editedEmployee.roles
          if (this.customFields.length) {
            this.customFields.forEach(item => {
              strInsert['customFields'].push({ _id: item._id, value: item.value })
            })
          }
          if (this.editedEmployee.name) strInsert['personal'].name = this.editedEmployee.name
          if (this.editedEmployee.profile) strInsert['personal'].profile = this.editedEmployee.profile
          if (this.editedEmployee.employeeNo) strInsert['employer'].employeeNo = this.editedEmployee.employeeNo;

          this.$store.dispatch(CREATE_EMPLOYEE, strInsert)
            .then(() => {
              this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
              if (this.txtSearch !== '') this.getEmployee({key: this.txtSearch})
              this.closeDialog()
            })
            .catch((error) => {
              this.$store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break;
        }
      }
    },
    closeDialog () {
      this.dialogEmployee = false
      this.$nextTick(() => {
        this.editedEmployee = Object.assign({}, this.defaultEmployee)
        this.importSuccess = 0
        this.importFailed.length = 0
        this.importEnd = false
      })
    },
    flatGroupRoles(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.roles.forEach((rolesItem) => {
          arrRoles.push({ _id: rolesItem._id, name: rolesItem.name, group: groupItem.name })
        })
        arrRoles.push({ divider: true })
      })
      return arrRoles
    },
    flatDeptList(obj) {
      let arrObj = [];
      arrObj.push({name: obj.name, value: obj._id})
      if (obj.children) {
        for (let item_1 of obj.children) {
          arrObj.push({name: obj.name +'>>'+ item_1.name, value: item_1._id})
          if (item_1.children) {
            for (let item_2 of item_1.children) {
              arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name, value: item_2._id})
              if (item_2.children) {
                for (let item_3 of item_2.children) {
                  arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name +'>>'+ item_3.name, value: item_3._id})
                }
              }
            }
          }
        }
      }
      return arrObj;
    },
    checkName: _.debounce(function () {
      if (this.editedEmployee.username) {
        this.editedEmployee.chkName = true;
        this.$store.dispatch(CHECK_USERNAME, this.editedEmployee.username)
          .then(() => {
            this.editedEmployee.msgName = '电子邮件已被注册，请更换其它地址';
            this.editedEmployee.chkName = false;
          })
          .catch(() => {
            this.editedEmployee.msgName = '';
            this.editedEmployee.chkName = false;
          })
      }
    }, 1000),
    checkPhone: _.debounce(function () {
      if (this.editedEmployee.phone) {
        this.editedEmployee.chkPhone = true;
        this.$store.dispatch(CHECK_USERPHONE, this.editedEmployee.phone)
          .then(() => {
            this.editedEmployee.msgPhone = '手机号码已被注册，请更换其它号码';
            this.editedEmployee.chkPhone = false;
          })
          .catch(() => {
            this.editedEmployee.msgPhone = '';
            this.editedEmployee.chkPhone = false;
          })
      }
    }, 1000),
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', 'avatar/' + this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.editedEmployee.profile = 'avatar/' + this.uploadSign.key;
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    importFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importEmployee(fileList[x]);
      })
    },
    importEmployee(file) {
      let param = new FormData();
      param.append('file', file);
      ApiService.post('/employee/import', param)
        .then((data) => {
          this.importSuccess = data.data.Success
          this.importFailed = data.data.Failed
          this.$refs.uploadImport.value = '';
          this.importEnd = true
        })
        .catch((err) => {
          this.$refs.uploadImport.value = '';
          this.importSuccess = 0
          this.importFailed = []
          this.importEnd = true
          let arrErr = [];
          if (err.response.data) {
            if (err.response.data.code === 11000) {
              arrErr = err.response.data.writeErrors.map(obj => { return obj.op.personal.name });
            }
          }
          if (arrErr.length > 0) {
            this.$store.commit(SET_ERROR, {msg: '请检查以下员工数据：'+ arrErr});
          } else {
            this.importFailed = [{line: 2, error: err.response.data.message}]
          }
        });
      if (this.txtSearch !== '') this.getEmployee({key: this.txtSearch})
    },
    row_class(item) {
      if (item.isBlock) {
        return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
      }
    }
  },
  directives: {}
}
function addModelName(arr) {
  arr.forEach(obj => {
    obj.value = ''
  })
}
</script>
<style scoped>
  .show_upload {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .5;
    position: absolute;
    width: 100%;
  }
  input[type="file"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
</style>
