var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("自定义字段列表")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "flat": "",
      "dense": "",
      "hide-details": "",
      "label": "搜索字段名称...",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchItems();
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 自定义字段包含经销商和企业两部分。 "), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("删除的字段仅在删除之后不可用，但此前已经使用的数据仍然可以查看。")])]), _c('v-data-table', {
    attrs: {
      "headers": _vm.headerItem,
      "items": _vm.fieldList,
      "options": _vm.optionsItem,
      "server-items-length": _vm.fieldCount,
      "loading": _vm.fieldLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50, 100]
      },
      "item-key": "_id",
      "return-object": "false",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-toolbar-title', [_c('v-icon', {
          staticClass: "mr-4"
        }, [_vm._v("fact_check")]), _c('span', [_vm._v("字段列表")])], 1), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-spacer'), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    _vm.filterDrawer = !_vm.filterDrawer;
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          staticClass: "v-list-item--link text-truncate",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.openItem(item);
            }
          }
        }, [_vm._v(_vm._s(item.name))])];
      }
    }, {
      key: "item.ownerType",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.ownerType === 'Dealer' ? '经销商' : '企业'))])];
      }
    }, {
      key: "item.ownerId",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.ownerType === 'Enterprise' ? _c('td', {
          staticClass: "v-list-item--link",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.openEnterprise(item.ownerId._id);
            }
          }
        }, [_vm._v(_vm._s(item.ownerId.name))]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.ownerId.name))])];
      }
    }, {
      key: "item.module",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("stringModule")(item.module)))])];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }])
  })], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.filterDrawer = !_vm.filterDrawer;
            }
          }
        }, [_vm._v("隐藏")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.clearSearch();
            }
          }
        }, [_vm._v("清空")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-subheader', {
    staticClass: "mt-n1 font-weight-bold"
  }, [_vm._v("筛选")]), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("雇主类型")]), _c('v-list-item-subtitle', [_c('v-chip-group', {
    attrs: {
      "column": ""
    },
    on: {
      "change": _vm.searchItems
    },
    model: {
      value: _vm.query.type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "type", $$v);
      },
      expression: "query.type"
    }
  }, [_c('v-chip', {
    attrs: {
      "filter": "",
      "outlined": "",
      "value": 'All'
    }
  }, [_vm._v("全部")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "outlined": "",
      "value": 'Dealer'
    }
  }, [_vm._v("经销商")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "outlined": "",
      "value": 'Enterprise'
    }
  }, [_vm._v("企业")])], 1)], 1)], 1)], 1), _vm.query.type === 'Enterprise' ? _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("企业名称")]), _c('v-list-item-subtitle', [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.fieldEnterpriseList,
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": _vm.searchItems
    },
    model: {
      value: _vm.query.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "enterprise", $$v);
      },
      expression: "query.enterprise"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("功能模块")]), _c('v-list-item-subtitle', [_c('v-chip-group', {
    attrs: {
      "multiple": "",
      "column": ""
    },
    on: {
      "change": _vm.searchItems
    },
    model: {
      value: _vm.query.module,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "module", $$v);
      },
      expression: "query.module"
    }
  }, [_c('v-chip', {
    attrs: {
      "filter": "",
      "outlined": "",
      "value": 'Account'
    }
  }, [_vm._v("人员")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "outlined": "",
      "value": 'Asset'
    }
  }, [_vm._v("资产")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "outlined": "",
      "value": 'Product'
    }
  }, [_vm._v("产品")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "outlined": "",
      "value": 'Task'
    }
  }, [_vm._v("任务")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'enterprise' ? _c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": _vm.closeInfo
    }
  }) : _vm._e(), _vm.activeType === 'field' ? _c('v-card', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" 编辑" + _vm._s(_vm._f("stringModule")(_vm.activeObject.module)) + "字段 ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "字段名称",
      "hint": "字段名称提示用户输入内容",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.activeObject.name,
      callback: function callback($$v) {
        _vm.$set(_vm.activeObject, "name", $$v);
      },
      expression: "activeObject.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.inputType,
      "rules": [_vm.rules.selected],
      "label": "输入形式",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.activeObject.format.type,
      callback: function callback($$v) {
        _vm.$set(_vm.activeObject.format, "type", $$v);
      },
      expression: "activeObject.format.type"
    }
  })], 1)], 1), _vm.activeObject.format.type === 'text' ? _c('v-row', {
    staticClass: "mt-n6"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "内容格式",
      "hint": "限定内容，为空即不限制任何输入",
      "append-outer-icon": "help_outline",
      "outlined": "",
      "dense": ""
    },
    on: {
      "click:append-outer": function clickAppendOuter($event) {
        return _vm.helpMask();
      }
    },
    model: {
      value: _vm.activeObject.format.mask,
      callback: function callback($$v) {
        _vm.$set(_vm.activeObject.format, "mask", $$v);
      },
      expression: "activeObject.format.mask"
    }
  }), _c('v-btn', {
    staticClass: "mt-n6",
    attrs: {
      "text": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.setMask('datatime');
      }
    }
  }, [_vm._v(" 日期时间 2016-12-12 20:32 ")]), _c('v-btn', {
    staticClass: "mt-n6",
    attrs: {
      "text": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.setMask('time');
      }
    }
  }, [_vm._v(" 时间 05:23:12 ")]), _c('v-btn', {
    staticClass: "mt-n6",
    attrs: {
      "text": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.setMask('telnumber');
      }
    }
  }, [_vm._v(" 国际电话 +086-01062011122 ")]), _c('v-btn', {
    staticClass: "mt-n6",
    attrs: {
      "text": "",
      "x-small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.setMask('compose');
      }
    }
  }, [_vm._v(" 字母数字组合 CN001 ")])], 1)], 1) : _vm._e(), _vm.activeObject.format.type === 'selects' ? _c('v-row', [_vm._l(_vm.activeObject.format.items, function (item, i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-text-field', {
      staticClass: "mt-n6",
      attrs: {
        "type": "text",
        "label": "内容",
        "hint": "下拉菜单选项内容",
        "outlined": "",
        "dense": "",
        "append-outer-icon": "remove"
      },
      on: {
        "click:append-outer": function clickAppendOuter($event) {
          return _vm.removeSelectItem(item);
        }
      },
      model: {
        value: _vm.activeObject.format.items[i],
        callback: function callback($$v) {
          _vm.$set(_vm.activeObject.format.items, i, $$v);
        },
        expression: "activeObject.format.items[i]"
      }
    })], 1);
  }), _c('v-col', {
    staticClass: "text-center mt-n6",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addSelectItem();
      }
    }
  }, [_c('v-icon', [_vm._v("add")]), _vm._v(" 增加下拉菜单内容 ")], 1)], 1)], 2) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.inputRules,
      "label": "输入验证",
      "hint": "请选择用户输入该字段时验证方式",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "multiple": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": ""
    },
    model: {
      value: _vm.activeObject.format.rules,
      callback: function callback($$v) {
        _vm.$set(_vm.activeObject.format, "rules", $$v);
      },
      expression: "activeObject.format.rules"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dlgDelete = !_vm.dlgDelete;
      }
    }
  }, [_vm._v("删除")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeInfo
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("确定")])], 1)], 1)], 1) : _vm._e()], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    model: {
      value: _vm.dlgDelete,
      callback: function callback($$v) {
        _vm.dlgDelete = $$v;
      },
      expression: "dlgDelete"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v("确认要删除该项目？")]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeDelete
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": _vm.removeField
    }
  }, [_vm._v("确认删除")]), _c('v-spacer')], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }