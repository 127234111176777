var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _vm.process_info ? _c('v-toolbar-title', [_vm._v(_vm._s(_vm.enterprise.name) + "：" + _vm._s(_vm.currentVendor && _vm.currentVendor.name) + _vm._s(_vm.process_info.service_catalog.name) + "工单")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": function click($event) {
        return _vm.submit('');
      }
    }
  }, [_vm._v("创建")])], 1), _vm.process_info ? _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', [_c('v-tab', [_vm._v("关联设备")]), _c('v-tab', [_vm._v("附件")]), _c('v-tab', [_vm._v("备注")]), _vm.enterprise && _vm.enterprise._id && _vm.vendorList && _vm.vendorList.length ? _c('v-tab-item', [_c('case-create-select-asset', {
    ref: "select_asset",
    attrs: {
      "selectItem": _vm.editedItem.link_asset,
      "enterprise": _vm.enterprise._id,
      "vendor": _vm.vendorList.map(function (vendor) {
        return vendor._id;
      }).join()
    },
    on: {
      "RepairVendor": _vm.setVendor
    }
  })], 1) : _vm._e(), _vm.enterprise && _vm.enterprise._id ? _c('v-tab-item', [_c('case-create-upload-files', {
    ref: "upload_files",
    attrs: {
      "enterprise": _vm.enterprise._id
    }
  })], 1) : _vm._e(), _c('v-tab-item', [_c('case-create-edit-remarks', {
    ref: "edit_remarks"
  })], 1)], 1)], 1)], 1), _vm.process_info && _vm.process_info.service_catalog.name === _vm.Global.REPAIR_CATALOG_TEXT ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-subheader', [_vm._v("申报信息")]), _c('v-divider', {
    staticClass: "mb-4"
  }), _vm.currentVendor && _vm.currentVendor.name && !_vm.currentVendor.name.toLowerCase().includes('apple') ? _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.repairType,
      "rules": [_vm.rules.required],
      "label": "服务类型",
      "placeholder": "维修服务类型",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.out_req_type,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "out_req_type", $$v);
      },
      expression: "editedItem.out_req_type"
    }
  }) : _vm._e(), _vm.editedItem.out_req_type === '三包服务' ? _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.repairPolicy,
      "rules": [_vm.rules.required],
      "label": "三包服务",
      "placeholder": "退换货策略",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.return_policy,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "return_policy", $$v);
      },
      expression: "editedItem.return_policy"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "联系电话",
      "placeholder": "请输入联系电话号码",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.returns.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.returns, "contact_phone", $$v);
      },
      expression: "editedItem.returns.contact_phone"
    }
  }), _vm.recoveryType ? _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.recoveryType,
      "rules": [_vm.rules.required],
      "label": "送修方式",
      "placeholder": "请选择设备送修方式",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.recovery.channel,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.recovery, "channel", $$v);
      },
      expression: "editedItem.recovery.channel"
    }
  }) : _vm._e(), _vm.branchList && _vm.currentVendor && _vm.currentVendor._id ? _c('v-autocomplete', {
    attrs: {
      "items": _vm.branchList.filter(function (branch) {
        return branch.support.includes(_vm.currentVendor._id.toString());
      }),
      "rules": [_vm.rules.required],
      "return-object": "",
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "clearable": "",
      "label": "服务门店",
      "placeholder": "请选择一家服务门店"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.name ? data.item.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.city) + " - " + _vm._s(data.item.address))])], 1)];
      }
    }], null, false, 43629064),
    model: {
      value: _vm.editedItem.service_branch,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "service_branch", $$v);
      },
      expression: "editedItem.service_branch"
    }
  }) : _vm._e(), _vm.enterprise && _vm.enterprise._id ? _c('case-create-select-account', {
    attrs: {
      "enterprise": _vm.enterprise
    },
    on: {
      "Selected": _vm.reloadAccount
    }
  }) : _vm._e(), _c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "故障描述",
      "placeholder": "请输入设备故障描述：如发生时间、原因、现象等，详细的故障描述有助于加速问题解决的过程。",
      "rows": "5",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.detail_fault,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "detail_fault", $$v);
      },
      expression: "editedItem.detail_fault"
    }
  }), _c('v-subheader', [_vm._v("寄回信息")]), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.returnType,
      "rules": [_vm.rules.required],
      "label": "寄回方式",
      "placeholder": "服务完成或被取消后将按此方式退回设备",
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.returns.channel,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.returns, "channel", $$v);
      },
      expression: "editedItem.returns.channel"
    }
  }), _vm.editedItem.returns.channel === 'express' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "append-icon": _vm.editedItem.account ? 'content_copy' : '',
      "type": "text",
      "label": "联系人",
      "placeholder": "请输入联系人姓名",
      "outlined": "",
      "dense": ""
    },
    on: {
      "click:append": _vm.copyAccount
    },
    model: {
      value: _vm.editedItem.returns.contact_name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.returns, "contact_name", $$v);
      },
      expression: "editedItem.returns.contact_name"
    }
  }) : _vm._e(), _vm.editedItem.returns.channel === 'express' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "联系电话",
      "placeholder": "请输入联系电话号码",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.returns.contact_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.returns, "contact_phone", $$v);
      },
      expression: "editedItem.returns.contact_phone"
    }
  }) : _vm._e(), _vm.editedItem.returns.channel === 'express' ? _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "寄回地址",
      "placeholder": "请输入地址信息",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.returns.express_address,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem.returns, "express_address", $$v);
      },
      expression: "editedItem.returns.express_address"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-subheader', [_vm._v("门店信息")]), _c('v-divider', {
    staticClass: "mb-4"
  }), _vm.editedItem.service_branch ? _c('case-create-label-branch', {
    staticClass: "mt-2",
    attrs: {
      "branch": _vm.editedItem.service_branch
    }
  }) : _vm._e(), _c('v-subheader', [_vm._v("用户信息")]), _c('v-divider', {
    staticClass: "mb-4"
  }), _vm.editedItem.account ? _c('case-create-label-account', {
    attrs: {
      "account": _vm.editedItem.account
    },
    on: {
      "Update": _vm.reloadAccount
    }
  }) : _vm._e()], 1)], 1) : _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "故障描述",
      "placeholder": "请输入设备故障描述：如发生时间、原因、现象等，详细的故障描述有助于加速问题解决的过程。",
      "rows": "5",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedItem.detail_fault,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "detail_fault", $$v);
      },
      expression: "editedItem.detail_fault"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": function click($event) {
        return _vm.submit('');
      }
    }
  }, [_vm._v("提交")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "500px"
    },
    model: {
      value: _vm.dlgSubmit,
      callback: function callback($$v) {
        _vm.dlgSubmit = $$v;
      },
      expression: "dlgSubmit"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" 确认提交新的服务请求 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeSubmit
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 服务请求提交后将通知经销商进行受理，处理过程和完成将会通过短信、邮件方式进行通知。如果关联了多台设备，系统将按设备创建多个服务请求。 ")])], 1), _vm.createResult.length ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.createResult.length) + " 个服务请求；服务请求编号为： ")])], 1), _c('v-row', [_c('v-col', {
    staticClass: "grow"
  }, _vm._l(_vm.createResult, function (code) {
    return _c('p', {
      key: code,
      staticClass: "font-weight-black"
    }, [_vm._v(_vm._s(code))]);
  }), 0)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-divider'), !_vm.createResult.length ? _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeSubmit
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _vm.$refs.select_asset && _vm.$refs.select_asset.selectedAsset.length > 1 ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": "",
            "color": "secondary",
            "loading": !_vm.submitEnd
          },
          on: {
            "click": function click($event) {
              return _vm.submit('each');
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("file_copy")]), _vm._v("提交")], 1)];
      }
    }], null, false, 510471526)
  }, [_c('span', [_vm._v("按所选设备创建多个服务请求")])]) : _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
            attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "text": "",
            "color": "secondary",
            "loading": !_vm.submitEnd
          },
          on: {
            "click": function click($event) {
              return _vm.submit('all');
            }
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("note_add")]), _vm._v("提交")], 1)];
      }
    }], null, false, 3919191770)
  }, [_c('span', [_vm._v("创建新的请求")])])], 1) : _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v("关闭")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }