<template>
  <v-container class="fluid">
    <v-card-actions>
      <v-spacer></v-spacer
      ><v-btn
        class="mx-4 my-2"
        depressed
        @click="createItem()"
        color="secondary"
        >创建报价模版</v-btn
      >
    </v-card-actions>
    <!-- <v-row>
      <v-col cols="2" v-for="item in itemList" :key="item._id">
        <v-hover v-slot="{ hover }">
          <v-card height="100" outlined class="overflow-hidden">
            <v-img
              :src="
                item.cover_image
                  ? ossURL + '/' + item.cover_image
                  : '/static/error/empty_state.svg'
              "
              class="align-end"
              height="100"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            >
              <v-card-title
                class="pa-2 caption white--text"
                v-text="item.title"
              ></v-card-title>
              <span class="blockIcon">
                <v-icon x-small color="white" v-if="!item.isEnable"
                  >block</v-icon
                >
                <v-icon x-small color="white" v-if="item.isTop"
                  >vertical_align_top</v-icon
                >
              </span>
            </v-img>
            <v-fade-transition>
              <div
                v-if="hover"
                class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal body-2 white--text"
                style="height: 100%; cursor: pointer"
              >
                <v-btn
                  fab
                  color="secondary mr-4"
                  x-small
                  @click="editItem(item)"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
                <v-btn
                  fab
                  color="secondary mr-4"
                  x-small
                  @click="setEnable(item._id, true)"
                  v-if="!item.isEnable"
                >
                  <v-icon>check_circle_outline</v-icon>
                </v-btn>
                <v-btn
                  fab
                  color="secondary mr-4"
                  x-small
                  @click="setEnable(item._id, false)"
                  v-else
                >
                  <v-icon>block</v-icon>
                </v-btn>
                <v-btn
                  fab
                  color="secondary"
                  x-small
                  v-if="!item.isTop"
                  @click="setTop(item._id, true)"
                >
                  <v-icon>vertical_align_top</v-icon>
                </v-btn>
                <v-btn
                  fab
                  color="secondary"
                  x-small
                  v-else
                  @click="setTop(item._id, false)"
                >
                  <v-icon>vertical_align_bottom</v-icon>
                </v-btn>
              </div>
            </v-fade-transition>
          </v-card>
        </v-hover>
      </v-col>
    </v-row> -->
    <v-row class="fill-height overflow-y-auto">
      <v-col
        lg="3"
        md="3"
        sm="4"
        cols="12"
        v-for="item in itemList"
        :key="item._id"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            outlined
            hover
            :ripple="false"
          >
            <v-img
              :src="item.cover_image ? ossURL + '/' + item.cover_image : '/static/error/empty_state.svg'"
              class="white--text align-end repeating-gradient"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title v-text="item.title"></v-card-title>
              <v-app-bar absolute :dark="hover" color="transparent" flat dense>
                <!-- <v-chip label small v-if="!item.isEnable">已停用</v-chip> -->
                <v-icon small color="green" v-if="item.isEnable">info</v-icon>
                <v-icon small color="error" v-else>info</v-icon>
                <v-spacer></v-spacer>
                <v-btn icon small @click="editItem(item)"><v-icon small>edit</v-icon></v-btn>
                <v-btn icon small v-if="!item.isEnable" @click="setEnable(item._id, true)">
                  <v-icon small>check_circle_outline</v-icon>
                </v-btn>
                <v-btn icon small v-else @click="setEnable(item._id, false)">
                  <v-icon small>block</v-icon>
                </v-btn>
                <v-btn icon small v-if="!item.isTop" @click="setTop(item._id, true)">
                  <v-icon small>vertical_align_top</v-icon>
                </v-btn>
                <v-btn icon small v-else @click="setTop(item._id, false)">
                  <v-icon small>vertical_align_bottom</v-icon>
                </v-btn>
              </v-app-bar>
            </v-img>
            <!-- <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn icon @click="editItem(item)"><v-icon>edit</v-icon></v-btn>
              <v-btn icon v-if="!item.isEnable" @click="setEnable(item._id, true)"
                ><v-icon>check_circle_outline</v-icon></v-btn
              >
              <v-btn icon v-else @click="setEnable(item._id, false)"
                ><v-icon>block</v-icon></v-btn
              >
              <v-btn icon v-if="!item.isTop" @click="setTop(item._id, true)"
                ><v-icon>vertical_align_top</v-icon></v-btn
              >
              <v-btn icon v-else @click="setTop(item._id, false)"
                ><v-icon>vertical_align_bottom</v-icon></v-btn
              >
            </v-card-actions> -->
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-progress-circular
          v-if="this.query.page * this.query.limit < rentTemplateCount"
          indeterminate
          color="secondary"
          width="2"
          v-intersect="{
            handler: infiniteScrolling,
            options: {
              threshold: [1.0],
            },
          }"
        ></v-progress-circular>
        <span v-else class="title grey--text font-weight-light"
          >已展示{{ rentTemplateCount }}个报价模版，没有更多数据了。</span
        >
      </v-col>
    </v-row>
    <v-fab-transition>
      <v-btn
        class="transition-swing"
        v-scroll="onScroll"
        v-show="fab"
        color="secondary"
        fab
        dark
        fixed
        bottom
        right
        large
        @click="toTop"
        style="z-index: 6"
      >
        <v-icon>keyboard_arrow_up</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog v-model="dlgEdit" persistent scrollable max-width="700px">
      <v-form v-model="valid" @submit.prevent="submitItem">
        <v-card>
          <v-card-title>
            <span class="headline">{{ dlgTitle }}</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeItem"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row justify="center" align="center">
              <v-col cols="12">
                <v-progress-linear
                  color="secondary"
                  v-if="Upload.uploading"
                  :value="Upload.progressUpload"
                ></v-progress-linear>
                <input
                  id="files"
                  type="file"
                  name="file"
                  ref="uploadInput"
                  accept="image/*"
                  :multiple="false"
                  @change="detectFiles($event)"
                />
                <v-img
                  :src="
                    editedItem.cover_image
                      ? ossURL + '/' + editedItem.cover_image
                      : '/static/error/empty_state.svg'
                  "
                  dark
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="200px"
                >
                  <v-row
                    align="end"
                    class="lightbox white--text pa-2 fill-height"
                  >
                    <v-col>
                      <div class="text-h6">
                        {{ editedItem.title }}
                        <v-btn text @click="uploadImage()" class="float-right"
                          ><v-icon left>add_photo_alternate</v-icon
                          >上传素材封面</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-img>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.title"
                  label="报价名称、说明"
                  outlined
                  :rules="[rules.required]"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.rent_parameter"
                  :items="rentParameterList"
                  item-text="name"
                  item-value="_id"
                  label="默认参数"
                  :rules="[rules.selected]"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.product_list"
                  :items="productList"
                  :rules="[rules.selected]"
                  item-text="name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  dense
                  chips
                  small-chips
                  label="选择产品"
                  multiple
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="data.attrs.inputValue"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.name"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="closeItem">关闭</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="secondary" @click="submitItem" :disabled="!valid"
              >保存</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import store from "@/store";
import {
  FETCH_UPLOAD,
  FETCH_RENT_PARAMETER_LIST,
  FETCH_RENT_TEMPLATE_LIST,
  FETCH_PRODUCT_LIST,
  PUBLISH_RENT_TEMPLATE,
  EDIT_RENT_TEMPLATE,
  ACTIVE_RENT_TEMPLATE,
  TOP_RENT_TEMPLATE,
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
export default {
  data() {
    return {
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
      },
      itemList: [],
      fab: false,
      query: {
        page: 1,
        limit: 10,
      },
      defaultQuery: {
        page: 1,
        limit: 10,
      },
      dlgEdit: false,
      dlgTitle: null,
      valid: true,
      editedItem: {
        title: null,
        cover_image: null,
        product_list: [],
        rent_parameter: null,
      },
      defaultItem: {
        title: null,
        cover_image: null,
        product_list: [],
        rent_parameter: null,
      },
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      productList: [],
    };
  },
  computed: {
    ...mapGetters([
      "rentParameterList",
      "rentTemplateLoading",
      "rentTemplateCount",
      "uploadSign",
    ]),
  },
  mounted() {},
  created() {
    this.getList();
    this.getProductList();
  },
  watch: {
    dlgEdit(val) {
      val || this.closeItem();
    },
  },
  methods: {
    getList() {
      let strQuery = "";
      if (this.query.page) strQuery += "&page=" + this.query.page;
      if (this.query.limit) strQuery += "&limit=" + this.query.limit;
      store.dispatch(FETCH_RENT_TEMPLATE_LIST, strQuery).then((data) => {
        this.itemList = data.template;
      });
    },
    getProductList() {
      store.dispatch(FETCH_PRODUCT_LIST, "isRent=true").then((data) => {
        this.productList = data.product;
      });
    },
    infiniteScrolling() {
      this.query.page++;
      this.getList();
    },
    onScroll() {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || document.documentElement.offsetTop || 0;
      this.fab = top > 300;
    },
    toTop() {
      if (this.$route.hash) {
        this.$router.push({ hash: "" });
      }
      this.$vuetify.goTo(0);
    },
    createItem() {
      store.dispatch(FETCH_RENT_PARAMETER_LIST).then(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.dlgTitle = "创建报价模版";
        this.dlgEdit = true;
      });
    },
    editItem(item) {
      store.dispatch(FETCH_RENT_PARAMETER_LIST).then(() => {
        this.editedItem = Object.assign({}, item);
        this.dlgTitle = "编辑报价模版";
        this.dlgEdit = true;
      });
    },
    setEnable(id, reply) {
      store
        .dispatch(ACTIVE_RENT_TEMPLATE, { _id: id, reply })
        .then(() => {
          store.commit(SET_ERROR, { msg: "操作成功", color: "primary" });
          this.getList();
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err });
        });
    },
    setTop(id, reply) {
      store
        .dispatch(TOP_RENT_TEMPLATE, { _id: id, reply })
        .then(() => {
          store.commit(SET_ERROR, { msg: "操作成功", color: "primary" });
          this.getList();
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err });
        });
    },
    submitItem() {
      if (this.editedItem._id) {
        // 编辑 EDIT_RENT_TEMPLATE
        store
          .dispatch(EDIT_RENT_TEMPLATE, this.editedItem)
          .then(() => {
            store.commit(SET_ERROR, {
              msg: "修改模版成功！",
              color: "primary",
            });
            this.closeItem();
            this.getList();
          })
          .catch(({ err }) => {
            store.commit(SET_ERROR, { msg: err.response.data.message });
          });
      } else {
        // 新增 PUBLISH_RENT_TEMPLATE
        store
          .dispatch(PUBLISH_RENT_TEMPLATE, this.editedItem)
          .then(() => {
            store.commit(SET_ERROR, {
              msg: "创建模版成功！",
              color: "primary",
            });
            this.closeItem();
            this.getList();
          })
          .catch(({ err }) => {
            store.commit(SET_ERROR, { msg: err.response.data.message });
          });
      }
    },
    closeItem() {
      this.dlgTitle = "";
      this.dlgEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    uploadImage() {
      this.$store
        .dispatch(FETCH_UPLOAD)
        .then(this.$refs.uploadInput.click())
        .catch((err) => {
          this.$store.commit(SET_ERROR, { msg: err });
        });
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.upload(fileList[x]);
      });
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append("name", file.name);
      param.append("key", "rent/" + this.uploadSign.key);
      param.append("policy", this.uploadSign.policy);
      param.append("OSSAccessKeyId", this.uploadSign.OSSAccessKeyId);
      param.append("success_action_status", 200);
      param.append("signature", this.uploadSign.signature);
      param.append("file", file, file.name);

      let config = {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          this.Upload.progressUpload = Math.floor(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
      };

      await axios
        .post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = "";
          this.editedItem.cover_image = "rent/" + this.uploadSign.key;
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = "";
          this.$store.commit(SET_ERROR, { msg: error.message });
        });
    },
  },
};
</script>
<style scoped>
.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 0;
  background: url("/static/empty_data.png") no-repeat center center;
  background-size: contain;
  transform: scale(0.15);
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}
.v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0 0 0 0 !important;
}
.blockIcon {
  position: absolute;
  right: 5px;
  top: 0;
}
</style>
