<template>
  <v-container>
    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>产品管理</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-alert dense type="info" class="ma-2" dismissible>
        ESP平台所有产品都将在此维护，包括产品属性、成本信息等内容。产品型号为产品的唯一值，不能重复录入。
      </v-alert>
      <v-tabs v-model="activeTab" color="secondary" grow icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route">
          {{ tab.name }}
          <v-icon>{{tab.icon}}</v-icon>
        </v-tab>
        <v-tab-item v-for="tab of tabs" :key="tab.id" :value="tab.route" :transition="false" :reverse-transition="false">
          <router-view></router-view>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>
<script>

export default {
  data() {
    return {
      activeTab: '/dealer/system/product/list',
      tabs: [
        { id: 1, name: '产品类别', route: '/dealer/system/product/type', help: '产品分类', icon: 'widgets' },
        { id: 2, name: '品牌厂商', route: '/dealer/system/product/vendor', help: '供应商是资产的生产方即品牌', icon: 'local_offer' },
        { id: 3, name: '产品列表', route: '/dealer/system/product/list', help: '产品管理', icon: 'devices_other' },
      ],
    }
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    goBack() {
      this.$router.push({path: '/dealer/system'});
    },
  }
}
</script>
