<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>问题反馈</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="doAction('search_bugs')"
          dark
          class="mx-4"
          flat
          dense
          hide-details
          label="功能模块"
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
      </v-toolbar>
      <v-alert dense type="info" class="ma-2" dismissible>
        操作日志为系统用户登录系统后所有的增加、删除、修改操作，不包括浏览和查询操作。
      </v-alert>
      <v-data-table
        :headers="headerBugs"
        :items="bugList"
        :options.sync="bugs_options"
        :server-items-length="bugCount"
        :loading="bugLoading"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10,15, 30, 50]}"
        transition
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title><v-icon class="mr-2">bug_report</v-icon>反馈记录</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn class="ma-2" outlined fab x-small @click.stop="doAction('filter_logs')">
              <v-icon>filter_list</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.model="{ item }">
          <td nowrap="true" width="80px" @click="doAction('open_item', item, item._id)">{{item.model || ''}}</td>
        </template>
        <template v-slot:item.who="{ item }">
          <td nowrap="true" width="80px" v-if="item.who"><widgets-employee-dialogs :account="item.who._id" class="v-list-item--link">{{item.who.personal ? item.who.personal.name : '*未设置姓名'}}</widgets-employee-dialogs></td>
          <td nowrap="true" width="80px" v-else>-</td>
        </template>
        <template v-slot:item.when="{ item }">
          <td nowrap="true" width="135px">{{item.when | formatTime}}</td>
        </template>
        <template v-slot:item.where.fullPath="{ item }">
          <td nowrap="true" width="155px">{{item.where.fullPath}}</td>
        </template>
        <template v-slot:item.updateTime="{ item }">
          <td nowrap="true" width="155px">{{item.updateTime | formatTime}}</td>
        </template>
        <template v-slot:item.status="{ item }">
          <td nowrap="true" width="75px">{{item.status | normalStatus}}</td>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="doAction('open_item', item, item._id)">open_with</v-icon>
          <v-icon small>done</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" persistent scrollable width="750">
      <v-card>
        <v-card-title>
          反馈详情
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_item')"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="openItem.who">
          <v-row>
            <v-col cols="2" class="text-right">功能模块</v-col>
            <v-col cols="4" class="text--secondary">{{openItem.model}}</v-col>
            <v-col cols="2" class="text-right">发生时间</v-col>
            <v-col cols="4" class="text--secondary">{{openItem.when | formatTime}}</v-col>
            <v-col cols="2" class="text-right">提交人</v-col>
            <v-col cols="4" class="text--secondary">{{openItem.who.personal ? openItem.who.personal.name : openItem.who.username }}</v-col>
            <v-col cols="2" class="text-right">IP地址</v-col>
            <v-col cols="4" class="text--secondary"><pre>{{openItem.ip_address}}</pre></v-col>
            <v-col cols="2" class="text-right">路由信息</v-col>
            <v-col cols="10" class="text--secondary"><pre>{{openItem.where}}</pre></v-col>
            <v-col cols="2" class="text-right">问题描述</v-col>
            <v-col cols="10" class="text--secondary"><pre>{{openItem.what}}</pre></v-col>
            <v-col cols="2" class="text-right">预期效果</v-col>
            <v-col cols="10" class="text--secondary"><pre>{{openItem.want}}</pre></v-col>
            <v-col cols="2" class="text-right">系统信息</v-col>
            <v-col cols="10" class="text--secondary"><pre>{{openItem.agent}}</pre></v-col>
            <v-col cols="2" class="text-right">页面截图</v-col>
            <v-col cols="10">
              <v-img
                max-width="250"
                contain
                :src="openItem.preview ? openItem.preview : '/static/error/empty_state.svg'"
                @click="doAction('open_preview', openItem, openItem.preview ? openItem.preview : '/static/error/empty_state.svg')"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgImage" fullscreen hide-overlay scrollable transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>页面截图</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="doAction('close_preview')"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-img :src="activeImage" aspect-ratio="1" contain></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="doAction('close_preview')">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
  FETCH_BUG_LIST,
  FETCH_BUG,
} from "@/store/actions.type";

export default {
  data() {
    return {
      query: {
        key: '',
      },
      defaultQuery: {
        key: '',
      },
      headerBugs: [
        { text: '功能模块', value: 'model' },
        { text: '提交人', value: 'who' },
        { text: '提交时间', value: 'when' },
        { text: '页面地址', value: 'where.fullPath' },
        { text: '更新时间', value: 'updateTime' },
        { text: '状态', value: 'status' },
        { text: '操作', value: 'actions', sortable: false },
      ],
      bugs_options: {},
      filterDrawer: null,
      dialog: false,
      openItem: {},
      dlgImage: false,
      activeImage: '/static/error/empty_state.svg'
    };
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "bugLoading",
      "bugList",
      "bugCount"]),
  },
  watch: {
    bugs_options: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.push({path: '/dealer/system'});
    },
    getDataList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.bugs_options;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (query.key) urlQuery += '&key='+ query.key
      if (Object.keys(this.bugs_options).length > 0) {
        store.dispatch(FETCH_BUG_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    doAction(action, item = {}, customitem) {
      switch (action) {
        case 'search_bugs': {
          if (this.query.key !== '') {
            this.getDataList({key: this.query.key})
          } else {
            this.getDataList()
          }
          break
        }
        case 'open_item': {
          store.dispatch(FETCH_BUG, item._id)
            .then((data) => {
              this.openItem = Object.assign({}, data)
              this.dialog = true
            })
          break
        }
        case 'close_item': {
          this.openItem = Object.assign({}, {})
          this.dialog = false
          break
        }
        case 'open_preview': {
          this.activeImage = customitem
          this.dlgImage = true
          break
        }
        case 'close_preview': {
          this.activeImage = ''
          this.dlgImage = false
          break
        }
        case 'filter_logs': {
          this.filterDrawer = !this.filterDrawer
          break
        }
        default: {
          this.dlgTitle = customitem
          this.dlgEdit = true
          break
        }
      }
    },
  }
}
</script>
<style scoped>
pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>
