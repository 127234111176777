var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("设备服务列表")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "flat": "",
      "dense": "",
      "hide-details": "",
      "label": "搜索服务编号、企业工单编号、ERP单号...",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.doAction('search_item');
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 设备服务列表是经销商为企业提供定制化服务的工单列表，经销商可以基于不同的企业在后台配置不同的服务流程。 ")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.computedHeaders,
      "items": _vm.caseList,
      "options": _vm.optionsItem,
      "server-items-length": _vm.caseCount,
      "loading": _vm.caseLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 20]
      },
      "item-key": "_id",
      "return-object": "false",
      "transition": "",
      "fixed-header": "",
      "height": "600",
      "show-select": _vm.query.tasks && _vm.query.tasks.length === 1
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-tabs', {
          attrs: {
            "align-with-title": ""
          },
          model: {
            value: _vm.active_tab,
            callback: function callback($$v) {
              _vm.active_tab = $$v;
            },
            expression: "active_tab"
          }
        }, [_c('v-tabs-slider', {
          attrs: {
            "color": "secondary"
          }
        }), _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('all');
            }
          }
        }, [_vm._v("全部工单")]), _vm.todoCount ? _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('todo');
            }
          }
        }, [_c('v-badge', {
          attrs: {
            "color": "secondary",
            "content": _vm.todoCount
          }
        }, [_vm._v(" 我的待办 ")])], 1) : _vm._e(), _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('follow');
            }
          }
        }, [_vm._v("我的关注")])], 1), _vm.query.tasks && _vm.query.tasks.length ? _c('v-btn', {
          staticClass: "ma-2",
          attrs: {
            "text": "",
            "disabled": !_vm.selectedItem.length
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('edit_batch', _vm.selectedItem, '批量修改');
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("create")]), _vm._v(" 批量修改 ")], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Service_Edit') ? _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "secondary",
                  "depressed": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "left": ""
                }
              }, [_vm._v("add")]), _vm._v(" 新建工单 ")], 1)];
            }
          }], null, false, 3632379005)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.doAction('select_service', _vm.query.enterprise, '选择企业和服务');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("add_box")])], 1), _c('v-list-item-title', [_vm._v("通过表单增加请求")])], 1), _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.doAction('batch_create', _vm.query.enterprise, '批量创建新请求');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("library_add")])], 1), _c('v-list-item-title', [_vm._v("通过文件增加请求")])], 1)], 1)], 1) : _vm._e(), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "loading": _vm.downloading
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('export_item');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("save_alt")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("导出数据")])]), _c('widgets-custom-header', {
          attrs: {
            "defaultHeader": _vm.defaultHeader,
            "headerList": _vm.headerItem,
            "version": 2,
            "tableName": "serviceHeader"
          },
          on: {
            "Cancel": function Cancel($event) {
              return _vm.doAction('cancel_header');
            },
            "Default": function Default($event) {
              return _vm.doAction('default_header');
            },
            "Save": function Save($event) {
              return _vm.doAction('set_header');
            }
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('filter_item');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('reload_item');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("autorenew")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("刷新列表")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.case_number",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.isMark ? _c('v-btn', {
          attrs: {
            "icon": "",
            "color": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.markCase(item._id, false);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "transition": "scale-transition"
          }
        }, [_vm._v("star")])], 1) : _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.markCase(item._id, true);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "transition": "scale-transition"
          }
        }, [_vm._v("star_outline")])], 1), _c('v-badge', {
          attrs: {
            "color": "secondary",
            "dot": "",
            "value": item.isEdit && !item.isSelf
          }
        }, [_c('widgets-case-dialogs', {
          key: item._id,
          attrs: {
            "getId": item._id
          },
          on: {
            "Close": _vm.closeCase
          }
        }, [_vm._v(" " + _vm._s(item.case_number) + " "), item.isSelf && item.status < 2 ? _c('v-icon', {
          attrs: {
            "right": "",
            "small": ""
          }
        }, [_vm._v("create")]) : _vm._e()], 1)], 1)], 1)];
      }
    }, {
      key: "item.out_number",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.out_number))])];
      }
    }, {
      key: "item.catalog",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.catalog.name))])];
      }
    }, {
      key: "item.handover_type",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.handover_type ? _vm.handoverType.find(function (o) {
          return o.code === item.handover_type;
        }).name : ''))])];
      }
    }, {
      key: "item.total_sla.expectTime",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.total_sla && item.total_sla.status === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-baseTimer', {
          attrs: {
            "endTime": item.total_sla.expectTime,
            "alertThreshold": item.total_sla.sla_limit / 2,
            "warningThreshold": item.total_sla.sla_limit,
            "type": "text"
          }
        })], 1) : item.total_sla ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.total_sla.status === 1 ? '完成' : '超时完成'))]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.current_task",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.current_task ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.current_task.task ? item.current_task.task.name : ''))]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.status === 0 ? '受理' : '已结束'))])];
      }
    }, {
      key: "item.count_tasks",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.status === 0 ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("共 " + _vm._s(item.count_tasks) + " 个")])];
      }
    }, {
      key: "item.enterprise",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [item.enterprise ? _c('td', {
          staticClass: "v-list-item--link",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('open_enterprise', item.enterprise);
            }
          }
        }, [_vm._v(_vm._s(item.enterprise.name))]) : _vm._e()];
      }
    }, {
      key: "item.creator",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [item.creator ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-employee-dialogs', {
          staticClass: "v-list-item--link",
          attrs: {
            "account": item.creator._id
          }
        }, [_vm._v(_vm._s(item.creator.personal ? item.creator.personal.name : '*未设置姓名'))])], 1) : _vm._e()];
      }
    }, {
      key: "item.editor",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [item.editor ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-employee-dialogs', {
          staticClass: "v-list-item--link",
          attrs: {
            "account": item.editor._id
          }
        }, [_vm._v(_vm._s(item.editor.personal ? item.editor.personal.name : '*未设置姓名'))])], 1) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.deliver_team",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.deliver_team ? item.deliver_team.name : '-'))])];
      }
    }, {
      key: "item.account",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [item.account ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('widgets-employee-dialogs', {
          staticClass: "v-list-item--link",
          attrs: {
            "account": item.account._id
          }
        }, [_vm._v(_vm._s(item.account.personal ? item.account.personal.name : '*未设置姓名'))])], 1) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("-")])];
      }
    }, {
      key: "item.link_asset",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [item.link_asset ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.link_asset.length) + "台")]) : _vm._e()];
      }
    }, {
      key: "item.link_asset_new",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [item.link_asset_new ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.link_asset_new.length) + "台")]) : _vm._e()];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [item.status === 0 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "secondary"
          }
        }, [_vm._v("待受理")]) : item.status === 1 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "primary"
          }
        }, [_vm._v("处理中")]) : item.status === 2 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已取消")]) : item.status === 3 ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已取消")]) : _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已完成")])], 1)];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }]),
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v;
      },
      expression: "selectedItem"
    }
  })], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.filterDrawer = !_vm.filterDrawer;
            }
          }
        }, [_vm._v("隐藏")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('clear_filter');
            }
          }
        }, [_vm._v("清空")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 my-2"
  }, [_vm._v("基本信息")]), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.dealer_enterprise,
      "label": "企业",
      "hint": "请选择一家企业",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_employee_list');
      }
    },
    model: {
      value: _vm.query.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "enterprise", $$v);
      },
      expression: "query.enterprise"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.listApplicant.filter(function (item) {
        return item.personal && item.personal.name;
      }),
      "loading": _vm.searchEmployee.loadingApplicant,
      "search-input": _vm.searchEmployee.searchApplicant,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "服务提交人员",
      "hint": "请选择工单提交人员",
      "clearable": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        return _vm.$set(_vm.searchEmployee, "searchApplicant", $event);
      },
      "update:search-input": function updateSearchInput($event) {
        return _vm.$set(_vm.searchEmployee, "searchApplicant", $event);
      },
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.query.creator,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "creator", $$v);
      },
      expression: "query.creator"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.listAccount.filter(function (item) {
        return item.personal && item.personal.name;
      }),
      "loading": _vm.searchEmployee.loadingAccount,
      "search-input": _vm.searchEmployee.searchAccount,
      "filter": _vm.filterEmployee,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "设备使用人员",
      "hint": "请选择设备使用人员",
      "clearable": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        return _vm.$set(_vm.searchEmployee, "searchAccount", $event);
      },
      "update:search-input": function updateSearchInput($event) {
        return _vm.$set(_vm.searchEmployee, "searchAccount", $event);
      },
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.username) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.query.account,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "account", $$v);
      },
      expression: "query.account"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "设备序列号",
      "hint": "设备序列号是设备的唯一标示",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      },
      "click:clear": function clickClear($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.serial_number,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "serial_number", $$v);
      },
      expression: "query.serial_number"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "快递单号",
      "hint": "请输入工单所含快递单号",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      },
      "click:clear": function clickClear($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.express_number,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "express_number", $$v);
      },
      expression: "query.express_number"
    }
  }), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("工单状态")]), _vm.caseStatus.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.status,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "status", $$v);
      },
      expression: "query.status"
    }
  }, _vm._l(_vm.caseStatus, function (cases) {
    return _c('v-chip', {
      key: cases.code,
      attrs: {
        "value": cases.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(cases.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("服务方式")]), _vm.handoverType.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.handover,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "handover", $$v);
      },
      expression: "query.handover"
    }
  }, _vm._l(_vm.handoverType, function (handover) {
    return _c('v-chip', {
      key: handover.code,
      attrs: {
        "value": handover.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(handover.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("SLA过期时间")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.deadline,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "deadline", $$v);
      },
      expression: "query.deadline"
    }
  }, _vm._l(_vm.SLAExpiry, function (datetiem) {
    return _c('v-chip', {
      key: datetiem.code,
      attrs: {
        "value": datetiem.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(datetiem.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("创建时间")]), _c('v-menu', {
    ref: "menuStartCreateTime",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref22) {
        var on = _ref22.on,
            attrs = _ref22.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "开始时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "change": function change($event) {
              return _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.startCreateTime,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "startCreateTime", $$v);
            },
            expression: "query.startCreateTime"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menuStartCreateTime,
      callback: function callback($$v) {
        _vm.menuStartCreateTime = $$v;
      },
      expression: "menuStartCreateTime"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuStartCreateTime = false;

        _vm.clearTime(['createTime']);
      }
    },
    model: {
      value: _vm.query.startCreateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "startCreateTime", $$v);
      },
      expression: "query.startCreateTime"
    }
  })], 1), _c('v-menu', {
    ref: "menuStartCreateTime",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref23) {
        var on = _ref23.on,
            attrs = _ref23.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "结束时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "change": function change($event) {
              return _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.endCreateTime,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "endCreateTime", $$v);
            },
            expression: "query.endCreateTime"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menuEndCreateTime,
      callback: function callback($$v) {
        _vm.menuEndCreateTime = $$v;
      },
      expression: "menuEndCreateTime"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuEndCreateTime = false;

        _vm.clearTime(['createTime']);
      }
    },
    model: {
      value: _vm.query.endCreateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "endCreateTime", $$v);
      },
      expression: "query.endCreateTime"
    }
  })], 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("完成时间")]), _c('v-menu', {
    ref: "menuStartCreateTime",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref24) {
        var on = _ref24.on,
            attrs = _ref24.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "开始时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "change": function change($event) {
              return _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.startCompleteTime,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "startCompleteTime", $$v);
            },
            expression: "query.startCompleteTime"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menuStartCompleteTime,
      callback: function callback($$v) {
        _vm.menuStartCompleteTime = $$v;
      },
      expression: "menuStartCompleteTime"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuStartCompleteTime = false;

        _vm.clearTime(['createTime']);
      }
    },
    model: {
      value: _vm.query.startCompleteTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "startCompleteTime", $$v);
      },
      expression: "query.startCompleteTime"
    }
  })], 1), _c('v-menu', {
    ref: "menuStartCreateTime",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref25) {
        var on = _ref25.on,
            attrs = _ref25.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "结束时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "change": function change($event) {
              return _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.endCompleteTime,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "endCompleteTime", $$v);
            },
            expression: "query.endCompleteTime"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menuEndCompleteTime,
      callback: function callback($$v) {
        _vm.menuEndCompleteTime = $$v;
      },
      expression: "menuEndCompleteTime"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuEndCompleteTime = false;

        _vm.clearTime(['createTime']);
      }
    },
    model: {
      value: _vm.query.endCompleteTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "endCompleteTime", $$v);
      },
      expression: "query.endCompleteTime"
    }
  })], 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("更新时间")]), _c('v-chip-group', {
    attrs: {
      "column": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.updateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "updateTime", $$v);
      },
      expression: "query.updateTime"
    }
  }, _vm._l(_vm.updateTime, function (datetiem) {
    return _c('v-chip', {
      key: datetiem.code,
      attrs: {
        "value": datetiem.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(datetiem.name))]);
  }), 1), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("所属服务")]), _vm.serviceList.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.service,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "service", $$v);
      },
      expression: "query.service"
    }
  }, _vm._l(_vm.serviceList, function (service) {
    return _c('v-chip', {
      key: service._id,
      attrs: {
        "value": service._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(service.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("当前任务")]), _vm.tasksList.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.tasks,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "tasks", $$v);
      },
      expression: "query.tasks"
    }
  }, _vm._l(_vm.tasksList, function (task) {
    return _c('v-chip', {
      key: task._id,
      attrs: {
        "value": task._id,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(task.name))]);
  }), 1) : _vm._e()], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "600px"
    },
    model: {
      value: _vm.dlgService,
      callback: function callback($$v) {
        _vm.dlgService = $$v;
      },
      expression: "dlgService"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('service');
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.dlgTitle))])]), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.dealer_enterprise,
      "rules": [_vm.rules.selected],
      "label": "企业",
      "hint": "请选择一家企业",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_service_list');
      }
    },
    model: {
      value: _vm.editedCase.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.editedCase, "enterprise", $$v);
      },
      expression: "editedCase.enterprise"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.availableService,
      "rules": [_vm.rules.selected],
      "label": "服务",
      "hint": "请选择开单的服务",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedCase.service,
      callback: function callback($$v) {
        _vm.$set(_vm.editedCase, "service", $$v);
      },
      expression: "editedCase.service"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dlgService = false;
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": function click($event) {
        return _vm.submit('service');
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'enterprise' ? _c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.doAction('close_info');
      }
    }
  }) : _vm._e(), _vm.activeType === 'batch_create' ? _c('v-card', [_c('v-card-title', [_vm._v(" 批量开单 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_info');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.dealer_enterprise,
      "rules": [_vm.rules.selected],
      "label": "企业",
      "hint": "请选择一家企业",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_service_list');
      }
    },
    model: {
      value: _vm.editedCase.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.editedCase, "enterprise", $$v);
      },
      expression: "editedCase.enterprise"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.availableService,
      "rules": [_vm.rules.selected],
      "label": "服务",
      "hint": "请选择开单的服务",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.editedCase.service,
      callback: function callback($$v) {
        _vm.$set(_vm.editedCase, "service", $$v);
      },
      expression: "editedCase.service"
    }
  }), _vm.editedCase.service ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载开单模版，填写设备信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('download_create_template');
      }
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importCreateFiles($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": "",
      "loading": !_vm.batch.import_end
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('upload_create_file');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1) : _vm._e(), _vm.batch.import_failed.length || _vm.batch.import_result.length ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _vm.batch.import_failed.length === 0 ? _c('span', [_vm._v("无")]) : _c('ul', _vm._l(_vm.batch.import_failed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.batch.import_result.length) + " 条记录； "), _vm.batch.import_result.length ? _c('v-row', [_c('v-col', {
    staticClass: "grow"
  }, _vm._l(_vm.batch.import_result, function (item) {
    return _c('p', {
      key: item._id,
      staticClass: "font-weight-black"
    }, [_vm._v(_vm._s(item.case_number))]);
  }), 0)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.activeType === 'batch_edit' ? _c('v-card', [_c('v-card-title', [_vm._v(" 批量处理任务 - " + _vm._s(_vm.batchUpdate.task_info.name) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_info');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.batchUpdate.task_info.remarks) + " "), _c('strong', [_vm._v("批量处理无法修改设备信息，请通过单个工单处理设备信息。")])])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.batchUpdate.task_info && _vm.batchUpdate.task_info.isExpress ? _c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.batchSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.batchUpdate.valid,
      callback: function callback($$v) {
        _vm.$set(_vm.batchUpdate, "valid", $$v);
      },
      expression: "batchUpdate.valid"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 收货人姓名 ")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "请输入收件人姓名",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.batchUpdate.express_name,
      callback: function callback($$v) {
        _vm.$set(_vm.batchUpdate, "express_name", $$v);
      },
      expression: "batchUpdate.express_name"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  })], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 收件人电话 ")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "请输入收件人手机号码",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.batchUpdate.express_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.batchUpdate, "express_phone", $$v);
      },
      expression: "batchUpdate.express_phone"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  })], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 收件地址 ")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "请输入收件人的收货地址",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.batchUpdate.express_address,
      callback: function callback($$v) {
        _vm.$set(_vm.batchUpdate, "express_address", $$v);
      },
      expression: "batchUpdate.express_address"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  })], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 快递公司 ")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.expressCorp,
      "rules": [_vm.rules.required, _vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "label": "请选择该快递承运公司"
    },
    model: {
      value: _vm.batchUpdate.express_code,
      callback: function callback($$v) {
        _vm.$set(_vm.batchUpdate, "express_code", $$v);
      },
      expression: "batchUpdate.express_code"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  })], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 快递单号 ")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "请输入快递单号",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.batchUpdate.express_number,
      callback: function callback($$v) {
        _vm.$set(_vm.batchUpdate, "express_number", $$v);
      },
      expression: "batchUpdate.express_number"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  })], 1)], 1) : _c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.batchSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.batchUpdate.valid,
      callback: function callback($$v) {
        _vm.$set(_vm.batchUpdate, "valid", $$v);
      },
      expression: "batchUpdate.valid"
    }
  }, _vm._l(_vm.batchUpdate.task_fields, function (item, i) {
    return _c('v-row', {
      key: i + '_task_fields',
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), item.format.type === 'text' ? _c('v-col', {
      staticClass: "text--secondary",
      attrs: {
        "cols": "8"
      }
    }, [item.format.mask ? _c('v-text-field', {
      directives: [{
        name: "mask",
        rawName: "v-mask",
        value: item.format.mask,
        expression: "item.format.mask"
      }],
      attrs: {
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "hide-details": "",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    }) : _c('v-text-field', {
      attrs: {
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "hide-details": "",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), item.format.type === 'selects' ? _c('v-col', {
      staticClass: "text--secondary",
      attrs: {
        "cols": "8"
      }
    }, [_c('v-autocomplete', {
      attrs: {
        "autocomplete": "off",
        "items": item.format.items,
        "rules": item.format.rules ? item.format.rules.map(function (item) {
          return _vm.rules[item];
        }) : [],
        "hide-details": "",
        "outlined": "",
        "dense": ""
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), _c('v-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "2"
      }
    })], 1);
  }), 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.batchUpdate.showCase = !_vm.batchUpdate.showCase;
      }
    }
  }, [_c('v-subheader', [_vm._v("批量处理工单：" + _vm._s(_vm.selectedItem.length))]), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(_vm._s(_vm.batchUpdate.showCase ? 'keyboard_arrow_up' : 'keyboard_arrow_down'))])], 1), _c('v-spacer'), _vm.batchUpdate.task_info.actions.includes('cancel') ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.batchCancel();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("remove_circle")]), _vm._v("批量取消")], 1) : _vm._e(), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.batchReject
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("replay_circle_filled")]), _vm._v("批量驳回")], 1), _vm.batchUpdate.task_info.actions.includes('complete') ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.batchUpdate.valid
    },
    on: {
      "click": function click($event) {
        return _vm.batchSubmit();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("check_circle")]), _vm._v("批量提交")], 1) : _vm._e()], 1), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.batchUpdate.showCase,
      expression: "batchUpdate.showCase"
    }]
  }, [_c('v-divider'), _c('v-card-text', _vm._l(_vm.selectedItem, function (selected, index) {
    return _c('v-chip', {
      key: selected._id,
      staticClass: "ma-2",
      attrs: {
        "close": "",
        "label": "",
        "small": ""
      },
      on: {
        "click:close": function clickClose($event) {
          return _vm.doAction('close_selected', index);
        }
      }
    }, [_vm._v(_vm._s(selected.case_number))]);
  }), 1)], 1)])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }